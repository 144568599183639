import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import { getModuleById } from '../store/modules/actions'
import { getThemeById } from '../store/themes/actions'

import CircularProgress from '@material-ui/core/CircularProgress'
import ModuleView from '../components/view/ModuleView'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: 0,
    maxWidth: '1110px',
    maxHeight: '100%'
  }
}))

const mapDispatchToProps = {
  getModuleById,
  getThemeById
}

const mapStateToProps = ({ modules, themes }) => {
  return {
    currentModule: modules.currentItem || {},
    currentTheme: themes.currentItem?.item || {},
    isFetchingTheme: themes.currentItem?.isFetching
  }
}

const ModulePage = ({
  match,
  getModuleById,
  currentModule,
  getThemeById,
  currentTheme,
  isFetchingTheme
}) => {
  const classes = useStyles()
  const moduleId = match.params?.id
  const module = currentModule?.item?.attributes || {}

  useEffect(() => {
    getModuleById({ ids: moduleId })
  }, [])

  useEffect(
    () => {
      module.themes_order &&
        module.themes_order?.length > 0 &&
        getThemeById({ ids: module.themes_order.join(',') })
    },
    [module]
  )

  if (currentModule.isFetching || isFetchingTheme) {
    return <CircularProgress color='secondary' />
  }

  return (
    <div className={classes.root}>
      <ModuleView
        currentModule={currentModule}
        currentTheme={currentTheme}
        module={module}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ModulePage)
