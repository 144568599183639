// Communications
export const GET_COMMUNICATIONS = 'communications/GET_COMMUNICATIONS'
export const GET_COMMUNICATIONS_SUCCESSFUL =
  'communications/GET_COMMUNICATIONS_SUCCESSFUL'
export const GET_COMMUNICATIONS_FAILURE =
  'communications/GET_COMMUNICATIONS_FAILURE'

export const GET_COMMUNICATION_BY_ID = 'communications/GET_COMMUNICATION_BY_ID'
export const GET_COMMUNICATION_BY_ID_SUCCESSFUL =
  'communications/GET_COMMUNICATION_BY_ID_SUCCESSFUL'
export const GET_COMMUNICATION_BY_ID_FAILURE =
  'communications/GET_COMMUNICATION_BY_ID_FAILURE'

export const CREATE_COMMUNICATION = 'communications/CREATE_COMMUNICATION'
export const CREATE_COMMUNICATION_SUCCESSFUL =
  'communications/CREATE_COMMUNICATION_SUCCESSFUL'
export const CREATE_COMMUNICATION_FAILURE =
  'communications/CREATE_COMMUNICATION_FAILURE'

export const UPDATE_COMMUNICATION = 'communications/UPDATE_COMMUNICATION'
export const UPDATE_COMMUNICATION_SUCCESSFUL =
  'communications/UPDATE_COMMUNICATION_SUCCESSFUL'
export const UPDATE_COMMUNICATION_FAILURE =
  'communications/UPDATE_COMMUNICATION_FAILURE'

// Messages
export const GET_MESSAGES = 'communications/GET_MESSAGES'
export const GET_MESSAGES_SUCCESSFUL = 'communications/GET_MESSAGES_SUCCESSFUL'
export const GET_MESSAGES_FAILURE = 'communications/GET_MESSAGES_FAILURE'

export const GET_MESSAGE_BY_ID = 'communications/GET_MESSAGE_BY_ID'
export const GET_MESSAGE_BY_ID_SUCCESSFUL =
  'communications/GET_MESSAGE_BY_ID_SUCCESSFUL'
export const GET_MESSAGE_BY_ID_FAILURE =
  'communications/GET_MESSAGE_BY_ID_FAILURE'

export const CREATE_MESSAGES = 'communications/CREATE_MESSAGES'
export const CREATE_MESSAGES_SUCCESSFUL =
  'communications/CREATE_MESSAGES_SUCCESSFUL'
export const CREATE_MESSAGES_FAILURE = 'communications/CREATE_MESSAGES_FAILURE'

export const UPDATE_MESSAGE = 'communications/UPDATE_MESSAGE'
export const UPDATE_MESSAGE_SUCCESSFUL =
  'communications/UPDATE_MESSAGE_SUCCESSFUL'
export const UPDATE_MESSAGE_FAILURE = 'communications/UPDATE_MESSAGE_FAILURE'

export const RESPOND_MESSAGES = 'communications/RESPOND_MESSAGES'
export const RESPOND_MESSAGES_SUCCESSFUL =
  'communications/RESPOND_MESSAGES_SUCCESSFUL'
export const RESPOND_MESSAGES_FAILURE =
  'communications/RESPOND_MESSAGES_FAILURE'

export const CLEAN_SUCCESS = 'communications/CLEAN_SUCCESS'
