import jsonApi from '../../utils/jsonApi'

const tagModal = 'tags'
const tagOptionsModel = 'tag_options'

export const fetchTags = ({ page, params, school }) => {
  return jsonApi
    .findAll(tagModal, {
      params: {
        ...params,
        'q[school_ids_cont_any]': school,
        'page[number]': page || 1,
        include: 'tag_options'
      }
    })
    .then(res => res)
}

export const fetchTagOptions = ({ page, params }) => {
  return jsonApi
    .findAll(tagOptionsModel, {
      params: {
        ...params,
        'page[number]': page || 1
      }
    })
    .then(res => res)
}
