import React from 'react'
import { makeStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles(theme => ({
  check: {
    '& svg': {
      fill: theme.palette.primary.main,
      height: 18,
      width: 18
    }
  }
}))

const CheckboxInput = ({ checked, handleCheck, ...rest }) => {
  const classes = useStyles()

  return (
    <Checkbox
      className={classes.check}
      checked={checked}
      onChange={handleCheck}
      color='primary'
      {...rest}
    />
  )
}

export default CheckboxInput
