import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  reduxForm,
  Form,
  Field,
  formValueSelector,
  change,
  submit,
  destroy,
  getFormNames,
  isDirty
} from 'redux-form'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputMaterialUI from '@material-ui/core/Input'
import AddIcon from '@material-ui/icons/Add'

import { getSegmentByModuleRSPA } from '../../store/themes/actions'
import { Box, ButtonStyled } from './styles/CreateModuleForm.style'
import Select from '../shared/Select'
import Stepper from '../shared/Stepper'
import AsyncSelect from '../shared/AsyncSelect'
import ImagesGrid from '../shared/ImagesGrid'
import { dataToOptions } from '../../utils/parsers'
import Btn from '../button/Button'
import ListTable from '../shared/ListTable'
import Input from '../shared/Input'
import MaterialInput from '../shared/MaterialInput'
import TextArea from '../shared/TextArea'
import { validDescription } from '../../utils/formUtils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: 0,
    maxWidth: '1110px',
    maxHeight: '100%'
  },
  headerList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    '& span > h2': {
      fontSize: '2rem'
    },
    '& span > p': {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '8px',
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      padding: '1em 2em',
      textAlign: 'center'
    }
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  createBtn: {
    color: '#FFFF',
    backgroundColor: '#386093',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  createContentBtn: {
    color: '#FFFF',
    backgroundColor: '#00ACDB',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  instructions: {
    marginRight: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  stepButton: {
    zIndex: 999
  },
  mobileStepper: {
    background: 'none'
  },
  active: {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  selectSegment: {
    backgroundColor: '#FFF',
    '& .MuiInputBase-formControl': {
      maxHeight: 50
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      border: 'none'
    }
  },
  selectAsyncSkill: {
    '& .select__value-container': {
      display: 'grid',
      gridTemplateColumns: '30% 30% 30%',
      gridTemplateRows: '40px',
      color: 'white'
    },
    '& .select__placeholder': {
      color: 'rgba(0, 0, 0, 0.26)',
      paddingLeft: 12
    },
    '& .select__value-container > .select__multi-value': {
      justifyContent: 'space-between'
    },
    '& .select__value-container > .select__multi-value > .select__multi-value__label': {
      color: 'white'
    },
    '& .select__value-container > .select__multi-value:nth-of-type(3n-2)': {
      background: '#F4A77B',
      borderRadius: 8
    },
    '& .select__value-container > .select__multi-value:nth-of-type(3n-1)': {
      background: '#00ACDB',
      borderRadius: 8
    },
    '& .select__value-container > .select__multi-value:nth-of-type(3n)': {
      background: '#EF7B8E',
      borderRadius: 8
    },
    '& .select__control, .select__value-container': {
      minHeight: '2.5rem',
      paddingTop: 0,
      borderRadius: '8px !important'
    },
    '& .select__indicators': {
      height: 40
    }
  },
  contentTable: {
    backgroundColor: '#FFF',
    padding: '1rem 2rem',
    marginBottom: '10px',
    '& p:first-of-type': {
      fontWeight: 'bold'
    },
    '& span': {
      marginRight: '2rem'
    }
  },
  disableBtn: {
    backgroundColor: '#F2F2F2',
    border: '1px solid #ADB8CC',
    borderRadius: '8px',
    color: '#F2F2F2',
    height: '48px',
    padding: '8px 12px'
  },
  bull: {
    color: theme.palette.primary.dark
  },
  noRequired: {
    color: 'transparent'
  }
}))

const validate = values => {
  const errors = {}
  if (!values.title) {
    errors.title = 'Campo Obrigatório *'
  }
  if (!values.skill_ids || values.skill_ids.length === 0) {
    errors.skill_ids = 'Campo Obrigatório *'
  }
  if (!values.cover_id) {
    errors.cover_id = 'Campo Obrigatório *'
  }
  return errors
}

const CreateThemeForm = ({
  handleSubmit,
  onSubmit,
  submitting,
  initialValues,
  title,
  change,
  submit,
  contents,
  currentModule,
  currentTheme,
  currentContent,
  dirtyForms,
  destroy,
  getContents,
  valid,
  touch,
  isEditing,
  school,
  getContentsByIds,
  clearCurrentTheme,
  clearCurrentContent,
  segments,
  segmentValue,
  themeModuleId,
  description,
  isAdmin,
  getSegmentByModuleRSPA
}) => {
  const getSteps = {
    1: isEditing ? 'Editar Tema' : 'Novo Tema',
    2: 'Seleção de Conteúdos',
    3: 'Salvar'
  }
  const history = useHistory()

  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(1)
  const [selected, setSelected] = useState(initialValues.cover_id)
  const [segmentsOptions, setSegmentsOptions] = useState([])
  const [clearSkillField, setClearSkillField] = useState(false)
  const themeValues = currentTheme.item && currentTheme.item[0]
  const themeIncludedSkills =
    currentTheme?.included &&
    currentTheme.included.filter(include => include.type === 'skills')
  const labelSkill =
    themeIncludedSkills?.length > 0 &&
    themeIncludedSkills.map(skill => {
      return { label: skill.attributes.name, value: String(skill.id) }
    })
  const [skillsOptions, setSkillOptions] = useState(labelSkill)
  const themeIncludedModule =
    currentTheme?.included &&
    currentTheme.included.filter(include => include.type === 'theme_modules')
  const [themeModule, setThemeModule] = useState(
    initialValues.theme_module_id
      ? {
          label:
            themeIncludedModule && themeIncludedModule[0]?.attributes?.title,
          value: initialValues.theme_module_id
        }
      : {}
  )
  const initialContent = themeValues
    ? themeValues.attributes
      ? themeValues.attributes.contents_order.map(cont => String(cont))
      : []
    : []
  const [selectedContents, setSelectedContents] = useState(initialContent || [])

  useEffect(
    () => {
      const loadData = async () => {
        const options =
          segments?.items.length > 0 &&
          !segments?.isFetching &&
          // allow 'Ensino Médio' to segmentOptions
          (await dataToOptions(segments.items))
        setSegmentsOptions(options)
      }

      loadData()
    },
    [segments]
  )

  useEffect(
    async () => {
      if (segmentValue) {
        change('skill_ids', [])
        setSkillOptions([])
        setClearSkillField(true)
      }
    },
    [segmentValue]
  )

  useEffect(
    async () => {
      if (activeStep === 2) {
        await getContents({
          sort: 'id desc',
          hasTheme: false,
          hasSchoolId: !!school?.id,
          schoolId: school?.id
        })
      }
      themeValues?.attributes &&
        themeValues.attributes.contents_order.length > 0 &&
        (await getContentsByIds({
          ids: selectedContents.join(',')
        }))
    },
    [activeStep]
  )

  useEffect(
    async () => {
      if (currentModule && history.location.state?.from === 'createModule') {
        // clearCurrentTheme()
        const moduleId = currentModule?.id
        change('theme_module_id', moduleId)
      } else if (
        history.location?.state?.moduleId &&
        history.location.state?.from === 'searchModules'
      ) {
        change('theme_module_id', history.location.state.moduleId)
      } else if (history.location.state?.from === 'createContent') {
        setActiveStep(2)
        themeValues?.attributes &&
          themeValues.attributes.contents_order.length > 0 &&
          getContentsByIds({ ids: selectedContents.join(',') })
      }
    },
    [history]
  )

  useEffect(
    async () => {
      themeValues?.attributes &&
        themeValues.attributes.contents_order.length > 0 &&
        getContentsByIds({ ids: selectedContents.join(',') })
    },
    [activeStep, themeValues]
  )

  useEffect(
    () => {
      school && change('school_id', school.id)
    },
    [school]
  )

  const handleClick = id => {
    setSelected(id)
    change('cover_id', id)
  }

  const getModuleSegments = moduleId => {
    getSegmentByModuleRSPA({ moduleId })
      .then(res => {
        const segment = res.data?.grade?.segment
        change('segment_id', segment.id)
      })
      .catch(err => console.error(err))
  }

  const handleModuleChange = event => {
    setThemeModule(event)
    const module = event ? Number(event.value) : event
    change('theme_module_id', module)
    getModuleSegments(module)
  }

  const handleSkillChange = event => {
    setSkillOptions(event)
    const skillIds = event.map(skill => skill.value)
    change('skill_ids', skillIds)
    setClearSkillField(false)
  }

  const handleCheckContents = contentsIds => {
    setSelectedContents(contentsIds === null ? [] : contentsIds)
    change('contents_order', contentsIds === null ? [] : contentsIds)
  }

  const schoolParams = isAdmin
    ? { 'q[school_id_null]': true }
    : { 'q[school_id_eq]': school?.id }

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 1:
        return (
          <Box className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <label htmlFor='title'>
                  <span>Título do Tema</span>
                  <span className={classes.bull}>&bull;</span>
                </label>
                <Field
                  component={MaterialInput}
                  name='title'
                  placeholder='Título do Tema'
                  type='text'
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <label htmlFor='theme_module_id'>
                  <span>Módulo</span>
                  <span className={classes.noRequired}>&bull;</span>
                </label>
                {history.location.state?.from === 'createModule' ||
                history.location.state?.from === 'searchModules' ? (
                  <InputMaterialUI
                    className={classes.disableBtn}
                    type='text'
                    disableUnderline
                    placeholder={history.location.state.title}
                    disabled
                  />
                ) : (
                  <Field
                    component={AsyncSelect}
                    id='theme_module_id'
                    name='theme_module_id'
                    placeholder='Procure e selecione o Título do Módulo'
                    handleAsyncChange={handleModuleChange}
                    changeField={change}
                    searchParam='q[title_cont]'
                    touch={touch}
                    defaultValue={themeModule?.value ? themeModule : null}
                    request={{
                      path: 'theme_module',
                      params: schoolParams
                    }}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={3}>
                <label htmlFor='segment_id'>
                  <span>Segmento</span>
                  <span className={classes.bull}>&bull;</span>
                </label>
                {segmentsOptions.length > 0 && (
                  <Field
                    className={classes.selectSegment}
                    component={Select}
                    name='segment_id'
                    options={segmentsOptions}
                    height={'48px'}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={3}>
                <label htmlFor='skill_ids'>
                  <span>Habilidades</span>
                  <span className={classes.bull}>&bull;</span>
                </label>
                <Field
                  component={AsyncSelect}
                  id='skill_ids'
                  name='skill_ids'
                  isMulti
                  className={classes.selectAsyncSkill}
                  clearField={clearSkillField}
                  placeholder='Selecione as Habilidades'
                  handleAsyncChange={handleSkillChange}
                  disabled={!segmentValue}
                  cacheUniqs={0 || segmentValue}
                  isSearchable={false}
                  touch={touch}
                  defaultValue={skillsOptions}
                  request={{
                    path: 'skill',
                    params: {
                      'q[segments_id_in]': segmentValue
                    }
                  }}
                />
              </Grid>
            </Grid>
            <label htmlFor='description'>
              <span>Descrição do Tema</span>
              <span className={classes.bull}>&bull;</span>
            </label>
            <Field
              component={TextArea}
              name='description'
              placeholder='Descrição do Tema'
              rows='7'
              type='text'
              characterCounter='308'
              validate={[validDescription]}
            />
            <label htmlFor='cover_id'>
              <span>Selecione a Imagem de capa</span>
            </label>
            <ImagesGrid handleClick={handleClick} selected={selected} />
            <Field component={Input} name='cover_id' type='number' hidden />
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', gap: 12 }}
            >
              {backButton(() => {
                history.location?.state?.from === 'createModule'
                  ? history.push(`/modules/${currentModule.id}/edit`, {
                      from: 'createTheme',
                      moduleId: currentModule.id
                    })
                  : history.location?.state?.from === 'searchModules'
                    ? history.push('/newmodules')
                    : history.push('/themes')
              })}
              {submitButton()}
            </div>
          </Box>
        )
      case 2:
        return (
          <Box className={classes.root}>
            <div className={classes.headerList}>
              <span>
                <h2>Lista de Conteúdos</h2>
                <p>
                  {selectedContents && selectedContents.length} Conteúdos
                  Selecionados
                </p>
              </span>
              <ButtonStyled>
                <Btn
                  className={classes.createContentBtn}
                  startIcon={<AddIcon />}
                  onclick={async () => {
                    await clearCurrentContent()
                    history.push('/contents/create', {
                      from: 'createTheme',
                      title: title || themeValues?.title
                    })
                  }}
                >
                  Criar conteúdo
                </Btn>
              </ButtonStyled>
            </div>
            <ListTable
              data={contents.contents}
              name='contents_order'
              handleCheck={handleCheckContents}
              fetchItems={getContents}
              param={{ hasTheme: false }}
              selected={selectedContents}
              defaultValue={currentContent?.item?.data}
            />
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', gap: 12 }}
            >
              {backButton(handleBack)}
              {submitButton()}
            </div>
          </Box>
        )
      case 3:
        return (
          !currentContent.isFetching && (
            <Box className={classes.root}>
              <h2>Resumo</h2>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <h3>Título do Tema</h3>
                  <p>{title || themeValues?.attributes?.title}</p>
                  <h3>Módulo</h3>
                  <p>
                    {themeModule?.label ||
                      (themeValues?.attributes &&
                        themeValues.attributes.theme_module_id)}
                  </p>
                  <h3>Descrição</h3>
                  <p>{description || themeValues?.attributes?.description}</p>
                  <h3>Habilidades</h3>
                  {skillsOptions &&
                    skillsOptions.map(skill => (
                      <p key={skill.value}>{skill.label}</p>
                    ))}
                </Grid>
                <Grid item xs={6}>
                  <h3>
                    {selectedContents && selectedContents.length} Conteúdos
                    Selecionados
                  </h3>
                  {currentContent.item?.data &&
                    currentContent.item.data.length > 0 &&
                    currentContent.item.data
                      .filter(
                        content =>
                          selectedContents.indexOf(String(content.id)) !== -1
                      )
                      .map(content => <p key={content.id}>{content?.title}</p>)}
                  {(history.location.state?.from === 'createContent' ||
                    !currentContent.item?.data) &&
                    contents.contents?.data &&
                    contents.contents.data
                      .filter(
                        content =>
                          selectedContents.indexOf(String(content.id)) !== -1
                      )
                      .map(content => (
                        <p key={content.id}>{content.attributes?.title}</p>
                      ))}
                </Grid>
              </Grid>
              <div
                style={{ display: 'flex', justifyContent: 'flex-end', gap: 12 }}
              >
                {backButton(handleBack)}
                {finishedButton('Salvar Tema')}
              </div>
            </Box>
          )
        )
      default:
        return ''
    }
  }

  const submitButton = () => (
    <Button
      color='primary'
      className={classes.createBtn}
      variant='contained'
      disabled={submitting || !valid}
      onClick={() => {
        activeStep === 1 && clearCurrentContent()
        handleNext()
        submit()
      }}
    >
      Salvar
    </Button>
  )

  const finishedButton = (name, action) => (
    <Button
      name='btnNext'
      variant='contained'
      className={classes.createBtn}
      type='button'
      onClick={async () => {
        history.location?.state?.from === 'createModule'
          ? history.push(`/modules/${currentModule.id}/edit`, {
              from: 'createTheme',
              moduleId: currentModule.id
            })
          : history.location?.state?.from === 'searchModules'
            ? history.push('/newmodules')
            : history.push('/themes')
      }}
    >
      {name}
    </Button>
  )

  const backButton = action => (
    <Button
      className={classes.backBtn}
      name='btnback'
      size='small'
      variant='text'
      type='button'
      onClick={action}
    >
      Voltar
    </Button>
  )

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} steps={getSteps} totalSteps={3} />
      <>
        <div className={classes.instructions}>
          {segments.isFetching || contents.isFetching ? (
            <CircularProgress color='secondary' />
          ) : (
            <Form
              initialvalues={initialValues}
              onSubmit={handleSubmit(onSubmit)}
            >
              {getStepContent(activeStep)}
            </Form>
          )}
        </div>
      </>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('createThemeForm')
  const dirtyForms = getFormNames()(state).filter(form => isDirty(form)(state))
  const { initialValues } = props
  const themeCoverId = selector(state, 'cover_id')
  const themeModuleId = selector(state, 'theme_module_id')
  const initValues = {
    title: initialValues?.title,
    description: initialValues?.description,
    skill_ids:
      initialValues &&
      initialValues.skill_ids &&
      initialValues.skill_ids.map(skill => String(skill)),
    theme_module_id:
      (initialValues && initialValues.theme_module_id) || themeModuleId,
    cover_id: (initialValues && initialValues.cover_id) || themeCoverId,
    contents_order: initialValues && initialValues.contents_order
  }
  return {
    dirtyForms,
    initialValues: initValues,
    title: selector(state, 'title'),
    description: selector(state, 'description'),
    skillsValue: selector(state, 'skill_ids'),
    themeCoverId: themeCoverId,
    themeModuleId: selector(state, 'theme_module_id'),
    contents_order: selector(state, 'contents_order'),
    segmentValue: selector(state, 'segment_id')
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  change: (field, data) => dispatch(change('createThemeForm', field, data)),
  submit: () => dispatch(submit('createThemeForm')),
  destroy: formname => dispatch(destroy(formname)),
  getSegmentByModuleRSPA: data => dispatch(getSegmentByModuleRSPA.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'createThemeForm',
    validate,
    touchOnChange: true,
    touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {
      const currentTheme =
        (props.currentTheme?.item && props.currentTheme?.item[0]) ||
        props.currentTheme?.item
      try {
        if (currentTheme || props.isEditing) {
          if (!props.pristine) {
            await props.editTheme({ id: currentTheme.id, data: { ...values } })
            props.initialValues &&
              props.initialValues.contents_order?.length > 0 &&
              props.initialValues.contents_order.map(
                content =>
                  values.contents_order?.findIndex(
                    x => String(x) === String(content)
                  ) === -1 &&
                  props.editContent({
                    id: content,
                    data: { theme_id: null }
                  })
              )
            if (
              props.initialValues &&
              props.initialValues.theme_module_id !== null &&
              values.theme_module_id === null
            ) {
              const module =
                props.currentTheme?.included &&
                props.currentTheme.included.filter(
                  include => include.type === 'theme_modules'
                )
              module &&
                module[0] &&
                props.editModule({
                  id: props.initialValues.theme_module_id,
                  data: {
                    themes_order:
                      module[0].attributes &&
                      module[0].attributes.themes_order?.filter(
                        themeId => String(themeId) !== currentTheme.id
                      )
                  }
                })
            }
            (await values.contents_order) &&
              values.contents_order.map(
                content =>
                  content &&
                  props.editContent({
                    id: content,
                    data: { theme_id: currentTheme.id }
                  })
              )
          }
        } else {
          Promise.all([
            values.title && props.createTheme(values),
            values.contents_order &&
              values.contents_order.map(content =>
                props.editContent({
                  id: content,
                  data: { theme_id: currentTheme.id }
                })
              )
          ])
        }
      } catch (err) {
        console.error(err)
      }
    }
  })(CreateThemeForm)
)
