import { createAction } from '@reduxjs/toolkit'
import { createPromiseAction } from 'redux-saga-promise-actions'
import * as types from './types'

export const getTeachers = createAction(types.GET_TEACHERS)
export const getTeachersSuccessful = createAction(types.GET_TEACHERS_SUCCESSFUL)
export const getTeachersFailure = createAction(types.GET_TEACHERS_FAILURE)

export const editTeacher = createAction(types.EDIT_TEACHER)
export const editTeacherSuccessful = createAction(types.EDIT_TEACHER_SUCCESSFUL)
export const editTeacherFailure = createAction(types.EDIT_TEACHER_FAILURE)

export const getTeacherById = createAction(types.GET_TEACHER_BY_ID)
export const getTeacherByIdSuccessful = createAction(
  types.GET_TEACHER_BY_ID_SUCCESSFUL
)
export const getTeacherByIdFailure = createAction(
  types.GET_TEACHER_BY_ID_FAILURE
)

export const removeTeacherSubjects = createAction(types.REMOVE_TEACHER_SUBJECT)
export const removeTeacherSubjectsSuccessful = createAction(
  types.REMOVE_TEACHER_SUBJECT_SUCCESSFUL
)
export const removeTeacherSubjectsFailure = createAction(
  types.REMOVE_TEACHER_SUBJECT_FAILURE
)

export const removeClassroomTeachers = createAction(
  types.REMOVE_CLASSROOM_TEACHERS
)
export const removeClassroomTeachersSuccessful = createAction(
  types.REMOVE_CLASSROOM_TEACHERS_SUCCESSFUL
)
export const removeClassroomTeachersFailure = createAction(
  types.REMOVE_CLASSROOM_TEACHERS_FAILURE
)

export const getDeletedTeachers = createAction(types.GET_DELETED_TEACHERS)
export const getDeletedTeachersSuccessful = createAction(
  types.GET_DELETED_TEACHERS_SUCCESSFUL
)
export const getDeletedTeachersFailure = createAction(
  types.GET_DELETED_TEACHERS_FAILURE
)

export const restoreTeacherRSPA = createPromiseAction('rspa: RESTORE_TEACHER')()
