import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  items: [],
  isFetching: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  currentUser: {},
  pagination: {
    total: 0,
    pageCount: 1
  }
}

const users = createReducer(initialState, {
  'users/GET_USERS_HISTORY': (state, action) => ({
    ...state,
    currentItem: {},
    isFetching: true,
    isError: false,
    isSuccess: false,
    errorMessage: ''
  }),
  'users/GET_USERS_HISTORY_SUCCESSFUL': (state, action) => ({
    ...state,
    items: action.payload.data,
    pagination: {
      total: action.payload.meta.total_count,
      pageCount: action.payload.meta.page_count
    },
    isFetching: false,
    isSuccess: true,
    isError: false,
    errorMessage: ''
  }),
  'users/GET_USERS_HISTORY_FAILURE': (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    isSuccess: false,
    errorMessage: action.payload
  }),
  'users/GET_USER_BY_ID': (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    isSuccess: false,
    currentUser: {}
  }),
  'users/GET_USER_BY_ID_SUCCESSFUL': (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    isSuccess: true,
    currentUser: action.payload.data
  }),
  'users/GET_USER_BY_ID_FAILURE': (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    isSuccess: false,
    currentUser: {},
    errorMessage: action.payload
  }),
  'users/CLEAR_CURRENT_USER': (state, action) => ({
    ...initialState
  })
})

export default users
