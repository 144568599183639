import jsonApi from '../../utils/jsonApi'
import http from '../../utils/http'

const reportTemplateModel = 'report_template'
const tagModal = 'tags'
const reportStudentsModel = 'report_student'
const reportModel = 'report'
const reportOptionsModel = 'report_question'
const reportStudentAnswers = 'report_student_answer'

export const fetchReports = ({ page, params }) => {
  return jsonApi
    .findAll(reportTemplateModel, {
      params: {
        ...params,
        'page[number]': page || 1,
        include: 'classroom,user'
      }
    })
    .then(res => res)
}

export const createReportFile = async data => {
  return http
    .post('/report_files', data, { 'Content-Type': 'multipart/form-data' })
    .then(res => res.data)
}

export const editReportFile = async data => {
  return http
    .put(`/report_files/${data.id}`, {
      data: data.data
    })
    .then(res => res.data)
}
export const deleteReportFile = async id => {
  return http.delete(`/report_files/${id}`).then(res => res)
}

export const getGeneratedReport = data => {
  return http
    .get(`/reports`, {
      params: {
        ...data.params,
        'q[report_template_id_eq]': data.id,
        'q[s]': 'created_at desc',
        include: 'report_template'
      }
    })
    .then(res => res.data)
}

export const generateReportsRequest = data => {
  return http.post('/reports', { data }).then(res => res.data)
}

export const fetchReportById = ({ id, params }) => {
  return jsonApi
    .find(reportModel, id, {
      params: {
        ...params,
        include: 'user'
      }
    })
    .then(res => res.data)
}

export const fetchReportTemplateById = ({ id, params }) => {
  return jsonApi
    .find(reportTemplateModel, id, {
      params: {
        ...params,
        include:
          'questions,questions.tag,classroom,tags,tags.tag_options,classroom.grade'
      }
    })
    .then(res => res.data)
}

export const createReport = data => {
  return jsonApi.create(reportTemplateModel, data).then(res => res.data)
}

export const editReport = data => {
  return jsonApi
    .put(reportTemplateModel, {
      id: data.id,
      ...data.data
    })
    .then(res => res.data)
}

export const fetchTags = ({ page, params }) => {
  return jsonApi
    .findAll(tagModal, {
      params: {
        ...params,
        'page[number]': page || 1
      }
    })
    .then(res => res)
}

export const fetchReportStudentById = ({ id }) => {
  return jsonApi
    .find(reportStudentsModel, id, {
      params: {
        include: 'user,child,report.report_template.classroom,report_files'
      }
    })
    .then(res => res.data)
}

export const fetchReportStudents = ({ page, params }) => {
  return jsonApi
    .findAll(reportStudentsModel, {
      params: {
        ...params,
        'page[number]': page || 1,
        'q[s]': 'created_at desc',
        include: 'child,views.user,report_files'
      }
    })
    .then(res => res)
}

export const fetchReportStudentAnswersById = ({ id, page }) => {
  return jsonApi
    .findAll(reportStudentAnswers, {
      params: {
        'q[report_student_id_eq]': id,
        'page[number]': page || 1,
        include: 'tag,subject,user'
      }
    })
    .then(res => res)
}

export const fetchReportOptionsById = ({ id, page }) => {
  return jsonApi
    .findAll(reportOptionsModel, {
      params: {
        'q[report_template_reports_id_eq]': id,
        'page[number]': page || 1,
        include: 'tag.tag_options,tag.subjects'
      }
    })
    .then(res => res)
}

export const fetchLastTeacherByReport = reportId => {
  return http
    .get('report_students', {
      params: {
        'q[report_id_eq]': reportId,
        include: 'user'
      }
    })
    .then(res => {
      return res
    })
}

export const exportReport = data => {
  return http
    .post(
      '/report_student_answers/create_spreadsheet',
      { data },
      {
        responseType: 'blob'
      }
    )
    .then(res => res)
}

export const createReportStudent = data => {
  return jsonApi.create(reportStudentsModel, data).then(res => res.data)
}

export const createReportStudentAnswers = data => {
  return jsonApi.create(reportStudentAnswers, data).then(res => res.data)
}

export const editReportStudentAnswers = data => {
  return jsonApi
    .put(reportStudentAnswers, { id: data.id, ...data })
    .then(res => res.data)
}

export const editReportStudent = data => {
  return jsonApi
    .put(reportStudentsModel, { id: data.id, ...data.data })
    .then(res => {
      return res
    })
}

export const fetchTagOptions = ({ page, params }) => {
  return jsonApi
    .findAll(tagModal, {
      params: {
        ...params,
        'page[number]': page || 1
      }
    })
    .then(res => res)
}
