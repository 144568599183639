import jsonApi, { multipartInstance } from '../../utils/jsonApi'
import http from '../../utils/http'

export const fetchUtils = ({ page, params }) => {
  return jsonApi
    .findAll('utilities', {
      params: {
        ...params,
        'page[number]': page || 1,
        'q[s]': 'created_at desc',
        include:
          'school,destinations,destinations.classroom.grade,classroom,grade,destinations.child,files'
      }
    })
    .then(res => res)
}

export const fetchUtilById = ({ id }) => {
  return jsonApi
    .find('utilities', id, {
      params: {
        include:
          'destinations,destinations.classroom,school,destinations.classroom.grade,classroom,grade,destinations.child,files'
      }
    })
    .then(res => {
      return res.data
    })
}

export const deleteUtilityFile = ({ id }) => {
  return http.delete(`/utility_files/${id}`).then(res => res.data)
}

export const createUtil = data => {
  return multipartInstance
    .post('/utilities', data, { 'Content-Type': 'multipart/form-data' })
    .then(res => {
      return res.data
    })
}

export const createBulletin = data => {
  return multipartInstance
    .post('/utilities/send_bulletins', data, {
      'Content-Type': 'multipart/form-data'
    })
    .then(res => {
      return res.data
    })
}

export const editWrapper = ({ id, data }) => {
  return http
    .put(`/utilities/update_bulletins/${id}`, data, {
      'Content-Type': 'multipart/form-data'
    })
    .then(res => {
      return res.data
    })
}

export const deleteUtil = id =>
  jsonApi.destroy('utilities', id).then(res => res.data)

//   export const createUtilDestinations = data =>
//     jsonApi.create('util_destination', data).then(res => res.data)

export const fetchUtilDestinationsById = ({ id, page }) => {
  return http
    .get('/utility_destinations', {
      params: {
        'q[util_id_eq]': id,
        'page[number]': page || 1,
        'page[size]': 20
        // include: 'user'
      }
    })
    .then(res => res.data)
}

export const fetchUtilSchoolDestination = ({ id, page }) => {
  return http
    .get('/classroom_children', {
      params: {
        'q[classroom_school_id_eq]': id,
        'page[number]': page || 1,
        'page[size]': 20,
        include: 'child'
      }
    })
    .then(res => res.data)
}

export const fetchUtilClassroomDestination = async ({ id, page }) => {
  const res = await http.get('/classroom_children', {
    params: {
      'q[classroom_id_eq]': id,
      'page[number]': page || 1,
      'page[size]': 20,
      include: 'child.parents'
    }
  })
  return res.data
}

export const editUtil = ({ id, data }) => {
  return http
    .put(`/utilities/${id}`, data, { 'Content-Type': 'multipart/form-data' })
    .then(res => res.data)
}

export const fetchChildrenSchool = ({ params, page }) => {
  return jsonApi
    .findAll('children', {
      params: {
        ...params,
        'page[number]': page || 1,
        'page[size]': 20
      }
    })
    .then(res => res)
}

export const fetchParentsSchool = ({ params, page }) => {
  return jsonApi
    .findAll('child_parents', {
      params: {
        ...params,
        'page[number]': page || 1,
        'page[size]': 20,
        include: 'parent'
      }
    })
    .then(res => res)
}

export const fetchTeachersSchool = ({ params, page }) => {
  return jsonApi
    .findAll('classroom_teachers', {
      params: {
        ...params,
        'page[number]': page || 1,
        'page[size]': 20,
        include: 'teacher'
      }
    })
    .then(res => res)
}

export const fetchDestinationSchool = async ({ params, page }) => {
  const res = await http.get('/classrooms', {
    params: {
      ...params,
      'page[number]': page || 1,
      'page[size]': 20,
      include: 'teachers,children.parents'
    }
  })
  return res.data
}

export const fetchDestinationsUtils = ({ params, endpoint }) => {
  return jsonApi
    .findAll(endpoint, {
      params: {
        ...params,
        'page[size]': 20
      }
    })
    .then(res => res)
}

export const fetchAllDestinationsUtils = ({ params, endpoint }) => {
  return http
    .get(endpoint, {
      params: {
        ...params,
        'page[size]': 20
      }
    })
    .then(res => res)
}
