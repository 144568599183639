import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'

import { LinearProgress } from '@material-ui/core'
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Pagination from '@material-ui/lab/Pagination'
import CircularProgress from '@material-ui/core/CircularProgress'
import { backToThePage } from '../../store/pagination/actions'

import Collapse from '@material-ui/core/Collapse'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
const useStyles = makeStyles(theme => ({
  table: {
    [theme.breakpoints.up('lg')]: {
      minWidth: 700
    }
  },
  pagination: {
    float: 'right',
    paddingTop: '15px',
    [theme.breakpoints.down('426')]: {
      float: 'unset',
      '& .MuiPagination-ul': {
        flexWrap: 'nowrap'
      }
    }
  },
  rowHead: {
    '& th.MuiTableCell-root': {
      [theme.breakpoints.down('376')]: {
        padding: '5px !important'
      }
    }
  },
  firstCell: {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px'
  },
  lastCell: {
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px'
  },
  childCellArrow: {
    width: '10px',
    '&:hover': {
      backgroundColor: '#FFFFFF'
    }
  },
  arrowIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s ease'
  },
  arrowRotated: {
    transform: 'rotate(180deg)'
  },
  childCell: {
    border: 'none'
  },
  childRow: {
    backgroundColor: '#FFFFFF'
  }
}))

const CustomTable = ({
  columns,
  firstPageAfterSearch,
  data,
  pagination,
  params,
  hasSchoolId,
  isFetching,
  fetchItems,
  withoutTableHead,
  loadMoreData,
  hasNextPage,
  whiteHeader,
  border,
  noDataAlert = 'Sem dados',
  bodyBorder,
  paramId,
  isAdmin,
  from,
  backToThePage,
  pageToGoBack,
  headCellRadius,
  headColor,
  columnsPointer,
  classRoot,
  getChildren,
  childs,
  childrenIsFetching,
  hasChild,
  childrenPagination,
  childColumns,
  noDataChild = 'Sem dados',
  hiddeCellToExpandChild,
  currentParentId
}) => {
  const classes = useStyles()
  const [page, setPage] = useState(1)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('426'))
  const [childItems, setChildItems] = React.useState([])
  const [expandedId, setExpandedId] = React.useState(-1)

  // controll the child expanded by the parent component
  React.useEffect(
    () => {
      if (hiddeCellToExpandChild) {
        handleSetOpen(currentParentId)
      }
    },
    [currentParentId]
  )

  React.useEffect(
    () => {
      childs && setChildItems(prevState => childs)
    },
    [childs]
  )

  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:hover': {
        backgroundColor: 'rgba(173, 184, 204, 0.1)'
      },
      '& td': {
        borderBottom: bodyBorder || '1px solid #EFEFEF'
      }
    }
  }))(TableRow)

  React.useEffect(
    () => {
      if (pageToGoBack.canGoBack !== true) {
        if (from) backToThePage({ current: page, from })
      } else {
        // setTimeout is necessary because the first request in pages is with default params (page = 1)
        setTimeout(() => {
          setPage(+pageToGoBack.current)
          paramId
            ? fetchItems({
                id: paramId,
                params: {
                  'page[number]': pageToGoBack.current,
                  hasSchoolId,
                  ...params
                }
              })
            : fetchItems({
                page: pageToGoBack.current,
                hasSchoolId,
                params,
                isAdmin
              })
          backToThePage({ ...pageToGoBack, canGoBack: false })
        }, 300)
      }
    },
    [page]
  )

  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: headColor,
      color: '#555555',
      fontWeight: 700,
      borderBottom: border || (whiteHeader ? 'none' : '1px solid #EFEFEF'),
      [theme.breakpoints.down('426')]: {
        fontSize: '12px !important',
        '& .MuiTableCell-root': {
          padding: '5px !important'
        }
      }
    },
    body: {
      fontSize: 14,
      [theme.breakpoints.down('426')]: {
        fontSize: '12px !important'
        // '& .MuiTableCell-root': {
        //   padding: '5px !important'
        // }
      }
    }
  }))(TableCell)

  const handleChange = (event, value) => {
    setPage(value)
    paramId
      ? fetchItems({
          id: paramId,
          params: { 'page[number]': value, hasSchoolId, ...params }
        })
      : fetchItems({ page: value, hasSchoolId, params, isAdmin })
  }

  React.useEffect(() => {
    if (firstPageAfterSearch && firstPageAfterSearch.current === true) {
      setPage(1)
      firstPageAfterSearch.current = false
    }
  })

  const handleSetOpen = id => {
    setChildItems([])
    id && getChildren({ id: id })
    setExpandedId(expandedId === id ? -1 : id)
  }

  return (
    <TableContainer className={classRoot} component={Paper} elevation={0}>
      <Table className={classes.table} aria-label='customized table'>
        {!withoutTableHead && (
          <TableHead>
            <TableRow className={classes.rowHead}>
              {columns.map((column, index) => (
                <StyledTableCell
                  key={column.key}
                  className={
                    headCellRadius
                      ? index === 0
                        ? classes.firstCell
                        : index === columns.length - 1 ? classes.lastCell : ''
                      : ''
                  }
                  style={{ width: column.width }}
                >
                  {column.name}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {isFetching ? (
            <StyledTableRow>
              <TableCell style={{ background: 'none' }}>
                <CircularProgress color='secondary' />
              </TableCell>
            </StyledTableRow>
          ) : data && data.length > 0 ? (
            data.map(row => {
              return (
                <React.Fragment key={row.id}>
                  <StyledTableRow>
                    {columns.map((column, index) => {
                      const showIndex = column.render && column.render(row)
                      return (
                        <StyledTableCell
                          key={`${column.key}[${row.id}]`}
                          align={column.align || 'left'}
                          style={{
                            ...(column.changeRowColor &&
                              showIndex === 'Concluído' && {
                                color: column.changeRowColor
                              }),
                            ...(columnsPointer?.includes(index) && {
                              cursor: 'pointer'
                            })
                          }}
                        >
                          {showIndex}
                        </StyledTableCell>
                      )
                    })}
                    {hasChild &&
                      !hiddeCellToExpandChild && (
                        <StyledTableCell className={classes.childCellArrow}>
                          <KeyboardArrowDownIcon
                            onClick={() => handleSetOpen(row.id)}
                            className={`${classes.arrowIcon} ${
                              expandedId === row.id ? classes.arrowRotated : ''
                            }`}
                          />
                        </StyledTableCell>
                      )}
                  </StyledTableRow>
                  <TableRow
                    style={{
                      ...(expandedId !== row.id && { display: 'none' })
                    }}
                  >
                    <TableCell colSpan={6}>
                      <Collapse
                        in={expandedId === row.id}
                        timeout='auto'
                        unmountOnExit
                      >
                        <Table
                          style={{ padding: '0px 40px 0px 40px' }}
                          aria-label='childs'
                        >
                          <colgroup>
                            <col
                              style={{
                                ...(childItems.length > 0
                                  ? { width: '35%' }
                                  : { width: '17%' })
                              }}
                            />
                            <col style={{ width: '18%' }} />
                            <col style={{ width: '32%' }} />
                            <col style={{ width: '7%' }} />
                          </colgroup>
                          <TableBody>
                            {childrenIsFetching ? (
                              <CircularProgress color='secondary' />
                            ) : childItems && childItems.length > 0 ? (
                              childItems.map((row, index, self) => {
                                return (
                                  row && (
                                    <TableRow key={row.id}>
                                      {childColumns?.map(
                                        (column, columnIndex) => {
                                          return (
                                            <TableCell
                                              key={columnIndex}
                                              className={
                                                index === self.length - 1 &&
                                                classes.childCell
                                              }
                                            >
                                              {column.render &&
                                                column.render(row)}
                                            </TableCell>
                                          )
                                        }
                                      )}
                                    </TableRow>
                                  )
                                )
                              })
                            ) : (
                              <TableCell
                                align='left'
                                style={{
                                  background: 'none',
                                  border: 'none',
                                  padding: 0
                                }}
                                colSpan={1}
                              >
                                {noDataChild}
                              </TableCell>
                            )}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })
          ) : (
            <StyledTableRow>
              <TableCell
                align='center'
                style={{ background: 'none' }}
                colSpan={columns.length}
              >
                {noDataAlert}
              </TableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
      {pagination &&
        pagination.pageCount > 1 &&
        !isFetching && (
          <Pagination
            count={pagination.pageCount}
            className={classes.pagination}
            siblingCount={matches ? undefined : 0}
            page={page}
            onChange={handleChange}
          />
        )}
      {hasNextPage && (
        <Waypoint onEnter={loadMoreData}>
          <LinearProgress color='secondary' />
        </Waypoint>
      )}
    </TableContainer>
  )
}

const mapStateToProps = (state, props) => {
  return {
    pageToGoBack: state.pagination
  }
}

const mapDispatchToProps = dispatch => ({
  backToThePage: data => dispatch(backToThePage(data))
})

CustomTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  params: PropTypes.object,
  hasSchoolId: PropTypes.bool,
  isFetching: PropTypes.bool,
  fetchItems: PropTypes.func,
  withoutTableHead: PropTypes.bool,
  whiteHeader: PropTypes.bool,
  headCellRadius: PropTypes.bool,
  headColor: PropTypes.string,
  border: PropTypes.string,
  bodyBorder: PropTypes.string,
  loadMoreData: PropTypes.func,
  hasNextPage: PropTypes.bool,
  noDataAlert: PropTypes.any,
  firstPageAfterSearch: PropTypes.object,
  paramId: PropTypes.number,
  isAdmin: PropTypes.bool,
  from: PropTypes.string,
  backToThePage: PropTypes.func,
  pageToGoBack: PropTypes.object,
  columnsPointer: PropTypes.array,
  classRoot: PropTypes.string,
  getChildren: PropTypes.func,
  childs: PropTypes.array,
  childrenIsFetching: PropTypes.bool,
  hasChild: PropTypes.bool,
  childrenPagination: PropTypes.any,
  childColumns: PropTypes.array,
  noDataChild: PropTypes.any,
  hiddeCellToExpandChild: PropTypes.any,
  currentParentId: PropTypes.number
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomTable)
