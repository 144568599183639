import React, { useState } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Form, Field, formValueSelector, change } from 'redux-form'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import HeaderCard from '../card/HeaderCard'
import jsonApi from '../../utils/jsonApi'
import { showAlertMessage } from '../../store/alert/actions'
import DatePickerInput from '../shared/DatePickerInput'
import ReactSelect from '../select/ReactSelect'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 1220,
    marginBottom: '2rem',
    '& a': {
      textDecoration: 'none'
    },
    '& h3': {
      color: theme.palette.primary.main
    }
  },
  container: {
    paddingLeft: 0,
    marginTop: '2rem'
  },
  paper: {
    padding: theme.spacing(2),
    margin: 0,
    marginBottom: '2rem',
    borderRadius: '8px',
    lineHeight: '20.11px !important',
    fontSize: 16,
    '& h2': {
      color: theme.palette.primary.main
    }
  },
  paperLeftSide: {
    minWidth: 300,
    paddingLeft: '1.5rem',
    maxWidth: '67vw',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '80vw'
    }
  },
  cycleField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .select__control': {
      width: 300
    },
    '& svg': {
      cursor: 'pointer',
      fontSize: 18,
      marginLeft: '1rem'
    }
  },
  contentDates: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '0.6rem',
    border: `1px dashed ${theme.palette.primary.brightest}`,
    borderRadius: 8,
    padding: '1.5rem',
    '& label': {
      '& div': {
        marginBottom: '0.7rem'
      },
      '& span:last-of-type': {
        fontSize: '2rem',
        verticalAlign: 'text-bottom',
        marginLeft: '0.8rem'
      }
    },
    '& input': {
      margin: '0 1rem'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  contentField: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '0.6rem',
    '& label': {
      '& div': {
        marginBottom: '0.7rem'
      },
      '& span:last-of-type': {
        fontSize: '2rem',
        verticalAlign: 'text-bottom',
        marginLeft: '0.8rem'
      }
    },
    '& input': {
      margin: '0 1rem'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  bull: {
    color: theme.palette.primary.dark
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  sendBtn: {
    backgroundColor: '#386093',
    color: '#FFFF',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    textAlign: 'end',
    marginBottom: '2rem',
    '& .Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    }
  }
}))

const required = value => (value ? undefined : 'Campo Obrigatório *')

const ThemeScheduleForm = ({
  grade,
  cycle,
  handleSubmit,
  history,
  initialValues,
  startDateValue,
  onSubmit,
  pristine,
  submitting,
  valid,
  selectedSchool,
  touch,
  change,
  cycleValue,
  ...props
}) => {
  const classes = useStyles()
  const [cyclesOptions, setCyclesOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const selectTheme = props.initValues?.theme

  React.useEffect(() => {
    getCycles()
  }, [])

  const getCycles = async () => {
    return jsonApi
      .findAll('cycle', {
        params: {
          'page[size]': 20
        }
      })
      .then(res => {
        const options =
          res.data &&
          res.data.map(data => {
            return { label: data.name, value: data.id }
          })
        setCyclesOptions(options)
        setIsLoading(false)
      })
  }

  const handleChangeCycle = (event, input) => {
    if (event) {
      change(input.name, event.value)
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <HeaderCard>
          <div style={{ margin: 'auto 1rem' }}>
            <h1>
              {grade?.name} {selectedSchool?.id && ' - ' + selectedSchool.name}
            </h1>
            <p style={{ paddingTop: 0 }}>{grade?.segment?.name}</p>
          </div>
        </HeaderCard>
        <Grid item xs={12} container className={classes.container}>
          <Grid item xs={12}>
            <Paper
              className={`${classes.paper} ${classes.paperLeftSide}`}
              elevation={0}
            >
              <div style={{ marginBottom: '1rem' }}>
                <h2>{selectTheme?.theme || selectTheme?.title}</h2>
                <p>
                  Módulo:{' '}
                  {selectTheme?.module || selectTheme?.theme_module?.title}
                </p>
                {!isLoading && (
                  <div className={classes.contentField}>
                    <div>
                      <label htmlFor='cycle_id'>
                        <div>
                          <span>Ciclo</span>
                          <span className={classes.bull}>&bull;</span>
                        </div>
                      </label>
                      <div className={classes.cycleField}>
                        <Field
                          component={ReactSelect}
                          id='cycle_id'
                          name='cycle_id'
                          defaultValue={cycleValue}
                          handleChange={handleChangeCycle}
                          placeholder='Selecione um ciclo'
                          options={cyclesOptions}
                          touch={touch}
                          validate={[required]}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.contentDates}>
                <div>
                  <label htmlFor='start_at'>
                    <div>
                      <span>Data inicio</span>
                      <span className={classes.bull}>&bull;</span>
                    </div>
                  </label>
                  <Field
                    component={DatePickerInput}
                    id='start_at'
                    name='start_at'
                    validate={[required]}
                  />
                </div>
                <div>
                  <label htmlFor='end_at'>
                    <div>
                      <span>Data fim</span>
                      <span className={classes.bull}>&bull;</span>
                    </div>
                  </label>
                  <Field
                    component={DatePickerInput}
                    id='end_at'
                    name='end_at'
                    initialFocusedDate={startDateValue}
                    minDate={startDateValue}
                    validate={[required]}
                  />
                </div>
              </div>
            </Paper>
            <div className={classes.btnGroup}>
              <Button
                className={classes.backBtn}
                variant='outlined'
                type='button'
                onClick={() => history.goBack()}
              >
                Voltar
              </Button>
              <Button
                className={classes.sendBtn}
                disabled={!valid || pristine || submitting}
                type='submit'
              >
                Publicar
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('themeScheduleForm')
  const school =
    (state.school?.currentSchool && state.school.currentSchool.school_id) ||
    state.school?.selectedItem?.id
  const noTemplate = props.history.location.state?.noTemplate
  const initValues = {
    start_at: props.initValues.schedule && props.initValues.schedule.start_at,
    end_at: props.initValues.schedule && props.initValues.schedule.end_at,
    schedule_template_id:
      noTemplate ||
      (props.initValues.schedule &&
        !props.initValues.schedule.schedule_template_id)
        ? null
        : props.grade.schedule_templates &&
          props.grade.schedule_templates[0] &&
          props.grade.schedule_templates[0].id,
    grade_id: props.grade?.id,
    theme_id: props.initValues.theme?.id,
    school_id:
      school ||
      (props.initValues.schedule && props.initValues.schedule.school_id),
    cycle_id: props.cycle?.id || props.history.location?.state?.cycle?.id
  }
  return {
    initialValues: initValues,
    startDateValue: selector(state, 'start_at'),
    cycleValue: selector(state, 'cycle_id')
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('themeScheduleForm', field, data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'themeScheduleForm',
    touchOnChange: true,
    touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {
      const newValues = {
        ...values,
        cycle_id: values.cycle_id?.value || values.cycle_id
      }
      try {
        if (props.initValues?.schedule) {
          jsonApi
            .put('theme_schedule', {
              id: props.initValues.schedule.id,
              ...newValues
            })
            .then(res => {
              props.alert('Sucesso ao publicar tema.', 'success')
              props.history.push(`/theme-schedule/grades/${values.grade_id}`)
            })
            .catch(err => {
              alert('Falha ao publicar tema.', 'error')
              console.error(err)
            })
        } else {
          jsonApi
            .create('theme_schedule', newValues)
            .then(res => {
              props.alert('Sucesso ao publicar o tema.', 'success')
              props.history.push(`/theme-schedule/grades/${values.grade_id}`)
            })
            .catch(err => {
              alert('Falha ao publicar o tema.', 'error')
              console.error(err)
            })
        }
      } catch (error) {
        console.error(error)
      }
    }
  })(ThemeScheduleForm)
)
