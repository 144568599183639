import jsonApi from '../../utils/jsonApi'
import http from '../../utils/http'

const usersEndpoint = 'school_coordinator'

export const fetchCoordinatorById = id => {
  return jsonApi
    .find(usersEndpoint, id, {
      params: {
        include: 'coordinator.classroom_coordinators,school'
      }
    })
    .then(res => res.data)
}

export const deleteClassroomCoordinator = id => {
  return jsonApi.destroy('classroom_coordinator', id).then(res => res.data)
}

export const deleteSchoolCoodinator = id => {
  return jsonApi.destroy('school_coordinator', id).then(res => res.data)
}

export const getClassroomCoordinators = userId => {
  return jsonApi
    .findAll('classroom_coordinator', {
      params: {
        'q[user_id_eq]': userId
      }
    })
    .then(res => res.data)
}

export const fetchDeletedCoordinators = payload => {
  const { page, ...rest } = payload
  return jsonApi
    .customRequest(
      'GET',
      'school_coordinators/only_deleted',
      usersEndpoint,
      undefined,
      {
        params: {
          'page[number]': page || 1,
          include: 'coordinator',
          ...rest?.params
        }
      }
    )
    .then(res => {
      return res
    })
}

export const restoreCoordinator = id => {
  return http.put(`/school_coordinators/restore/${id}`).then(res => res)
}

export const fetchCoordinatorsService = ({ page, params }) => {
  return jsonApi
    .findAll(usersEndpoint, {
      params: {
        ...params,
        'q[s]': 'created_at desc',
        'page[number]': page || 1,
        'page[size]': 5,
        include: 'coordinator.classroom_coordinators.classroom,school'
      }
    })
    .then(res => res)
}
