import { createReducer } from '@reduxjs/toolkit'
import {
  SIGN_IN,
  SIGN_IN_SUCCESSFUL,
  SIGN_IN_FAILURE,
  LOGOUT_SUCCESSFUL
} from './actions'
import * as types from './constants'

const initialState = {
  user: {},
  isLogged: false,
  isFetching: false,
  isError: false,
  errorMessage: '',
  credentials: {},
  currentOccupation: []
}

const auth = createReducer(initialState, {
  [SIGN_IN]: (state, action) => ({
    ...state,
    isFetching: true,
    isLogged: false,
    isError: false,
    errorMessage: ''
  }),
  [SIGN_IN_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isLogged: true,
    isError: false,
    user: action.payload.userData.data,
    errorMessage: '',
    credentials: action.payload.userData.headers,
    currentOccupation: action.payload.currentOccupation
  }),
  [SIGN_IN_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isLogged: false,
    user: {},
    isError: true,
    errorMessage: action.payload.data,
    credentials: {}
  }),
  [types.SWITCH_USER_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentOccupation: action.payload
  }),
  [LOGOUT_SUCCESSFUL]: (state, action) => ({
    ...initialState
  })
})

export default auth
