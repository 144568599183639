import React from 'react'
import { connect } from 'react-redux'
// import { CircularProgress } from '@material-ui/core'
// import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import SearchModulesForm from '../components/form/SearchModulesForm'
import NewCreateModuleForm from '../components/form/NewCreateModuleForm'

const NewModulesPage = ({
  componentAttribute,
  match,
  currentMaterial,
  isFetching,
  userOccupations
}) => {
  const history = useHistory()
  if (componentAttribute?.isCreate) {
    return <NewCreateModuleForm history={history} />
  }
  return <SearchModulesForm />
}

const mapStateToProps = state => {}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(NewModulesPage)
