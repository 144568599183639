import React from 'react'
import { connect } from 'react-redux'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'

import { hideAlertMessage } from '../../store/alert/actions'

const mapDispatchToProps = {
  hideAlertMessage
}

const mapStateToProps = ({ alert }) => {
  return {
    alertMessage: alert.alertMessage
  }
}

function Alert (props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  alert: {
    minWidth: 250
  }
}))

function CustomizedSnackbars ({ alertMessage, hideAlertMessage }) {
  const { open, message, severity } = alertMessage
  const classes = useStyles()

  // severity = error | warning | info | success
  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={6000}
        onClose={hideAlertMessage}
      >
        <Alert className={classes.alert} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomizedSnackbars)
