import React from 'react'
import SVG from 'react-inlinesvg'
import warningSVG from '../../assets/icons/warning.svg'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#F4A87C',
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '15px',
    // [theme.breakpoints.down('426')]: {
    //   flexDirection: 'column',
    // },
    [theme.breakpoints.down('400')]: {
      '& div': {
        '& svg': {
          width: '80px'
        }
      }
    }
  },
  title: {
    color: '#ffff',
    fontSize: '40px',
    lineHeight: '48px',
    textAlign: 'center',
    fontWeight: '700',
    maxWidth: '480px',
    [theme.breakpoints.down('426')]: {
      fontSize: '24px',
      lineHeight: 'unset'
    },
    [theme.breakpoints.down('380')]: {
      fontSize: '18px'
    }
  }
}))
const ShowPendingAnnouncement = props => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <SVG style={{ height: '120px' }} src={warningSVG} />
        <div style={{ maxWidth: '580px' }}>
          <p className={classes.title}>Seu comunicado está sendo moderado!</p>
        </div>
      </div>
    </div>
  )
}

export default ShowPendingAnnouncement
