import React from 'react'
import { reduxForm, Form, Field } from 'redux-form'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import DropzoneInput from '../shared/DropzoneInput'
import Button from '../button/Button'
import { showAlertMessage } from '../../store/alert/actions'

const useStyles = makeStyles(theme => ({
  btnStyle: {
    alignSelf: 'center',
    marginBottom: '2rem',
    '& button': {
      height: 40,
      marginTop: '1.5rem',
      minWidth: 150
    }
  }
}))

const UploadCoversForm = ({
  handleSubmit,
  isSuccess,
  pristine,
  submitting
}) => {
  const classes = useStyles()
  const history = useHistory()

  if (isSuccess) {
    history.go(0)
  }
  return (
    <Form onSubmit={handleSubmit}>
      <div>
        <Field
          accept='image/jpeg, image/png, image/svg+xml'
          component={DropzoneInput}
          maxSize={204800}
          name='image'
          type='file'
        />
      </div>
      <div className={classes.btnStyle}>
        <Button
          color='secondary'
          type='submit'
          disabled={pristine || submitting}
        >
          salvar
        </Button>
      </div>
    </Form>
  )
}

const mapDispatchToProps = dispatch => ({
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    )
})

export default connect(null, mapDispatchToProps)(
  reduxForm({
    form: 'uploadCoversForm',
    ouchOnChange: true,
    touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {
      props.uploadCovers(values)
    }
  })(UploadCoversForm)
)
