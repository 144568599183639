import { createAction } from '@reduxjs/toolkit'
import * as types from './types'

export const getSchoolContentCreatorsById = createAction(
  types.GET_SCHOOL_CONTENT_CREATORS_BY_ID
)
export const getSchoolContentCreatorsByIdSuccessful = createAction(
  types.GET_SCHOOL_CONTENT_CREATORS_BY_ID_SUCCESSFUL
)
export const getSchoolContentCreatorsByIdFailure = createAction(
  types.GET_SCHOOL_CONTENT_CREATORS_BY_ID_FAILURE
)

export const getSchoolAdministratorById = createAction(
  types.GET_SCHOOL_ADMINISTRATOR_BY_ID
)
export const getSchoolAdministratorByIdSuccessful = createAction(
  types.GET_SCHOOL_ADMINISTRATOR_BY_ID_SUCCESSFUL
)
export const getSchoolAdministratorByIdFailure = createAction(
  types.GET_SCHOOL_ADMINISTRATOR_BY_ID_FAILURE
)

export const getSchoolTeacherById = createAction(types.GET_SCHOOL_TEACHER_BY_ID)
export const getSchoolTeacherByIdSuccessful = createAction(
  types.GET_SCHOOL_TEACHER_BY_ID_SUCCESSFUL
)
export const getSchoolTeacherByIdFailure = createAction(
  types.GET_SCHOOL_TEACHER_BY_ID_FAILURE
)

export const getSchoolCoordinatorById = createAction(
  types.GET_SCHOOL_COORDINATOR_BY_ID
)
export const getSchoolCoordinatorByIdSuccessful = createAction(
  types.GET_SCHOOL_COORDINATOR_BY_ID_SUCCESSFUL
)
export const getSchoolCoordinatorByIdFailure = createAction(
  types.GET_SCHOOL_COORDINATOR_BY_ID_FAILURE
)

export const updateLayoutSchool = createAction(types.UPDATE_LAYOUT_SCHOOL)
export const updateLayoutSchoolSuccessful = createAction(
  types.UPDATE_LAYOUT_SCHOOL_SUCCESSFUL
)
export const updateLayoutSchoolFailure = createAction(
  types.UPDATE_LAYOUT_SCHOOL_FAILURE
)

export const getSchools = createAction(types.GET_SCHOOLS)
export const getSchoolsSuccessful = createAction(types.GET_SCHOOLS_SUCCESSFUL)
export const getSchoolsFailure = createAction(types.GET_SCHOOLS_FAILURE)

export const switchSchool = createAction(types.SWITCH_SCHOOL)
export const switchSchoolSuccessful = createAction(
  types.SWITCH_SCHOOL_SUCCESSFUL
)
export const switchSchoolFailure = createAction(types.SWITCH_SCHOOL_FAILURE)

export const getSchoolConfig = createAction(types.GET_SCHOOL_CONFIG)
export const getSchoolConfigSuccessFul = createAction(
  types.GET_SCHOOL_CONFIG_SUCCESSFUL
)
export const getSchoolConfigFailure = createAction(
  types.GET_SCHOOL_CONFIG_FAILURE
)

export const changeSchoolConfig = createAction(types.CHANGE_SCHOOL_CONFIG)
export const changeSchoolConfigSuccessful = createAction(
  types.CHANGE_SCHOOL_CONFIG_SUCCESSFUL
)
export const changeSchoolConfigFailure = createAction(
  types.CHANGE_SCHOOL_CONFIG_FAILURE
)

export const clearSchoolConfig = createAction('CLEAR_SCHOOL_CONFIG')

export const selectedCurrentSchool = createAction(types.SELECTED_CURRENT_SCHOOL)
export const selectedCurrentSchoolSuccessful = createAction(
  types.SELECTED_CURRENT_SCHOOL_SUCCESSFUL
)
export const clearCurrentSchool = createAction(types.CLEAR_CURRENT_SCHOOL)
