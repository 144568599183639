export const GET_MATERIALS = 'materials/GET_MATERIALS'
export const GET_MATERIALS_SUCCESSFUL = 'materials/GET_MATERIALS_SUCCESSFUL'
export const GET_MATERIALS_FAILURE = 'materials/GET_MATERIALS_FAILURE'

export const GET_MATERIAL_BY_ID = 'materials/GET_MATERIAL_BY_ID'
export const GET_MATERIAL_BY_ID_SUCCESSFUL =
  'materials/GET_MATERIAL_BY_ID_SUCCESSFUL'
export const GET_MATERIAL_BY_ID_FAILURE = 'materials/GET_MATERIAL_BY_ID_FAILURE'

export const CREATE_MATERIALS = 'materials/CREATE_MATERIALS'
export const CREATE_MATERIALS_SUCCESSFUL =
  'materials/CREATE_MATERIALS_SUCCESSFUL'
export const CREATE_MATERIALS_FAILURE = 'materials/CREATE_MATERIALS_FAILURE'

export const DELETE_MATERIAL = 'materials/DELETE_MATERIAL'
export const DELETE_MATERIAL_SUCCESSFUL = 'materials/DELETE_MATERIAL_SUCCESSFUL'
export const DELETE_MATERIAL_FAILURE = 'materials/DELETE_MATERIAL_FAILURE'

export const EDIT_MATERIAL = 'materials/EDIT_MATERIAL'
export const EDIT_MATERIAL_SUCCESSFUL = 'materials/EDIT_MATERIAL_SUCCESSFUL'
export const EDIT_MATERIAL_FAILURE = 'materials/EDIT_MATERIAL_FAILURE'

export const SET_CURRRENT_MATERIAL = 'materials/SET_CURRRENT_MATERIAL'
export const CLEAN_CURRRENT_MATERIAL = 'materials/CLEAN_CURRRENT_MATERIAL'
