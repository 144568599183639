import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import Card from '../card/Card'
import Label from '../label/Label'

const useStyles = makeStyles(theme => ({
  root: {
    '& a': {
      textDecoration: 'none'
    },
    '& h3': {
      color: theme.palette.primary.main
    }
  },
  cardButton: {
    textTransform: 'capitalize',
    '& div.MuiPaper-root': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 10
      }
    },
    '& div': {
      maxWidth: 300,
      height: 100,
      paddingTop: '1rem'
    }
  },
  list: {
    display: 'grid',
    gridGap: 30,
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(4, 1fr)'
    }
  },
  label: {
    marginBottom: '1.5rem'
  }
}))

const SchoolsListView = ({ schoolsList, selectedCurrentSchool }) => {
  const classes = useStyles()
  const history = useHistory()
  const handleClick = id => {
    const goToGrades = history.location.pathname.includes('theme-schedule')
    const selected = schoolsList.find(school => school.id === id)
    selected && selectedCurrentSchool(selected)
    history.push(
      goToGrades
        ? `${history.location.pathname}/${id}/grades`
        : `${history.location.pathname}/${id}`
    )
  }
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} className={classes.label}>
        <Label>Escolas</Label>
      </Grid>
      <Grid item xs={12} className={classes.list}>
        {schoolsList &&
          schoolsList.map(school => (
            <div
              className={classes.cardButton}
              key={school.id}
              value={school.id}
              onClick={() => handleClick(school.id)}
            >
              <Card color='secondary'>
                <CardContent>
                  <Typography
                    className={classes.title}
                    variant='h5'
                    component='h4'
                  >
                    {school.name}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          ))}
      </Grid>
    </Grid>
  )
}

export default SchoolsListView
