import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    marginBottom: 14
  },
  input: {
    marginBottom: '2px !important',
    height: props => (props.isForModule ? '48px !important' : ''),
    width: props => (props.isForModule ? '100% !important' : '')
  },
  error: {
    color: theme.palette.danger.main,
    fontSize: '0.8rem'
  }
}))

const Input = ({
  input,
  hidden,
  placeholder,
  type,
  autoComplete,
  meta: { touched, error },
  isForModule
}) => {
  const classes = useStyles({ isForModule })
  return (
    <div>
      <div className={classes.root}>
        <input
          {...input}
          type={type}
          placeholder={placeholder}
          className={classes.input}
          hidden={hidden}
          autoComplete={autoComplete}
        />
        {touched && error && <span className={classes.error}>{error}</span>}
      </div>
    </div>
  )
}

export default Input
