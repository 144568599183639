import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

import { colors } from '../../utils/constants'
import { numberFromText } from '../../utils/helpers'
import CustomTable from '../table/CustomTable'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    padding: '29px 35px 50px',
    color: theme.palette.text.primary,
    maxHeight: 600,
    width: 825,
    overflow: 'auto',
    '& button': {
      fontWeight: 700,
      textTransform: 'capitalize'
    },
    '& .MuiTableContainer-root': {
      height: 450
    },
    '& > div:last-of-type': {
      display: 'flex',
      justifyContent: 'flex-end',
      '& button': {
        marginTop: '1rem',
        width: 130
      }
    }
  },
  destinationsData: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      display: 'flex',
      gap: 8,
      alignItems: 'center',
      fontWeight: 700,
      '& div': {
        height: 27,
        width: 27
      }
    }
  },
  closeIcon: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '-20px',
    '& svg': {
      fill: theme.palette.text.primary,
      height: 16,
      width: 16
    }
  }
}))

const UsersList = ({
  list,
  title,
  pagination,
  fetchItem,
  handleClose,
  moreData,
  showEmail
}) => {
  const classes = useStyles()
  const [hasNextPage, setHasNextPage] = useState(true)
  const [page, setPage] = useState(1)

  const columns = [
    {
      key: 'user',
      name: 'Usuário',
      width: '35%',
      render: function render (row) {
        const name = row.name || row.label || row.user?.name
        return (
          <span key={`user[${row.id || row.value}]`}>
            <Avatar
              style={{
                backgroundColor:
                  colors[numberFromText(name.slice(0, 2)) % colors.length]
              }}
            >
              {name[0]}
            </Avatar>
            {name}{' '}
          </span>
        )
      }
    },
    {
      key: 'email',
      name: 'Email',
      width: '35%',
      render: row => {
        return row.email || row.user.email
      }
    },
    {
      key: 'relationationship',
      name: 'Relacionamento',
      width: '30%',
      render: row => {
        let related = ''
        switch (row?.viewer_role) {
          case 'school_administrators':
            related = 'Administrador'
            break
          case 'teachers':
            related = 'Professor'
            break
          case 'parents':
            related = 'Responsável'
            break
          case 'students':
            related = 'Estudante'
            break
        }
        return related
      }
    }
  ]
  const loadMoreData = () => {
    if (page >= (pagination && pagination.pageCount)) {
      return setHasNextPage(false)
    }
    if (pagination?.pageCount > page) {
      fetchItem(page + 1)
      setPage(prevState => prevState + 1)
      return
    }
    return setHasNextPage(false)
  }

  const getColumns = () => {
    const moreDataArray =
      moreData && !showEmail
        ? ['user', 'email', 'relationationship']
        : ['user', 'email']
    const columnKeys = moreData ? moreDataArray : ['user']

    return columns.filter(column => columnKeys.includes(column.key))
  }
  return (
    <div className={classes.root}>
      <div className={classes.closeIcon} onClick={handleClose}>
        <CloseRoundedIcon />
      </div>
      <h2 id='modal-title'>{title}</h2>
      <div className={classes.destinationsData}>
        <CustomTable
          columns={getColumns()}
          whiteHeader
          data={list}
          loadMoreData={loadMoreData}
          hasNextPage={hasNextPage}
        />
      </div>
    </div>
  )
}

export default UsersList
