export const GET_TEACHERS = 'classrooms/GET_TEACHERS'
export const GET_TEACHERS_SUCCESSFUL = 'classrooms/GET_TEACHERS_SUCCESSFUL'
export const GET_TEACHERS_FAILURE = 'classrooms/GET_TEACHERS_FAILURE'

export const EDIT_TEACHER = 'classrooms/EDIT_TEACHER'
export const EDIT_TEACHER_SUCCESSFUL = 'classrooms/EDIT_TEACHER_SUCCESSFUL'
export const EDIT_TEACHER_FAILURE = 'classrooms/EDIT_TEACHER_FAILURE'

export const GET_TEACHER_BY_ID = 'classrooms/GET_TEACHER_BY_ID'
export const GET_TEACHER_BY_ID_SUCCESSFUL =
  'classrooms/GET_TEACHER_BY_ID_SUCCESSFUL'
export const GET_TEACHER_BY_ID_FAILURE = 'classrooms/GET_TEACHER_BY_ID_FAILURE'

export const REMOVE_CLASSROOM_TEACHERS = 'classrooms/REMOVE_CLASSROOM_TEACHERS'
export const REMOVE_CLASSROOM_TEACHERS_SUCCESSFUL =
  'classrooms/REMOVE_CLASSROOM_TEACHERS_SUCCESSFUL'
export const REMOVE_CLASSROOM_TEACHERS_FAILURE =
  'classrooms/REMOVE_CLASSROOM_TEACHERS_FAILURE'

export const REMOVE_TEACHER_SUBJECT = 'classrooms/REMOVE_TEACHER_SUBJECT'
export const REMOVE_TEACHER_SUBJECT_SUCCESSFUL =
  'classrooms/REMOVE_TEACHER_SUBJECT_SUCCESSFUL'
export const REMOVE_TEACHER_SUBJECT_FAILURE =
  'classrooms/REMOVE_TEACHER_SUBJECT_FAILURE'

export const GET_DELETED_TEACHERS = 'classrooms/GET_DELETED_TEACHERS'
export const GET_DELETED_TEACHERS_SUCCESSFUL =
  'classrooms/GET_DELETED_TEACHERS_SUCCESSFUL'
export const GET_DELETED_TEACHERS_FAILURE =
  'classrooms/GET_DELETED_TEACHERS_FAILURE'
