import { createAction } from '@reduxjs/toolkit'
import * as types from './types'
import { createPromiseAction } from 'redux-saga-promise-actions'

export const getModules = createAction(types.GET_MODULES)
export const getModulesSuccessful = createAction(types.GET_MODULES_SUCCESSFUL)
export const getModulesFailure = createAction(types.GET_MODULES_FAILURE)
export const getModuleById = createAction(types.GET_MODULE_BY_ID)
export const getModuleByIdSuccessful = createAction(
  types.GET_MODULE_BY_ID_SUCCESSFUL
)
export const getModuleByIdFailure = createAction(types.GET_MODULE_BY_ID_FAILURE)
export const getModuleThemes = createAction(types.GET_MODULE_THEMES)
export const getModuleThemesSuccessful = createAction(
  types.GET_MODULE_THEMES_SUCCESFUL
)
export const getModuleThemesFailure = createAction(
  types.GET_MODULE_THEMES_FAILURE
)

export const newGetModuleThemes = createAction(types.NEW_GET_MODULE_THEMES)
export const newGetModuleThemesSuccessful = createAction(
  types.NEW_GET_MODULE_THEMES_SUCCESFUL
)
export const newGetModuleThemesFailure = createAction(
  types.NEW_GET_MODULE_THEMES_FAILURE
)

export const newGetModules = createAction(types.NEW_GET_MODULES)
export const newGetModulesSuccessful = createAction(
  types.NEW_GET_MODULES_SUCCESSFUL
)
export const newGetModulesFailure = createAction(types.NEW_GET_MODULES_FAILURE)

export const clearCurrentItem = createAction(types.CLEAR_CURRENT_ITEM)

export const createModule = createAction(types.CREATE_MODULE)
export const createModuleSuccessful = createAction(
  types.CREATE_MODULE_SUCCESSFUL
)
export const createModuleFailure = createAction(types.CREATE_MODULE_FAILURE)

export const editModule = createAction(types.EDIT_MODULE)
export const editModuleSuccessful = createAction(types.EDIT_MODULE_SUCCESSFUL)
export const editModuleFailure = createAction(types.EDIT_MODULE_FAILURE)

export const deleteModule = createAction(types.DELETE_MODULE)
export const deleteModuleSuccessful = createAction(
  types.DELETE_MODULE_SUCCESSFUL
)
export const deleteModuleFailure = createAction(types.DELETE_MODULE_FAILURE)

export const createModuleRSPA = createPromiseAction('rspa: CREATE_MODULE')()

export const removeThemeFromModule = createAction(
  'modules/REMOVE_THEME_FROM_MODULE'
)
export const removeThemeFromModuleSuccessful = createAction(
  'modules/REMOVE_THEME_FROM_MODULE_SUCCESSFUL'
)
export const removeThemeFromModuleFailure = createAction(
  'modules/REMOVE_THEME_FROM_MODULE_FAILURE'
)
