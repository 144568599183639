import jsonApi, { multipartInstance } from '../../utils/jsonApi'
import http from '../../utils/http'

const classroomModel = 'classroom'
const childClassroomModel = 'classroom_child'
const childModel = 'child'
const classroomTeacherModel = 'classroom_teachers'
const userUrl = '/users'
const timesheetModel = 'timesheet'

export const exportClassroom = id => {
  try {
    return http
      .post(
        '/classrooms/create_spreadsheet',
        { data: { attributes: { classroom_id: id } } },
        {
          responseType: 'blob'
        }
      )
      .then(res => res)
  } catch (err) {
    console.error(err)
  }
}

export const fetchClassrooms = ({ page, per, sort, params }) => {
  return jsonApi
    .findAll(classroomModel, {
      params: {
        'q[s]': 'name asc',
        'q[year_lteq]': new Date().getFullYear() + 1,
        'q[year_gteq]': new Date().getFullYear(),
        'page[number]': page || 1,
        'page[size]': per || 5,
        sort: sort,
        include: 'children,grade,school,segment',
        ...params
      }
    })
    .then(res => res)
}

export const fetchClassroomById = id =>
  jsonApi
    .find(classroomModel, id, {
      params: {
        include:
          'grade,school,children.parents,children.child_parents,teachers.classroom_teachers,children.user,segment'
      }
    })
    .then(res => res)

export const fetchClassroomsRecords = ({ page, per, id }) => {
  return jsonApi
    .find('teaching_record', '', {
      params: {
        'page[number]': page,
        'page[size]': per,
        'q[destinations_classroom_id_eq]': id,
        include: 'content,destinations,files'
      }
    })
    .then(res => res)
}

export const createClassroom = data => {
  return jsonApi.create(classroomModel, data).then(res => res.data)
}

export const createTimesheet = data => {
  return jsonApi.create(timesheetModel, data).then(res => res.data)
}

export const createClassroomTeacher = data => {
  return jsonApi.create('classroom_teacher', data)
}

export const createChildWithParents = data => {
  return http.post('/children/children_with_parents', data).then(res => res)
}

export const editTimesheet = data => {
  return jsonApi
    .put(timesheetModel, { id: data.id, ...data.data })
    .then(res => res.data)
}

export const editClassroom = data => {
  return jsonApi
    .put(classroomModel, { id: data.id, ...data })
    .then(res => res.data)
}

export const fetchChildParents = ({ userId, childId }) => {
  return jsonApi
    .find('child_parent', '', {
      params: {
        'q[child_id_eq]': childId,
        'q[user_id_eq]': userId
      }
    })
    .then(res => res)
}

export const createClassroomChildren = data => {
  return jsonApi.create(childClassroomModel, data).then(res => res.data)
}

export const createChildren = data => {
  return multipartInstance
    .post('/children', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res => {
      return res.data
    })
}

export const editClassroomChildren = data => {
  return http
    .put(`${'classroom_children'}/${data.id}`, {
      data: {
        type: 'classroom_children',
        id: data.id,
        attributes: { ...data.data }
      }
    })
    .then(res => {
      return res.data
    })
}

export const editChildren = data => {
  return multipartInstance
    .put(`children/${data.id}`, data.fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res => res.data)
}

export const fetchChild = childId =>
  jsonApi
    .find(childModel, childId, {
      params: {
        include: 'user,child_parents.parent'
      }
    })
    .then(res => res)

export const fetchClassroomChild = ({ childId, classroomId }) => {
  const params = {
    'q[child_id_eq]': childId
  }
  classroomId && Object.assign(params, { 'q[classroom_id_eq]': classroomId })
  return jsonApi
    .findAll(childClassroomModel, {
      params: {
        ...params,
        include: 'classroom'
      }
    })
    .then(res => res)
}

export const fetchDestinationsByClassroom = params => {
  return http.get('/classrooms', { params })
}

export const recoverPassword = data => {
  return http.post('/users/recover_password', data).then(res => res)
}

export const fetchClassroomTeacherByUserId = userId => {
  return jsonApi
    .findAll('classroom_teacher', {
      params: {
        'q[user_id_eq]': userId,
        include: 'classroom'
      }
    })
    .then(res => res)
}

export const fetchClassroomsBySchoolIdCount = schoolId => {
  return http
    .get('/classrooms', {
      params: {
        'q[school_id_eq]': schoolId,
        'q[year_lteq]': new Date().getFullYear() + 1,
        'q[year_gteq]': new Date().getFullYear()
      }
    })
    .then(res => res.data.meta.total_count)
}

export const fetchClassroomsBySchoolId = async schoolId => {
  let currentPage = 1
  const response = await http
    .get('classrooms', {
      params: {
        'q[school_id_eq]': schoolId,
        'q[year_lteq]': new Date().getFullYear() + 1,
        'q[year_gteq]': new Date().getFullYear(),
        'page[number]': currentPage
      }
    })
    .then(res => {
      return res.data
    })
  let fullResponse = { ...response }
  while (fullResponse.data.length < fullResponse.meta.total_count) {
    currentPage += 1
    let responseForNextPage = await http
      .get('classrooms', {
        params: {
          'q[school_id_eq]': schoolId,
          'q[year_lteq]': new Date().getFullYear() + 1,
          'q[year_gteq]': new Date().getFullYear(),
          'page[number]': currentPage
        }
      })
      .then(res => res.data)
    fullResponse.data = [...fullResponse.data, ...responseForNextPage.data]
  }
  return fullResponse.data
}

export const fetchClassroomsByGrades = async ({ gradeIds, isSingleGrade }) => {
  let currentPage = 1
  const response = await http
    .get('classrooms', {
      params: {
        'q[grade_id_in]': isSingleGrade ? gradeIds : [...gradeIds],
        'page[number]': currentPage
      }
    })
    .then(res => res.data)
  let fullResponse = { ...response }
  while (fullResponse.data.length < fullResponse.meta.total_count) {
    currentPage += 1
    let responseForNextPage = await http
      .get('classrooms', {
        params: {
          'q[grade_id_in]': isSingleGrade ? gradeIds : [...gradeIds],
          'page[number]': currentPage
        }
      })
      .then(res => res.data)
    fullResponse.data = [...fullResponse.data, ...responseForNextPage.data]
  }
  return fullResponse.data
}

export const fetchChildByClassroom = classroom => {
  return http.get(`classrooms/${classroom}`, {
    params: {
      include: 'children'
    }
  })
}

export const fetchClassroomChildrenByChild = childId => {
  return http.get('/classroom_children', {
    params: {
      'q[child_id_eq]': childId
    }
  })
}

export const removeChildrenTaxpayerNmbRSPA = childId => {
  return http
    .put(`children/${childId}`, {
      data: {
        id: childId,
        type: 'children',
        attributes: {
          taxpayer_number: null
        }
      }
    })
    .then(res => res)
}

export const removeUserTaxpayerNmbRSPA = userId => {
  return http
    .put(`users/${userId}`, {
      data: {
        id: userId,
        type: 'users',
        attributes: {
          taxpayer_number: null
        }
      }
    })
    .then(res => res)
}

export const deleteUserChildren = userId => {
  return http.delete(`users/${userId}`).then(res => res)
}

export const fetchClassroomTeacher = teacherId =>
  jsonApi
    .find(classroomTeacherModel, teacherId, {
      params: {
        include: 'teacher,classroom'
      }
    })
    .then(res => res)

export const editUser = data => {
  return http
    .put(`${userUrl}/${data.id}`, {
      data: { type: 'users', id: data.id, attributes: { ...data.data } }
    })
    .then(res => {
      return res.data
    })
}

export const editUserFormData = data => {
  return multipartInstance
    .put(`users/${data.id}`, data.fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res => res.data)
}

export const deleteUserAvatar = data => {
  return http.delete(`users/${data.id}/avatar`)
}

export const deleteChildrenAvatar = data => {
  return http.delete(`children/${data.id}/avatar`)
}

export const deleteChildParent = data => {
  return http.delete(`child_parents/${data.id}`)
}

export const fetchChildrenByTaxpayer = taxpayer => {
  return http
    .get('children', {
      params: {
        'q[taxpayer_number_eq]': taxpayer,
        include: 'user,child_parents.parent'
      }
    })
    .then(res => res)
}

export const fetchClassroomByChildId = childId => {
  return http
    .get('classroom_children', {
      params: {
        'q[child_id_eq]': childId,
        include: 'child'
      }
    })
    .then(res => res)
}

export const createUser = data => {
  return http
    .post(userUrl, { data: { type: 'users', attributes: { ...data } } })
    .then(res => {
      return res.data
    })
}

export const getUser = data => {
  return jsonApi.find('users', data.id, {
    params: {
      include: 'child'
    }
  })
}

export const getParentsByEmail = data =>
  jsonApi
    .find('users', '', {
      params: {
        'q[email_eq]': data,
        include: 'child_parents'
      }
    })
    .then(res => res)

export const createChildParents = data => {
  return jsonApi.create('child_parent', data)
}

export const getParentsByChild = ({ childId }) => {
  return jsonApi
    .findAll('user', {
      params: {
        'q[child_parents_child_id_eq]': childId
      }
    })
    .then(res => res.data)
}

export const getChildParents = ({ childId, parentId }) => {
  return jsonApi
    .find('child_parent', '', {
      params: {
        'q[child_id_eq]': childId,
        'q[user_id_eq]': parentId
      }
    })
    .then(res => res.data)
}

export const createSchoolTeacher = data => {
  return jsonApi.create('school_teacher', data)
}

export const deleteClassroomChild = id =>
  jsonApi.destroy(childClassroomModel, id, { id })
