import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import ClassroomView from '../components/view/ClassroomView'
import {
  getClassroomById,
  clearCurrentClassroom
} from '../store/classrooms/actions'
import { getGradeThemeSchedules } from '../store/grades/actions'

const mapStateToProps = ({ classrooms, grades }) => {
  return {
    classroom: classrooms.currentItem,
    isFetching: classrooms.isFetching,
    themeSchedules: grades.themeSchedules
  }
}

const mapDispatchToProps = {
  getClassroomById,
  getGradeThemeSchedules,
  clearCurrentClassroom
}

const ClassroomPage = ({
  match,
  getClassroomById,
  classroom,
  getGradeThemeSchedules,
  themeSchedules,
  isFetching,
  clearCurrentClassroom
}) => {
  const { id } = match.params

  useEffect(() => {
    const loadData = async () => {
      await getClassroomById(id)
    }
    loadData()
    return () => {
      clearCurrentClassroom()
    }
  }, [])

  useEffect(
    () => {
      const loadData = async () => {
        classroom?.id &&
          (await getGradeThemeSchedules({
            gradeId: classroom.grade_id,
            schoolId: classroom.school_id,
            year: classroom.year || new Date().getFullYear(),
            sort: 'start_at asc'
          }))
      }
      loadData()
    },
    [classroom]
  )

  return (
    <ClassroomView
      classroomId={id}
      classroom={classroom}
      getGradeThemeSchedules={getGradeThemeSchedules}
      themeSchedules={themeSchedules}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassroomPage)
