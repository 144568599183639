import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Button from '../button/Button'
import StyledSVG from '../shared/StyledSVG'
import webSVG from '../../assets/icons/web-icon.svg'
import androidSVG from '../../assets/icons/android-icon.svg'
import iosSVG from '../../assets/icons/ios-icon.svg'
import emailSVG from '../../assets/icons/mail-icon.svg'
import MaterialViewTemplate from '../shared/MaterialViewTemplate'
import { useHistory } from 'react-router-dom'
import exportSVG from '../../assets/icons/export-icon.svg'
import reportIcon from '../../assets/icons/material-report.svg'
import PaginatedAsyncTable from '../table/PaginatedAsyncTable'
import ShowOvercontentTable from '../table/ShowOvercontentTable'
import CircularProgress from '@material-ui/core/CircularProgress'
import { saveAs } from 'file-saver'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    width: '98%',
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    margin: 'auto',
    height: '95%',
    padding: '32px',
    marginBottom: '80px'
  },
  horizontalBar: {
    marginTop: 24,
    marginBottom: 27,
    width: '96%',
    height: 1,
    border: '1px solid #ADB8CC'
  },
  file: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
    width: '25%',
    height: 70,
    border: '1px solid #D7DCE480',
    borderRadius: 16,
    minWidth: '250px',
    overflowX: 'hidden',
    '& .aroundDiv': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 56,
      backgroundColor: '#FDE6E8',
      height: 56,
      width: 56,
      minWidth: 56,
      minHeight: 56
    },
    '& p': {
      fontWeight: 600,
      fontSize: 14,
      color: '#555555',
      margin: 0
    }
  },
  files: {
    display: 'flex',
    gap: 8,
    flexWrap: 'wrap',
    marginBottom: 30
  },
  bottomBtns: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    '& button': {
      // width: 167
    }
  },
  goBackBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  reportBtn: {
    width: 146,
    border: '1px solid #F4A87C',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#F4A87C',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #F4A87C'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  downloadAll: {
    backgroundColor: '#F4A87C',
    '& span': {
      textTransform: 'initial'
    },
    width: 200,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#F4A87C'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  exportBtn: {
    backgroundColor: '#F4A87C',
    '& span': {
      textTransform: 'initial'
    },
    width: 200,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#F4A87C'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px',
    '& .exportIcon': {
      display: 'flex',
      alignItems: 'center',
      gap: 8
    }
  },
  reportSectionLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& h1': {
      margin: 0,
      color: '#386093',
      fontWeight: 700,
      fontSize: 28
    }
  },
  isFetching: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  views: {
    marginBottom: 20
  }
}))

const MaterialReportView = ({
  files,
  materialId,
  currentMaterial,
  cleanMaterialSuccessful,
  isFetching,
  userOccupations,
  exportMaterialReportRSPA
}) => {
  const history = useHistory()

  const exportMaterialReport = () => {
    try {
      exportMaterialReportRSPA({ id: currentMaterial.id }).then(res => {
        saveAs(res.data, `Relatório - ${currentMaterial?.title}.xlsx`)
      })
    } catch (err) {
      console.error(err)
    }
  }

  const getViewingPlatformIcon = platform => {
    switch (platform.toLowerCase()) {
      case 'web':
        return webSVG
      case 'android':
        return androidSVG
      case 'ios':
        return iosSVG
      case 'email':
        return emailSVG
      default:
        return null
    }
  }

  React.useEffect(() => {}, [])

  const classes = useStyles()
  const columns = [
    {
      key: 'name',
      name: 'Alunos',
      render: function render (row) {
        return row.user.name
      }
    },
    {
      key: 'classroom',
      name: 'Turma',
      render: function render (row) {
        const classroom = row?.user?.classrooms?.map(item => item.name)
        if (classroom.length > 0) {
          return <ShowOvercontentTable item={classroom} />
        } else {
          return '-'
        }
      }
    },
    {
      key: 'date',
      name: 'Data e hora',
      render: function render (row) {
        const date = moment(row.created_at).format('DD/MM/YYYY')
        const hour = moment(row.created_at).format('HH:mm')
        return `${date} - ${hour}`
      }
    },
    {
      key: 'viewing_platform',
      name: 'Via',
      render: function render (row) {
        return (
          <div style={{ display: 'flex' }}>
            {row.viewing_platform && (
              <StyledSVG
                color={'#7B7B7B'}
                height={20}
                width={20}
                src={getViewingPlatformIcon(row.viewing_platform)}
              />
            )}
            <span style={{ marginLeft: '7px' }}>
              {row.viewing_platform.charAt(0)?.toUpperCase() +
                row.viewing_platform.slice(1)}
            </span>
          </div>
        )
        // const date = row.
      }
    }
  ]
  if (isFetching) {
    return (
      <div className={classes.isFetching}>
        <CircularProgress size={60} />
      </div>
    )
  } else {
    return (
      <div className={classes.root}>
        <MaterialViewTemplate
          userOccupations={userOccupations}
          cleanMaterialSuccessful={cleanMaterialSuccessful}
          currentMaterial={currentMaterial}
        />
        <div className={classes.horizontalBar} />
        <div>
          <div className={classes.reportSectionLabel}>
            <StyledSVG src={reportIcon} height={24} width={24} />
            <h1>Relatório</h1>
          </div>
          <div className={classes.views}>
            {currentMaterial?.views &&
              currentMaterial?.views?.length > 0 && (
                <PaginatedAsyncTable
                  columns={columns}
                  whiteHeader
                  pageSize={10}
                  data={currentMaterial?.views}
                />
              )}
          </div>
        </div>
        <div className={classes.bottomBtns}>
          <Button
            type='button'
            variant='outlined'
            className={classes.goBackBtn}
            onClick={() => history.push(`/materials/${currentMaterial?.id}`)}
          >
            Voltar
          </Button>
          <Button
            onClick={exportMaterialReport}
            type='button'
            className={classes.exportBtn}
          >
            <div className='exportIcon'>
              <span>Exportar</span>
              <StyledSVG src={exportSVG} width={24} height={24} />
            </div>
          </Button>
        </div>
      </div>
    )
  }
}

export default MaterialReportView
