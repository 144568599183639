import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import CircularProgress from '@material-ui/core/CircularProgress'

import { getGradesById } from '../store/grades/actions'
import SearchThemeForm from '../components/form/SearchThemeForm'

const mapStateToProps = ({ grades, school }) => {
  return {
    currentSchool: school.currentSchool,
    currentGrade: grades.current,
    isFetching: grades.isFetching
  }
}

const mapDispatchToProps = {
  getGradesById
}

const PlanningSearchPage = ({
  match,
  getGradesById,
  currentGrade,
  currentSchool,
  isFetching
}) => {
  const { params: { gradeId } } = match
  useEffect(() => {
    gradeId && getGradesById(gradeId)
  }, [])

  if (isFetching) return <CircularProgress color='secondary' />
  return <SearchThemeForm grade={currentGrade} school={currentSchool} />
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanningSearchPage)
