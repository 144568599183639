import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Field, Form, formValueSelector, reduxForm, change } from 'redux-form'
import { useHistory } from 'react-router-dom'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'

import AsyncSelect from '../shared/AsyncSelect'
import Button from '../button/Button'
import { roles, reportStatus } from '../../utils/constants'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import filterSVG from '../../assets/icons/filter-icon.svg'
import StyledSVG from '../shared/StyledSVG'
import doneSVG from '../../assets/icons/status-confirmation.svg'
import pendingSVG from '../../assets/icons/status-pending.svg'
import openSVG from '../../assets/icons/status-open.svg'
import moderatingSVG from '../../assets/icons/moderating-icon.svg'

import MaterialInput from '../shared/MaterialInput'
import { generateReports } from '../../store/reports/actions'
import CustomTable from '../table/CustomTable'
import moment from 'moment'
import viewSVG from '../../assets/icons/pattern-view-icon.svg'
import editSVG from '../../assets/icons/pattern-edit-icon.svg'
import generateReportSVG from '../../assets/icons/generate-report.svg'
import { showAlertMessage } from '../../store/alert/actions'
import { Tooltip } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '40px 30px',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  status: {
    marginLeft: '5px',
    [theme.breakpoints.down('426')]: {
      display: 'none'
    }
  },
  statusRoot: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('426')]: {
      justifyContent: 'center'
    }
  },
  pageLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 20,
    '& h1': {
      fontSize: 32,
      color: '#386093',
      fontWeight: 700,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 45,
    [theme.breakpoints.down('890')]: {
      flexDirection: 'column-reverse',
      gap: 16
    }
  },
  searchSection: {
    marginTop: 40
  },
  searchDiv: {
    '& .MuiInputBase-root': {
      width: 300,
      [theme.breakpoints.down('426')]: {
        maxWidth: '250px'
      },
      [theme.breakpoints.down('330')]: {
        maxWidth: 220
      }
    }
  },
  filtersSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  filterLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  advancedFilter: {
    overflow: 'hidden',
    transition: 'height 0.5s ease-in-out',
    [theme.breakpoints.down('1000')]: {
      overflow: 'scroll'
    }
  },
  filtersDisplay: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24
  },
  searchGroup: {
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    gap: 24,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      gap: 12
    }
  },
  searchField: {
    '& .select__menu-list': {
      maxHeight: '150px'
    },
    '& div': {
      '& .select__control': {
        minWidth: 200
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        minWidth: 'unset',
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    },
    [theme.breakpoints.down('1000')]: {
      width: '100% !important',
      '& div': {
        // redux form 'Field' componente
        '& div': {
          '& .select__control, .select__value-container, .select__indicators, .MuiInputBase-root': {
            minHeight: '35px',
            height: '35px'
          },
          maxWidth: '100%'
        }
      }
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: 8,
    '& button': {
      marginRight: 0,
      width: 149
    },
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'flex-start',
      marginTop: 15,
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    [theme.breakpoints.down('350')]: {
      '& button': {
        width: '170px !important'
      }
    }
  },
  horizontalBar: {
    marginBottom: 27,
    width: '96%',
    height: 1,
    border: '1px solid #ADB8CC'
  },
  clearBtn: {
    backgroundColor: '#EF7C8F',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  createBtn: {
    backgroundColor: '#00ACDB',
    width: 146,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  actionsSection: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& path': {
      stroke: '#868E96'
    },
    '& circle': {
      stroke: '#868E96'
    }
  }
}))

const SearchReportsForm = ({
  alert,
  change,
  handleSubmit,
  gradeValue,
  schoolValue,
  startDateValue,
  classroomValue,
  studentValue,
  userOccupations,
  getReports,
  reports,
  reset,
  touch,
  generateReports,
  handleClickButton,
  handleGenerateReport,
  defaultParams,
  nameValue
}) => {
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)
  const isCoordinator = userOccupations?.includes(roles.COORDINATOR)
  const classes = useStyles({ isAdmin })
  const history = useHistory()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('426'))

  const [openMoreFilter, setOpenMoreFilter] = React.useState(false)

  const selectSchoolRef = useRef(null)
  const selectGradeRef = useRef(null)
  const selectClassroomRef = useRef(null)
  const selectNameRef = useRef(null)

  const handleChangeTitle = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      getReports({ params: searchParams })
    }
  }

  Object.assign(defaultParams, { 'q[classroom_school_id_eq]': schoolValue })

  const handleChange = (event, input) => {
    touch()
    let newValue
    if (Array.isArray(event)) {
      newValue = event?.map(v => v)
    } else {
      newValue = event ? event.value : ''
    }
    change(input.name, newValue)
  }

  const handleClearFields = () => {
    reset()
    selectGradeRef.current?.select.clearValue()
    selectClassroomRef.current?.select.clearValue()
    selectNameRef.current?.select.clearValue()
    getReports({ params: defaultParams })
  }

  const startDateValueAux = startDateValue && new Date(startDateValue)
  const searchParams = {
    ...defaultParams,
    'q[title_eq]': nameValue,
    'q[classroom_id_eq]': classroomValue,
    'q[classroom_grade_id_eq]': gradeValue,
    'q[classroom_school_id_eq]': schoolValue,
    'q[classroom_children_id_eq]': studentValue,
    'q[created_at_gteq]': startDateValue
      ? moment(startDateValue).format('YYYY-MM-DD')
      : null,
    'q[created_at_lteq]': startDateValue
      ? moment(
          new Date(startDateValueAux.setDate(startDateValueAux.getDate() + 1))
        ).format('YYYY-MM-DD')
      : null
  }

  const handleSearch = () => {
    getReports({ params: searchParams })
  }

  const columns = [
    {
      key: 'title',
      name: 'Título',
      width: '30%',
      render: row => {
        let title
        matches ? (title = row.title) : (title = `${row.title.slice(0, 10)}...`)
        return (
          <Tooltip title={row.title}>
            <span onClick={() => history.push(`report_template/${row.id}`)}>
              {title}
            </span>
          </Tooltip>
        )
      }
    },
    {
      key: 'status',
      name: 'Status',
      width: '25%',
      render: function render (row) {
        const statusProperties = (status, isForColor) => {
          switch (status) {
            case 'done': {
              if (isForColor) return '#00ACDB'
              return doneSVG
            }
            case 'pending': {
              if (isForColor) return '#F69E9E'
              return pendingSVG
            }
            case 'open': {
              if (isForColor) return '#C4C4C4'
              return openSVG
            }
            case 'waiting_moderation': {
              if (isForColor) return '#F1925B'
              return moderatingSVG
            }
            default:
              return ''
          }
        }
        return (
          <div className={classes.statusRoot}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledSVG
                height={20}
                width={20}
                src={statusProperties(row.status)}
              />
            </div>
            <div>
              <span
                style={{ color: statusProperties(row.status, true) }}
                className={classes.status}
              >
                {reportStatus[row.status]}
              </span>
            </div>
          </div>
        )
      }
    },
    {
      key: 'classroom',
      name: 'Turma',
      width: '30%',
      render: row => {
        let classrooms = []
        row.classroom?.map(clas => {
          classrooms.push(clas.name)
        })
        return classrooms
      }
    },
    {
      key: 'actions',
      name: 'Ações',
      width: '15%',
      render: function render (row) {
        return (
          <div className={classes.actionsSection}>
            {(isAdmin || isCoordinator) && (
              <Tooltip title='Visualizar'>
                <div onClick={() => history.push(`report_template/${row.id}`)}>
                  <StyledSVG src={viewSVG} width={20} height={20} />
                </div>
              </Tooltip>
            )}
            {
              <Tooltip
                title={isAdmin || isCoordinator ? 'Gerar diário' : 'Visualizar'}
              >
                <div
                  onClick={() => {
                    handleGenerateReport(
                      row.id,
                      row.status,
                      row.user_id,
                      generateReports
                    )
                    setTimeout(() => {
                      history.push(`/report_template/${row.id}`)
                    }, 1000)
                  }}
                >
                  <StyledSVG
                    src={isAdmin || isCoordinator ? generateReportSVG : viewSVG}
                    width={20}
                    height={20}
                  />
                </div>
              </Tooltip>
            }
            {(isAdmin || isCoordinator) && (
              <Tooltip title={'Editar'}>
                <div
                  onClick={() =>
                    history.push(`report_template/${row.id}?name=edit`)
                  }
                >
                  <StyledSVG src={editSVG} width={20} height={20} />
                </div>
              </Tooltip>
            )}
          </div>
        )
      }
    }
  ]

  return (
    <div className={classes.root}>
      <Form onSubmit={handleSubmit}>
        <div className={classes.pageLabel}>
          <h1>Diários</h1>
          <span>Explore o histórico de diários disponíveis.</span>
        </div>
        {/* Header */}
        <div className={classes.searchSection}>
          <div className={classes.searchContainer}>
            <div className={classes.searchDiv}>
              <strong>Busca</strong>
              <Field
                style={{ marginTop: 10 }}
                component={MaterialInput}
                selectRef={selectNameRef}
                name='name'
                endBtnFunction={() => {
                  getReports({ params: searchParams })
                }}
                onKeyDown={handleChangeTitle}
                placeholder={'Título do diário'}
                type='text'
              />
            </div>
            {(isAdmin || isCoordinator) && (
              <Button
                className={classes.createBtn}
                id='create_report_template'
                type='button'
                onClick={() => handleClickButton('create')}
              >
                {`Criar`}
              </Button>
            )}
          </div>
          <div className={classes.filtersSection}>
            <div className={classes.filterLabel}>
              <StyledSVG src={filterSVG} width={12} height={12} />
              <p
                onClick={() => setOpenMoreFilter(prev => !prev)}
                style={{
                  cursor: 'default',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#386093'
                }}
              >
                Filtros avançados
              </p>
              {!openMoreFilter ? (
                <KeyboardArrowDownIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              )}
            </div>
            <div
              className={classes.advancedFilter}
              style={{
                height: openMoreFilter ? '250px' : '0px'
              }}
            >
              <div className={classes.filtersDisplay}>
                <div
                  className={classes.searchGroup}
                  style={{ opacity: openMoreFilter ? '1' : '0' }}
                >
                  {userOccupations?.includes(roles.VERSAR_ADMIN) && (
                    <div className={classes.searchField}>
                      <strong htmlFor='school_id'>Escola</strong>
                      <Field
                        style={{ width: '100%' }}
                        component={AsyncSelect}
                        selectRef={selectSchoolRef}
                        id='school_id'
                        name='school_id'
                        placeholder='Digite ou selecione a escola'
                        handleAsyncChange={handleChange}
                        searchParam='q[name_cont]'
                        request={{
                          path: 'school'
                        }}
                        touch={touch}
                      />
                    </div>
                  )}
                  {isAdmin && (
                    <div className={classes.searchField}>
                      <label htmlFor={`grade_id`}>Série</label>
                      <Field
                        component={AsyncSelect}
                        defaultOptions={false}
                        id='grade_id'
                        name='grade_id'
                        selectRef={selectGradeRef}
                        placeholder='Digite ou selecione'
                        handleAsyncChange={handleChange}
                        searchParam='q[name_cont]'
                        request={{
                          path: 'grade',
                          params: {
                            'q[school_id_eq]': schoolValue,
                            'q[m]': 'or',
                            'q[school_id_null]': true
                          }
                        }}
                        touch={touch}
                      />
                    </div>
                  )}
                  <div className={classes.searchField}>
                    <label htmlFor={`classroom_id`}>Turma</label>
                    <Field
                      component={AsyncSelect}
                      defaultOptions={false}
                      id='classroom_id'
                      name='classroom_id'
                      cacheUniqs={[gradeValue, schoolValue]}
                      selectRef={selectClassroomRef}
                      placeholder='Digite ou selecione'
                      handleAsyncChange={handleChange}
                      searchParam='q[name_cont]'
                      request={{
                        path: 'classroom',
                        params: {
                          'q[school_id_in]': schoolValue,
                          'q[grade_id_in]': gradeValue,
                          'q[year_lteq]': new Date().getFullYear() + 1,
                          'q[year_gteq]': new Date().getFullYear()
                        }
                      }}
                      touch={touch}
                    />
                  </div>
                </div>
                <div
                  style={{
                    opacity: openMoreFilter ? '1' : '0',
                    transition: 'opacity 0.3s ease-in-out'
                  }}
                  className={classes.buttons}
                >
                  <Button
                    onClick={() => handleClearFields()}
                    className={classes.clearBtn}
                  >
                    Limpar
                  </Button>
                  <Button
                    className={classes.applyBtn}
                    type='button'
                    onClick={() => handleSearch()}
                  >
                    Aplicar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.horizontalBar} />
        </div>
        <div>
          <h2>Lista de relatórios</h2>
          <CustomTable
            columns={columns}
            from={'reports'}
            data={reports.items}
            params={searchParams}
            fetchItems={getReports}
            isFetching={reports.isFetching}
            pagination={reports.pagination}
            columnsPointer={[0]}
            border={'none'}
            bodyBorder={'none'}
            headCellRadius
            headColor={'rgba(173, 184, 204, 0.3)'}
          />
        </div>
      </Form>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('searchReportsForm')

  return {
    userOccupations: state.auth.currentOccupation,
    schoolValue:
      selector(state, 'school_id') ||
      (state.school.currentSchool && state.school.currentSchool.school_id),
    classroomValue: selector(state, 'classroom_id'),
    gradeValue: selector(state, 'grade_id'),
    nameValue: selector(state, 'name'),
    startDateValue: selector(state, 'start_at')
  }
}

const mapDispatchToProps = dispatch => ({
  generateReports: data => dispatch(generateReports(data)),
  change: (field, data) => dispatch(change('searchReportsForm', field, data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'searchReportsForm'
  })(SearchReportsForm)
)
