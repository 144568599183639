import { all, call, takeLatest, put, select } from 'redux-saga/effects'

import * as actions from './actions'
import * as services from './services'
import { roles } from '../../utils/constants'

export function * fetchSchoolContentCreatorsById ({ payload }) {
  try {
    const response = yield call(services.fetchContentCreatorById, payload)
    yield put(actions.getSchoolContentCreatorsByIdSuccessful(response))
  } catch (error) {
    yield put(actions.getSchoolContentCreatorsByIdFailure(error))
  }
}

export function * fetchSchoolAdministratorById ({ payload }) {
  try {
    const response = yield call(services.fetchAdministratorById, payload.id)
    yield put(
      actions.getSchoolAdministratorByIdSuccessful({
        response,
        isLogin: payload.isLogin
      })
    )
  } catch (error) {
    yield put(actions.getSchoolAdministratorByIdFailure(error))
  }
}

export function * fetchSchoolTeacherById ({ payload }) {
  try {
    const response = yield call(services.fetchTeacherById, payload.id)
    yield put(
      actions.getSchoolTeacherByIdSuccessful({
        response,
        isLogin: payload.isLogin
      })
    )
  } catch (error) {
    yield put(actions.getSchoolTeacherByIdFailure(error))
  }
}

export function * fetchSchoolCoordinatorById ({ payload }) {
  try {
    const response = yield call(services.fetchCoordinatorById, payload.id)
    yield put(
      actions.getSchoolCoordinatorByIdSuccessful({
        response,
        isLogin: payload.isLogin
      })
    )
  } catch (error) {
    yield put(actions.getSchoolCoordinatorByIdFailure(error))
  }
}

export function * updateLayoutSchoolRequest ({ payload }) {
  try {
    const { auth } = yield select()
    const userOccupations = auth.user.data?.attributes?.occupations
    const file = payload.avatar[0]
    const fd = new FormData()
    fd.append('data[type]', 'schools')
    fd.append('data[id]', payload.id)
    typeof file === 'object' && fd.append('data[attributes][avatar]', file)
    fd.append('data[attributes]primary_color', payload.primary_color)
    fd.append('data[attributes]secondary_color', payload.secondary_color)
    const response = yield call(services.updateLayoutSchool, {
      id: payload.id,
      data: fd
    })
    yield put(
      actions.updateLayoutSchoolSuccessful(
        userOccupations.includes(roles.SCHOOL_ADMIN) ? response.data : {}
      )
    )
  } catch (error) {
    yield put(actions.updateLayoutSchoolFailure(error))
  }
}

export function * fetchSchools ({ payload }) {
  try {
    const response = yield call(services.fetchSchools, payload)
    yield put(actions.getSchoolsSuccessful(response))
  } catch (error) {
    yield put(actions.getSchoolsFailure(error))
  }
}

export function * saveSelectedSchool ({ payload }) {
  yield put(actions.selectedCurrentSchoolSuccessful(payload))
}

export function * fetchSchoolConfig ({ payload }) {
  try {
    const response = yield call(services.getSchoolConfig, payload)
    yield put(actions.getSchoolConfigSuccessFul(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getSchoolConfigFailure(error))
  }
}

export function * updateSchoolConfig ({ payload }) {
  try {
    const response = yield call(services.updateSchoolConfig, payload)
    yield put(actions.changeSchoolConfigSuccessful(response))
  } catch (err) {
    console.error(err)
    yield put(actions.changeSchoolConfigFailure(err))
  }
}

export function * watchFetchSchoolContentCreators () {
  yield takeLatest(
    actions.getSchoolContentCreatorsById,
    fetchSchoolContentCreatorsById
  )
  yield takeLatest(
    actions.getSchoolAdministratorById,
    fetchSchoolAdministratorById
  )
  yield takeLatest(actions.updateLayoutSchool, updateLayoutSchoolRequest)
  yield takeLatest(actions.getSchoolTeacherById, fetchSchoolTeacherById)
  yield takeLatest(actions.getSchools, fetchSchools)
  yield takeLatest(actions.selectedCurrentSchool, saveSelectedSchool)
  yield takeLatest(actions.getSchoolCoordinatorById, fetchSchoolCoordinatorById)
  yield takeLatest(actions.getSchoolConfig, fetchSchoolConfig)
  yield takeLatest(actions.changeSchoolConfig, updateSchoolConfig)
}

export default function * schoolContentCreatorsSagas () {
  yield all([watchFetchSchoolContentCreators()])
}
