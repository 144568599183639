import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { useQuery } from '../utils/helpers'

import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ButtonCard from '../components/card/ButtonCard'
import StyledSVG from '../components/shared/StyledSVG'
import menuSVG from '../assets/icons/menu.svg'
import linkSVG from '../assets/icons/link.svg'
import bulletinSVG from '../assets/icons/bulletin.svg'

import docSVG from '../assets/icons/doc.svg'
// import addIcon from '../assets/icons/addIcon.svg'

import UtilsView from '../components/view/UtilsView'

const defaultColor = '#00ACDB'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem 0'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
    margin: 16,
    flexWrap: 'wrap',
    [theme.breakpoints.down('426')]: {
      '& button': {
        width: '80px',
        height: '80px',
        '& svg': {
          width: '30px !important',
          height: '30px !important',
          minWidth: '30px !important',
          minHeight: '30px !important'
        },
        '& p': {
          fontSize: '10px'
        }
      }
    }
  },
  paper: {
    minHeight: '90vh',
    borderRadius: 8,
    padding: 32
  }
}))

const topButtons = [
  {
    id: 1,
    title: 'Cardápio',
    icon: menuSVG,
    type: 'menus'
  },
  {
    id: 3,
    title: 'Link',
    icon: linkSVG,
    type: 'links'
  },
  {
    id: 4,
    title: 'Documento',
    icon: docSVG,
    type: 'documents'
  },
  {
    id: 5,
    title: 'Boletim',
    icon: bulletinSVG,
    type: 'wrapper',
    subType: 'bulletins'
  }
]

const UtilsPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const query = useQuery()

  const handleClickButton = type => {
    if (type === 'add') {
      history.push(`?name=new&util=${type}`)
    } else history.push(`?name=list&util=${type}`)
  }

  const isHidden = () => {
    if (query.get('name') !== 'list' && query.get('name') !== null) {
      return true
    } else {
      return false
    }
  }
  return (
    <div className={classes.root}>
      <div
        className={classes.buttons}
        style={isHidden() ? { display: 'none' } : {}}
      >
        {topButtons.map(btn => {
          return (
            <ButtonCard
              key={btn.id}
              styleType={
                query.get('util') === btn.type ||
                query.get('util') === btn?.subType
                  ? 'default'
                  : 'disabled'
              }
              onClick={() => handleClickButton(btn.type)}
            >
              <StyledSVG
                color={defaultColor}
                src={btn.icon}
                width={35}
                height={35}
              />
              <p>{btn.title}</p>
            </ButtonCard>
          )
        })}
      </div>
      <Paper className={classes.paper} elevation={0}>
        {(query.get('util') === 'menus' || query.get('util') === null) && (
          <UtilsView utilType='menus' title='Cardápios' />
        )}
        {query.get('util') === 'links' && (
          <UtilsView utilType='links' title='Links' />
        )}
        {query.get('util') === 'documents' && (
          <UtilsView utilType='documents' title='Documentos' />
        )}
        {query.get('util') === 'wrapper' && (
          <UtilsView utilType='wrapper' title='Boletins' />
        )}
        {query.get('util') === 'bulletins' && (
          <UtilsView utilType='bulletins' title='Boletins' />
        )}
      </Paper>
    </div>
  )
}
const mapStateToProps = ({ auth }) => {
  return {
    userOccupations: auth.currentOccupation
  }
}

export default connect(mapStateToProps)(UtilsPage)
