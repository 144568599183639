import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { isPlainObject } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { multipartInstance } from '../../utils/jsonApi'

const useStyles = makeStyles(theme => ({
  root: {
    '& .mce-panel': {
      border: 'none'
    },
    '& .mce-container *': {
      whiteSpace: 'normal'
    },
    border: '1.5px solid #DFDFDF',
    borderRadius: 8,
    marginBottom: '1rem',
    padding: '0px 0px 10px',
    '& .tox-tinymce': {
      border: 'none',
      '& .tox-editor-header': {
        zIndex: 0
      }
    },
    '& .tox-toolbar__primary': {
      border: 'none !important',
      background: 'none !important',
      '& .tox-toolbar__group': {
        background: '#F6F7F8',
        boxShadow: '3px 8px 14px 6px rgba(107, 103, 103, 0.1)',
        borderRadius: 8,
        height: 50,
        margin: '5px 10px 22px'
      }
    },
    '& .tox .tox-tbtn svg': {
      fill: '#555'
    }
  },
  error: {
    color: theme.palette.danger.main,
    fontSize: '0.8rem'
  }
}))

const SimpleEditorInput = ({
  input,
  init,
  meta: { error, touched },
  request,
  characterLimit,
  defaultValue,
  addRootStyle,
  additionalToolbar,
  ...props
}) => {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(true)
  const pasteBinDefaultContent = '%MCEPASTEBIN%'

  const [touch, setTouch] = React.useState(false)
  const handleBlur = () => {
    setTouch(true)
  }

  const handleEditorChange = e => {
    input.onChange(e)
  }

  const getBaseUrl = () => {
    if (typeof location.origin === 'undefined') {
      location.origin = location.protocol + '//' + location.host
    }
    return location.origin
  }

  // const updateCharCounter = () => {
  //   const text = window.tinymce?.get(window.tinymce.activeEditor.id).contentDocument.body.textContent?.replace(pasteBinDefaultContent, '')
  //   setTotalTypedChars(text ? text.length : 0)
  // }

  const handleImageUpload = (blobInfo, success, failure) => {
    const blob = blobInfo.blob()
    const allowedFiles = [
      {
        type: 'image/png',
        sizeLimit: 0.2
      },
      {
        type: 'image/jpeg',
        sizeLimit: 0.2
      },
      {
        type: 'image/gif',
        sizeLimit: 1
      }
    ]
    const isAllowedType = type => allowedFiles.some(file => file.type === type)
    const isAllowedSize = (type, size) =>
      allowedFiles.find(file => file.type === type).sizeLimit >= size

    if (!isAllowedType(blob.type)) {
      const allowedTypes = allowedFiles.map(file => file.type).join(', ')
      return failure(
        `Formato ${
          blob.type
        } não é permitido. Insira um arquivo em um dos seguintes formatos: ${allowedTypes}`
      )
    }

    if (!isAllowedSize(blob.type, blob.size / 1024 / 1024)) {
      return failure(
        `Insira uma imagem png ou jpeg de até 200KB ou gif de até 1MB`
      )
    }

    const headers = { 'Content-Type': 'multipart/form-data' }
    const formData = new FormData()
    const fileExtRegex = /\.[^.]+$/i
    const filename = blobInfo.name()
    const ext = blobInfo.filename().match(fileExtRegex)[0]
    formData.append('data[type]', request.type)
    formData.append('data[attributes][name]', filename)
    formData.append('data[attributes][file]', blob, `${filename}${ext}`)

    if (request.otherAttributes) {
      request.otherAttributes.map(
        attrib =>
          attrib.value &&
          formData.append(`data[attributes][${attrib.name}]`, attrib.value)
      )
    }

    multipartInstance
      .post(request.model, formData, { headers })
      .then(response => {
        success(response.data.data.attributes.file_url)
      })
      .catch(err => {
        console.error(err)
        failure('Ocorreu um erro ao tentar fazer o upload.')
      })
  }

  const tinymceGetContentLength = () => {
    const text = window.tinymce
      ?.get(window.tinymce.activeEditor.id)
      .contentDocument.body.textContent?.replace(pasteBinDefaultContent, '')
    return text ? text.length : 0
  }
  return (
    <div className={`${classes.root} ${addRootStyle}`}>
      {loading && (
        <div
          style={{
            position: 'fixed',
            zIndex: '5'
          }}
        >
          <CircularProgress />
        </div>
      )}
      <Editor
        {...props}
        init={{
          indent: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen hr textpattern wordcount',
            'insertdatetime media video table paste code autoresize nonbreaking help'
          ],
          browser_spellcheck: true,
          contextmenu: false,
          content_style:
            'body { font-family:Source Sans,sans-serif; overflow-y: auto !important }',
          // enable tab
          nonbreaking_force_tab: true,
          autoresize_bottom_margin: 1,
          autoresize_min_height: 30,
          branding: false,
          menubar: false,
          statusbar: false,
          readonly: props.disabled,
          media_alt_source: false,
          // eslint-disable-next-line camelcase
          urlconverter_callback: (url, node, on_save, name) => {
            if (url.substring(0, 4) === 'blob') {
              return url
            }
            if (url.substring(0, 4) !== 'http') {
              url = 'https://' + url
              return url
            } else if (url.substring(0, 4) === 'http') {
              url = url.replace('http://', 'https://')
              return url
            } else {
              return url
            }
          },
          audio_template_callback: data =>
            '<audio controls>\n' +
            `<source src="${data.source}"${
              data.sourcemime ? ` type="${data.sourcemime}"` : ''
            } />\n` +
            (data.altsource
              ? `<source src="${data.altsource}"${
                  data.altsourcemime ? ` type="${data.altsourcemime}"` : ''
                } />\n`
              : '') +
            '</audio>',
          video_template_callback: data =>
            `<video width="${data.width}" height="${data.height}"${
              data.poster ? ` poster="${data.poster}"` : ''
            } controls="controls">\n` +
            `<source src="${data.source}"${
              data.sourcemime ? ` type="${data.sourcemime}"` : ''
            } />\n` +
            (data.altsource
              ? `<source src="${data.altsource}"${
                  data.altsourcemime ? ` type="${data.altsourcemime}"` : ''
                } />\n`
              : '') +
            '</video>',
          toolbar: `undo redo formatselect
             bold italic underline alignleft 
             aligncenter alignright alignjustify 
             forecolor backcolor casechangebullist numlist
             link removeformat ${additionalToolbar || ''}`,
          language: 'pt_BR',
          language_url: `${getBaseUrl()}/tinymce/langs/pt_BR.js`,
          // paste options
          paste_word_valid_elements: 'b,strong,i,em,h1,h2',
          paste_retain_style_properties:
            'color font-size font-style text-decoration font-weight',
          paste_enable_default_filters: false,
          paste_filter_drop: false,
          toolbar_location: 'bottom',
          file_picker_callback: (cb, value, meta) => {
            const input = document.createElement('input')
            input.setAttribute('type', 'file')
            input.setAttribute('accept', 'image/*')

            input.addEventListener('change', e => {
              const file = e.target.files[0]

              const reader = new FileReader()
              reader.addEventListener('load', () => {
                const id = 'blobid' + new Date().getTime()
                // eslint-disable-next-line no-undef
                const blobCache = tinymce.activeEditor.editorUpload.blobCache
                const base64 = reader.result.split(',')[1]
                const blobInfo = blobCache.create(id, file, base64)
                blobCache.add(blobInfo)

                cb(blobInfo.blobUri(), { title: file.name })
              })
              reader.readAsDataURL(file)
            })

            input.click()
          },
          images_upload_handler: handleImageUpload,
          setup: editor => {
            editor.on('keydown', event => {
              const allowedKeys = [
                'Backspace',
                'End',
                'Home',
                'ArrowLeft',
                'ArrowUp',
                'ArrowRight',
                'ArrowDown',
                'Delete'
              ]
              if (allowedKeys.indexOf(event.key) !== -1) return true
              if (
                event.key === 'Enter' &&
                !(
                  characterLimit &&
                  tinymceGetContentLength() + 1 > characterLimit
                )
              ) {
                editor.insertContent('&#13;')
              }
              if (event.key === 'Tab') {
                // tab pressed
                if (
                  !characterLimit ||
                  (characterLimit &&
                    tinymceGetContentLength() + 1 < characterLimit)
                ) {
                  editor.insertContent('&emsp;&emsp;')
                }
                event.preventDefault()
                event.stopPropagation()
                return false
              }
              if (
                characterLimit &&
                tinymceGetContentLength() + 1 > characterLimit
              ) {
                event.preventDefault()
                event.stopPropagation()
                return false
              }
              return true
            })
            editor.on('paste', event => {
              const contentLength = tinymceGetContentLength()
              const inputData = event.clipboardData.getData('Text')
              if (
                characterLimit &&
                inputData.length + contentLength > characterLimit
              ) {
                alert(
                  `O texto excede o limite de ${characterLimit} caracteres do campo`
                )
                return false
              }
              return true
            })
          }
        }}
        onEditorChange={handleEditorChange}
        onInit={() => {
          setLoading(false)
        }}
        onBlur={handleBlur}
        content={isPlainObject(input.value) ? input.value.value : input.value}
        value={input.value}
      />
      {error &&
        (touched || touch) && (
          <span className={classes.error}>Campo Obrigatório *</span>
        )}
    </div>
  )
}

export default SimpleEditorInput
