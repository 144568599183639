import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import SettingsLayoutView from '../components/view/SettingsLayoutView'
import { getSchools, updateLayoutSchool } from '../store/school/actions'
import { roles } from '../utils/constants'

const mapStateToProps = ({ auth, school }) => {
  return {
    schoolsList: school.list,
    userOccupations: auth.currentOccupation
  }
}

const mapDispatchToProps = {
  getSchools,
  updateLayoutSchool
}

const SettingsLayoutPage = ({
  getSchools,
  schoolsList,
  updateLayoutSchool,
  userOccupations
}) => {
  const isAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)

  useEffect(
    () => {
      isAdmin && getSchools()
    },
    [isAdmin]
  )

  return (
    <div>
      <SettingsLayoutView
        updateLayoutSchool={updateLayoutSchool}
        schoolsList={schoolsList}
        isAdmin={isAdmin}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsLayoutPage)
