import React from 'react'
import SearchMaterialsForm from '../components/form/SearchMaterialsForm.js'
import CreateMaterialForm from '../components/form/CreateMaterialForm'
import MaterialReportView from '../components/view/MaterialReportView.js'
import MaterialView from '../components/view/MaterialView'
import { connect } from 'react-redux'
import {
  getMaterialById,
  exportMaterialReportRSPA
} from '../store/materials/actions.js'
import { CircularProgress } from '@material-ui/core'
import { isEmpty } from 'lodash'

const MaterialsPage = ({
  componentAttribute,
  match,
  getMaterialById,
  currentMaterial,
  isFetching,
  userOccupations,
  exportMaterialReportRSPA
}) => {
  React.useEffect(
    () => {
      if (match.params.materialId) {
        getMaterialById({ id: match.params.materialId })
      }
    },
    [match.params.materialId]
  )

  if (componentAttribute?.isCreate === true) {
    return <CreateMaterialForm />
  }

  if (match.params.materialId) {
    // get current material
    if (componentAttribute?.isEdit === true) {
      if (isEmpty(currentMaterial)) {
        return <CircularProgress color='secondary' />
      } else {
        return <CreateMaterialForm currentMaterial={currentMaterial} isEdit />
      }
    } else if (componentAttribute?.isReport === true) {
      return (
        <MaterialReportView
          exportMaterialReportRSPA={exportMaterialReportRSPA}
          userOccupations={userOccupations}
          isFetching={isFetching}
          currentMaterial={currentMaterial}
          materialId={match.params.materialId}
        />
      )
    }
    return (
      <MaterialView
        currentMaterial={currentMaterial}
        materialId={match.params.materialId}
      />
    )
  }
  return <SearchMaterialsForm />
}

const mapStateToProps = state => {
  const { materials, school } = state
  return {
    userOccupations: state.auth.currentOccupation,
    currentMaterial: materials?.currentItem,
    school: school.currentSchool && school.currentSchool.school_id,
    isFetching: materials.isFetching
  }
}

const mapDispatchToProps = dispatch => ({
  getMaterialById: data => dispatch(getMaterialById(data)),
  exportMaterialReportRSPA: data =>
    dispatch(exportMaterialReportRSPA.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MaterialsPage)
