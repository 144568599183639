import { once } from 'lodash'

import { instance as axiosInstance } from '../utils/jsonApi'
import { LOGOUT as logout } from '../store/auth/actions'

const clearUserOnAuthErrors = once(store => {
  axiosInstance.interceptors.response.use(
    response => response,
    /**
     * This is a central point to handle all
     * error messages generated by HTTP
     * requests
     */
    error => {
      const { response } = error
      /**
       * If token is either expired, not provided or invalid
       * then redirect to login. On server side the error
       * messages can be changed on app/Providers/EventServiceProvider.php
       */
      const isUnauthorizedError = [401].indexOf(response.status) > -1
      const isAuthEndpoint = error.config.url.includes('auth')

      if (isUnauthorizedError && !isAuthEndpoint) {
        store.dispatch(logout())
      }

      return Promise.reject(error)
    }
  )
})

export const onAuthErrors = store => next => action => {
  clearUserOnAuthErrors(store)
  next(action)
}

/**
 * This set the token on two moments: On localStorage rehydrate
 * and on user sign in.
 * TODO: Set the token on signup too
 */
export const addTokenToRequest = store => next => action => {
  const isStorageRehydrate = action.type === 'persist/REHYDRATE'
  const isUserSignedIn = action.type === 'auth:SIGN_IN_SUCCESSFUL'
  const isLogged = action.payload?.auth?.isLogged
  const currentOccupation = store.getState()?.auth?.currentOccupation?.[0]
  if (currentOccupation) {
    axiosInstance.defaults.headers.common[
      'platform-info'
    ] = `Device Web; Role ${currentOccupation}`
  }

  if (isStorageRehydrate && action.payload && action.payload.headers) {
    axiosInstance.defaults.headers.common['access-token'] =
      action.payload.headers['access-token']
    axiosInstance.defaults.headers.common['client'] =
      action.payload.headers['client']
    axiosInstance.defaults.headers.common['uid'] = action.payload.headers['uid']
  }

  if (isStorageRehydrate && isLogged) {
    axiosInstance.defaults.headers.common['access-token'] =
      action.payload.auth.credentials['access-token']
    axiosInstance.defaults.headers.common['client'] =
      action.payload.auth.credentials['client']
    axiosInstance.defaults.headers.common['uid'] =
      action.payload.auth.credentials['uid']
  }

  if (isUserSignedIn && action.payload && action.payload.userData.headers) {
    axiosInstance.defaults.headers.common['access-token'] =
      action.payload.userData.headers['access-token']
    axiosInstance.defaults.headers.common['client'] =
      action.payload.userData.headers['client']
    axiosInstance.defaults.headers.common['uid'] =
      action.payload.userData.headers['uid']
  }

  next(action)
}
