import React from 'react'
import { connect } from 'react-redux'
import { makeStyles, styled } from '@material-ui/core/styles'
import { Switch, FormGroup, FormControlLabel } from '@material-ui/core'
import {
  getSchoolConfig,
  changeSchoolConfig,
  clearSchoolConfig
} from '../store/school/actions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Field, Form, reduxForm, change, formValueSelector } from 'redux-form'
import { roles } from '../utils/constants'
import AsyncSelect from '../components/shared/AsyncSelect'

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Montserrat, Source Sans Pro',
    width: '100%',
    padding: '40px 30px',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    '& .MuiFormControlLabel-root': {
      margin: 0
    }
  },
  pageLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 20,
    '& h1': {
      fontSize: 32,
      color: '#386093',
      fontWeight: 700,
      margin: 0
    },
    '& span': {
      maxWidth: 650,
      color: '#555555',
      fontSize: 16
    }
  },
  horizontalBar: {
    width: '96%',
    height: 1,
    border: '1px solid #CED6E0',
    marginTop: 24
  },
  switchSection: {
    display: 'flex'
  },
  switchLabel: {
    marginLeft: 13,
    '& h2': {
      fontWeight: 600,
      fontSize: 18,
      margin: 0
    },
    '& span': {
      fontSize: 14,
      fontWeight: 400,
      margin: 0,
      color: '#868E96',
      maxWidth: 380
    }
  },
  configTitle: {
    marginTop: 36,
    marginBottom: 47,
    fontWeight: 700,
    fontSize: 20,
    color: '#375F92',
    margin: 0
  },
  configSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  },
  schoolField: {
    marginTop: 30,
    minWidth: '270px',
    maxWidth: '20%',
    '& label': {
      fontWeight: 700
    }
  }
}))

const CustomSwitch = styled(props => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#9DD2DA',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: 'rgb(233, 233, 234)',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}))

const ModerationPage = ({
  currentSchool,
  getSchoolConfig,
  // eslint-disable-next-line camelcase
  report_moderation,
  // eslint-disable-next-line camelcase
  event_moderation,
  // eslint-disable-next-line camelcase
  message_moderation,
  isFetching,
  changeSchoolConfig,
  schoolConfigId,
  userOccupations,
  touch,
  schoolValue,
  change,
  clearSchoolConfig
}) => {
  const classes = useStyles()

  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const [reportCheck, setReportCheck] = React.useState(null)
  const [eventCheck, setEventCheck] = React.useState(null)
  const [messageCheck, setMessageCheck] = React.useState(null)
  const selectSchoolRef = React.useRef(null)

  React.useEffect(() => {
    clearSchoolConfig()
  }, [])
  React.useEffect(
    () => {
      setReportCheck(report_moderation)
      setEventCheck(event_moderation)
      setMessageCheck(message_moderation)
    },
    // eslint-disable-next-line camelcase
    [report_moderation, event_moderation, message_moderation]
  )

  React.useEffect(
    () => {
      if (schoolValue?.value && isVersarAdmin) {
        getSchoolConfig(schoolValue?.value)
      } else if (currentSchool?.school_id) {
        getSchoolConfig(currentSchool?.school_id)
      } else if (!schoolValue) {
        clearSchoolConfig()
      }
    },
    [currentSchool, schoolValue]
  )

  const handleOptionChange = option => {
    if (schoolConfigId) {
      changeSchoolConfig({
        id: schoolConfigId,
        data: {
          ...(option === 'report'
            ? { moderate_reports: !reportCheck }
            : option === 'messages'
              ? { moderate_messages: !messageCheck }
              : { moderate_events: !eventCheck })
        }
      })
    }
  }

  if (isFetching) {
    return <CircularProgress />
  }
  return (
    <div className={classes.root}>
      <Form>
        <div className={classes.pageLabel}>
          <h1>Moderação</h1>
          <span>
            Permita que o administrador revise e aprove diários de turmas,
            comunicados e mensagens. Ative ou desative conforme a necessidade da
            escola.
          </span>
        </div>
        {isVersarAdmin && (
          <div className={classes.schoolField}>
            <label htmlFor='school_id'>Escola</label>
            <Field
              component={AsyncSelect}
              selectRef={selectSchoolRef}
              defaultOptions={false}
              isClearable
              id='school_id'
              name='school_id'
              placeholder='Digite ou selecione a escola'
              handleAsyncChange={event => {
                change('school_id', event)
              }}
              searchParam='q[name_cont]'
              defaultValue={schoolValue}
              request={{
                path: 'school'
              }}
              touch={touch}
            />
          </div>
        )}
        <div className={classes.horizontalBar} />
        <p className={classes.configTitle}>Configurações de moderação</p>
        {schoolConfigId === 0 || schoolConfigId ? (
          <FormGroup className={classes.configSection}>
            <div className={classes.switchSection}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    checked={reportCheck}
                    onClick={() => handleOptionChange('report')}
                    sx={{ m: 1 }}
                  />
                }
              />
              <div className={classes.switchLabel}>
                <h2>Diário de turmas</h2>
                <span>
                  O administrador revisa e aprova os diários antes da
                  publicação.
                </span>
              </div>
            </div>

            <div className={classes.switchSection}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    checked={eventCheck}
                    onClick={() => handleOptionChange('event')}
                    sx={{ m: 1 }}
                  />
                }
              />
              <div className={classes.switchLabel}>
                <h2>Comunicado</h2>
                <span>
                  Comunicados são revisados e aprovados pelo administrador antes
                  de serem enviados.
                </span>
              </div>
            </div>

            <div className={classes.switchSection}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    checked={messageCheck}
                    onClick={() => handleOptionChange('messages')}
                    sx={{ m: 1 }}
                  />
                }
              />
              <div className={classes.switchLabel}>
                <h2>Mensagens</h2>
                <span>
                  Mensagens entre usuários são monitoradas e aprovadas pelo
                  administrador.
                </span>
              </div>
            </div>
          </FormGroup>
        ) : (
          <>
            A escola não possui sistema de notificação ativo, por favor,
            contacte o suporte
          </>
        )}
      </Form>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('moderation')
  return {
    userOccupations: state.auth.currentOccupation,
    currentSchool: state.school.currentSchool,
    report_moderation: state.school.school_config.report_moderation,
    event_moderation: state.school.school_config.event_moderation,
    message_moderation: state.school.school_config.message_moderation,
    utility_moderation: state.school.school_config.utility_moderation,
    schoolConfigId: state.school.school_config.id,
    isFetching: state.school.isFetching,
    schoolValue: selector(state, 'school_id')
  }
}

const mapDispatchToProps = dispatch => ({
  getSchoolConfig: data => dispatch(getSchoolConfig(data)),
  changeSchoolConfig: data => dispatch(changeSchoolConfig(data)),
  change: (field, data) => dispatch(change('moderation', field, data)),
  clearSchoolConfig: data => dispatch(clearSchoolConfig(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    enableReinitialize: true,
    form: 'moderation'
  })(ModerationPage)
)
