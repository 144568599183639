import 'date-fns'
import React from 'react'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'moment/locale/pt-br'

const useStyles = makeStyles(theme => ({
  input: {
    '& div.MuiOutlinedInput-root': {
      border: 'none',
      borderRadius: '8px',
      height: '48px',
      '& input': {
        minWidth: 'auto'
      }
    },
    '& div.MuiFormControl-marginNormal': {
      margin: 0
    },
    '& fieldset': {
      border: `1px solid ${theme.palette.primary.brightest}`
    },
    '&.MuiPickersCalendarHeader-dayLabel': {
      color: theme.palette.secondary.main
    }
  },
  error: {
    color: theme.palette.danger.main,
    fontSize: '0.8rem'
  }
}))

const YearPickerInput = ({
  meta: { submitting, error, touched },
  input: { onBlur, value, onChange, ...inputProps },
  ...others
}) => {
  const classes = useStyles()

  const handleOnChange = date => {
    const dateYear = moment(date)
    Date.parse(date) ? onChange(dateYear) : onChange(null)
  }

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={'pt-br'}
    >
      <>
        <Grid className={classes.input} container>
          <DatePicker
            autoOk
            disabled={submitting}
            inputVariant='outlined'
            // disableToolbar
            invalidDateMessage='Data inválida'
            margin='normal'
            onChange={handleOnChange}
            value={value || null}
            variant='inline'
            views={['year']}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            {...inputProps}
            {...others}
          />
        </Grid>
        {error && <span className={classes.error}>{error}</span>}
      </>
    </MuiPickersUtilsProvider>
  )
}

export default YearPickerInput
