import { createAction } from '@reduxjs/toolkit'
import { createPromiseAction } from 'redux-saga-promise-actions'
import * as types from './types'

export const getThemes = createAction(types.GET_THEMES)
export const getThemesSuccessful = createAction(types.GET_THEMES_SUCCESSFUL)
export const getThemesFailure = createAction(types.GET_THEMES_FAILURE)
export const getThemeById = createAction(types.GET_THEME_BY_ID)
export const getTheme = createAction(types.GET_THEME)
export const getThemeByIdSuccessful = createAction(
  types.GET_THEME_BY_ID_SUCCESSFUL
)
export const getThemeByIdFailure = createAction(types.GET_THEME_BY_ID_FAILURE)
export const getThemeContents = createAction(types.GET_THEME_CONTENTS)
export const getThemeContentsSuccessful = createAction(
  types.GET_THEME_CONTENTS_SUCCESFUL
)
export const getThemeContentsFailure = createAction(
  types.GET_THEME_CONTENTS_FAILURE
)
export const getThemeSegments = createAction(types.GET_THEME_SEGMENTS)
export const getThemeSegmentsSuccessful = createAction(
  types.GET_THEME_SEGMENTS_SUCCESSFUL
)
export const getThemesSegmentsFailure = createAction(
  types.GET_THEME_SEGMENTS_FAILURE
)
export const createTheme = createAction(types.CREATE_THEME)
export const createThemeSuccessful = createAction(types.CREATE_THEME_SUCCESSFUL)
export const createThemeFailure = createAction(types.CREATE_THEME_FAILURE)

export const editTheme = createAction(types.EDIT_THEME)
export const editThemeSuccessful = createAction(types.EDIT_THEME_SUCCESSFUL)
export const editThemeFailure = createAction(types.EDIT_THEME_FAILURE)

export const deleteTheme = createAction(types.DELETE_THEME)
export const deleteThemeSuccessful = createAction(types.DELETE_THEME_SUCCESSFUL)
export const deleteThemeFailure = createAction(types.DELETE_THEME_FAILURE)

export const cloneTheme = createAction(types.CLONE_THEME)
export const cloneThemeSuccessful = createAction(types.CLONE_THEME_SUCCESSFUL)
export const cloneThemeFailure = createAction(types.CLONE_THEME_FAILURE)

export const clearCurrentTheme = createAction(types.CLEAR_CURRENT_THEME)
export const clearClonedTheme = createAction(types.CLEAR_CLONED_THEME)

export const newGetThemes = createAction('NEW_GET_THEMES')
export const newGetThemesSuccessful = createAction('NEW_GET_THEMES_SUCCESSFUL')
export const newGetThemesFailure = createAction('NEW_GET_THEMES_FAILURE')

export const createThemeRSPA = createPromiseAction('rspa: CREATE_THEME')()
export const newGetThemeById = createAction('NEW_GET_THEME_BY_ID')
export const newGetThemeByIdSuccessful = createAction(
  'NEW_GET_THEME_BY_ID_SUCCESSFUL'
)
export const newGetThemeByIdFailure = createAction(
  'NEW_GET_THEME_BY_ID_FAILURE'
)

export const newGetThemeContents = createAction('NEW_GET_THEME_CONTENTS')
export const newGetThemeContentsSuccessful = createAction(
  'NEW_GET_THEME_CONTENTS_SUCCESSFUL'
)
export const newGetThemeContentsFailure = createAction(
  'NEW_GET_THEME_CONTENTS_FAILURE'
)

export const getThemesRSPA = createPromiseAction('rspa: GET_THEMES')()
export const getSegmentByModuleRSPA = createPromiseAction(
  'rspa: GET_SEGMENT_BY_MODULE'
)()
