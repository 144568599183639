import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 1220
  },
  paper: {
    padding: theme.spacing(2),
    margin: 0,
    borderRadius: '8px',
    lineHeight: '20.11px !important',
    fontSize: 16,
    minWidth: 300,
    paddingLeft: '1.5rem',
    maxWidth: '67vw',
    paddingTop: '3rem',
    '& h2': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '80vw'
    }
  },
  cardContainer: {
    alignItems: 'center',
    background: '#F6F7F8',
    borderRadius: 8,
    minWidth: 280,
    minHeight: 116,
    margin: 10,
    padding: '1rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& p': {
      color: '#555555',
      marginBottom: 0
    },
    '& h2': {
      margin: 0
    }
  },
  backContainer: {
    margin: '1rem 10px 2rem',
    textAlign: 'end'
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const ModuleView = ({ currentModule, currentTheme, module }) => {
  const history = useHistory()
  const classes = useStyles()
  const grade =
    currentModule?.included &&
    currentModule.included.filter(include => include.type === 'grades')
  const cover =
    currentModule?.included &&
    currentModule.included.filter(include => include.type === 'covers')

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className={classes.cardContainer}>
              <h2>Título do módulo</h2>
              <p>{module.title}</p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.cardContainer}>
              <h2>Série</h2>
              <p>{grade && grade[0]?.attributes?.name}</p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.cardContainer}>
              <h2>Cor</h2>
              <p>
                <Tooltip title={module.color} aria-label={module.color}>
                  <input
                    type='color'
                    value={module.color}
                    id={module.color}
                    disabled
                  />
                </Tooltip>
              </p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.cardContainer}>
              <h2>Descrição</h2>
              <p>{module.description}</p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.cardContainer}>
              <h2>Capa</h2>
              <p>
                {cover &&
                  cover[0] &&
                  cover[0].attributes && (
                    <img
                      src={cover[0].attributes.image_url}
                      alt='cover'
                      width='270'
                      height='149'
                    />
                  )}
              </p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.cardContainer}>
              <h2>Temas</h2>
              <span>
                {module && module.themes_order?.length === 0 ? (
                  <p>Sem temas</p>
                ) : (
                  currentTheme &&
                  currentTheme.length > 0 &&
                  currentTheme.map(theme => (
                    <p key={theme.id}>{theme.attributes.title}</p>
                  ))
                )}
              </span>
            </div>
          </Grid>
          <Grid item md={12}>
            <div className={classes.backContainer}>
              <Button
                variant='outlined'
                className={classes.backBtn}
                type='button'
                onClick={() => history.goBack()}
              >
                Voltar
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default ModuleView
