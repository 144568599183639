import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { StepConnector, Tooltip } from '@material-ui/core'
import StyledSVG from './StyledSVG'
import styled from '@emotion/styled'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiStepConnector-alternativeLabel': {
      top: 24
    },
    '& .MuiStepConnector-line': {
      margin: '0px 14px',
      borderTopWidth: '3px'
    }
  }
}))

const FormProgress = ({
  totalSteps,
  steps,
  currentStep,
  setCurrentStep,
  showTooltip,
  tooltipIndex,
  tooltipMessage
}) => {
  const classes = useStyles()

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .stepDiv': {
      '& path': {
        stroke: '#FFFFFF',
        strokeWidth: 1.5
      },
      width: 36,
      height: 36,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor:
        ownerState.active || ownerState.completed ? '#7AC2CD' : '#CED6E0'
    },
    ...(ownerState.active || ownerState.completed
      ? {
          '& .activeStep': {
            width: 46,
            height: 46,
            borderRadius: '50%',
            border: '0.75px solid #7AC2CD',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }
        }
      : {})
  }))

  function ColorlibStepIcon (props, stepIcon) {
    const { active, completed, className, icon } = props
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        <div className='activeStep'>
          <div className='stepDiv'>
            {stepIcon ? <StyledSVG src={stepIcon} /> : <span>{icon}</span>}
          </div>
        </div>
      </ColorlibStepIconRoot>
    )
  }
  const StepConnectorStyled = styled(StepConnector)(
    ({ theme, ownerState }) => ({
      top: '24px',
      left: 'calc(-50% + 20px)',
      right: 'calc(50% + 20px)',
      position: 'absolute',
      ...(currentStep === ownerState.index - 1
        ? {
            '& .MuiStepConnector-line': {
              borderColor: 'transparent',
              borderImage:
                'linear-gradient(to right, #7AC2CD 50%, #CED6E0 50%) 1'
            }
          }
        : currentStep > ownerState.index - 1
          ? {
              '& .MuiStepConnector-line': {
                borderColor: '#7AC2CD'
              }
            }
          : { borderColor: '#CED6E0' })
    })
  )

  const Connector = props => {
    return <StepConnectorStyled ownerState={props} />
  }

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={currentStep}
        connector={<Connector />}
      >
        {steps.map(({ key, icon }, index) => {
          return (
            <Tooltip
              key={key}
              placement='top'
              title={
                showTooltip && index === tooltipIndex ? tooltipMessage : ''
              }
            >
              <Step key={key}>
                <StepLabel
                  StepIconComponent={event => ColorlibStepIcon(event, icon)}
                >
                  {key}
                </StepLabel>
              </Step>
            </Tooltip>
          )
        })}
      </Stepper>
    </div>
  )
}

export default FormProgress
