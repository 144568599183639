export const GET_THEMES = 'themes/GET_THEMES'
export const GET_THEMES_SUCCESSFUL = 'themes/GET_THEMES_SUCCESSFUL'
export const GET_THEMES_FAILURE = 'themes/GET_THEMES_FAILURE'
export const GET_THEME_BY_ID = 'themes/GET_THEME_BY_ID'
export const GET_THEME_BY_ID_SUCCESSFUL = 'themes/GET_THEME_BY_ID_SUCCESSFUL'
export const GET_THEME_BY_ID_FAILURE = 'themes/GET_THEME_BY_ID_FAILURE'
export const GET_THEME = 'themes/GET_THEME'
export const GET_THEME_CONTENTS = 'themes/GET_THEME_CONTENTS'
export const GET_THEME_CONTENTS_SUCCESFUL =
  'themes/GET_THEME_CONTENTS_SUCCESFUL'
export const GET_THEME_CONTENTS_FAILURE = 'themes/GET_THEME_CONTENTS_FAILURE'
export const GET_THEME_SEGMENTS = 'themes/GET_THEME_SEGMENTS'
export const GET_THEME_SEGMENTS_SUCCESSFUL =
  'themes/GET_THEMES_SEGMENTS_SUCCESSFUL'
export const GET_THEME_SEGMENTS_FAILURE = 'themes/GET_THEMES_SEGMENTS_FAILURE'

export const CREATE_THEME = 'themes/CREATE_THEME'
export const CREATE_THEME_SUCCESSFUL = 'themes/CREATE_THEME_SUCCESSFUL'
export const CREATE_THEME_FAILURE = 'themes/CREATE_THEME_FAILURE'

export const EDIT_THEME = 'themes/EDIT_THEME'
export const EDIT_THEME_SUCCESSFUL = 'themes/EDIT_THEME_SUCCESSFUL'
export const EDIT_THEME_FAILURE = 'themes/EDIT_THEME_FAILURE'

export const DELETE_THEME = 'themes/DELETE_THEME'
export const DELETE_THEME_SUCCESSFUL = 'themes/DELETE_THEME_SUCCESSFUL'
export const DELETE_THEME_FAILURE = 'themes/DELETE_THEME_FAILURE'

export const CLONE_THEME = 'themes/CLONE_THEME'
export const CLONE_THEME_SUCCESSFUL = 'themes/CLONE_THEME_SUCCESSFUL'
export const CLONE_THEME_FAILURE = 'themes/CLONE_THEME_FAILURE'

export const CLEAR_CURRENT_THEME = 'themes/CLEAR_CURRENT_THEME'
export const CLEAR_CLONED_THEME = 'themes/CLEAR_CLONED_THEME'
