import { createReducer } from '@reduxjs/toolkit'
import * as types from './types'

const initialState = {
  items: [],
  isFetching: false,
  isError: false,
  errorMessage: ''
}

const cyclesReducer = createReducer(initialState, {
  [types.GET_CYCLES]: (state, action) => ({
    ...state,
    items: [],
    isFetching: true
  }),
  [types.GET_CYCLES_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: action.payload,
    isFetching: false
  }),
  [types.GET_CYCLES_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  })
})

export default cyclesReducer
