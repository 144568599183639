import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'

const useStyles = makeStyles(theme => ({
  root: {
    width: 320,
    [theme.breakpoints.up('sm')]: {
      width: 500
    }
  }
}))

function valueText (value) {
  return `${value}`
}

export default function SliderInput ({
  input,
  step,
  marks,
  min,
  max,
  handleSliderChange
}) {
  const classes = useStyles()
  const [value, setValue] = React.useState(input?.value)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <Slider
        {...input}
        aria-labelledby='discrete-slider-custom'
        valueLabelDisplay='auto'
        getAriaValueText={valueText}
        step={step}
        marks={marks}
        min={min}
        max={max}
        value={typeof value === 'number' ? value : 0}
        onChange={handleSliderChange}
        onChangeCommitted={handleChange}
      />
    </div>
  )
}
