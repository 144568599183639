import React, { useEffect } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Field, Form, formValueSelector, reduxForm, change } from 'redux-form'

import Button from '../button/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CustomTable from '../table/CustomTable'
import { NoReportsDiv } from '../../pages/ReportsPage'
import StyledSVG from '../shared/StyledSVG'
import doneSVG from '../../assets/icons/status-confirmation.svg'
import pendingSVG from '../../assets/icons/status-pending.svg'
import openSVG from '../../assets/icons/status-open.svg'
import generateReportSVG from '../../assets/icons/generate-report.svg'
import moderatingSVG from '../../assets/icons/moderating-icon.svg'
import filterSVG from '../../assets/icons/filter-icon.svg'
import { Tooltip } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import AsyncSelect from '../shared/AsyncSelect'
import MaterialInput from '../shared/MaterialInput'
import { reportStatus, reportsShift } from '../../utils/constants'
import { generateReports } from '../../store/reports/actions'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '40px 30px',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  action: {
    cursor: 'pointer'
  },
  actions: {
    display: 'flex',
    flex: 'row',
    justifyContent: 'space-around'
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 24,
    marginBottom: 16,
    [theme.breakpoints.down('426')]: {
      fontSize: 16
    }
  },
  status: {
    marginLeft: '5px',
    [theme.breakpoints.down('426')]: {
      display: 'none'
    }
  },
  statusRoot: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('426')]: {
      justifyContent: 'center'
    }
  },
  pageLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 20,
    '& h1': {
      fontSize: 32,
      color: '#386093',
      fontWeight: 700,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 45,
    [theme.breakpoints.down('890')]: {
      flexDirection: 'column-reverse',
      gap: 16
    }
  },
  searchSection: {
    marginTop: 40
  },
  searchDiv: {
    '& .MuiInputBase-root': {
      width: 300,
      [theme.breakpoints.down('426')]: {
        maxWidth: '250px'
      },
      [theme.breakpoints.down('330')]: {
        maxWidth: 220
      }
    }
  },
  filtersSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  filterLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  advancedFilter: {
    overflow: 'hidden',
    transition: 'height 0.5s ease-in-out',
    [theme.breakpoints.down('1000')]: {
      overflow: 'scroll'
    }
  },
  filtersDisplay: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24
  },
  searchGroup: {
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    gap: 24,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      gap: 12
    }
  },
  searchField: {
    '& .select__menu-list': {
      maxHeight: '150px'
    },
    '& div': {
      '& .select__control': {
        minWidth: 200
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        minWidth: 'unset',
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    },
    [theme.breakpoints.down('1000')]: {
      width: '100% !important',
      '& div': {
        // redux form 'Field' componente
        '& div': {
          '& .select__control, .select__value-container, .select__indicators, .MuiInputBase-root': {
            minHeight: '35px',
            height: '35px'
          },
          maxWidth: '100%'
        }
      }
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: 8,
    '& button': {
      marginRight: 0,
      width: 149
    },
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'flex-start',
      marginTop: 15,
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    [theme.breakpoints.down('350')]: {
      '& button': {
        width: '170px !important'
      }
    }
  },
  horizontalBar: {
    marginBottom: 27,
    width: '96%',
    height: 1,
    border: '1px solid #ADB8CC'
  },
  clearBtn: {
    backgroundColor: '#EF7C8F',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  createBtn: {
    backgroundColor: '#00ACDB',
    width: 146,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  actionsSection: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& path': {
      stroke: '#868E96'
    },
    '& circle': {
      stroke: '#868E96'
    }
  }
}))
const ReportsTemplateListView = ({
  getReports,
  reports,
  generateReports,
  defaultParams,
  handleGenerateReport,
  schoolValue,
  classroomValue,
  userId,
  nameValue,
  handleSubmit,
  touch,
  reset
}) => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('426'))
  const [openMoreFilter, setOpenMoreFilter] = React.useState(false)
  const selectNameRef = React.useRef(null)
  const selectClassroomRef = React.useRef(null)

  const searchParams = {
    ...defaultParams,
    'q[title_eq]': nameValue,
    'q[classroom_id_eq]': classroomValue,
    'q[classroom_school_id_eq]': schoolValue
  }

  const handleSearch = () => {
    getReports({ params: searchParams })
  }

  const handleChange = (event, input) => {
    touch()
    let newValue
    if (Array.isArray(event)) {
      newValue = event?.map(v => v)
    } else {
      newValue = event ? event.value : ''
    }
    change(input.name, newValue)
  }

  const handleChangeTitle = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      getReports({ params: searchParams })
    }
  }

  useEffect(() => {
    handleSearch()
  }, [])

  const handleClearFields = () => {
    reset()
    selectClassroomRef.current?.select.clearValue()
    selectNameRef.current?.select.clearValue()
    getReports({ params: defaultParams })
  }

  const columns = [
    {
      key: 'title',
      name: 'Título',
      render: row => {
        let title
        matches ? (title = row.title) : (title = `${row.title.slice(0, 10)}...`)
        return title
      }
    },
    {
      key: 'classrooms',
      name: 'Turmas',
      render: row => {
        // preview
        return row.classroom[0]?.name
      }
    },
    {
      key: 'date',
      name: 'Data',
      render: row => {
        return moment(row.created_at).format('DD/MM/YYYY')
      }
    },
    {
      key: 'shift',
      name: 'Turno',
      render: row => {
        return `${reportsShift[row.classroom?.[0]?.shift].slice(0, 3)}.`
      }
    },
    {
      key: 'totalStudent',
      name: 'Alunos',
      render: row => {
        return row.students_count
      }
    },
    {
      key: 'status',
      name: 'Status',
      render: function render (row) {
        const statusProperties = (status, isForColor) => {
          switch (status) {
            case 'done': {
              if (isForColor) return '#00ACDB'
              return doneSVG
            }
            case 'pending':
              if (isForColor) return '#F69E9E'
              return pendingSVG
            case 'open':
              if (isForColor) return '#C4C4C4'
              return openSVG
            case 'waiting_moderation': {
              if (isForColor) return '#F1925B'
              return moderatingSVG
            }
            default:
              return ''
          }
        }
        return (
          <div className={classes.statusRoot}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledSVG
                height={20}
                width={20}
                src={statusProperties(row.status)}
              />
            </div>
            <div>
              <span
                style={{ color: statusProperties(row.status, true) }}
                className={classes.status}
              >
                {reportStatus[row.status]}
              </span>
            </div>
          </div>
        )
      }
    },
    {
      key: 'action',
      name: 'Ações',
      render: function render (row) {
        return (
          <Tooltip title='Gerar'>
            <div
              className={classes.action}
              onClick={() => {
                handleGenerateReport(
                  row.id,
                  row.status,
                  row.user_id,
                  generateReports
                )
                setTimeout(() => {
                  localStorage.getItem('origeNavegate')
                    ? history.push(
                        `/report_template/${row.id}?name=${localStorage.getItem(
                          'origeNavegate'
                        )}`
                      )
                    : history.push(`/report_template/${row.id}`)
                }, 1000)
              }}
            >
              <StyledSVG src={generateReportSVG} width={20} height={20} />
            </div>
          </Tooltip>
        )
      }
    }
  ]

  const getColumns = () => {
    const columnKeys = matches
      ? ['classrooms', 'date', 'totalStudent', 'status', 'title', 'action']
      : ['title', 'date', 'shift', 'status', 'action']
    return columns.filter(column => columnKeys.includes(column.key))
  }
  return (
    <div className={classes.root}>
      <Form onSubmit={handleSubmit}>
        <div className={classes.pageLabel}>
          <h1>Diários do professor</h1>
          <span>Explore os diários de suas turmas.</span>
        </div>
        {/* Header */}
        <div className={classes.searchSection}>
          <div className={classes.searchDiv}>
            <strong>Busca</strong>
            <Field
              style={{ marginTop: 10 }}
              component={MaterialInput}
              selectRef={selectNameRef}
              name='name'
              endBtnFunction={() => {
                getReports({ params: searchParams })
              }}
              onKeyDown={handleChangeTitle}
              placeholder={'Título do diário'}
              type='text'
            />
          </div>
        </div>
        <div className={classes.filtersSection}>
          <div className={classes.filterLabel}>
            <StyledSVG src={filterSVG} width={12} height={12} />
            <p
              onClick={() => setOpenMoreFilter(prev => !prev)}
              style={{
                cursor: 'default',
                fontWeight: 700,
                fontSize: 12,
                color: '#386093'
              }}
            >
              Filtros avançados
            </p>
            {!openMoreFilter ? (
              <KeyboardArrowDownIcon
                onClick={() => setOpenMoreFilter(prev => !prev)}
                style={{ height: 12, width: 12 }}
              />
            ) : (
              <KeyboardArrowUpIcon
                onClick={() => setOpenMoreFilter(prev => !prev)}
                style={{ height: 12, width: 12 }}
              />
            )}
          </div>
          <div
            className={classes.advancedFilter}
            style={{
              height: openMoreFilter ? '250px' : '0px'
            }}
          >
            <div className={classes.filtersDisplay}>
              <div
                className={classes.searchGroup}
                style={{ opacity: openMoreFilter ? '1' : '0' }}
              >
                <div className={classes.searchField}>
                  <label htmlFor={`classroom_id`}>Turma</label>
                  <Field
                    component={AsyncSelect}
                    defaultOptions={false}
                    id='classroom_id'
                    name='classroom_id'
                    cacheUniqs={[schoolValue]}
                    selectRef={selectClassroomRef}
                    placeholder='Digite ou selecione'
                    handleAsyncChange={handleChange}
                    searchParam='q[name_cont]'
                    request={{
                      path: 'classroom',
                      params: {
                        'q[teachers_user_id_eq]': userId,
                        'q[school_id_in]': schoolValue,
                        'q[year_lteq]': new Date().getFullYear() + 1,
                        'q[year_gteq]': new Date().getFullYear()
                      }
                    }}
                    touch={touch}
                  />
                </div>
              </div>
              <div
                style={{
                  opacity: openMoreFilter ? '1' : '0',
                  transition: 'opacity 0.3s ease-in-out'
                }}
                className={classes.buttons}
              >
                <Button
                  onClick={() => handleClearFields()}
                  className={classes.clearBtn}
                >
                  Limpar
                </Button>
                <Button
                  className={classes.applyBtn}
                  type='button'
                  onClick={() => handleSearch()}
                >
                  Aplicar
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.horizontalBar} />
        <div>
          <h2>Lista de relatórios</h2>
          <CustomTable
            from={'reports-list'}
            columns={getColumns()}
            data={reports.items}
            params={defaultParams}
            fetchItems={getReports}
            isFetching={reports.isFetching}
            pagination={reports.pagination}
            noDataAlert={<NoReportsDiv />}
            border={'none'}
            bodyBorder={'none'}
            headCellRadius
            headColor={'rgba(173, 184, 204, 0.3)'}
          />
        </div>
      </Form>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('searchReportTemplateList')
  return {
    schoolValue:
      selector(state, 'school_id') ||
      (state.school.currentSchool && state.school.currentSchool.school_id),
    classroomValue: selector(state, 'classroom_id'),
    nameValue: selector(state, 'name'),
    userId: state.auth.user.data.id
  }
}

const mapDispatchToProps = {
  generateReports
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'searchReportTemplateList'
  })(ReportsTemplateListView)
)
