import { useLocation } from 'react-router-dom'
import { saveAs } from 'file-saver'
import Compressor from 'compressorjs'
import JSZip from 'jszip'

export const handleAddAvatar = (
  event,
  setUploadingInProgress,
  alert,
  changeField,
  // The import of heic cannot be done inside the 'helpers.js' because trhow the error "Worker is not definned" in the tests
  heic2any
) => {
  const file = event.target.files[0]
  const compressImage = async file => {
    try {
      const compressedBlob = await new Promise((resolve, reject) => {
        /* eslint-disable no-new */
        new Compressor(file, {
          quality: 0.6,
          maxWidth: 800,
          maxHeight: 800,
          success (result) {
            resolve(result)
          },
          error (error) {
            reject(error)
          }
        })
      })
      return compressedBlob
    } catch (error) {
      console.error(error)
    }
  }
  async function setAllFieldsOfImg (file) {
    setUploadingInProgress(false)
    let fileCompressed = file
    if (file.size > 20000000) {
      fileCompressed = await compressImage(file)
    }
    if (fileCompressed.size > 21000000) {
      alert('A imagem anexada deve ser menor que 20MB.', 'error')
    } else {
      changeField(fileCompressed)
    }
  }
  if (
    file &&
    file.name.split('.')[file.name.split('.').length - 1].toLowerCase() ===
      'heic'
  ) {
    setUploadingInProgress(true)
    heic2any({
      blob: file,
      toType: 'image/jpeg'
    })
      .then(resultBlob => {
        let fileJpg = new File([resultBlob], `converted-${file.name}`, {
          type: 'image/jpeg',
          lastModified: new Date().getTime()
        })
        setAllFieldsOfImg(fileJpg)
      })
      .catch(error => {
        console.error(error)
        setUploadingInProgress(false)
        alert('Erro ao adicionar imagem', 'error')
      })
  } else if (file) {
    setAllFieldsOfImg(file)
  }
}

export const debounce = (func, wait, immediate) => {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const csvToArray = (str, delim = ',') => {
  const headers = str.slice(0, str.indexOf('\n')).split(delim)
  const rows = str.slice(str.indexOf('\n') + 1).split('\n')

  const newArray = rows.map(row => {
    const values = row.split(delim)
    const eachObject = headers.reduce((obj, header, i) => {
      obj[header] = values[i]
      return obj
    }, {})
    return eachObject
  })
  return newArray
}

export const groupByKey = (items, key) => {
  return items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item]
    }),
    {}
  )
}

export const compareValues = (key, order = 'asc') => {
  return function innerSort (a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return order === 'desc' ? comparison * -1 : comparison
  }
}

export const numberFromText = text => {
  // const charCode = text.charCodeAt(0)
  const charCodes =
    text &&
    text
      .split('')
      .map(char => char.charCodeAt(0))
      .join('')
  return parseInt(charCodes || '', 10)
}

export function useQuery () {
  return new URLSearchParams(useLocation().search)
}

export async function downloadZip (files, zipName) {
  if (files?.length > 0) {
    const zipArchive = new JSZip()
    await Promise.all(
      files.map(async file => {
        await fetch(file.file_url)
          .then(res => res.blob())
          .then(blob =>
            zipArchive.file(
              file.filename,
              new File([blob], { base64: true }),
              file.filename
            )
          )
      })
    )
    zipArchive
      .generateAsync({ type: 'blob' })
      .then(res => saveAs(res, zipName || 'files.zip'))
  }
}

export function downloadImage (file, fileName) {
  const xhr = new XMLHttpRequest()
  xhr.responseType = 'blob'

  xhr.onload = () => {
    const blob = xhr.response
    const url = window.URL.createObjectURL(blob)

    // Crie um link temporário em memória
    const link = document.createElement('a')
    link.href = url

    !file.filename
      ? (link.download = fileName)
      : (link.download = file.filename)
    link.target = '_blank'

    // Dispare um evento de clique no link para iniciar o download
    link.dispatchEvent(new MouseEvent('click'))

    // Libere os recursos
    window.URL.revokeObjectURL(url)
  }

  // Abra a requisição para a URL do arquivo
  !file.file_url ? xhr.open('GET', file) : xhr.open('GET', file.file_url)
  xhr.send()
}

export const convertArrayToObject = (array, key, subject) => {
  const initialValue = {}
  return array.reduce((obj, item) => {
    const currentValue = subject
      ? { [`${item.tag.name}-${item[key]}-${item.subject.name}`]: item }
      : { [`${item.tag.name}-${item[key]}`]: item }
    return {
      ...obj,
      ...currentValue
    }
  }, initialValue)
}

export const areAllPropertiesNullOrUndefined = obj => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] !== null && obj[key] !== undefined) {
        return false
      }
    }
  }
  return true
}
