import { all, call, takeLatest, put } from 'redux-saga/effects'

import * as actions from './actions'
import * as services from './services'

export function * fetchGrades ({ payload }) {
  try {
    const response = yield call(services.fetchGrades, payload)
    yield put(actions.getGradesSuccessful(response))
  } catch (error) {
    yield put(actions.getGradesFailure(error))
  }
}

export function * fetchGradesById ({ payload }) {
  try {
    const response = yield call(services.fetchGradesById, payload)
    yield put(actions.getGradesByIdSuccessful(response.data))
  } catch (error) {
    yield put(actions.getGradesByIdFailure(error))
  }
}

export function * fetchGradeThemeSchedule ({ payload }) {
  try {
    const response = yield call(services.fetchGradeThemeSchedules, payload)
    yield put(actions.getGradeThemeSchedulesSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getGradeThemeSchedulesFailure(error))
  }
}

export function * watchFetchGrades () {
  yield takeLatest(actions.getGrades, fetchGrades)
  yield takeLatest(actions.getGradesById, fetchGradesById)
  yield takeLatest(actions.getGradeThemeSchedules, fetchGradeThemeSchedule)
}

export default function * gradesSagas () {
  yield all([watchFetchGrades()])
}
