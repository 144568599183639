import { all, call, takeLatest, put } from 'redux-saga/effects'

import * as actions from './actions'
import * as services from './services'

export function * fetchCategories ({ payload }) {
  try {
    const response = yield call(services.fetchCategoriesBySegmentId, payload)
    yield put(actions.getCategoriesSuccessful(response.data))
  } catch (error) {
    yield put(actions.getCategoriesFailure(error))
  }
}

export function * watchFetchCategories () {
  yield takeLatest(actions.getCategories, fetchCategories)
}

export default function * categoriesSagas () {
  yield all([watchFetchCategories()])
}
