import React, { useState, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Waypoint } from 'react-waypoint'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ChatIcon from '@material-ui/icons/Chat'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
// import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import FavoriteIcon from '@material-ui/icons/Favorite'

import BasicTimeline from '../shared/Timeline'
import jsonApi from '../../utils/jsonApi'
import HeaderCard from '../card/HeaderCard'
import { useQuery } from '../../utils/helpers'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    '& .Mui-selected': {
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiListItemText-primary': {
      fontWeight: 700
    }
  },
  paper: {
    padding: theme.spacing(2),
    margin: 0,
    borderRadius: '8px',
    lineHeight: '20.11px !important',
    fontSize: 16
  },
  paperRightSide: {
    minHeight: 250,
    maxHeight: 'fit-content',
    maxWidth: '67vw',
    [theme.breakpoints.down('sm')]: {
      width: '85vw'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '85vw'
    }
  },
  paperLeftSide: {
    minWidth: 300,
    padding: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '90vw'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '100vw'
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%'
    }
  },
  paperClassesName: {
    display: 'flex',
    minWidth: '300px',
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    height: '23vh'
  },
  container: {
    paddingLeft: 0,
    marginTop: theme.spacing(8)
  },
  recordImg: {
    objectFit: 'contain',
    margin: '1rem 0',
    width: '30vw',
    [theme.breakpoints.down('md')]: {
      maxWidth: '50vw',
      minWidth: '180px'
    }
  },
  label: {
    fontWeight: 700
  },
  contentBox: {
    backgroundColor: '#F7F7F7',
    color: theme.palette.primary.main,
    padding: '24px',
    marginTop: 15
  },
  archiveBtn: {
    margin: '1.5rem 0',
    border: 'none',
    background: '#FFF',
    padding: '8px 16px',
    '& svg': {
      color: '#979797'
    }
  },
  textIcon: {
    verticalAlign: 'super',
    marginLeft: 6,
    '& button': {
      color: theme.palette.primary.main,
      textTransform: 'none'
    }
  },
  selectFilter: {
    marginRight: 16,
    borderRadius: 8,
    color: theme.palette.primary.main,
    '& fieldset': {
      border: `1px solid ${theme.palette.primary.main}`,
      transform: 'rotate(0.04deg)'
    },
    '& svg.MuiSvgIcon-root.MuiSelect-icon': {
      color: theme.palette.primary.main
    },
    '& div.MuiSelect-root': {
      padding: '8px 32px 8px 8px'
    },
    '& div.MuiInputBase-root': {
      maxHeight: 34
    }
  },
  recordsHeader: {
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    },
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4)
  },
  hrStyle: {
    margin: '1rem 0'
  },
  childrenBox: {
    marginBottom: '1rem'
  },
  avatar: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  nested: {
    marginLeft: theme.spacing(6),
    color: theme.palette.primary.main
  },
  link: {
    fontSize: '14px',
    color: '#555555',
    textDecoration: 'none',
    width: 'fit-content',
    paddingBottom: '4px',
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
      fontWeight: 800,
      background: 'none',
      paddingBottom: 0
    }
  },
  topMenu: {
    '& ul.MuiList-root': {
      display: 'flex',
      flexWrap: 'wrap'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: '67vw'
    },
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column'
    }
  },
  boxList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  participants: {
    marginBottom: '4rem'
  },
  participantsList: {
    width: 'inherit',
    margin: '2rem 0',
    [theme.breakpoints.between('sm', 'lg')]: {
      margin: '2rem 0 2rem 3rem'
    }
  },
  studentList: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr'
    }
  },
  recordsFiles: {
    margin: '1rem 0'
  },
  captions: {
    marginTop: -10
  },
  themeName: {
    cursor: 'pointer'
  },
  typography: {
    padding: theme.spacing(2)
  },
  dataButton: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFF',
    fontWeight: 700,
    margin: 'auto 0',
    padding: '0.5rem'
  },
  cyleCard: {
    padding: '0 1rem 1rem',
    '& h2:first-child': {
      textAlign: 'center'
    },
    '& div > h2': {
      cursor: 'pointer'
    },
    '& h2,h3': {
      color: theme.palette.primary.main
    },
    '& p,span': {
      color: theme.palette.primary.dark
    },
    '& img': {
      border: `1px dashed ${theme.palette.primary.brightest}`,
      borderRadius: 8
    },
    [theme.breakpoints.up('md')]: {
      '& > div': {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        '& div:last-child': {
          justifySelf: 'end'
        }
      }
    }
  },
  favoritedIcon: {
    color: '#E8505B',
    float: 'left'
  },
  backBtn: {
    marginTop: 25,
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const ClassroomView = ({
  classroom,
  classroomId,
  getGradeThemeSchedules,
  themeSchedules
}) => {
  const classes = useStyles()
  const query = useQuery()
  const history = useHistory()
  // classroom records
  const [items, setItems] = useState([])
  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)
  const ITEMS_PER_PAGE = 10
  // records view popup
  const [openId, setOpenId] = React.useState('-1')
  const projectId = process.env.REACT_APP_PROJECT_ID
  const teachers = classroom.teachers
  const children = classroom.children

  useEffect(() => {
    getData()
  }, [])
  const getUserInfo = async ids => {
    return Promise.all(ids.map(id => jsonApi.find('users', id)))
  }
  const getData = async () => {
    if (!hasNextPage) return
    await jsonApi
      .find('teaching_record', '', {
        params: {
          'q[s]': 'created_at desc',
          'page[number]': page,
          'page[size]': ITEMS_PER_PAGE,
          'q[destinations_classroom_id_eq]': classroomId,
          include: 'content,destinations,files,content.theme,views,views.user'
        }
      })
      .then(async res => {
        if (res.data) {
          if (res.meta.total_count === items.length + res.data.length) {
            setHasNextPage(false)
          }
          const responseItems = res.data.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at)
          })
          const userIds = [
            ...new Set(res.data.map(item => item.user_id))
          ].filter(item => item !== null || item !== undefined)
          if (userIds.length) {
            await getUserInfo(userIds).then(userRes => {
              const formattedResponse = responseItems.map(item => {
                const userInfo = userRes.find(
                  userItem => userItem.data.id * 1 === item.user_id
                )
                return {
                  ...item,
                  user: userInfo.data
                }
              })
              setItems(items => [...items, ...formattedResponse])
              setPage(page => page + 1)
            })
          } else {
            setItems(items => [...items, ...responseItems])
            setPage(page => page + 1)
          }
        }
      })
  }
  const loadMoreData = () => {
    if (page > 1) {
      getData()
    }
  }

  const handleClick = event => {
    const currentId = event.currentTarget.id
    setOpenId(prevState => (prevState === currentId ? -1 : currentId))
  }

  const muralActivities = (
    <Grid item xs={12} container className={classes.container}>
      <Grid item sm={12} md={8} style={{ margin: '0 1rem 1rem 0' }}>
        <Paper
          className={`${classes.paper} ${classes.paperLeftSide}`}
          elevation={0}
        >
          <div className={classes.recordsHeader}>
            <Typography
              color='primary'
              variant='h5'
              component='h3'
              className={classes.label}
            >
              Atividade Recente
            </Typography>
            <Select
              className={classes.selectFilter}
              labelId='filter-content'
              id='filter-content'
              variant='outlined'
              value='content'
            >
              <MenuItem value='content'>Ordene por Conteúdo</MenuItem>
            </Select>
          </div>
          {items.map(record => {
            return (
              <React.Fragment key={record.id}>
                <BasicTimeline
                  icon={
                    <ChatIcon style={{ fontSize: '20px', padding: '2px' }} />
                  }
                >
                  <div className={classes.recordsHeader}>
                    <div>
                      <Typography color='primary' variant='p' component='h4'>
                        {record.user && record.user.name}
                      </Typography>
                      <Typography color='primary' variant='body1' component='p'>
                        postou uma nova atividade para{' '}
                        {record.destinations.map(
                          (destination, index) =>
                            !destination.child_id
                              ? 'Todos'
                              : children?.filter(
                                  child =>
                                    String(destination.child_id) ===
                                    String(child.id)
                                )[0]?.name +
                                `${
                                  index < record.destinations.length - 1
                                    ? '; '
                                    : '.'
                                }`
                        )}
                      </Typography>
                    </div>
                    <Paper
                      className={classes.dataButton}
                      component='div'
                      elevation={0}
                    >
                      {moment(record.created_at)
                        .startOf('hour')
                        .fromNow()}
                    </Paper>
                  </div>
                  {record.content && (
                    <Typography color='primary' variant='body1' component='p'>
                      Tema:{' '}
                      <span
                        className={classes.themeName}
                        onClick={() =>
                          history.push(`/themes/${record.content.theme?.id}`)
                        }
                      >
                        {record.content.theme?.title}
                      </span>
                    </Typography>
                  )}
                  <Paper
                    elevation={0}
                    className={`${classes.paper} ${classes.contentBox}`}
                  >
                    {
                      <div style={{ width: '85%', marginLeft: 0 }}>
                        <Typography
                          style={{ fontWeight: 'bold' }}
                          color='primary'
                          variant='body1'
                          component='p'
                        >
                          {record.text}
                        </Typography>
                        {record.files.length > 0 &&
                          record.files.map(file => (
                            <React.Fragment key={file.id}>
                              {file.file_type === 'image' && (
                                <>
                                  <img
                                    src={file.file_url}
                                    alt='imagem'
                                    className={classes.recordImg}
                                  />
                                  <p className={classes.captions}>
                                    {file.caption}
                                  </p>
                                </>
                              )}
                              {file.file_type === 'file' && (
                                <>
                                  <Typography
                                    key={file.id}
                                    className={classes.archiveBtn}
                                    color='primary'
                                    variant='body1'
                                    component='button'
                                  >
                                    <InsertDriveFileIcon style={{}} />{' '}
                                    <span className={classes.textIcon}>
                                      nomedoarquivo.doc
                                    </span>
                                  </Typography>
                                  <p className={classes.captions}>
                                    {file.caption}
                                  </p>
                                </>
                              )}
                              {file.file_type === 'audio' &&
                                file.file_url.slice(-4) !== '.mp4' && (
                                  <>
                                    <audio
                                      style={{
                                        width: '100%',
                                        margin: '1em 0'
                                      }}
                                      controls
                                    >
                                      <source
                                        src={file.file_url}
                                        type='audio/mpeg'
                                      />
                                    </audio>
                                    <p className={classes.captions}>
                                      {file.caption}
                                    </p>
                                  </>
                                )}
                              {(file.file_type === 'video' ||
                                file.file_url.slice(-4) === '.mp4') && (
                                <>
                                  <video
                                    className={`${classes.recordsFiles} ${
                                      classes.recordImg
                                    }`}
                                    controls='controls'
                                  >
                                    <source
                                      src={file.file_url}
                                      type='video/mp4'
                                    />
                                    <source
                                      src={file.file_url}
                                      type='video/ogg'
                                    />
                                    Seu navegador não suporta HTML5
                                  </video>
                                  <p className={classes.captions}>
                                    {file.caption}
                                  </p>
                                </>
                              )}
                              {file.file_type === 'text' && (
                                <>
                                  <p className={classes.recordsFiles}>
                                    {file.file_url}
                                  </p>
                                  <p className={classes.captions}>
                                    {file.caption}
                                  </p>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        <Divider
                          className={classes.hrStyle}
                          variant='middle'
                          component='hr'
                        />
                        <Typography
                          color='primary'
                          variant='body1'
                          component='p'
                        >
                          <VisibilityIcon color='secondary' />{' '}
                          <span className={classes.textIcon}>
                            <Button id={record.id} onClick={handleClick}>
                              Visto por {record.views.length}/{record
                                .destinations.length === 1
                                ? record.destinations[0].child_id
                                  ? 1
                                  : classroom.children?.length
                                : record.destinations.length}
                            </Button>
                          </span>
                        </Typography>
                        <Collapse in={openId === record.id}>
                          <Paper elevation={0} className={classes.paper}>
                            {record.views?.length > 0
                              ? record.views.map((view, index) => (
                                <div key={view.id}>
                                  {view.user.name +
                                      ' em ' +
                                      moment(view.seen_at).format(
                                        'DD/MM/YYYY HH:mm'
                                      )}
                                </div>
                                ))
                              : ''}
                          </Paper>
                        </Collapse>
                      </div>
                    }
                  </Paper>
                </BasicTimeline>
              </React.Fragment>
            )
          })}
        </Paper>
        {hasNextPage && (
          <Waypoint onEnter={loadMoreData}>
            <CircularProgress color='secondary' />
          </Waypoint>
        )}
      </Grid>
      <Grid item xs={4} sm container alignItems='flex-start'>
        <Grid item xs container direction='column'>
          <Grid item xs style={{ marginBottom: '1rem' }}>
            <Paper
              className={`${classes.paper} ${classes.paperRightSide}`}
              elevation={0}
            >
              <Typography
                color='primary'
                variant='h5'
                component='h3'
                className={`${classes.label} ${classes.childrenBox}`}
              >
                Professor Responsável
              </Typography>
              <List className={classes.boxList}>
                {teachers &&
                  teachers.map(teacher => (
                    <React.Fragment key={teacher.id}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar src='/broken-image.jpg' />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              color='primary'
                              variant='body1'
                              component='h4'
                            >
                              {teacher.name}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <Divider variant='fullWidth' component='hr' />
                    </React.Fragment>
                  ))}
              </List>
            </Paper>
          </Grid>
          <Grid item>
            <Paper
              className={`${classes.paper} ${classes.paperRightSide}`}
              elevation={0}
            >
              <Typography
                color='primary'
                variant='h5'
                component='h3'
                className={`${classes.label} ${classes.childrenBox}`}
              >
                Alunos da Turma
              </Typography>
              <List className={classes.boxList}>
                {children &&
                  children.map(child => {
                    return (
                      <React.Fragment key={child.id}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar src='/broken-image.jpg' />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography
                                color='primary'
                                variant='p'
                                component='h4'
                              >
                                {child.name}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <Divider variant='fullWidth' component='hr' />
                      </React.Fragment>
                    )
                  })}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  const muralSchedules = (
    <Grid item xs={12} container className={classes.container} spacing={2}>
      <Grid item xs={12} style={{ flexGrow: '1', maxWidth: 'none' }}>
        {themeSchedules &&
          themeSchedules.length > 0 &&
          themeSchedules.map(schedule => {
            return (
              <Paper
                className={classes.paper}
                elevation={0}
                key={schedule.id}
                style={{ marginBottom: '1rem' }}
              >
                <div className={classes.cyleCard}>
                  <h2>
                    {schedule.favorited && (
                      <FavoriteIcon className={classes.favoritedIcon} />
                    )}{' '}
                    {schedule.cycle?.name || 'Ciclo'}
                  </h2>
                  <div>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        history.push(`/themes/${schedule.theme?.id}`)
                      }
                    >
                      <h3>
                        {moment(schedule.start_at).format('DD/MM/YYYY')} a{' '}
                        {moment(schedule.end_at).format('DD/MM/YYYY')}
                      </h3>
                      <p>
                        Módulo:{' '}
                        {schedule.theme && schedule.theme.theme_module?.title}
                      </p>
                      <h2
                        onClick={() =>
                          history.push(`/themes/${schedule.theme?.id}`)
                        }
                      >
                        {schedule.theme?.title}
                      </h2>
                      {schedule.theme?.skills &&
                        schedule.theme.skills.map((skill, index) => (
                          <span key={skill.id}>
                            {skill.name}
                            {index < schedule.theme?.skills.length - 1
                              ? ' | '
                              : ''}
                          </span>
                        ))}
                    </div>
                    <div>
                      {schedule?.theme?.cover && (
                        <img
                          src={schedule.theme.cover.image_url}
                          alt='Capa do tema'
                          width='370'
                          height='185'
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Paper>
            )
          })}
      </Grid>
    </Grid>
  )

  const muralParticipants = (
    <Grid item xs={12} container className={classes.container}>
      <Grid item xs={12} style={{ flexGrow: '1', maxWidth: 'none' }}>
        <Paper className={classes.paper} elevation={0}>
          <Typography
            color='primary'
            variant='h5'
            component='h3'
            className={`${classes.label} ${classes.participants}`}
          >
            Participantes
          </Typography>
          <Typography
            color='primary'
            variant='h5'
            component='h3'
            className={`${classes.label}`}
          >
            Professores
          </Typography>
          <List className={`${classes.boxList} ${classes.participantsList}`}>
            {classroom?.teachers &&
              classroom.teachers.map((teacher, index) => (
                <React.Fragment key={teacher.id}>
                  {index === 0 && (
                    <Divider variant='fullWidth' component='hr' />
                  )}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar src='/broken-image.jpg' />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          color='primary'
                          variant='body1'
                          component='p'
                        >
                          {teacher.name}
                        </Typography>
                      }
                    />
                    {/* <ListItemSecondaryAction>
                      <IconButton edge='end' aria-label='more vert icon'>
                        <MoreVertIcon />
                      </IconButton>
                    </ListItemSecondaryAction> */}
                  </ListItem>
                  <Divider variant='fullWidth' component='hr' />
                </React.Fragment>
              ))}
          </List>
          <Typography
            color='primary'
            variant='h5'
            component='h3'
            className={`${classes.label}`}
          >
            Alunos
          </Typography>
          <List className={`${classes.boxList} ${classes.participantsList}`}>
            {children &&
              children.map((child, index) => (
                <React.Fragment key={child.id}>
                  {index === 0 && (
                    <Divider variant='fullWidth' component='hr' />
                  )}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar src='/broken-image.jpg' />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          className={classes.studentList}
                          color='primary'
                          variant='body1'
                          component='p'
                        >
                          <span>{child.name}</span>{' '}
                          <span>
                            Responsável:{' '}
                            {child.parents.map(
                              (parent, index) =>
                                parent.name +
                                (index < child.parents.length - 1 ? ', ' : '')
                            )}
                          </span>
                        </Typography>
                      }
                    />
                    {/* <ListItemSecondaryAction>
                      <IconButton edge='end' aria-label='more vert icon'>
                        <MoreVertIcon />
                      </IconButton>
                    </ListItemSecondaryAction> */}
                  </ListItem>
                  <Divider variant='fullWidth' component='hr' />
                </React.Fragment>
              ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
  )

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          alignItems='center'
          justify='flex-end'
          className={classes.topMenu}
        >
          <List component='ul' aria-label='top nav' disablePadding>
            <ListItem
              activeClassName='Mui-selected'
              className={`${classes.link} ${classes.nested}`}
              component={NavLink}
              isActive={(match, location) => {
                const param =
                  location?.search &&
                  new URLSearchParams(location.search).get('name')
                if (param === 'activities') {
                  return true
                } else return false
              }}
              to={'?name=activities'}
            >
              <ListItemText primary='Mural de Atividades' />
            </ListItem>
            {projectId.toString() !== '42' && (
              <ListItem
                activeClassName='Mui-selected'
                className={`${classes.link} ${classes.nested}`}
                component={NavLink}
                isActive={(match, location) => {
                  const param =
                    location?.search &&
                    new URLSearchParams(location.search).get('name')
                  if (param === 'schedules') {
                    return true
                  } else return false
                }}
                to={'?name=schedules'}
              >
                <ListItemText primary='Aulas' />
              </ListItem>
            )}
            {/* <ListItem
              activeClassName='Mui-selected'
              className={`${classes.link} ${classes.nested}`}
              component={NavLink}
              isActive={(match, location) => {
                const param =
                  location?.search &&
                  new URLSearchParams(location.search).get('name')
                if (param === 'participants') {
                  return true
                } else return false
              }}
              to={'?name=participants'}
            >
              <ListItemText primary='Participantes' />
            </ListItem> */}
            {/* <ListItem
              activeClassName='Mui-selected'
              className={`${classes.link} ${classes.nested}`}
              component={NavLink}
              isActive={(match, location) => {
                const param =
                  location?.search &&
                  new URLSearchParams(location.search).get('name')
                if (param === 'reports') {
                  return true
                } else return false
              }}
              to={'?name=reports'}
            >
              <ListItemText primary='Relatórios' />
            </ListItem> */}
          </List>
        </Grid>
        <HeaderCard>
          <div style={{ margin: 'auto 1rem' }}>
            <h1 style={{ fontSize: '1.5rem' }}>{classroom.name}</h1>
            <h4 style={{ fontWeight: 400 }}>{classroom?.grade?.name}</h4>
            <p style={{ paddingTop: 0, fontSize: '1rem' }}>
              {classroom.children?.length} alunos participantes
            </p>
          </div>
        </HeaderCard>
        {(query.get('name') === 'activities' ||
          query.get('name') === null ||
          !query.get('name')) &&
          muralActivities}
        {query.get('name') === 'participants' && muralParticipants}
        {query.get('name') === 'schedules' && muralSchedules}
      </Grid>
      <div style={{ textAlign: 'end', marginBottom: '2rem' }}>
        <Button
          className={classes.backBtn}
          variant='outlined'
          type='button'
          onClick={() => history.push('/classrooms')}
        >
          Voltar
        </Button>
      </div>
    </div>
  )
}

export default ClassroomView
