import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import AsyncSelect from '../shared/AsyncSelect'
import { reduxForm, Form, Field, getFormValues, change } from 'redux-form'

import {
  createClassroomChildrenRSPA,
  getChildrensByClassroomRSPA,
  getClassroomChildrenByChildRSPA,
  removeUserTaxpayerNmbRSPA,
  removeChildrenTaxpayerNmbRSPA,
  deleteUserChildrenRSPA,
  removeClassroomChild,
  exportClassroomRSPA
} from '../../store/classrooms/actions'
import StyledSVG from '../shared/StyledSVG'
import arrowSVG from '../../assets/icons/arrow-to-right.svg'
import migrationSVG from '../../assets/icons/migration-icon.svg'
import addUserSVG from '../../assets/icons/add-user.svg'
import studentBookSVG from '../../assets/icons/student-details-icon.svg'
import viewSVG from '../../assets/icons/pattern-view-icon.svg'
import editSVG from '../../assets/icons/pattern-edit-icon.svg'
import editIntoModalSVG from '../../assets/icons/edit-pencil.svg'
import removeFromClassroomSVG from '../../assets/icons/remove-from-classroom.svg'
import removeFromSchoolSVG from '../../assets/icons/remove-from-school.svg'
import changeClassroomSVG from '../../assets/icons/change-student.svg'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import Label from '../label/Label'
import CheckboxInput from '../input/CheckboxInput'
import YearPickerInput from '../shared/YearPickerInput'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { isEmpty } from 'lodash'
import { saveAs } from 'file-saver'

import { Tooltip, FormGroup } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Modal from '../modal/Modal'
import { showAlertMessage } from '../../store/alert/actions'
import {
  fetchClassroomChild,
  editClassroomChildren
} from '../../store/classrooms/services'
import { backToThePage } from '../../store/pagination/actions'
import HeaderCard from '../card/HeaderCard'
import CustomTable from '../table/CustomTable'
import Button from '../button/Button'
import ConfirmModal from '../modal/ConfirmModal'

const useStyles = makeStyles(theme => ({
  paper: {
    color: '#555555',
    padding: theme.spacing(2),
    margin: 0,
    borderRadius: '16px',
    boxShadow: '3px 11px 14px 6px rgba(107, 103, 103, 0.1)',
    lineHeight: '20.11px !important',
    fontSize: 16,
    paddingLeft: '1.5rem',
    '& .MuiListItem-button': {
      color: '#ADB8CC',
      '&:hover': {
        fontWeight: 700
      }
    }
  },
  grayContainer: {
    // backgroundColor: '#F6F7F8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '2rem auto',
    minHeight: 40,
    '& p': {
      margin: 0
    },
    '& svg': {
      cursor: 'pointer',
      fontSize: '1rem',
      marginLeft: '0.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: 20
    }
  },
  btnStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    textAlign: 'right',
    margin: '2rem auto',
    [theme.breakpoints.down('600')]: {
      justifyContent: 'center'
    }
  },
  cellStyle: {
    margin: 0
  },
  label: {
    fontWeight: '600',
    fontSize: '16px',
    color: '#555555 !important'
  },
  migrationModal: {
    width: '100%',
    height: '100%',
    padding: '50px'
  },
  migrationHeader: {
    maxWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    '& h2': {
      marginBottom: 'unset'
    }
  },
  solidBorder: {
    marginTop: '5px',
    width: '100%',
    height: '0px',
    border: '1px solid #ADB8CC'
  },
  migrationSeparate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& div': {
      '& h3': {
        marginBottom: 'unset',
        color: '#9B9B9B',
        fontWeight: '700'
      }
    },
    marginBottom: '15px'
  },
  migrationOldClassroom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('750')]: {
      maxWidth: '200px'
    },
    [theme.breakpoints.down('750')]: {
      '& .MuiInputBase-root': {
        width: 300
      }
    },
    '& > div': {
      width: '300px',
      marginBottom: 0,
      maxWidth: '100%',
      [theme.breakpoints.down('1500')]: {
        width: '300px'
      }
    }
  },
  migrationMain: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    [theme.breakpoints.down('1300')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('750')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  migrationNewClassroom: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },
  newClassroom: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '200px',
    height: '48px',
    borderRadius: '8px',
    border: '1px solid #ADB8CC',
    [theme.breakpoints.down('750')]: {
      width: '300px'
    },
    '& p': {
      margin: 0,
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '24px'
    }
  },
  migrationChildrens: {
    display: 'flex',
    flexDirection: 'column'
    // marginBottom: '40px'
  },
  migration: {
    maxHeight: '75vh',
    minHeight: '75vh',
    overflowY: 'auto',
    position: 'relative'
  },
  migrationButton: {
    maxHeight: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('750')]: {
      justifyContent: 'center'
    },
    '& button': {
      backgroundColor: '#386093',
      borderRadius: 8,
      color: '#FFFF',
      height: 44,
      width: 146,
      [theme.breakpoints.down('750')]: {
        width: 280
      }
    }
  },
  migrationAll: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '24px',
      color: theme.palette.primary.main
    }
  },
  migrationSelectTxt: {
    textAlign: 'initial',
    fontWeight: '400',
    color: '#868E96',
    fontSize: '14px'
  },
  migrationSpan: {
    color: '#ADB8CC'
  },
  saveBtn: {
    backgroundColor: '#386093',
    width: 165,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  cancelBtn: {
    backgroundColor: '#EF7C8F',
    width: 136,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  insertBtn: {
    backgroundColor: '#386093',
    width: 226,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px',
    [theme.breakpoints.down('600')]: {
      width: 220
    }
  },
  exportBtn: {
    backgroundColor: '#F4A87C',
    width: 196,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#F4A87C'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  migrationBtn: {
    width: 200,
    borderRadius: '8px',
    backgroundColor: 'transparent',
    border: '2px solid #00ACDB',
    boxShadow: 'none',
    transition: 'background-color 0.4s ease, color 0.4s ease',
    '& span': {
      fontSize: '14px',
      color: '#00ACDB',
      fontWeight: 700
    },
    '& svg > path': {
      stroke: '#00ACDB'
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#00ACDB',
      '& span': {
        color: '#FFF'
      },
      '& svg > path': {
        stroke: '#FFF'
      }
    }
  },
  childrensTableTitle: {
    marginBottom: 24
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  detailHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 20,
    '& h1': {
      fontSize: 24,
      fontWeight: 700,
      color: '#386093',
      [theme.breakpoints.down('360')]: {
        fontSize: 19
      }
    }
  },
  modalRoot: {
    padding: '40px',
    position: 'relative'
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& label': {
      color: '#9F9F9F',
      fontWeight: 500,
      fontSize: 16
    },
    '& p': {
      margin: 0,
      color: '#666666',
      fontWeight: 700,
      fontSize: 16
    }
  },
  infoLabel: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  addressSection: {
    marginTop: 50
  },
  userType: {
    marginBottom: 20,
    '& span': {
      fontSize: '18px !important',
      fontWeight: '600 !important',
      color: '#386093 !important'
    }
  },
  datasLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
    [theme.breakpoints.down('500')]: {
      justifyContent: 'center'
    }
  },
  addressInfo: {
    color: '#555555',
    marginBottom: 18,
    fontWeight: 600
  },
  personalData: {
    marginBottom: 10,
    color: '#555555',
    fontWeight: 600
  },
  parentLabel: {
    marginTop: 50
  },
  arrowIcon: {
    position: 'absolute',
    cursor: 'pointer',
    top: 20,
    right: 20,
    color: '#8B598E',
    width: 20,
    height: 20,
    transform: 'rotate(0deg)', // Inicialmente sem rotação
    transition: 'transform 0.3s ease', // Adicione uma transição suave
    [theme.breakpoints.down('360')]: {
      right: 5
    }
  },
  rotated: {
    transform: 'rotate(180deg)' // Aplica a rotação de 180 graus quando 'rotated' é adicionado à classe
  },
  parentSection: {
    padding: '20px 10px',
    height: '100px',
    borderRadius: 8,
    background: 'linear-gradient(194.95deg, #FFFFFF -0.58%, #F1F3F4 60.91%)',
    position: 'relative',
    transition: 'height 0.3s ease-in-out',
    // xs={12} sm={6} md={4} lg={3} xl={3}
    [theme.breakpoints.down('md')]: {
      height: '150px'
    },
    [theme.breakpoints.down('xs')]: {
      height: '250px'
    }
  },
  parentAddres: {
    height: '300px',
    [theme.breakpoints.down('md')]: {
      height: '320px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '340px'
    },
    [theme.breakpoints.down('xs')]: {
      height: '450px'
    }
  },
  addressGrid: {
    height: '200px',
    width: '100%',
    '& .addressLabel': {
      marginTop: 20,
      height: 20
    }
  },
  hiddenAddress: {
    display: 'none'
  },
  topBtns: {
    justifyContent: 'flex-end',
    display: 'flex',
    gap: 20,
    [theme.breakpoints.down('800')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& button': {
        width: 220
      }
    }
  },
  teacherNames: {
    '& .seeMore': {
      color: '#00ACDB',
      textDecoration: 'underline',
      cursor: 'pointer',
      marginLeft: 8
    }
  },
  classroomInfo: {
    display: 'flex',
    gap: 20,
    [theme.breakpoints.down('800')]: {
      flexDirection: 'column'
    }
  },
  headerCard: {
    minWidth: 'unset',
    [theme.breakpoints.down('600')]: {
      padding: 0
    }
  },
  migrationArrowIcon: {
    height: 30,
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('750')]: {
      display: 'none'
    }
  },
  firstSection: {
    display: 'flex',
    gap: 15,
    [theme.breakpoints.down('1300')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  secondSection: {
    display: 'flex',
    gap: 15,
    alignItems: 'center',
    [theme.breakpoints.down('1300')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  responsiveField: {
    [theme.breakpoints.down('450')]: {
      width: '250px !important',
      '& .MuiInputBase-root': {
        width: '250px !important'
      }
    },
    '& > div': {
      [theme.breakpoints.down('450')]: {
        width: '250px !important'
      }
    }
  },
  actionsSection: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& path': {
      stroke: '#868E96'
    },
    '& circle': {
      stroke: '#868E96'
    }
  },
  editButton: {
    '& button': {
      border: '2px solid #386093',
      color: '#386093',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'transparent',
        border: '2px solid #386093'
      }
    }
  },
  editLabel: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
    color: '#386093'
  },
  customScrollbar: {
    maxHeight: '500px',
    '&::-webkit-scrollbar': {
      width: '5px'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-border-radius': '10px',
      'border-radius': '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      '-webkit-border-radius': '10px',
      'border-radius': '10px',
      background: 'rgba(173, 184, 204, 1)'
    }
  }
}))

const SettingsClassroomView = ({
  classroom,
  removeClassroomChild,
  handleSubmit,
  submit,
  touch,
  schoolId,
  fieldValues,
  change,
  alert,
  backToThePage,
  currentPagination,
  getChildrensByClassroomRSPA,
  getClassroomChildrenByChildRSPA,
  removeChildrenTaxpayerNmbRSPA,
  removeUserTaxpayerNmbRSPA,
  deleteUserChildrenRSPA,
  exportClassroomRSPA
}) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    title: null,
    childId: null,
    userId: null,
    forAllSchool: undefined,
    classroomChildren: undefined
  })
  const [childrensToMigrate, setChildrensToMigrate] = React.useState([])
  const [selectedChildrens, setSelectedChildrens] = React.useState([])
  const [isForAllChildrens, setIsForAllChildrens] = React.useState(false)
  const [lastSelectedClassroom, setLastSelectedClassroom] = React.useState(null)
  const [currentChildrens, setCurrentChildrens] = React.useState([])
  const [emptyClassroom, setEmptyClassroom] = React.useState(false)
  const [userDetailModal, setUserDetailModal] = React.useState({
    isOpen: false,
    current: null
  })
  const [openProfile, setOpenProfile] = React.useState([])

  const [studentAlreadyInClass, setStudentAlreadyInClass] = React.useState(
    false
  )
  const [modalForMigration, setModalForMigration] = React.useState(false)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('1300'))
  const matcheToColumnCount = useMediaQuery(theme.breakpoints.down('750'))
  const exportClassroom = () => {
    try {
      exportClassroomRSPA({ id: classroom.id }).then(res => {
        saveAs(res.data, `relatorio de turma - ${classroom?.name} .xlsx`)
      })
    } catch (err) {
      console.error(err)
    }
  }
  React.useEffect(
    () => {
      if (userDetailModal.isOpen === false) {
        setOpenProfile([])
      }
    },
    [userDetailModal.isOpen]
  )

  const goBack = () => {
    backToThePage({ ...currentPagination, canGoBack: true })
    history.push('/settings/classrooms')
  }

  const handleParentOpen = (index, arrayLength) => {
    setOpenProfile(prev => {
      const newState = []
      for (let i = 0; i < arrayLength; i++) {
        newState.push(
          i === index
            ? { open: prev?.[index]?.open ? !prev?.[index]?.open : true }
            : { open: false }
        )
      }
      return newState
    })
  }

  React.useEffect(() => {
    setCurrentChildrens(prev => {
      const newState = classroom?.children?.map(item => item.id)
      return newState
    })
  }, [])

  React.useEffect(
    () => {
      if (!modalForMigration) {
        setSelectedChildrens([])
        setChildrensToMigrate([])
        setIsForAllChildrens(false)
        setStudentAlreadyInClass(false)
      }
    },
    [modalForMigration]
  )

  const { REACT_APP_PROJECT_ID } = process.env

  // control migration content
  React.useEffect(
    () => {
      if (!fieldValues?.school_origen) {
        change('classroom_origen', null)
      }
      if (fieldValues?.classroom_origen && fieldValues?.school_origen) {
        if (lastSelectedClassroom !== fieldValues?.classroom_origen) {
          setSelectedChildrens([])
          setStudentAlreadyInClass(false)
        }
        getChildrensByClassroomRSPA({
          classroom: fieldValues.classroom_origen
        }).then(res => {
          if (res?.data?.included) {
            const childrensNonDuplicated = res?.data?.included
              .filter(item => {
                return !currentChildrens.includes(item.id)
              })
              .sort((x, y) => {
                return x.attributes.name.localeCompare(y.attributes.name)
              })
            if (
              childrensNonDuplicated?.length !== res?.data?.included?.length
            ) {
              setStudentAlreadyInClass(true)
            }
            setChildrensToMigrate(childrensNonDuplicated)
            setIsForAllChildrens(false)
            setLastSelectedClassroom(fieldValues.classroom_origen)
            setEmptyClassroom(false)
          } else {
            setEmptyClassroom(true)
          }
        })
      } else {
        setSelectedChildrens([])
        setChildrensToMigrate([])
        setStudentAlreadyInClass(false)
        setIsForAllChildrens(false)
        setEmptyClassroom(false)
      }
    },
    [fieldValues?.classroom_origen, fieldValues?.school_origen]
  )

  const handleChange = (event, input) => {
    let newValue
    if (Array.isArray(event)) {
      newValue = event?.map(v => ({ classroom_id: v.value }))
    } else {
      newValue = event ? event.value : ''
    }
    change(input.name, newValue)
  }

  const handleUserDetails = row => {
    setUserDetailModal(prev => ({ isOpen: !prev.isOpen, current: row }))
  }
  const [childrenFiltered, setChildrenFiltered] = React.useState()
  const [switchClassroomModal, setSwitchClassroomModal] = React.useState({
    studentId: null,
    classroomId: null,
    open: false,
    classroomChildId: null
  })
  const columns = [
    {
      key: 'avatar',
      name: '',
      width: '5%',
      render: function render (row) {
        if (row.avatar_url) {
          return (
            <Avatar
              onClick={() => handleUserDetails(row)}
              src={row.avatar_url}
            />
          )
        } else {
          return (
            <Avatar
              onClick={() => handleUserDetails(row)}
              src='/broken-image.jpg'
            />
          )
        }
      }
    },
    {
      key: 'name',
      name: 'Aluno',
      width: '20%',
      render: row => {
        return (
          <span onClick={() => handleUserDetails(row)}>
            {row.user?.name || row.name}
          </span>
        )
      }
    },
    {
      key: 'emailStudent',
      name: 'E-mail',
      width: '20%',
      render: row => {
        return row.user?.email || '-'
      }
    },
    {
      key: 'phone',
      name: 'Telefone',
      width: '15%',
      render: row => {
        return row.user?.phone || '-'
      }
    },
    {
      key: 'actions',
      name: 'Ações',
      width: '5%',
      // align: 'right',
      render: function render (row) {
        return (
          <div className={classes.actionsSection}>
            <Tooltip title='Visualizar'>
              <div
                onClick={() => {
                  handleUserDetails(row)
                }}
              >
                <StyledSVG src={viewSVG} width={20} height={20} />
              </div>
            </Tooltip>
            <Tooltip title='Editar'>
              <div
                onClick={() =>
                  history.push(
                    `${history.location.pathname}/students/${row.id}`
                  )
                }
              >
                <StyledSVG src={editSVG} width={20} height={20} />
              </div>
            </Tooltip>
            <Tooltip title='Trocar turma'>
              <div onClick={() => handleSwitchClassroom(row.id, classroom.id)}>
                <StyledSVG src={changeClassroomSVG} width={20} height={20} />
              </div>
            </Tooltip>
            <Tooltip title='Remover da turma'>
              <div
                onClick={async () => {
                  const classroomChildren = await getClassroomChildrenByChildRSPA(
                    { child: row.id }
                  ).then(res => res.data.data)
                  return setConfirmModal({
                    childId: row.id,
                    title: row.name,
                    open: true,
                    userId: row?.user_id,
                    forAllSchool: false,
                    classroomChildren: classroomChildren
                  })
                }}
              >
                <StyledSVG
                  src={removeFromClassroomSVG}
                  width={20}
                  height={20}
                />
              </div>
            </Tooltip>
            <Tooltip title='Remover da escola'>
              <div
                onClick={async () => {
                  const classroomChildren = await getClassroomChildrenByChildRSPA(
                    { child: row.id }
                  ).then(res => res.data.data)
                  setConfirmModal({
                    childId: row.id,
                    title: row.name,
                    open: true,
                    userId: row.user_id,
                    forAllSchool: true,
                    classroomChildren: classroomChildren
                  })
                }}
              >
                <StyledSVG src={removeFromSchoolSVG} width={20} height={20} />
              </div>
            </Tooltip>
          </div>
        )
      }
    }
  ]
  React.useEffect(
    () => {
      setChildrenFiltered(prev => {
        let newState = [...classroom.children]
        return newState.sort((x, y) => x.name.localeCompare(y.name))
      })
    },
    [classroom.children]
  )

  const handleTitle = (classroomChildren, forAllSchool, title) => {
    const classroomChildrenFiltered = classroomChildren?.filter(item => {
      return item.attributes.classroom_id !== +classroom.id
    })
    if (!forAllSchool) {
      if (classroomChildrenFiltered?.length > 0) {
        return `Deseja remover ${title} da turma ?`
      } else {
        return `Cuidado! ${title} possui cadastro em apenas uma turma. Sua remoção acarretará em seu desligamento da escola`
      }
    } else {
      return `Cuidado! "Sim" removerá ${title} da escola, não apenas da turma.`
    }
  }

  const handleSwitchClassroom = async (studentId, classroomId) => {
    const currentClassroomChild = await fetchClassroomChild({
      childId: studentId,
      classroomId: classroomId
    }).then(res => res.data)
    currentClassroomChild[0] &&
      setSwitchClassroomModal({
        studentId: currentClassroomChild[0].child_id,
        classroomId: currentClassroomChild[0].classroom_id,
        open: true,
        classroomChildId: currentClassroomChild[0].id
      })
  }

  const handleCheck = event => {
    const { checked, name } = event.target
    if (name !== 'allChildrens') {
      if (checked) {
        setSelectedChildrens(prev => [...prev, name])
      } else {
        setSelectedChildrens(prev => prev.filter(item => item !== name))
      }
    } else {
      if (checked) {
        setIsForAllChildrens(true)
        setSelectedChildrens(prev =>
          childrensToMigrate.map(item => item.id.toString())
        )
      } else {
        setIsForAllChildrens(false)
        setSelectedChildrens(prev => [])
      }
    }
  }

  const allTeachersNames =
    classroom.teachers?.length > 0 &&
    classroom.teachers
      .map(
        (teacher, index) =>
          teacher.name + `${index < classroom.teachers.length - 1 ? '; ' : ' '}`
      )
      .join('')

  const [seeAllTeachers, setSeeAllTeachers] = React.useState(false)
  const handleMigration = () => {
    dispatch(
      createClassroomChildrenRSPA.request({
        selectedChildrens,
        classroomId: classroom.id
      })
    )
      .then(res => {
        alert('Alunos migrados com sucesso!', 'success')
        setTimeout(() => {
          window.location.reload()
        }, 200)
      })
      .catch(err => {
        console.error(err)
        alert('Erro ao migrar alunos.', 'error')
      })
  }
  return (
    <Grid container spacing={2}>
      <HeaderCard parentClass={classes.headerCard}>
        <div style={{ margin: 'auto 1rem' }}>
          <h1>{classroom?.name}</h1>
          <p style={{ paddingTop: 0 }}>
            {classroom.children?.length} aluno(s) participante(s)
          </p>
        </div>
      </HeaderCard>
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0}>
            <div className={classes.grayContainer}>
              <div className={classes.classroomInfo}>
                <div>
                  <p>
                    <strong>Série:</strong> {classroom.grade?.name}{' '}
                  </p>
                </div>
                <div className={classes.teacherNames}>
                  <strong>Professor(a):</strong>{' '}
                  <span>{`${
                    allTeachersNames?.length > 100 && !seeAllTeachers
                      ? `${allTeachersNames?.slice(0, 100)}...`
                      : allTeachersNames || 'Nenhum professor cadastrado.'
                  }`}</span>
                  {allTeachersNames?.length > 100 &&
                    !seeAllTeachers && (
                      <span
                        onClick={() => setSeeAllTeachers(true)}
                        className='seeMore'
                      >
                        Ver mais
                      </span>
                    )}
                  {allTeachersNames?.length > 100 &&
                    seeAllTeachers && (
                      <span
                        onClick={() => setSeeAllTeachers(false)}
                        className='seeMore'
                      >
                        Ver menos
                      </span>
                    )}
                </div>
                <div>
                  <p>
                    <strong>Turno:</strong>{' '}
                    {(() => {
                      switch (classroom.shift) {
                        case 'morning':
                          return 'Manhã'
                        case 'afternoon':
                          return 'Tarde'
                        case 'fulltime':
                          return 'Integral'
                        default:
                          return 'Turno não definido'
                      }
                    })()}
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.topBtns}>
              <Button
                className={classes.migrationBtn}
                type='button'
                onClick={() => setModalForMigration(true)}
              >
                <div
                  style={{
                    width: '200px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    justifyContent: 'center'
                  }}
                >
                  <StyledSVG src={migrationSVG} width={18} height={20} />
                  <span>Migração</span>
                </div>
              </Button>
              <Button
                className={classes.insertBtn}
                onClick={() =>
                  history.push(`${history.location.pathname}/students/new`)
                }
              >
                <div
                  style={{
                    width: '200px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    justifyContent: 'center'
                  }}
                >
                  <StyledSVG src={addUserSVG} width={18} height={20} />
                  <span>Inserir aluno</span>
                </div>
              </Button>
            </div>
            <h3 className={classes.childrensTableTitle}>Lista de alunos</h3>
            {childrenFiltered && (
              <CustomTable
                classRoot={classes.customScrollbar}
                columnsPointer={[0, 1]}
                headColor={'rgba(173, 184, 204, 0.3)'}
                headCellRadius
                columns={columns}
                data={childrenFiltered}
              />
            )}
            <div className={classes.btnStyle}>
              <Button
                variant='outlined'
                className={classes.backBtn}
                onClick={() => goBack()}
              >
                Voltar
              </Button>
              <Button
                type='button'
                className={classes.exportBtn}
                onClick={() => exportClassroom()}
              >
                Exportar
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <ConfirmModal
        title={handleTitle(
          confirmModal.classroomChildren,
          confirmModal.forAllSchool,
          confirmModal.title
        )}
        open={confirmModal.open}
        setOpen={value => {
          if (value) {
            setConfirmModal({ ...confirmModal, open: value })
          } else {
            setConfirmModal({
              childId: null,
              title: '',
              open: value,
              userId: null,
              forAllSchool: undefined,
              classroomChildren: undefined
            })
          }
        }}
        onConfirm={async () => {
          const removeFromAllClassrooms = async () => {
            if (classroomChildrenFiltered?.length === 0) {
              await removeChildrenTaxpayerNmbRSPA({
                childId: confirmModal.childId
              })
              confirmModal.userId &&
                (await removeUserTaxpayerNmbRSPA({
                  userId: confirmModal.userId
                }))
            }
            removeClassroomChild({ childId: confirmModal.childId, schoolId })
          }
          const classroomChildrenFiltered = confirmModal.classroomChildren?.filter(
            item => {
              return +item.attributes.classroom_id !== +classroom.id
            }
          )

          if (!confirmModal.forAllSchool) {
            if (classroomChildrenFiltered?.length > 0) {
              removeClassroomChild({
                childId: confirmModal.childId,
                classroomId: classroom.id,
                schoolId
              })
            } else {
              removeFromAllClassrooms()
            }
          } else {
            removeFromAllClassrooms()
          }
          history.push(`/settings/classrooms/${classroom.id}`)
        }}
      />
      <Modal
        open={switchClassroomModal.open}
        handleClose={() =>
          setSwitchClassroomModal(prev => {
            return { open: !prev.open }
          })
        }
      >
        <Form>
          <h1 htmlFor='classroom_id'>Selecione a turma substituta: </h1>
          <Field
            component={AsyncSelect}
            defaultValue={fieldValues?.classroom_id}
            id='classroom_id'
            name='classroom_id'
            placeholder='Selecione a(s) turma(s)'
            handleAsyncChange={event => change('classroom_id', event)}
            searchParam='q[name_cont]'
            request={{
              path: 'classroom',
              params: {
                'q[school_id_eq]': schoolId,
                'q[id_not_eq]': classroom.id,
                'q[year_lteq]': new Date().getFullYear() + 1,
                'q[year_gteq]': new Date().getFullYear()
              }
            }}
            touch={touch}
          />
          <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
            <Button
              className={classes.cancelBtn}
              onClick={() => {
                setSwitchClassroomModal(prev => {
                  return { open: !prev.open }
                })
              }}
            >
              Cancelar
            </Button>
            <Button
              className={classes.saveBtn}
              onClick={() => {
                switchClassroomModal.classroomChildId &&
                  fieldValues?.classroom_id.value &&
                  editClassroomChildren({
                    id: switchClassroomModal.classroomChildId,
                    data: {
                      classroom_id: +fieldValues?.classroom_id.value,
                      child_id: switchClassroomModal.studentId
                    }
                  }).then(
                    res =>
                      res.data
                        ? alert(
                            'Troca de turma realizada com sucesso!',
                            'success'
                          )
                        : alert(
                            'Ocorreu um erro durante a troca de turma.',
                            'error'
                          )
                  )
                history.goBack()
              }}
            >
              Salvar
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        open={modalForMigration}
        handleClose={() => setModalForMigration(prev => !prev)}
      >
        <div>
          <div className={classes.migration}>
            <div className={classes.migrationHeader}>
              <Label>Migração</Label>
              <p style={{ color: '#555555' }}>
                Transfira estudantes entre turmas com praticidade. Otimize a
                gestão escolar com apenas alguns cliques
              </p>
            </div>
            <div className={classes.migrationSeparate}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <h3>De uma turma para outra</h3>
                {studentAlreadyInClass && (
                  <p style={{ color: 'red', marginBottom: 'unset' }}>
                    * A turma atual já possui alguns alunos da turma selecionada
                  </p>
                )}
                {emptyClassroom && (
                  <p style={{ color: 'red', marginBottom: 'unset' }}>
                    * A turma selecionada não possui alunos cadastrados
                  </p>
                )}
              </div>
              <div className={classes.solidBorder} />
            </div>
            <div className={classes.migrationMain}>
              <div className={classes.firstSection}>
                <div
                  className={`${classes.migrationOldClassroom} ${
                    classes.responsiveField
                  }`}
                >
                  <label className={classes.label} htmlFor='school_origen'>
                    Escola
                  </label>
                  <Field
                    component={AsyncSelect}
                    defaultOptions={false}
                    id='school_origen'
                    name='school_origen'
                    placeholder='Selecione uma escola'
                    handleAsyncChange={handleChange}
                    searchParam='q[name_cont]'
                    request={{
                      path: 'school',
                      params: {
                        'q[apps_user_application_id_eq]': REACT_APP_PROJECT_ID
                      }
                    }}
                    touch={touch}
                  />
                </div>
                <div
                  className={`${classes.migrationOldClassroom} ${
                    classes.responsiveField
                  }`}
                >
                  <label className={classes.label} htmlFor='year'>
                    Ano
                  </label>
                  <Field
                    autoFocus
                    component={YearPickerInput}
                    name='year'
                    placeholder='Ano da turma'
                    views={['year']}
                    defaultValue={fieldValues?.year}
                  />
                </div>
              </div>
              {!matches && (
                <div className={classes.migrationArrowIcon}>
                  <StyledSVG height={18} width={18} src={arrowSVG} />
                </div>
              )}
              <div className={classes.secondSection}>
                <div
                  className={`${classes.migrationOldClassroom} ${
                    classes.responsiveField
                  }`}
                >
                  <label className={classes.label} htmlFor='classroom_origen'>
                    Turma
                  </label>
                  <Field
                    component={AsyncSelect}
                    defaultOptions={false}
                    id='classroom_origen'
                    name='classroom_origen'
                    cacheUniqs={[fieldValues?.school_origen, fieldValues?.year]}
                    placeholder='Selecione uma turma'
                    handleAsyncChange={handleChange}
                    searchParam='q[name_cont]'
                    disabled={!fieldValues?.school_origen || !fieldValues?.year}
                    request={{
                      path: 'classroom',
                      params: {
                        'q[school_id_eq]': fieldValues?.school_origen,
                        'q[year_eq]': fieldValues?.year?.year()
                      }
                    }}
                    touch={touch}
                  />
                </div>
                {matches && (
                  <div className={`${classes.migrationArrowIcon}`}>
                    <StyledSVG height={18} width={18} src={arrowSVG} />
                  </div>
                )}
                <div className={classes.migrationNewClassroom}>
                  <labe className={classes.label}>Nova turma</labe>
                  <div
                    className={`${classes.newClassroom} ${
                      classes.responsiveField
                    }`}
                  >
                    <p>{`${classroom?.name} - ${classroom?.year}`}</p>
                  </div>
                </div>
              </div>
            </div>
            {childrensToMigrate.length > 0 &&
              !emptyClassroom && (
                <div className={classes.migrationChildrens}>
                  <p className={classes.migrationSelectTxt}>
                    Selecione o(s) aluno(s) que deverão ser migrados:
                  </p>
                  <FormGroup style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                      <div className={classes.migrationAll}>
                        <CheckboxInput
                          checked={isForAllChildrens}
                          name='allChildrens'
                          handleCheck={handleCheck}
                        />
                        <span>Selecionar todos</span>
                      </div>
                      <div
                        style={{
                          columnCount:
                            childrensToMigrate.length <= 10 ||
                            matcheToColumnCount
                              ? 1
                              : childrensToMigrate.length <= 20 ? 2 : 3
                        }}
                      >
                        {childrensToMigrate.map((child, index) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginBottom: '5px',
                              breakInside: 'avoid-column'
                            }}
                          >
                            <CheckboxInput
                              checked={selectedChildrens.includes(child.id)}
                              name={child.id}
                              handleCheck={handleCheck}
                            />
                            <span className={classes.migrationSpan}>
                              {child.attributes.name}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </FormGroup>
                  <div className={classes.solidBorder} />
                </div>
              )}
          </div>
          <div className={classes.migrationButton}>
            <Button
              type='button'
              disabled={selectedChildrens.length < 1}
              onClick={() => handleMigration()}
            >
              Migrar
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={userDetailModal.isOpen}
        handleClose={() => handleUserDetails({ isOpen: false, current: null })}
        width={'85%'}
        disablePadding
      >
        <div className={classes.modalRoot}>
          <CloseRoundedIcon
            onClick={() => handleUserDetails({ isOpen: false, current: null })}
            className={classes.closeIcon}
          />
          <Grid className={classes.datasLabel} container item>
            <div className={classes.detailHeader}>
              <StyledSVG src={studentBookSVG} />
              <h1>Dados cadastrais</h1>
            </div>
            <div className={classes.editButton}>
              <Button variant='outlined'>
                <div
                  className={classes.editLabel}
                  onClick={() => {
                    history.push(
                      `${history.location.pathname}/students/${
                        userDetailModal?.current?.id
                      }`
                    )
                  }}
                >
                  <StyledSVG width={14} height={14} src={editIntoModalSVG} />
                  <span>Editar</span>
                </div>
              </Button>
            </div>
          </Grid>
          <Grid container style={{ rowGap: '10px' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className={`${classes.infoLabel} ${classes.userType}`}>
                <span>Aluno</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className={`${classes.infoLabel} ${classes.personalData}`}>
                <span>Dados pessoais</span>
              </div>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className={classes.userInfo}>
                  <label>Nome do aluno</label>
                  <p>{userDetailModal?.current?.name}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className={classes.userInfo}>
                  <label>CPF</label>
                  <p>{userDetailModal?.current?.taxpayer_number || '-'}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className={classes.userInfo}>
                  <label>Data de nascimento</label>
                  <p>{userDetailModal?.current?.birthdate || '-'}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className={classes.userInfo}>
                  <label>E-mail</label>
                  <p>{userDetailModal?.current?.user?.email || '-'}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className={classes.userInfo}>
                  <label>Contato</label>
                  <p>{userDetailModal?.current?.user?.phone || '-'}</p>
                </div>
              </Grid>
              <Grid container className={classes.addressSection}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div
                    className={`${classes.infoLabel} ${classes.addressInfo}`}
                  >
                    <span>Endereço</span>
                  </div>
                </Grid>
                <Grid container style={{ rowGap: '10px' }}>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <div className={classes.userInfo}>
                      <label>CEP</label>
                      <p>
                        {userDetailModal?.current?.user?.address?.zipcode ||
                          '-'}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <div className={classes.userInfo}>
                      <label>Rua</label>
                      <p>
                        {userDetailModal?.current?.user?.address?.street || '-'}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <div className={classes.userInfo}>
                      <label>Complemento</label>
                      <p>
                        {userDetailModal?.current?.user?.address?.complement ||
                          '-'}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <div className={classes.userInfo}>
                      <label>Bairro</label>
                      <p>
                        {userDetailModal?.current?.user?.address
                          ?.neighbourhood || '-'}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <div className={classes.userInfo}>
                      <label>Número</label>
                      <p>
                        {userDetailModal?.current?.user?.address?.number || '-'}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {userDetailModal.current?.parents?.length > 0 && (
            <Grid container style={{ rowGap: '10px' }}>
              <Grid
                className={classes.parentLabel}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <div className={`${classes.infoLabel} ${classes.userType}`}>
                  <span>
                    {userDetailModal.current?.parents?.length > 1
                      ? 'Responsáveis'
                      : 'Responsável'}
                  </span>
                </div>
              </Grid>
              {userDetailModal.current?.parents.map((parent, index, self) => {
                const hasAddress = !isEmpty(parent.address)
                return (
                  <Grid
                    style={{ rowGap: 10 }}
                    key={index}
                    className={`${classes.parentSection} ${
                      openProfile?.[index]?.open ? classes.parentAddres : ''
                    }`}
                    container
                  >
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <div className={classes.userInfo}>
                        <label>Nome do responsável </label>
                        <p>{parent.name || '-'}</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <div className={classes.userInfo}>
                        <label>CPF </label>
                        <p>{parent?.taxpayer_number || '-'}</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <div className={classes.userInfo}>
                        <label>Email</label>
                        <p>{parent?.email || '-'}</p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <div className={classes.userInfo}>
                        <label>Contato</label>
                        <p>{parent?.phone || '-'}</p>
                      </div>
                    </Grid>
                    {hasAddress && (
                      <>
                        <KeyboardArrowDown
                          onClick={() => handleParentOpen(index, self.length)}
                          className={`${classes.arrowIcon} ${
                            openProfile?.[index]?.open ? classes.rotated : ''
                          }`}
                        />
                        <Grid
                          container
                          className={`${
                            openProfile?.[index]?.open
                              ? classes.addressGrid
                              : classes.hiddenAddress
                          }`}
                        >
                          <Grid
                            className='addressLabel'
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                          >
                            <div
                              className={`${classes.infoLabel} ${
                                classes.personalData
                              }`}
                            >
                              <span>Endereço</span>
                            </div>
                          </Grid>
                          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.userInfo}>
                              <label>CEP</label>
                              <p>{parent?.address?.zipcode || '-'}</p>
                            </div>
                          </Grid>
                          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.userInfo}>
                              <label>Rua</label>
                              <p>{parent?.address?.street || '-'}</p>
                            </div>
                          </Grid>
                          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.userInfo}>
                              <label>Número</label>
                              <p>{parent?.address?.number}</p>
                            </div>
                          </Grid>
                          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.userInfo}>
                              <label>Bairro</label>
                              <p>{parent?.address?.neighbourhood || '-'}</p>
                            </div>
                          </Grid>
                          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.userInfo}>
                              <label>Complemento</label>
                              <p>{parent?.address?.complement || '-'}</p>
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                )
              })}
            </Grid>
          )}
        </div>
      </Modal>
    </Grid>
  )
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) =>
    dispatch(change('switchClassroomChildrenForm', field, data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    ),
  backToThePage: data => dispatch(backToThePage(data)),
  exportClassroomRSPA: data => dispatch(exportClassroomRSPA.request(data)),
  getChildrensByClassroomRSPA: data =>
    dispatch(getChildrensByClassroomRSPA.request(data)),
  getClassroomChildrenByChildRSPA: data =>
    dispatch(getClassroomChildrenByChildRSPA.request(data)),
  removeChildrenTaxpayerNmbRSPA: data =>
    dispatch(removeChildrenTaxpayerNmbRSPA.request(data)),
  removeUserTaxpayerNmbRSPA: data =>
    dispatch(removeUserTaxpayerNmbRSPA.request(data)),
  removeClassroomChild: data => dispatch(removeClassroomChild(data)),
  deleteUserChildrenRSPA: data => dispatch(deleteUserChildrenRSPA.request(data))
})

const mapStateToProps = (state, props) => {
  return {
    schoolId: state.school.currentSchool.school_id,
    fieldValues: getFormValues('switchClassroomChildrenForm')(state),
    currentPagination: state.pagination
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'switchClassroomChildrenForm'
  })(SettingsClassroomView)
)
