import jsonApi from '../../utils/jsonApi'

const gradesModel = 'grades'

export const fetchGrades = ({ belongsToASchool, schoolId }) => {
  let params = belongsToASchool
    ? {
        'q[school_id_in]': schoolId,
        'q[m]': 'or',
        'q[school_id_null]': true
      }
    : { 'q[school_id_null]': true }
  return jsonApi
    .findAll(gradesModel, {
      params: {
        'page[size]': 20,
        include: 'segment',
        ...params
      }
    })
    .then(res => res.data)
}

export const fetchGradesById = id => {
  return jsonApi.find(gradesModel, id, {
    params: {
      include: 'segment,schedule_templates'
    }
  })
}

export const fetchGradeThemeSchedules = ({ schoolId, gradeId, year, sort }) => {
  return jsonApi
    .find('theme_schedule', '', {
      params: {
        'page[size]': 20,
        'q[school_id_eq]': schoolId,
        'q[grade_id_eq]': gradeId,
        'q[cycle_id_not_null]': true,
        'q[s]': sort || 'cycle_id asc',
        'q[start_at_gteq]': `${year}-01-01`,
        'q[start_at_lteq]': `${year}-12-31`,
        include: 'theme.theme_module,theme.skills,theme.cover,cycle'
      }
    })
    .then(res => res.data)
}
