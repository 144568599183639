import http from '../../utils/http'
import jsonApi from '../../utils/jsonApi'

const fetchModulesUrl = '/theme_modules'
const fetchCoversUrl = '/covers'

export const fetchModules = ({
  value,
  sort = 'created_at desc',
  option,
  page,
  hasSchoolId = false,
  schoolId = null
}) => {
  const valueIsNotNumber = isNaN(value)
  const contentKey = valueIsNotNumber
    ? 'q[themes_contents_title_cont]'
    : 'q[themes_contents_id_eq]'
  const themeKey = valueIsNotNumber ? 'q[themes_title_cont]' : 'q[themes_id_eq]'

  let params =
    option === 'Temas'
      ? {
          [themeKey]: value,
          include: 'grade'
        }
      : option === 'Conteúdos'
        ? {
            [contentKey]: value,
            include: 'grade'
          }
        : {
            [valueIsNotNumber ? 'q[title_cont]' : 'q[id_eq]']: value,
            include: 'grade'
          }
  let school = hasSchoolId === true && { 'q[school_id_eq]': schoolId }

  return http
    .get(fetchModulesUrl, {
      params: {
        'page[number]': page || 1,
        'page[size]': 5,
        'q[s]': sort,
        'q[school_id_not_null]': hasSchoolId,
        ...school,
        ...params
      }
    })
    .then(res => res.data)
}

export const removeTheme = ({ themeId, moduleId }) => {
  return http
    .put(`theme_modules/${moduleId}/filter_theme`, {
      data: {
        type: 'theme_modules',
        id: +moduleId,
        attributes: {
          theme_id: +themeId
        }
      }
    })
    .then(res => {
      return res
    })
}

export const newFetchModules = ({ params, page }) => {
  return jsonApi
    .findAll('theme_modules', {
      params: {
        'page[number]': page,
        'q[school_id_not_null]': false,
        'q[s]': 'created_at desc',
        'page[size]': 5,
        ...params,
        include: 'grade.segment'
      }
    })
    .then(res => {
      return res
    })
}

export const fetchModuleById = ids => {
  return http
    .get(fetchModulesUrl, {
      params: {
        'q[id_in]': ids,
        include: 'grade,cover'
      }
    })
    .then(res => res.data)
}

export const fetchModuleThemes = params => {
  return http
    .get(`/themes?q[theme_module_id_in]=${params}`)
    .then(res => res.data)
}

export const newFetchModuleThemes = async ({ id, page }) => {
  let currentPage = 1
  const response = await jsonApi
    .findAll('themes', {
      params: {
        'page[number]': currentPage,
        'q[theme_module_id_in]': id,
        include: 'skills'
      }
    })
    .then(res => res)
  let fullResponse = { ...response }
  while (fullResponse.data.length < fullResponse.meta.total_count) {
    currentPage += 1
    let responseForNextPage = await jsonApi
      .findAll('themes', {
        params: {
          'page[number]': currentPage,
          'q[theme_module_id_in]': id,
          include: 'skills'
        }
      })
      .then(res => res)
    fullResponse.data = [...fullResponse.data, ...responseForNextPage.data]
  }
  return fullResponse
}

export const createModule = data => {
  return jsonApi.create('theme_module', data).then(res => res.data)
}

export const fetchCovers = () => http.get(fetchCoversUrl).then(res => res.data)

export const editModule = data => {
  return jsonApi
    .put('theme_module', { id: data.id, ...data.data })
    .then(res => res.data)
}

export const deleteModule = id =>
  http.delete(`${fetchModulesUrl}/${id}`).then(res => res.data)
