import styled from '@emotion/styled'
import { withTheme } from '@material-ui/core/styles'

export const DroppableContainer = styled('div')`
  margin: 1.5rem 8px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 1.5rem 1.5rem;
  box-shadow: 3px 11px 14px 6px rgb(107 103 103 / 10%);
`
export const Title = styled('h3')`
  padding: 8px;
  display: flex;
  flex: auto;
  svg {
    cursor: pointer;
    margin-left: 0.5rem;
    font-size: 1.2rem;
  }
  .select__option,
  .select__option--is-focused,
  .select__placeholder,
  .select__value-container {
    font-weight: 500;
  }
  .MuiOutlinedInput-root {
    flex-flow: inherit;
    min-height: 30px;
    height: 30px;
  }
`
export const ItemList = styled('div')`
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? 'lightblue' : 'white')};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: auto;
  width: 100%;
  height: 160;
  justify-content: space-around;
  min-height: 4rem;
  @media (min-width: 960px) {
    flex-direction: row;
  }
`

export const Item = withTheme(styled('div')`
  display: grid;
  grid-template-columns: auto 30px;
  div {
    margin-left: 0.5rem;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  svg {
    margin: 5px auto;
    font-size: 1.2rem;
    color: ${props => props.theme.palette.text.primary};
  }
`)

export const DraggableContainer = withTheme(styled.div`
  border-radius: 8px;
  padding: 8px;
  margin-right: 8px;
  border: 1px dashed
    ${props =>
      props.isDragging
        ? props.theme.palette.secondary.brightest
        : props.theme.palette.primary.brightest};
  width: 250px;
  color: ${props => props.theme.palette.primary.light};
  &:focus {
    outline: none;
    border-color: red;
  }
`)
