import { createReducer } from '@reduxjs/toolkit'

import * as types from './types'

const initialState = {
  open: true,
  socio: {
    open: false
  },
  config: {
    open: false
  }
}

const sidebarReducer = createReducer(initialState, {
  [types.SET_SIDEBAR_STATUS]: (state, action) => {
    return {
      ...state,
      open: action.payload
    }
  },
  [types.SET_SOCIO_EXPANDED]: (state, action) => {
    return {
      ...state,
      socio: {
        open: action.payload
      }
    }
  },
  [types.SET_CONFIG_EXPANDED]: (state, action) => {
    return {
      ...state,
      config: {
        open: action.payload
      }
    }
  }
})

export default sidebarReducer
