import React, { useEffect } from 'react'
import moment from 'moment'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import { makeStyles } from '@material-ui/core/styles'

import { backToThePage } from '../../store/pagination/actions'
import { reportStatus, reportsShift } from '../../utils/constants'
import {
  getGeneratedReportsById,
  getLastTeacherByReportRSPA,
  exportReportRSPA
} from '../../store/reports/actions'
import { showAlertMessage } from '../../store/alert/actions'
import StyledSVG from '../shared/StyledSVG'
import doneSVG from '../../assets/icons/status-confirmation.svg'
import pendingSVG from '../../assets/icons/status-pending.svg'
import openSVG from '../../assets/icons/status-open.svg'
import moderatingSVG from '../../assets/icons/moderating-icon.svg'

import ReportTemplateLabel from '../label/ReportTemplateLabel'
import CustomTable from '../table/CustomTable'

import Button from '../button/Button'
import CheckboxInput from '../input/CheckboxInput'
import { NoReportsDiv } from '../../pages/ReportsPage'
import { Tooltip } from '@material-ui/core'
import viewSVG from '../../assets/icons/pattern-view-icon.svg'
import respondReportSVG from '../../assets/icons/generate-report.svg'

const useStyles = makeStyles(theme => ({
  status: {
    marginLeft: '5px',
    [theme.breakpoints.down('426')]: {
      display: 'none'
    }
  },
  actions: {
    display: 'flex',
    flex: 'row',
    justifyContent: 'space-around'
  },
  buttons: {
    display: 'flex',
    width: '100%',
    marginTop: '15px',
    gap: 10,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'flex-start',
      marginTop: '15px'
    },
    [theme.breakpoints.down('426')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    }
  },
  exportBtn: {
    backgroundColor: '#F4A87C',
    width: 256,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#F4A87C'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  statusRoot: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('426')]: {
      justifyContent: 'center'
    }
  },
  actionsSection: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& path': {
      stroke: '#868E96'
    },
    '& circle': {
      stroke: '#868E96'
    }
  },
  moderatingSvg: {
    '& path': {
      fill: '#ffffff'
    }
  }
}))
const ReportsListView = ({
  isAdmin,
  getGeneratedReportsById,
  reports,
  schoolId,
  alert,
  currentPagination,
  backToThePage,
  getLastTeacherByReportRSPA,
  exportReportRSPA
}) => {
  const classes = useStyles()
  const history = useHistory()
  const defaultParams = {
    // 'page[size]': 5,
    'q[classroom_school_id_eq]': schoolId
  }
  const [lastTeacher, setLastTeacher] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [selectedReport, setSelectedReport] = React.useState([])
  const lastReportTemplate = reports?.reportsFromTemplate?.items?.[0]
  const lastReportTemplateDate = lastReportTemplate?.attributes?.created_at
    ? moment(lastReportTemplate?.attributes?.created_at).format(
        'DD-MM-YYYY HH:mm'
      )
    : ''

  useEffect(
    async () => {
      lastReportTemplate?.id &&
        (await getLastTeacherByReportRSPA({
          reportId: lastReportTemplate.id
        }).then(res => {
          setLastTeacher(() => res?.data?.included?.[0])
        }))
    },
    [lastReportTemplate]
  )

  const { reportTemplateId } = useParams()

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCheck = id => {
    setSelectedReport(prevState => {
      if (prevState.includes(id)) {
        return prevState.filter(item => item !== id)
      } else return [id]
    })
  }

  const handleExportReport = async () => {
    if (selectedReport.length > 0) {
      handleToggle()
      try {
        const data = {
          type: 'report',
          attributes: {
            report_id: selectedReport
          }
        }
        await exportReportRSPA({ data }).then(response => {
          saveAs(response.data, 'relatorio.xlsx')
          handleClose()
          setSelectedReport([])
        })
      } catch (error) {
        console.error(error)
        alert('Erro ao gerar diário', 'error')
      }
    } else {
      alert('Selecione pelo menos um diário', 'warning')
    }
  }

  useEffect(() => {
    getGeneratedReportsById({ id: reportTemplateId })
  }, [])

  const columns = [
    {
      key: 'checked',
      name: '',
      render: function render (row) {
        return (
          <CheckboxInput
            name='reports'
            checked={selectedReport.includes(row.id)}
            handleCheck={() => handleCheck(row.id)}
          />
        )
      }
    },
    {
      key: 'date',
      name: 'Data',
      render: row => {
        return moment(row.attributes.created_at).format('DD/MM/YYYY HH:mm')
      }
    },
    {
      key: 'status',
      changeRowColor: '#00ACDB',
      name: 'Status',
      render: row => {
        const statusProperties = (status, isForColor) => {
          switch (status) {
            case 'done': {
              if (isForColor) return '#00ACDB'
              return doneSVG
            }
            case 'pending':
              if (isForColor) return '#F69E9E'
              return pendingSVG
            case 'open':
              if (isForColor) return '#C4C4C4'
              return openSVG
            case 'waiting_moderation': {
              if (isForColor) return '#F1925B'
              return moderatingSVG
            }
            default:
              return ''
          }
        }
        return (
          <div className={classes.statusRoot}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledSVG
                height={20}
                width={20}
                src={statusProperties(row.attributes.status)}
              />
            </div>
            <div>
              <span
                style={{ color: statusProperties(row.attributes.status, true) }}
                className={classes.status}
              >
                {reportStatus[row.attributes.status]}
              </span>
            </div>
          </div>
        )
      }
    },
    {
      key: 'action',
      name: 'Ações',
      render: function render (row) {
        return (
          <div className={classes.actionsSection}>
            <Tooltip
              title={
                row.attributes.status === 'done'
                  ? 'Visualizar'
                  : row.attributes.status === 'waiting_moderation' && isAdmin
                    ? 'Moderação'
                    : 'Preencher'
              }
            >
              <div
                onClick={() => {
                  handleReportStudents(row.id)
                }}
              >
                <StyledSVG
                  src={
                    row.attributes.status === 'done'
                      ? viewSVG
                      : row.attributes.status === 'waiting_moderation' &&
                        isAdmin
                        ? moderatingSVG
                        : respondReportSVG
                  }
                  width={20}
                  height={20}
                  className={
                    row.attributes.status === 'waiting_moderation' &&
                    isAdmin &&
                    classes.moderatingSvg
                  }
                />
              </div>
            </Tooltip>
            {/* <MenuItem
              className={classes.action}
              onClick={() => handleReportStudents(row.id)}
            >
              {row.attributes.status === 'done'
                ? 'Visualizar'
                : row.attributes.status === 'waiting_moderation' && isAdmin
                  ? 'Moderação'
                  : 'Preencher'}
            </MenuItem> */}
          </div>
        )
      }
    }
  ]
  const handleReportStudents = async reportId => {
    history.push(`/reports/${reportId}/classroom`)
  }
  const getColumns = () => {
    const columnKeys = ['checked', 'date', 'status', 'action']
    return columns.filter(column => columnKeys.includes(column.key))
  }

  const classroom = reports?.currentTemplate?.classroom?.map(clsr => clsr.name)
  const grade = reports?.currentTemplate?.classroom?.map(
    clrs => clrs.grade.name
  )
  const shift = reports?.currentTemplate?.classroom?.map(
    clrs => reportsShift[clrs.shift]
  )

  return (
    <div>
      <ReportTemplateLabel
        classroom={classroom}
        grade={grade}
        shift={shift}
        lastTeacher={lastTeacher}
        lastReportTemplateDate={lastReportTemplateDate}
      />
      <CustomTable
        columns={getColumns()}
        data={reports.reportsFromTemplate.items}
        params={defaultParams}
        fetchItems={getGeneratedReportsById}
        isFetching={reports.reportsFromTemplate.isFetching}
        pagination={reports.reportsFromTemplate.pagination}
        noDataAlert={<NoReportsDiv />}
        paramId={reportTemplateId}
        border={'none'}
        bodyBorder={'none'}
        headCellRadius
      />
      <div style={{ position: 'relative' }}>
        <div className={classes.buttons}>
          <Button
            type='button'
            variant='outlined'
            className={classes.backBtn}
            onClick={() => {
              const data = { ...currentPagination, canGoBack: true }
              backToThePage(data)
              localStorage.getItem('origeNavegate')
                ? history.push(
                    `/reports?name=${localStorage.getItem('origeNavegate')}`
                  )
                : history.push(`/reports`)
            }}
          >
            Voltar
          </Button>
          <Button
            id='export'
            type='button'
            className={classes.exportBtn}
            onClick={handleExportReport}
          >
            Exportar para Excel
          </Button>
        </div>
      </div>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    currentPagination: state.pagination
  }
}

const mapDispatchToProps = dispatch => ({
  getGeneratedReportsById: data => dispatch(getGeneratedReportsById(data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    ),
  backToThePage: data => dispatch(backToThePage(data)),
  getLastTeacherByReportRSPA: data =>
    dispatch(getLastTeacherByReportRSPA.request(data)),
  exportReportRSPA: data => dispatch(exportReportRSPA.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportsListView)
