import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import {
  clearCurrentClassroom,
  createClassroomTeacher,
  editClassroomTeacher
} from '../store/classrooms/actions'
import CreateTeacherForm from '../components/form/CreateTeacherForm'
import { CircularProgress } from '@material-ui/core'
import {
  getTeacherById,
  removeClassroomTeachers,
  removeTeacherSubjects
} from '../store/teachers/actions'
import { roles } from '../utils/constants'

const mapStateToProps = ({ auth, school, classrooms, teachers }) => {
  return {
    userOccupations: auth.currentOccupation,
    currentTeacher: teachers.currentItem,
    teacherIsFetching: teachers.isFetching,
    currentSchool: school.currentSchool,
    classrooms: classrooms,
    isFetching: classrooms.isFetching
  }
}

const mapDispatchToProps = {
  createClassroomTeacher,
  editClassroomTeacher,
  clearCurrentClassroom,
  getTeacherById,
  removeClassroomTeachers,
  removeTeacherSubjects
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem 0'
    // maxWidth: 1220
  }
}))

const CreateTeacherPage = ({
  currentTeacher,
  currentSchool,
  createClassroomTeacher,
  isFetching,
  teacherIsFetching,
  match,
  editClassroomTeacher,
  getTeacherById,
  clearCurrentClassroom,
  removeClassroomTeachers,
  userOccupations,
  removeTeacherSubjects
}) => {
  const classes = useStyles()
  const { teacherId } = match.params
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)

  useEffect(
    () => {
      const loadData = async () => {
        await getTeacherById(teacherId)
      }
      teacherId && loadData()

      return () => {
        clearCurrentClassroom()
      }
    },
    [teacherId]
  )

  if (isFetching || teacherIsFetching) {
    return <CircularProgress color='secondary' />
  }

  return (
    <div className={classes.root}>
      <CreateTeacherForm
        removeTeacherSubjects={removeTeacherSubjects}
        currentTeacher={currentTeacher}
        school={currentSchool}
        createClassroomTeacher={createClassroomTeacher}
        editClassroomTeacher={editClassroomTeacher}
        removeClassroomTeachers={removeClassroomTeachers}
        isVersarAdmin={isVersarAdmin}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeacherPage)
