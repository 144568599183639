import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { reduxForm, change, formValueSelector, Field, Form } from 'redux-form'
import { connect } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import SaveIcon from '@material-ui/icons/Save'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import SettingsIcon from '@material-ui/icons/Settings'

import { showAlertMessage } from '../../store/alert/actions'

import {
  DroppableContainer,
  Title,
  ItemList,
  DraggableContainer,
  Item
} from '../view/styles/PublishContent.style'
import { CreatableAdvancedSelect as CreatableSelect } from '../select/ReactSelect'
import ConfirmModal from '../modal/ConfirmModal'
import jsonApi from '../../utils/jsonApi'
import HeaderCard from '../card/HeaderCard'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 1220,
    '& h2,h3': {
      color: theme.palette.primary.main
    }
  },
  container: {
    paddingLeft: 0
    // maxWidth: '100vw'
  },
  paper: {
    padding: 0,
    margin: 0,
    borderRadius: '8px',
    lineHeight: '20.11px !important',
    fontSize: 16,
    minWidth: 300,
    maxWidth: '100vw'
  },
  draggableContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& h3': {
      margin: 'auto'
    }
  },
  card: {
    userSelect: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    border: `1px dashed ${theme.palette.primary.brightest}`,
    color: theme.palette.text.primary,
    cursor: 'pointer',
    width: 200,
    height: 116,
    '& svg': {
      color: theme.palette.primary.main,
      fontSize: '3.5rem'
    }
  },
  cardModule: {
    color: theme.palette.text.primary
  },
  chooseTheme: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginLeft: 'auto'
    }
  },
  cardAddClass: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'bold',
    minWidth: 160,
    [theme.breakpoints.up('md')]: {
      margin: 'auto 1rem'
    }
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  saveBtn: {
    color: '#FFFF',
    backgroundColor: '#386093',
    width: 165,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const GradeTemplateForm = ({
  grade,
  change,
  templateValues,
  handleSubmit,
  onSubmit,
  pristine,
  cyclesOptions,
  submitting,
  initialValues,
  touch
}) => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const templateData = {
    themes: {
      // 'theme-1': { id: 'theme-1', theme: 'thema 1', module: 'module 1' },
    },
    rows: {
      [cyclesOptions[0].value]: {
        name: cyclesOptions[0].label,
        cycle_id: cyclesOptions[0].value,
        theme_ids: []
      }
    },
    rowOrder: [cyclesOptions[0].value]
  }
  const [state, setState] = useState(templateValues || templateData)
  const [openEditRowTitle, setOpenEditRowTitle] = useState(null)
  const [rowTitle, setRowTitle] = useState({})
  const selectCycleRef = React.useRef(null)
  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    title: null,
    id: null
  })
  const [removeThemeModal, setRemoveThemeModal] = React.useState({
    open: false,
    title: null,
    id: null,
    row: {}
  })
  useEffect(
    () => {
      if (Object.keys(initialValues).length > 0) {
        setState(initialValues)
      } else {
        const selectedTheme = history.location.state?.theme
        !selectedTheme && setState(templateData)
      }
    },
    [initialValues]
  )

  useEffect(() => {
    const selectedTheme = history.location.state?.theme
    const templateQueries = history.location.state?.templateQueries?.split(
      '&name'
    )[0]
    const rowId = templateQueries?.split('?id=')[1]
    rowId && addNewTheme(rowId, selectedTheme)
  }, [])

  useEffect(
    () => {
      change('template_values', state)
    },
    [state]
  )

  const onDragEnd = result => {
    const { destination, source, draggableId } = result
    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const start = state.rows[source.droppableId]
    const finish = state.rows[destination.droppableId]

    if (start === finish) {
      const newThemeIds = Array.from(start.theme_ids)
      newThemeIds.splice(source.index, 1)
      newThemeIds.splice(destination.index, 0, draggableId)

      const newRow = {
        ...start,
        theme_ids: newThemeIds
      }

      const newState = {
        ...state,
        rows: {
          ...state.rows,
          [newRow.cycle_id]: newRow
        }
      }
      setState(newState)
    } else {
      // Moving from one list to another
      const startThemeIds = Array.from(start.theme_ids)
      startThemeIds.splice(source.index, 1)
      const newStart = {
        ...start,
        theme_ids: startThemeIds
      }

      const finishThemeIds = Array.from(finish.theme_ids)
      finishThemeIds.splice(destination.index, 0, draggableId)
      const newFinish = {
        ...finish,
        theme_ids: finishThemeIds
      }

      const newState = {
        ...state,
        rows: {
          ...state.rows,
          [newStart.cycle_id]: newStart,
          [newFinish.cycle_id]: newFinish
        }
      }
      setState(newState)
    }
  }

  const saveChangeCycle = (rowId, rowTitle) => {
    const row = state.rows[rowId]
    const rows = { ...state.rows }
    const newRowOrder = state.rowOrder.filter(id => rowId !== id)
    const newRow = {
      ...row,
      name: rowTitle.label,
      cycle_id: rowTitle.value
    }
    Object.keys(rows).forEach((key, index) => {
      if (key === rowId) {
        delete rows[key]
      }
    })
    const rowPosition = state.rowOrder.findIndex(cycleRow => cycleRow === rowId)
    newRowOrder.splice(rowPosition, 0, newRow.cycle_id)

    setState(prevState => {
      return {
        ...prevState,
        rows: {
          ...rows,
          [newRow.cycle_id]: newRow
        },
        rowOrder: newRowOrder
      }
    })
    setRowTitle({})
  }

  const handleChangeName = (event, inputName) => {
    if (event) {
      const regexRow = inputName.match(/\d/g)
      const rowId = regexRow?.length && regexRow.join('')
      change(inputName, event)
      setRowTitle(event)
      setOpenEditRowTitle(null)
      saveChangeCycle(rowId, event)
    }
  }

  const handleEditRowTitle = rowId => {
    if (rowTitle?.value) {
      setOpenEditRowTitle(null)
      saveChangeCycle(rowId, rowTitle)
    }
  }

  const handleOpenEditRow = row => {
    setRowTitle({})
    setOpenEditRowTitle(row.cycle_id)
    setRowTitle({ label: row.name, value: row.value })
  }

  const addNewTheme = (rowId, theme) => {
    const row = state.rows[rowId]
    const newTheme = {
      [theme.id]: {
        id: theme.id,
        theme: theme.title,
        module: theme.theme_module?.title
      }
    }
    const themeArray = row ? new Set([...row.theme_ids, theme.id]) : []
    const newRow = {
      ...row,
      theme_ids: [...themeArray]
    }
    const newState = {
      ...state,
      rows: {
        ...state.rows,
        [newRow.cycle_id]: newRow
      },
      themes: { ...state.themes, ...newTheme }
    }
    setState(newState)
  }

  const removeRow = (rowId, theme) => {
    const stateCopy = { ...state }
    delete stateCopy.rows[rowId]

    const newRowOrder = stateCopy.rowOrder.filter(id => id !== rowId)
    const newState = {
      ...stateCopy,
      rowOrder: newRowOrder
    }
    setState(newState)
  }

  const AddRow = () => {
    const stateCopy = { ...state }
    const lastValue = stateCopy.rowOrder[stateCopy.rowOrder.length - 1]
    const findIndexPreviousCycle = cyclesOptions.findIndex(
      cycle => cycle.value === String(lastValue)
    )
    const cyclesWithoutPrevious = cyclesOptions.slice(
      findIndexPreviousCycle + 1
    )
    const nextRow =
      cyclesWithoutPrevious &&
      cyclesWithoutPrevious.length > 0 &&
      cyclesWithoutPrevious[0]
    const inCaseNoRow = lastValue ? String(Number(lastValue) + 1) : '1'
    const newRowOrder = stateCopy.rowOrder.concat(nextRow.value || inCaseNoRow)
    const newRow = {
      name: nextRow.label || 'Ciclo',
      cycle_id: nextRow.value || inCaseNoRow,
      theme_ids: []
    }
    const newState = {
      ...stateCopy,
      rows: {
        ...state.rows,
        [nextRow.value || inCaseNoRow]: newRow
      },
      rowOrder: newRowOrder
    }
    setState(newState)
  }

  return (
    <div className={classes.root}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <HeaderCard>
            <div style={{ margin: 'auto 1rem' }}>
              <h1>{grade?.name}</h1>
              <p style={{ paddingTop: 0 }}>{grade?.segment?.name}</p>
            </div>
          </HeaderCard>
          <Grid item xs={12} container className={classes.container}>
            <Grid item xs={12}>
              <div className={classes.paper}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <div className={classes.draggableContainer}>
                    {state.rowOrder.map(rowId => {
                      const row = state.rows[rowId]
                      const cycle = cyclesOptions.find(
                        cycle => cycle.value === rowId
                      )
                      const themes =
                        row &&
                        row.theme_ids.map(themeId => {
                          const theme = state.themes[themeId]
                          return theme || ''
                        })
                      return (
                        row && (
                          <DroppableContainer
                            key={`droppable[${row.cycle_id}]`}
                            elevation={3}
                          >
                            <div style={{ display: 'flex' }}>
                              <Title>
                                {openEditRowTitle &&
                                openEditRowTitle === row.cycle_id ? (
                                  <>
                                    <Field
                                      selectRef={selectCycleRef}
                                      component={CreatableSelect}
                                      id={`row[${row.cycle_id}]`}
                                      name={`row[${row.cycle_id}]`}
                                      defaultValue={cycle}
                                      handleChange={handleChangeName}
                                      placeholder='Selecione ou digite um ciclo'
                                      requestPath='cycle'
                                      // schoolId={selectedSchool?.id}
                                      options={cyclesOptions.filter(
                                        cycle =>
                                          !state.rowOrder.includes(cycle.value)
                                      )}
                                      touch={touch}
                                    />
                                    <SaveIcon
                                      style={{
                                        verticalAlign: 'middle',
                                        marginTop: '5px'
                                      }}
                                      onClick={() =>
                                        handleEditRowTitle(row.cycle_id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <span>{row.name || cycle?.label}</span>
                                    <SettingsIcon
                                      onClick={() => handleOpenEditRow(row)}
                                    />
                                  </>
                                )}
                              </Title>
                              <HighlightOffIcon
                                color='secondary'
                                onClick={() => {
                                  if (row.theme_ids.length > 0) {
                                    setConfirmModal({
                                      id: row.cycle_id,
                                      title: row.name || cycle?.label,
                                      open: true
                                    })
                                  } else {
                                    removeRow(row.cycle_id)
                                  }
                                }}
                                style={{
                                  cursor: 'pointer',
                                  margin: '-1.2rem 0.3rem 0 0'
                                }}
                              />
                            </div>
                            <Droppable
                              droppableId={
                                row.cycle_id || `dropppable-${rowId}`
                              }
                              direction={matches ? 'horizontal' : 'vertical'}
                            >
                              {(provided, snapshot) => (
                                <ItemList
                                  ref={provided.innerRef}
                                  isDraggingOver={snapshot.isDraggingOver}
                                  {...provided.droppableProps}
                                >
                                  {themes.map((item, index) => {
                                    return (
                                      <Draggable
                                        key={item.id}
                                        draggableId={item.id || String(index)}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <DraggableContainer
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            isDragging={snapshot.isDragging}
                                          >
                                            <Item>
                                              <div>
                                                <span>{item.theme}</span>
                                                <span
                                                  className={classes.cardModule}
                                                >
                                                  {item.module}
                                                </span>
                                              </div>
                                              <HighlightOffIcon
                                                onClick={() => {
                                                  setRemoveThemeModal({
                                                    id: item.id,
                                                    title: item.theme,
                                                    open: true,
                                                    row: row
                                                  })
                                                }}
                                              />
                                            </Item>
                                          </DraggableContainer>
                                        )}
                                      </Draggable>
                                    )
                                  })}
                                  {provided.placeholder}
                                  <div className={classes.chooseTheme}>
                                    <Tooltip title='Adicionar outro tema'>
                                      <div
                                        className={`${classes.card} ${
                                          classes.cardAddClass
                                        }`}
                                        onClick={() =>
                                          history.push(
                                            `${
                                              history.location.pathname
                                            }/search?id=${row.cycle_id}&name=${
                                              row.name
                                            }`
                                          )
                                        }
                                      >
                                        <AddCircleOutlineIcon />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </ItemList>
                              )}
                            </Droppable>
                          </DroppableContainer>
                        )
                      )
                    })}
                  </div>
                </DragDropContext>

                <div style={{ textAlign: 'center' }}>
                  <Tooltip title='Adicionar ciclo'>
                    <AddCircleOutlineIcon
                      className={classes.addIcon}
                      style={{ fontSize: '2.5rem', cursor: 'pointer' }}
                      onClick={AddRow}
                    />
                  </Tooltip>
                </div>
                <div style={{ textAlign: 'end' }}>
                  <Button
                    variant='outlined'
                    type='button'
                    style={{
                      marginRight: '1rem'
                    }}
                    className={classes.backBtn}
                    onClick={() => history.goBack()}
                  >
                    Voltar
                  </Button>
                  <Button
                    className={classes.saveBtn}
                    variant='contained'
                    type='submit'
                    disabled={pristine || submitting}
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <ConfirmModal
          title={`Deseja remover do ciclo o tema "${
            removeThemeModal.title
          }"? Após a remoção, será necessário salvar as alterações feitas no ciclo.`}
          open={removeThemeModal.open}
          setOpen={value => {
            if (value) {
              setRemoveThemeModal({ ...removeThemeModal, open: value })
            } else {
              setRemoveThemeModal({ id: null, title: '', open: value, row: {} })
            }
          }}
          onConfirm={() => {
            const newThemeIds = removeThemeModal.row.theme_ids.filter(
              id => id !== removeThemeModal.id
            )
            const newRow = {
              ...removeThemeModal?.row,
              theme_ids: newThemeIds
            }
            const newState = {
              ...state,
              rows: {
                ...state.rows,
                [newRow.cycle_id]: newRow
              }
              // themes: newThemes
            }
            setState(newState)
          }}
        />
        <ConfirmModal
          title={`Deseja deletar - ${confirmModal.title}?`}
          open={confirmModal.open}
          setOpen={value => {
            if (value) {
              setConfirmModal({ ...confirmModal, open: value })
            } else {
              setConfirmModal({ id: null, title: '', open: value })
            }
          }}
          onConfirm={() => {
            removeRow(confirmModal.id)
          }}
        />
      </Form>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('gradeTemplateForm')
  return {
    initialValues: props.initValues,
    tempValues: selector(state, 'template_values'),
    templateValues:
      state.form?.gradeTemplateForm?.values &&
      state.form.gradeTemplateForm.values.template_values
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('gradeTemplateForm', field, data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'gradeTemplateForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    keepDirtyOnReinitialize: true,
    onSubmit: async (values, dispatch, props) => {
      const rows = Object.keys(values.template_values.rows).map(key => {
        const newRows = values.template_values.rows[key]
        delete newRows['name']
        return newRows
      })
      const templateId =
        props.grade.schedule_templates && props.grade.schedule_templates[0]?.id
      const data = {
        template: rows,
        grade_id: props.grade.id
      }
      if (templateId) {
        jsonApi
          .put('schedule_template', { id: templateId, ...data })
          .then(res => {
            props.alert('Sucesso ao editar template.', 'success')
            props.history.push('/planning/grades')
          })
          .catch(err => {
            props.alert('Falha ao editar template.', 'error')
            console.error(err)
          })
      } else {
        jsonApi
          .create('schedule_template', data)
          .then(res => {
            props.alert('Sucesso ao salvar o template.', 'success')
          })
          .catch(err => {
            props.alert('Falha ao salvar o template.', 'error')
            console.error(err)
          })
      }
    }
  })(GradeTemplateForm)
)
