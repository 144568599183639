import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Card from '../card/Card'
import Label from '../label/Label'
import jsonApi from '../../utils/jsonApi'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '2rem',
    '& a': {
      textDecoration: 'none'
    },
    '& h3': {
      color: theme.palette.primary.main
    }
  },
  label: {
    marginBottom: '1.5rem'
  },
  control: {
    padding: theme.spacing(2)
  },
  list: {
    borderRadius: 8,
    display: 'flex',
    flexWrap: 'wrap'
  },
  cardButton: {
    '& div.MuiPaper-root': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 10
      }
    },
    '& div': {
      maxWidth: 200,
      height: 80,
      paddingTop: '1rem'
    }
  },
  title: {
    fontSize: 20,
    lineHeight: '22px',
    width: 120,
    textAlign: 'center'
  },
  segmentsContainer: {
    backgroundColor: '#FFF',
    marginRight: '1rem',
    borderRadius: '8px',
    boxShadow: '3px 11px 14px 6px rgb(107 103 103 / 10%)',
    marginBottom: '2rem',
    padding: '1rem 2rem 2rem',
    width: '500px',
    '& > h2': {
      color: theme.palette.primary.main
    }
  },
  gradesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '10px'
    /* gridGap: '30px',
     gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)'
    } */
  }
}))

const initialState = {
  items: {},
  pagination: {
    current: 1,
    totalPages: 1,
    per: 20,
    hasNextPage: true
  }
}

const reducer = (state, action) => {
  return { ...state, [action.property]: action.value }
}

const PlanningGradesView = ({ school, selectedSchool }) => {
  const classes = useStyles()
  const history = useHistory()
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const { items, pagination: { current, totalPages, per, hasNextPage } } = state

  const defaultParams = {
    'page[number]': initialState.pagination.current,
    'page[size]': per,
    'q[school_id_in]': selectedSchool?.id || (school && school.school_id),
    sort: 'grade_id',
    include: 'grade,grade.segment,grade.schedule_templates'
  }

  useEffect(() => {
    getData({ params: defaultParams })
  }, [])

  useEffect(
    () => {
      const loadMoreData = () => {
        if (current < totalPages) {
          const params = {
            ...defaultParams,
            'page[number]': current + 1
          }
          getData({ params })
        } else {
          dispatch({
            property: 'pagination',
            value: {
              ...state.pagination,
              hasNextPage: false
            }
          })
        }
      }
      loadMoreData()
    },
    [items]
  )

  const getData = async ({ params, hasNxtPage = hasNextPage }) => {
    if (!hasNxtPage) return
    jsonApi
      .findAll('classroom', {
        params: { ...params }
      })
      .then(res => {
        if (res.data) {
          let newHasNextPage = true
          let newPage = current
          if (res.meta.page_count * 1 > current) {
            newPage = params['page[number]']
          }
          if (res.meta.total_count === items.length + res.data.length) {
            newHasNextPage = false
          }
          dispatch({
            property: 'pagination',
            value: {
              ...state.pagination,
              totalPages: res.meta.page_count,
              hasNextPage: newHasNextPage,
              current: newPage
            }
          })
          const classroomsGrades = res.data.map(classroom => classroom.grade)
          const parsedData = classroomsGrades.reduce(
            (previousValue, currentValue) => {
              const segment = currentValue.segment.name
              return {
                ...previousValue,
                [segment]: [...(previousValue[segment] || []), currentValue]
              }
            },
            {}
          )

          let mergedObject = {}
          Object.entries(items).forEach(([key, value]) => {
            mergedObject[key] = value
          })
          Object.entries(parsedData).forEach(([key, value]) => {
            if (mergedObject[key]) {
              mergedObject[key] = mergedObject[key].concat(value)
            } else {
              mergedObject[key] = value
            }
          })

          dispatch({ property: 'items', value: mergedObject })
        }
      })
  }

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.label}>
          <Label>
            Séries {selectedSchool?.id && ' - ' + selectedSchool.name}
          </Label>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.list}>
          {Object.entries(state.items)
            .filter(([key, value], index, self) => key !== 'null')
            .map(([key, value]) => {
              return (
                <div
                  key={`segment[${key}]`}
                  className={classes.segmentsContainer}
                >
                  <h3>{key}</h3>
                  <div className={classes.gradesContainer}>
                    {value
                      .filter(
                        (grade, index, self) =>
                          index === self.findIndex(s => s.id === grade.id)
                      )
                      .map(grade => {
                        return (
                          <Link
                            className={classes.cardButton}
                            key={grade.id}
                            to={{
                              pathname: `${history.location.pathname}/${
                                grade.id
                              }`
                            }}
                          >
                            <Card
                              color={
                                grade.schedule_templates.length > 0
                                  ? 'secondary'
                                  : 'default'
                              }
                            >
                              <CardContent>
                                <Typography
                                  className={classes.title}
                                  variant='h5'
                                  component='h2'
                                >
                                  {grade.name}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Link>
                        )
                      })}
                  </div>
                </div>
              )
            })}
        </Grid>
      </Grid>
    </>
  )
}

export default PlanningGradesView
