import { createAction } from '@reduxjs/toolkit'
import * as types from './types'

export const getContents = createAction(types.GET_CONTENTS)
export const getContentsSuccessful = createAction(types.GET_CONTENTS_SUCCESSFUL)
export const getContentsFailure = createAction(types.GET_CONTENTS_FAILURE)
export const getContentById = createAction(types.GET_CONTENT_BY_ID)
export const getContentByIdSuccessful = createAction(
  types.GET_CONTENT_BY_ID_SUCCESSFUL
)
export const getContentByIdFailure = createAction(
  types.GET_CONTENT_BY_ID_FAILURE
)
export const getContentsByIds = createAction(types.GET_CONTENTS_BY_IDS)

export const createContents = createAction(types.CREATE_CONTENTS)
export const createContentsSuccessful = createAction(
  types.CREATE_CONTENTS_SUCCESSFUL
)
export const createContentsFailure = createAction(types.CREATE_CONTENTS_FAILURE)

export const deleteContent = createAction(types.DELETE_CONTENT)
export const deleteContentSuccessful = createAction(
  types.DELETE_CONTENT_SUCCESSFUL
)
export const deleteContentFailure = createAction(types.DELETE_CONTENT_FAILURE)

export const editContent = createAction(types.EDIT_CONTENT)
export const editContentSuccessful = createAction(types.EDIT_CONTENT_SUCCESSFUL)
export const editContentFailure = createAction(types.EDIT_CONTENT_FAILURE)

export const deleteDocument = createAction(types.DELETE_DOCUMENT)
export const deleteAudio = createAction(types.DELETE_AUDIO)
export const clearCurrentContent = createAction(types.CLEAR_CURRENT_CONTENT)
