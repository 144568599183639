import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

import {
  getClassrooms,
  editClassroomChildren,
  getClassroomChildren,
  getClassroomById
} from '../store/classrooms/actions'
import CreateStudentForm from '../components/form/CreateStudentForm'

const mapStateToProps = ({ school, classrooms }) => {
  return {
    currentClassroom: classrooms.currentItem,
    currentClassroomChild: classrooms.currentChild,
    isFetching: classrooms.isFetching
  }
}

const mapDispatchToProps = {
  getClassrooms,
  getClassroomChildren,
  editClassroomChildren,
  getClassroomById
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem 0'
    // maxWidth: 1220
  }
}))

const CreateStudentPage = ({
  currentClassroom,
  currentClassroomChild,
  getClassroomChildren,
  editClassroomChildren,
  isFetching,
  getClassroomById,
  match
}) => {
  const classes = useStyles()
  const { classId, childId } = match.params
  useEffect(() => {
    const loadData = () => {
      getClassroomById(classId)
      childId && getClassroomChildren(childId)
    }
    loadData()
  }, [])

  if (
    isFetching ||
    (childId && currentClassroomChild && !currentClassroomChild.name)
  ) {
    return <CircularProgress color='secondary' />
  }

  return (
    <div className={classes.root}>
      <CreateStudentForm
        editClassroomChildren={editClassroomChildren}
        currentClassroomChild={currentClassroomChild}
        currentGrade={currentClassroom?.grade}
        classroomId={classId}
        isFetching={isFetching}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateStudentPage)
