import React from 'react'
import { reduxForm, Form, Field, change, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import Button from '../button/Button'
import Input from '../shared/Input'
import Radios from '../shared/RadioButton'
import { showAlertMessage } from '../../store/alert/actions'

const useStyles = makeStyles(theme => ({
  colors: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  btnStyle: {
    alignSelf: 'center',
    '& button': {
      height: 40,
      marginTop: '1.5rem',
      minWidth: 150
    }
  },
  colorField: {
    display: 'flex',
    '& input[type=color]': {
      backgroundColor: 'transparent',
      border: 'none',
      height: 30,
      width: 30,
      outline: 'none',
      '&::-webkit-color-swatch': {
        border: 'none'
      }
    },
    '& div': {
      alignSelf: 'center',
      marginRight: '0.5rem'
    },
    '& div > div': {
      marginBottom: 0
    }
  },
  schoolsField: {
    marginTop: '2rem'
  }
}))
const SettingsLayoutForm = ({
  handleSubmit,
  onsubmit,
  pristine,
  submit,
  initialValues,
  submitting,
  schoolsList,
  schoolValue,
  isAdmin,
  change
}) => {
  const classes = useStyles()

  const handleChange = async (event, newValue) => {
    const school = schoolsList.find(school => school.id === newValue) || {}
    change('school_id', newValue)
    change('primary_color', school.primary_color)
    change('secondary_color', school.secondary_color)
    change('avatar', school.avatar_url)
  }

  return (
    <Form onSubmit={handleSubmit}>
      {isAdmin && (
        <div className={classes.schoolsField}>
          <Field
            component={Radios}
            name='school_id'
            radios={schoolsList.map(school => ({
              value: school.id,
              label: school.name
            }))}
            handleRadioChange={handleChange}
            required
            value={schoolValue}
          />
        </div>
      )}
      {!(isAdmin && !schoolValue) && (
        <>
          <div className={classes.colors}>
            <div className={classes.colorField}>
              <Field
                component={Input}
                name='primary_color'
                placeholder='Cor'
                type='color'
              />
              <p>Cor Primária</p>
            </div>
            <div className={classes.colorField}>
              <Field
                component={Input}
                name='secondary_color'
                placeholder='Cor'
                type='color'
              />
              <p>Cor Secundária</p>
            </div>
          </div>
          <div className={classes.btnStyle}>
            <Button
              color='secondary'
              type='submit'
              disabled={pristine || submitting}
            >
              Salvar
            </Button>
          </div>
        </>
      )}
    </Form>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('settingsLayoutForm')
  const school =
    state.school.currentSchool?.school && state.school.currentSchool.school
  const initValues = {
    primary_color:
      (school?.avatar?.record && school.avatar.record.primary_color) ||
      process.env.REACT_APP_PROJECT_COLOR_PRIMARY,
    secondary_color:
      (school?.avatar?.record && school.avatar.record.secondary_color) ||
      process.env.REACT_APP_PROJECT_COLOR_SECONDARY,
    school_id: school?.id,
    avatar: school && school.avatar_url
  }

  return {
    initialValues: initValues,
    schoolValue: selector(state, 'school_id')
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('settingsLayoutForm', field, data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'settingsLayoutForm',
    ouchOnChange: true,
    touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {
      props.updateLayoutSchool({ id: values.school_id, ...values })
    }
  })(SettingsLayoutForm)
)
