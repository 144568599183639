import http from '../../utils/http'

const fetchSkillsUrl = '/skills'

export const fetchSkills = ({ idSegment }) =>
  http
    .get(fetchSkillsUrl, {
      params: {
        'q[segments_id_in]': idSegment,
        'page[size]': 20
      }
    })
    .then(res => res.data)
