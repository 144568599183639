import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  reduxForm,
  Form,
  Field,
  formValueSelector,
  getFormValues
} from 'redux-form'
import { InputNumber } from 'antd'

import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import ChipMaterial from '@material-ui/core/Chip'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import AsyncSelect from '../shared/AsyncSelect'
import MaterialInput from '../shared/MaterialInput'
import Button from '../button/Button'
import { roles } from '../../utils/constants'
import Chip from '../shared/Chip'
import Stepper from '../shared/Stepper'
import { getTags } from '../../store/tags/actions'
import { backToThePage } from '../../store/pagination/actions'
import {
  getReportTemplateById,
  createReports,
  editReportTemplate
} from '../../store/reports/actions'
import { showAlertMessage } from '../../store/alert/actions'
import { required, validReportTitle } from '../../utils/formUtils'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiRadio-root': {
      '& svg': {
        height: 18,
        width: 18
      }
    },
    '& .MuiChip-clickable': {
      marginBottom: 16
    }
  },
  label: {
    display: 'block',
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 24,
    marginBottom: 16
  },
  classroomList: {
    marginBottom: 14,
    '& span': {
      backgroundColor: '#F6F7F8',
      borderRadius: 32,
      display: 'inline-flex',
      alignItems: 'center',
      gap: 8,
      margin: '5px 10px',
      padding: '5px 10px'
    },
    '& button': {
      border: 'none',
      background: 'none',
      color: theme.palette.info.main,
      cursor: 'pointer',
      fontSize: 16,
      fontWeight: 700,
      textDecorationLine: 'underline'
    }
  },
  requiredField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 40,
    '& label.MuiFormLabel-root': {
      color: theme.palette.primary.main,
      fontWeight: 600,
      marginRight: 12
    },
    '& .MuiRadio-root': {
      padding: 4
    }
  },
  grayField: {
    '& label.MuiFormLabel-root': {
      color: theme.palette.text.secondary
    }
  },
  buttons: {
    marginTop: 10,
    '& .defaultBtn': {
      cursor: 'pointer',
      // margin: theme.spacing(1),
      borderRadius: 8,
      border: 'unset',
      backgroundColor: '#386093',
      borderColor: 'none',
      boxShadow: 'none',
      color: '#FFF',
      width: 136,
      height: 44,
      textTransform: 'capitalize',
      fontWeight: 'bold',
      fontSize: '16px',
      [theme.breakpoints.down('426')]: {
        fontSize: '14px',
        marginRight: 0
      }
    },
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    [theme.breakpoints.down('850')]: {
      justifyContent: 'flex-start'
    },
    [theme.breakpoints.down('426')]: {
      marginTop: props =>
        props.activeStep === 1
          ? '200px'
          : props.activeStep === 2 ? 'unset' : '20px',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    }
  },
  tagChip: {
    background: 'rgba(33, 33, 33, 0.08)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: '#7B7B7B',
    fontSize: 14,
    margin: '0 16px 16px 0',
    '& > .MuiChip-deleteIcon': {
      color: 'rgba(0, 0, 0, 0.6)',
      height: 18,
      width: 18
    }
  },
  defaultFields: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    '& .classroom-field': {
      '& > div': {
        width: '300px',
        maxWidth: '100%',
        [theme.breakpoints.down('1500')]: {
          width: '300px'
        }
      }
    },
    '& .title-field': {
      '& > div': {
        width: '800px',
        maxWidth: '100%',
        [theme.breakpoints.down('1500')]: {
          width: '400px'
        }
      }
    },
    [theme.breakpoints.down('1100')]: {
      flexDirection: 'column'
    }
  },
  accordion: {
    '& .MuiAccordionSummary-root': {
      display: 'none'
    },
    '& .MuiAccordionDetails-root': {
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'column'
    },
    '& .MuiAccordion-root': {
      width: '0px'
    },
    '& .Mui-expanded': {
      width: '100%',
      border: 'none',
      boxShadow: 'none'
    }
  },
  accordionSumary: {
    display: 'flex'
  },
  exitBtn: {
    width: 100,
    backgroundColor: '#EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  continueBtn: {
    backgroundColor: '#386093',
    width: 179,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

/* const RequiredFields = (
  <>
    <FormControlLabel
      value={'true'}
      control={<Radio color='primary' />}
      label='Sim'
    />
    <FormControlLabel
      value={'false'}
      control={<Radio color='primary' />}
      label='Não'
    />
  </>
) */
const getSteps = {
  1: 'Selecione a turma e o título do diário',
  2: 'Selecione o tipo de frequência',
  3: 'Selecione as informações que deseja inserir no diário'
}

const CreateReportForm = ({
  alert,
  change,
  school,
  touch,
  valid,
  getTags,
  tags,
  handleSubmit,
  onSubmit,
  submitSucceeded,
  userOccupations,
  fieldValues,
  isEdit,
  reportTemplateId,
  getReportTemplateById,
  report,
  initialValues,
  backToThePage,
  currentPagination
}) => {
  const [activeStep, setActiveStep] = useState(1)
  const history = useHistory()
  const classes = useStyles({ activeStep: activeStep })
  const attendancesTags =
    tags.items &&
    tags.items.filter(
      item =>
        // frequencia e frequencia por disciplina
        +item.tag_order === 1 || +item.tag_order === 2
    )
  const [selected, setSelected] = useState(new Set())
  const [requiredFieldsValue, setRequiredFieldsValue] = useState({
    // [+attendanceTag]: 'true'
  })
  const [classroomsSelecteds, setClassroomsSelecteds] = React.useState([])
  const [inputValues, setInputValues] = useState()
  const [accordionForEachTag, setAccordionForEachTag] = useState([])
  const selectingFrequencyTag = activeStep === 2

  const goBack = submit => {
    backToThePage({ ...currentPagination, canGoBack: true })
    submit === true && history.push('/reports?name=history')
  }

  const handleClickButton = type => {
    isEdit
      ? history.push(`/reports?name=${type}`)
      : history.push({ search: `?name=${type}` })
    type !== 'create' && localStorage.setItem('origeNavegate', type)
    goBack(false)
  }

  // definição da ordem 1 para a tag de frequência
  useEffect(
    () => {
      if (selectingFrequencyTag || isEdit) {
        change(`tagOrder_${Array.from(selected)[0]}`, { order: 1 })
      }
    },
    [selected, isEdit]
  )

  useEffect(
    () => {
      tags.items.length > 0 &&
        tags.items.map(tag => {
          const accordion = {
            id: tag.id,
            name: tag.name,
            show:
              // frequencia
              tag.tag_order === 1 ||
              // frequencia por disciplina
              tag.tag_order === 2
          }
          setAccordionForEachTag(prev => {
            const newState = [
              ...(prev?.length > 0 &&
              prev?.find(item => item.id === accordion.id)
                ? [...prev]
                : [...prev, accordion])
            ]
            return newState
          })
        })
    },
    [tags.items]
  )

  useEffect(
    () => {
      reportTemplateId && getReportTemplateById({ id: reportTemplateId })
    },
    [reportTemplateId]
  )

  useEffect(() => {
    getTags({ school })
    touch('selectedFields')
  }, [])

  useEffect(
    () => {
      change('selectedFields', selected)
      change('requiredFields', { ...requiredFieldsValue })
    },
    [selected, requiredFieldsValue]
  )

  submitSucceeded && goBack(true)

  /* const sameOrderValue = arr => {
    let newArrWithoutDuplicates = []
    let arrWithTotalLenght = []
    arr.map(itm => {
      if (itm?.order !== 0 && itm?.order !== null) {
        newArrWithoutDuplicates.push(JSON.stringify(itm))
        arrWithTotalLenght.push(itm)
      }
    })
    return new Set(newArrWithoutDuplicates).size !== arrWithTotalLenght.length
  } */

  React.useEffect(
    () => {
      if (fieldValues?.questions?.length > 0) {
        let tags = []
        fieldValues?.questions?.forEach(question => {
          tags.push(question.tag_id.toString())
        })
        setSelected(new Set(tags))
      }
    },
    [fieldValues?.questions]
  )

  const handleRequiredFields = value => {
    const newState = { ...requiredFieldsValue }
    Object.assign(newState, { [value.name]: value.value })
    setRequiredFieldsValue(newState)
    change('requiredFields', newState)
  }

  /* const handleRadioChange = event => {
    event.target && handleRequiredFields(event.target)
  } */

  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)

  const handleChange = (event, input) => {
    if (input.name === 'classroom_id') {
      setClassroomsSelecteds(event)
    }
    let newValue
    if (Array.isArray(event)) {
      newValue = event?.map(v => ({ classroom_id: v.value }))
    } else {
      newValue = event ? event.value : ''
    }
    change(input.name, newValue)
  }

  const handleSelectionChanged = id => {
    let newSet = new Set(selected)
    // escolha da frequência
    if (selectingFrequencyTag) {
      delete requiredFieldsValue[+selected[0]]
      // caso haja uma mudança na tag de frequencia, será resetado o valor do selectedFields
      newSet = new Set([id])
      handleRequiredFields({ name: id, value: 'true' })
    } else {
      // escolha de outras tags
      if (newSet.has(id)) {
        delete requiredFieldsValue[id]
        newSet.delete(id)
      } else {
        newSet.add(id)
        handleRequiredFields({ name: id, value: 'true' })
      }
    }
    setSelected(newSet)
    change('selectedFields', newSet)
  }

  if (!isAdmin) return 'Sem permissão para acessar essa página'
  const isExpanded = tagId => {
    const tag = accordionForEachTag.find(item => item.id === tagId)
    return tag?.show
  }

  const handleExpande = tagId => {
    setAccordionForEachTag(prev => {
      const tag = prev.find(item => item.id === tagId)
      tag.show = !tag.show
      const newState = [...prev]
      return newState
    })
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepper = stepIndex => {
    if (stepIndex === 1) {
      return !(fieldValues?.classroom_id && fieldValues?.title)
    } else if (stepIndex === 2) {
      if (!isEdit) {
        const selectedValue = selected.size > 0 && Array.from(selected)[0]
        const hasFrequencyTag =
          selectedValue &&
          fieldValues?.[`tagOrder_${selectedValue}`]?.order === 1
        return !hasFrequencyTag
      } else {
        const hasFrequencyTag =
          fieldValues?.tags.map(
            item => +item.tag_order === 1 || +item.tag_order === 2
          ).length > 0
        return !hasFrequencyTag
      }
    } else {
      return true
    }
  }

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 1:
        return (
          <div style={{ marginBottom: '2rem' }}>
            {userOccupations?.includes(roles.VERSAR_ADMIN) && (
              <>
                <label htmlFor='school_id'>Escola</label>
                <Field
                  component={AsyncSelect}
                  id='school_id'
                  name='school_id'
                  placeholder='Digite ou selecione a escola'
                  handleAsyncChange={handleChange}
                  searchParam='q[name_cont]'
                  request={{
                    path: 'school'
                  }}
                  touch={touch}
                />
              </>
            )}
            <div className={classes.defaultFields}>
              <div
                className='field classroom-field'
                style={isEdit ? { display: 'none' } : {}}
              >
                <label htmlFor='classroom_id'>Turma(s)</label>
                <Field
                  component={AsyncSelect}
                  cacheUniqs={[school]}
                  defaultValue={classroomsSelecteds}
                  defaultOptions={false}
                  disabled={isEdit}
                  isMulti
                  id='classroom_id'
                  name='classroom_id'
                  placeholder='Selecione a(s) turma(s)'
                  value={fieldValues?.classroom_id}
                  handleAsyncChange={handleChange}
                  searchParam='q[name_cont]'
                  request={{
                    path: 'classroom',
                    params: {
                      'q[school_id_eq]': school,
                      'q[year_lteq]': new Date().getFullYear() + 1,
                      'q[year_gteq]': new Date().getFullYear(),
                      'q[report_templates_id_null]': true
                    }
                  }}
                  touch={touch}
                  validate={[required]}
                />
              </div>
              <div className='field title-field'>
                <label htmlFor='title'>Título</label>
                <Field
                  autoComplete='off'
                  component={MaterialInput}
                  id='title'
                  name='title'
                  placeholder='Digite um título para o seu diário'
                  type='text'
                  validate={[required, validReportTitle]}
                />
              </div>
            </div>
            <div
              className={classes.classroomList}
              style={!isEdit ? { display: 'none' } : {}}
            >
              <strong>Turma:</strong>
              <span>{initialValues?.classroom?.label}</span>
            </div>
            <div
              className={classes.buttons}
              style={{ justifyContent: 'space-between' }}
            >
              <Button
                className={classes.exitBtn}
                onClick={() => handleClickButton('history')}
              >
                {'Sair'}
              </Button>
              <Button
                className={classes.continueBtn}
                onClick={handleNext}
                disabled={handleStepper(stepIndex)}
              >
                {'Avançar'}
              </Button>
            </div>
          </div>
        )
      case 2:
        return (
          <>
            <div>
              {tags.items.length > 0 &&
                // filter only tags that are for frequency
                tags.items
                  .filter(tagItem =>
                    attendancesTags
                      .filter(item => item.tag_order !== 2)
                      .some(tagAtte => tagItem.id === tagAtte.id)
                  )
                  .map((tag, index) => {
                    const showMore = accordionForEachTag.find(
                      item => item.id === tag.id
                    )
                    return (
                      <>
                        <div
                          className={classes.accordion}
                          key={`tag[${tag.id}]`}
                        >
                          <div className={classes.accordionSumary}>
                            <Chip
                              checked={selected.has(tag.id)}
                              label={tag.name}
                              name={tag.id}
                              clickable
                              onClick={() => handleSelectionChanged(tag.id)}
                            />
                            <div onClick={() => handleExpande(tag.id)}>
                              {showMore?.show === true ||
                              selected.has(tag.id) ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </div>
                          </div>

                          <Accordion
                            expanded={
                              isExpanded(tag.id) || selected.has(tag.id)
                            }
                            onChange={() => {}}
                            style={{ marginTop: 0 }}
                          >
                            <AccordionSummary />
                            <AccordionDetails>
                              <div>
                                <p>
                                  <b>Descrição</b>: {tag.description}
                                </p>
                              </div>
                              {tag.tag_options?.length > 0 &&
                                tag.tag_options.map(option => {
                                  return (
                                    <div
                                      key={`option[${option.id}]`}
                                      className={classes.tagOptions}
                                    >
                                      <div>
                                        <ChipMaterial
                                          className={classes.tagChip}
                                          label={option.topic}
                                        />
                                        {option.possible_answers?.length > 0 &&
                                          option.possible_answers.map(
                                            answer => {
                                              return (
                                                <ChipMaterial
                                                  key={`answer[${answer}]`}
                                                  className={classes.tagChip}
                                                  label={answer}
                                                  disabled
                                                />
                                              )
                                            }
                                          )}
                                      </div>
                                    </div>
                                  )
                                })}
                              <FormControl
                                disabled={!selected.has(`${tag.id}`)}
                                className={classes.requiredField}
                                component='fieldset'
                              >
                                <Fragment>
                                  <span
                                    style={{ marginRight: 10, fontWeight: 700 }}
                                  >
                                    Ordem:
                                  </span>
                                  <InputNumber
                                    disabled={
                                      !selected.has(`${tag.id}`) ||
                                      (selectingFrequencyTag &&
                                        selected.has(`${tag.id}`))
                                    }
                                    value={
                                      selectingFrequencyTag &&
                                      selected.has(`${tag.id}`)
                                        ? 1
                                        : !selected.has(`${tag.id}`)
                                          ? 0
                                          : inputValues?.[index]?.order
                                    }
                                    min={1}
                                  />
                                </Fragment>
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </>
                    )
                  })}
            </div>
            <div
              className={classes.buttons}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Button
                className={classes.exitBtn}
                onClick={() => handleClickButton('history')}
              >
                {'Sair'}
              </Button>
              <div className={classes.buttons}>
                <Button
                  variant='outlined'
                  className={classes.backBtn}
                  onClick={handleBack}
                >
                  {'Voltar'}
                </Button>
                <Button
                  className={classes.continueBtn}
                  onClick={handleNext}
                  disabled={handleStepper(stepIndex)}
                >
                  {'Avançar'}
                </Button>
              </div>
            </div>
          </>
        )
      case 3:
        return (
          <>
            <div>
              {tags.items.length > 0 &&
                tags.items
                  .filter(
                    tagItem =>
                      !attendancesTags.some(
                        tagAtte => tagItem.id === tagAtte.id
                      )
                  )
                  .map((tag, index) => {
                    const showMore = accordionForEachTag.find(
                      item => item.id === tag.id
                    )
                    return (
                      <>
                        <div
                          className={classes.accordion}
                          key={`tag[${tag.id}]`}
                        >
                          <div className={classes.accordionSumary}>
                            <Chip
                              checked={selected.has(tag.id)}
                              label={tag.name}
                              name={tag.id}
                              clickable
                              onClick={() => handleSelectionChanged(tag.id)}
                            />
                            <div onClick={() => handleExpande(tag.id)}>
                              {showMore?.show === true ||
                              selected.has(tag.id) ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </div>
                          </div>

                          <Accordion
                            expanded={
                              isExpanded(tag.id) || selected.has(tag.id)
                            }
                            onChange={() => {}}
                            style={{ marginTop: 0 }}
                          >
                            <AccordionSummary />
                            <AccordionDetails>
                              <div>
                                <p>
                                  <b>Descrição</b>: {tag.description}
                                </p>
                              </div>
                              {tag.tag_options?.length > 0 &&
                                tag.tag_options.map(option => {
                                  return (
                                    <div
                                      key={`option[${option.id}]`}
                                      className={classes.tagOptions}
                                    >
                                      <div>
                                        <ChipMaterial
                                          className={classes.tagChip}
                                          label={option.topic}
                                        />
                                        {option.possible_answers?.length > 0 &&
                                          option.possible_answers.map(
                                            answer => {
                                              return (
                                                <ChipMaterial
                                                  key={`answer[${answer}]`}
                                                  className={classes.tagChip}
                                                  label={answer}
                                                  disabled
                                                />
                                              )
                                            }
                                          )}
                                      </div>
                                    </div>
                                  )
                                })}
                              <FormControl
                                disabled={!selected.has(`${tag.id}`)}
                                className={classes.requiredField}
                                component='fieldset'
                              >
                                <Fragment>
                                  <span
                                    style={{ marginRight: 10, fontWeight: 700 }}
                                  >
                                    Defina a ordem
                                  </span>
                                  <InputNumber
                                    disabled={!selected.has(`${tag.id}`)}
                                    value={
                                      !selected.has(`${tag.id}`)
                                        ? 0
                                        : inputValues?.[index]?.order
                                    }
                                    min={2}
                                    max={selected.size}
                                    onChange={newValue =>
                                      setInputValues(prevValues => {
                                        const newValues = [
                                          ...(prevValues || [])
                                        ]
                                        newValues[index] = {
                                          ...prevValues?.[index],
                                          order: newValue
                                        }
                                        change(
                                          `tagOrder_${tag.id}.order`,
                                          newValue
                                        )
                                        return newValues
                                      })
                                    }
                                  />
                                </Fragment>
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </>
                    )
                  })}
            </div>
            <div
              className={classes.buttons}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Button
                className={classes.exitBtn}
                type='button'
                onClick={() => handleClickButton('history')}
              >
                {'Sair'}
              </Button>
              <div className={classes.buttons}>
                <Button
                  variant='outlined'
                  className={classes.backBtn}
                  type='button'
                  onClick={handleBack}
                >
                  {'Voltar'}
                </Button>
                <button className='defaultBtn' type='submit'>
                  {isEdit ? 'Editar' : 'Criar'}
                </button>
              </div>
            </div>
          </>
        )
      default:
        return ''
    }
  }

  return (
    <div className={classes.root}>
      <Stepper
        styles={{ padding: '0px' }}
        activeStep={activeStep}
        steps={getSteps}
        totalSteps={3}
      />
      {!isEdit &&
        classroomsSelecteds.length > 0 &&
        activeStep !== 1 && (
          <div
            style={{ marginBottom: '3rem' }}
            className={classes.classroomList}
          >
            <strong>Turma(s):</strong>
            {classroomsSelecteds.map(classroom => {
              return <span key={classroom.value}>{classroom.label}</span>
            })}
          </div>
        )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        {getStepContent(activeStep)}
      </Form>
    </div>
  )
}

const mapStateToProps = state => {
  const { auth, school, tags, reports, pagination } = state
  const selector = formValueSelector('createReportForm')
  const current = reports.currentTemplate
  const initValues = {
    ...current,
    classroom:
      current?.classroom && current?.classroom[0]
        ? { label: current?.classroom[0].name, value: current?.classroom[0].id }
        : null
  }
  return {
    initialValues: initValues || {},
    fieldValues: getFormValues('createReportForm')(state),
    userId: auth.user?.data?.id,
    userOccupations: auth.currentOccupation,
    school:
      selector(state, 'school_id') ||
      (school.currentSchool && school.currentSchool.school_id),
    tags: tags,
    report: reports.currentTemplate,
    currentPagination: pagination
  }
}

const mapDispatchToProps = dispatch => ({
  getTags: data => dispatch(getTags(data)),
  getReportTemplateById: data => dispatch(getReportTemplateById(data)),
  backToThePage: data => dispatch(backToThePage(data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'createReportForm',
    enableReinitialize: true,
    touchOnChange: true,
    touchOnBlur: true,
    onSubmit: (values, dispatch, props) => {
      const previousQuestions = props.initialValues.questions?.map(quest => {
        return {
          tag_id: quest.tag_id.toString(),
          id: quest.id
        }
      })
      let allQuestions = [...values.selectedFields]
      previousQuestions ? allQuestions.push(...previousQuestions) : undefined
      const newValues = {
        title: values.title,
        year: new Date().getFullYear(),
        user_id: props.userId,
        questions_attributes: allQuestions.map(field => {
          const valueTagId = field.tag_id ? field.tag_id : field
          return {
            id: field.id ? field.id : null,
            _destroy: !!field.id,
            tag_id: valueTagId,
            required:
              values.requiredFields.hasOwnProperty(valueTagId) &&
              values.requiredFields[valueTagId],
            question_order: values[`tagOrder_${valueTagId}`]?.order || null
          }
        })
      }
      if (props.isEdit) {
        dispatch(
          editReportTemplate({
            id: props.reportTemplateId,
            data: newValues
          })
        )
      } else {
        values.classroom_id.forEach(classroom => {
          dispatch(
            createReports({
              ...newValues,
              classroom_id: classroom.classroom_id
            })
          )
        })
      }
    }
  })(CreateReportForm)
)
