import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Select, MenuItem } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { shiftOptions } from '../../utils/constants'

const customIcon = props => {
  return <ArrowDropDownIcon {...props} />
}
const useStyles = makeStyles(theme => ({
  shift: {
    width: '100%',
    borderRadius: '8px !important',
    fontFamily: 'Source Sans Pro',
    fontSize: '16px',
    border: `1px solid  rgb(173, 184, 204)`,
    outline: 'none !important',
    padding: '8px !important',
    '& svg': {
      marginRight: '5px',
      color: `${theme.palette.primary.dark}`
    }
  },
  hover: {
    '& div:first-child:hover': {
      borderColor: `${theme.palette.primary.light}`
    }
  }
}))

const ShiftSelect = props => {
  const { input } = props
  const classes = useStyles()
  return (
    <div className={classes.hover}>
      <Select
        displayEmpty
        className={classes.shift}
        disableUnderline
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
        IconComponent={customIcon}
        {...input}
      >
        {shiftOptions
          .filter(
            item => (props.isForTeacher ? item.value !== undefined : item)
          )
          .map(shift => (
            <MenuItem
              key={shift.value}
              value={shift.value}
              disabled={shift.disabled}
            >
              {shift.label}
            </MenuItem>
          ))}
      </Select>
    </div>
  )
}

export default ShiftSelect
