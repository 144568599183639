import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { getCovers, uploadCovers } from '../store/covers/actions'
import SettingsCoversView from '../components/view/SettingsCoversView'

const mapStateToProps = ({ covers }) => {
  return {
    covers: covers.items,
    pagination: covers.pagination,
    isFetching: covers.isFetching,
    isSuccess: covers.isSuccess
  }
}

const mapDispatchToProps = {
  getCovers,
  uploadCovers
}

const SettingsCoversPage = ({
  isFetching,
  isSuccess,
  getCovers,
  uploadCovers,
  pagination,
  covers
}) => {
  useEffect(() => {
    getCovers({})
  }, [])

  return (
    <SettingsCoversView
      getCovers={getCovers}
      uploadCovers={uploadCovers}
      isFetching={isFetching}
      isSuccess={isSuccess}
      pagination={pagination}
      covers={covers}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsCoversPage)
