export const jsonToFormdata = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach(key => {
      jsonToFormdata(
        formData,
        data[key],
        parentKey
          ? !isNaN(Number(key)) ? `[${parentKey}][]` : `${parentKey}[${key}]`
          : key
      )
    })
  } else {
    const value = data === null || data === undefined ? '' : data

    if (value !== '') formData.append(`data[attributes]${parentKey}`, value)
  }
}
