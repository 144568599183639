import { all, call, takeLatest, put } from 'redux-saga/effects'

import * as actions from './actions'
import * as services from './services'

export function * fetchSkills ({ payload }) {
  try {
    const response = yield call(services.fetchSkills, payload)
    yield put(actions.getSkillsSuccessful(response.data))
  } catch (error) {
    yield put(actions.getSkillsFailure(error))
  }
}

export function * watchFetchSkills () {
  yield takeLatest(actions.getSkills, fetchSkills)
}

export default function * skillsSagas () {
  yield all([watchFetchSkills()])
}
