import { all, call, takeLatest, put } from 'redux-saga/effects'
import { roles } from '../../utils/constants'

import { showAlertMessage } from '../alert/actions'
import configureStore from '../configureStore'
import {
  getSchoolContentCreatorsById,
  getSchoolAdministratorById,
  getSchoolTeacherById,
  clearCurrentSchool,
  getSchoolCoordinatorById
} from '../school/actions'
import * as actions from './actions'
import * as services from './services'

function * procedureToGetSchools ({ currentOccupation, id }) {
  try {
    yield put(clearCurrentSchool())
    yield currentOccupation.includes(roles.CONTENT_CREATOR) &&
      put(getSchoolContentCreatorsById(id))
    yield currentOccupation.includes(roles.SCHOOL_ADMIN) &&
      put(getSchoolAdministratorById({ id: id, isLogin: true }))
    yield currentOccupation.includes(roles.TEACHER) &&
      put(getSchoolTeacherById({ id: id, isLogin: true }))
    yield currentOccupation.includes(roles.COORDINATOR) &&
      put(getSchoolCoordinatorById({ id: id, isLogin: true }))
  } catch (err) {
    console.error(err)
    throw err
  }
}

export function * signIn ({ payload }) {
  const { history, ...rest } = payload
  try {
    const userData = yield call(services.signIn, rest)
    const currentOccupation = [
      userData?.data?.data?.attributes?.occupations?.find(
        occup => occup !== 'parent'
      )
    ]
    yield put(
      actions.SIGN_IN_SUCCESSFUL({
        userData: userData,
        currentOccupation: currentOccupation.filter(item => item)
      })
    )
    yield call(procedureToGetSchools, {
      currentOccupation: currentOccupation,
      id: userData?.data?.data?.id
    })
    setTimeout(() => {
      history.push('/')
    }, 1000)
  } catch (error) {
    yield put(actions.SIGN_IN_FAILURE(error))
    yield put(
      showAlertMessage({
        message: 'Usuário e/ou senha incorretos.',
        severity: 'error'
      })
    )
    history.push('/')
  }
}

export function * signOut () {
  try {
    yield call(services.signOut)
    yield put({ type: 'app:RESET_STORE' })
    yield put(actions.LOGOUT_SUCCESSFUL())
  } catch (error) {
    console.error(error)
    yield put({ type: 'app:RESET_STORE' })
    yield put(actions.LOGOUT_FAILURE())
  } finally {
    const { persistor } = configureStore()
    persistor.pause()
    persistor.flush().then(() => {
      return persistor.purge()
    })
  }
}

export function * switchUserRequest ({ payload }) {
  try {
    yield call(procedureToGetSchools, {
      id: payload.id,
      currentOccupation: [payload.occupation]
    })
    yield put(actions.switchUserSuccessful([payload.occupation]))
    setTimeout(() => {
      payload.history.push('/')
    }, 1000)
  } catch (error) {
    console.error(error)
  }
}

// Watchers
export function * watchSignIn () {
  yield takeLatest(actions.SIGN_IN, signIn)
}

export function * watchSignout () {
  yield takeLatest(actions.LOGOUT, signOut)
}

export function * watchSwitchOccupation () {
  yield takeLatest(actions.switchUser, switchUserRequest)
}

export default function * authSagas () {
  yield all([watchSignIn(), watchSignout(), watchSwitchOccupation()])
}
