import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Form, Field, change, formValueSelector } from 'redux-form'
import { useHistory } from 'react-router-dom'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import Tooltip from '@material-ui/core/Tooltip'

import StyledSVG from '../shared/StyledSVG'
import videoSVG from '../../assets/icons/video.svg'
import audioSVG from '../../assets/icons/audio.svg'
import imgSVG from '../../assets/icons/img.svg'

import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { Backdrop } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Radios from '../shared/RadioButton'
// import MaterialInput from '../shared/MaterialInput'
import AsyncSelect from '../shared/AsyncSelect'
import Label from '../label/Label'
import Button from '../button/Button'
import SimpleEditorInput from '../shared/SimpleEditorInput'
import DropzoneInput from '../shared/DropzoneInput'
import Modal from '../modal/Modal'
import StudentsAsyncSelect from '../select/StudentsAsyncSelect'
import { required } from '../../utils/formUtils'
import Input from '../shared/Input'
import SendMsg from '../../assets/icons/send-msg.svg'
import { showAlertMessage } from '../../store/alert/actions'
import jsonApi from '../../utils/jsonApi'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    '& .tox-tinymce': {
      minHeight: 150
    },
    '& section': {
      textAlign: 'center',
      '& div:first-child': {
        maxWidth: 'inherit !important'
      }
    },
    '& #grade_id, #classrooms, #names ': {
      minWidth: 180
    },
    '& .select__multi-value__label': {
      color: '#FFF'
    },
    '& .select__value-container > .select__multi-value': {
      background: `${theme.palette.primary.main} !important`,
      borderRadius: '16px !important',
      padding: '0 8px',
      '& svg': {
        border: '1px solid #FFF',
        borderRadius: '50%'
      }
    }
  },
  paper: {
    padding: theme.spacing(2),
    margin: 0,
    borderRadius: '8px',
    lineHeight: '20.11px !important',
    fontSize: 16,
    minWidth: 300,
    [theme.breakpoints.up('426')]: {
      paddingLeft: '1.5rem'
    }
  },
  container: {
    [theme.breakpoints.up('426')]: {
      padding: '1rem'
    },
    '& h3': {
      color: '#555555',
      marginTop: '2.5rem'
    }
  },
  destinations: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: 20,
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '250px 360px 1fr'
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: '1fr 430px 1fr'
    }
  },
  bottomButtons: {
    gap: 10,
    marginTop: '15px',
    display: 'flex',
    [theme.breakpoints.up('426')]: {
      marginBottom: '50px'
    },
    justifyContent: 'flex-end',
    '& button': {
      marginRight: 0,
      width: 149,
      [theme.breakpoints.down('426')]: {
        width: '90%'
      }
    },
    [theme.breakpoints.down('850')]: {
      justifyContent: 'flex-start'
    },
    [theme.breakpoints.down('426')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center'
    }
  },
  btnStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '2rem auto',
    [theme.breakpoints.down('890')]: {
      justifyContent: 'center'
    }
  },
  destinationsUsers: {
    '& span': {
      backgroundColor: '#F6F7F8',
      borderRadius: 32,
      display: 'inline-flex',
      margin: '5px 10px',
      padding: '5px 10px',
      '& svg': {
        fontSize: 16,
        margin: '0 0 3px 6px',
        verticalAlign: 'middle'
      }
    }
  },
  msgTitle: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'baseline',
      '& label': {
        marginRight: 10,
        '& + div': {
          minWidth: 500,
          width: '100%'
        }
      }
    }
  },
  link: {
    backgroundColor: 'rgba(218, 218, 218, 0.2)',
    borderRadius: 8,
    display: 'flex',
    gap: 8,
    padding: '5px 10px',
    width: 'fit-content',
    '& a': {
      color: theme.palette.text.secondary,
      textDecoration: 'none'
    }
  },
  destinationType: {
    '& .MuiToggleButtonGroup-root': {
      justifyContent: 'flex-start !important',
      gap: 20
    },
    '& div div div': {
      '& button': {
        color: '#ADB8CC',
        border: '0.7px solid #ADB8CC !important',
        height: 27,
        padding: '0 10px',
        borderRadius: 8,
        width: 'fit-content',
        '&[aria-pressed="true"]': {
          backgroundColor: theme.palette.info.main,
          color: '#FFF',
          border: `0.7px solid ${theme.palette.info.main}`,
          '&:hover': {
            backgroundColor: theme.palette.info.main,
            border: `0.7px solid ${theme.palette.info.main}`
          }
        },
        '&:hover': {
          backgroundColor: theme.palette.info.main,
          border: `0.7px solid ${theme.palette.info.main}`,
          color: '#FFF'
        },
        '& span': {
          fontWeight: 500
        }
      }
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  archives: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: 20,
    marginBottom: '1rem',
    '& div': {
      display: 'inline-flex',
      justifyContent: 'end',
      '& svg': {
        color: theme.palette.danger.main,
        cursor: 'pointer',
        position: 'absolute',
        margin: '-14px 14px'
      }
    },
    '& img': {
      maxWidth: 300,
      maxHeight: 194
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-start',

    '& button': {
      backgroundColor: '#F6F7F8',
      border: 'none',
      borderRadius: '50% !important',
      marginRight: 16,
      padding: 7
    },
    '& button.Mui-selected': {
      backgroundColor: '#F6F7F8'
    },
    '& > div.MuiToggleButtonGroup-root': {
      marginBottom: '0px !important'
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  files: {
    margin: '-24px 24px',
    color: 'gray !important',
    [theme.breakpoints.down('426')]: {
      margin: '-5px 10px'
    }
  },
  error: {
    color: theme.palette.danger.main,
    fontSize: '0.8rem'
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  cancelBtn: {
    backgroundColor: '#EF7C8F',
    width: 169,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  submitBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  continueBtn: {
    backgroundColor: '#386093',
    color: '#FFFF',
    width: 146,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

// segment id of students that have account
const SEGMENT_ID_WITH_ACCOUNT = 3

const SendMessageForm = ({
  isAdmin,
  school,
  touch,
  gradeValue,
  classroomValue,
  nameValue,
  handleSubmit,
  change,
  isSuccess,
  cleanSuccess,
  alert,
  filesImageValue,
  filesAudioValue,
  filesVideoValue,
  destinationTypeValue,
  selectedSchool,
  submitting,
  valid
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [destinations, setDestinations] = React.useState({
    classrooms: [],
    users: []
  })
  const [fileToDelete, setFileToDelete] = React.useState(null)
  const [sendToStudent, setSendToStudent] = React.useState(null)
  const selectNameRef = React.useRef(null)
  const selectGradeRef = React.useRef(null)
  const selectClassroomRef = React.useRef(null)
  const [fileType, setFileType] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const handleToggle = () => {
    valid && setOpen(!open)
  }
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('426'))

  React.useEffect(
    () => {
      gradeValue &&
        jsonApi
          .find('grades', gradeValue)
          .then(res => {
            res.data &&
              setSendToStudent(res.data.segment_id === SEGMENT_ID_WITH_ACCOUNT)
            setDestinations({
              classrooms: [],
              users: []
            })
          })
          .catch(err => console.error(err))
    },
    [gradeValue]
  )

  const handleFileType = (event, newfileType) => {
    setFileType(newfileType)
  }

  const handleChange = (event, input) => {
    touch()
    let newValue
    if (Array.isArray(event)) {
      newValue = event?.map(v => v)
    } else {
      newValue = event ? { value: event.value, label: event.label } : ''
    }
    change(input.name, newValue)
  }

  const schoolParams = {
    'q[school_id_in]': (school && school.school_id) || selectedSchool?.id,
    'q[m]': 'or',
    'q[school_id_null]': true
  }

  const handleAddDestinataries = () => {
    classroomValue &&
      setDestinations(prevState => {
        const newState = {}
        if (classroomValue && (!nameValue || nameValue?.length === 0)) {
          const newValues = classroomValue.filter(classroom => {
            return !prevState.classrooms.includes(classroom)
          })
          Object.assign(newState, prevState, {
            classrooms: [...prevState?.classrooms, ...newValues]
          })
        } else {
          Object.assign(newState, prevState, {
            users: [nameValue]
          })
        }
        change('destinations', newState)
        return newState
      })
  }
  const handleDeleteDestination = (type, destination) => {
    const isClassroom = type === 'classroom'
    setDestinations(prevState => {
      const newValues = isClassroom
        ? {
            classrooms: prevState.classrooms.filter(
              dest => String(dest.value) !== String(destination.value)
            )
          }
        : {
            users: prevState.users.filter(
              dest => String(dest.value) !== String(destination.value)
            )
          }
      const newState = {
        ...prevState,
        ...newValues
      }
      change('destinations', newState)
      return newState
    })
  }

  const handleDeleteFile = file => {
    setFileToDelete(file)
    if (file.type.includes('image')) {
      const newFiles = filesImageValue.filter(image => image.name !== file.name)
      change('filesImage', newFiles)
    }
    if (file.type.includes('audio')) {
      const newFiles = filesAudioValue.filter(audio => audio.name !== file.name)
      change('filesAudio', newFiles)
    }
    if (file.type.includes('video')) {
      const newFiles = filesVideoValue.filter(video => video.name !== file.name)
      change('filesVideo', newFiles)
    }
  }

  const handleDestinationType = (event, newValue) => {
    change('destinationType', newValue)
  }

  return (
    <Form onSubmit={handleSubmit} className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12}>
            <Label>
              Enviar {selectedSchool?.id && ` - ${selectedSchool.name}`}
            </Label>
          </Grid>
          <Grid item xs={12}>
            <h3>Enviar para:</h3>
            <div className={classes.destinations}>
              <label htmlFor='grade_id'>
                <div style={{ marginBottom: '0.7rem' }}>Série</div>
                <Field
                  component={AsyncSelect}
                  id='grade_id'
                  name='grade_id'
                  placeholder='Selecione uma série'
                  handleAsyncChange={handleChange}
                  isSearchable={false}
                  selectRef={selectGradeRef}
                  cacheUniqs={0}
                  onInputChange={(value, action) => {
                    if (action.action === 'menu-close') {
                      selectNameRef.current.select.clearValue()
                      selectClassroomRef.current.select.clearValue()
                    }
                  }}
                  request={{
                    path: 'grade',
                    params: schoolParams
                  }}
                  touch={touch}
                />
              </label>
              <label htmlFor='classrooms'>
                <div style={{ marginBottom: '0.7rem' }}>Turma</div>
                <Field
                  component={AsyncSelect}
                  id='classrooms'
                  name='classrooms'
                  selectRef={selectClassroomRef}
                  placeholder='Selecione uma turma'
                  defaultOptions={false}
                  handleAsyncChange={handleChange}
                  cacheUniqs={gradeValue || 0}
                  searchParam='q[name_cont]'
                  disabled={!gradeValue}
                  touch={touch}
                  request={{
                    path: 'classroom',
                    params: {
                      'q[grade_id_in]': gradeValue?.value,
                      'q[school_id_eq]':
                        (school && school.school_id) || selectedSchool?.id,
                      'q[year_lteq]': new Date().getFullYear() + 1,
                      'q[year_gteq]': new Date().getFullYear()
                    }
                  }}
                  validate={[required]}
                />
              </label>
              <label htmlFor='names'>
                <Tooltip
                  title={!classroomValue ? 'Selecione uma turma primeiro' : ''}
                  aria-label='dica'
                >
                  <div style={{ marginBottom: '0.5rem' }}>Destinatário</div>
                </Tooltip>
                <Field
                  component={StudentsAsyncSelect}
                  id='names'
                  name='names'
                  defaultValue={nameValue}
                  selectRef={selectNameRef}
                  placeholder='Digite ou selecione aluno(a) / responsável'
                  handleAsyncChange={handleChange}
                  cacheUniqs={classroomValue || 0}
                  defaultOptions={false}
                  isSearchable={false}
                  disabled={!classroomValue}
                  request={{
                    path: 'classroom',
                    params: {
                      'q[id_in]': classroomValue?.value,
                      'q[grade_id_in]': gradeValue?.value,
                      include: 'children.parents'
                    }
                  }}
                  touch={touch}
                />
              </label>
            </div>
            <div className={classes.btnStyle}>
              <Tooltip
                title={!nameValue ? 'Selecione um destinatário.' : ''}
                aria-label='dica'
              >
                <Button
                  color='secondary'
                  id='all_classroom'
                  type='button'
                  style={{ borderRadius: 8 }}
                  className={classes.applyBtn}
                  onClick={() => {
                    !nameValue ? '' : handleAddDestinataries()
                  }}
                >
                  Aplicar
                </Button>
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.destinationsUsers}>
            <p>
              <label htmlFor='destinations'>Destinatários:</label>
              {destinations?.classrooms.length > 0 &&
                destinations.classrooms.map(destination => (
                  <span key={destination?.value}>
                    {destination?.label}{' '}
                    <HighlightOffRoundedIcon
                      onClick={() =>
                        handleDeleteDestination('classroom', destination)
                      }
                    />
                  </span>
                ))}
              {destinations?.users.length > 0 &&
                destinations.users.map(destination => (
                  <span key={destination?.value}>
                    {destination?.label}{' '}
                    <HighlightOffRoundedIcon
                      onClick={() =>
                        handleDeleteDestination('user', destination)
                      }
                    />
                  </span>
                ))}
            </p>
            <Field
              component={Input}
              name='destinations'
              hidden
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12}>
            {destinations.users?.length === 0 &&
              destinations.classrooms?.length > 0 && (
                <div className={classes.destinationType}>
                  <div style={{ marginBottom: '0.7rem' }}>
                    Tipo de destinatário
                  </div>
                  <Field
                    component={Radios}
                    name='destinationType'
                    radios={
                      sendToStudent
                        ? [
                            {
                              value: 'classroom_parents_destination_id',
                              label: 'Responsáveis'
                            },
                            {
                              value: 'classroom_children_destination_id',
                              label: 'Estudantes'
                            }
                          ]
                        : [
                            {
                              value: 'classroom_parents_destination_id',
                              label: 'Responsáveis'
                            }
                          ]
                    }
                    handleRadioChange={handleDestinationType}
                    value={destinationTypeValue}
                  />
                </div>
              )}
            {destinations?.users.length === 0 &&
              destinations?.classrooms.length > 0 &&
              !destinationTypeValue && (
                <div className={classes.error}>
                  Escolha o tipo de destinatário *
                </div>
              )}
          </Grid>
          {/* <Grid item xs={12} className={classes.msgTitle}>
            <label htmlFor={'title'}>Adicione um título a mensagem</label>
            <Field
              component={MaterialInput}
              name={'title'}
              placeholder='Digite o título da mensagem'
              type='text'
              autoComplete='off'
            />
          </Grid> */}
          <Grid item xs={12}>
            <Field
              name='content'
              component={SimpleEditorInput}
              validate={[required]}
            />

            {(!!filesImageValue?.length ||
              !!filesAudioValue?.length ||
              !!filesVideoValue?.length) && (
              <>
                <p>Arquivos</p>
                <div className={classes.archives}>
                  {filesImageValue &&
                    filesImageValue.map((file, index) => {
                      let fileName = file.name
                      const extencion = fileName.split('.')[
                        fileName.split('.').length - 1
                      ]
                      fileName.length > 20 &&
                        !matches &&
                        (fileName = `${fileName.slice(0, 12)}...${extencion}`)
                      return matches ? (
                        <div key={fileName}>
                          <img src={file.preview} alt={fileName} />
                          <HighlightOffRoundedIcon
                            onClick={() => handleDeleteFile(file)}
                          />
                        </div>
                      ) : (
                        <div key={fileName}>
                          <p className={classes.link}>
                            <img
                              src={imgSVG}
                              alt='image'
                              width='21'
                              height='21'
                            />
                            <a
                              target='_blank'
                              href={file.preview}
                              rel='noreferrer'
                            >
                              {fileName}
                            </a>
                          </p>
                          <HighlightOffIcon
                            className={classes.files}
                            onClick={() => handleDeleteFile(file)}
                          />
                        </div>
                      )
                    })}
                  {filesAudioValue &&
                    filesAudioValue.map(file => {
                      let fileName = file.name
                      const extencion = fileName.split('.')[
                        fileName.split('.').length - 1
                      ]
                      fileName.length > 20 &&
                        !matches &&
                        (fileName = `${fileName.slice(0, 12)}...${extencion}`)
                      return matches ? (
                        <div
                          key={file.name}
                          onClick={() => handleDeleteFile(file)}
                        >
                          <audio controls>
                            <source src={file.preview} type={file.type} />
                            O seu navegador não suporta o elemento{' '}
                            <code>audio</code>.
                          </audio>
                          <HighlightOffRoundedIcon
                            onClick={() => handleDeleteFile(file)}
                          />
                        </div>
                      ) : (
                        <div key={fileName}>
                          <p className={classes.link}>
                            <img
                              src={audioSVG}
                              alt='video'
                              width='21'
                              height='21'
                            />
                            <a
                              target='_blank'
                              href={file.preview}
                              rel='noreferrer'
                            >
                              {fileName}
                            </a>
                          </p>
                          <HighlightOffIcon
                            className={classes.files}
                            onClick={() => handleDeleteFile(file)}
                          />
                        </div>
                      )
                    })}
                  {filesVideoValue &&
                    filesVideoValue.map(file => {
                      let fileName = file.name
                      const extencion = fileName.split('.')[
                        fileName.split('.').length - 1
                      ]
                      fileName.length > 20 &&
                        !matches &&
                        (fileName = `${fileName.slice(0, 12)}...${extencion}`)
                      return matches ? (
                        <div
                          key={file.name}
                          onClick={() => handleDeleteFile(file)}
                        >
                          <video width='320' height='240' controls>
                            <source src={file.preview} type={file.type} />
                            O seu navegador não suporta o elemento{' '}
                            <code>audio</code>.
                          </video>
                          <HighlightOffRoundedIcon
                            onClick={() => handleDeleteFile(file)}
                          />
                        </div>
                      ) : (
                        <div key={fileName}>
                          <p className={classes.link}>
                            <img
                              src={videoSVG}
                              alt='video'
                              width='21'
                              height='21'
                            />
                            <a
                              target='_blank'
                              href={file.preview}
                              rel='noreferrer'
                            >
                              {fileName}
                            </a>
                          </p>
                          <HighlightOffIcon
                            className={classes.files}
                            onClick={() => handleDeleteFile(file)}
                          />
                        </div>
                      )
                    })}
                </div>
              </>
            )}
            <div className={classes.buttons}>
              <p>Adicionar arquivos:</p>
              <ToggleButtonGroup
                value={fileType}
                exclusive
                onChange={handleFileType}
                aria-label='file types'
                style={{ marginBottom: '1rem' }}
              >
                <Tooltip title='Adicionar foto' aria-label='foto'>
                  <ToggleButton
                    value='image'
                    aria-label='image'
                    color='secondary'
                  >
                    <StyledSVG width={21} height={21} src={imgSVG} />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title='Adicionar vídeo' aria-label='video'>
                  <ToggleButton value='video' aria-label='video'>
                    <StyledSVG width={21} height={21} src={videoSVG} />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title='Adicionar áudio' aria-label='audio'>
                  <ToggleButton value='audio' aria-label='audio'>
                    <StyledSVG width={21} height={21} src={audioSVG} />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </div>
            {fileType === 'image' && (
              <Field
                accept='image/jpg, image/jpeg, image/png, image/gif'
                component={DropzoneInput}
                maxFiles={100}
                maxSize={2097152}
                name='filesImage'
                type='file'
                fileToDelete={fileToDelete}
                withoutPreview
              />
            )}
            {fileType === 'video' && (
              <Field
                accept='video/mp4, video/mov, video/quicktime, video/3gpp, video/3gpp2'
                component={DropzoneInput}
                maxFiles={50}
                maxSize={31457280}
                name='filesVideo'
                type='file'
                fileToDelete={fileToDelete}
                withoutPreview
              />
            )}
            {fileType === 'audio' && (
              <Field
                accept='audio/mpeg, audio/mpeg3, audio/mp4, audio/m4a, audio/x-m4a, audio/3gpp, audio/3gpp2'
                component={DropzoneInput}
                maxFiles={50}
                maxSize={10485760}
                name='filesAudio'
                type='file'
                fileToDelete={fileToDelete}
                withoutPreview
              />
            )}
            <div className={classes.bottomButtons}>
              <Button
                onClick={() => {
                  cleanSuccess()
                  history.goBack()
                }}
                className={classes.cancelBtn}
              >
                Cancelar
              </Button>

              <Button
                type='submit'
                disabled={
                  submitting ||
                  !valid ||
                  (destinations?.users.length === 0 &&
                    destinations?.classrooms.length === 0) ||
                  (destinations?.classrooms.length > 0 && !destinationTypeValue)
                }
                onclick={() => handleToggle()}
                className={classes.submitBtn}
              >
                Enviar
              </Button>
              <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color='inherit' />
              </Backdrop>
              {/* {submitting && <CircularProgress size={24} color='secondary' />} */}
            </div>
            <Modal open={isSuccess}>
              <img
                width={matches ? '400px' : '200px'}
                src={SendMsg}
                alt='icone de mensagem enviada'
              />
              <h3 id='transition-modal-title'>Mensagem enviada com sucesso!</h3>
              <Button
                className={classes.continueBtn}
                onClick={() => {
                  cleanSuccess()
                  history.goBack()
                }}
              >
                Continuar
              </Button>
            </Modal>
          </Grid>
        </Grid>
      </Paper>
    </Form>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('sendMessageForm')
  return {
    gradeValue: selector(state, 'grade_id'),
    classroomValue: selector(state, 'classrooms'),
    nameValue: selector(state, 'names'),
    filesImageValue: selector(state, 'filesImage'),
    filesAudioValue: selector(state, 'filesAudio'),
    filesVideoValue: selector(state, 'filesVideo'),
    destinationTypeValue: selector(state, 'destinationType')
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('sendMessageForm', field, data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'sendMessageForm',
    validateOnBlur: true,
    touchOnChange: true,
    touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {
      const files = [
        values.filesAudio,
        values.filesImage,
        values.filesVideo
      ].flat()

      // para destinatários -> user_id: <id do usuario>
      // para turma toda (professores, alunos e pais) -> classroom_id: <id da tuma>
      // pais da turma -> classroom_parents_destination_id: <id da turma>
      // professores da turma -> classroom_teachers_destination_id: <id da turma>
      // estudantes da turma -> classroom_children_destination_id: <id da turma>

      const valuesToData = {
        school_id:
          (props.school && props.school.school_id) || props.selectedSchool?.id,
        classroom_id:
          values.classrooms.length === 1 ? values.classrooms[0].value : null,
        messages_attributes: [
          {
            title: values.title,
            content: values.content,
            files_attributes: files.filter(Boolean).map(file => ({ file })),
            message_destinations_attributes:
              values.destinations.users &&
              !!values.destinations.users.length > 0
                ? values.destinations.users.map(destination => ({
                    user_id: destination.value
                  }))
                : values.destinations.classrooms.map(classroom => ({
                    [values.destinationType]: classroom.value
                  }))
          }
        ]
      }
      props.createCommunication(valuesToData)
    }
  })(SendMessageForm)
)
