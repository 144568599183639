import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import { isEmpty, max } from 'lodash'
import { connect } from 'react-redux'
import {
  Field,
  Form,
  // formValueSelector,
  reduxForm,
  getFormValues
} from 'redux-form'
// import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '../button/Button'
// import moment from 'moment'
import MaterialInput from '../shared/MaterialInput'
import SimpleEditorInput from '../shared/SimpleEditorInput'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
// import DropzoneInput from '../shared/DropzoneInput'
// import DatePickerInput, { TimePickerInput } from '../shared/DatePickerInput'
import ImagesGrid from '../shared/ImagesGrid'
import AsyncSelect from '../shared/AsyncSelect'
// import destinationsSVG from '../../assets/icons/sended-icon.svg'
// import docSVG from '../../assets/icons/doc.svg'
// import videoSVG from '../../assets/icons/video.svg'
// import audioSVG from '../../assets/icons/audio.svg'
// import linkSVG from '../../assets/icons/material-link.svg'
// import crossAddSVG from '../../assets/icons/cross-add-icon.svg'
// import HighlightOffIcon from '@material-ui/icons/HighlightOff'
// import imgSVG from '../../assets/icons/img.svg'
import StyledSVG from '../shared/StyledSVG'
import modulePuzzleSVG from '../../assets/icons/module-puzzle.svg'
// import { createMaterials, editMaterial } from '../../store/materials/actions'
// import { required } from '../../utils/formUtils'
import { roles } from '../../utils/constants'
import { createModuleRSPA } from '../../store/modules/actions'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  root: {
    width: '98%',
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    margin: 'auto',
    height: '95%',
    padding: '32px',
    marginBottom: '100px'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      columnGap: 12
    },
    '& path': {
      stroke: '#4D5E80'
    },
    '& h1': {
      fontWeight: 700,
      color: '#4D5E80',
      fontSize: 32,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16,
      maxWidth: '25%'
    }
  },
  root__label: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12,
    '& p': {
      margin: 0,
      fontWeight: 700,
      fontSize: 16
    },
    '& span': {
      fontWeight: 500,
      fontSize: 14
    }
  },
  inputField: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    '& span': {
      fontWeight: 400
    }
  },
  firstLineField: {
    marginTop: 24,
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    [theme.breakpoints.down('830')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  title: {
    width: '70%'
  },
  grade: {
    width: '30%'
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
    '& label': {
      fontSize: 16,
      fontWeight: 600
    }
  },
  descriptionRootStyle: {
    padding: 0,
    marginBottom: 0,
    border: '1.5px solid #ADB8CC',
    '& .mce-edit-area': {
      minHeight: '120px'
    },
    '& .mce-container-body': {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    '& .mce-btn-group .mce-btn': {
      margin: 0,
      backgroundColor: 'transparent'
    },
    '& .mce-toolbar-grp': {
      width: 'fit-content',
      backgroundColor: '#F6F7F8',
      boxShadow: '3px 11px 14px 6px #6B67671A',
      margin: '0px 30px 30px 40px',
      height: 'fit-content',
      borderRadius: 8
    },
    '& .mce-btn': {
      '& button': {
        height: 50
      }
    },
    '& .mce-panel': {
      backgroundColor: 'unset'
    }
  },
  label: {
    marginBottom: 8,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px'
  },
  selectCover: {
    marginTop: 60,
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      marginBottom: 20,
      fontWeight: 600,
      fontSize: 16,
      color: 'rgba(85, 85, 85, 1)'
    }
  },
  bottomBtns: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    '& button': {
      width: 150
    }
  },
  cancelBtn: {
    width: 146,
    border: '2px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '2px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  sendBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const NewCreateModuleForm = ({
  isEdit,
  handleSubmit,
  userOccupations,
  change,
  touch,
  initialValues,
  valid,
  history,
  currentSchool,
  fieldValues,
  modules
}) => {
  const isAdmin =
    userOccupations?.includes(roles.VERSAR_ADMIN) ||
    userOccupations?.includes(roles.SCHOOL_ADMIN)
  const classes = useStyles()
  const [selectedCover, setSelectedCover] = React.useState(
    initialValues?.cover_id
  )
  const [open, setOpen] = React.useState(false)
  const selectGradeRef = React.useRef()

  const handleClickCover = id => {
    setSelectedCover(id)
    change('cover_id', id)
  }

  const handleChange = (event, input) => {
    touch(input.name)
    let newValue
    if (Array.isArray(event)) {
      newValue = event?.map(v => v)
    } else {
      newValue = event ? event.value : ''
    }
    change(input.name, newValue)
  }

  const handleToggle = () => {
    valid && setOpen(!open)
  }

  return (
    <div className={classes.root}>
      <Form onSubmit={handleSubmit}>
        <div className={classes.header}>
          <div className='title'>
            <StyledSVG src={modulePuzzleSVG} height={32} width={32} />
            <h1>Criar um novo módulo</h1>
          </div>
          <span>
            Escolha um nome e descrição claros para que sua organização seja
            eficaz.
          </span>
        </div>

        <div className={classes.root__label}>
          <p>Configurações inicias</p>
          <span>Dados do módulo</span>
        </div>

        <div className={classes.firstLineField}>
          <div className={`${classes.inputField} ${classes.title}`}>
            <label className={classes.label} htmlFor='title'>
              Título:
            </label>
            <Field
              component={MaterialInput}
              name='title'
              onKeyPress={e => {
                if (e.key === 'Enter' && isAdmin) {
                  e.preventDefault()
                }
              }}
              type='text'
              placeholder='Digite o título'
              autoComplete='off'
              // validate={!alreadyFilled(initialValues.title) && [required]}
            />
          </div>
          <div className={`${classes.inputField} ${classes.grade}`}>
            <label className={classes.label}>Série</label>
            <Field
              component={AsyncSelect}
              selectRef={selectGradeRef}
              rootStyle={classes.asyncSelectClass}
              // disabled={isAdmin && !teacherValue}
              // defaultValue={initialValues?.grade_id}
              id='grade_id'
              name='grade_id'
              placeholder='Digite ou selecione a série'
              handleAsyncChange={handleChange}
              searchParam='q[name_cont]'
              request={{
                path: 'grade',
                params: {
                  'q[school_id_null]': true
                }
              }}
              touch={touch}
              // validate={!isEdit && [required]}
            />
          </div>
        </div>

        <div className={classes.description}>
          <label className={classes.label} htmlFor='description'>
            Descrição:
          </label>
          <Field
            name='description'
            component={SimpleEditorInput}
            // defaultValue={
            //   fieldValues?.description || initialValues?.description
            // }
            addRootStyle={classes.descriptionRootStyle}
            // validate={!alreadyFilled(initialValues.description) && [required]}
          />
        </div>
        <div className={classes.selectCover}>
          <label htmlFor='cover_id'>Selecione a imagem de capa</label>
          <ImagesGrid
            perPage={5}
            handleClick={handleClickCover}
            selected={selectedCover}
          />
        </div>
        <div className={classes.bottomBtns}>
          <Button
            type='button'
            variant={'outlined'}
            className={classes.cancelBtn}
            onClick={() => history.push('/modules')}
          >
            Voltar
          </Button>
          <Button
            type='submit'
            onClick={handleToggle}
            className={classes.sendBtn}
            disabled={!valid}
          >
            {'Próximo'}
          </Button>
        </div>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color='secondary' />
        </Backdrop>
      </Form>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  // const selector = formValueSelector('newCreateModuleForm')
  return {
    // gradeValue: selector('grade_id'),
    currentSchool: state.school.currentSchool.school,
    fieldValues: getFormValues('newCreateModuleForm')(state),
    modules: state.modules
  }
}

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'newCreateModuleForm',
    enableReinitialize: true,
    onSubmit: async (values, dispatch, props) => {
      const data = {
        cover_id: values.cover_id,
        description: values.description,
        grade_id: values.grade_id,
        school_id: props.currentSchool.id,
        title: values.title
      }

      try {
        dispatch(createModuleRSPA.request({ data })).then(res => {
          props.history.push(`/newmodules/${res.id}/themes/create`)
        })
      } catch (err) {
        console.error(err)
      }
    }
  })(NewCreateModuleForm)
)
