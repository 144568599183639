import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import { showAlertMessage } from '../../store/alert/actions'
import heic2any from 'heic2any'
import uploadSVG from '../../assets/icons/upload-file.svg'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  placeholder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      height: '4rem',
      width: '4rem',
      backgroundColor: '#ADB8CC',
      color: '#FFF',
      borderRadius: '50%',
      padding: 10
    },
    '& p': {
      fontWeight: 500,
      '& span': {
        color: '#00ACDB'
      },
      textAlign: 'center',
      margin: 0,
      '&:nth-of-type(2)': {
        marginBottom: '0.5rem'
      },
      '&:nth-of-type(n+3)': {
        fontSize: '11px'
      }
    }
  },
  img: {
    width: 320,
    maxHeight: 240,
    height: 'fit-content',
    objectFit: 'contain'
  }
}))

const baseStyle = {
  alignItems: 'center',
  border: '2px dashed #ADB8CC',
  borderRadius: 8,
  color: '#ADB8CC',
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  padding: '20px',
  transition: 'border .3s ease-in-out',
  maxWidth: 730
}

const activeStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#E8505B'
}

function DropzoneInput ({
  input,
  accept,
  fileSize,
  imageWidth,
  imageHeight,
  maxFiles = 1,
  maxSize, // base 2 - byte
  withoutPreview,
  fileToDelete,
  fieldFilled = true,
  nameToFilter,
  dropzoneRootStyle,
  meta: { error, touched },
  onChangeInput
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [files, setFiles] = useState([])
  React.useEffect(
    () => {
      if (nameToFilter) {
        setFiles(prev =>
          prev.filter(
            obj => obj.name !== nameToFilter && obj.filename !== nameToFilter
          )
        )
      }
    },
    [nameToFilter]
  )
  const [uploadingHeic, setUploadingHeic] = useState(false)
  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    const processedFiles = await Promise.all(
      acceptedFiles.map(async file => {
        const arrayFromSplitName = file.name.split('.')
        const nameWithoutExtension = arrayFromSplitName
          .slice(0, arrayFromSplitName.length - 1)
          .join('-')
        const extension = file.name.split('.')[arrayFromSplitName.length - 1]
        const isHeic = extension.toLowerCase() === 'heic'
        if (!isHeic) {
          return Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        } else {
          setUploadingHeic(prev => true)
          const resultBlob = await heic2any({
            blob: file,
            toType: 'image/jpeg'
          }).catch(error => {
            console.error(error)
            alert('Erro ao adicionar imagem', 'error')
            return null
          })
          if (resultBlob) {
            setUploadingHeic(prev => false)
            const fileJpg = new File(
              [resultBlob],
              `${nameWithoutExtension}.jpeg`,
              {
                type: 'image/jpeg',
                lastModified: new Date().getTime()
              }
            )
            return Object.assign(fileJpg, {
              preview: URL.createObjectURL(fileJpg)
            })
          } else {
            return null
          }
        }
      })
    )
    if (
      acceptedFiles &&
      acceptedFiles.length > 0 &&
      processedFiles.length > 0
    ) {
      setFiles(prevState => {
        if (!fieldFilled) {
          dispatch(
            showAlertMessage({
              message: `Necessário selecionar série/turma para adicionar o(s) arquivo(s).`,
              severity: 'info'
            })
          )
          return prevState
        }
        if (prevState.length === maxFiles) {
          dispatch(
            showAlertMessage({
              message: `Quantidade de arquivos permitidos: ${maxFiles}`,
              severity: 'warning'
            })
          )
          return prevState
        }
        if (processedFiles.length !== acceptedFiles.length) {
          dispatch(
            showAlertMessage({
              message: `Não foi possível processar alguns arquivos.`,
              severity: 'warning'
            })
          )
        }
        const newFiles = prevState.concat(processedFiles)
        onChangeInput ? onChangeInput(newFiles) : input.onChange(newFiles)
        return newFiles
      })
    }
    if (rejectedFiles && rejectedFiles.length > 0) {
      let message
      rejectedFiles[0] &&
        rejectedFiles[0].errors.forEach(err => {
          message =
            err.code === 'file-invalid-type'
              ? `Algum dos arquivos podem não ter um tipo válido: ${accept}`
              : err.code === 'too-many-files'
                ? `Quantidade de arquivos permitidos: ${maxFiles}`
                : `Algum dos arquivos ultrapassa o limite de: ${maxSize /
                    (1024 * 1024)}MB`
        })
      dispatch(
        showAlertMessage({
          message: message,
          severity: 'warning'
        })
      )
    }
  }, [])

  useEffect(
    () => {
      if (fileToDelete) {
        const newFiles = [...files]
        newFiles.splice(newFiles.indexOf(fileToDelete), 1)
        setFiles(newFiles)
      }
    },
    [fileToDelete]
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    accept,
    minSize: 0,
    maxFiles,
    maxSize
  })
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const thumbs = files.map(file => {
    const isImage = file.type?.includes('image')
    const isVideo = file.type?.includes('video')
    const isAudio = file.type?.includes('audio')

    const preview = {
      image: (
        <img
          src={file.preview}
          alt={file.name}
          className={classes.img}
          // height={imageHeight}
        />
      ),
      video: (
        <video width='320' height='240' controls>
          <source src={file.preview} type={file.type} />
          O seu navegador não suporta o elemento <code>audio</code>.
        </video>
      ),
      audio: (
        <audio controls>
          <source src={file.preview} type={file.type} />
          O seu navegador não suporta o elemento <code>audio</code>.
        </audio>
      ),
      default: (
        <p>
          {file.name} - {(file.size / 1024).toFixed(2)}KB
        </p>
      )
    }
    return (
      <div key={file.name}>
        {(isImage && preview['image']) ||
          (isVideo && preview['video']) ||
          (isAudio && preview['audio']) ||
          preview['default']}
      </div>
    )
  })

  return (
    <section>
      <div className={dropzoneRootStyle} {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {thumbs && thumbs.length > 0 && !withoutPreview ? (
          thumbs
        ) : input.value && !withoutPreview ? (
          <img
            src={input.value}
            alt={input.name}
            width={imageWidth}
            height={imageHeight}
          />
        ) : uploadingHeic === false ? (
          <div className={classes.placeholder}>
            <img src={uploadSVG} />
            <div>
              <p>
                Clique para <span>adicionar</span> os seus
              </p>
              <p> arquivos ou arraste-os e solte aqui</p>
              <p>
                Capacidade máxima: {maxSize / (1024 * 1024)}
                {' MB'}
                {/* {(imageWidth || imageHeight) &&
                    ` - ${imageWidth} x ${imageHeight}`}{' '} */}
              </p>
              <p>{accept}</p>
            </div>
          </div>
        ) : (
          <CircularProgress color='secondary' size={21} />
        )}
      </div>
    </section>
  )
}

DropzoneInput.propTypes = {
  accept: PropTypes.string.isRequired,
  fileToDelete: PropTypes.any,
  imageHeight: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onDragStart: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.any
  }),
  fileSize: PropTypes.string,
  imageWidth: PropTypes.string,
  fieldFilled: PropTypes.bool,
  meta: PropTypes.object,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number, // base 2 - byte
  withoutPreview: PropTypes.bool,
  nameToFilter: PropTypes.any,
  dropzoneRootStyle: PropTypes.object,
  onChangeInput: PropTypes.func
}
export default DropzoneInput
