import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Label from '../components/label/Label'
import Form from '../components/form/CreateThemeForm'

import {
  createTheme,
  editTheme,
  getThemeById,
  clearCurrentTheme
} from '../store/themes/actions'

import { editModule } from '../store/modules/actions'

import { getSegments } from '../store/segments/actions'
import {
  getContents,
  editContent,
  getContentsByIds,
  clearCurrentContent
} from '../store/contents/actions'
import { CircularProgress } from '@material-ui/core'
import { roles } from '../utils/constants'

const mapDispatchToProps = {
  createTheme,
  getContents,
  editTheme,
  editContent,
  getThemeById,
  getContentsByIds,
  getSegments,
  clearCurrentTheme,
  clearCurrentContent,
  editModule
}

const mapStateToProps = ({
  auth,
  contents,
  modules,
  themes,
  school,
  segments
}) => {
  return {
    userOccupations: auth.currentOccupation,
    contents: contents,
    segments: segments,
    currentModule: modules.currentItem?.item,
    currentTheme: themes?.currentItem,
    currentContent: contents?.currentItem,
    userId: auth.user?.data?.id,
    school: school.currentSchool
  }
}

const CreateThemePage = ({
  createTheme,
  getSkills,
  getContents,
  contents,
  editTheme,
  currentModule,
  currentContent,
  currentTheme,
  editContent,
  userId,
  getThemeById,
  getContentsByIds,
  clearCurrentTheme,
  clearCurrentContent,
  getSegments,
  segments,
  school,
  editModule,
  match,
  userOccupations
}) => {
  const paramsId = match.params?.id
  const isAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)

  useEffect(() => {
    Promise.all([getSegments(), paramsId && getThemeById({ ids: paramsId })])
  }, [])

  return paramsId ? (
    <>
      <Label>Editar Tema</Label>
      {currentTheme.isFetching ? (
        <CircularProgress color='secondary' />
      ) : (
        <Form
          isEditing
          contents={contents}
          currentTheme={currentTheme}
          editTheme={editTheme}
          segments={segments}
          editContent={editContent}
          getContentsByIds={getContentsByIds}
          initialValues={
            currentTheme?.item && currentTheme?.item[0]?.attributes
          }
          school={school?.school}
          getThemeById={getThemeById}
          currentContent={currentContent}
          clearCurrentContent={clearCurrentContent}
          getContents={getContents}
          editModule={editModule}
          isAdmin={isAdmin}
        />
      )}
    </>
  ) : (
    <>
      <div
        style={{
          padding: '0 1rem'
        }}
      >
        <Label>Criar Tema</Label>
      </div>
      <Form
        contents={contents}
        currentModule={currentModule}
        currentTheme={currentTheme}
        createTheme={createTheme}
        currentContent={currentContent}
        getContents={getContents}
        editTheme={editTheme}
        editContent={editContent}
        school={school?.school}
        segments={segments}
        getThemeById={getThemeById}
        getContentsByIds={getContentsByIds}
        clearCurrentTheme={clearCurrentTheme}
        clearCurrentContent={clearCurrentContent}
        editModule={editModule}
        isAdmin={isAdmin}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateThemePage)
