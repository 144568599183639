import { createReducer } from '@reduxjs/toolkit'
import * as types from './types'

const initialState = {
  items: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  pagination: {
    total: 1,
    pageCount: 1
  }
}

const getCoversReducer = createReducer(initialState, {
  [types.GET_COVERS]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    isSuccess: false,
    errorMessage: ''
  }),
  [types.GET_COVERS_SUCCESSFUL]: (state, action) => ({
    ...state,
    pagination: {
      total: action.payload.meta.total_count,
      pageCount: action.payload.meta.page_count
    },
    items: action.payload.data,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_COVERS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.UPLOAD_COVERS]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    isSuccess: false,
    errorMessage: ''
  }),
  [types.UPLOAD_COVERS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: [
      ...state.items,
      {
        id: action.payload.id,
        type: action.payload.type,
        ...action.payload.attributes
      }
    ],
    isSuccess: true,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.UPLOAD_COVERS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isSuccess: false,
    isError: true,
    errorMessage: action.payload
  })
})

export default getCoversReducer
