import React from 'react'
import { change, reduxForm, Field, formValueSelector, Form } from 'redux-form'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/styles'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import Tooltip from '@material-ui/core/Tooltip'
import ImageIcon from '@material-ui/icons/Image'
import MovieIcon from '@material-ui/icons/Movie'
import AudiotrackIcon from '@material-ui/icons/Audiotrack'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import Button from '../button/Button'
import SimpleEditorInput from '../shared/SimpleEditorInput'
import DropzoneInput from '../shared/DropzoneInput'
import { required } from '../../utils/formUtils'
// import SendMsg from '../../assets/icons/send-msg.svg'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '1rem',
    '& .tox-tinymce': {
      minHeight: 150
    },
    '& section': {
      textAlign: 'center',
      '& div:first-child': {
        maxWidth: 'inherit !important'
      }
    },
    '& .MuiInput-root': {
      border: '1.5px solid #DFDFDF'
    }
  },
  btnContainer: {
    display: 'flex',
    gap: 10,
    justifyContent: 'flex-end',
    margin: '2rem 0'
  },
  archives: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: 20,
    marginBottom: '1rem',
    '& div': {
      display: 'inline-flex',
      justifyContent: 'end',
      '& svg': {
        color: theme.palette.danger.main,
        cursor: 'pointer',
        position: 'absolute',
        margin: '-14px 14px'
      }
    },
    '& img': {
      maxWidth: 300,
      maxHeight: 194
    }
  },
  sendBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  cancelBtn: {
    backgroundColor: '#EF7C8F',
    width: 169,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  continueBtn: {
    backgroundColor: '#386093',
    color: '#FFFF',
    width: 146,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const MessageForm = ({
  handleOpen,
  handleSubmit,
  contentValue,
  change,
  files,
  filesImageValue,
  filesAudioValue,
  filesVideoValue
}) => {
  const classes = useStyles()
  const [fileType, setFileType] = React.useState('')
  const [, setFileToDelete] = React.useState([])
  const [fileToDeleteDropzone, setFileToDeleteDropzone] = React.useState(null)

  const handleFileType = (event, newfileType) => {
    setFileType(newfileType)
  }

  const handleDeleteFile = file => {
    setFileToDeleteDropzone(file)
    if (file.type.includes('image')) {
      const newFiles = filesImageValue.filter(image => image.name !== file.name)
      change('filesImage', newFiles)
    }
    if (file.type.includes('audio')) {
      const newFiles = filesAudioValue.filter(audio => audio.name !== file.name)
      change('filesAudio', newFiles)
    }
    if (file.type.includes('video')) {
      const newFiles = filesVideoValue.filter(video => video.name !== file.name)
      change('filesVideo', newFiles)
    }
  }

  const handleDeleteFileMsg = file => {
    change('files', files.filter(f => f.id !== file.id))
    setFileToDelete(prevState => {
      const newList = prevState.concat(file.id)
      change('filesToDelete', newList)
      return newList
    })
  }

  return (
    <Form onSubmit={handleSubmit} className={classes.root}>
      <Field
        name='content'
        component={SimpleEditorInput}
        defaultValue={contentValue}
        validate={[required]}
      />
      {(!!filesImageValue?.length ||
        !!filesAudioValue?.length ||
        !!filesVideoValue?.length) && (
        <>
          <p>Arquivos</p>
          <div className={classes.archives}>
            {filesImageValue &&
              filesImageValue.map((file, index) => (
                <div key={`image-${file.size}`}>
                  <img
                    src={file.preview || file.file_url}
                    alt={file.name || file.filename}
                  />
                  <HighlightOffRoundedIcon
                    onClick={() => handleDeleteFile(file)}
                  />
                </div>
              ))}
            {filesAudioValue &&
              filesAudioValue.map(file => (
                <div key={`audio-${file.size}`}>
                  <audio controls>
                    <source src={file.preview} type={file.type} />
                    O seu navegador não suporta o elemento <code>audio</code>.
                  </audio>
                  <HighlightOffRoundedIcon
                    onClick={() => handleDeleteFile(file)}
                  />
                </div>
              ))}
            {filesVideoValue &&
              filesVideoValue.map(file => (
                <div key={`video-${file.name}`}>
                  <video width='320' height='240' controls>
                    <source src={file.preview} type={file.type} />
                    O seu navegador não suporta o elemento <code>audio</code>.
                  </video>
                  <HighlightOffRoundedIcon
                    onClick={() => handleDeleteFile(file)}
                  />
                </div>
              ))}
          </div>
        </>
      )}
      {!!files?.length && (
        <>
          <p>Arquivos</p>
          <div className={classes.archives}>
            {files.map(file => {
              const isImage = file.content_type.includes('image')
              const isVideo = file.content_type.includes('video')
              const isAudio = file.content_type.includes('audio')

              const preview = {
                image: (
                  <img
                    src={file.file_url}
                    alt={file.filename}
                    className={classes.img}
                  />
                ),
                video: (
                  <video width='320' height='240' controls>
                    <source src={file.file_url} type={file.content_type} />
                    O seu navegador não suporta o elemento <code>audio</code>.
                  </video>
                ),
                audio: (
                  <audio controls>
                    <source src={file.file_url} type={file.content_type} />
                    O seu navegador não suporta o elemento <code>audio</code>.
                  </audio>
                ),
                default: (
                  <p>
                    {file.filename} - {(file.size / 1024).toFixed(2)}KB
                  </p>
                )
              }
              return (
                <div key={file.filename}>
                  {(isImage && preview['image']) ||
                    (isVideo && preview['video']) ||
                    (isAudio && preview['audio']) ||
                    preview['default']}
                  <HighlightOffRoundedIcon
                    onClick={() => handleDeleteFileMsg(file)}
                  />
                </div>
              )
            })}
          </div>
        </>
      )}
      <label htmlFor='files'>Adicionar arquivos: </label>
      <ToggleButtonGroup
        value={fileType}
        exclusive
        onChange={handleFileType}
        aria-label='file types'
        style={{ marginBottom: '1rem' }}
      >
        <ToggleButton value='image' aria-label='image' color='secondary'>
          <Tooltip title='Adicionar foto' aria-label='foto'>
            <ImageIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value='video' aria-label='video'>
          <Tooltip title='Adicionar vídeo' aria-label='video'>
            <MovieIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value='audio' aria-label='audio'>
          <Tooltip title='Adicionar audio' aria-label='audio'>
            <AudiotrackIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
      {fileType === 'image' && (
        <Field
          accept='image/jpg, image/jpeg, image/png, image/gif'
          component={DropzoneInput}
          maxFiles={0}
          maxSize={2097152}
          name='filesImage'
          type='file'
          fileToDelete={fileToDeleteDropzone}
          withoutPreview
        />
      )}
      {fileType === 'video' && (
        <Field
          accept='video/mp4, video/mov, video/quicktime, video/3gpp, video/3gpp2'
          component={DropzoneInput}
          maxFiles={0}
          maxSize={31457280}
          name='filesVideo'
          type='file'
          fileToDelete={fileToDeleteDropzone}
          withoutPreview
        />
      )}
      {fileType === 'audio' && (
        <Field
          accept='audio/mpeg, audio/mpeg3, audio/mp4, audio/m4a, audio/x-m4a, audio/3gpp, audio/3gpp2'
          component={DropzoneInput}
          maxFiles={0}
          maxSize={10485760}
          name='filesAudio'
          type='file'
          fileToDelete={fileToDeleteDropzone}
          withoutPreview
        />
      )}
      <div className={classes.btnContainer}>
        <Button className={classes.cancelBtn} onClick={handleOpen}>
          Cancelar
        </Button>
        <Button className={classes.sendBtn} type='submit'>
          Enviar
        </Button>
      </div>
    </Form>
  )
}

const mapStateToProps = (state, props) => {
  const initValues = {
    id: props.initialValues?.id,
    title: props.initialValues?.title,
    content: props.initialValues?.content,
    communicationId: props.initialValues?.communicationId,
    parentId: props.initialValues?.parentId,
    destinationsId: props.initialValues?.destinationsId,
    toResponde: props.initialValues?.toResponde
  }
  const selector = formValueSelector('messageForm')
  return {
    initialValues: initValues,
    contentValue: selector(state, 'content') || props.initialValues?.content,
    files: selector(state, 'files') || props.initialValues.files,
    filesImageValue: selector(state, 'filesImage'),
    filesAudioValue: selector(state, 'filesAudio'),
    filesVideoValue: selector(state, 'filesVideo')
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('messageForm', field, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'messageForm',
    onSubmit: async (values, dispatch, props) => {
      const files = [
        values.filesAudio,
        values.filesImage,
        values.filesVideo
      ].flat()
      // if has parent_id its a response to parent message or toRespond to check ifs a response
      if (values.parentId || values.toResponde) {
        // enviar a resposta
        const valuesToData = {
          title: values.title,
          content: values.content,
          communication_id: values.communicationId,
          parent_id: values.parentId,
          message_destinations_attributes: values.destinationsId.map(
            destination => ({ user_id: destination.id })
          ),
          files_attributes: files.filter(Boolean).map(file => ({ file }))
        }
        props.respondMessages(valuesToData)
      } else {
        // para o moderador editar a mensagem, sempre mudar o status para open
        // verificar o values.filesToDelete e deletar os arquivos

        const valuesToData = {
          title: values.title,
          content: values.content,
          files_attributes: files.filter(Boolean).map(file => ({ file }))
        }
        props.updateMessage({
          id: values.id,
          data: { ...valuesToData, status: 'open' },
          filesToDelete: values.filesToDelete
        })
      }
    }
  })(MessageForm)
)
