import { createReducer } from '@reduxjs/toolkit'
import * as types from './types'
const initialState = {
  themes: {},
  isFetching: false,
  isError: false,
  errorMessage: '',
  clonedTheme: {},
  currentItem: {
    item: null,
    isFetching: false,
    isError: false,
    messageError: null,
    contents: {}
  },
  pagination: {
    total: 1,
    pageCount: 1
  }
}

const modulesReducer = createReducer(initialState, {
  [types.GET_THEMES]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_THEMES_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    themes: action.payload,
    errorMessage: '',
    currentItem: initialState.currentItem
  }),
  [types.GET_THEMES_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_THEME_BY_ID]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: true,
      isError: false,
      messageError: null
    }
  }),
  [types.GET_THEME_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      item: action.payload.data,
      isFetching: false,
      isError: false,
      messageError: null,
      included: action.payload?.included
    }
  }),
  [types.GET_THEME_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: false,
      isError: true,
      messageError: action.payload
    }
  }),
  [types.GET_THEME]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: true,
      isError: false,
      messageError: null
    }
  }),
  [types.GET_THEME_CONTENTS]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: true,
      isError: false,
      messageError: null,
      contents: {}
    }
  }),
  [types.GET_THEME_CONTENTS_SUCCESFUL]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: false,
      isError: false,
      messageError: null,
      contents: { items: action.payload.data, pagination: action.payload.meta }
    }
  }),
  [types.GET_THEME_CONTENTS_FAILURE]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: false,
      isError: true,
      messageError: action.payload,
      contents: {}
    }
  }),
  [types.CREATE_THEME]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.CREATE_THEME_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    currentItem: {
      item: action.payload
    },
    errorMessage: ''
  }),
  [types.CREATE_THEME_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.EDIT_THEME]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.EDIT_THEME_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.EDIT_THEME_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.DELETE_THEME]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.DELETE_THEME_SUCCESSFUL]: (state, action) => ({
    ...state,
    themes: state.themes.filter(item => item.id !== action.payload),
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.DELETE_THEME_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.CLONE_THEME]: (state, action) => ({
    ...state,
    clonedTheme: {},
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.CLONE_THEME_SUCCESSFUL]: (state, action) => ({
    ...state,
    clonedTheme: action.payload,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.CLONE_THEME_FAILURE]: (state, action) => ({
    ...state,
    clonedTheme: {},
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.CLEAR_CURRENT_THEME]: (state, action) => ({
    ...state,
    currentItem: initialState.currentItem
  }),
  [types.CLEAR_CLONED_THEME]: (state, action) => ({
    ...state,
    clonedTheme: initialState.clonedTheme
  }),
  NEW_GET_THEME_BY_ID: (state, action) => ({
    ...state,
    currentItem: {
      item: null,
      isFetching: true,
      isError: false,
      messageError: null
    }
  }),
  NEW_GET_THEME_BY_ID_SUCCESSFUL: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      item: action.payload.data,
      isFetching: false,
      isError: false,
      messageError: null
    }
  }),
  NEW_GET_THEME_BY_ID_FAILURE: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: false,
      isError: true,
      messageError: action.payload
    }
  }),
  NEW_GET_THEMES: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  NEW_GET_THEMES_SUCCESSFUL: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    themes: action.payload.data,
    pagination: {
      totalCount: action.payload.meta?.total_count,
      pageCount: action.payload.meta?.page_count
    },
    errorMessage: ''
  }),
  NEW_GET_THEMES_FAILURE: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  NEW_GET_THEME_CONTENTS: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: true,
      isError: false,
      messageError: null,
      contents: {}
    }
  }),
  NEW_GET_THEME_CONTENTS_SUCCESSFUL: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: false,
      isError: false,
      messageError: null,
      contents: action.payload
    }
  }),
  NEW_GET_THEME_CONTENTS_FAILURE: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      isFetching: false,
      isError: true,
      messageError: action.payload,
      contents: {}
    }
  })
})

export default modulesReducer
