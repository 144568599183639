import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Pagination from '@material-ui/lab/Pagination'
import {
  Field,
  Form,
  formValueSelector,
  reduxForm,
  change,
  getFormValues
} from 'redux-form'
import { roles } from '../../utils/constants'

import { Tooltip } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import MaterialInput from '../shared/MaterialInput'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '../button/Button'
import AsyncSelect from '../shared/AsyncSelect'
import filterSVG from '../../assets/icons/filter-icon.svg'
import folderDisableSVG from '../../assets/icons/folder-disable.svg'
import folderHoverSVG from '../../assets/icons/folder-hover.svg'
import folderActiveSVG from '../../assets/icons/folder-active.svg'
import viewSVG from '../../assets/icons/pattern-view-icon.svg'
import editSVG from '../../assets/icons/pattern-edit-icon.svg'
import CustomTable from '../table/CustomTable'
import StyledSVG from '../shared/StyledSVG'
import {
  getWrapperUtils,
  getUtils,
  deleteUtil,
  cleanUtilSuccessful,
  getUtilById
} from '../../store/utils/actions'
import { showAlertMessage } from '../../store/alert/actions'
import { getClassroomsBySchoolIdRSPA } from '../../store/classrooms/actions'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  select: {
    '& .select__menu-list': {
      maxHeight: '150px'
    },
    '& div': {
      '& .select__control': {
        minWidth: 200
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        minWidth: 'unset',
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    },
    [theme.breakpoints.down('1000')]: {
      width: '100% !important',
      '& div': {
        // redux form 'Field' componente
        '& div': {
          '& .select__control, .select__value-container, .select__indicators, .MuiInputBase-root': {
            minHeight: '35px',
            height: '35px'
          },
          maxWidth: '100%'
        }
      }
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    // width: '100%',
    gap: 8,
    '& button': {
      marginRight: 0,
      width: 149
    },
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'flex-start',
      marginTop: 15,
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    [theme.breakpoints.down('350')]: {
      '& button': {
        width: '170px !important'
      }
    }
  },
  folder: {
    display: 'flex',
    width: '320px',
    height: '220px',
    padding: '32px 10px 10px 20px',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${folderDisableSVG})`,
    transition: 'background-image 0.3s ease, color 0.3s ease',
    '&:hover': {
      backgroundImage: `url(${folderHoverSVG})`,
      color: 'white',
      '& p': {
        color: 'white'
      },
      '& svg': {
        '& path': {
          // fill: 'white',
          stroke: 'white',
          transition: 'fill 0.3s ease-in-out'
        }
      }
    },
    '&:active': {
      backgroundImage: `url(${folderActiveSVG})`
    }
  },
  hidden: {
    opacity: 0,
    visibility: 'hidden',
    position: 'absolute',
    pointerEvents: 'none' // Torna não clicável
  },
  folderInfo: {
    height: '33.33%',
    '& p': {
      color: '#868E96',
      fontSize: '10px',
      marginBottom: 0
    },
    '& strong': {
      margin: 0,
      fontSize: '16px'
    },
    [theme.breakpoints.down('426')]: {
      '& p': {
        fontSize: '8px'
      },
      '& strong': {
        fontSize: '12px'
      }
    }
  },
  pagination: {
    float: 'right',
    paddingTop: '15px',
    [theme.breakpoints.down('426')]: {
      float: 'unset',
      '& .MuiPagination-ul': {
        flexWrap: 'nowrap'
      }
    }
  },
  clearBtn: {
    backgroundColor: '#EF7C8F',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  createBtn: {
    backgroundColor: '#00ACDB',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 45,
    [theme.breakpoints.down('890')]: {
      flexDirection: 'column-reverse',
      gap: 16
    }
  },
  searchDiv: {
    '& .MuiInputBase-root': {
      width: 300,
      [theme.breakpoints.down('426')]: {
        maxWidth: '250px'
      },
      [theme.breakpoints.down('330')]: {
        maxWidth: 220
      }
    }
  },
  searchGroup: {
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    gap: 24,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      gap: 12
    }
  },
  advancedFilter: {
    overflow: 'hidden',
    transition: 'height 0.5s ease-in-out',
    [theme.breakpoints.down('1000')]: {
      overflow: 'scroll'
    }
  },
  listTitle: {
    marginTop: 25,
    color: '#386093',
    [theme.breakpoints.down('426')]: {
      fontSize: 18
    }
  },
  searchField: {
    '& .select__menu-list': {
      maxHeight: '150px'
    },
    '& div': {
      '& .select__control': {
        minWidth: 200
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        minWidth: 'unset',
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    },
    [theme.breakpoints.down('1000')]: {
      width: '100% !important',
      '& div': {
        // redux form 'Field' componente
        '& div': {
          '& .select__control, .select__value-container, .select__indicators, .MuiInputBase-root': {
            minHeight: '35px',
            height: '35px'
          },
          maxWidth: '100%'
        }
      }
    }
  },
  actionsSection: {
    display: 'flex',
    gap: 5,
    alignItems: 'flex-end',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& path': {
      stroke: '#868E96'
    },
    '& circle': {
      stroke: '#868E96'
    }
  },
  secondColumn__firstLine: {
    display: 'flex',
    gap: 30
  },
  wrappedSection: {
    overflowY: 'auto',
    '& div.wrappers': {
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: '20px',
      columnGap: '40px',
      justifyContent: 'flex-start'
    }
  }
}))

const WrappedComponent = ({
  wrapped,
  fetchItems,
  isFetching,
  params,
  isAdmin,
  getCurrentWrapped,
  firstPageAfterSearch,
  isVersarAdmin
}) => {
  const classes = useStyles()
  const [page, setPage] = React.useState(1)
  const history = useHistory()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const handleWrapperClick = (isEdit, classroomId, wrappedId) => {
    if (isEdit) {
      getCurrentWrapped({ id: wrappedId })
      history.push({
        pathname: '/utils',
        search: `?id=${wrappedId}&name=edit&util=wrapper`
      })
    } else {
      history.push({
        pathname: '/utils',
        search: '?name=list&util=bulletins',
        state: { classroomId, wrappedId }
      })
    }
  }

  const handleChange = (event, value) => {
    setPage(value)
    fetchItems({
      page: value,
      params: {
        ...params
      }
    })
  }

  // to syncronize both components that handle the wrappers when the screen size changes
  React.useEffect(
    () => {
      fetchItems({
        page: 1,
        params: {
          ...params
        }
      })
      setPage(1)
    },
    [matches]
  )

  return (
    <div style={{ marginTop: 40 }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: 10,
          alignItems: 'center'
        }}
      >
        <StyledSVG src={filterSVG} height={24} width={24} />
        <h1 style={{ fontSize: 24, fontWeight: 700, color: '#386093' }}>
          Lista de turmas
        </h1>
      </div>
      {matches ? (
        <div className={classes.wrappedSection}>
          <div className='wrappers'>
            {wrapped?.data &&
              wrapped?.data?.length > 0 &&
              wrapped.data.map(item => {
                return (
                  <div key={item.id} className={`${classes.folder}`}>
                    <div
                      onClick={() =>
                        handleWrapperClick(false, item.classroom_id, item.id)
                      }
                      id={'firstColumn'}
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        rowGap: '10px',
                        cursor: 'pointer'
                      }}
                    >
                      <div className={classes.folderInfo}>
                        <p>Nome</p>
                        <strong>{item.title}</strong>
                      </div>
                      <div className={classes.folderInfo}>
                        <p>Criação</p>
                        <strong>
                          {moment(item.created_at).format('YYYY/MM/DD HH:mm')}
                        </strong>
                      </div>
                      <div className={classes.folderInfo}>
                        <p>Publicação</p>
                        <strong>
                          {moment(item.scheduled_at).format('YYYY/MM/DD HH:mm')}
                        </strong>
                      </div>
                    </div>
                    <div
                      id={'secondColumn'}
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        rowGap: '10px'
                      }}
                    >
                      <div className={classes.secondColumn__firstLine}>
                        <div className={classes.folderInfo}>
                          <p>Série</p>
                          <strong>{item.grade[0].name}</strong>
                        </div>
                        <div className={classes.actionsSection}>
                          <Tooltip title='Visualizar'>
                            <div
                              onClick={() =>
                                handleWrapperClick(
                                  false,
                                  item.classroom_id,
                                  item.id
                                )
                              }
                            >
                              <StyledSVG width={24} height={24} src={viewSVG} />
                            </div>
                          </Tooltip>
                          {isAdmin && (
                            <Tooltip title='Editar'>
                              <div
                                onClick={() =>
                                  handleWrapperClick(
                                    true,
                                    item.classroom_id,
                                    item.id
                                  )
                                }
                              >
                                <StyledSVG
                                  width={24}
                                  height={24}
                                  src={editSVG}
                                />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <div className={classes.folderInfo}>
                        <p>Turma</p>
                        <strong>{item.classroom[0].name}</strong>
                      </div>
                      <div className={classes.folderInfo}>
                        <p>Escola</p>
                        <strong>{item.school.name}</strong>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
          {wrapped.meta &&
            wrapped.meta.page_count > 1 && (
              <Pagination
                count={wrapped.meta.page_count}
                className={classes.pagination}
                color='primary'
                page={page}
                onChange={handleChange}
              />
            )}
        </div>
      ) : (
        <CustomTable
          from={'wrapper'}
          columns={[
            {
              key: 'name',
              name: 'Nome',
              render: function render (row) {
                return row.title
              }
            },
            {
              key: 'school',
              name: 'Escola',
              render: function render (row) {
                return <p>{row.school?.name}</p>
              }
            },
            {
              key: 'created_at',
              name: 'Criação',
              render: function render (row) {
                return row.created_at
              }
            },
            {
              key: 'scheduled_at',
              name: 'Publicação',
              render: function render (row) {
                return row.scheduled_at
              }
            },
            {
              key: 'grade',
              name: 'Série',
              render: function render (row) {
                return row.grade[0].name
              }
            },
            {
              key: 'classroom',
              name: 'Turma',
              render: function render (row) {
                return row.classroom[0].name
              }
            },
            {
              key: 'actions',
              name: 'Ações',
              render: function render (row) {
                return (
                  <div className={classes.actionsSection}>
                    <Tooltip title='Visualizar'>
                      <div
                        onClick={() =>
                          handleWrapperClick(false, row.classroom_id, row.id)
                        }
                      >
                        <StyledSVG width={24} height={24} src={viewSVG} />
                      </div>
                    </Tooltip>
                    {isAdmin && (
                      <Tooltip title='Editar'>
                        <div
                          onClick={() =>
                            handleWrapperClick(true, row.classroom_id, row.id)
                          }
                        >
                          <StyledSVG width={24} height={24} src={editSVG} />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                )
              }
            }
          ].filter(item => {
            if (!isVersarAdmin) {
              return item.key !== 'school'
            } else {
              return item
            }
          })}
          data={wrapped.data}
          whiteHeader
          border={'1px solid #ADB8CC'}
          isFetching={isFetching}
          fetchItems={fetchItems}
          params={params}
          pagination={{ pageCount: wrapped?.meta?.page_count }}
          firstPageAfterSearch={firstPageAfterSearch}
        />
      )}
    </div>
  )
}

const SearchBulletinsForm = ({
  utils,
  isFetching,
  pagination,
  schoolId,
  handleSubmit,
  fieldValues,
  schoolValue,
  classroomValue,
  gradeValue,
  change,
  reset,
  touch,
  title,
  utilType,
  userOccupations,
  cleanUtilSuccessful,
  getClassroomsBySchoolIdRSPA,
  getWrapperUtils,
  wrappers,
  getUtilById
}) => {
  const classes = useStyles()
  const selectGradeRef = React.useRef(null)
  const selectClassroomRef = React.useRef(null)
  const selectNameRef = React.useRef(null)
  const selectSchoolRef = React.useRef(null)
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)

  const firstPageAfterSearch = React.useRef(false)
  const history = useHistory()
  // const [confirmModal, setConfirmModal] = React.useState({
  //   open: false,
  //   title: null,
  //   util_type: null,
  //   id: null
  // })

  const [openMoreFilter, setOpenMoreFilter] = React.useState(false)
  const defaultParams = {
    'page[size]': 8,
    'q[school_id_eq]': schoolId,
    'q[utility_type_eq]': 6
  }

  const bulletinsParams = {
    ...defaultParams,
    ...(schoolValue && { 'q[school_id_eq]': schoolValue }),
    'q[title_cont]': fieldValues?.title,
    'q[grade_id_eq]': gradeValue,
    'q[classroom_id_eq]': classroomValue
  }

  React.useEffect(() => {
    if (utilType !== 'bulletins') {
      getWrapperUtils({ params: defaultParams })
    }
  }, [])

  const handleChangeTitle = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      getWrapperUtils({
        params: { ...defaultParams, 'q[title_cont]': event.target.value }
      })
    }
  }
  const handleChange = (util, input) => {
    touch()
    let newValue
    if (Array.isArray(util)) {
      newValue = util?.map(v => v)
    } else {
      newValue = util ? util.value : ''
    }
    change(input.name, newValue)
  }

  const handleClearFields = () => {
    reset()
    selectGradeRef.current?.select.clearValue()
    selectClassroomRef.current?.select.clearValue()
    selectSchoolRef.current?.select.clearValue()
    selectNameRef.current?.select?.clearValue()
    getWrapperUtils({ params: defaultParams })
  }

  return (
    <div className={classes.root}>
      <Form onSubmit={handleSubmit}>
        {/* Header */}
        <div>
          {/* content */}
          <div>
            <div className={classes.searchContainer}>
              <div className={classes.searchDiv}>
                <strong style={{ marginBottom: 10 }}>Busca</strong>
                <Field
                  style={{ width: 300 }}
                  component={MaterialInput}
                  ref={selectNameRef}
                  name='title'
                  onKeyDown={handleChangeTitle}
                  placeholder='Título do boletim'
                  type='text'
                />
              </div>
              {isAdmin && (
                <Button
                  style={{
                    lineHeight: '16px',
                    whiteSpace: 'nowrap',
                    textTransform: 'none'
                  }}
                  className={classes.createBtn}
                  type='button'
                  onClick={() => history.push('?name=new&util=wrapper')}
                >
                  Criar boletim
                </Button>
              )}
            </div>
          </div>
          {/* advanced filters */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <StyledSVG src={filterSVG} height={12} width={12} />
              <p
                onClick={() => setOpenMoreFilter(prev => !prev)}
                style={{
                  cursor: 'default',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#386093'
                }}
              >
                Filtros avançados
              </p>
              {!openMoreFilter ? (
                <KeyboardArrowDownIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              )}
            </div>
            <div
              className={classes.advancedFilter}
              style={{
                height: openMoreFilter ? '250px' : '0px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: 24
                }}
              >
                <div
                  className={`${!openMoreFilter ? classes.hidden : ''} ${
                    classes.searchGroup
                  }`}
                  style={{ opacity: openMoreFilter ? '1' : '0' }}
                >
                  {userOccupations?.includes(roles.VERSAR_ADMIN) && (
                    <div className={classes.searchField}>
                      <strong htmlFor='school_id'>Escola</strong>
                      <Field
                        component={AsyncSelect}
                        selectRef={selectSchoolRef}
                        defaultOptions={false}
                        id='school_id'
                        name='school_id'
                        placeholder='Digite ou selecione a escola'
                        handleAsyncChange={handleChange}
                        searchParam='q[name_cont]'
                        request={{
                          path: 'school'
                        }}
                        touch={touch}
                      />
                    </div>
                  )}
                  <div className={classes.searchField}>
                    <strong style={{ marginBottom: 10 }} htmlFor='grade_id'>
                      Série
                    </strong>
                    <Field
                      component={AsyncSelect}
                      defaultOptions={false}
                      selectRef={selectGradeRef}
                      cacheUniqs={[schoolValue]}
                      id='grade_id'
                      name='grade_id'
                      placeholder='Digite ou selecione'
                      handleAsyncChange={handleChange}
                      searchParam='q[name_cont]'
                      request={{
                        path: 'grade',
                        params: {
                          'q[school_id_null]': true
                        }
                      }}
                      touch={touch}
                    />
                  </div>
                  <div className={classes.searchField}>
                    <strong style={{ marginBottom: 10 }} htmlFor='classroom_id'>
                      Turma
                    </strong>
                    <Field
                      component={AsyncSelect}
                      defaultOptions={false}
                      id='classroom_id'
                      name='classroom_id'
                      selectRef={selectClassroomRef}
                      cacheUniqs={[gradeValue, schoolValue]}
                      placeholder='Digite ou selecione'
                      handleAsyncChange={handleChange}
                      searchParam='q[name_cont]'
                      request={{
                        path: 'classroom',
                        params: {
                          'q[school_id_in]': schoolId || schoolValue,
                          'q[grade_id_in]': gradeValue,
                          'q[year_lteq]': new Date().getFullYear() + 1,
                          'q[year_gteq]': new Date().getFullYear()
                        }
                      }}
                      touch={touch}
                    />
                  </div>
                  <div />
                </div>
                <div
                  style={{
                    opacity: openMoreFilter ? '1' : '0',
                    transition: 'opacity 0.3s ease-in-out'
                  }}
                  className={`${classes.buttons} ${
                    !openMoreFilter ? classes.hidden : ''
                  }`}
                >
                  <Button
                    className={classes.clearBtn}
                    onClick={() => {
                      handleClearFields()
                      setOpenMoreFilter(false)
                    }}
                  >
                    Limpar
                  </Button>
                  <Button
                    onClick={() => {
                      getWrapperUtils({ params: { ...bulletinsParams } })
                      firstPageAfterSearch.current = true
                    }}
                    className={classes.applyBtn}
                    type='button'
                  >
                    Aplicar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* horizontal bar */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              height: 1,
              border: '#ADB8CC solid 1px'
            }}
          />
        </div>
      </Form>
      {/* Body */}
      <WrappedComponent
        isVersarAdmin={isVersarAdmin}
        getCurrentWrapped={getUtilById}
        wrapped={wrappers}
        params={bulletinsParams}
        fetchItems={getWrapperUtils}
        isAdmin={isAdmin}
        isFetching={isFetching}
        firstPageAfterSearch={firstPageAfterSearch}
      />
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('searchBulletinsForm')

  return {
    utils: state.utils.items,
    wrappers: state.utils.wrapperItems,
    isFetching: state.utils.isFetching,
    pagination: state.utils.pagination,
    userOccupations: state.auth.currentOccupation,
    schoolId:
      state.school.currentSchool && state.school.currentSchool.school_id,
    startDateValue: selector(state, 'start_date'),
    finalDateValue: selector(state, 'final_date'),
    createdDateValue: selector(state, 'created_at'),
    schoolValue:
      selector(state, 'school_id') ||
      (state.school.currentSchool && state.school.currentSchool.school_id),
    classroomValue: selector(state, 'classroom_id'),
    gradeValue: selector(state, 'grade_id'),
    destinationTypeValue: selector(state, 'destination_type'),
    searchUtilTypeValue: selector(state, 'search_util_type'),
    fieldValues: getFormValues('searchBulletinsForm')(state)
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('searchBulletinsForm', field, data)),
  getUtils: data => dispatch(getUtils(data)),
  getWrapperUtils: data => dispatch(getWrapperUtils(data)),
  getUtilById: data => dispatch(getUtilById(data)),
  deleteUtil: data => dispatch(deleteUtil(data)),
  cleanUtilSuccessful: data => dispatch(cleanUtilSuccessful(data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    ),
  getClassroomsBySchoolIdRSPA: data =>
    dispatch(getClassroomsBySchoolIdRSPA.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'searchBulletinsForm'
  })(SearchBulletinsForm)
)
