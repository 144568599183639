import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  label: {
    color: '#4D5E80',
    fontWeight: 700,
    fontSize: '2rem',
    [theme.breakpoints.down('426')]: {
      fontSize: '1.5rem'
    },
    marginBottom: '32px'
  }
}))

export default function Label ({ children, component, style, className }) {
  const classes = useStyles()
  return (
    <Typography
      variant='h4'
      component={component || 'h2'}
      style={style}
      className={`${classes.label} ${className}`}
    >
      {children}
    </Typography>
  )
}
