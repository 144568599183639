import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  reduxForm,
  Field,
  change,
  Form,
  formValueSelector,
  getFormValues
} from 'redux-form'
import { connect } from 'react-redux'
import { normalizeCPF, normalizePhone } from '../../utils/formUtils'
import MaterialInput from '../shared/MaterialInput'
import { makeStyles } from '@material-ui/core/styles'
import { occupationsObject } from '../../utils/dicts'
import CustomTable from '../table/CustomTable'
import Button from '../button/Button'
import Grid from '@material-ui/core/Grid'
import studentBookSVG from '../../assets/icons/student-details-icon.svg'
import filterSVG from '../../assets/icons/filter-icon.svg'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
// import { KeyboardArrowDown } from '@material-ui/icons'
import StyledSVG from '../shared/StyledSVG'
import CheckboxInput from '../input/CheckboxInput'
import {
  getUsersHistory,
  exportUsersHistoryRSPA,
  forceTemporaryPasswordRequest
} from '../../store/users/actions'
import moment from 'moment'
import { isEmpty } from 'lodash'
import UserAvatar from '../shared/UserAvatar'
import notificationCheckSVG from '../../assets/icons/notification-check.svg'
import calendarSVG from '../../assets/icons/calendar.svg'
import notificationUncheckSVG from '../../assets/icons/notification-uncheck.svg'
import temporaryPasswordSVG from '../../assets/icons/temporary-password.svg'
import { months } from '../../utils/constants'
import Modal from '../modal/Modal'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import viewSVG from '../../assets/icons/pattern-view-icon.svg'
import Tooltip from '@material-ui/core/Tooltip'
import { Input } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles(theme => ({
  btnStyle: {
    marginTop: 18,
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'center',
    textAlign: 'right',
    gap: 10,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& button': {
        width: 240
      }
    }
  },
  exportBtn: {
    backgroundColor: '#F4A87C',
    width: 196,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#F4A87C'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  root: {
    width: '100%',
    padding: '40px 30px',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  pageLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 20,
    '& h1': {
      fontSize: 32,
      color: '#386093',
      fontWeight: 700,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  searchDiv: {
    '& .MuiInputBase-root': {
      width: 300,
      [theme.breakpoints.down('426')]: {
        maxWidth: '250px'
      },
      [theme.breakpoints.down('330')]: {
        maxWidth: 220
      }
    }
  },
  mainInput: {
    marginTop: 10
  },
  filtersSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  filterLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  advancedFilter: {
    overflow: 'hidden',
    transition: 'height 0.5s ease-in-out',
    [theme.breakpoints.down('1000')]: {
      overflow: 'scroll'
    }
  },
  filtersDisplay: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24
  },
  searchGroup: {
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    gap: 24,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      gap: 12
    }
  },
  notificationCheckbox: {
    display: 'flex'
  },
  searchField: {
    '& .select__menu-list': {
      maxHeight: '150px'
    },
    '& div': {
      '& .select__control': {
        minWidth: 200
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        minWidth: 'unset',
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    },
    [theme.breakpoints.down('1000')]: {
      width: '100% !important',
      '& div': {
        // redux form 'Field' componente
        '& div': {
          '& .select__control, .select__value-container, .select__indicators, .MuiInputBase-root': {
            minHeight: '35px',
            height: '35px'
          },
          maxWidth: '100%'
        }
      }
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: 8,
    '& button': {
      marginRight: 0,
      width: 149
    },
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'flex-start',
      marginTop: 15,
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    [theme.breakpoints.down('350')]: {
      '& button': {
        width: '170px !important'
      }
    }
  },
  clearBtn: {
    backgroundColor: '#EF7C8F',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  horizontalBar: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 1,
    border: '#ADB8CC solid 1px'
  },
  searchSection: {
    marginTop: 40
  },
  userAvatar: {
    justifyContent: 'flex-end'
  },
  notificationCheck: {
    display: 'flex',
    alignItems: 'center',
    gap: 8
  },
  modalRoot: {
    padding: '40px',
    position: 'relative'
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  datasLabel: {
    marginBottom: 20
  },
  detailHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 20,
    '& h1': {
      fontSize: 24,
      fontWeight: 700,
      color: '#386093'
    }
  },
  infoLabel: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  userType: {
    marginBottom: 20,
    '& span': {
      fontSize: '18px !important',
      fontWeight: '600 !important',
      color: '#386093 !important'
    }
  },
  personalData: {
    marginBottom: 10,
    color: '#555555',
    fontWeight: 600
  },
  addressSection: {
    marginTop: 50
  },
  addressInfo: {
    color: '#555555',
    marginBottom: 18,
    fontWeight: 600
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& label': {
      color: '#9F9F9F',
      fontWeight: 500,
      fontSize: 16
    },
    '& p': {
      margin: 0,
      color: '#666666',
      fontWeight: 700,
      fontSize: 16
    }
  },
  childrenLabel: {
    marginTop: 50
  },
  childSection: {
    padding: '20px 10px',
    minHeight: '100px',
    borderRadius: 8,
    background: 'linear-gradient(194.95deg, #FFFFFF -0.58%, #F1F3F4 60.91%)',
    position: 'relative',
    transition: 'height 0.3s ease-in-out',
    // xs={12} sm={6} md={4} lg={3} xl={3}
    [theme.breakpoints.down('md')]: {
      minHeight: '150px'
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '250px'
    }
  },
  childAddress: {
    height: '300px',
    [theme.breakpoints.down('md')]: {
      height: '320px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '340px'
    },
    [theme.breakpoints.down('xs')]: {
      height: '450px'
    }
  },
  childBirthdate: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    '& svg': {
      '& path': {
        stroke: '#555555'
      }
    }
  },
  actionsSection: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& path': {
      stroke: '#868E96'
    }
  },
  temporaryPassword: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& h1': {
      color: theme.palette.primary.main,
      fontSize: '24px',
      fontWeight: 700
    },
    '& > div': {
      display: 'flex',
      gap: '8px',
      '& > span:nth-of-type(1)': {
        fontSize: '16px'
      },
      '& > span:nth-of-type(2)': {
        fontSize: '16px',
        fontWeight: 600
      }
    },
    '& div:nth-of-type(4)': {
      display: 'flex',
      gap: '16px',
      justifyContent: 'center',
      marginTop: '16px',
      '& > button:first-of-type': {
        color: '#fff',
        border: 'none',
        borderRadius: '8px',
        background: '#386093',
        padding: '12px',
        '&:hover': {
          cursor: 'pointer',
          background: '#00ACDB'
        },
        '&:disabled': {
          background: '#C0C3C5',
          cursor: 'not-allowed'
        }
      },
      '& > button:nth-of-type(2)': {
        color: '#fff',
        border: 'none',
        borderRadius: '8px',
        background: '#EF7C8F',
        padding: '12px',
        '&:hover': {
          cursor: 'pointer',
          background: '#EF7C8ecd'
        }
      }
    }
  },
  errorMessage: {
    color: '#EF7C8F'
  }
}))

const SearchUsersHistoryForm = ({
  change,
  school,
  isAdmin,
  touch,
  onSubmit,
  users,
  getUsersHistory,
  fieldValues,
  name,
  email,
  phone,
  // eslint-disable-next-line camelcase
  taxpayer_number,
  orderBy,
  listItemValue,
  exportUsersHistoryRSPA,
  // eslint-disable-next-line camelcase
  children_name,
  notificationCheckValue,
  generateTemporaryPassword
}) => {
  const history = useHistory()
  const classes = useStyles()
  const firstPageAfterSearch = React.useRef(false)
  const selectNameRef = React.useRef(null)
  const [notificationChecked, setNotificationChecked] = React.useState(false)
  const [openMoreFilter, setOpenMoreFilter] = React.useState(false)
  const handleChangeTitle = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      getUsersHistory({
        ...searchParams
      })
    }
  }
  const [userDetailModal, setUserDetailModal] = React.useState({
    isOpen: false,
    current: null
  })

  const [temporaryPasswordModal, setTemporaryPasswordModal] = React.useState(
    false
  )
  const [tempUserData, setTempUserData] = React.useState(null)
  const [tempPassword, setTempPassword] = React.useState(null)
  const [error, setError] = React.useState('')
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const [disabled, setDisabled] = React.useState(false)
  const sendTempPassword = () => {
    if (tempPassword.length < 6) {
      setError('A senha deve ter pelo menos 6 caracteres.')
    } else {
      generateTemporaryPassword({
        email: tempUserData?.email,
        password: tempPassword
      })
      setDisabled(true)
      handleCloseGenerateTemporaryPassword()
    }
  }

  const handlePasswordChange = event => {
    const { value } = event.target
    setTempPassword(value)
    setError('')
  }

  const modalGenerateTemporaryPassword = userData => {
    setTemporaryPasswordModal(true)
    setTempUserData(userData)
  }

  const handleCloseGenerateTemporaryPassword = () => {
    setTempPassword(null)
    setTemporaryPasswordModal(false)
    setDisabled(false)
  }

  const [openProfile, setOpenProfile] = React.useState([])
  const today = new Date()
  const lastAccessIsToday =
    moment(today).format('DD/MM/YYYY') ===
    moment(userDetailModal?.current?.last_access_at).format('DD/MM/YYYY')
  const lastAccessWasYesterday =
    moment(today.setDate(today.getDate() - 1)).format('DD/MM/YYYY') ===
    moment(userDetailModal?.current?.last_access_at).format('DD/MM/YYYY')

  // const handleChange = event => {
  //   change('listItemValue', event.value)
  //   change('paramToOrder', event.query)
  // }

  React.useEffect(
    () => {
      if (userDetailModal.isOpen === false) {
        setOpenProfile([])
      }
    },
    [userDetailModal.isOpen]
  )

  const handleSearch = isToClear => {
    if (isToClear === true) {
      getUsersHistory({ schoolId: school.id })
      change('children_name', undefined)
      change('name', undefined)
      change('email', undefined)
      change('taxpayer_number', undefined)
      change('phone', undefined)
      change('paramToOrder', undefined)
    } else {
      getUsersHistory({
        ...searchParams
      })
    }
    firstPageAfterSearch.current = true
  }

  useEffect(() => {
    change('listItemValue', 'name asc')
  }, [])

  useEffect(
    () => {
      if (listItemValue !== null) {
        handleSearch()
      }
    },
    [listItemValue]
  )

  const searchParams = {
    'q[name_cont]': name,
    'q[email_cont]': email,
    'q[phone_cont]': phone,
    'q[taxpayer_number_cont]': taxpayer_number,
    'q[child_parents_child_name_cont]': children_name,
    ...(notificationChecked === true && {
      'q[user_config_notify_by_push_eq]': false
    }),
    schoolId: school?.id,
    ...orderBy
  }

  const columns = [
    {
      key: 'userAvatar',
      name: '',
      justify: 'flex-end',
      render: row => {
        return (
          <UserAvatar
            onClick={() => handleUserDetails(row)}
            userName={row.name}
            withoutUserName
            className={classes.userAvatar}
          />
        )
      }
    },
    {
      key: 'name',
      name: 'Usuários',
      render: row => {
        return <span onClick={() => handleUserDetails(row)}>{row.name}</span>
      }
    },
    {
      key: 'occupations',
      name: 'Ocupações',

      render: row => {
        const occupations = row?.user_occupations?.map((item, index, array) => {
          if (index === array.length - 1) {
            return `${occupationsObject[item.occupation.name]}\n`
          } else {
            return `${occupationsObject[item.occupation.name]}, \n`
          }
        })
        return occupations
      }
    },
    {
      key: 'childrens',
      name: 'Filho(s)',
      render: row => {
        const childrens = row?.children?.map((item, index, array) => {
          if (index === array.length - 1) {
            return `${item?.name}\n`
          } else {
            return `${item?.name}, \n`
          }
        })
        return childrens?.length > 0 ? childrens : '-'
      }
    },
    {
      key: 'last_access',
      name: 'Último acesso',
      render: row => {
        const isToday =
          moment(today).format('DD/MM/YYYY') ===
          moment(row?.last_access_at).format('DD/MM/YYYY')
        const wasYesterday =
          moment(today.setDate(today.getDate() - 1)).format('DD/MM/YYYY') ===
          moment(row?.last_access_at).format('DD/MM/YYYY')
        return (
          <span>
            {!row?.last_access_at
              ? 'Sem registro de acesso do usuário.'
              : isToday
                ? `Hoje às ${moment(row.last_access_at).format('HH:mm')}`
                : wasYesterday
                  ? `Ontem às ${moment(row.last_access_at).format('HH:mm')}`
                  : `${moment(row.last_access_at).format('DD')} de ${
                      months[`${moment(row.last_access_at).format('MM')}`]
                    } de ${moment(row.last_access_at).format(
                      'YYYY'
                    )} às ${moment(row.last_access_at).format('HH:mm')}`}
          </span>
        )
      }
    },
    {
      key: 'notifications',
      name: 'Notificação',
      render: row => {
        const hasNotification = row?.user_config?.notify_by_push === true
        return (
          <span className={classes.notificationCheck}>
            <StyledSVG
              src={
                hasNotification ? notificationCheckSVG : notificationUncheckSVG
              }
              width={16}
              height={16}
            />
            {hasNotification ? 'Sim' : 'Não'}
          </span>
        )
      }
    },
    {
      key: 'actions',
      name: 'Ações',
      render: row => {
        return (
          <div className={classes.actionsSection}>
            <Tooltip title='Visualizar'>
              <div onClick={() => handleUserDetails(row)}>
                <StyledSVG width={18} height={18} src={viewSVG} />
              </div>
            </Tooltip>

            <Tooltip title='Gerar senha temporária'>
              <div onClick={() => modalGenerateTemporaryPassword(row)}>
                <StyledSVG width={25} src={temporaryPasswordSVG} />
              </div>
            </Tooltip>
            {/* <Tooltip title='Editar'>
          <div
            onClick={() => history.push('user/12/edit')}
          >
            <StyledSVG width={18} height={18} src={editSVG} />
          </div>
        </Tooltip> */}
          </div>
        )
      }
    }
  ]
  const exportUsers = () => {
    try {
      exportUsersHistoryRSPA({ id: school.id })
    } catch (err) {
      console.error(err)
    }
  }
  const handleCheck = event => {
    setNotificationChecked(event.target.checked)
  }

  const handleUserDetails = row => {
    setUserDetailModal(prev => ({ isOpen: !prev.isOpen, current: row }))
  }
  return (
    <div className={classes.root}>
      <Form>
        <div className={classes.pageLabel}>
          <h1>Configurações de usuários</h1>
          <span>
            Visualize o registro de acessos dos usuários na plataforma
          </span>
        </div>
        {/* Header */}
        <div className={classes.searchSection}>
          {/* content */}
          <div className={classes.searchDiv}>
            <strong>Busca</strong>
            <Field
              style={{ marginTop: 10 }}
              component={MaterialInput}
              ref={selectNameRef}
              name='name'
              endBtnFunction={() => {
                getUsersHistory({
                  ...searchParams
                })
              }}
              onKeyDown={handleChangeTitle}
              placeholder={'Nome do usuário'}
              type='text'
            />
          </div>
          {/* advanced filters */}
          <div className={classes.filtersSection}>
            <div className={classes.filterLabel}>
              <StyledSVG src={filterSVG} height={12} width={12} />
              <p
                onClick={() => setOpenMoreFilter(prev => !prev)}
                style={{
                  cursor: 'default',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#386093'
                }}
              >
                Filtros avançados
              </p>
              {!openMoreFilter ? (
                <KeyboardArrowDownIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              )}
            </div>
            <div
              className={classes.advancedFilter}
              style={{
                height: openMoreFilter ? '250px' : '0px'
              }}
            >
              <div className={classes.filtersDisplay}>
                <div
                  className={classes.searchGroup}
                  style={{ opacity: openMoreFilter ? '1' : '0' }}
                >
                  <div className={classes.searchField}>
                    <label htmlFor={`taxpayer_number`}>CPF</label>
                    <Field
                      allowClear
                      component={MaterialInput}
                      name={`taxpayer_number`}
                      placeholder='CPF'
                      type='text'
                      autoComplete='off'
                      normalize={normalizeCPF}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                        }
                      }}
                    />
                  </div>
                  <div className={classes.searchField}>
                    <label htmlFor='phone' className={classes.fieldLabel}>
                      Telefone
                    </label>
                    <Field
                      component={MaterialInput}
                      name='phone'
                      placeholder='Telefone'
                      type='text'
                      normalize={normalizePhone}
                      autoComplete='off'
                    />
                  </div>
                  <div className={classes.searchField}>
                    <label htmlFor='email' className={classes.fieldLabel}>
                      Email
                    </label>
                    <Field
                      component={MaterialInput}
                      name='email'
                      placeholder='Email'
                      type='text'
                      autoComplete='off'
                    />
                  </div>
                  <div className={classes.searchField}>
                    <label htmlFor='name' className={classes.fieldLabel}>
                      Nome do aluno
                    </label>
                    <Field
                      component={MaterialInput}
                      name='children_name'
                      placeholder='Nome do estudante'
                      type='text'
                      autoComplete='off'
                      autoFocus
                    />
                  </div>
                </div>
                <div className={classes.notificationCheckbox}>
                  <CheckboxInput
                    name='notificationCheck'
                    checked={notificationChecked}
                    handleCheck={handleCheck}
                  />
                  <p>Usuários com notificações desabilitadas</p>
                </div>
                <div
                  style={{
                    opacity: openMoreFilter ? '1' : '0',
                    transition: 'opacity 0.3s ease-in-out'
                  }}
                  className={classes.buttons}
                >
                  <Button
                    onClick={() => handleSearch(true)}
                    className={classes.clearBtn}
                  >
                    Limpar
                  </Button>
                  <Button
                    className={classes.applyBtn}
                    type='button'
                    onClick={() => handleSearch(false)}
                  >
                    Aplicar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* horizontal bar */}
          <div className={classes.horizontalBar} />
        </div>
        <div>
          <h2>Lista de usuários</h2>
          <CustomTable
            // firstPageAfterSearch={firstPageAfterSearch}
            columnsPointer={[0, 1]}
            columns={columns}
            border={'none'}
            bodyBorder={'none'}
            headCellRadius
            headColor={'rgba(173, 184, 204, 0.3)'}
            data={users.items}
            params={searchParams}
            fetchItems={getUsersHistory}
            isFetching={users.isFetching}
            pagination={users.pagination}
          />
        </div>
        <div>
          {/* <div className={classes.orderBy} style={{ marginBottom: '10px' }}>
              <label style={{ marginRight: 10 }} htmlFor='order_by'>
                Ordenação:{' '}
              </label>
              <Field
                component={ReactSelect}
                id='order_by'
                name='order_by'
                placeholder='Selecione...'
                defaultValue={fieldValues?.listItemValue || 'name asc'}
                handleChange={event => handleChange(event)}
                touch={touch}
                options={[
                  {
                    label: 'Nome (A-Z)',
                    value: 'name asc',
                    query: {
                      'q[s]': 'name asc'
                    }
                  },
                  {
                    label: 'Nome (Z-A)',
                    value: 'name desc',
                    query: {
                      'q[s]': 'name desc'
                    }
                  },
                  {
                    label: 'Data de acesso (Mais antigo)',
                    value: 'date asc',
                    query: {
                      'q[s]': 'last_access_at asc',
                      'q[m]': 'and',
                      'q[last_access_at_not_null]': true
                    }
                  },
                  {
                    label: 'Data de acesso (Mais recente)',
                    value: 'date desc',
                    query: {
                      'q[s]': 'last_access_at desc',
                      'q[m]': 'and',
                      'q[last_access_at_not_null]': true
                    }
                  },
                  {
                    label: 'Usuários nunca logados',
                    value: 'date null',
                    query: {
                      'q[last_access_at_not_null]': false
                    }
                  }
                ]}
              />
            </div> */}

          <Grid item xs={12} className={classes.btnStyle}>
            <Button
              className={classes.backBtn}
              variant='outlined'
              type='button'
              onClick={() => history.push('/settings')}
            >
              Voltar
            </Button>
            <Button
              style={{ display: 'none' }}
              type='button'
              className={classes.exportBtn}
              onClick={() => exportUsers()}
            >
              Exportar
            </Button>
          </Grid>
        </div>
      </Form>
      <Modal
        open={userDetailModal.isOpen}
        handleClose={() => setUserDetailModal({ isOpen: false, current: null })}
        width={'85%'}
        disablePadding
      >
        <div className={classes.modalRoot}>
          <CloseRoundedIcon
            onClick={() => setUserDetailModal({ isOpen: false, current: null })}
            className={classes.closeIcon}
          />
          <Grid className={classes.datasLabel} container item>
            <div className={classes.detailHeader}>
              <StyledSVG src={studentBookSVG} />
              <h1>Detalhes do perfil</h1>
            </div>
          </Grid>
          <Grid container style={{ rowGap: '10px' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className={`${classes.infoLabel} ${classes.userType}`}>
                <span>Usuário</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className={`${classes.infoLabel} ${classes.personalData}`}>
                <span>Dados pessoais</span>
              </div>
            </Grid>
            <Grid style={{ rowGap: 20 }} container>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className={classes.userInfo}>
                  <label>Nome</label>
                  <p>{userDetailModal?.current?.name || '-'}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className={classes.userInfo}>
                  <label>CPF</label>
                  <p>{userDetailModal?.current?.taxpayer_number || '-'}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className={classes.userInfo}>
                  <label>Último acesso</label>
                  <p>
                    {!userDetailModal?.current?.last_access_at
                      ? 'Sem registro de acesso do usuário.'
                      : lastAccessIsToday
                        ? `Hoje às ${moment(
                            userDetailModal?.current.last_access_at
                          ).format('HH:mm')}`
                        : lastAccessWasYesterday
                          ? `Ontem às ${moment(
                              userDetailModal?.current.last_access_at
                            ).format('HH:mm')}`
                          : `${moment(
                              userDetailModal?.current.last_access_at
                            ).format('DD')} de ${
                              months[
                                `${moment(
                                  userDetailModal?.current.last_access_at
                                ).format('MM')}`
                              ]
                            } de ${moment(
                              userDetailModal?.current.last_access_at
                            ).format('YYYY')} às ${moment(
                              userDetailModal?.current.last_access_at
                            ).format('HH:mm')}`}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className={classes.userInfo}>
                  <label>Habilitou notificação ?</label>
                  <p>
                    {userDetailModal?.current?.user_config?.notify_by_push
                      ? 'Sim'
                      : 'Não'}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className={classes.userInfo}>
                  <label>E-mail</label>
                  <p>{userDetailModal?.current?.email || '-'}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                <div className={classes.userInfo}>
                  <label>Contato</label>
                  <p>{userDetailModal?.current?.phone || '-'}</p>
                </div>
              </Grid>
              {!isEmpty(userDetailModal.current?.user?.address) && (
                <Grid container className={classes.addressSection}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div
                      className={`${classes.infoLabel} ${classes.addressInfo}`}
                    >
                      <span>Endereço</span>
                    </div>
                  </Grid>
                  <Grid container style={{ rowGap: '10px' }}>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <div className={classes.userInfo}>
                        <label>CEP</label>
                        <p>
                          {userDetailModal?.current?.user?.address?.zipcode ||
                            '-'}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <div className={classes.userInfo}>
                        <label>Rua</label>
                        <p>
                          {userDetailModal?.current?.user?.address?.street ||
                            '-'}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <div className={classes.userInfo}>
                        <label>Complemento</label>
                        <p>
                          {userDetailModal?.current?.user?.address
                            ?.complement || '-'}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <div className={classes.userInfo}>
                        <label>Bairro</label>
                        <p>
                          {userDetailModal?.current?.user?.address
                            ?.neighbourhood || '-'}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <div className={classes.userInfo}>
                        <label>Número</label>
                        <p>
                          {userDetailModal?.current?.user?.address?.number ||
                            '-'}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {userDetailModal.current?.children?.length > 0 && (
            <Grid container style={{ rowGap: '10px' }}>
              <Grid
                className={classes.childrenLabel}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              />
              <div className={`${classes.infoLabel} ${classes.userType}`}>
                <span>Aluno</span>
              </div>
              {userDetailModal.current?.children.map((child, index, self) => {
                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <Grid
                      style={{ rowGap: 10 }}
                      key={index}
                      className={`${classes.childSection} ${
                        openProfile?.[index]?.open ? classes.childAddress : ''
                      }`}
                      container
                    >
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <div className={classes.userInfo}>
                          <label>Nome do aluno</label>
                          <p>{child.name || '-'}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <div className={classes.userInfo}>
                          <label>CPF </label>
                          <p>{child?.taxpayer_number || '-'}</p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <div className={classes.userInfo}>
                          <label>Data de nascimento</label>
                          <p className={classes.childBirthdate}>
                            <StyledSVG
                              src={calendarSVG}
                              width={14}
                              height={14}
                            />
                            {child?.birthdate || '-'}
                          </p>
                        </div>
                      </Grid>
                      {child?.classrooms?.length > 0 && (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                          <div className={classes.userInfo}>
                            <label>Turma(s)</label>
                            <p>
                              {child?.classrooms.map((item, index, array) => {
                                if (index === array.length - 1) {
                                  return `${item.name} (${item.year})\n`
                                } else {
                                  return `${item.name} (${item.year}), \n`
                                }
                              })}
                            </p>
                          </div>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <div className={classes.userInfo}>
                          <label>Email</label>
                          <p>{child?.email || '-'}</p>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  // <div>
                  //   {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  //     <div className={`${classes.infoLabel} ${classes.userType}`}>
                  //       <span>Filho {self.length > 1 ? index + 1 : ''}</span>
                  //     </div>
                  //   </Grid> */}

                  // </div>
                )
              })}
            </Grid>
          )}
        </div>
      </Modal>
      <Modal
        open={temporaryPasswordModal}
        handleClose={handleCloseGenerateTemporaryPassword}
        width={isMobile ? '350px' : '25%'}
      >
        <div className={classes.temporaryPassword}>
          <h1>Gerar senha temporária:</h1>

          <div>
            <span>Nome:</span>
            <span>{tempUserData?.name}</span>
          </div>
          <div>
            <span>Email:</span>
            <span>{tempUserData?.email}</span>
          </div>
          <div>
            <span>Senha:</span>
            <div>
              <Input
                disabled={disabled}
                value={tempPassword}
                onChange={handlePasswordChange}
                placeholder='Digite a senha temporária'
              />
              {error && <p className={classes.errorMessage}>* {error}</p>}
            </div>
          </div>

          <div>
            <button disabled={disabled} onClick={sendTempPassword}>
              Enviar
            </button>
            <button onClick={handleCloseGenerateTemporaryPassword}>
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('searchUsersHistoryForm')
  return {
    users: state.users,
    school: state.school?.currentSchool?.school,
    fieldValues: getFormValues('searchUsersHistoryForm')(state),
    name: selector(state, 'name'),
    email: selector(state, 'email'),
    phone: selector(state, 'phone'),
    children_name: selector(state, 'children_name'),
    taxpayer_number: selector(state, 'taxpayer_number'),
    orderBy: selector(state, 'paramToOrder'),
    notificationCheckValue: selector(state, 'notificationCheck'),
    listItemValue: selector(state, 'listItemValue')
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) =>
    dispatch(change('searchUsersHistoryForm', field, data)),
  getUsersHistory: data => dispatch(getUsersHistory(data)),
  exportUsersHistoryRSPA: data =>
    dispatch(exportUsersHistoryRSPA.request(data)),
  generateTemporaryPassword: data =>
    dispatch(forceTemporaryPasswordRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'searchUsersHistoryForm'
  })(SearchUsersHistoryForm)
)
