import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import Card from '../card/Card'
import Label from '../label/Label'
import jsonApi from '../../utils/jsonApi'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '2rem',
    '& a': {
      textDecoration: 'none'
    },
    '& h3': {
      color: theme.palette.primary.main
    }
  },
  label: {
    marginBottom: '1.5rem'
  },
  control: {
    padding: theme.spacing(2)
  },
  list: {
    borderRadius: 8,
    maxWidth: 950,
    padding: '2rem 2.2rem 2rem 0'
  },
  cardButton: {
    '& div.MuiPaper-root': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 10
      }
    },
    '& div': {
      maxWidth: 200,
      height: 80,
      paddingTop: '1rem'
    }
  },
  title: {
    fontSize: 20,
    lineHeight: '22px',
    width: 120,
    textAlign: 'center'
  },
  segmentsContainer: {
    backgroundColor: '#FFF',
    borderRadius: '8px',
    boxShadow: '3px 11px 14px 6px rgb(107 103 103 / 10%)',
    marginBottom: '2rem',
    padding: '1rem 2rem 2rem',
    width: 'fit-content',
    '& > h2': {
      color: theme.palette.primary.main
    }
  },
  gradesContainer: {
    display: 'grid',
    gridGap: '30px',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)'
    }
  }
}))

const PlanningGradesAdminView = ({ school }) => {
  const classes = useStyles()
  const history = useHistory()
  const [isFetching, setIsFetching] = useState(true)
  const [segments, setSegments] = useState([])
  useEffect(() => {
    fetchSegments()
  }, [])

  const fetchSegments = async () => {
    setIsFetching(true)
    try {
      const response = await jsonApi
        .findAll('segments', {
          params: {
            'page[size]': 20
          }
        })
        .then(res => {
          return res.data
        })
      createNewSegments(response)
    } catch (e) {
      console.error(e)
      setIsFetching(false)
    }
  }

  const createNewSegments = async segments => {
    await segments.map(seg => {
      fetchGrades(seg.id).then(res => {
        seg.grades = res
        setSegments(prevState => [...prevState, { ...seg, grades: res }])
      })
      return seg
    })
    setIsFetching(false)
  }

  const fetchGrades = async segmentId => {
    try {
      return await jsonApi
        .findAll('grades', {
          params: {
            'q[segment_id_in]': segmentId,
            'page[size]': 20,
            include: 'schedule_templates'
          }
        })
        .then(response => {
          return response.data
        })
    } catch (e) {
      console.error(e)
      setIsFetching(false)
    }
  }

  if (isFetching) return <CircularProgress color='secondary' />

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.label}>
          <Label>Séries</Label>
        </Grid>
        <Grid item xs={9} sm={4} className={classes.list}>
          {segments
            .sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0))
            .map(segment => {
              return (
                segment.grades &&
                segment.grades.length > 0 && (
                  <div key={segment.id} className={classes.segmentsContainer}>
                    <h2>{segment?.name}</h2>
                    <div className={classes.gradesContainer}>
                      {segment.grades !== undefined
                        ? segment.grades.map(item => (
                          <Link
                            className={classes.cardButton}
                            key={item.id}
                            to={`${history.location.pathname}/${item.id}`}
                            >
                            <Card
                              color={
                                  item.schedule_templates.length > 0
                                    ? 'secondary'
                                    : 'default'
                                }
                              >
                              <CardContent>
                                <Typography
                                  className={classes.title}
                                  variant='h5'
                                  component='h2'
                                  >
                                  {item.name}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Link>
                          ))
                        : ''}
                    </div>
                  </div>
                )
              )
            })}
        </Grid>
      </Grid>
    </>
  )
}

export default PlanningGradesAdminView
