import { createReducer } from '@reduxjs/toolkit'
import * as types from './types'

const initialState = {
  grades: [],
  isFetching: false,
  isError: false,
  errorMessage: '',
  themeSchedules: [],
  current: {}
}

const gradesReducer = createReducer(initialState, {
  [types.GET_GRADES]: (state, action) => ({
    ...state,
    themeSchedules: [],
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_GRADES_SUCCESSFUL]: (state, action) => ({
    ...state,
    grades: action.payload,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_GRADES_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    grades: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_GRADES_BY_ID]: (state, action) => ({
    ...state,
    themeSchedules: [],
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_GRADES_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    current: action.payload,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_GRADES_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    grades: [],
    isError: true,
    current: {},
    errorMessage: action.payload
  }),
  [types.GET_GRADE_THEME_SCHEDULES]: (state, action) => ({
    ...state,
    themeSchedules: [],
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_GRADE_THEME_SCHEDULES_SUCCESSFUL]: (state, action) => ({
    ...state,
    themeSchedules: action.payload,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_GRADE_THEME_SCHEDULES_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    themeSchedules: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.CLEAN_GRADE]: (state, action) => ({
    ...initialState
  })
})

export default gradesReducer
