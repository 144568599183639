import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

import { getGradesById } from '../store/grades/actions'
import ThemeScheduleForm from '../components/form/ThemeScheduleForm'

const mapStateToProps = ({ school, grades }) => {
  return {
    currentGrade: grades.current,
    selectedSchoolItem: school.selectedItem,
    isFetching: grades.isFetching
  }
}

const mapDispatchToProps = {
  getGradesById
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 1220,
    marginBottom: '2rem',
    '& a': {
      textDecoration: 'none'
    },
    '& h3': {
      color: theme.palette.primary.main
    }
  }
}))

const ThemeScheduleDatePage = ({
  currentGrade,
  isFetching,
  getGradesById,
  selectedSchoolItem,
  match
}) => {
  const classes = useStyles()
  const history = useHistory()
  const themeSchedule = history.location?.state?.themeSchedule
  const theme = history.location?.state?.theme
  const cycle = history.location?.state?.cycle
  const { params: { gradeId } } = match

  useEffect(() => {
    if (gradeId) {
      getGradesById(gradeId)
    }
  }, [])

  if (isFetching) return <CircularProgress color='secondary' />

  return (
    <div className={classes.root}>
      <ThemeScheduleForm
        cycle={cycle}
        history={history}
        grade={currentGrade}
        initValues={{ schedule: themeSchedule, theme }}
        selectedSchool={selectedSchoolItem}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(
  ThemeScheduleDatePage
)
