import { createAction } from '@reduxjs/toolkit'
import * as types from './types'

export const getGrades = createAction(types.GET_GRADES)
export const getGradesSuccessful = createAction(types.GET_GRADES_SUCCESSFUL)
export const getGradesFailure = createAction(types.GET_GRADES_FAILURE)

export const getGradesById = createAction(types.GET_GRADES_BY_ID)
export const getGradesByIdSuccessful = createAction(
  types.GET_GRADES_BY_ID_SUCCESSFUL
)
export const getGradesByIdFailure = createAction(types.GET_GRADES_BY_ID_FAILURE)

export const getGradeThemeSchedules = createAction(
  types.GET_GRADE_THEME_SCHEDULES
)
export const getGradeThemeSchedulesSuccessful = createAction(
  types.GET_GRADE_THEME_SCHEDULES_SUCCESSFUL
)
export const getGradeThemeSchedulesFailure = createAction(
  types.GET_GRADE_THEME_SCHEDULES_FAILURE
)

export const cleanGrade = createAction(types.CLEAN_GRADE)
