import React, { useEffect } from 'react'
import './pages.css'
import { connect } from 'react-redux'

import Label from '../components/label/Label'
import Button from '../components/button/Button'
import Tab from '../components/tab/Tab'

import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

import {
  getModules,
  getModuleThemes,
  deleteModule
} from '../store/modules/actions'
import { deleteTheme } from '../store/themes/actions'

const useStyles = makeStyles(theme => ({
  wrapper: {
    background: 'white',
    borderRadius: '8px',
    boxShadow: '3px 11px 14px 6px rgba(107, 103, 103, 0.1)',
    padding: '1rem'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  createBtn: {
    backgroundColor: '#00ACDB',
    color: '#FFFF',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const mapStateToProps = ({ auth, modules, school }) => {
  return {
    user: auth.user,
    modules: modules.modules,
    isFetching: modules.isFetching,
    isError: modules.isError,
    moduleThemes: modules.currentItem.themes,
    moduleThemesIsFetching: modules.currentItem.isFetching,
    school: school.currentSchool,
    schoolIsFetching: school.isFetching,
    userOccupations: auth.currentOccupation
  }
}

const mapDispatchToProps = {
  getModules,
  getModuleThemes,
  deleteModule,
  deleteTheme
}

const ModulesPage = ({
  getModules,
  getModuleThemes,
  history,
  modules,
  moduleThemes,
  deleteModule,
  deleteTheme,
  search,
  isFetching,
  moduleThemesIsFetching,
  user,
  school,
  schoolIsFetching,
  userOccupations
}) => {
  const classes = useStyles()
  useEffect(
    () => {
      getModules(search)
    },
    [search]
  )
  const deleteItem = payload => {
    const { type, id, afterEffect } = payload
    if (type === 'modules') {
      deleteModule(id)
    } else if (type === 'themes') {
      deleteTheme({ id, afterEffect })
    }
  }
  const formattedData = modules?.data?.map(module => {
    const gradeId = module.attributes.grade_id
    const gradeInfo = modules.included.find(
      item => item.type === 'grades' && gradeId === item.id * 1
    )
    return {
      ...module,
      attributes: {
        ...module.attributes,
        grade: { ...gradeInfo.attributes }
      }
    }
  })
  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <Label>Módulos</Label>
        <Button
          data-testid='btn-create-module'
          className={classes.createBtn}
          startIcon={<AddIcon />}
          onclick={() => history.push('/modules/create')}
        >
          Criar Módulo
        </Button>
      </div>
      <Tab
        data={{ ...modules, data: formattedData }}
        itemsIsFetching={isFetching}
        fetchItems={getModules}
        getChildren={getModuleThemes}
        childs={moduleThemes}
        childTitle='TEMAS'
        deleteItem={deleteItem}
        hasChild
        childrenIsFetching={moduleThemesIsFetching}
        search={search}
        schoolIsFetching={schoolIsFetching}
        school={school?.school}
        userOccupations={user?.data?.attributes.occupations}
        extraContent={[
          {
            title: 'Séries',
            attr: 'grade'
          }
        ]}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ModulesPage)
