import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Label from '../components/label/Label'
import Form from '../components/form/CreateContentForm'
import { useHistory } from 'react-router-dom'

import {
  createContents,
  getContentById,
  editContent,
  deleteDocument,
  deleteAudio
} from '../store/contents/actions'
import { getSegments } from '../store/segments/actions'
import { getOccupations } from '../store/occupations/actions'
import { getCategories } from '../store/categories/actions'
import { CircularProgress } from '@material-ui/core'
import { roles } from '../utils/constants'
import { isEmpty } from 'lodash'

const mapDispatchToProps = {
  createContents,
  getSegments,
  getOccupations,
  getCategories,
  getContentById,
  editContent,
  deleteDocument,
  deleteAudio
}

const mapStateToProps = ({
  auth,
  contents,
  segments,
  occupations,
  categories,
  themes,
  school
}) => {
  return {
    userOccupations: auth.currentOccupation,
    isFetching: contents.isFetching,
    isError: contents.isError,
    segments: segments,
    occupations: occupations,
    categories: categories,
    currentContent: contents?.currentItem,
    currentTheme: themes?.currentItem,
    school: school.currentSchool
  }
}

const CreateContentsPage = ({
  createContents,
  segments,
  getSegments,
  getCategories,
  getOccupations,
  occupations,
  categories,
  isFetching,
  currentTheme,
  currentContent,
  userId,
  school,
  isError,
  match,
  getContentById,
  editContent,
  deleteDocument,
  deleteAudio,
  userOccupations
}) => {
  const history = useHistory()
  const [isEditing, setIsEditing] = React.useState(false)
  const isAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const contentId = match.params.id
  useEffect(async () => {
    if (match.params.id) {
      setIsEditing(true)
      await getContentById({ ids: match.params.id, isToEdit: true }) // editing
    }
  }, [])

  if (isEmpty(currentContent.item) && match.params.id) {
    return <CircularProgress color='secondary' />
  }

  if (!isEditing) {
    return (
      <div>
        <div
          style={{
            padding: '0 1rem'
          }}
        >
          <Label>Criar Novo Conteúdo</Label>
        </div>
        <Form
          history={history}
          isError={isError}
          isFetching={isFetching}
          segments={segments}
          getOccupations={getOccupations}
          getCategories={getCategories}
          occupations={occupations}
          categories={categories}
          currentTheme={currentTheme}
          currentContent={currentContent}
          createContents={createContents}
          school={school?.school}
          isAdmin={isAdmin}
        />
      </div>
    )
  } else {
    return (
      <div>
        <Label>Editar Conteúdo</Label>
        <Form
          history={history}
          isError={isError}
          isFetching={isFetching}
          contentId={contentId}
          segments={segments}
          getOccupations={getOccupations}
          getCategories={getCategories}
          occupations={occupations}
          categories={categories}
          currentTheme={currentTheme}
          currentContent={currentContent}
          editContent={editContent}
          createContents={createContents}
          school={school?.school}
          deleteDocument={deleteDocument}
          deleteAudio={deleteAudio}
          initialValues={currentContent && currentContent.item}
          isAdmin={isAdmin}
          isEditing
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateContentsPage)
