import React from 'react'
import { useDispatch } from 'react-redux'
import AnnouncementDetailsView from '../components/view/AnnouncementDetailsView'
import { getEventDetailsRSPA } from '../store/events/actions'

const AnnouncementDetailsPage = ({ match }) => {
  const dispatch = useDispatch()
  const { eventId } = match.params
  const [eventDetail, setEventDetail] = React.useState()

  React.useEffect(
    () => {
      if (eventId) {
        dispatch(
          getEventDetailsRSPA.request({
            eventId
          })
        )
          .then(res => {
            setEventDetail(res)
          })
          .catch(err => {
            console.error(err)
          })
      }
    },
    [eventId]
  )

  return <AnnouncementDetailsView eventId={eventId} eventDetail={eventDetail} />
}

export default AnnouncementDetailsPage
