import React from 'react'
import { makeStyles } from '@material-ui/core'
import StyledSVG from '../shared/StyledSVG'
import gradeSVG from '../../assets/icons/grade-icon.svg'
import timerSVG from '../../assets/icons/timer.svg'
import peopleSVG from '../../assets/icons/people.svg'
import calendarSVG from '../../assets/icons/calendar.svg'

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    marginBottom: 16,
    maxWidth: `${window.outerWidth}px !important`,
    '& p#classroom': {
      marginBottom: 0,
      fontSize: '2em',
      fontWeight: 600,
      color: '#4D5E80'
    },
    [theme.breakpoints.down('426')]: {
      flexWrap: 'wrap',
      '& p#classroom': {
        fontSize: '16px !important'
      }
    }
  },
  labelGrade: {
    display: 'flex',
    justifyContent: props => (props.showAll ? 'space-between' : 'flex-start'),
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start !important',
      '& p': {
        marginLeft: '5px',
        marginRight: '57px',
        fontSize: 18
      }
    },
    [theme.breakpoints.down('426')]: {
      flexWrap: 'wrap',
      marginTop: '15px',
      justifyContent: 'space-between !important'
    }
  },
  dashedBorder: {
    marginTop: '25px',
    width: '100%',
    height: '0px',
    border: '1px dashed #ADB8CC'
  },
  elementInfo: {
    '& p': {
      color: '#ADB8CC'
    },
    [theme.breakpoints.down('426')]: {
      '& p': {
        fontSize: '12px !important',
        margin: '0px 12px 0px 12px !important',
        lineHeight: '14px'
      },
      justifyContent: 'flex-start'
    }
  },
  infoGroup: {
    [theme.breakpoints.down('426')]: {
      flexDirection: 'column !important',
      alignItems: 'unset !important',
      '& div.elementInfo': {
        flexDirection: 'row'
      }
    }
  }
}))

const ReportTemplateLabel = props => {
  const classes = useStyles({ showAll: props.showAllInfos })
  if (props.classroom) {
    const getTeacherName = fullName => {
      if (fullName) {
        const getInitialsOfName = name => {
          const names = name.split(' ')
          const initials = names
            .slice(1)
            .map(name => name.charAt(0).toUpperCase() + '.')
          return initials.join(' ')
        }

        const names = fullName.split(' ')
        const firstName = names[0]
        const otherInitials = getInitialsOfName(fullName)
        return firstName + ' ' + otherInitials
      } else {
        return ''
      }
    }

    return (
      <div className={classes.label}>
        <p id='classroom'>{props.classroom}</p>
        <div className={classes.labelGrade}>
          <div className={classes.infoGroup}>
            <div
              style={!props.grade ? { display: 'none' } : {}}
              className={classes.elementInfo}
            >
              <StyledSVG height={18} width={18} src={gradeSVG} />
              <p>{`Série: ${props.grade}`}</p>
            </div>
            <div
              style={!props.shift ? { display: 'none' } : {}}
              className={classes.elementInfo}
            >
              <StyledSVG height={18} width={18} src={timerSVG} />
              <p>{`Turno: ${props.shift}`}</p>
            </div>
          </div>
          <div className={classes.infoGroup}>
            <div
              style={!props.lastTeacher ? { display: 'none' } : {}}
              className={classes.elementInfo}
            >
              <StyledSVG height={18} width={18} src={peopleSVG} />
              <p>{`Último Prof.: ${getTeacherName(
                props.lastTeacher?.attributes?.name
              )}`}</p>
            </div>
            <div
              style={!props.lastReportTemplateDate ? { display: 'none' } : {}}
              className={classes.elementInfo}
            >
              <StyledSVG height={18} width={18} src={calendarSVG} />
              <p>{`Última atualização: ${props.lastReportTemplateDate}`}</p>
            </div>
          </div>
        </div>
        <div className={classes.dashedBorder} />
      </div>
    )
  } else return <></>
}

export default ReportTemplateLabel
