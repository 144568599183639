import { createReducer } from '@reduxjs/toolkit'
import * as types from './types'

const initialState = {
  items: [],
  isFetching: false,
  isError: false,
  errorMessage: '',
  currentItem: {},
  pagination: {
    total: 1,
    pageCount: 1
  },
  deletedTeachers: {
    items: [],
    pagination: {
      total: 1,
      pageCount: 1
    }
  }
}

const teachersReducer = createReducer(initialState, {
  [types.GET_TEACHERS]: (state, action) => ({
    ...state,
    currentItem: {},
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_TEACHERS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: action.payload.data,
    pagination: {
      total: action.payload.meta.total_count,
      pageCount: action.payload.meta.page_count
    },
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_TEACHERS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_DELETED_TEACHERS]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: '',
    deletedTeachers: {}
  }),
  [types.GET_DELETED_TEACHERS_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    deletedTeachers: {
      items: [...action.payload.data],
      pagination: {
        total: action.payload.meta.total_count,
        pageCount: action.payload.meta.page_count
      }
    }
  }),
  [types.GET_DELETED_TEACHERS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: ''
  }),
  [types.EDIT_TEACHER]: (state, action) => ({
    ...state,
    isFetching: true,
    currentItem: {},
    isError: false,
    errorMessage: ''
  }),
  [types.EDIT_TEACHER_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.EDIT_TEACHER_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_TEACHER_BY_ID]: (state, action) => ({
    ...state,
    isFetching: true,
    currentItem: {},
    isError: false,
    errorMessage: ''
  }),
  [types.GET_TEACHER_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: action.payload,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_TEACHER_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    currentItem: {},
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.REMOVE_CLASSROOM_TEACHERS]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.REMOVE_CLASSROOM_TEACHERS_SUCCESSFUL]: (state, action) => ({
    ...state,
    // items: action.payload.data,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.REMOVE_CLASSROOM_TEACHERS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.REMOVE_TEACHER_SUBJECT]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.REMOVE_TEACHER_SUBJECT_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.REMOVE_TEACHER_SUBJECT_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  })
})

export default teachersReducer
