import { createAction } from '@reduxjs/toolkit'
import {
  GET_SEGMENTS,
  GET_SEGMENTS_SUCCESSFUL,
  GET_SEGMENTS_FAILURE
} from './types'

export const getSegments = createAction(GET_SEGMENTS)
export const getSegmentsSuccessful = createAction(GET_SEGMENTS_SUCCESSFUL)
export const getSegmentsFailure = createAction(GET_SEGMENTS_FAILURE)
