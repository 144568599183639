import React from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  paper: {
    padding: '6px 16px'
  },
  secondaryTail: {
    backgroundColor: '#FFF',
    borderLeft: `1px dashed ${theme.palette.primary.main}`
  },
  content: {
    padding: '1px 16px'
  },
  icon: {
    fontSize: 20,
    padding: 2
  }
}))

export default function BasicTimeline ({ children, icon }) {
  const classes = useStyles()

  return (
    <Timeline className={classes.root}>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color='primary' className={classes.icon}>
            {icon}
          </TimelineDot>
          <TimelineConnector className={classes.secondaryTail} />
        </TimelineSeparator>
        <TimelineContent className={classes.content}>
          {children}
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  )
}
