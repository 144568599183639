import React from 'react'
import PropTypes from 'prop-types'
import { AsyncPaginate } from 'react-select-async-paginate'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import jsonApi from '../../utils/jsonApi'

const StudentsAsyncSelect = ({
  request,
  searchParam,
  placeholder,
  handleAsyncChange,
  defaultValue,
  isMulti,
  disabled,
  meta: { touched, error },
  input,
  touch,
  defaultOptions = true,
  ...rest
}) => {
  const [hover, setHover] = React.useState(false)
  const generalTheme = useTheme()
  const useStyles = makeStyles(theme => ({
    rootDiv: {
      marginBottom: 14
    },
    root: {
      minWidth: 311,
      maxWidth: 540,
      border: props => props.borderColor,
      backgroundColor: '#FFF',
      borderRadius: '10px',
      [theme.breakpoints.up('sm')]: {
        minWidth: 350
      },
      [theme.breakpoints.down('426')]: {
        width: 255
      },
      '& .select__input > input': {
        height: isMulti ? 0 : 38,
        paddingBottom: 12
      },
      '& .select__control, .select__value-container': {
        height: !isMulti && '2.5rem',
        minHeight: '2.5rem',
        paddingTop: 0,
        borderRadius: '10px !important',
        color: '#FFF'
      },
      '& .select__control': {
        border: 'none !important',
        borderRadius: '10px',
        minHeight: '48px',
        alignContent: 'center'
      },
      '& .select__placeholder': {
        color: '#e4e4e4',
        fontSize: '1rem'
      },
      '& .select__indicators': {
        height: 38
      },
      '& .select__single-value': {
        paddingTop: '7px',
        color: theme.palette.text.primary
      },
      '& .select__option, .select__single-value': {
        whiteSpace: 'pre-line !important'
      }
    },
    error: {
      color: theme.palette.danger.main,
      fontSize: '0.8rem'
    },
    indicators: {
      padding: '5px 0',
      '& > button.MuiIconButton-root': {
        color: theme.palette.primary.dark,
        padding: '10px 7px'
      }
    }
  }))

  const classes = useStyles({
    borderColor:
      touched && error
        ? `1px solid ${generalTheme.palette.danger.main} !important`
        : hover
          ? `1px solid ${generalTheme.palette.primary.light}`
          : '1px solid #ADB8CC'
  })
  const loadOptions = async (search, loadedOptions = '', { page }) => {
    const hasSearchParam = searchParam ? { [searchParam]: search } : ''
    const hasInclude =
      request.params && request.params.hasOwnProperty('include')
    const requestParams = {
      ...hasSearchParam,
      ...request.params,
      'page[number]': page
    }
    const response = await jsonApi
      .findAll(request.path, { params: requestParams })
      .then(res => res)
    let options = []
    response.data.forEach(data => {
      if (hasInclude) {
        // to use when the displayed value is on the include
        const newOptions = data.children.map(child => {
          return !child.user_id
            ? child.parents.map(parent => ({
                label: parent.name + ` \n (Responsável de ${child.name})`,
                value: parent.id
              }))
            : [{ label: child.name, value: child.user_id }].concat(
                child.parents.map(parent => ({
                  label: parent.name + ` \n (Responsável de ${child.name})`,
                  value: parent.id
                }))
              )
        })
        options = newOptions.flat()
      }
    })
    // filter duplicate values
    const optionsFilter = options.filter(
      (element, index, self) =>
        index === self.findIndex(seg => seg.value === element.value)
    )
    return {
      options: optionsFilter,
      hasMore: response.data.length >= 1,
      additional: {
        page: page + 1
      }
    }
  }

  const wrappedLoadOptions = (...args) => {
    return loadOptions(...args)
  }

  const ClearIndicator = ({ innerRef, innerProps }) => (
    <span ref={innerRef} {...innerProps} className={classes.indicators}>
      <IconButton>
        <HighlightOffIcon />
      </IconButton>
    </span>
  )

  const DropdownIndicator = ({ innerRef, innerProps }) => (
    <span ref={innerRef} {...innerProps} className={classes.indicators}>
      <IconButton>
        <ArrowDropDownIcon />
      </IconButton>
    </span>
  )

  return (
    <div
      className={classes.rootDiv}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <AsyncPaginate
        classNamePrefix='select'
        key={`${input.name}[${rest?.cacheUniqs}]`}
        debounceTimeout={500}
        cacheOptions
        onBlurResetsInput
        className={classes.root}
        defaultOptions={defaultOptions}
        onMouse
        isDisabled={disabled}
        isMulti={isMulti}
        isClearable
        closeMenuOnSelect={!isMulti}
        components={{
          IndicatorSeparator: () => null,
          ClearIndicator,
          DropdownIndicator
        }}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Não encontrado'}
        loadOptions={wrappedLoadOptions}
        minMenuHeight={50}
        onChange={handleAsyncChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onFocus={e => {
          touch(input.name)
        }}
        name={input.name}
        {...rest}
        additional={{
          page: 1
        }}
      />
      {touched && error && <span className={classes.error}>{error}</span>}
    </div>
  )
}

StudentsAsyncSelect.propTypes = {
  request: PropTypes.object.isRequired,
  searchParam: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.any,
  handleAsyncChange: PropTypes.func,
  meta: PropTypes.object,
  input: PropTypes.object,
  touch: PropTypes.any.isRequired,
  borderColor: PropTypes.string,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultOptions: PropTypes.bool
}

export default StudentsAsyncSelect
