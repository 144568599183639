import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  Form,
  reduxForm,
  Field,
  formValueSelector,
  change,
  getFormValues
} from 'redux-form'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Tooltip } from '@material-ui/core'
import StyledSVG from '../shared/StyledSVG'

import {
  removeClassroomTeachers,
  editTeacher
} from './../../store/teachers/actions'
import { showAlertMessage } from '../../store/alert/actions'
import { getClassroomTeacherByUserIdRSPA } from '../../store/classrooms/actions'
import http from '../../utils/http'

// import ConfirmModal from '../modal/ConfirmModal'
import Modal from '../modal/Modal'
import CustomTable from '../table/CustomTable'
import Button from '../button/Button'
import AsyncSelect from '../shared/AsyncSelect'
import MaterialInput from '../shared/MaterialInput'
import editIntoModalSVG from '../../assets/icons/edit-pencil.svg'
import filterSVG from '../../assets/icons/filter-icon.svg'
import deleteSVG from '../../assets/icons/pattern-delete-icon.svg'
import editSVG from '../../assets/icons/pattern-edit-icon.svg'
import viewSVG from '../../assets/icons/pattern-view-icon.svg'
import studentBookSVG from '../../assets/icons/student-details-icon.svg'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

const useStyles = makeStyles(theme => ({
  btnStyle: {
    marginTop: 18,
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'center',
    textAlign: 'right',
    gap: 10,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& button': {
        width: 240
      }
    }
  },
  textModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  cancelBtn: {
    backgroundColor: '#EF7C8F',
    width: 136,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  confirmBtn: {
    backgroundColor: '#386093',
    color: '#FFFF',
    width: 165,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  createBtn: {
    backgroundColor: '#386093',
    color: '#FFFF',
    width: 230,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  reactiveBtn: {
    backgroundColor: '#F4A87C',
    width: 247,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#F4A87C'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  actionsSection: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    width: '55%',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& path': {
      stroke: '#868E96'
    }
  },
  root: {
    width: '100%',
    padding: '40px 30px',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  pageLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 20,
    '& h1': {
      fontSize: 32,
      color: '#386093',
      fontWeight: 700,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  searchSection: {
    marginTop: 40
  },
  searchDiv: {
    '& .MuiInputBase-root': {
      width: 300,
      [theme.breakpoints.down('426')]: {
        maxWidth: '250px'
      },
      [theme.breakpoints.down('330')]: {
        maxWidth: 220
      }
    }
  },
  filtersSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  filterLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  advancedFilter: {
    overflow: 'hidden',
    transition: 'height 0.5s ease-in-out',
    [theme.breakpoints.down('1000')]: {
      overflow: 'scroll'
    }
  },
  filtersDisplay: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24
  },
  searchGroup: {
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    gap: 24,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      gap: 12
    }
  },
  searchField: {
    '& .select__menu-list': {
      maxHeight: '150px'
    },
    '& div': {
      '& .select__control': {
        minWidth: 200
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        minWidth: 'unset',
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    },
    [theme.breakpoints.down('1000')]: {
      width: '100% !important',
      '& div': {
        // redux form 'Field' componente
        '& div': {
          '& .select__control, .select__value-container, .select__indicators, .MuiInputBase-root': {
            minHeight: '35px',
            height: '35px'
          },
          maxWidth: '100%'
        }
      }
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: 8,
    '& button': {
      marginRight: 0,
      width: 149
    },
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'flex-start',
      marginTop: 15,
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    [theme.breakpoints.down('350')]: {
      '& button': {
        width: '170px !important'
      }
    }
  },
  clearBtn: {
    backgroundColor: '#EF7C8F',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  horizontalBar: {
    marginBottom: 27,
    width: '96%',
    height: 1,
    border: '1px solid #ADB8CC'
  },
  modalRoot: {
    padding: '40px',
    position: 'relative'
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  datasLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
    [theme.breakpoints.down('500')]: {
      justifyContent: 'center'
    }
  },
  detailHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 20,
    '& h1': {
      fontSize: 24,
      fontWeight: 700,
      color: '#386093',
      [theme.breakpoints.down('360')]: {
        fontSize: 19
      }
    }
  },
  infoLabel: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  userType: {
    marginBottom: 20,
    '& span': {
      fontSize: '18px !important',
      fontWeight: '600 !important',
      color: '#386093 !important'
    }
  },
  personalData: {
    marginBottom: 10,
    color: '#555555',
    fontWeight: 600
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& label': {
      color: '#9F9F9F',
      fontWeight: 500,
      fontSize: 16
    },
    '& p': {
      margin: 0,
      color: '#666666',
      fontWeight: 700,
      fontSize: 16
    }
  },
  editButton: {
    '& button': {
      border: '2px solid #386093',
      color: '#386093',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: 'transparent',
        border: '2px solid #386093'
      }
    }
  },
  editLabel: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
    color: '#386093'
  }
}))

const SearchTeacherForm = ({
  teachers,
  getTeachers,
  nameValue,
  emailValue,
  school,
  isAdmin,
  alert,
  removeClassroomTeachers,
  editTeacher,
  getClassroomTeacherByUserIdRSPA,
  touch,
  subjectValue,
  classroomValue,
  fieldValues,
  change,
  reset
}) => {
  const classes = useStyles()
  const history = useHistory()
  const paramsRequest = {
    'q[teacher_name_cont]': nameValue,
    'q[teacher_email_cont]': emailValue,
    'q[teacher_subjects_subject_id_in]': subjectValue,
    'q[teacher_classroom_teachers_classroom_id_in]': classroomValue,
    'q[school_id_eq]': school?.school_id,
    'q[s]': 'created_at desc'
  }
  const [schoolTeacher, setSchoolTeacher] = React.useState(null)
  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    title: null,
    id: null
  })
  const [differenteSchools, setDifferenteSchools] = React.useState([])
  const [openMoreFilter, setOpenMoreFilter] = React.useState(false)
  const [userDetailModal, setUserDetailModal] = React.useState({
    isOpen: false,
    current: null
  })
  const handleSearch = () => {
    school && getTeachers({ params: paramsRequest })
  }
  const handleUserDetails = row => {
    setUserDetailModal(prev => ({ isOpen: !prev.isOpen, current: row }))
  }
  const selectNameRef = React.useRef(null)
  const selectSubjectRef = React.useRef(null)
  const selectClassroomRef = React.useRef(null)
  const selectEmailRef = React.useRef(null)
  const columns = [
    {
      key: 'name',
      name: 'Nome',
      // width: '40%',
      render: row => {
        return row.teacher?.name
      }
    },
    {
      key: 'email',
      name: 'Email',
      // width: '20%',
      render: row => {
        return row.teacher?.email
      }
    },
    {
      key: 'subjects',
      name: 'Disciplina(s)',
      // width: '20%',
      render: row => {
        const subjects = row?.teacher_subjects.map((item, index, array) => {
          if (index === array.length - 1) {
            return `${item?.subject?.name}\n`
          } else {
            return `${item?.subject?.name}, \n`
          }
        })
        return subjects
      }
    },
    {
      key: 'classrooms',
      name: 'Turma(s)',
      width: '40%',
      render: row => {
        // aqui será pego também as escolas, diferentes da escola logada, que o professor está vinculado.
        const classrooms = row?.teacher?.classroom_teachers
          ?.filter(item => {
            return item?.classroom?.school_id === school?.school_id
          })
          .map((item, index, array) => {
            if (index === array.length - 1) {
              return `${item?.classroom?.name}\n`
            } else {
              return `${item?.classroom?.name}, \n`
            }
          })
        return classrooms
      }
    },
    {
      key: 'school',
      name: 'Escola',
      // width: '10%',
      render: row => {
        return row.school?.name
      }
    },
    {
      key: 'actions',
      name: 'Ações',
      // width: '20%',
      align: 'right',
      render: function render (row) {
        return (
          <div className={classes.actionsSection}>
            <Tooltip title='Visualizar'>
              <div
                onClick={() => {
                  handleUserDetails(row)
                }}
              >
                <StyledSVG src={viewSVG} width={20} height={20} />
              </div>
            </Tooltip>
            {isAdmin && (
              <Tooltip title='Editar'>
                <div
                  onClick={() =>
                    history.push(`${history.location.pathname}/${row.id}/edit`)
                  }
                >
                  <StyledSVG src={editSVG} width={20} height={20} />
                </div>
              </Tooltip>
            )}
            {isAdmin && (
              <Tooltip title='Remover professores'>
                <div
                  onClick={async () => {
                    await http
                      .get('/school_teachers', {
                        params: {
                          'q[user_id_eq]': row.teacher?.id
                        }
                      })
                      .then(async res => {
                        setSchoolTeacher(prev => {
                          return res.data.data.filter(item => {
                            return (
                              item?.attributes?.school_id === school?.school_id
                            )
                          })
                        })
                        setDifferenteSchools(prev => {
                          return res.data.data.filter(item => {
                            return (
                              item?.attributes?.school_id !== school?.school_id
                            )
                          })
                        })
                      })
                    setConfirmModal({
                      id: row.teacher?.id,
                      title: row.teacher?.name,
                      open: true
                    })
                  }}
                >
                  <StyledSVG src={deleteSVG} width={20} height={20} />
                </div>
              </Tooltip>
            )}
          </div>
        )
      }
    }
  ]

  const getColumns = () => {
    const columnKeys = isAdmin
      ? ['name', 'email', 'classrooms', 'subjects', 'school', 'actions']
      : ['name', 'email', 'classrooms', 'subjects', 'actions']

    return columns.filter(column => columnKeys.includes(column.key))
  }

  const handleClearFields = () => {
    reset()
    selectNameRef.current?.select.clearValue()
    selectSubjectRef.current?.select.clearValue()
    selectClassroomRef.current?.select.clearValue()
    selectEmailRef.current?.select.clearValue()
    getTeachers({
      params: {
        'q[school_id_eq]': school?.school_id,
        'q[s]': 'created_at desc'
      }
    })
  }

  const handleChangeTitle = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      getTeachers({ params: paramsRequest })
    }
  }

  const handleChange = (event, input) => {
    touch()
    let newValue
    if (Array.isArray(event)) {
      newValue = event?.map(v => v)
    } else {
      newValue = event ? event.value : ''
    }
    change(input.name, newValue)
  }

  return (
    <div className={classes.root}>
      <Form>
        <div className={classes.pageLabel}>
          <h1>Professores</h1>
          <span>Veja a lista de professores disponíveis na plataforma</span>
        </div>
        <div className={classes.searchSection}>
          <div className={classes.searchDiv}>
            <strong>Busca</strong>
            <Field
              style={{ marginTop: 10 }}
              component={MaterialInput}
              selectRef={selectNameRef}
              name='name'
              endBtnFunction={() => {
                getTeachers({ params: paramsRequest })
              }}
              onKeyDown={handleChangeTitle}
              placeholder={'Nome do professor'}
              type='text'
            />
          </div>
          <div className={classes.filtersSection}>
            <div className={classes.filterLabel}>
              <StyledSVG src={filterSVG} width={12} height={12} />
              <p
                onClick={() => setOpenMoreFilter(prev => !prev)}
                style={{
                  cursor: 'default',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#386093'
                }}
              >
                Filtros avançados
              </p>
              {!openMoreFilter ? (
                <KeyboardArrowDownIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              )}
            </div>
            <div
              className={classes.advancedFilter}
              style={{
                height: openMoreFilter ? '250px' : '0px'
              }}
            >
              <div className={classes.filtersDisplay}>
                <div
                  className={classes.searchGroup}
                  style={{ opacity: openMoreFilter ? '1' : '0' }}
                >
                  <div className={classes.searchField}>
                    <label htmlFor='email'>E-mail</label>
                    <Field
                      component={MaterialInput}
                      name='email'
                      selectRef={selectEmailRef}
                      placeholder='Digite um nome'
                      type='email'
                    />
                  </div>
                  <div className={classes.searchField}>
                    <label>Disciplina</label>
                    <Field
                      component={AsyncSelect}
                      selectRef={selectSubjectRef}
                      id='subject_id'
                      name='subject_id'
                      placeholder='Digite ou selecione a(s) turma(s)'
                      handleAsyncChange={handleChange}
                      searchParam='q[name_cont]'
                      request={{
                        path: 'subjects',
                        params: {
                          'q[school_id_in]': school?.school_id
                        }
                      }}
                      touch={touch}
                    />
                  </div>
                  <div className={classes.searchField}>
                    <label>Turma</label>
                    <Field
                      component={AsyncSelect}
                      defaultOptions={false}
                      id='classroom_id'
                      name='classroom_id'
                      selectRef={selectClassroomRef}
                      placeholder='Digite ou selecione'
                      handleAsyncChange={handleChange}
                      searchParam='q[name_cont]'
                      request={{
                        path: 'classroom',
                        params: {
                          'q[school_id_in]': school?.school_id,
                          'q[year_lteq]': new Date().getFullYear() + 1,
                          'q[year_gteq]': new Date().getFullYear()
                        }
                      }}
                      touch={touch}
                    />
                  </div>
                </div>
                <div
                  style={{
                    opacity: openMoreFilter ? '1' : '0',
                    transition: 'opacity 0.3s ease-in-out'
                  }}
                  className={classes.buttons}
                >
                  <Button
                    onClick={() => handleClearFields()}
                    className={classes.clearBtn}
                  >
                    Limpar
                  </Button>
                  <Button
                    className={classes.applyBtn}
                    type='button'
                    onClick={() => handleSearch()}
                  >
                    Aplicar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.horizontalBar} />
        </div>
        <div>
          <h2>Resultados</h2>
          <CustomTable
            columns={getColumns()}
            border={'none'}
            bodyBorder={'none'}
            headCellRadius
            headColor={'rgba(173, 184, 204, 0.3)'}
            data={teachers.items}
            params={paramsRequest}
            fetchItems={getTeachers}
            isFetching={teachers.isFetching}
            pagination={teachers.pagination}
          />
        </div>
        <div>
          <Grid item xs={12} className={classes.btnStyle}>
            <Button
              className={classes.backBtn}
              variant='outlined'
              type='button'
              onClick={() => history.push('/settings')}
            >
              Voltar
            </Button>
            {isAdmin && (
              <>
                <Button
                  className={classes.reactiveBtn}
                  type='button'
                  onClick={() =>
                    history.push(`${history.location.pathname}/reactivate`)
                  }
                >
                  Reativar docente
                </Button>
                <Button
                  className={classes.createBtn}
                  type='button'
                  onClick={() =>
                    history.push(`${history.location.pathname}/new`)
                  }
                >
                  Cadastrar docente
                </Button>
              </>
            )}
          </Grid>
        </div>
        <Modal
          open={confirmModal.open}
          handleClose={() =>
            setConfirmModal({ id: null, title: '', open: false })
          }
        >
          {differenteSchools.length > 0 ? (
            <div className={classes.textModal}>
              <h1>Atenção!</h1>
              <h2>
                {`O docente ${confirmModal.title} possui cadastro ativo em mais
                ${
                  differenteSchools.length
                } escola(s) e seu desligamento refletirá
                apenas no(a) ${school.school.name}.`}
              </h2>
            </div>
          ) : (
            <div className={classes.textModal}>
              <h1>Atenção!</h1>
              <h2>
                Ao confirmar {confirmModal.title} será desligado da escola.
              </h2>
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
            <Button
              className={classes.cancelBtn}
              onClick={() => {
                setConfirmModal({ id: null, title: '', open: false })
              }}
            >
              Cancelar
            </Button>
            <Button
              className={classes.confirmBtn}
              onClick={async () => {
                await http.delete(`school_teachers/${schoolTeacher[0]?.id}`)
                let classroomTeachersIds
                await getClassroomTeacherByUserIdRSPA({
                  userId: confirmModal.id
                }).then(res => {
                  classroomTeachersIds = res?.data
                    ?.map(item => {
                      if (item?.classroom?.school_id === school?.school_id) {
                        return item?.id
                      }
                    })
                    .filter(item => item !== undefined)
                })

                classroomTeachersIds?.length > 0 &&
                  removeClassroomTeachers({ ids: classroomTeachersIds })
                setConfirmModal({ id: null, title: '', open: false })
                getTeachers({ params: paramsRequest })
              }}
            >
              Confirmar
            </Button>
          </div>
        </Modal>
        <Modal
          open={userDetailModal.isOpen}
          handleClose={() =>
            handleUserDetails({ isOpen: false, current: null })
          }
          width={'85%'}
          disablePadding
        >
          <div className={classes.modalRoot}>
            <CloseRoundedIcon
              onClick={() =>
                handleUserDetails({ isOpen: false, current: null })
              }
              className={classes.closeIcon}
            />
            <Grid className={classes.datasLabel} container item>
              <div className={classes.detailHeader}>
                <StyledSVG src={studentBookSVG} />
                <h1>Dados cadastrais</h1>
              </div>
              <div className={classes.editButton}>
                <Button variant='outlined'>
                  <div
                    className={classes.editLabel}
                    onClick={() => {
                      history.push(
                        `${history.location.pathname}/${
                          userDetailModal?.current?.id
                        }/edit`
                      )
                    }}
                  >
                    <StyledSVG width={14} height={14} src={editIntoModalSVG} />
                    <span>Editar</span>
                  </div>
                </Button>
              </div>
            </Grid>
            <Grid container style={{ rowGap: '10px' }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={`${classes.infoLabel} ${classes.userType}`}>
                  <span>Professor</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={`${classes.infoLabel} ${classes.personalData}`}>
                  <span>Dados pessoais</span>
                </div>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <div className={classes.userInfo}>
                    <label>Nome do professor</label>
                    <p>{userDetailModal?.current?.teacher?.name}</p>
                  </div>
                </Grid>
                {userDetailModal?.current?.teacher?.taxpayer_number && (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <div className={classes.userInfo}>
                      <label>CPF</label>
                      <p>
                        {userDetailModal?.current?.teacher?.taxpayer_number}
                      </p>
                    </div>
                  </Grid>
                )}
                {userDetailModal?.current?.teacher?.email && (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <div className={classes.userInfo}>
                      <label>E-mail</label>
                      <p>{userDetailModal?.current?.teacher?.email}</p>
                    </div>
                  </Grid>
                )}
                {userDetailModal?.current?.teacher?.phone && (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <div className={classes.userInfo}>
                      <label>Contato</label>
                      <p>{userDetailModal?.current?.teacher?.phone}</p>
                    </div>
                  </Grid>
                )}
                {userDetailModal?.current?.teacher?.address?.zipcode && (
                  <Grid container className={classes.addressSection}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div
                        className={`${classes.infoLabel} ${
                          classes.addressInfo
                        }`}
                      >
                        <span>Endereço</span>
                      </div>
                    </Grid>
                    <Grid container style={{ rowGap: '10px' }}>
                      {userDetailModal?.current?.teacher?.address?.zipcode && (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                          <div className={classes.userInfo}>
                            <label>CEP</label>
                            <p>
                              {
                                userDetailModal?.current?.teacher?.address
                                  ?.zipcode
                              }
                            </p>
                          </div>
                        </Grid>
                      )}
                      {userDetailModal?.current?.teacher?.address?.street && (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                          <div className={classes.userInfo}>
                            <label>Rua</label>
                            <p>
                              {
                                userDetailModal?.current?.teacher?.address
                                  ?.street
                              }
                            </p>
                          </div>
                        </Grid>
                      )}
                      {userDetailModal?.current?.teacher?.address
                        ?.complement && (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                          <div className={classes.userInfo}>
                            <label>Complemento</label>
                            <p>
                              {
                                userDetailModal?.current?.teacher?.address
                                  ?.complement
                              }
                            </p>
                          </div>
                        </Grid>
                      )}
                      {userDetailModal?.current?.teacher?.address
                        ?.neighbourhood && (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                          <div className={classes.userInfo}>
                            <label>Bairro</label>
                            <p>
                              {
                                userDetailModal?.current?.teacher?.address
                                  ?.neighbourhood
                              }
                            </p>
                          </div>
                        </Grid>
                      )}
                      {userDetailModal?.current?.teacher?.address?.number && (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                          <div className={classes.userInfo}>
                            <label>Número</label>
                            <p>
                              {
                                userDetailModal?.current?.teacher?.address
                                  ?.number
                              }
                            </p>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </Modal>
      </Form>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('searchTeacherForm')
  return {
    nameValue: selector(state, 'name'),
    emailValue: selector(state, 'email'),
    subjectValue: selector(state, 'subject_id'),
    classroomValue: selector(state, 'classroom_id'),
    fieldValues: getFormValues('searchTeacherForm')(state)
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('searchTeacherForm', field, data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    ),
  removeClassroomTeachers: data => dispatch(removeClassroomTeachers(data)),
  editTeacher: data => dispatch(editTeacher(data)),
  getClassroomTeacherByUserIdRSPA: data =>
    dispatch(getClassroomTeacherByUserIdRSPA.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'searchTeacherForm'
  })(SearchTeacherForm)
)
