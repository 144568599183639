import { all, call, takeLatest, put } from 'redux-saga/effects'

import * as actions from './actions'
import * as services from './services'

export function * fetchSegments ({ payload }) {
  try {
    const response = yield call(services.fetchSegments, payload)
    yield put(actions.getSegmentsSuccessful(response.data))
  } catch (error) {
    yield put(actions.getSegmentsFailure(error))
  }
}

export function * watchFetchSegments () {
  yield takeLatest(actions.getSegments, fetchSegments)
}

export default function * segmentsSagas () {
  yield all([watchFetchSegments()])
}
