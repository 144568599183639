import { createReducer } from '@reduxjs/toolkit'
import * as types from './types'

const initialState = {
  items: [],
  isFetching: false,
  isError: false,
  errorMessage: '',
  currentItem: {},
  currentChild: {},
  currentTeacher: {},
  records: [],
  pagination: {
    total: 1,
    pageCount: 1
  }
}

const classroomsReducer = createReducer(initialState, {
  [types.GET_CLASSROOMS]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_CLASSROOMS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: action.payload.data,
    pagination: {
      total: action.payload.meta.total_count,
      pageCount: action.payload.meta.page_count
    },
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_CLASSROOMS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_CLASSROOM_BY_ID]: (state, action) => ({
    ...state,
    currentChild: {},
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_CLASSROOM_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: action.payload.data,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_CLASSROOM_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_CLASSROOM_RECORDS]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_CLASSROOM_RECORDS_SUCCESSFUL]: (state, action) => ({
    ...state,
    records: action.payload.data,
    pagination: {
      total: action.payload.meta.total_count,
      pageCount: action.payload.meta.page_count
    },
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_CLASSROOM_RECORDS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.CREATE_CLASSROOM]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.CREATE_CLASSROOM_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    currentItem: action.payload,
    errorMessage: ''
  }),
  [types.CREATE_CLASSROOM_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.EDIT_CLASSROOM]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.EDIT_CLASSROOM_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    currentItem: action.payload,
    errorMessage: ''
  }),
  [types.EDIT_CLASSROOM_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_CLASSROOM_CHILDREN]: (state, action) => ({
    ...state,
    currentChild: {},
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_CLASSROOM_CHILDREN_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    currentChild: action.payload,
    errorMessage: ''
  }),
  [types.GET_CLASSROOM_CHILDREN_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.CREATE_CHILDREN_WITH_PARENTS]: (state, action) => ({
    ...state,
    currentChild: {},
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.CREATE_CHILDREN_WITH_PARENTS_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    currentChild: action.payload,
    errorMessage: ''
  }),
  [types.CREATE_CHILDREN_WITH_PARENTS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.CREATE_CLASSROOM_CHILDREN]: (state, action) => ({
    ...state,
    currentChild: {},
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.CREATE_CLASSROOM_CHILDREN_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    currentChild: action.payload,
    errorMessage: ''
  }),
  [types.CREATE_CLASSROOM_CHILDREN_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.EDIT_CLASSROOM_CHILDREN]: (state, action) => ({
    ...state,
    // currentChild: {},
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.EDIT_CLASSROOM_CHILDREN_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    currentChild: action.payload,
    errorMessage: ''
  }),
  [types.EDIT_CLASSROOM_CHILDREN_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_CLASSROOM_TEACHER_BY_ID]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_CLASSROOM_TEACHER_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    currentTeacher: action.payload,
    errorMessage: ''
  }),
  [types.GET_CLASSROOM_TEACHER_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.CREATE_CLASSROOM_TEACHER]: (state, action) => ({
    ...state,
    isFetching: true,
    currentTeacher: {},
    isError: false,
    errorMessage: ''
  }),
  [types.CREATE_CLASSROOM_TEACHER_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    currentTeacher: action.payload,
    errorMessage: ''
  }),
  [types.CREATE_CLASSROOM_TEACHER_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.EDIT_CLASSROOM_TEACHER]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.EDIT_CLASSROOM_TEACHER_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    currentTeacher: action.payload,
    errorMessage: ''
  }),
  [types.EDIT_CLASSROOM_TEACHER_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.REMOVE_CLASSROOM_CHILD]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.REMOVE_CLASSROOM_CHILD_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      children: state.currentItem.children.filter(
        child => child.id !== action.payload
      )
    },
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.REMOVE_CLASSROOM_CHILD_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.CLEAR_CURRENT_CLASSROOM]: (state, action) => ({
    ...initialState
  })
})

export default classroomsReducer
