import { all, call, takeLatest, put } from 'redux-saga/effects'
import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'

import * as actions from './actions'
import * as services from './services'
import { editUser } from '../classrooms/services'

export function * fetchTeachersRequest ({ payload }) {
  try {
    const response = yield call(services.fetchTeacherss, payload)
    yield put(actions.getTeachersSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getTeachersFailure(error))
  }
}

export function * editTeacherRequest ({ payload }) {
  try {
    const response = yield call(editUser, payload)
    yield put(actions.editTeacherSuccessful(response.data))
  } catch (error) {
    console.error(error)
    yield put(actions.editTeacherFailure(error))
  }
}

export function * fetchTeacherByIdRequest ({ payload }) {
  try {
    const response = yield call(services.fetchTeacherById, payload)
    yield put(actions.getTeacherByIdSuccessful(response.data))
  } catch (error) {
    console.error(error)
    yield put(actions.getTeacherByIdFailure(error))
  }
}
export function * removeTeacherSubjectsRequest ({ payload }) {
  try {
    if (payload.ids && payload.ids.length > 0) {
      yield all(
        payload.ids.map(id => {
          return call(services.deleteSubjectTeacher, id)
        })
      )
    }
    yield put(actions.removeTeacherSubjectsSuccessful(payload.ids))
  } catch (error) {
    console.error(error)
    yield put(actions.removeTeacherSubjectsFailure(error))
  }
}

export function * fetchDeletedTeachersRequest ({ payload }) {
  try {
    const response = yield call(services.fetchDeletedTeachers, payload)
    yield put(actions.getDeletedTeachersSuccessful(response))
  } catch (err) {
    console.error(err)
    yield put(actions.getDeletedTeachersFailure(err))
  }
}

export function * removeClassroomTeachersRequest ({ payload }) {
  try {
    if (payload.ids && payload.ids.length > 0) {
      yield all(
        payload.ids.map(id => {
          return call(services.deleteClassroomTeacher, id)
        })
      )
    }
    yield put(actions.removeClassroomTeachersSuccessful(payload.ids))
  } catch (error) {
    console.error(error)
    yield put(actions.removeClassroomTeachersFailure(error))
  }
}

export function * restoreTeacherRequest (action) {
  try {
    const { payload: { schoolTeacherId } } = action
    const response = yield call(services.restoreTeacher, schoolTeacherId)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * watchTeachers () {
  yield takeLatest(actions.getTeachers, fetchTeachersRequest)
  yield takeLatest(actions.getTeacherById, fetchTeacherByIdRequest)
  yield takeLatest(
    actions.removeClassroomTeachers,
    removeClassroomTeachersRequest
  )
  yield takeLatest(actions.editTeacher, editTeacherRequest)
  yield takeLatest(actions.removeTeacherSubjects, removeTeacherSubjectsRequest)
  yield takeLatest(actions.getDeletedTeachers, fetchDeletedTeachersRequest)
  yield takeLatest(actions.restoreTeacherRSPA.request, restoreTeacherRequest)
}

export default function * teachersSaga () {
  yield all([watchTeachers()])
}
