import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import Pagination from '@material-ui/lab/Pagination'

const useRowStyles = makeStyles({
  root: {
    backgroundColor: '#FFF',
    '& > *': {
      borderBottom: '1px solid #F1F3F4'
    }
  },
  tableCell: {
    padding: 10
  },
  pagination: {
    float: 'right',
    paddingTop: '1rem',
    paddingBottom: '0.5rem'
  }
})

function Row (props) {
  const { id, row, handleCheck, selected } = props
  const classes = useRowStyles()

  const handleChange = id => {
    const currentIndex = selected.indexOf(id)
    const newChecked = [...selected]

    if (currentIndex === -1) {
      newChecked.push(id)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    handleCheck(newChecked.length === 0 ? null : newChecked)
  }
  return (
    <React.Fragment>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <Checkbox
            value={id}
            checked={selected.indexOf(id) !== -1}
            onChange={() => handleChange(id)}
            color='primary'
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            tabIndex={-1}
          />
        </TableCell>
        <TableCell component='th' scope='row'>
          {id}
        </TableCell>
        <TableCell align='left'>{row.title}</TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default function DataTable ({
  data,
  handleCheck,
  selected,
  fetchItems,
  param,
  defaultValue
}) {
  const classes = useRowStyles()
  const [page, setPage] = React.useState(1)
  const handleChange = (event, value) => {
    setPage(value)
    fetchItems({ page: value, sort: 'id desc', ...param })
  }

  return (
    <TableContainer className={classes.root}>
      <Table aria-label='collapsible table'>
        <colgroup>
          <col style={{ width: '8%' }} />
          <col style={{ width: '8%' }} />
          <col style={{ width: '75%' }} />
          <col style={{ width: '15%' }} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>ID</TableCell>
            <TableCell align='left'>Título</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {defaultValue &&
            Array.isArray(defaultValue) &&
            defaultValue.map(row => (
              <Row
                className={classes.tableCell}
                key={row.id}
                id={row.id}
                row={row.attributes || row}
                handleCheck={handleCheck}
                selected={selected}
              />
            ))}
          {data &&
            data.data &&
            data.data.map(row => (
              <Row
                className={classes.tableCell}
                key={row.id}
                id={row.id}
                row={row.attributes || row}
                handleCheck={handleCheck}
                selected={selected}
              />
            ))}
        </TableBody>
      </Table>
      <Pagination
        count={data && data.meta && data.meta.page_count}
        className={classes.pagination}
        color='primary'
        page={page}
        onChange={handleChange}
      />
    </TableContainer>
  )
}
