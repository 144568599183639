import React from 'react'
import { connect } from 'react-redux'
import { Field, formValueSelector, change } from 'redux-form'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ConfirmModal from '../modal/ConfirmModal'

import MaterialInput from '../shared/MaterialInput'
import Button from '../button/Button'
import {
  capitalize,
  normalizePhone,
  normalizeCEP,
  normalizeCPF,
  zipcodeUrl
} from '../../utils/formUtils'
import { showAlertMessage } from '../../store/alert/actions'
import {
  recoverPasswordRSPA,
  getParentByEmailRSPA
} from '../../store/classrooms/actions'
import { handleAddAvatar } from '../../utils/helpers'
import heic2any from 'heic2any'
import ListImg from '../shared/ListImg'
import { MenuItem, Select } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import StyledSVG from '../shared/StyledSVG'
import imgSVG from '../../assets/icons/img.svg'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import ToggleButton from '@material-ui/lab/ToggleButton'

const useStyles = makeStyles(theme => ({
  relationship: {
    width: '100%',
    [theme.breakpoints.down('500')]: {
      width: '100%'
    },
    [theme.breakpoints.down('1800')]: {
      width: '100%'
    },
    borderRadius: '8px !important',
    fontFamily: 'Source Sans Pro',
    fontSize: '16px',
    border: `1px solid  rgb(173, 184, 204)`,
    outline: 'none !important',
    padding: '8px !important',
    '& svg': {
      marginRight: '5px',
      color: `${theme.palette.primary.dark}`
    }
  },
  hover: {
    '& div:first-child:hover': {
      borderColor: `${theme.palette.primary.light}`
    }
  },
  searchBtn: {
    marginLeft: 12,
    width: 146,
    border: '1px solid #00ACDB',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#00ACDB',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  addAvatarBtn: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    '& button': {
      backgroundColor: '#F6F7F8',
      border: 'none',
      borderRadius: '50% !important',
      marginRight: 16,
      padding: 7
    },
    '& button.Mui-selected': {
      backgroundColor: '#F6F7F8'
    },
    '& > div.MuiToggleButtonGroup-root': {
      marginBottom: '0px !important'
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center'
    }
  }
}))

const ParentForm = ({
  alert,
  change,
  parent,
  index,
  fields,
  emailValue,
  zipCodeValue,
  errors,
  isEdit,
  fieldValues,
  recoverPasswordRSPA,
  getParentByEmailRSPA,
  initialValues
}) => {
  const theme = useTheme()
  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    title: null,
    id: null
  })
  const [uploadingInProgress, setUploadingInProgress] = React.useState(false)
  const imageRef = React.useRef(null)
  const [imagesElements, setImagesElements] = React.useState({ img: null })
  const classes = useStyles()
  const searchZipcopde = () => {
    let newZipcode = zipCodeValue?.replace(/[^0-9]/g, '')
    if (newZipcode?.length !== 8) {
      alert('O cep deve possuir 8 números', 'info')
      return
    }
    fetch(zipcodeUrl(newZipcode))
      .then(res => res.json())
      .then(data => {
        if (data.erro) {
          // setOpen(!open)
          alert(`O cep ${zipCodeValue} não foi encontrado`, 'warning')
        } else {
          change(`${parent}.address.street`, data.logradouro)
          change(`${parent}.address.neighbourhood`, data.bairro)
        }
      })
      .catch(error => console.error(error))
  }

  const searchParentByEmail = () => {
    try {
      getParentByEmailRSPA({ email: emailValue }).then(res => {
        if (res.data && res.data.length === 1) {
          const parentData = res.data && res.data[0]
          change(`${parent}.id`, parentData.id)
          change(`${parent}.name`, parentData.name)
          change(`${parent}.phone`, parentData.phone)
          change(`${parent}.taxpayer_number`, parentData.taxpayer_number)
          if (parentData.address) {
            change(`${parent}.address.street`, parentData.address.street)
            change(
              `${parent}.address.neighbourhood`,
              parentData.address.neighbourhood
            )
            change(`${parent}.address.number`, parentData.address.number)
            change(`${parent}.address.zipcode`, parentData.address.zipcode)
            change(
              `${parent}.address.complement`,
              parentData.address.complement
            )
          }
        } else {
          alert(
            `Responsável não encontrado, adicione os dados do responsável`,
            'warning'
          )
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const submitEmail = async email => {
    const data = {
      data: {
        type: 'users',
        attributes: {
          email
        }
      }
    }
    await recoverPasswordRSPA({ data }).then(res =>
      alert('Dados reenviados com sucesso!', 'success')
    )
  }

  const relationOptions = [
    {
      label: 'Selecione parentesco',
      value: '',
      disabled: true
    },
    {
      label: 'Pai/Mãe',
      value: 'progenitor'
    },
    {
      label: 'Avô/Avó',
      value: 'grandparent'
    },
    {
      label: 'Irmão/Irmã',
      value: 'sibling'
    },
    {
      label: 'Próprio Aluno',
      value: 'themself'
    },
    {
      label: 'Outro',
      value: 'other'
    }
  ]

  const customIcon = props => <ArrowDropDownIcon {...props} />

  const handleDeleteAvatar = (deleteFromDataBase = false) => {
    setImagesElements(prevState => {
      const newState = {
        img: deleteFromDataBase === true ? 'delete' : null
      }
      change(`${parent}.avatar`, newState.img)
      return newState
    })
  }

  const relationSelect = props => {
    const { input } = props
    return (
      <div className={classes.hover}>
        <Select
          displayEmpty
          className={classes.relationship}
          disableUnderline
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
          IconComponent={customIcon}
          {...input}
        >
          {relationOptions.map(relation => (
            <MenuItem
              key={relation.value}
              value={relation.value}
              disabled={relation.disabled}
            >
              {relation.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }

  const handleOnChangeAvatar = event => {
    handleAddAvatar(
      event,
      setUploadingInProgress,
      alert,
      param =>
        setImagesElements(prevState => {
          const newState = {
            img: param
          }
          change(`${parent}.avatar`, newState.img)
          return newState
        }),
      heic2any
    )
  }

  return (
    <Grid
      container
      spacing={2}
      style={{
        padding: 16,
        marginBottom: '1rem',
        borderBottom: `1px dashed ${theme.palette.secondary.brightest}`
      }}
    >
      <Grid item xs={8} sm={5}>
        <label htmlFor={`${parent}.email`}>
          Email
          <span
            style={{
              fontSize: '2rem',
              verticalAlign: 'text-bottom',
              marginLeft: '0.8rem',
              color: theme.palette.primary.dark
            }}
          >
            &bull;
          </span>
        </label>
        <Field
          component={MaterialInput}
          name={`${parent}.email`}
          placeholder='Digite um Email'
          type='email'
          disabled={
            isEdit &&
            initialValues?.parents?.[parent.slice(8, parent.length - 1)]
          }
          autoComplete='off'
          onKeyPress={e => {
            if (e.key === 'Enter') {
              e.preventDefault()
              searchParentByEmail()
            }
          }}
        />
      </Grid>
      <Grid item xs={8} sm={6} style={{ display: 'flex', alignSelf: 'center' }}>
        <Button
          type='button'
          variant='outlined'
          onClick={searchParentByEmail}
          className={classes.searchBtn}
          style={{ marginTop: 20 }}
        >
          Procurar
        </Button>
        <Button
          type='button'
          onClick={() =>
            setConfirmModal({
              id: emailValue,
              title: emailValue,
              open: true
            })
          }
          style={{ marginTop: 20, backgroundColor: '#F4A87C', color: '#FFFF' }}
        >
          Reenviar
        </Button>
      </Grid>
      <Grid item xs={12} sm={5}>
        <label htmlFor={`${parent}.name`}>
          Nome
          <span
            style={{
              fontSize: '2rem',
              verticalAlign: 'text-bottom',
              marginLeft: '0.8rem',
              color: theme.palette.primary.dark
            }}
          >
            &bull;
          </span>
        </label>
        <Field
          allowClear
          component={MaterialInput}
          name={`${parent}.name`}
          placeholder='Nome do Responsável'
          type='text'
          autoComplete='off'
          normalize={capitalize}
        />
      </Grid>
      <Grid item>
        <label htmlFor={`${parent}.taxpayer_number`}>
          CPF
          <span
            style={
              isEdit
                ? {
                    display: 'none'
                  }
                : {
                    fontSize: '2rem',
                    verticalAlign: 'text-bottom',
                    marginLeft: '0.8rem',
                    color: theme.palette.primary.dark
                  }
            }
          >
            &bull;
          </span>
        </label>
        <Field
          allowClear
          component={MaterialInput}
          name={`${parent}.taxpayer_number`}
          placeholder='CPF'
          type='text'
          autoComplete='off'
          normalize={normalizeCPF}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <label htmlFor={`${parent}.child_parent.relationship_type`}>
          Parentesco
        </label>
        <Field
          component={relationSelect}
          name={`${parent}.child_parent.relationship_type`}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <label htmlFor={`${parent}.child_parent.description`}>Descrição</label>
        <Field
          allowClear
          component={MaterialInput}
          name={`${parent}.child_parent.description`}
          placeholder='Descrição de parentesco'
          type='text'
          autoComplete='off'
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <label htmlFor={`${parent}.phone`}>Telefone</label>
        <Field
          allowClear
          component={MaterialInput}
          name={`${parent}.phone`}
          placeholder='Digite o telefone'
          type='text'
          autoComplete='off'
          normalize={normalizePhone}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <div className={classes.addAvatarBtn}>
          <label htmlFor={`${parent}.avatar`}>Adicionar foto</label>
          <input
            accept='.png, .jpg, .jpeg, .heic'
            hidden
            name='files'
            onChange={handleOnChangeAvatar}
            ref={imageRef}
            type='file'
          />
          {!uploadingInProgress ? (
            <Tooltip title='Adicionar foto' aria-label='foto'>
              <ToggleButton
                style={{ background: 'none' }}
                onClick={() => imageRef.current && imageRef.current.click()}
                value='image'
                aria-label='image'
                color='secondary'
              >
                <StyledSVG width={21} height={21} src={imgSVG} />
              </ToggleButton>
            </Tooltip>
          ) : (
            <CircularProgress color='secondary' size={21} />
          )}
        </div>
        {imagesElements.img ? (
          // show image in createChildren
          <ListImg
            handleDeleteImg={handleDeleteAvatar}
            item={imagesElements.img}
          />
        ) : initialValues.parents?.[index]?.avatar ? (
          // show image in editUser
          <ListImg
            item={initialValues.parents?.[index]?.avatar}
            handleDeleteImg={handleDeleteAvatar}
          />
        ) : (
          undefined
        )}
      </Grid>
      <Grid item xs={12}>
        <h4
          style={{
            margin: 0
          }}
        >
          Endereço
        </h4>
      </Grid>
      <Grid item xs={6} sm={3}>
        <label htmlFor={`${parent}.address.zipcode`}>CEP</label>
        <Field
          allowClear
          component={MaterialInput}
          name={`${parent}.address.zipcode`}
          placeholder='CEP'
          type='text'
          autoComplete='off'
          normalize={normalizeCEP}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              e.preventDefault()
              searchZipcopde(zipCodeValue)
            }
          }}
        />
      </Grid>
      <Grid item xs={6} sm={6} style={{ alignSelf: 'center' }}>
        <Button
          type='button'
          onClick={searchZipcopde}
          variant='outlined'
          className={classes.searchBtn}
          style={{ marginTop: 20 }}
        >
          Procurar
        </Button>
      </Grid>
      <Grid item xs={12} sm={5}>
        <label htmlFor={`${parent}.address.street`}>Rua</label>
        <Field
          allowClear
          component={MaterialInput}
          name={`${parent}.address.street`}
          placeholder='Nome da rua'
          type='text'
          autoComplete='off'
        />
      </Grid>
      <Grid item xs={6} sm={2}>
        <label htmlFor={`${parent}.address.number`}>Número</label>
        <Field
          allowClear
          component={MaterialInput}
          name={`${parent}.address.number`}
          placeholder='Número'
          type='text'
          autoComplete='off'
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <label htmlFor={`${parent}.address.complement`}>Complemento</label>
        <Field
          allowClear
          component={MaterialInput}
          name={`${parent}.address.complement`}
          placeholder='Complemento'
          type='text'
          autoComplete='off'
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <label htmlFor={`${parent}.address.neighbourhood`}>Bairro</label>
        <Field
          allowClear
          component={MaterialInput}
          name={`${parent}.address.neighbourhood`}
          placeholder='Bairro'
          type='text'
          autoComplete='off'
        />
      </Grid>
      <ConfirmModal
        title={`Deseja reenviar os dados de acesso?`}
        open={confirmModal.open}
        setOpen={value => {
          if (value) {
            setConfirmModal({ ...confirmModal, open: value })
          } else {
            setConfirmModal({ id: null, title: '', open: value })
          }
        }}
        onConfirm={() => {
          submitEmail(confirmModal.id)
        }}
      />
    </Grid>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('createStudentForm')
  return {
    initialValues: props.initialValues,
    zipCodeValue: selector(state, `${props.parent}.address.zipcode`),
    emailValue: selector(state, `${props.parent}.email`),
    description: selector(state, `${props.parent}.description`),
    relationship_type: selector(state, `${props.parent}.relationship_type`)
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('createStudentForm', field, data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    ),
  recoverPasswordRSPA: data => dispatch(recoverPasswordRSPA.request(data)),
  getParentByEmailRSPA: data => dispatch(getParentByEmailRSPA.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ParentForm)
