import React from 'react'
import SVG from 'react-inlinesvg'
import rejectSVG from '../../assets/icons/reject.svg'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#ef7c8fb3',
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '15px',
    [theme.breakpoints.down('426')]: {
      flexDirection: 'column'
    }
  },
  title: {
    color: '#ffff',
    fontSize: '24px',
    lineHeight: '29px',
    textAlign: 'center',
    fontWeight: '700',
    [theme.breakpoints.down('426')]: {
      fontSize: '16px',
      lineHeight: 'unset'
    },
    [theme.breakpoints.down('400')]: {
      fontSize: '14px',
      lineHeight: 'unset'
    }
  },
  observationContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    '& p.header': {
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '15px',
      letterSpacing: '0.01em',
      color: '#e94e67',
      paddingLeft: '20px',
      [theme.breakpoints.down('426')]: {
        fontSize: '12px'
      }
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('426')]: {
      flexDirection: 'column',
      '& svg': {
        height: '80px !important'
      }
    }
  },
  observationContent: {
    borderRadius: '16px',
    backgroundColor: '#ffff',
    width: '450px',
    height: 'auto',
    minHeight: '100px',
    '& p': {
      wordBreak: 'break-all'
    },
    [theme.breakpoints.down('426')]: {
      width: '100%',
      '& p': {
        padding: '0 10px 0 10px !important'
      }
    }
  }
}))
const ShowRejectAnnouncement = props => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <SVG style={{ height: '100px' }} src={rejectSVG} />
        <div style={{ maxWidth: '250px' }}>
          <p className={classes.title}>Seu comunicado precisa de ajustes!</p>
        </div>
      </div>
      <div className={classes.observationContainer}>
        <p className='header'>Observação:</p>
        <div className={classes.observationContent}>
          <p
            style={{ paddingLeft: '20px' }}
            dangerouslySetInnerHTML={{ __html: props.rejectMessage }}
          />
        </div>
      </div>
    </div>
  )
}

export default ShowRejectAnnouncement
