import { all, call, takeLatest, put } from 'redux-saga/effects'

import * as actions from './actions'
import * as services from './services'
import { showAlertMessage } from '../alert/actions'

export function * fetchCoversRequest ({ payload }) {
  try {
    const response = yield call(services.fetchCovers, payload)
    yield put(actions.getCoversSuccessful(response))
  } catch (error) {
    yield put(actions.getCoversFailure(error))
  }
}

export function * uploadCoversRequest ({ payload }) {
  try {
    const file = payload.image[0]
    const fd = new FormData()
    fd.append('data[type]', 'covers')
    typeof file === 'object' && fd.append('data[attributes][image]', file)
    const response = yield call(services.createCovers, fd)
    yield put(
      showAlertMessage({
        message: 'Upload da capa realizado.',
        severity: 'success'
      })
    )
    yield put(actions.uploadCoversSuccessful(response.data))
  } catch (error) {
    yield put(actions.uploadCoversFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao fazer o upload da capa.',
        severity: 'error'
      })
    )
  }
}

export function * watchFetchCovers () {
  yield takeLatest(actions.getCovers, fetchCoversRequest)
  yield takeLatest(actions.uploadCovers, uploadCoversRequest)
}

export default function * coversSagas () {
  yield all([watchFetchCovers()])
}
