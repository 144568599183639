import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  seeMoreBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    border: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginBottom: '8px',
    padding: '8px 8px 8px 0px',
    '&:hover': {
      backgroundColor: '#F6F7F8',
      borderRadius: '10px'
    }
  }
}))

const ShowOvercontentTable = ({ item }) => {
  const [seeMore, setSeeMore] = useState(false)
  const qtdSpan = 3
  const classes = useStyles()
  return (
    <>
      {item instanceof Array &&
        item.length > 0 && (
          <>
            {item.map((item, index, self) => {
              if (index < qtdSpan || seeMore) {
                return (
                  <span key={item} style={{ display: 'block' }}>
                    {index === self.length - 1 ? item : item + ', '}
                  </span>
                )
              }
            })}
            {item.length > 3 && (
              <button
                className={classes.seeMoreBtn}
                onClick={() => setSeeMore(!seeMore)}
              >
                {seeMore ? 'Ver menos' : 'Ver mais'}
              </button>
            )}
          </>
        )}
      {item instanceof Array === false && (
        <span style={{ display: 'block' }}>{item}</span>
      )}
    </>
  )
}

export default ShowOvercontentTable
