import React from 'react'
import { change, reduxForm, Form, Field } from 'redux-form'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import AsyncSelect from '../shared/AsyncSelect'
import Button from '../button/Button'
import { showAlertMessage } from '../../store/alert/actions'

const useStyles = makeStyles(theme => ({
  btnStyle: {
    display: 'flex',
    gap: 12,
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: '2rem',
    '& button': {
      height: 40,
      marginTop: '1.5rem',
      minWidth: 150
    }
  },
  createBtn: {
    backgroundColor: '#386093',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  closeBtn: {
    backgroundColor: '#EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const ThemeModuleForm = ({
  handleSubmit,
  submitting,
  pristine,
  change,
  touch,
  schoolId,
  clearClonedTheme
}) => {
  const classes = useStyles()
  const handleChange = event => {
    const module = event ? Number(event.value) : event
    change('theme_module_id', module)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div>
        <Field
          component={AsyncSelect}
          id='theme_module_id'
          name='theme_module_id'
          placeholder='Selecione o Título do Módulo'
          handleAsyncChange={handleChange}
          changeField={change}
          searchParam='q[title_cont]'
          touch={touch}
          request={{
            path: 'theme_module',
            params: {
              'q[school_id_eq]': schoolId
            }
          }}
        />
      </div>
      <div className={classes.btnStyle}>
        <Button
          className={classes.closeBtn}
          type='button'
          onClick={() => {
            clearClonedTheme()
          }}
        >
          Fechar
        </Button>
        <Button
          className={classes.createBtn}
          type='submit'
          disabled={pristine || submitting}
        >
          salvar
        </Button>
      </div>
    </Form>
  )
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('themeModuleForm', field, data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    )
})

export default connect(null, mapDispatchToProps)(
  reduxForm({
    form: 'themeModuleForm',
    ouchOnChange: true,
    touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {
      if (props.clonedTheme) {
        await props.editTheme({ id: props.clonedTheme.id, data: { ...values } })
        props.clearClonedTheme()
      }
    }
  })(ThemeModuleForm)
)
