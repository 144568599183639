import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'

import * as actions from './actions'
import * as services from './services'

export function * fetchTagsRequest ({ payload }) {
  try {
    const { school } = payload
    let currentPage = 1
    const response = yield call(services.fetchTags, {
      page: currentPage,
      school
    })
    let fullResponse = { ...response }
    while (fullResponse.data.length < fullResponse.meta.total_count) {
      currentPage += 1
      let responseForNextPage = yield call(services.fetchTags, {
        page: currentPage,
        school
      })
      fullResponse.data = [...fullResponse.data, ...responseForNextPage.data]
    }
    yield put(actions.getTagsSuccessful(fullResponse))
  } catch (error) {
    console.error(error)
    yield put(actions.getTagsSuccessful(error))
  }
}

export function * fetchTagOptionsRequest ({ payload }) {
  try {
    const response = yield call(services.fetchTagOptions, payload)
    yield put(actions.getTagOptionsSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getTagOptionsFailure(error))
  }
}

export function * fetchTagsRSPARequest (action) {
  try {
    const { payload: { tagsOrder } } = action
    const response = yield call(services.fetchTags, {
      params: {
        'q[tag_order_in]': tagsOrder
      }
    })
    resolvePromiseAction(action, response)
  } catch (err) {
    rejectPromiseAction(action, err)
  }
}

export function * watchFetchReports () {
  yield takeLatest(actions.getTags, fetchTagsRequest)
  yield takeLatest(actions.getTagOptions, fetchTagOptionsRequest)
  yield takeLatest(actions.getTagsRSPA.request, fetchTagsRSPARequest)
}

export default function * reportsSagas () {
  yield all([watchFetchReports()])
}
