import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  card: {
    border: 'none',
    borderRadius: 8,
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'inherit',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 140,
    width: 162,
    '& p': {
      fontSize: 18,
      fontWeight: 700
    },
    [theme.breakpoints.down('1000')]: {
      height: 160,
      width: 152,
      '& p': {
        fontSize: 16
      }
    },
    [theme.breakpoints.down('426')]: {
      height: 100,
      width: 100
    }
  },
  default: {
    transition: 'background 0.3s ease-in-out, border 0.3s ease-in-out',
    background: '#386093',
    '& svg': {
      '& path': {
        stroke: '#FFFFFF'
      }
    },
    '& p': {
      color: '#FFFFFF'
    }
  },
  disabled: {
    transition: 'background 0.3s ease-in-out, border 0.3s ease-in-out',
    background: 'none',
    border: `1px dashed ${theme.palette.text.secondary}`,
    '& p': {
      color: theme.palette.text.secondary
    }
  }
}))

const ButtonCard = ({ children, styleType, ...props }) => {
  const classes = useStyles()

  return (
    <button
      className={`${classes.card} ${
        styleType === 'default' ? classes.default : classes.disabled
      }`}
      {...props}
    >
      {children}
    </button>
  )
}

export default ButtonCard
