import { createAction } from '@reduxjs/toolkit'
import { createPromiseAction } from 'redux-saga-promise-actions'
import * as types from './types'

export const getUtils = createAction(types.GET_UTILS)
export const getUtilsSuccessful = createAction(types.GET_UTILS_SUCCESSFUL)
export const getUtilsFailure = createAction(types.GET_UTILS_FAILURE)

export const getWrapperUtils = createAction(types.GET_WRAPPER_UTILS)
export const getWrapperUtilsSuccessful = createAction(
  types.GET_WRAPPER_UTILS_SUCCESSFUL
)
export const getWrapperUtilsFailure = createAction(
  types.GET_WRAPPER_UTILS_FAILURE
)

export const editWrapper = createAction(types.EDIT_WRAPPER)
export const editWrapperSuccessful = createAction(types.EDIT_WRAPPER_SUCCESSFUL)
export const editWrapperFailure = createAction(types.EDIT_WRAPPER_FAILURE)

export const getUtilById = createAction(types.GET_UTIL_BY_ID)
export const getUtilByIdSuccessful = createAction(
  types.GET_UTIL_BY_ID_SUCCESSFUL
)
export const getUtilByIdFailure = createAction(types.GET_UTIL_BY_ID_FAILURE)

export const createUtils = createAction(types.CREATE_UTILS)
export const createUtilsSuccessful = createAction(types.CREATE_UTILS_SUCCESSFUL)
export const createUtilsFailure = createAction(types.CREATE_UTILS_FAILURE)

export const getUtilDestinationById = createAction(
  types.GET_UTIL_DESTINATION_BY_ID
)
export const getUtilDestinationByIdSuccessfull = createAction(
  types.GET_UTIL_DESTINATION_BY_ID_SUCCESSFUL
)
export const getUtilDestinationByIdFailure = createAction(
  types.GET_UTIL_DESTINATION_BY_ID_FAILURE
)

export const deleteUtil = createAction(types.DELETE_UTIL)
export const deleteUtilSuccessful = createAction(types.DELETE_UTIL_SUCCESSFUL)
export const deleteUtilFailure = createAction(types.DELETE_UTIL_FAILURE)

export const editUtil = createAction(types.EDIT_UTIL)
export const editUtilSuccessful = createAction(types.EDIT_UTIL_SUCCESSFUL)
export const editUtilFailure = createAction(types.EDIT_UTIL_FAILURE)

export const setCurrentUtil = createAction(types.SET_CURRRENT_UTIL)
export const cleanUtilSuccessful = createAction(types.CLEAN_CURRRENT_UTIL)
export const cleanCurrentDestinationsUtil = createAction(
  types.CLEAN_CURRRENT_DESTINATIONS_UTIL
)

export const getUtilDestinationsRSPA = createPromiseAction(
  'rspa: GET_UTIL_DESTINATIONS'
)()
export const getUtilForAllDestinationsRSPA = createPromiseAction(
  'rspa: GET_UTIL_ALL_DESTINATIONS'
)()
