import React from 'react'
import { connect } from 'react-redux'

import {
  getCommunications,
  updateCommunication
} from '../store/communications/actions'
import MessagesInboxView from '../components/view/MessagesInboxView'

const mapStateToProps = ({ auth, communications, school }) => {
  return {
    user: auth.user?.data,
    communications: communications,
    selectedSchoolItem: school.currentSchool,
    isFetching: communications.isFetching,
    userOccupations: auth.currentOccupation
  }
}

const mapDispatchToProps = {
  getCommunications,
  updateCommunication
}

const MessagesInboxPage = ({
  user,
  userOccupations,
  communications,
  getCommunications,
  updateCommunication,
  selectedSchoolItem,
  isFetching
}) => {
  return (
    <MessagesInboxView
      getCommunications={getCommunications}
      updateCommunication={updateCommunication}
      communications={communications}
      userOccupations={userOccupations}
      userId={user?.id}
      isFetching={isFetching}
      selectedSchool={selectedSchoolItem}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesInboxPage)
