import jsonApi from '../../utils/jsonApi'
import http from '../../utils/http'

const fetchSchoolContentCreators = 'school_content_creator'
const fetchSchoolAdministrator = 'school_administrator'
const fetchSchoolCoordinator = 'school_coordinator'
const fetchSchoolTeacher = 'school_teachers'

export const getSchoolConfig = schoolId => {
  return jsonApi
    .find('school_config', '', {
      params: {
        'q[school_id_eq]': schoolId
      }
    })
    .then(res => res.data?.[0])
}

export const updateSchoolConfig = params => {
  return http
    .put(`school_configs/${params.id}`, {
      data: {
        id: params.id,
        type: 'school_configs',
        attributes: {
          ...params.data
        }
      }
    })
    .then(res => res.data)
}

export const fetchContentCreatorById = id =>
  jsonApi
    .find(fetchSchoolContentCreators, '', {
      params: {
        'q[user_id_in]': id,
        include: 'school'
      }
    })
    .then(res => res.data)

export const fetchAdministratorById = async id => {
  let currentPage = 1
  const response = await jsonApi
    .find(fetchSchoolAdministrator, '', {
      params: {
        'q[user_id_in]': id,
        'page[number]': currentPage,
        include: 'school'
      }
    })
    .then(res => res)
  let fullResponse = { ...response }
  while (fullResponse.data.length < fullResponse.meta.total_count) {
    currentPage += 1
    let responseForNextPage = await jsonApi
      .find(fetchSchoolAdministrator, '', {
        params: {
          'q[user_id_in]': id,
          'page[number]': currentPage,
          include: 'school'
        }
      })
      .then(response => response.data)
    fullResponse.data = [...fullResponse.data, ...responseForNextPage]
  }
  return fullResponse.data
}

export const fetchCoordinatorById = id => {
  return jsonApi
    .find(fetchSchoolCoordinator, '', {
      params: {
        'q[user_id_in]': id,
        include: 'school'
      }
    })
    .then(res => res.data)
}

export const fetchTeacherById = id =>
  jsonApi
    .find(fetchSchoolTeacher, '', {
      params: {
        'q[user_id_in]': id,
        include: 'school'
      }
    })
    .then(res => res.data)

export const updateLayoutSchool = data =>
  http
    .put(`schools/${data.id}`, data.data, {
      'Content-Type': 'multipart/form-data'
    })
    .then(res => res.data)

export const fetchSchools = () =>
  jsonApi
    .findAll('school', {
      params: {
        'page[size]': 20
      }
    })
    .then(res => res.data)
