import React from 'react'
import { connect } from 'react-redux'
import SchoolsListView from '../components/view/SchoolsListView'

import { getSchools, selectedCurrentSchool } from '../store/school/actions'
import { roles } from '../utils/constants'

const mapStateToProps = ({ auth, school }) => {
  return {
    userOccuppations: auth.user.data?.attributes?.occupations,
    schoolsList: school.list
  }
}

const mapDispatchToProps = {
  getSchools,
  selectedCurrentSchool
}

const SchoolsListPage = ({
  schoolsList,
  getSchools,
  selectedCurrentSchool,
  userOccuppations
}) => {
  const isAdmin = userOccuppations.includes(roles.VERSAR_ADMIN)

  React.useEffect(() => {
    isAdmin && getSchools()
  }, [])

  return (
    <SchoolsListView
      selectedCurrentSchool={selectedCurrentSchool}
      schoolsList={schoolsList}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolsListPage)
