import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '40px 16px 0',
    width: 250,
    height: 240,
    borderRadius: 8,
    '&.MuiPaper-outlined': {
      border: 'none'
    }
  },
  color: {
    background: theme.palette.secondary.main,
    color: '#FFF'
  },
  white: {
    background: '#FFF',
    color: theme.palette.primary.main,
    '&.MuiPaper-root': {
      padding: 0,
      marginLeft: '10px'
    }
  },
  default: {
    background: '#F6F7F8',
    color: theme.palette.primary.main
  },
  child: {
    '&.MuiPaper-outlined': {
      border: 'none'
    }
  }
}))

export default function OutlinedCard ({ children, color }) {
  const classes = useStyles()

  return (
    <Card
      className={`${classes.root} ${
        color === 'secondary'
          ? classes.color
          : color === 'default' ? classes.default : classes.white
      }`}
      variant='outlined'
    >
      {children}
    </Card>
  )
}
