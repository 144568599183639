import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import deleteItemSVG from '../../assets/icons/delete-item.svg'

const useStyles = makeStyles(theme => ({
  root: {
    '& img': {
      height: 200,
      width: 200
    },
    padding: theme.spacing(2),
    margin: 'auto',
    width: 600,
    '& h2': {
      fontSize: 20,
      fontWeight: 700,
      textAlign: 'center'
    },
    '& button': {
      fontWeight: 700,
      textTransform: 'capitalize',
      width: 140
    },
    '& .MuiDialogTitle-root': {
      '& h2': {}
    },
    '& .MuiDialog-paper': {
      padding: '2rem',
      borderRadius: 16,
      display: 'flex',
      alignItems: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%'
    }
  },
  buttons: {
    justifyContent: 'space-evenly',
    '& button': {
      borderRadius: 8
    }
  }
}))

const ConfirmModal = props => {
  const classes = useStyles()
  const { title, children, open, setOpen, onConfirm } = props
  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='confirm-dialog'
    >
      <img src={deleteItemSVG} />
      <DialogTitle id='confirm-dialog'>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions className={classes.buttons}>
        <Button
          variant='contained'
          onClick={() => setOpen(false)}
          style={{ backgroundColor: '#EF7C8F', color: '#FFFF' }}
        >
          Não
        </Button>
        <Button
          // size='small'
          variant='contained'
          onClick={() => {
            setOpen(false)
            onConfirm()
          }}
          style={{ backgroundColor: '#386093', color: '#FFFF' }}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ConfirmModal
