export const GET_CONTENTS = 'contents/GET_CONTENTS'
export const GET_CONTENTS_SUCCESSFUL = 'contents/GET_CONTENTS_SUCCESSFUL'
export const GET_CONTENTS_FAILURE = 'contents/GET_CONTENTS_FAILURE'
export const GET_CONTENT_BY_ID = 'contents/GET_CONTENT_BY_ID'
export const GET_CONTENT_BY_ID_SUCCESSFUL =
  'contents/GET_CONTENT_BY_ID_SUCCESSFUL'
export const GET_CONTENT_BY_ID_FAILURE = 'contents/GET_CONTENT_BY_ID_FAILURE'
export const GET_CONTENTS_BY_IDS = 'contents/GET_CONTENTS_BY_IDS'

export const CREATE_CONTENTS = 'contents/CREATE_CONTENTS'
export const CREATE_CONTENTS_SUCCESSFUL = 'contents/CREATE_CONTENTS_SUCCESSFUL'
export const CREATE_CONTENTS_FAILURE = 'contents/CREATE_CONTENTS_FAILURE'

export const DELETE_CONTENT = 'contents/DELETE_CONTENT'
export const DELETE_CONTENT_SUCCESSFUL = 'contents/DELETE_CONTENT_SUCCESSFUL'
export const DELETE_CONTENT_FAILURE = 'contents/DELETE_CONTENT_FAILURE'

export const EDIT_CONTENT = 'contents/EDIT_CONTENT'
export const EDIT_CONTENT_SUCCESSFUL = 'contents/EDIT_CONTENT_SUCCESSFUL'
export const EDIT_CONTENT_FAILURE = 'contents/EDIT_CONTENT_FAILURE'

export const DELETE_DOCUMENT = 'contents/DELETE_DOCUMENT'
export const DELETE_AUDIO = 'contents/DELETE_AUDIO'
export const CLEAR_CURRENT_CONTENT = 'contents/CLEAR_CURRENT_CONTENT'
