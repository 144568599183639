import styled from '@emotion/styled'
import { withTheme } from '@material-ui/core/styles'

export const Box = withTheme(styled('div')`
  background: white;
  border-radius: 8px;
  box-shadow: 3px 11px 14px 6px rgba(107, 103, 103, 0.1);
  display: flex;
  flex-flow: column;
  padding: 2rem 1rem 2rem 1.5rem;
  max-height: 100%;
  overflow: auto;
  label {
    align-items: center;
    display: flex;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    span:nth-of-type(2) {
      font-size: 2rem;
      margin-left: 1rem;
    }
  }
  h2,
  h3 {
    //color: ${props => props.theme.palette.primary.main};
  }

  input,
  textarea {
    font-family: inherit;
    border-radius: 2px;
    box-sizing: border-box;
    font-size: 14px;
    height: 38px;
    //min-width: 200px;
    ::placeholder {
      color: rgba(0, 0, 0, 0.25);
      font-size: 1rem;
    }
  }

  textarea {
    height: 140px;
  }

  input[type='color'] {
    border-radius: 8px;
    min-width: 40%;
    padding: 2px;
  }

  .btnStepList {
    padding-right: 0;
  }
  /*
  button {
    max-width: 275px;
    width: 100%;
    height: 40px;
    margin: 2rem auto;
  } */
  @media screen and (max-width: 775px) {
    padding: 1.5rem 1rem 1rem;
  }
`)

export const ButtonStyled = styled('span')`
  button {
    color: #fff;
    height: 40px;
    width: 100%;
    //max-width: 190px;
  }
`

export const BottomNav = withTheme(styled('div')`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  button:first-of-type {
    color: white;
    background: ${props => props.theme.palette.secondary.main};
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    margin-right: 1rem;
    padding: 0 4rem;
    text-transform: capitalize;
  }

  button:last-of-type {
    //background: ${props => props.theme.palette.secondary.main};
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    border-radius: 8px;
    max-width: 180px;
    height: 40px;
    padding: 0 4rem;
    justify-content: space-around;
    white-space: pre;
  }
  @media screen and (min-width: 1050px) {
    width: 100%;
  }
`)

export const StepsProgress = withTheme(styled('div')`
  display: flex;
  flex-flow: row;

  p:first-of-type {
    color: ${props => props.theme.palette.secondary.main};
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 1.5rem;
  }

  p:last-of-type {
    color: #555;
  }
`)

export const ImagesCover = styled('div')`
  display: flex;
  flex-flow: row wrap;
  img {
    width: 100%;
    height: 100%;
    max-width: 270px;
    max-height: 150px;
    padding: 0.5rem;
  }
`

export const UploadButtons = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 48px;
  margin-bottom: 1.5rem;
  button {
    padding: 16px 24px;
    //background: ${props => props.theme.palette.primary.main};
    border-radius: 2px;
    color: #fff;
    text-transform: capitalize;
    min-width: 200px;
    width: 255px;
  }
  svg {
    margin-right: 1rem;
  }

  span.fileName {
    background: #ffffff;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    border-radius: 2px;
    padding: 16px 24px;
    margin-left: 30px;
    min-width: 196px;
    max-height: 56px;
  }

  span.btnDelete {
    color: ${props => props.theme.palette.danger.main};
    cursor: pointer;
    margin-left: -0.8rem;
    margin-top: -3.2rem;
  }
`
