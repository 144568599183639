import axios from 'axios'

const { REACT_APP_SERVER_ENV } = process.env

const currentApi = process.env.REACT_APP_PROJECT_API_HOST

// const apiVersion = 'v1'

const backendHosts = {
  production: 'https://api.plataformaversar.com.br',
  staging: 'https://stag-api.plataformaversar.com.br',
  development: 'https://stag-api.plataformaversar.com.br',
  homolog: 'https://homolog-api.plataformaversar.com.br',
  local: 'http://localhost:3000'
}

export const currentHost = currentApi || backendHosts[REACT_APP_SERVER_ENV]

const instance = axios.create({
  // baseURL: `${backendHost}/api/${apiVersion}`
  baseURL: currentHost,
  headers: {
    'Content-Type': 'application/vnd.api+json'
  }
})

export default instance
