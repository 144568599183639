import React from 'react'
import moment from 'moment'
import { Waypoint } from 'react-waypoint'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles(theme => ({
  root: {
    height: 'fit-content',
    maxHeight: 450
  },
  removeStyle: {
    fontSize: 14,
    '& p, h1, h2, h3': {
      margin: 0,
      padding: 0
    }
  }
}))

const MessagesMenu = ({
  history,
  hasNextPage,
  loadMoreData,
  messages,
  userId
}) => {
  const classes = useStyles()

  return (
    <List
      className={classes.root}
      component='nav'
      aria-label='secondary mailbox folders'
    >
      {messages?.data &&
        messages.data.map(message => {
          const sendMsg = (
            <span style={{ fontWeight: 900 }}>Mensagem enviada</span>
          )
          const sendMsgAuthor = (
            <span style={{ fontWeight: 900 }}>
              {message.author?.name} enviou{' '}
              {message.title || message.content ? ' a ' : ' uma '} mensagem
            </span>
          )
          const msgText =
            message.message_type === 'system'
              ? message.content
                  ?.split('.')
                  .shift()
                  .substring(0, 60)
              : message?.title ||
                message.content
                  ?.split('.')
                  .shift()
                  .substring(0, 60)
          return (
            <ListItem
              style={{ maxWidth: 300 }}
              key={`listItem-message-${message.id}`}
              button
              onClick={() =>
                history.push(`/messages/inbox/${message.communication_id}`, {})
              }
            >
              <ListItemText
                primary={
                  <span className={classes.removeStyle}>
                    {message.message_type === 'default' ? (
                      String(message.author_id) === userId ? (
                        sendMsg
                      ) : (
                        sendMsgAuthor
                      )
                    ) : (
                      <span style={{ fontWeight: 900 }}>
                        Mensagem do sistema
                      </span>
                    )}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: msgText
                      }}
                    />
                  </span>
                }
                secondary={moment(message.created_at)
                  .startOf('minute')
                  .fromNow()}
              />
            </ListItem>
          )
        })}
      {hasNextPage && (
        <Waypoint onEnter={loadMoreData}>
          <LinearProgress color='secondary' />
        </Waypoint>
      )}
    </List>
  )
}

export default MessagesMenu
