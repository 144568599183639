import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  reduxForm,
  Field,
  change,
  Form,
  formValueSelector,
  submit,
  getFormValues
} from 'redux-form'

import { getUsers } from '../../store/users/actions'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '../modal/Modal'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import ToggleButton from '@material-ui/lab/ToggleButton'
import StyledSVG from '../shared/StyledSVG'
import imgSVG from '../../assets/icons/img.svg'
import ListImg from '../shared/ListImg'
import { handleAddAvatar } from '../../utils/helpers'
import heic2any from 'heic2any'
// import { TimePickerInput } from '../shared/DatePickerInput'
// import ShiftSelect from '../select/ShiftSelect'
// import DaysSelect from '../select/DaysSelect'
import moment from 'moment'

import HeadFormTab from '../tab/HeadFormTab'
import Label from '../label/Label'
import AsyncSelect from '../shared/AsyncSelect'
import MaterialInput from '../shared/MaterialInput'
import { Box } from './styles/CreateModuleForm.style'
import Button from '../button/Button'
import {
  required,
  validEmail,
  capitalize,
  validZipcode,
  normalizeCEP,
  normalizePhone,
  normalizeCPF,
  zipcodeUrl,
  validPhone,
  validFullname,
  isValidCPF
} from '../../utils/formUtils'
import { showAlertMessage } from '../../store/alert/actions'
import { occupationsIds } from '../../utils/constants'
import { backToThePage } from '../../store/pagination/actions'
// import HeadFormTab from '../../components/tab/HeadFormTab'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    margin: 0,
    borderRadius: '8px',
    borderTopLeftRadius: 'unser',
    lineHeight: '20.11px !important',
    fontSize: 16,
    minWidth: 300,
    paddingLeft: '1.5rem',
    '& .select__value-container--is-multi > .select__input > input': {
      height: 38
    }
  },
  btnStyle: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10
  },
  bull: {
    color: theme.palette.primary.dark,
    fontSize: '2rem',
    verticalAlign: 'text-bottom',
    marginLeft: '0.8rem'
  },
  defineAsContact: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTypography-body1': {
      fontSize: 'unset'
    }
  },
  radioValue: {
    '& span:nth-of-type(2)': {
      fontSize: 'unset !important'
    }
  },
  fieldLabel: {
    color: '#6E6E6E',
    fontWeight: '600'
  },
  sectionLabel: {
    margin: 0,
    color: '#555555',
    weight: '700',
    lineHeight: '24px',
    fontSize: '16px'
  },
  moments: {
    '& div': {
      justifyContent: 'flex-start'
    },
    '& .MuiGrid-container': {
      alignItems: 'center'
    }
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  saveBtn: {
    backgroundColor: '#386093',
    width: 165,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  searchBtn: {
    marginLeft: 12,
    width: 146,
    border: '1px solid #00ACDB',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#00ACDB',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  btnsModal: {
    display: 'flex',
    gap: 12,
    justifyContent: 'flex-end'
  },
  cancelBtn: {
    backgroundColor: '#EF7C8F',
    width: 169,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  sendBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  addAvatarBtn: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    '& button': {
      backgroundColor: '#F6F7F8',
      border: 'none',
      borderRadius: '50% !important',
      marginRight: 16,
      padding: 7
    },
    '& button.Mui-selected': {
      backgroundColor: '#F6F7F8'
    },
    '& > div.MuiToggleButtonGroup-root': {
      marginBottom: '0px !important'
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center'
    }
  }
}))

const CreateTeacherForm = ({
  alert,
  change,
  handleSubmit,
  initialValues,
  pristine,
  onSubmit,
  submitSucceeded,
  touch,
  zipCodeValue,
  valid,
  currentTeacher,
  isVersarAdmin,
  schoolValue,
  school,
  fieldValues,
  entranceHourValue,
  exitHourValue,
  backToThePage,
  currentPagination,
  getUsers
}) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const goBack = () => {
    backToThePage({ ...currentPagination, canGoBack: true })
    history.push('/settings/teachers')
  }
  const [uploadingInProgress, setUploadingInProgress] = React.useState(false)
  const [imagesElements, setImagesElements] = React.useState({ img: null })
  const imageRef = React.useRef(null)

  const [userAlreadyRegistered, setUserAlreadyResgistered] = React.useState(
    null
  )
  const [
    userAlreadyRegisteredModal,
    setUserAlreadyRegisteredModal
  ] = React.useState({
    email: false,
    taxpayer_number: false,
    open: false
  })

  const handleDeleteAvatar = (deleteFromDataBase = false) => {
    setImagesElements(prevState => {
      const newState = {
        img: deleteFromDataBase === true ? 'delete' : null
      }
      change('image', newState.img)
      return newState
    })
  }

  const searchExistingUser = async () => {
    await getUsers({
      params: {
        'q[taxpayer_number_eq]': fieldValues.taxpayer_number,
        'q[email_eq]': fieldValues.email,
        'q[m]': 'or'
      }
    }).then(res => {
      setUserAlreadyResgistered(res)
    })
  }

  submitSucceeded && goBack()

  React.useEffect(
    async () => {
      if (userAlreadyRegistered) {
        if (userAlreadyRegistered.length > 0) {
          const newState = { email: false, taxpayer_number: false, open: true }
          if (
            String(userAlreadyRegistered[0].email) === String(fieldValues.email)
          ) {
            newState.email = true
          }
          if (
            String(userAlreadyRegistered[0].taxpayer_number) ===
            String(fieldValues.taxpayer_number)
          ) {
            newState.taxpayer_number = true
          }
          if (
            String(userAlreadyRegistered[0].taxpayer_number) ===
              String(fieldValues.taxpayer_number) &&
            String(userAlreadyRegistered[0].email) === String(fieldValues.email)
          ) {
            await change('userToEdit', userAlreadyRegistered[0])
            dispatch(submit('createTeacherForm'))
          } else {
            setUserAlreadyRegisteredModal(newState)
          }
        } else {
          dispatch(submit('createTeacherForm'))
        }
      }
    },
    [userAlreadyRegistered]
  )
  const handleChange = (event, input) => {
    let newValue
    if (Array.isArray(event)) {
      if (input.name === 'classroom_teachers_attributes') {
        newValue = event?.map(v => ({ classroom_id: v.value }))
      } else {
        newValue = event?.map(v => ({
          subject_id: v.value,
          school_id: school.school_id
        }))
      }
    } else {
      newValue = event ? event.value : ''
    }
    change(input.name, newValue)
  }

  const searchZipcopde = zipcode => {
    let newZipcode = zipcode?.replace(/[^0-9]/g, '')
    if (newZipcode?.length !== 8) {
      alert('O cep deve possuir 8 números', 'info')
      return
    }
    fetch(zipcodeUrl(newZipcode))
      .then(res => res.json())
      .then(data => {
        if (data.erro) {
          alert(
            `O cep ${zipcode} não foi encontrado. Digite o endereço manualmente`,
            'warning'
          )
        } else {
          change('address.street', data.logradouro)
          change('address.neighbourhood', data.bairro)
        }
      })
      .catch(error => console.error(error))
  }

  const RequiredFields = (
    <>
      <FormControlLabel
        className={classes.radioValue}
        value={'true'}
        control={<Radio color='primary' />}
        label='Sim'
      />
      <FormControlLabel
        className={classes.radioValue}
        value={'false'}
        control={<Radio color='primary' />}
        label='Não'
      />
    </>
  )
  const contactIsFilled = () => {
    if (fieldValues?.contact_from_school !== undefined) {
      return (
        fieldValues?.contact_from_school.toString() === 'true' ||
        fieldValues?.contact_from_school.toString() === 'false'
      )
    }
    return false
  }

  const handleOnChangeAvatar = event => {
    handleAddAvatar(
      event,
      setUploadingInProgress,
      alert,
      param =>
        setImagesElements(prevState => {
          const newState = {
            img: param
          }
          change('image', newState.img)
          return newState
        }),
      heic2any
    )
  }

  return (
    <div className={`${classes.root}`}>
      <HeadFormTab currentPage={'teachers'} />
      <Box>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Label>Cadastro de novos docentes</Label>
              <p style={{ maxWidth: '350px' }}>
                Registre novos professores facilmente e enriqueça a experiência
                educacional na sua escola.
              </p>
            </Grid>
            {isVersarAdmin && (
              <>
                <Grid item xs={12} sm={5}>
                  <label htmlFor='school_id'>
                    Escola
                    <span className={classes.bull}>&bull;</span>
                  </label>
                  <Field
                    component={AsyncSelect}
                    defaultValue={{
                      label: currentTeacher?.school?.name,
                      value: initialValues.school_id
                    }}
                    id='school_id'
                    name='school_id'
                    placeholder='Digite ou selecione uma escola'
                    handleAsyncChange={handleChange}
                    searchParam='q[name_cont]'
                    cacheUniqs={0}
                    request={{
                      path: 'school'
                    }}
                    touch={touch}
                    validate={[required]}
                  />
                </Grid>
                <Grid item sm={6} />
              </>
            )}
            <Grid item xs={12} sm={12}>
              <label htmlFor='name' className={classes.fieldLabel}>
                Nome
                <span className={classes.bull}>&bull;</span>
              </label>
              <Field
                component={MaterialInput}
                name='name'
                placeholder='Nome do Docente'
                type='text'
                autoComplete='off'
                normalize={capitalize}
                validate={[required, validFullname]}
                autoFocus
              />
            </Grid>
            <Grid className={classes.fieldLabel} item xs={12} sm={4}>
              <label htmlFor={`taxpayer_number`}>
                CPF
                <span
                  className={classes.bull}
                  style={{
                    fontSize: '2rem',
                    verticalAlign: 'text-bottom',
                    marginLeft: '0.8rem'
                  }}
                >
                  &bull;
                </span>
              </label>
              <Field
                allowClear
                component={MaterialInput}
                name={`taxpayer_number`}
                placeholder='CPF'
                type='text'
                autoComplete='off'
                normalize={normalizeCPF}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                  }
                }}
                validate={[isValidCPF]}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label className={classes.fieldLabel} htmlFor='subjects'>
                Disciplina(s)
              </label>
              <Field
                component={AsyncSelect}
                defaultValue={initialValues?.subjects}
                id='subjects'
                name='subjects'
                isMulti
                placeholder='Selecione a(s) disciplina(s)'
                handleAsyncChange={handleChange}
                cacheUniqs={schoolValue || 0}
                searchParam={'q[name_cont]'}
                request={{
                  path: 'subject'
                }}
                touch={touch}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label
                className={classes.fieldLabel}
                htmlFor='classroom_teachers_attributes'
              >
                Turma(s)
              </label>
              <Field
                component={AsyncSelect}
                defaultValue={initialValues.classroom_teachers_attributes}
                id='classroom_teachers_attributes'
                name='classroom_teachers_attributes'
                isMulti
                placeholder='Selecione a(s) turma(s)'
                handleAsyncChange={handleChange}
                cacheUniqs={schoolValue || 0}
                searchParam={'q[name_cont]'}
                request={{
                  path: 'classroom',
                  params: {
                    'q[school_id_eq]':
                      (school && school.school_id) || schoolValue,
                    'q[year_lteq]': new Date().getFullYear() + 1,
                    'q[year_gteq]': new Date().getFullYear()
                  }
                }}
                touch={touch}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label className={classes.fieldLabel} htmlFor='email'>
                Email
                <span className={classes.bull}>&bull;</span>
              </label>
              <Field
                component={MaterialInput}
                name='email'
                placeholder='Digite um email'
                type='email'
                autoComplete='off'
                disabled={!!currentTeacher?.id}
                validate={[required, validEmail]}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label className={classes.fieldLabel} htmlFor='phone'>
                Telefone
              </label>
              <Field
                component={MaterialInput}
                name='phone'
                placeholder='Telefone de contato'
                type='text'
                autoComplete='off'
                normalize={normalizePhone}
                validate={[validPhone]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={6}>
              <div className={classes.defineAsContact}>
                <label className={classes.fieldLabel} htmlFor='contact'>
                  Deseja cadastrar como um contato da escola?
                  <span className={classes.bull}>&bull;</span>
                </label>
                <RadioGroup
                  row
                  aria-label={'Adição de contato'}
                  name='contact_from_school'
                  defaultValue={`${initialValues?.contact_from_school}`}
                  onChange={event =>
                    change('contact_from_school', event.target.value)
                  }
                >
                  {RequiredFields}
                </RadioGroup>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.addAvatarBtn}>
                <p>Adicionar foto:</p>
                <input
                  accept='.png, .jpg, .jpeg, .heic'
                  hidden
                  name='files'
                  onChange={handleOnChangeAvatar}
                  ref={imageRef}
                  type='file'
                />
                {!uploadingInProgress ? (
                  <Tooltip title='Adicionar foto' aria-label='foto'>
                    <ToggleButton
                      style={{ background: 'none' }}
                      onClick={() =>
                        imageRef.current && imageRef.current.click()
                      }
                      value='image'
                      aria-label='image'
                      color='secondary'
                    >
                      <StyledSVG width={21} height={21} src={imgSVG} />
                    </ToggleButton>
                  </Tooltip>
                ) : (
                  <CircularProgress color='secondary' size={21} />
                )}
              </div>
              {imagesElements.img ? (
                // show image in createTeacher
                <ListImg
                  handleDeleteImg={handleDeleteAvatar}
                  item={imagesElements.img}
                />
              ) : initialValues.avatar_url ? (
                // show image in editUser
                <ListImg
                  item={initialValues.avatar_url}
                  handleDeleteImg={handleDeleteAvatar}
                />
              ) : (
                undefined
              )}
            </Grid>
            {/*
            <Grid item xs={12}>
              <h4 className={classes.sectionLabel}>Horas e dias de trabalho</h4>
            </Grid>
            <Grid container item lg={12} style={{ marginTop: '15px' }}>
              <Grid container xs={12} sm={12} md={8} lg={4} xl={4} spacing={2}>
                <Grid item>
                  <div className={classes.moments}>
                    <label
                      className={classes.fieldLabel}
                      htmlFor='entrance_hour'
                    >
                      Início
                    </label>
                    <Field
                      component={TimePickerInput}
                      id='entrance_hour'
                      name='entrance_hour'
                      placeholder='00:00'
                      value={entranceHourValue}
                      disabled={false}
                      defaultValue={initialValues.entrance_hour}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.moments}>
                    <label className={classes.fieldLabel} htmlFor='exit_hour'>
                      Término
                    </label>
                    <Field
                      component={TimePickerInput}
                      id='exit_hour'
                      name='exit_hour'
                      placeholder='00:00'
                      value={exitHourValue}
                      defaultValue={initialValues.exit_hour}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12} md={8} lg={6} xl={6}>
                <Grid
                  xs={8}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  item
                  style={{ marginRight: '80px' }}
                >
                  <label className={classes.fieldLabel} htmlFor='shift'>
                    Turno
                  </label>
                  <Field component={ShiftSelect} isForTeacher name='shift' />
                </Grid>
                <Grid item lg={6}>
                  <label className={classes.fieldLabel} htmlFor='working_days'>
                    Dias da semana
                  </label>
                  <Field
                    component={DaysSelect}
                    options={[
                      { tooltip: 'Domingo', label: 'D', value: '0' },
                      { tooltip: 'Segunda', label: 'S', value: '1' },
                      { tooltip: 'Terça', label: 'T', value: '2' },
                      { tooltip: 'Quarta', label: 'Q', value: '3' },
                      { tooltip: 'Quinta', label: 'Q', value: '4' },
                      { tooltip: 'Sexta', label: 'S', value: '5' },
                      { tooltip: 'Sábado', label: 'S', value: '6' }
                    ]}
                    defaultValue={initialValues.working_days}
                    name='working_days'
                  />
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item xs={12}>
              <h4 className={classes.sectionLabel}>Endereço</h4>
            </Grid>
            <Grid item xs={6} md={3} sm={6}>
              <label className={classes.fieldLabel} htmlFor='address.zipcode'>
                CEP
              </label>
              <Field
                component={MaterialInput}
                name='address.zipcode'
                placeholder='CEP'
                type='text'
                autoComplete='off'
                normalize={normalizeCEP}
                validate={[validZipcode]}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    searchZipcopde(zipCodeValue)
                  }
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} style={{ alignSelf: 'center' }}>
              <Button
                type='button'
                className={classes.searchBtn}
                variant='outlined'
                onClick={() => searchZipcopde(zipCodeValue)}
                style={{ marginTop: 20 }}
              >
                Procurar
              </Button>
            </Grid>
            <Grid item xs={6} md={5} sm={6}>
              <label className={classes.fieldLabel} htmlFor='address.street'>
                Rua
              </label>
              <Field
                component={MaterialInput}
                name='address.street'
                placeholder='Nome da rua'
                type='text'
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={6} md={2} sm={4}>
              <label className={classes.fieldLabel} htmlFor='address.number'>
                Número
              </label>
              <Field
                component={MaterialInput}
                name='address.number'
                placeholder='Número'
                type='text'
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={6} md={4} sm={5}>
              <label
                className={classes.fieldLabel}
                htmlFor='address.complement'
              >
                Complemento
              </label>
              <Field
                component={MaterialInput}
                name='address.complement'
                placeholder='Complemento'
                type='text'
                autoComplete='off'
              />
            </Grid>
            <Grid className={classes.fieldLabel} item xs={6} sm={5}>
              <label htmlFor='address.neighbourhood'>Bairro</label>
              <Field
                component={MaterialInput}
                name='address.neighbourhood'
                placeholder='Bairro'
                type='text'
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={12} className={classes.btnStyle}>
              <Button
                className={classes.backBtn}
                variant='outlined'
                type='button'
                onClick={() => goBack()}
              >
                Voltar
              </Button>
              <Button
                className={classes.saveBtn}
                onClick={() => {
                  searchExistingUser()
                }}
                disabled={
                  !valid ||
                  !contactIsFilled() ||
                  (currentTeacher && currentTeacher.user_id ? false : pristine)
                }
              >
                {currentTeacher && currentTeacher.user_id
                  ? 'Salvar'
                  : 'Cadastrar'}
              </Button>
            </Grid>
          </Grid>
          <Modal
            open={userAlreadyRegisteredModal.open}
            handleClose={() =>
              setUserAlreadyRegisteredModal(prev => ({ ...prev, open: false }))
            }
          >
            <div>
              {((userAlreadyRegisteredModal.taxpayer_number &&
                !userAlreadyRegisteredModal.email) ||
                (userAlreadyRegisteredModal.email &&
                  !userAlreadyRegisteredModal.taxpayer_number)) && (
                  <div>
                    <h1>
                      {`O usuário possui cadastro ativo com o CPF "${
                      userAlreadyRegistered[0].taxpayer_number
                    }" e e-mail "${userAlreadyRegistered[0].email}"`}
                    </h1>
                    <h2>
                      Ao confirmar, o usuário será cadastrado na escola com os
                      dados preexistentes.
                    </h2>
                    <p>
                      {' '}
                      Em caso de dúvidas ou para tratar incompatibilidades nos
                      dados, entre em contato com o suporte.
                    </p>
                  </div>
              )}
            </div>
            <div className={classes.btnsModal}>
              <Button
                className={classes.cancelBtn}
                onClick={() => {
                  setUserAlreadyRegisteredModal(prev => ({
                    email: false,
                    taxpayer_number: false,
                    open: false
                  }))
                }}
              >
                Cancelar
              </Button>
              <Button
                className={classes.sendBtn}
                onClick={async () => {
                  await change('userToEdit', userAlreadyRegistered[0])
                  dispatch(submit('createTeacherForm'))
                }}
              >
                Confirmar
              </Button>
            </div>
          </Modal>
        </Form>
      </Box>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('createTeacherForm')
  const classroomsTeacher =
    props.currentTeacher?.teacher &&
    props.currentTeacher.teacher.classroom_teachers

  const teacherTimesheet = props.currentTeacher?.teacher?.timesheets?.[0]
  const subjectsTeacher = props.currentTeacher?.teacher_subjects
  let editTimesheetObj = {}
  if (teacherTimesheet) {
    Object.assign(editTimesheetObj, {
      entrance_hour: teacherTimesheet.entrance_hour,
      exit_hour: teacherTimesheet.exit_hour,
      shift: teacherTimesheet.shift,
      working_days: teacherTimesheet.working_days
    })
  }

  const initValues = {
    name: props.currentTeacher?.teacher?.name,
    email: props.currentTeacher?.teacher?.email,
    phone: props.currentTeacher?.teacher?.phone,
    address: props.currentTeacher?.teacher?.address,
    avatar_url: props.currentTeacher?.teacher?.avatar_url,
    contact_from_school: props.currentTeacher?.teacher?.contact_from_school,
    taxpayer_number: props.currentTeacher?.teacher?.taxpayer_number,
    subjects: subjectsTeacher
      ? subjectsTeacher?.map(subject => ({
          label: subject.subject.name,
          value: subject.subject_id,
          subject_id: subject.subject_id
        }))
      : [],
    school_id:
      props.school.school_id ||
      (props.currentTeacher && props.currentTeacher.school_id),
    classroom_teachers_attributes: classroomsTeacher
      ? classroomsTeacher
          .filter(item => {
            return item.classroom.year === new Date().getFullYear()
          })
          .map(classroom => ({
            label: classroom.classroom?.name,
            value: classroom.classroom_id
          }))
      : [],
    user_occupations_attributes: [{ occupation_id: occupationsIds['TEACHER'] }],
    ...editTimesheetObj
  }

  return {
    initialValues: initValues,
    zipCodeValue: selector(state, 'address.zipcode'),
    schoolValue: selector(state, 'school_id'),
    fieldValues: getFormValues('createTeacherForm')(state),
    entranceHourValue: selector(state, 'entrance_hour'),
    exitHourValue: selector(state, 'exit_hour'),
    currentPagination: state.pagination
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('createTeacherForm', field, data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    ),
  backToThePage: data => dispatch(backToThePage(data)),
  getUsers: data => dispatch(getUsers.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'createTeacherForm',
    onSubmit: async (values, dispatch, props) => {
      const {
        // eslint-disable-next-line camelcase
        contact_from_school,
        shift,
        // eslint-disable-next-line camelcase
        working_days,
        // eslint-disable-next-line camelcase
        exit_hour,
        // eslint-disable-next-line camelcase
        entrance_hour,
        userToEdit
      } = values
      // eslint-disable-next-line camelcase
      delete values.contact_from_school
      delete values.userToEdit
      delete values.working_days
      delete values.shift
      // eslint-disable-next-line camelcase
      delete values.exit_hour
      // eslint-disable-next-line camelcase
      delete values.entrance_hour

      const timesheetValues = {
        // eslint-disable-next-line camelcase
        entrance_hour: entrance_hour
          ? moment(entrance_hour).format('HH:mm')
          : null,
        // eslint-disable-next-line camelcase
        exit_hour: exit_hour ? moment(exit_hour).format('HH:mm') : null,
        shift: shift,
        working_days: working_days
      }

      if (props.currentTeacher && props.currentTeacher.user_id) {
        const addTeacherSubject = []
        const findSubject = id => {
          return props.currentTeacher.teacher_subjects.find(
            subject => String(subject.subject_id) === String(id)
          )
        }
        // get only new subject_ids
        values.subjects.forEach(subject => {
          if (subject.subject_id) {
            const teacherSubject = findSubject(subject.subject_id)
            !teacherSubject &&
              addTeacherSubject.push({
                subject_id: subject.subject_id,
                school_id: props.school.school_id
              })
          }
        })
        // subjects to remove from teacher
        const removeTeacherSubject = props.currentTeacher.teacher_subjects
          .filter(subject => {
            const find = values.subjects.some(s => {
              return String(s.subject_id) === String(subject.subject_id)
            })
            return !find && subject.subject_id
          })
          .map(subject => {
            return subject.id
          })

        // if the teacher is already in the classroom, don't send the classroom_id
        const addTeacherClassroom = []
        const findClassroom = id =>
          props.currentTeacher.teacher.classroom_teachers.find(
            classroom => String(classroom.classroom_id) === String(id)
          )
        // get only new classroom_ids
        values.classroom_teachers_attributes.forEach(classroom => {
          if (classroom.classroom_id) {
            const teacherClassroom = findClassroom(classroom.classroom_id)
            !teacherClassroom &&
              addTeacherClassroom.push({ classroom_id: classroom.classroom_id })
          }
        })
        // classrooms to remove from teacher
        const removeTeacherClassroom = props.currentTeacher.teacher.classroom_teachers
          .filter(classroom => {
            const find = values.classroom_teachers_attributes.some(
              c =>
                String(c.classroom_id) === String(classroom.classroom_id) ||
                String(c.value) === String(classroom.classroom_id)
            )
            return !find && classroom.classroom_id
          })
          .map(classroomTeacher => classroomTeacher.id)
        let newValuesToEdit = {
          ...values,
          classroom_teachers_attributes: addTeacherClassroom,
          subjects: addTeacherSubject
        }
        if (props?.currentTeacher?.teacher?.timesheets[0]?.id) {
          Object.assign(newValuesToEdit, {
            timesheetValues: {
              ...timesheetValues,
              id: props.currentTeacher.teacher.timesheets[0].id
            }
          })
          // eslint-disable-next-line camelcase
        } else if (entrance_hour || exit_hour || shift || working_days) {
          Object.assign(newValuesToEdit, {
            timesheetValues: {
              ...timesheetValues
            }
          })
        }

        await props.editClassroomTeacher({
          id: props.currentTeacher.user_id,
          data: {
            ...newValuesToEdit,
            removeTeacherClassroom,
            removeTeacherSubject,
            contact_from_school: contact_from_school.toString() === 'true'
          }
        })
      } else {
        // create
        props.createClassroomTeacher({
          ...values,
          timesheetValues,
          userToEdit,
          contact_from_school: contact_from_school.toString() === 'true'
        })
      }
    }
  })(CreateTeacherForm)
)
