import React from 'react'
import { connect } from 'react-redux'
import { useHistory, NavLink } from 'react-router-dom'

import { Hidden, Drawer, Divider, Select, InputLabel } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import styled from '@emotion/styled'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import Collapse from '@material-ui/core/Collapse'
import Badge from '@material-ui/core/Badge'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Popover from '@material-ui/core/Popover'
import UserAvatar from '../shared/UserAvatar'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import CachedIcon from '@material-ui/icons/Cached'
import SettingsIcon from '@material-ui/icons/Settings'
import addSVG from '../../assets/icons/add-icon-toolbar.svg'
import notificationsSVG from '../../assets/icons/notifications.svg'
import calendarSVG from '../../assets/icons/calendar.svg'
import { KeyboardArrowDown } from '@material-ui/icons'
import homepageSVG from '../../assets/icons/homepage.svg'
import { switchUser, LOGOUT as logout } from '../../store/auth/actions'
import {
  setSideBarStatus,
  setConfigExpanded,
  setSocioExpanded
} from '../../store/sidebar/actions'
import { roles } from '../../utils/constants'
import { getPendingCommunicationsRSPA } from '../../store/communications/actions'

import { occupationsObject } from '../../utils/dicts'
import MessagesMenu from './MessagesMenu'
import { switchSchool } from '../../store/school/actions'
import MiniDrawer from './MiniDrawerMenu'
import StyledSVG from './../shared/StyledSVG'
import miniLogoDefault from '../../assets/minilogo-versar.svg'
import logoDefault from '../../assets/logo_versar.png'

const drawerWidth = 250

const MessageContainer = styled.div`
  position: absolute;
  border-radius: 16px;
  background-color: rgb(157, 210, 218);
  top: ${({ top }) => `${top}px`};
  color: black;
  padding: 8px;
  border-radius: 4px;
  z-index: 999;
`

const useStyles = makeStyles(theme => ({
  appBar: {
    background: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    '& .MuiToolbar-regular': {
      width: '100%',
      padding: '0 32px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 10px'
      }
    }
  },
  logo: {
    width: '200px',
    padding: '10px 0',
    marginBottom: '30px'
  },
  user: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#555555',
    marginLeft: 'auto'
  },
  welcome: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  userIcon: {
    fill: theme.palette.primary.main
  },
  menuBurguer: {
    color: theme.palette.primary.main
  },
  list: {
    paddingTop: '20px'
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  menuExitIcon: {
    width: 50
  },
  // necessary for content to be below app bar
  // toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  content: {
    overflow: 'hidden',
    gridArea: 'content',
    padding: '20px 30px 20px 30px',
    minHeight: 'calc(100vh - 65px)'
  },
  appSearchAuth: {
    boxShadow: '-10px 10px 10px 0px #0000000D',
    overflowX: 'auto',
    display: 'flex',
    alignItems: 'center',
    width: '97%',
    [theme.breakpoints.up('sm')]: {
      minHeight: 50
    },
    padding: '0.5rem',
    background: 'white',
    borderRadius: '16px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('820')]: {
      justifyContent: 'space-around'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'

      // '& .makeStyles-root-275': {
      //   background: '#F6F7F8'
      // }
    }
  },
  msgIcon: {
    cursor: 'pointer',
    marginRight: '1rem',
    '& .MuiBadge-colorSecondary': {
      color: '#FFF'
    }
  },
  nullMessage: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  popover: {
    height: 150,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  dashboard: {
    [theme.breakpoints.up('sm')]: {
      display: 'grid'
    },
    backgroundColor: '#F0F3F4',
    gridTemplateAreas: `
      "sidebar navbar"
      "sidebar content"
    `
  },
  selectedOccupation: {
    '& svg': {
      color: '#8B598E'
    }
  },
  selectedSchool: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: 10,
    borderRadius: 16,
    backgroundColor: '#F5F5F5',
    '& svg': {
      color: '#8B598E'
    },
    '& #demo-simple-select': {
      width: 150
    },
    [theme.breakpoints.down('880')]: {
      height: 32,
      '& .MuiInputBase-root': {
        width: 100
      }
    },
    [theme.breakpoints.down('820')]: {
      '& .MuiInputBase-root': {
        width: 150
      }
    },
    [theme.breakpoints.down('400')]: {
      '& .MuiInputBase-root': {
        width: 90
      }
    }
  },
  toolbar: {
    // overflow: 'hidden',
    paddingTop: 10,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gridArea: 'navbar'
  },
  homepage: {
    cursor: 'pointer',
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
      marginLeft: 15
    },
    [theme.breakpoints.down('950')]: {
      '& span': {
        display: 'none'
      }
    },
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    '& span': {
      color: '#868E96',
      fontSize: 14
    }
  },
  toolbarSecondSection: {
    display: 'flex',
    alignItems: 'center',
    gap: 40,
    [theme.breakpoints.down('820')]: {
      gap: 25
    }
  },
  verticalBar: {
    height: 23,
    width: 0,
    border: '1px solid #D2D5D8',
    [theme.breakpoints.down('820')]: {
      display: 'none'
    }
  },
  perfilComponent: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: 200,
    // minWidth: 200,
    minHeight: 35,
    borderRadius: 16,
    border: '0.5px solid #D2D5D8',
    [theme.breakpoints.down('1150')]: {
      width: 80,
      '& .userAttributes': {
        display: 'none !important'
      }
    },
    [theme.breakpoints.down('400')]: {
      width: 60,
      '& .userAttributes': {
        display: 'none !important'
      }
    }
  },
  arrowIcon: {
    color: '#8B598E',
    width: 20,
    height: 20,
    transform: 'rotate(0deg)', // Inicialmente sem rotação
    transition: 'transform 0.3s ease' // Adicione uma transição suave
  },
  rotated: {
    transform: 'rotate(180deg)' // Aplica a rotação de 180 graus quando 'rotated' é adicionado à classe
  },
  iconsOptions: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    [theme.breakpoints.down('820')]: {
      display: 'none'
    }
  }
}))

const mapStateToProps = ({ auth, school, communications, sidebar }) => ({
  communications: communications,
  school: school,
  user: auth,
  sidebar: sidebar
})

const mapDispatchToProps = dispatch => ({
  setSideBarStatus: data => dispatch(setSideBarStatus(data)),
  setConfigExpanded: data => dispatch(setConfigExpanded(data)),
  setSocioExpanded: data => dispatch(setSocioExpanded(data)),
  switchUser: data => dispatch(switchUser(data)),
  logout: data => dispatch(logout(data)),
  switchSchool: data => dispatch(switchSchool(data)),
  getPendingCommunicationsRSPA: data =>
    dispatch(getPendingCommunicationsRSPA.request(data))
})

const MenuApp = ({
  window,
  children,
  logout,
  user,
  school,
  communications,
  switchUser,
  switchSchool,
  getPendingCommunicationsRSPA,
  setSideBarStatus,
  setConfigExpanded,
  setSocioExpanded,
  sidebar
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [open, setOpen] = React.useState({
    socio: true,
    config: false
  })
  const [search] = React.useState({})
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorMailEl, setAnchorMailEl] = React.useState(null)
  const [schoolsByUser, setSchoolsByUser] = React.useState(null)

  const [requestCounter, setRequestCounter] = React.useState(0)
  const [selectedOccupation, setSelectedOccupation] = React.useState(null)
  const [selectedSchool, setSelectedSchool] = React.useState(null)
  const [pendingCommunications, setPendingCommunications] = React.useState([])
  const [openProfile, setOpenProfile] = React.useState(false)
  const openMail = Boolean(anchorMailEl)
  const [showMessage, setShowMessage] = React.useState({
    homepage: false,
    addSocio: false,
    calendar: false
  })
  const [position, setPosition] = React.useState({ top: 0 })
  const handleClickUnmadePage = (e, from) => {
    const rect = e.target.getBoundingClientRect()
    setPosition({
      top: rect.bottom - 15
    })
    setShowMessage(prev => ({ ...prev, [from]: true }))
    setTimeout(() => setShowMessage(prev => ({ ...prev, [from]: false })), 500)
  }
  const frontVersion = 'v1.6.0'
  const occupations = user.currentOccupation
  const isCoordinator = occupations?.includes(roles.COORDINATOR)
  const isAdmin =
    occupations?.includes(roles.SCHOOL_ADMIN) ||
    occupations?.includes(roles.VERSAR_ADMIN)
  const schoolLogo = occupations?.includes(roles.VERSAR_ADMIN)
    ? logoDefault
    : (school?.currentSchool && school?.currentSchool?.school?.logo_url) ||
      logoDefault
  const miniLogo = occupations?.includes(roles.VERSAR_ADMIN)
    ? miniLogoDefault
    : (school?.currentSchool && school?.currentSchool?.school?.minilogo_url) ||
      miniLogoDefault
  // has access to menu options

  const accessVersarProgram = [
    roles.VERSAR_ADMIN,
    roles.SCHOOL_ADMIN,
    roles.CONTENT_CREATOR
  ]
  const accessClassrooms = [
    roles.VERSAR_ADMIN,
    roles.SCHOOL_ADMIN,
    roles.TEACHER
  ]
  const accessTemplates = [roles.VERSAR_ADMIN]
  const accessPublishContent = [roles.SCHOOL_ADMIN]
  const accessPublishContentAdmin = [roles.VERSAR_ADMIN]
  const accessConfigurationVersar = [roles.VERSAR_ADMIN]
  const accessConfigurationSchool = [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN]
  const accessAnnouncementsAndReports = [
    roles.VERSAR_ADMIN,
    roles.SCHOOL_ADMIN,
    roles.TEACHER
  ]
  const accessSwitchSchoolOrOccupation = [
    roles.SCHOOL_ADMIN,
    roles.TEACHER,
    roles.COORDINATOR
  ]
  const accessUtils = [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.TEACHER]
  const accessMessagesAdmin = [roles.VERSAR_ADMIN]
  const accessMessages = [
    roles.SCHOOL_ADMIN,
    roles.TEACHER,
    roles.SCHOOL_MODERATOR,
    roles.CLASSROOM_MODERATOR
  ]
  function encodeQueryData (data) {
    const ret = []
    for (let d in data) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    }
    return ret.join('&')
  }
  const ssoUrl = {
    local: 'http://localhost:8000/',
    staging: 'https://staging-fractalid.fractaltecnologia.com.br/',
    homolog: 'https://staging-fractalid.fractaltecnologia.com.br/',
    production: 'https://fractalid.fractaltecnologia.com.br/'
  }
  const { REACT_APP_PROJECT_ID, REACT_APP_SERVER_ENV = 'local' } = process.env

  const data = {
    app_id: REACT_APP_PROJECT_ID || 34
  }
  const urlString = encodeQueryData(data)
  const teacherNonContact =
    !user?.user?.data?.attributes?.contact_from_school &&
    (occupations?.[0] === roles.TEACHER ||
      occupations?.[0] === roles.COORDINATOR)
  const url = `${ssoUrl[REACT_APP_SERVER_ENV]}?${urlString}`
  const schoolOccupationItems = [
    ...(isAdmin
      ? school?.items?.schoolAdmin?.length > 0 ? school?.items?.schoolAdmin : []
      : isCoordinator
        ? school?.items?.schoolCoordinator?.length > 0
          ? school?.items?.schoolCoordinator
          : []
        : school?.items?.schoolTeacher?.length > 0
          ? school?.items?.schoolTeacher
          : [])
  ]
  // reset redux store to set the new initialState from schoolReducer
  // before schools.items was array and now is an object
  React.useEffect(
    () => {
      if (school?.items?.length) {
        logout()
      }
    },
    [school?.items]
  )

  React.useEffect(
    () => {
      const getPendingCommunications = async () => {
        try {
          await getPendingCommunicationsRSPA({
            userId: user.user.data.id,
            schoolId: school?.currentSchool?.school_id
          }).then(res => {
            const messageData = res?.data?.data?.map(item => item.attributes)
            setPendingCommunications({
              data: messageData,
              meta: { total_count: messageData?.length }
            })
          })
        } catch (err) {
          console.error(err)
        }
      }
      if (school?.currentSchool?.school_id) {
        getPendingCommunications()
      }
    },
    [school?.currentSchool?.school_id]
  )

  React.useEffect(
    () => {
      setSelectedOccupation(user.currentOccupation)
      if (localStorage.getItem('canDispatchAll') === 'true') {
        switchSchool(schoolOccupationItems[0])
        setSelectedSchool(prev => schoolOccupationItems[0])
        localStorage.setItem('canDispatchAll', false)
      }
    },
    [user.currentOccupation]
  )
  React.useEffect(
    () => {
      setSelectedSchool(prev => school.currentSchool)
      schoolOccupationItems.length > 0 &&
        setSchoolsByUser(prev => {
          const newState = schoolOccupationItems
            ?.map(item => {
              return item?.school
            })
            ?.filter((item, index, self) => self.indexOf(item) === index)
          return newState
        })
    },
    [school.currentSchool]
  )

  const handleClick = req => {
    const option = req.target.id
    if (option === 'socio') {
      setOpen({ ...open, socio: !open.socio })
    } else if (option === 'config') {
      setOpen({ ...open, config: !open.config })
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleMenu = event => {
    setOpenProfile(!openProfile)
    setAnchorEl(event.currentTarget)
  }

  const handleClickMail = event => {
    setAnchorMailEl(event.currentTarget)
  }

  const handleCloseMail = () => {
    setRequestCounter(0)
    setAnchorMailEl(null)
  }

  const hasAccess = permissionList => {
    const hasAccess = occupations
      ? occupations.filter(occ => permissionList.some(permit => occ === permit))
      : []
    return hasAccess.length > 0
  }
  const projectId = process.env.REACT_APP_PROJECT_ID
  const drawer = (
    <div className={classes.container}>
      <div className={classes.toolbar} />
      <List className={classes.list}>
        <ListItem component={NavLink} to={'/'}>
          <img src={schoolLogo} alt='Logo da escola' className={classes.logo} />
        </ListItem>
        {hasAccess(accessVersarProgram) && (
          <div style={projectId.toString() === '42' ? { display: 'none' } : {}}>
            <ListItem
              id={'socio'}
              className={classes.listItem}
              onClick={handleClick}
            >
              Socioemocional
              {open.socio ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </ListItem>
            <Collapse in={open.socio} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <ListItem
                  component={NavLink}
                  to={'/modules'}
                  className={`${classes.link} ${classes.nested}`}
                  activeClassName='Mui-selected'
                >
                  Módulos
                </ListItem>
                <ListItem
                  component={NavLink}
                  to={'/themes'}
                  className={`${classes.link} ${classes.nested}`}
                  activeClassName='Mui-selected'
                >
                  Temas
                </ListItem>
                <ListItem
                  component={NavLink}
                  to={'/contents'}
                  className={`${classes.link} ${classes.nested}`}
                  activeClassName='Mui-selected'
                >
                  Conteúdos
                </ListItem>
                {hasAccess(accessPublishContent) && (
                  <ListItem
                    component={NavLink}
                    className={`${classes.nested} ${classes.link}`}
                    to={'/theme-schedule/grades'}
                    activeClassName='Mui-selected'
                  >
                    Publicar conteúdo
                  </ListItem>
                )}
                {hasAccess(accessPublishContentAdmin) && (
                  <ListItem
                    component={NavLink}
                    className={`${classes.nested} ${classes.link}`}
                    to={'/theme-schedule/schools'}
                    activeClassName='Mui-selected'
                  >
                    Publicar conteúdo
                  </ListItem>
                )}
                {hasAccess(accessTemplates) && (
                  <ListItem
                    component={NavLink}
                    className={`${classes.nested} ${classes.link}`}
                    to={'/planning/grades'}
                    activeClassName='Mui-selected'
                  >
                    Templates
                  </ListItem>
                )}
              </List>
            </Collapse>
          </div>
        )}
        <>
          {user.currentOccupation[0] === 'parent' ? (
            <div />
          ) : (
            <>
              {hasAccess(accessAnnouncementsAndReports) && (
                <ListItem
                  component={NavLink}
                  className={`${classes.listItem} ${classes.link}`}
                  to={'/announcements?name=list&event=communications'}
                  activeClassName='Mui-selected'
                >
                  Comunicados
                </ListItem>
              )}
              {hasAccess(accessMessages) && (
                <ListItem
                  component={NavLink}
                  className={`${classes.listItem} ${classes.link}`}
                  to={'/messages'}
                  activeClassName='Mui-selected'
                >
                  <Badge
                    overlap='rectangular'
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    color='secondary'
                    invisible={
                      pendingCommunications?.meta
                        ? pendingCommunications?.meta?.total_count === 0
                        : true
                    }
                    variant='dot'
                  >
                    Mensagens
                  </Badge>
                </ListItem>
              )}
              {hasAccess(accessMessagesAdmin) && (
                <ListItem
                  component={NavLink}
                  className={`${classes.listItem} ${classes.link}`}
                  to={'/messages/schools'}
                  activeClassName='Mui-selected'
                >
                  <Badge
                    overlap='rectangular'
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    color='secondary'
                    invisible={
                      pendingCommunications?.meta
                        ? pendingCommunications?.meta?.total_count === 0
                        : true
                    }
                    variant='dot'
                  >
                    Mensagens
                  </Badge>
                </ListItem>
              )}
            </>
          )}
        </>

        {hasAccess(accessAnnouncementsAndReports) && (
          <ListItem
            component={NavLink}
            className={`${classes.listItem} ${classes.link}`}
            to={'/reports?name=history'}
            activeClassName='Mui-selected'
          >
            Diário de Turma
          </ListItem>
        )}
        {hasAccess(accessClassrooms) && (
          <ListItem
            component={NavLink}
            className={`${classes.listItem} ${classes.link}`}
            to={'/classrooms'}
            activeClassName='Mui-selected'
          >
            Turmas
          </ListItem>
        )}
        {user.currentOccupation[0] === 'parent' ? (
          <></>
        ) : (
          hasAccess(accessUtils) && (
            <ListItem
              component={NavLink}
              className={`${classes.listItem} ${classes.link}`}
              to={'/utils?name=list&util=menus'}
              activeClassName='Mui-selected'
            >
              Mural
            </ListItem>
          )
        )}
        {(hasAccess(accessConfigurationVersar) ||
          hasAccess(accessConfigurationSchool)) && (
          <>
            <ListItem
              id={'config'}
              onClick={handleClick}
              component={NavLink}
              className={`${classes.listItem}`}
              to={'/settings'}
              activeClassName='Mui-selected'
            >
              Configurações
              {open.config ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </ListItem>
            <Collapse in={open.config} timeout='auto' unmountOnExit>
              {hasAccess(accessConfigurationVersar) && (
                <List component='div' disablePadding>
                  <ListItem
                    component={NavLink}
                    to={'/settings/covers'}
                    className={`${classes.link} ${classes.nested}`}
                    activeClassName='Mui-selected'
                    exact
                  >
                    Capas
                  </ListItem>
                </List>
              )}
              {hasAccess(accessConfigurationSchool) && (
                <List component='div' disablePadding>
                  <ListItem
                    component={NavLink}
                    to={'/settings/classrooms'}
                    className={`${classes.link} ${classes.nested}`}
                    activeClassName='Mui-selected'
                    exact
                  >
                    Turmas
                  </ListItem>
                  <ListItem
                    component={NavLink}
                    to={'/settings/teachers'}
                    className={`${classes.link} ${classes.nested}`}
                    activeClassName='Mui-selected'
                    exact
                  >
                    Docentes
                  </ListItem>
                  <ListItem
                    component={NavLink}
                    to={'/settings/layout'}
                    className={`${classes.link} ${classes.nested}`}
                    activeClassName='Mui-selected'
                    exact
                  >
                    Layout
                  </ListItem>
                  <ListItem
                    component={NavLink}
                    to={'/settings/historic'}
                    className={`${classes.link} ${classes.nested}`}
                    activeClassName='Mui-selected'
                    exact
                  >
                    Configurações de usuários
                  </ListItem>
                </List>
              )}
            </Collapse>
          </>
        )}
      </List>
    </div>
  )

  const getSchoolByName = name => {
    const schoolObject = schoolOccupationItems?.find(
      schoolItem => schoolItem.school.name === name
    )
    return schoolObject
  }

  const handleChangeOccupation = event => {
    switchUser({
      occupation: event.target.value,
      id: user.user.data.id,
      history: history
    })
  }

  const handleChangeSchool = event => {
    const schl = getSchoolByName(event.target.value)
    switchSchool(schl)
    history.push('/')
  }

  const occupationsFiltered = user.user.data?.attributes?.occupations?.filter(
    item => {
      const allowOccupations = [
        'administrator',
        'school_administrator',
        'coordinator',
        'student',
        'teacher',
        'parent'
      ]
      return allowOccupations.includes(item)
    }
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <div
      style={{ gridTemplateColumns: sidebar.open ? '1fr 10fr' : '0.5fr 12fr' }}
      className={classes.dashboard}
    >
      {/* sidebar (mobile) */}
      <Hidden smUp implementation='css'>
        <Drawer
          container={container}
          variant='temporary'
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {drawer}
          <span>{frontVersion}</span>
        </Drawer>
      </Hidden>
      {/* sidebar (desktop) */}
      <Hidden xsDown implementation='css'>
        <MiniDrawer
          setConfigExpanded={setConfigExpanded}
          setSocioExpanded={setSocioExpanded}
          teacherNonContact={teacherNonContact}
          school={school}
          miniLogo={miniLogo}
          openSidebar={setSideBarStatus}
          sidebarControl={sidebar}
          logo={schoolLogo}
          logout={logout}
          frontVersion={frontVersion}
          occupations={occupations}
        />
      </Hidden>
      {/* header */}
      <Toolbar className={classes.toolbar}>
        <div className={classes.appSearchAuth}>
          <Hidden smUp implementation='css'>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon className={classes.menuBurguer} />
            </IconButton>
          </Hidden>
          <Hidden xsDown implementation='css'>
            <div
              onClick={event => handleClickUnmadePage(event, 'homepage')}
              className={classes.homepage}
            >
              <StyledSVG src={homepageSVG} width={18} height={18} />
              <span>Homepage</span>
            </div>
            {showMessage.homepage && (
              <MessageContainer top={position.top}>
                Estamos trabalhando nisso!
              </MessageContainer>
            )}
          </Hidden>
          <div className={classes.toolbarSecondSection}>
            {hasAccess(accessSwitchSchoolOrOccupation) && (
              <>
                <div className={classes.selectedSchool}>
                  <div
                    style={{
                      width: 18,
                      height: 18,
                      borderRadius: '50%',
                      marginLeft: 8,
                      backgroundColor: selectedSchool?.school?.primary_color
                    }}
                  />
                  <Select
                    labelId='demo-simple-select-label'
                    className='select'
                    disableUnderline
                    IconComponent={KeyboardArrowDown}
                    id='demo-simple-select'
                    value={selectedSchool?.school?.name || null}
                    onChange={event => {
                      schoolsByUser?.length > 1
                        ? handleChangeSchool(event)
                        : undefined
                    }}
                  >
                    {schoolsByUser?.length > 0 &&
                      schoolsByUser
                        .filter(
                          (school, index, self) =>
                            index ===
                            self.findIndex(schl => schl.id === school.id)
                        )
                        .map(schl => {
                          return (
                            <MenuItem key={schl.name} value={schl.name}>
                              {schl.name}
                            </MenuItem>
                          )
                        })}
                  </Select>
                </div>
                <div className={classes.verticalBar} />
                <div className={classes.iconsOptions}>
                  <StyledSVG
                    style={{ cursor: 'pointer' }}
                    onClick={event => handleClickUnmadePage(event, 'addSocio')}
                    src={addSVG}
                    width={18}
                    height={18}
                  />
                  {showMessage.addSocio && (
                    <MessageContainer top={position.top} left={position.left}>
                      Estamos trabalhando nisso!
                    </MessageContainer>
                  )}
                  {!teacherNonContact && (
                    <Badge
                      overlap='rectangular'
                      style={{ cursor: 'pointer' }}
                      badgeContent={
                        pendingCommunications &&
                        pendingCommunications?.meta?.total_count
                      }
                      onClick={handleClickMail}
                    >
                      <StyledSVG
                        src={notificationsSVG}
                        width={18}
                        height={18}
                        aria-describedby={
                          openMail ? 'simple-popover' : undefined
                        }
                      />
                    </Badge>
                  )}
                  <Popover
                    id={openMail ? 'simple-popover' : undefined}
                    open={openMail}
                    anchorEl={anchorMailEl}
                    onClose={handleCloseMail}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    {pendingCommunications &&
                    !!pendingCommunications?.meta?.total_count > 0 ? (
                      <div className={classes.popover}>
                        <CachedIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            getPendingCommunicationsRSPA({
                              userId: user.user.data.id,
                              schoolId: school?.currentSchool?.school_id
                            }).then(res => {
                              setRequestCounter(requestCounter + 1)
                              const messageData = res?.data?.data?.map(
                                item => item.attributes
                              )
                              setPendingCommunications({
                                data: messageData,
                                meta: { total_count: messageData?.length }
                              })
                            })
                          }}
                        />
                        <MessagesMenu
                          // loadMoreData={loadMoreData}
                          // hasNextPage={hasNextPage}
                          history={history}
                          messages={pendingCommunications}
                          userId={user.user.data?.id}
                        />
                      </div>
                    ) : (
                      <div className={classes.popover}>
                        <CachedIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            getPendingCommunicationsRSPA({
                              userId: user.user.data.id,
                              schoolId: school?.currentSchool?.school_id
                            }).then(res => {
                              setRequestCounter(requestCounter + 1)
                              const messageData = res?.data?.data?.map(
                                item => item.attributes
                              )
                              setPendingCommunications({
                                data: messageData,
                                meta: { total_count: messageData?.length }
                              })
                            })
                          }}
                        />
                        <div className={classes.nullMessage}>
                          <label>{`Você não possui mensagens pendentes.${
                            requestCounter > 0 ? `(${requestCounter})` : ''
                          }`}</label>
                        </div>
                      </div>
                    )}
                  </Popover>
                  <StyledSVG
                    style={{ cursor: 'pointer' }}
                    onClick={event => handleClickUnmadePage(event, 'calendar')}
                    src={calendarSVG}
                    width={18}
                    height={18}
                  />
                  {showMessage.calendar && (
                    <MessageContainer top={position.top} left={position.left}>
                      Estamos trabalhando nisso!
                    </MessageContainer>
                  )}
                </div>
                <div className={classes.verticalBar} />
              </>
            )}
            <div className={classes.perfilComponent} onClick={handleMenu}>
              <UserAvatar
                avatarStyle={{
                  width: 27,
                  height: 27,
                  marginLeft: 4,
                  marginRight: '10px'
                }}
                withoutUserName
                userName={user.user.data?.attributes?.name}
                avatarUrl={user.user.data?.attributes?.avatar_url}
              />
              <div
                className='userAttributes'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
              >
                <span style={{ fontSize: 14, color: '#386093' }}>
                  {user.user.data?.attributes?.name.slice(0, 20)}
                </span>
                <span style={{ fontSize: 12, color: '#868E96' }}>
                  {occupationsObject[selectedOccupation]}
                </span>
              </div>

              <KeyboardArrowDown
                className={`${classes.arrowIcon} ${
                  openProfile ? classes.rotated : ''
                }`}
              />

              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={openProfile}
                onClose={() => setOpenProfile(false)}
                getContentAnchorEl={null}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px'
                  }}
                >
                  {selectedOccupation && (
                    <span>{occupationsObject[selectedOccupation]}</span>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      lexDirection: 'row',
                      alignItems: 'center',
                      marginTop: '20px',
                      marginBottom: '15px'
                    }}
                  >
                    <UserAvatar
                      avatarStyle={{
                        marginLeft: 4,
                        marginRight: '10px'
                      }}
                      withoutUserName
                      userName={user.user.data?.attributes?.name}
                      avatarUrl={user.user.data?.attributes?.avatar_url}
                    />
                    <span>{user.user.data?.attributes?.name}</span>
                  </div>
                  {occupationsFiltered?.length > 1 && (
                    <>
                      <InputLabel id='demo-simple-select-label'>
                        Selecione uma ocupação
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={selectedOccupation}
                        onChange={event => {
                          const executeChange = () => {
                            localStorage.setItem('canDispatchAll', true)
                            handleChangeOccupation(event)
                          }
                          occupationsFiltered.length > 1
                            ? executeChange()
                            : undefined
                        }}
                      >
                        {occupationsFiltered.map(occupation => {
                          return (
                            <MenuItem key={occupation} value={occupation}>
                              {occupationsObject[occupation]}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </>
                  )}
                </div>
                <Divider />
                <MenuItem>
                  <a
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textDecoration: 'none',
                      color: 'inherit'
                    }}
                    href={url}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <SettingsIcon className={classes.menuExitIcon} />
                    Editar perfil
                  </a>
                </MenuItem>
                <MenuItem onClick={logout}>
                  <ExitToAppIcon className={classes.menuExitIcon} />
                  <span>Sair</span>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </Toolbar>
      {/* body */}
      <main className={classes.content}>
        {React.cloneElement(children, { search: search })}
      </main>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuApp)
