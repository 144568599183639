import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import NewCreateThemeForm from '../components/form/NewCreateThemeForm'
import { getModuleById } from '../store/modules/actions'
import { newGetThemeById } from '../store/themes/actions'
import SearchThemesForm from '../components/form/SearchThemesForm'

const NewThemesPage = ({
  componentAttribute,
  match,
  currentMaterial,
  isFetching,
  userOccupations,
  getModuleById,
  newGetThemeById
}) => {
  const history = useHistory()
  const location = useLocation()

  if (match.params.moduleId) {
    getModuleById({ ids: match.params.moduleId })
  } else if (match.params.themeId) {
    newGetThemeById({ id: match.params.themeId })
  }

  if (componentAttribute?.createByModules === true) {
    return <NewCreateThemeForm step={0} location={location} history={history} />
  } else if (componentAttribute?.stepTwo === true) {
    return <NewCreateThemeForm step={1} location={location} history={history} />
  }
  return <SearchThemesForm />
}

const mapStateToProps = (state, props) => {
  return {
    currentSchool: state.school.currentSchool.school
  }
}

const mapDispatchToProps = dispatch => ({
  getModuleById: data => dispatch(getModuleById(data)),
  newGetThemeById: data => dispatch(newGetThemeById(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewThemesPage)
