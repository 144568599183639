import React from 'react'
import PropTypes from 'prop-types'

import Menu from '../menu/Menu'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    background: '#F6F7F8',
    minHeight: '100vh',
    boxSizing: 'border-box'
  }
}))

const DashboardTemplate = ({ children, match }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Menu>{children}</Menu>
    </div>
  )
}

DashboardTemplate.propTypes = {
  match: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.array
  ]).isRequired
}

export default DashboardTemplate
