export const GET_MODULES = 'modules/GET_MODULES'
export const GET_MODULES_SUCCESSFUL = 'modules/GET_MODULES_SUCCESSFUL'
export const GET_MODULES_FAILURE = 'modules/GET_MODULES_FAILURE'

export const GET_MODULE_BY_ID = 'modules/GET_MODULE_BY_ID'
export const GET_MODULE_BY_ID_SUCCESSFUL = 'modules/GET_MODULE_BY_ID_SUCCESSFUL'
export const GET_MODULE_BY_ID_FAILURE = 'modules/GET_MODULE_BY_ID_FAILURE'
export const GET_MODULE_THEMES = 'modules/GET_MODULE_THEMES'
export const GET_MODULE_THEMES_SUCCESFUL = 'modules/GET_MODULE_THEMES_SUCCESFUL'
export const GET_MODULE_THEMES_FAILURE = 'modules/GET_MODULE_THEMES_FAILURE'
export const CLEAR_CURRENT_ITEM = 'modules/CLEAR_CURRENT_ITEM'

export const NEW_GET_MODULE_THEMES = 'modules/NEW_GET_MODULE_THEMES'
export const NEW_GET_MODULE_THEMES_SUCCESFUL =
  'modules/NEW_GET_MODULE_THEMES_SUCCESFUL'
export const NEW_GET_MODULE_THEMES_FAILURE =
  'modules/NEW_GET_MODULE_THEMES_FAILURE'

export const NEW_GET_MODULES = 'modules/NEW_GET_MODULES'
export const NEW_GET_MODULES_SUCCESSFUL = 'modules/NEW_GET_MODULES_SUCCESSFUL'
export const NEW_GET_MODULES_FAILURE = 'modules/NEW_GET_MODULES_FAILURE'

export const CREATE_MODULE = 'modules/CREATE_MODULE'
export const CREATE_MODULE_SUCCESSFUL = 'modules/CREATE_MODULE_SUCCESSFUL'
export const CREATE_MODULE_FAILURE = 'modules/CREATE_MODULE_FAILURE'

export const EDIT_MODULE = 'modules/EDIT_MODULE'
export const EDIT_MODULE_SUCCESSFUL = 'modules/EDIT_MODULE_SUCCESSFUL'
export const EDIT_MODULE_FAILURE = 'modules/EDIT_MODULE_FAILURE'

export const DELETE_MODULE = 'modules/DELETE_MODULE'
export const DELETE_MODULE_SUCCESSFUL = 'modules/DELETE_MODULE_SUCCESSFUL'
export const DELETE_MODULE_FAILURE = 'modules/DELETE_MODULE_FAILURE'
