export const GET_UTILS = 'utils/GET_UTILS'
export const GET_UTILS_SUCCESSFUL = 'utils/GET_UTILS_SUCCESSFUL'
export const GET_UTILS_FAILURE = 'utils/GET_UTILS_FAILURE'

export const GET_WRAPPER_UTILS = 'utils/GET_WRAPPER_UTILS'
export const GET_WRAPPER_UTILS_SUCCESSFUL = 'utils/GET_WRAPPER_UTILS_SUCCESSFUL'
export const GET_WRAPPER_UTILS_FAILURE = 'utils/GET_WRAPPER_UTILS_FAILURE'

export const GET_UTIL_BY_ID = 'utils/GET_UTIL_BY_ID'
export const GET_UTIL_BY_ID_SUCCESSFUL = 'utils/GET_UTIL_BY_ID_SUCCESSFUL'
export const GET_UTIL_BY_ID_FAILURE = 'utils/GET_UTIL_BY_ID_FAILURE'

export const CREATE_UTILS = 'utils/CREATE_UTILS'
export const CREATE_UTILS_SUCCESSFUL = 'utils/CREATE_UTILS_SUCCESSFUL'
export const CREATE_UTILS_FAILURE = 'utils/CREATE_UTILS_FAILURE'

export const GET_UTIL_DESTINATION_BY_ID = 'utils/GET_UTIL_DESTINATION_BY_ID'
export const GET_UTIL_DESTINATION_BY_ID_SUCCESSFUL =
  'utils/GET_UTIL_DESTINATION_BY_ID_SUCCESSFUL'
export const GET_UTIL_DESTINATION_BY_ID_FAILURE =
  'utils/GET_UTIL_DESTINATION_BY_ID_FAILURE'

export const DELETE_UTIL = 'utils/DELETE_UTIL'
export const DELETE_UTIL_SUCCESSFUL = 'utils/DELETE_UTIL_SUCCESSFUL'
export const DELETE_UTIL_FAILURE = 'utils/DELETE_UTIL_FAILURE'

export const EDIT_UTIL = 'utils/EDIT_UTIL'
export const EDIT_UTIL_SUCCESSFUL = 'utils/EDIT_UTIL_SUCCESSFUL'
export const EDIT_UTIL_FAILURE = 'utils/EDIT_UTIL_FAILURE'

export const EDIT_WRAPPER = 'utils/EDIT_WRAPPER'
export const EDIT_WRAPPER_SUCCESSFUL = 'utils/EDIT_WRAPPER_SUCCESSFUL'
export const EDIT_WRAPPER_FAILURE = 'utils/EDIT_WRAPPER_FAILURE'

export const SET_CURRRENT_UTIL = 'utils/SET_CURRRENT_UTIL'
export const CLEAN_CURRRENT_UTIL = 'utils/CLEAN_CURRRENT_UTIL'
export const CLEAN_CURRRENT_DESTINATIONS_UTIL =
  'utils/CLEAN_CURRRENT_DESTINATIONS_UTIL'
