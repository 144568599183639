import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { CircularProgress, Paper } from '@material-ui/core'
import MessageView from '../components/view/MessageView'
import {
  cleanSuccess,
  getCommunicationsById,
  updateCommunication,
  updateMessage,
  respondMessages
} from '../store/communications/actions'

const mapStateToProps = ({ auth, communications, school }) => {
  return {
    userId: auth.user.data?.id,
    userOccuppations: auth.user.data?.attributes?.occupations,
    isFetching: communications.isFetching,
    currentCommunication: communications.currentItem || {},
    communicationSuccess: communications.isSuccess,
    communicationUpdateSuccess: communications.isUpdateSuccess,
    selectedSchoolItem: school.selectedItem
  }
}

const mapDispatchToProps = {
  cleanSuccess,
  getCommunicationsById,
  updateCommunication,
  updateMessage,
  respondMessages
}

const MessagePage = ({
  currentCommunication,
  getCommunicationsById,
  updateCommunication,
  isFetching,
  match,
  userOccuppations,
  updateMessage,
  respondMessages,
  communicationSuccess,
  communicationUpdateSuccess,
  cleanSuccess,
  selectedSchoolItem,
  userId
}) => {
  const { communicationId } = match.params
  useEffect(
    () => {
      getCommunicationsById({ id: communicationId })
    },
    [communicationId, communicationUpdateSuccess]
  )

  if (isFetching) return <CircularProgress color='secondary' />

  return (
    <Paper elevation={0}>
      <MessageView
        communicationSuccess={communicationSuccess}
        currentCommunication={currentCommunication}
        userOccuppations={userOccuppations}
        updateCommunication={updateCommunication}
        updateMessage={updateMessage}
        respondMessages={respondMessages}
        cleanSuccess={cleanSuccess}
        userId={userId}
        selectedSchool={selectedSchoolItem}
      />
    </Paper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagePage)
