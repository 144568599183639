import jsonApi from '../../utils/jsonApi'
import http from '../../utils/http'

const communicationsModel = 'communications'
const messagesModel = 'messages'

export const fetchCommunications = ({ page, per, params }) =>
  jsonApi.findAll(communicationsModel, {
    params: {
      ...params,
      'page[number]': page,
      'page[size]': per || 5,
      'q[s]': 'id desc',
      include: 'origin,messages.files,messages.message_destinations.user,users'
    }
  })

export const createCommunication = data => {
  return http.post(communicationsModel, data, {
    'Content-Type': 'multipart/form-data'
  })
}

export const updateCommunication = async data => {
  const res = await http.put(
    `${communicationsModel}/${data.id}`,
    { data: { ...data } },
    {
      'Content-Type': 'application/vnd.api+json'
    }
  )
  return res.data
}

export const editCommunicationMessage = async data => {
  const res = await http.put(`/${messagesModel}/${data.id}`, data.data, {
    'Content-Type': 'multipart/form-data'
  })
  return res.data
}

export const fetchCommunicationById = ({ id }) =>
  jsonApi.find(communicationsModel, id)

// export const fetchCommunicationById = ({ id }) =>
// jsonApi.find(communicationsModel, id, {
//   params: {
//     // 'q[communication_id_eq]': id,
//     include: 'classroom.grade,origin,messages.message_destinations.user,messages.author,messages.files,users'
//     // include: 'communication.classroom.grade,communication.origin,communication.users,message_destinations.user,author,files'
//   }
// })

export const fetchMessageByCommunicationId = ({ id }) =>
  jsonApi.find(messagesModel, '', {
    params: {
      'q[communication_id_eq]': id,
      'q[s]': 'id asc',
      'page[size]': 20,
      include:
        'communication.classroom.grade,communication.origin,communication.school,communication.finished_by,author,moderator,files,message_views.user'
    }
  })

export const createMessage = data => {
  return http.post(messagesModel, data, {
    'Content-Type': 'multipart/form-data'
  })
}

export const deleteMessageFile = id => {
  return jsonApi.destroy('message_files', id, { id })
}

export const createMessageFile = data => {
  return http.post('message_files', data, {
    'Content-Type': 'multipart/form-data'
  })
}

export const fetchPendingCommunications = ({ userId, schoolId }) => {
  return http
    .get('messages', {
      params: {
        'q[status_eq]': 1,
        'q[messages_message_destinations_user_id_eq]': userId,
        'q[communication_active_eq]': true,
        'q[communication_school_id_eq]': schoolId,
        'q[s]': 'updated_at desc'
      }
    })
    .then(res => res)
}

export const fetchDestinationsByCommunication = communicationId => {
  return http.get('/message_destinations', {
    params: {
      'q[communication_id_eq]': communicationId,
      include: 'user'
    }
  })
}
