import { createReducer } from '@reduxjs/toolkit'

import * as types from './types'

const initialState = {
  alertMessage: {
    open: false,
    message: '',
    severity: ''
  }
}

const reducer = createReducer(initialState, {
  [types.SHOW_ALERT_MESSAGE]: (state, action) => ({
    ...state,
    alertMessage: {
      open: true,
      message: action.payload?.message,
      severity: action.payload?.severity
    }
  }),
  [types.HIDE_ALERT_MESSAGE]: (state, action) => ({
    ...state,
    alertMessage: initialState.alertMessage
  })
})

export default reducer
