import React from 'react'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import tinycolor from 'tinycolor2'
import { roles } from './utils/constants'

const mapStateToProps = ({ school, auth }) => {
  return {
    currentSchool: school.currentSchool,
    currentOccupation: auth.currentOccupation
  }
}

function GlobalTheme ({ currentSchool, children, currentOccupation }) {
  const school = currentSchool && currentSchool.school
  const PRIMARY_COLOR = currentOccupation.includes(roles.VERSAR_ADMIN)
    ? '#386093'
    : (school && school.primary_color) ||
      process.env.REACT_APP_PROJECT_COLOR_PRIMARY
  const SECONDARY_COLOR = currentOccupation.includes(roles.VERSAR_ADMIN)
    ? '#EF7C8F'
    : (school && school.secondary_color) ||
      process.env.REACT_APP_PROJECT_COLOR_SECONDARY

  const themeDefault = {
    typography: {
      fontFamily: 'Source Sans Pro',
      h5: {
        fontWeight: 600
      }
    },
    palette: {
      primary: {
        main: PRIMARY_COLOR,
        brightest: tinycolor(PRIMARY_COLOR)
          .setAlpha(0.3)
          .toString(),
        versar: '#386093'
      },
      secondary: {
        main: SECONDARY_COLOR,
        brightest: tinycolor(SECONDARY_COLOR)
          .setAlpha(0.3)
          .toString(),
        versar: '#EF7C8F'
      },
      info: {
        main: '#00ACDB'
      },
      danger: {
        main: '#E8505B'
      },
      text: {
        primary: 'rgba(85, 85, 85, 1)',
        secondary: '#ADB8CC'
      }
    },
    overrides: {
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: 'rgba(85, 85, 85, 1)',
            fontWeight: 'bold'
          }
        },

        focused: {}
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'none'
          }
        }
      },
      MuiTimelineItem: {
        missingOppositeContent: {
          '&:before': {
            display: 'none'
          }
        }
      },
      MuiButton: {
        containedSecondary: {
          color: '#FFF'
        }
      }
    }
  }

  const theme = createTheme(themeDefault)

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default connect(mapStateToProps)(GlobalTheme)
