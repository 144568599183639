import { createReducer } from '@reduxjs/toolkit'
import {
  GET_SEGMENTS,
  GET_SEGMENTS_SUCCESSFUL,
  GET_SEGMENTS_FAILURE
} from './types'

const initialState = {
  items: [],
  isFetching: false,
  isError: false,
  errorMessage: ''
}

const segmentsReducer = createReducer(initialState, {
  [GET_SEGMENTS]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [GET_SEGMENTS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: action.payload,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [GET_SEGMENTS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  })
})

export default segmentsReducer
