import { currentHost } from '../utils/http'

export const parseData = (data, type) => {
  const parsedData = {
    data: {
      type: type,
      attributes: { ...data }
    }
  }
  return parsedData
}

export const parseSrc = src => {
  return /^\/rails/.test(src) ? `${currentHost}${src}` : src
}

export const parseImageData = data => {
  return data.map(img => {
    return {
      id: img.id,
      img: img.image_url || img.attributes.image_url
    }
  })
}

export const dataToOptions = data => {
  return data?.map(d => {
    return { label: d.attributes.name, value: d.id }
  })
}

export const dataToOptionsTranslate = async (data, dict) => {
  return data?.map(d => {
    return { label: dict[d.attributes.name], value: d.id }
  })
}

export const youtubeParser = url => {
  const regExp = /(?:[?&]v=|\/embed\/|\/1\/|\/v\/|https:\/\/(?:www\.)?youtu\.be\/)([^&\n?#]+)/
  const match = url.match(regExp)
  if (match && match[1].length === 11) {
    const b = match[1]
    return b
  }
}
