import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import CssBaseline from '@material-ui/core/CssBaseline'
import moment from 'moment'
import 'moment/locale/pt-br'

import App from './app'
import configureStore from './store/configureStore'

import GlobalTheme from './index.style'
import AlertMessage from './components/shared/Alert'

const { persistor, store } = configureStore()

const onBeforeLift = () => {
  // take some action before the gate lifts
}

moment.locale('pt-br')

const renderApp = NextApp => {
  render(
    <Provider store={store}>
      <GlobalTheme>
        <CssBaseline />
        <PersistGate
          loading={null}
          onBeforeLift={onBeforeLift}
          persistor={persistor}
        >
          <NextApp />
          <AlertMessage />
        </PersistGate>
      </GlobalTheme>
    </Provider>,
    document.getElementById('app')
  )
}

renderApp(App)
