import React from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '../../utils/helpers'

import Paper from '@material-ui/core/Paper'
import SearchMessagesForm from '../form/SearchMessagesForm'

const MessagesView = ({
  getCommunications,
  updateCommunication,
  setCurrentStatusFilter,
  communications,
  quantity,
  isTeacher,
  userId,
  selectedSchool,
  userOccupations,
  messageType,
  isAdmin,
  isFetching,
  schoolId
}) => {
  const history = useHistory()
  const query = useQuery()
  const filterBy = history.location.state?.filterBy
  const hasFilterBy =
    (filterBy === 'active' && { 'q[active_eq]': false }) ||
    (filterBy !== undefined && {
      'q[active_eq]': true,
      'q[status_eq]': filterBy
    })
  const paramsRequest = {
    'q[school_id_in]': selectedSchool?.id || schoolId,
    ...(messageType === 'all-message' ? {} : hasFilterBy)
  }

  React.useEffect(
    () => {
      getCommunications({ params: paramsRequest })
    },
    [messageType]
  )

  return (
    <>
      {(query.get('name') === null || query.get('name') === 'list') && (
        <Paper style={{ borderRadius: 8, padding: 32 }} elevation={0}>
          <SearchMessagesForm
            setCurrentStatusFilter={setCurrentStatusFilter}
            getCommunications={getCommunications}
            updateCommunication={updateCommunication}
            history={history}
            isFetching={isFetching}
            selectedSchool={selectedSchool}
            userId={userId}
            communications={communications}
            userOccupations={userOccupations}
            paramsRequest={paramsRequest}
          />
        </Paper>
      )}
    </>
  )
}

export default MessagesView
