export const GET_GRADES = 'grades/GET_GRADES'
export const GET_GRADES_SUCCESSFUL = 'grades/GET_GRADES_SUCCESSFUL'
export const GET_GRADES_FAILURE = 'grades/GET_GRADES_FAILURE'

export const GET_GRADES_BY_ID = 'grades/GET_GRADES_BY_ID'
export const GET_GRADES_BY_ID_SUCCESSFUL = 'grades/GET_GRADES_BY_ID_SUCCESSFUL'
export const GET_GRADES_BY_ID_FAILURE = 'grades/GET_GRADES_BY_ID_FAILURE'

export const GET_GRADE_THEME_SCHEDULES = 'grades/GET_GRADE_THEME_SCHEDULES'
export const GET_GRADE_THEME_SCHEDULES_SUCCESSFUL =
  'grades/GET_GRADE_THEME_SCHEDULES_SUCCESSFUL'
export const GET_GRADE_THEME_SCHEDULES_FAILURE =
  'grades/GET_GRADE_THEME_SCHEDULES_FAILURE'

export const CLEAN_GRADE = 'grades/CLEAN_GRADE'
