import http from '../../utils/http'

const fetchCategoriesUrl = '/categories'

export const fetchCategoriesBySegmentId = ({ idOcc, idSeg }) =>
  http
    .get(fetchCategoriesUrl, {
      params: {
        'q[occupations_id_eq]': idOcc,
        'q[segments_id_eq]': idSeg,
        'q[s]': 'name asc'
      }
    })
    .then(res => res.data)
