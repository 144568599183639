import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'

import { Box } from '../form/styles/CreateModuleForm.style'
import Label from '../label/Label'
import UploadCoversForm from '../form/UploadCoversForm'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    padding: '1rem 0',
    textAlign: 'center',
    '& p': {
      fontSize: 16
    },
    '& h2': {
      marginBottom: '2rem'
    }
  },
  box: {
    margin: 'auto auto 2rem'
  },
  gridList: {
    maxWidth: 810,
    alignSelf: 'center',
    justifyItems: 'center',
    flexFlow: 'column wrap',
    [theme.breakpoints.up('md')]: {
      flexFlow: 'row wrap',
      paddingLeft: '2.8rem'
    },
    '& li': {
      maxWidth: 245,
      width: '100% !important'
    }
  },
  pagination: {
    padding: '1rem 0 0 4rem',
    '& ul': {
      justifyContent: 'right'
    }
  }
}))

const SettingsCoversView = ({
  covers,
  getCovers,
  isFetching,
  isSuccess,
  pagination,
  uploadCovers
}) => {
  const classes = useStyles()
  const [currentPage, setCurrentPage] = useState(1)

  const handleChange = async (event, value) => {
    await getCovers({ page: value })
    setCurrentPage(value)
  }

  return (
    <div className={classes.root}>
      <Box className={classes.box}>
        <Label>Adicionar nova capa</Label>
        <UploadCoversForm isSuccess={isSuccess} uploadCovers={uploadCovers} />
        <Label>Capas</Label>
        {isFetching ? (
          <CircularProgress color='secondary' />
        ) : (
          <>
            <GridList className={classes.gridList} cellHeight={140}>
              {covers &&
                !isFetching &&
                covers.map(cover => (
                  <GridListTile key={cover.id}>
                    <img src={cover.image_url} alt={cover.type} />
                  </GridListTile>
                ))}
            </GridList>
            <Pagination
              count={pagination.pageCount}
              className={classes.pagination}
              color='primary'
              page={currentPage}
              onChange={handleChange}
            />
          </>
        )}
      </Box>
    </div>
  )
}

export default SettingsCoversView
