import React, { useEffect } from 'react'
import './pages.css'
import { connect } from 'react-redux'

import Label from '../components/label/Label'
import Button from '../components/button/Button'
import Tab from '../components/tab/Tab'

import Input from '@material-ui/core/Input'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

import {
  getThemes,
  getThemeContents,
  deleteTheme,
  cloneTheme,
  getThemeSegments,
  clearClonedTheme,
  editTheme
} from '../store/themes/actions'
import { deleteContent } from '../store/contents/actions'
import Modal from '../components/modal/Modal'
import ThemeModuleForm from '../components/form/ThemeModuleForm'

const useStyles = makeStyles(theme => ({
  wrapper: {
    background: 'white',
    borderRadius: '8px',
    boxShadow: '3px 11px 14px 6px rgba(107, 103, 103, 0.1)',
    padding: '1rem'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  createBtn: {
    color: '#FFFF',
    backgroundColor: '#00ACDB',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  inputText: {
    border: '1px solid #ADB8CC',
    borderRadius: 8,
    height: 48,
    paddingLeft: 8
  },
  inputComponent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12
  },
  inputsWrapper: {
    display: 'flex',
    gap: 16
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  clearBtn: {
    backgroundColor: '#EF7C8F',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  searchBtns: {
    display: 'flex',
    alignItems: 'center',
    gap: 12
  },
  filtersSection: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  }
}))

const mapStateToProps = ({ auth, themes, school }) => ({
  user: auth.user,
  themes: themes.themes,
  isFetching: themes.isFetching,
  isError: themes.isError,
  themeContents: themes.currentItem.contents?.items,
  childrenPagination: themes.currentItem.contents?.pagination,
  themeContentsIsFetching: themes.currentItem.isFetching,
  school: school.currentSchool,
  schoolIsFetching: school.isFetching,
  clonedTheme: themes.clonedTheme,
  userOccupations: auth.currentOccupation
})

const mapDispatchToProps = {
  getThemes,
  getThemeContents,
  deleteTheme,
  deleteContent,
  getThemeSegments,
  cloneTheme,
  clearClonedTheme,
  editTheme
}

const ThemesPage = ({
  getThemes,
  getThemeContents,
  themeContents,
  themes,
  history,
  search,
  deleteTheme,
  isFetching,
  themeContentsIsFetching,
  user,
  school,
  schoolIsFetching,
  deleteContent,
  childrenPagination,
  cloneTheme,
  clonedTheme,
  editTheme,
  userOccupations,
  clearClonedTheme
}) => {
  const classes = useStyles()
  const [themesValue, setThemes] = React.useState(themes)
  const [themeTitle, setThemeTitle] = React.useState('')
  const [themeId, setThemeId] = React.useState()
  const [dispatchSearch, setDispatchSearch] = React.useState(false)

  useEffect(
    () => {
      clearClonedTheme()
      getThemes(search)
    },
    [search]
  )

  const handleSearch = () => {
    setDispatchSearch(true)
  }

  const handleClearFields = () => {
    setThemeTitle('')
    setThemeId('')
    setDispatchSearch(true)
  }

  useEffect(
    () => {
      if (themes?.data) {
        let formattedData = themes.data
        const themeSkills = themes?.included
          ? themes.included.filter(include => include.type === 'skills')
          : []
        const themeModule = themes?.included
          ? themes.included.filter(include => include.type === 'theme_modules')
          : []
        const newThemes = formattedData.map(theme => {
          const skillIds = theme.attributes.skill_ids
          const skillsInfo = skillIds?.map(id => {
            const skillIndex = themeSkills.findIndex(
              skill => skill.id === String(id)
            )
            const skill = themeSkills[skillIndex]
            return skill?.attributes
          })

          const moduleId = theme.attributes.theme_module_id
          const moduleInfo = themeModule?.find(
            module => module.id === String(moduleId)
          )
          return {
            ...theme,
            attributes: {
              ...theme.attributes,
              modules: moduleInfo?.attributes,
              skills: skillsInfo
            }
          }
        })
        setThemes({ ...themes, data: newThemes })
      }
    },
    [themes]
  )
  const deleteItem = payload => {
    const { type, id, afterEffect } = payload
    if (type === 'themes') {
      deleteTheme({ id })
    } else if (type === 'contents') {
      deleteContent({ id, afterEffect })
    }
  }

  const handleCloseModal = () => {
    clearClonedTheme()
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <Label>Temas</Label>
        <Button
          data-testid='btn-create-theme'
          className={classes.createBtn}
          startIcon={<AddIcon />}
          onclick={() => history.push('/themes/create')}
        >
          Criar Tema
        </Button>
      </div>
      <div className={classes.filtersSection}>
        <div className={classes.inputsWrapper}>
          <div className={classes.inputComponent}>
            <label>ID:</label>
            <Input
              className={classes.inputText}
              type='number'
              disableUnderline
              value={themeId}
              placeholder={'Busque pelo ID'}
              onChange={event => setThemeId(event.target.value)}
            />
          </div>
          <div className={classes.inputComponent}>
            <label>Título</label>
            <Input
              className={classes.inputText}
              type='text'
              disableUnderline
              value={themeTitle}
              placeholder={'Busque pelo título'}
              onChange={event => setThemeTitle(event.target.value)}
            />
          </div>
        </div>
        <div className={classes.searchBtns}>
          <Button className={classes.clearBtn} onClick={handleClearFields}>
            Limpar
          </Button>
          <Button
            onClick={handleSearch}
            type='button'
            className={classes.applyBtn}
          >
            Aplicar
          </Button>
        </div>
      </div>
      <Tab
        data={themesValue}
        fetchItems={getThemes}
        itemsIsFetching={isFetching}
        getChildren={getThemeContents}
        childrenPagination={childrenPagination}
        params={{
          'q[id_eq]': themeId,
          'q[title_cont]': themeTitle
        }}
        dispatchSearch={dispatchSearch}
        setDispatchSearch={setDispatchSearch}
        childs={themeContents}
        childTitle='CONTEÚDOS'
        deleteItem={deleteItem}
        cloneTheme={cloneTheme}
        hasChild
        childrenIsFetching={themeContentsIsFetching}
        search={search}
        school={school?.school}
        schoolIsFetching={schoolIsFetching}
        userOccupations={userOccupations}
        extraContent={[
          {
            title: 'Habilidades',
            attr: 'skills'
          },
          {
            title: 'Módulos',
            attr: 'modules'
          }
        ]}
      />
      <Modal open={clonedTheme?.id} handleClose={handleCloseModal}>
        <h3 id='transition-modal-title'>
          Adicionar módulo no tema: {clonedTheme.title}.
        </h3>
        <ThemeModuleForm
          schoolId={school && school.school_id}
          clonedTheme={clonedTheme}
          clearClonedTheme={clearClonedTheme}
          editTheme={editTheme}
        />
      </Modal>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemesPage)
