import { all, call, takeLatest, put } from 'redux-saga/effects'
import { showAlertMessage } from '../alert/actions'
import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'

import * as actions from './actions'
import * as services from './services'
import { createUser, editUser, deleteUserAvatar } from '../classrooms/services'
import { addAvatar } from '../classrooms/sagas'

export function * fetchCoordinatorsRequest ({ payload }) {
  try {
    const response = yield call(services.fetchCoordinatorsService, payload)
    yield put(actions.getCoordinatorsSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getCoordinatorsFailure(error))
  }
}

export function * fetchCoordinatorById ({ payload }) {
  try {
    const response = yield call(services.fetchCoordinatorById, payload)
    yield put(actions.getCoordinatorByIdSuccessful(response))
  } catch (error) {
    yield put(actions.getCoordinatorByIdFailure(error))
  }
}

export function * editCoordinatorRequest ({ payload }) {
  try {
    const {
      // eslint-disable-next-line camelcase
      classroom_coordinators_attributes,
      removeClassroomCoordinator,
      addClassroomCoordinator,
      id,
      email,
      image,
      ...rest
    } = payload
    const response = yield call(editUser, {
      id: id,
      data: {
        ...rest,
        classroom_coordinators_attributes: [...addClassroomCoordinator]
      }
    })
    if (removeClassroomCoordinator?.length > 0) {
      yield all(
        removeClassroomCoordinator.map(classroomCoodinator => {
          return call(services.deleteClassroomCoordinator, classroomCoodinator)
        })
      )
    }
    if (image === 'delete') {
      yield call(deleteUserAvatar, {
        id: response.data.id
      })
    } else if (image && image instanceof Blob) {
      yield call(addAvatar, {
        id: response.data.id,
        avatar: image
      })
    }
    yield put(actions.editCoordinatorSuccessful(response))
    yield put(
      showAlertMessage({
        message: 'Coordenador editado com sucesso.',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.editCoordinatorFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao editar coordenador.',
        severity: 'error'
      })
    )
  }
}

export function * createCoordinatorRequest ({ payload }) {
  try {
    const newValues = { ...payload }
    const {
      email,
      // eslint-disable-next-line camelcase
      classroom_coordinators_attributes,
      // eslint-disable-next-line camelcase
      school_coordinators_attributes,
      image,
      userToEdit,
      ...rest
    } = newValues
    let response

    if (userToEdit) {
      response = yield call(editUser, {
        id: userToEdit.id,
        data: {
          ...rest,
          ...(classroom_coordinators_attributes?.length > 0
            ? { classroom_coordinators_attributes }
            : { school_coordinators_attributes })
        }
      })
    } else {
      response = yield call(createUser, {
        email: email.toLowerCase(),
        // eslint-disable-next-line camelcase
        ...(classroom_coordinators_attributes?.length > 0
          ? { classroom_coordinators_attributes }
          : { school_coordinators_attributes }),
        ...rest
      })
    }

    if (image === 'delete') {
      yield call(deleteUserAvatar, {
        id: response.data.id
      })
    } else if (image && image instanceof Blob) {
      yield call(addAvatar, {
        id: response.data.id,
        avatar: image
      })
    }

    yield put(actions.createCoordinatorSuccessful(response))
    yield put(
      showAlertMessage({
        message: 'Coordenador adicionado com sucesso.',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.createCoordinatorFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao adicionar coordenador.',
        severity: 'error'
      })
    )
  }
}

export function * fetchDeletedCoordinators ({ payload }) {
  try {
    const response = yield call(services.fetchDeletedCoordinators, payload)
    yield put(actions.getDeletedCoordinatorsSuccessful(response))
  } catch (err) {
    console.error(err)
    yield put(actions.getDeletedCoordinatorsFailure(err))
  }
}

export function * deleteCoordinatorRequest (action) {
  try {
    const { payload: { schoolCoordinatorId, userId } } = action
    yield call(services.deleteSchoolCoodinator, schoolCoordinatorId)
    const classroomCoordinators = yield call(
      services.getClassroomCoordinators,
      userId
    )
    if (classroomCoordinators?.length > 0) {
      yield all(
        classroomCoordinators.map(item => {
          return call(services.deleteClassroomCoordinator, item.id)
        })
      )
    }
    resolvePromiseAction(action, Promise.resolve())
  } catch (err) {
    rejectPromiseAction(action, err)
  }
}

export function * restoreCoordinatorRequest (action) {
  try {
    const { payload: { schoolCoordinatorId } } = action
    const response = yield call(
      services.restoreCoordinator,
      schoolCoordinatorId
    )
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * watchCoordinators () {
  yield takeLatest(actions.createCoordinator, createCoordinatorRequest)
  yield takeLatest(actions.getCoordinators, fetchCoordinatorsRequest)
  yield takeLatest(actions.getCoordinatorById, fetchCoordinatorById)
  yield takeLatest(actions.editCoordinator, editCoordinatorRequest)
  yield takeLatest(actions.getDeletedCoordinators, fetchDeletedCoordinators)
  yield takeLatest(
    actions.deleteCoordinatorRequestRSPA.request,
    deleteCoordinatorRequest
  )
  yield takeLatest(
    actions.restoreCoordinatorsRSPA.request,
    restoreCoordinatorRequest
  )
}

export default function * coordinatorsSaga () {
  yield all([watchCoordinators()])
}
