import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import CircularProgress from '@material-ui/core/CircularProgress'

import ThemeScheduleView from '../components/view/ThemeScheduleView'
import { getGradesById, getGradeThemeSchedules } from '../store/grades/actions'
import jsonApi from '../utils/jsonApi'

const mapStateToProps = ({ school, grades }) => {
  return {
    currentSchoolId: school.currentSchool?.school?.id,
    currentGrade: grades.current,
    selectedSchoolItem: school.selectedItem,
    isFetching: grades.isFetching,
    themeSchedules: grades.themeSchedules
  }
}

const mapDispatchToProps = {
  getGradesById,
  getGradeThemeSchedules
}

const ThemeSchedulePage = ({
  currentGrade,
  isFetching,
  getGradesById,
  getGradeThemeSchedules,
  themeSchedules,
  currentSchoolId,
  selectedSchoolItem,
  match
}) => {
  const { params: { gradeId } } = match
  const [template, setTemplate] = useState({})
  const [cycles, setCycles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (gradeId) {
      const schoolIdParam = selectedSchoolItem?.id || currentSchoolId
      getCycles()
      getGradesById(gradeId)
      getGradeThemeSchedules({
        gradeId,
        schoolId: schoolIdParam,
        year: new Date().getFullYear()
      })
    }
  }, [])

  useEffect(
    () => {
      const loadData = async () => {
        if (
          currentGrade.schedule_templates?.length > 0 &&
          currentGrade.schedule_templates[0].template
        ) {
          setIsLoading(true)
          const newThemes = {}
          const newRows = {}
          const newOrder = []
          const themesIds = await currentGrade.schedule_templates[0].template.map(
            (row, index) => {
              const newRowId = row.cycle_id
              const cycle = cycles.find(c => c.id === newRowId)

              const rowThemes = row.theme_ids
              // create new row
              const newRow = {
                [newRowId]: {
                  id: newRowId,
                  name: cycle?.name,
                  theme_ids: rowThemes
                }
              }
              // add row to rows
              Object.assign(newRows, newRow)
              // add row order
              newOrder.push(newRowId)
              return rowThemes.join(',')
            }
          )
          getThemeByIds({ ids: themesIds.join(',') })
            .then(async res => {
              setIsLoading(true)
              // create new theme
              await res.data.forEach(data => {
                const newTheme = {
                  [data.id]: {
                    id: data.id,
                    theme: data.title,
                    module: data.theme_module?.title
                  }
                }
                // add theme to themes
                Object.assign(newThemes, newTheme)
              })
              setIsLoading(false)
            })
            .catch(err => console.error(err))
          const newInitialValues = {
            rows: newRows,
            rowOrder: newOrder,
            themes: newThemes
          }
          setTemplate(newInitialValues)
          setIsLoading(false)
        } else {
          setTemplate({})
        }
      }
      loadData()

      return () => {
        setTemplate({})
      }
    },
    [currentGrade, cycles]
  )

  const getThemeByIds = async ({ ids }) => {
    return jsonApi.findAll('theme', {
      params: {
        'q[id_in]': ids,
        include: 'theme_module'
      }
    })
  }

  const getCycles = async () => {
    return jsonApi
      .findAll('cycle', {
        params: {
          'page[size]': 20
        }
      })
      .then(res => {
        setCycles(res.data)
      })
  }

  if (isFetching || isLoading) return <CircularProgress color='secondary' />

  return (
    <>
      <ThemeScheduleView
        selectedSchool={selectedSchoolItem}
        schoolId={selectedSchoolItem?.id || currentSchoolId}
        cycles={cycles}
        themeSchedules={themeSchedules}
        getGradesById={getGradesById}
        getGradeThemeSchedules={getGradeThemeSchedules}
        grade={currentGrade}
        template={template}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSchedulePage)
