import React from 'react'
import { connect } from 'react-redux'

import ClassroomsView from '../components/view/ClassroomsView'

const mapStateToProps = ({ auth, school }) => ({
  user: auth.user,
  currentSchool: school.currentSchool
})

const ClassroomsPage = ({ user, currentSchool }) => {
  return (
    <ClassroomsView
      currentSchool={currentSchool}
      occupations={user.data?.attributes?.occupations}
    />
  )
}

export default connect(mapStateToProps)(ClassroomsPage)
