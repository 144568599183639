import React, { useEffect } from 'react'
import './pages.css'
import { connect } from 'react-redux'

import Label from '../components/label/Label'
import Button from '../components/button/Button'
import Tab from '../components/tab/Tab'
import { occupationsObject } from '../utils/dicts'

import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

import {
  getContents,
  deleteContent,
  clearCurrentContent
} from '../store/contents/actions'

const useStyles = makeStyles(theme => ({
  wrapper: {
    background: 'white',
    borderRadius: '8px',
    boxShadow: '3px 11px 14px 6px rgba(107, 103, 103, 0.1)',
    padding: '1rem'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  createBtn: {
    color: '#FFFF',
    backgroundColor: '#00ACDB',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const mapStateToProps = ({ auth, contents, school }) => ({
  user: auth.user,
  contents: contents.contents,
  isFetching: contents.isFetching,
  isError: contents.isError,
  school: school.currentSchool,
  schoolIsFetching: school.isFetching,
  userOccupations: auth.currentOccupation
})

const mapDispatchToProps = {
  getContents,
  deleteContent,
  clearCurrentContent
}

const ContentsPage = ({
  getContents,
  contents,
  history,
  search,
  deleteContent,
  isFetching,
  user,
  school,
  schoolIsFetching,
  userOccupations,
  clearCurrentContent
}) => {
  const classes = useStyles()
  useEffect(
    () => {
      getContents(search)
    },
    [search]
  )
  const formattedData = contents?.data?.map(content => {
    const themeId = content.attributes.theme_id
    const themeInfo = contents.included.find(
      item => item.type === 'themes' && themeId === item.id * 1
    )
    const categoryId = content && content.attributes.category_id
    const categoryInfo = contents.included.find(
      item => item.type === 'categories' && categoryId === item.id * 1
    )
    const segmentId = content.attributes.segment_id
    const segmentInfo = contents.included.find(
      item => item.type === 'segments' && segmentId === item.id * 1
    )
    const occupationId = content.attributes.occupation_id
    const occupationInfo = contents.included.find(
      item => item.type === 'occupations' && occupationId === item.id * 1
    )
    return {
      ...content,
      attributes: {
        ...content.attributes,
        theme: { ...themeInfo?.attributes },
        category: { ...categoryInfo?.attributes },
        segment: { ...segmentInfo?.attributes },
        occupation: {
          ...occupationInfo?.attributes,
          name: occupationsObject[occupationInfo?.attributes.name]
        }
      }
    }
  })
  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <Label>Conteúdos</Label>
        <Button
          data-testid='btn-create-content'
          className={classes.createBtn}
          startIcon={<AddIcon />}
          onclick={async () => {
            await clearCurrentContent()
            history.push('/contents/create')
          }}
        >
          Criar Conteúdo
        </Button>
      </div>
      <Tab
        data={{ ...contents, data: formattedData }}
        fetchItems={getContents}
        deleteItem={deleteContent}
        itemsIsFetching={isFetching}
        school={school?.school}
        schoolIsFetching={schoolIsFetching}
        search={search}
        userOccupations={userOccupations}
        extraContent={[
          {
            title: 'Foco',
            attr: 'occupation'
          },
          {
            title: 'Tema',
            attr: 'theme'
          },
          {
            title: 'Categoria',
            attr: 'category'
          },
          {
            title: 'Segmento',
            attr: 'segment'
          }
        ]}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentsPage)
