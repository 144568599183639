import React from 'react'
import { Field, Form, reduxForm } from 'redux-form'

import TextArea from '../shared/TextArea'
import { makeStyles } from '@material-ui/styles'
import { createEventComment } from '../../store/events/actions'

const useStyles = makeStyles(theme => ({
  root: {
    '& > div textarea': {
      fontFamily: 'inherit',
      fontSize: 14,
      minHeight: 180,
      padding: '25px 35px',
      '&::placeholder': {
        color: theme.palette.text.secondary
      }
    },
    [theme.breakpoints.down('426')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  }
}))

const CommentForm = ({
  onSubmit,
  handleSubmit,
  reset,
  textColor,
  bgColor,
  hasBorder,
  disabled,
  defaultValue,
  style
}) => {
  const classes = useStyles()

  const handleOnChange = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      handleSubmit()
      reset()
    }
  }

  return (
    <Form
      className={classes.root}
      style={style}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Field
        name='content'
        disabled={disabled}
        placeholder='Digite aqui a sua mensagem...'
        component={TextArea}
        onKeyDown={handleOnChange}
        defaultValue={defaultValue}
        componentStyle={{ textColor, bgColor, hasBorder }}
      />
      <button type='submit' hidden>
        Submit
      </button>
    </Form>
  )
}

export default reduxForm({
  form: 'commentForm',
  onSubmit: (values, dispatch, props) => {
    const newValue = {
      ...values,
      event_id: props.eventId,
      user_id: props.userId
    }

    dispatch(createEventComment(newValue))
  }
})(CommentForm)
