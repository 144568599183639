import React from 'react'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import Paper from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: '12px !important'
    },
    '& svg': {
      '& path': {
        fill: '#555555'
      }
    }
  }
})

const RowActionTable = ({ children, className }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const classes = useStyles()
  const open = Boolean(anchorEl)

  const id = open ? 'simple-popper' : undefined
  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div className={`${classes.root} ${className}`}>
      <MoreVertIcon
        aria-controls='simple-menu'
        aria-haspopup='true'
        aria-describedby={id}
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transition
        // container={containerRef.current}
      >
        <Paper>{children}</Paper>
      </Popover>
    </div>
  )
}

export default RowActionTable
