import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core'

import Button from '../button/Button'
import SearchMessagesForm from '../form/SearchMessagesForm'
import Label from '../label/Label'

const MessagesInboxView = ({
  communications,
  userOccupations,
  getCommunications,
  updateCommunication,
  isFetching,
  selectedSchool,
  userId
}) => {
  const useStyles = makeStyles(theme => ({
    root: {
      marginBottom: '1rem',
      '& .MuiTableContainer-root': {
        backgroundColor: '#F6F7F8'
      },
      '& .MuiTable-root': {
        borderCollapse: 'separate',
        borderSpacing: '0 8px',
        backgroundColor: '#F6F7F8',
        '& .MuiTableCell-root': {
          border: 'none'
        },
        '& .MuiTableCell-body': {
          borderBottom: 'none',
          backgroundColor: `${isFetching ? 'none' : '#FFF'}`,
          '&:first-child': {
            borderRadius: '8px 0 0 8px'
          },
          '&:last-child': {
            borderRadius: '0 8px 8px 0'
          }
        }
      }
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '3rem 0 2rem'
    },
    backBtn: {
      width: 146,
      float: 'right',
      border: '1px solid #EF7C8F',
      height: 44,
      padding: '14px 48px',
      gap: 10,
      color: '#EF7C8F',
      borderRadius: 8,
      '&:hover': {
        border: '1px solid #EF7C8F'
      },
      fontSize: '16px',
      fontWeight: 600,
      textTransform: 'capitalize',
      lineHeight: '19.5px'
    },
    backButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 32,
      '& button': {
        marginRight: 0,
        width: 150
      }
    }
  }))

  const classes = useStyles()
  const history = useHistory()
  const filterBy = history.location.state?.filterBy
  const hasFilterBy =
    (filterBy === 'active' && { 'q[active_eq]': false }) ||
    (filterBy !== undefined && {
      'q[active_eq]': true,
      'q[status_eq]': filterBy
    })

  const paramsRequest = {
    'q[school_id_eq]': selectedSchool?.school_id,
    ...hasFilterBy
  }
  React.useEffect(() => {
    getCommunications({ params: paramsRequest })
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {selectedSchool?.id && <Label>{selectedSchool.name}</Label>}
      </div>
      <SearchMessagesForm
        getCommunications={getCommunications}
        updateCommunication={updateCommunication}
        history={history}
        communications={communications}
        userOccupations={userOccupations}
        paramsRequest={paramsRequest}
        isFetching={isFetching}
        userId={userId}
        selectedSchool={selectedSchool}
      />
      <div className={classes.backButton}>
        <Button
          className={classes.backBtn}
          variant='contained'
          onClick={() => history.goBack()}
        >
          Voltar
        </Button>
      </div>
    </div>
  )
}

export default MessagesInboxView
