import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

const useStyle = makeStyles(theme => ({
  buttonsOutSideBox: {
    display: 'flex',
    height: '40px',
    justifyContent: 'flex-start',
    '& :nth-of-type(1)': {
      width: '80px',
      borderTopLeftRadius: '10px',
      backgroundColor: props => {
        const hexColor = theme.palette.primary.main
        const r = parseInt(hexColor.slice(1, 3), 16)
        const g = parseInt(hexColor.slice(3, 5), 16)
        const b = parseInt(hexColor.slice(5, 7), 16)
        return props.currentPage === 'classroom'
          ? `rgba(${r}, ${g}, ${b}, ${0.1})`
          : '#ffffff'
      },
      color: props => {
        return props.currentPage === 'classroom'
          ? theme.palette.primary.main
          : ''
      }
    },
    '& :nth-of-type(2)': {
      width: '80px',
      borderTopRightRadius: '10px',
      borderLeft: `${theme.palette.primary.main} 1px solid`,
      backgroundColor: props => {
        const hexColor = theme.palette.primary.main
        const r = parseInt(hexColor.slice(1, 3), 16)
        const g = parseInt(hexColor.slice(3, 5), 16)
        const b = parseInt(hexColor.slice(5, 7), 16)
        return props.currentPage === 'teachers'
          ? `rgba(${r}, ${g}, ${b}, ${0.1})`
          : '#ffffff'
      },
      color: props => {
        return props.currentPage === 'teachers'
          ? theme.palette.primary.main
          : ''
      }
    },
    '& :nth-of-type(n+2)': {
      weight: 'bold'
    }
  }
}))

const HeadFormTab = props => {
  const history = useHistory()
  const classes = useStyle({ currentPage: props.currentPage })
  return (
    <>
      <div className={classes.buttonsOutSideBox}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={() => history.push('/settings/classrooms/new')}
        >
          Turmas
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={() => history.push('/settings/teachers/new')}
        >
          Docente
        </div>
      </div>
    </>
  )
}

export default HeadFormTab
