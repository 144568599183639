import jsonApi from '../../utils/jsonApi'
import http from '../../utils/http'

export const fetchEvents = ({
  page,
  params,
  isAdmin,
  isSelf,
  isCoordinator
}) => {
  const endpoint =
    isAdmin || isSelf || isCoordinator ? 'events' : 'events/by_teachers'
  return jsonApi
    .findAll(endpoint, {
      params: {
        ...params,
        'page[number]': page || 1,
        'q[s]': 'created_at desc',
        include: 'user,classroom,event_comments,event_likes.user,school'
      }
    })
    .then(res => res)
}

export const deleteCoverImage = data => {
  return http.delete(`events/${data.id}/cover_image`)
}

export const fetchEventById = ({ id }) => {
  return jsonApi
    .find('events', id, {
      params: {
        include:
          'classroom,user,classroom.children,classroom.grade,classroom.teachers,classroom.children.parents,grade,school,event_comments.user,event_likes.user,event_views.user.child,event_views.user.child_parents,event_views.user.classroom_teachers,files,destinations.user,destinations.classroom.grade.segment'
      }
    })
    .then(res => res.data)
}

export const createEvent = data =>
  http
    .post('/events', data, { 'Content-Type': 'multipart/form-data' })
    .then(res => res.data)

export const createEventFile = data => {
  return http.post('/event_files', data).then(res => res.data)
}

export const fetchEventDetails = id => {
  return http.get(`/events/${id}/graph_data`).then(res => res.data)
}

export const createEventDestinations = data =>
  jsonApi.create('event_destination', data).then(res => res.data)

export const fetchEventComments = ({ id, page }) => {
  return http
    .get('/event_comments', {
      params: {
        'q[event_id_eq]': id,
        'page[number]': page || 1,
        'page[size]': 5,
        'q[s]': 'created_at desc',
        include: 'user.child'
      }
    })
    .then(res => res.data)
}

export const fetchEventSchoolDestination = ({ id, page }) => {
  return http
    .get('/classroom_children', {
      params: {
        'q[classroom_school_id_eq]': id,
        'page[number]': page || 1,
        'page[size]': 20,
        include: 'child'
      }
    })
    .then(res => res.data)
}

export const fetchEventClassroomDestination = async ({ id, page }) => {
  const res = await http.get('/classroom_children', {
    params: {
      'q[classroom_id_eq]': id,
      'page[number]': page || 1,
      'page[size]': 20,
      include: 'child.parents'
    }
  })
  return res.data
}

export const fetchEventFiles = ({ id }) => {
  return jsonApi
    .find('event_files', '', {
      params: {
        'q[event_id_eq]': id,
        'page[size]': 20
      }
    })
    .then(res => res.data)
}

export const createEventComment = data =>
  jsonApi.create('event_comment', data).then(res => res.data)

export const fetchEventLikes = ({ id }) => {
  return jsonApi
    .find('event_like', '', {
      params: {
        'q[event_id_eq]': id
      }
    })
    .then(res => res)
}

export const editEvent = ({ id, data }) =>
  http
    .put(`/events/${id}`, data, { 'Content-Type': 'multipart/form-data' })
    .then(res => res.data)

export const deleteEvent = id =>
  jsonApi.destroy('event', id).then(res => res.data)

export const deleteEventFile = id => {
  return jsonApi.destroy('event_files', id, { id })
}

export const fetchEventDestinations = ({ id, params }) => {
  return jsonApi
    .findAll(`users/events/${id}`, {
      params: {
        ...params,
        'q[s]': 'name asc',
        include: 'children'
      }
    })
    .then(res => {
      return res
    })
}

export const fetchChildrenSchool = async ({ getAll, params, page }) => {
  const classroomsIdIn = params['q[classrooms_id_in]'].join(',')
  delete params['q[classrooms_id_in]']
  params['q[classrooms_id_in]'] = `${classroomsIdIn}`
  let res = await jsonApi
    .findAll('children', {
      params: {
        ...params,
        'page[number]': page || 1,
        'page[size]': 20
      }
    })
    .then(res => res)
  if (!getAll) {
    return res
  } else {
    let currentPage = 1
    let fullResponse = { ...res }
    while (fullResponse.data.length < fullResponse.meta.total_count) {
      currentPage += 1
      let responseForNextPage = await jsonApi
        .findAll('children', {
          params: {
            ...params,
            'page[number]': currentPage,
            'page[size]': 20
          }
        })
        .then(response => response.data)
      fullResponse.data = [...fullResponse.data, ...responseForNextPage]
    }
    return fullResponse
  }
}

export const fetchParentsSchool = async ({ getAll, params, page }) => {
  const classroomsIdIn = params['q[classrooms_id_in]'].join(',')
  delete params['q[classrooms_id_in]']
  params['q[classrooms_id_in]'] = `${classroomsIdIn}`
  let res = await jsonApi
    .findAll('child_parents', {
      params: {
        ...params,
        'page[number]': page || 1,
        'page[size]': 20,
        include: 'parent'
      }
    })
    .then(res => res)
  if (!getAll) {
    return res
  } else {
    let currentPage = 1
    let fullResponse = { ...res }
    while (fullResponse.data.length < fullResponse.meta.total_count) {
      currentPage += 1
      let responseForNextPage = await jsonApi
        .findAll('child_parents', {
          params: {
            ...params,
            'page[number]': currentPage,
            'page[size]': 20,
            include: 'parent'
          }
        })
        .then(response => response.data)
      fullResponse.data = [...fullResponse.data, ...responseForNextPage]
    }
    return fullResponse
  }
}

export const fetchTeachersSchool = async ({ params, page, getAll }) => {
  let res
  const classroomsIdIn = params['q[classrooms_id_in]'].join(',')
  delete params['q[classrooms_id_in]']
  params['q[classrooms_id_in]'] = `${classroomsIdIn}`
  res = await jsonApi
    .findAll('classroom_teachers', {
      params: {
        ...params,
        'page[number]': page || 1,
        'page[size]': 20,
        include: 'teacher'
      }
    })
    .then(res => res)
  if (!getAll) {
    return res
  } else {
    let currentPage = 1
    let fullResponse = { ...res }
    while (fullResponse.data.length < fullResponse.meta.total_count) {
      currentPage += 1
      let responseForNextPage = await jsonApi
        .findAll('classroom_teachers', {
          params: {
            ...params,
            'page[number]': currentPage,
            'page[size]': 20,
            include: 'teacher'
          }
        })
        .then(response => response.data)
      fullResponse.data = [...fullResponse.data, ...responseForNextPage]
    }
    return fullResponse
  }
}

export const fetchEventConfirmation = data => {
  return http.post('/events/participants', { data })
}

export const fetchEventNonConfirmations = data => {
  return http.post('/events/non_participants', { data })
}

export const fetchAllDestinationsEvents = ({ params, endpoint }) => {
  return http
    .get(endpoint, {
      params: {
        ...params,
        'page[size]': 20
      }
    })
    .then(res => res)
}

export const fetchDestinationsEvents = ({ params, endpoint }) => {
  return jsonApi
    .findAll(endpoint, {
      params: {
        ...params,
        'page[size]': 20
      }
    })
    .then(res => res)
}

export const fetchClassroomsCountBySchool = async schoolId => {
  return http
    .get('classrooms', {
      params: {
        'q[school_id_eq]': schoolId
      }
    })
    .then(res => res)
}

export const fetchDestinationSchool = async ({ params, page, getAll }) => {
  let res
  const classroomsIdIn = params['q[id_in]'].join(',')
  delete params['q[id_in]']
  params['q[id_in]'] = `${classroomsIdIn}`
  res = await jsonApi
    .findAll('classrooms', {
      params: {
        ...params,
        'page[number]': page || 1,
        'page[size]': 20,
        include: 'teachers,children.parents,children.user'
      }
    })
    .then(res => res)
  if (!getAll) {
    return res
  } else {
    let currentPage = 1
    let fullResponse = { ...res }
    while (fullResponse.data.length < fullResponse.meta.total_count) {
      currentPage += 1
      let responseForNextPage = await jsonApi
        .findAll('classrooms', {
          params: {
            ...params,
            'page[number]': currentPage,
            'page[size]': 20,
            include: 'teachers,children.parents,children.user'
          }
        })
        .then(response => response.data)
      fullResponse.data = [...fullResponse.data, ...responseForNextPage]
    }
    return fullResponse
  }
}
