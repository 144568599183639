import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Pagination from '@material-ui/lab/Pagination'

const styles = theme => ({
  root: {
    width: 'fit-content',
    '&:hover': {
      backgroundColor: 'rgba(173, 184, 204, 0.1)'
    },
    '& td': {
      borderBottom: props => props.borderBottom || '1px solid #EFEFEF'
    }
  }
})

const StyledTableRow = withStyles(styles)(TableRow)

const useStyles = makeStyles(theme => ({
  table: {
    [theme.breakpoints.up('lg')]: {
      minWidth: props => (props.minWidth ? props.minWidth : 700)
    }
  },
  pagination: {
    float: 'right',
    paddingTop: '15px',
    [theme.breakpoints.down('426')]: {
      float: 'unset',
      '& .MuiPagination-ul': {
        flexWrap: 'nowrap'
      }
    }
  },
  rowHead: {
    '& th.MuiTableCell-root': {
      [theme.breakpoints.down('376')]: {
        padding: '5px !important'
      }
    }
  },
  firstCell: {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px'
  },
  lastCell: {
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px'
  }
}))

const PaginatedAsyncTable = ({
  columns,
  data,
  noDataAlert = 'Sem dados',
  whiteHeader,
  withoutTableHead,
  pageSize = 10,
  withoutPageCounter,
  borderBottom,
  minWidth,
  className,
  tableClass,
  headBorder,
  tableCellPadding,
  headColor,
  headCellRadius
}) => {
  const classes = useStyles({ minWidth })
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('426'))

  const [page, setPage] = React.useState(1)
  // consts to use the table with static datas
  const [paginatedStaticData, setPaginatedStaticData] = React.useState([])
  const [segmentedData, setSegmentedData] = React.useState([])
  const setPageContent = pageNumber => {
    if (pageNumber >= 1 && pageNumber <= paginatedStaticData.length) {
      setSegmentedData(paginatedStaticData[pageNumber - 1])
    }
  }
  React.useEffect(
    () => {
      setSegmentedData(paginatedStaticData[page - 1])
    },
    [paginatedStaticData]
  )

  React.useEffect(
    () => {
      function paginateArray (array, pageSize) {
        const pageCount = Math.ceil(array.length / pageSize)
        const paginatedArray = []
        for (let i = 0; i < pageCount; i++) {
          const startIndex = i * pageSize
          const endIndex = startIndex + pageSize
          paginatedArray.push(array.slice(startIndex, endIndex))
        }
        if (segmentedData?.length === 0) setSegmentedData(paginatedArray[0])
        return paginatedArray
      }
      if (data?.length) {
        setPaginatedStaticData(paginateArray(data, pageSize))
      }
    },
    [data]
  )

  const StyledTableCell = withStyles(theme => ({
    root: {
      ...tableCellPadding
    },
    head: {
      backgroundColor: headColor,
      color: theme.palette.primary.main,
      fontWeight: 700,
      borderBottom: headBorder || (whiteHeader ? 'none' : '1px solid #EFEFEF'),
      [theme.breakpoints.down('426')]: {
        fontSize: '12px !important',
        '& .MuiTableCell-root': {
          padding: '5px !important'
        }
      }
    },
    body: {
      fontSize: 14,
      [theme.breakpoints.down('426')]: {
        fontSize: '12px !important'
        // '& .MuiTableCell-root': {
        //   padding: '5px !important'
        // }
      }
    }
  }))(TableCell)

  const handleChange = (event, value) => {
    setPageContent(value)
    setPage(value)
  }

  const renderTableRows = data => {
    return data && data.length > 0 ? (
      data.map(row => {
        return (
          <StyledTableRow borderBottom={borderBottom} key={row.id}>
            {columns.map(column => {
              const showIndex = column.render && column.render(row)
              return (
                <StyledTableCell
                  key={`${column.key}[${row.id}]`}
                  align={column.align || 'left'}
                  style={
                    column.changeRowColor
                      ? { color: column.changeRowColor }
                      : {}
                  }
                >
                  {showIndex}
                </StyledTableCell>
              )
            })}
          </StyledTableRow>
        )
      })
    ) : (
      <StyledTableRow borderBottom={borderBottom}>
        <TableCell
          align='center'
          style={{ background: 'none' }}
          colSpan={columns.length}
        >
          {noDataAlert}
        </TableCell>
      </StyledTableRow>
    )
  }

  return (
    <TableContainer className={className} component={Paper} elevation={0}>
      <Table
        className={`${classes.table} ${tableClass}`}
        aria-label='customized table'
      >
        {!withoutTableHead && (
          <TableHead>
            <TableRow className={classes.rowHead}>
              {columns.map((column, index) => (
                <StyledTableCell
                  className={
                    headCellRadius
                      ? index === 0
                        ? classes.firstCell
                        : index === columns.length - 1 ? classes.lastCell : ''
                      : ''
                  }
                  key={column.key}
                  style={{
                    width: column.width,
                    color: '#555555'
                  }}
                >
                  {column.name}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>{renderTableRows(segmentedData)}</TableBody>
      </Table>
      {!withoutPageCounter &&
        paginatedStaticData?.length > 0 && (
          <Pagination
            count={paginatedStaticData.length}
            className={classes.pagination}
            color='primary'
            siblingCount={matches ? undefined : 0}
            page={page}
            onChange={handleChange}
          />
        )}
    </TableContainer>
  )
}

PaginatedAsyncTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  noDataAlert: PropTypes.any,
  whiteHeader: PropTypes.bool,
  withoutTableHead: PropTypes.bool,
  pageSize: PropTypes.number,
  withoutPageCounter: PropTypes.bool,
  borderBottom: PropTypes.string,
  minWidth: PropTypes.string,
  className: PropTypes.string,
  tableClass: PropTypes.string,
  tableCellPadding: PropTypes.object,
  headBorder: PropTypes.string,
  headCellRadius: PropTypes.bool,
  headColor: PropTypes.string
  // headBorder={'none'}
  // borderBottom={'none'}
  // headCellRadius
  // headColor={'rgba(173, 184, 204, 0.3)'}
}

export default PaginatedAsyncTable
