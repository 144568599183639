import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import {
  Field,
  Form,
  formValueSelector,
  reduxForm,
  change,
  getFormValues
} from 'redux-form'
import {
  utilsTypes,
  roles,
  destinationTypeEvent as destinationTypeUtil
} from '../../utils/constants'

import StyledSVG from '../shared/StyledSVG'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import filterSVG from '../../assets/icons/filter-icon.svg'
import { Typography, Tooltip } from '@material-ui/core'
import { makeStyles, styled } from '@material-ui/styles'

import MaterialInput from '../shared/MaterialInput'
import ConfirmModal from '../modal/ConfirmModal'
import Button from '../button/Button'
import AsyncSelect from '../shared/AsyncSelect'
import CustomTable from '../table/CustomTable'
import viewSVG from '../../assets/icons/pattern-view-icon.svg'
import editSVG from '../../assets/icons/pattern-edit-icon.svg'
import deleteSVG from '../../assets/icons/pattern-delete-icon.svg'
import withoutBulletinsSVG from '../../assets/icons/without-bulletins.svg'

import {
  getUtils,
  deleteUtil,
  cleanUtilSuccessful
} from '../../store/utils/actions'
import { showAlertMessage } from '../../store/alert/actions'
import ShowOvercontentTable from '../table/ShowOvercontentTable'
import { getClassroomsBySchoolIdRSPA } from '../../store/classrooms/actions'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  action: {
    color: theme.palette.text.secondary,
    '&:hover': {
      background: '#F6F7F8'
    }
  },
  select: {
    '& .select__menu-list': {
      maxHeight: '150px'
    },
    '& div': {
      '& .select__control': {
        minWidth: 200
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        minWidth: 'unset',
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    },
    [theme.breakpoints.down('1000')]: {
      width: '100% !important',
      '& div': {
        // redux form 'Field' componente
        '& div': {
          '& .select__control, .select__value-container, .select__indicators, .MuiInputBase-root': {
            minHeight: '35px',
            height: '35px'
          },
          maxWidth: '100%'
        }
      }
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 8,
    '& button': {
      marginRight: 0,
      width: 149
    },
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'flex-start',
      marginTop: 15,
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    [theme.breakpoints.down('350')]: {
      '& button': {
        width: '170px !important'
      }
    }
  },
  hidden: {
    opacity: 0,
    visibility: 'hidden',
    position: 'absolute',
    pointerEvents: 'none'
  },
  clearBtn: {
    backgroundColor: '#EF7C8F',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  typography: {
    [theme.breakpoints.down('426')]: {
      '& p': {
        fontSize: '12px'
      }
    }
  },
  createBtn: {
    backgroundColor: '#00ACDB',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px',
    [theme.breakpoints.up('890')]: {
      marginTop: 16
    }
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 45,
    [theme.breakpoints.down('890')]: {
      flexDirection: 'column-reverse',
      gap: 16
    }
  },
  searchDiv: {
    '& .MuiInputBase-root': {
      width: 300,
      [theme.breakpoints.down('426')]: {
        maxWidth: '250px'
      },
      [theme.breakpoints.down('330')]: {
        maxWidth: 220
      }
    }
  },
  searchGroup: {
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    gap: 24,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      gap: 12
    }
  },
  advancedFilter: {
    overflow: 'hidden',
    transition: 'height 0.5s ease-in-out',
    [theme.breakpoints.down('1000')]: {
      overflow: 'scroll'
    }
  },
  listTitle: {
    marginTop: 25,
    color: '#386093',
    [theme.breakpoints.down('426')]: {
      fontSize: 18
    }
  },
  actionsSection: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& path': {
      stroke: '#868E96'
    },
    '& circle': {
      stroke: '#868E96'
    }
  }
}))

const NoUtilStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 16,
  marginTop: 18,
  [theme.breakpoints.down('sm')]: {
    '& h5': {
      fontSize: 18
    }
  },
  '& img': {
    objectFit: 'contain',
    width: '100%',
    maxWidth: 450
  }
}))

const SearchUtilsForm = ({
  utils,
  isFetching,
  pagination,
  schoolId,
  handleSubmit,
  userOccupations,
  startDateValue,
  finalDateValue,
  createdDateValue,
  schoolValue,
  classroomValue,
  gradeValue,
  change,
  reset,
  touch,
  utilType,
  getUtils,
  alert,
  deleteUtil,
  searchUtilTypeValue,
  destinationTypeValue,
  fieldValues,
  cleanUtilSuccessful,
  getClassroomsBySchoolIdRSPA
}) => {
  const classes = useStyles()
  const history = useHistory()
  const selectGradeRef = useRef(null)
  const selectClassroomRef = useRef(null)
  const selectSchoolRef = useRef(null)
  const selectNameRef = useRef(null)
  const [openMoreFilter, setOpenMoreFilter] = React.useState(false)
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)
  const isCoordinator = userOccupations?.includes(roles.COORDINATOR)
  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    title: null,
    util_type: null,
    id: null
  })
  const [totalClassroomsCount, setTotalClassroomsCount] = React.useState(0)

  const utilityTypeId = utilType => {
    switch (utilType) {
      case 'menus':
        return 0
      case 'messages':
        return 1
      case 'links':
        return 2
      case 'documents':
        return 3
      case 'docs_pdf':
        return 4
      case 'bulletins':
        return 5
    }
  }
  const defaultParams = {
    'page[size]': 5,
    'q[school_id_eq]': schoolId,
    'q[utility_type_eq]': utilityTypeId(utilType)
  }

  const utilDateParams = () => {
    const createdDateValueAux = createdDateValue && new Date(createdDateValue)
    return {
      'q[start_date_eq]': startDateValue
        ? moment(startDateValue).format('YYYY-MM-DD')
        : null,
      'q[final_date_eq]': finalDateValue
        ? moment(finalDateValue).format('YYYY-MM-DD')
        : null,
      'q[created_at_gteq]': createdDateValue
        ? moment(createdDateValue).format('YYYY-MM-DD')
        : null,
      'q[created_at_lteq]': createdDateValue
        ? moment(
            new Date(
              createdDateValueAux.setDate(createdDateValueAux.getDate() + 1)
            )
          ).format('YYYY-MM-DD')
        : null
    }
  }
  const [searchParams, setSearchParams] = React.useState({
    ...defaultParams,
    'q[school_id_eq]': schoolValue,
    ...utilDateParams()
  })

  useEffect(
    () => {
      const updatedParams = { ...searchParams }
      updatedParams['q[destinations_classroom_id_eq]'] = classroomValue
      updatedParams['q[destinations_classroom_grade_id_eq]'] = gradeValue
      updatedParams['q[school_id_eq]'] = schoolValue
      setSearchParams(updatedParams)
    },
    [classroomValue, gradeValue, schoolValue]
  )

  useEffect(
    () => {
      if (selectNameRef.current?.value?.length > 0) {
        setSearchParams(
          Object.assign(searchParams, {
            'q[title_cont]': selectNameRef.current?.value
          })
        )
      } else {
        setSearchParams(prev => {
          const { 'q[title_cont]': _, ...rest } = prev
          return rest
        })
      }
    },
    [selectNameRef.current?.value]
  )

  const NoDataAlert = ({ utilType }) => {
    return utilType === 'bulletins' ? (
      <NoUtilStyled>
        <img
          src={withoutBulletinsSVG}
          alt='imagem da lista de utilitários vazia'
        />
        <Typography variant='h5' color='primary'>
          {!classroomValue
            ? 'Escolha a série e a turma que deseja e tenha acesso aos boletins dos alunos.'
            : 'A turma selecionada não possui boletins cadastrados.'}
        </Typography>
      </NoUtilStyled>
    ) : (
      <NoUtilStyled>
        <Typography variant='p'>Sem dados.</Typography>
      </NoUtilStyled>
    )
  }

  useEffect(() => {
    getClassroomsBySchoolIdRSPA({ schoolId: schoolId, onlyCount: true }).then(
      res => setTotalClassroomsCount(res)
    )
    getUtils({ params: defaultParams })
    return () => {
      cleanUtilSuccessful()
    }
  }, [])

  const handleChange = (util, input) => {
    touch()
    let newValue
    if (Array.isArray(util)) {
      newValue = util?.map(v => v)
    } else {
      newValue = util ? util.value : ''
    }
    change(input.name, newValue)
  }

  const handleClearFields = () => {
    reset()
    selectGradeRef.current?.select.clearValue()
    selectClassroomRef.current?.select.clearValue()
    selectSchoolRef.current?.select.clearValue()
    getUtils({ params: defaultParams })
  }

  const handleChangeTitle = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      getUtils({
        params: { ...defaultParams, 'q[title_cont]': event.target.value }
      })
    }
  }

  const firstPageAfterSearch = React.useRef(false)

  const handleSearch = () => {
    searchUtilTypeValue &&
      history.push(`?name=list&util=${searchUtilTypeValue}`)
    getUtils({ params: searchParams })
    firstPageAfterSearch.current = true
  }

  const columns = [
    {
      key: 'title',
      name: 'Nome',
      width: '30%',
      render: function render (row) {
        let redirectLink
        if (row.utility_type !== 'links') {
          redirectLink = row.files?.[0]?.file_url || row.file_url
        } else {
          redirectLink = row.note
        }
        return (
          <Tooltip title={row.title}>
            <div className={classes.typography}>
              <Typography
                variant='subtitle2'
                component='p'
                onClick={() =>
                  redirectLink
                    ? window.open(redirectLink, '_blank')
                    : alert(
                        `${utilsTypes[row.utility_type]} não anexado`,
                        'warning'
                      )
                }
              >
                {row.title}
              </Typography>
            </div>
          </Tooltip>
        )
      }
    },
    {
      key: 'school',
      name: 'Escola',
      render: function render (row) {
        return <p>{row.school?.name}</p>
      }
    },
    {
      key: 'createdDate',
      name: 'Criação',
      width: '10%',
      render: function render (row) {
        return moment(row.created_at).format('DD/MM/YYYY HH:mm')
      }
    },
    {
      key: 'scheduledDate',
      name: 'Publicação',
      width: '10%',
      render: row => {
        return moment(row.scheduled_at).format('DD/MM/YYYY HH:mm')
      }
    },
    {
      key: 'finalDate',
      name: 'Final',
      width: '10%',
      render: row => {
        return moment(row.final_date).format('DD/MM/YYYY')
      }
    },
    {
      key: 'type',
      name: 'Tipo',
      width: '10%',
      render: function render (row) {
        let type = utilsTypes[row.utility_type]
        return <p>{type}</p>
      }
    },
    {
      key: 'receiver',
      name: 'Destinatário',
      width: '10%',
      render: function render (row) {
        if (row?.utility_type === 'bulletins') {
          return (
            <p key={row.destinations[0]?.child_id}>
              {row.destinations[0]?.child?.name}
            </p>
          )
        } else {
          if (row.destinations) {
            const destinationType =
              row?.destinations?.length > 0 &&
              (row?.destinations[0]?.destination_type === null ||
                row?.destinations[0]?.destination_type === undefined)
                ? 'Todos'
                : destinationTypeUtil[row?.destinations[0]?.destination_type]
            return <p key={row.id}>{destinationType}</p>
          }
        }
      }
    },
    {
      key: 'grade',
      name: 'Série(s)',
      width: '10%',
      render: function render (row) {
        if (row?.destinations?.length === totalClassroomsCount) {
          return <p>---</p>
        } else {
          if (row?.utility_type === 'bulletins') {
            const grade = row?.grade[0]?.name
            return <ShowOvercontentTable item={grade} />
          } else {
            let grades = new Set()
            row?.destinations?.map(item => {
              grades.add(item?.classroom?.grade?.name)
            })
            const gradesFormated = Array.from(grades).map(
              (item, index, array) => {
                return item
              }
            )
            return <ShowOvercontentTable item={gradesFormated} />
          }
        }
      }
    },
    {
      key: 'classroom',
      name: 'Turma(s)',
      width: '10%',
      render: function render (row) {
        if (row?.destinations?.length === totalClassroomsCount) {
          return <p>Toda escola</p>
        } else {
          if (row?.utility_type === 'bulletins') {
            const classroom = row?.classroom[0]?.name
            return <ShowOvercontentTable item={classroom} />
          } else {
            let classrooms = new Set()
            row?.destinations?.map(item => {
              classrooms.add(item?.classroom?.name)
            })
            const classroomsFormated = Array.from(classrooms).map(
              (item, index, array) => {
                return item
              }
            )
            return <ShowOvercontentTable item={classroomsFormated} />
          }
        }
      }
    },
    {
      key: 'actions',
      name: 'Ações',
      width: '10%',
      align: 'right',
      render: function render (row) {
        let redirectLink
        if (row.utility_type !== 'links') {
          redirectLink = row.files?.[0]?.file_url || row.file_url
        } else {
          redirectLink = row.note
        }
        return (
          <div className={classes.actionsSection}>
            <Tooltip title='Visualizar'>
              <div
                onClick={() => {
                  redirectLink
                    ? window.open(redirectLink, '_blank')
                    : alert(
                        `${utilsTypes[row.utility_type]} não anexado`,
                        'warning'
                      )
                }}
              >
                <StyledSVG width={24} height={24} src={viewSVG} />
              </div>
            </Tooltip>
            {(isAdmin || isCoordinator) && (
              <Tooltip title='Editar'>
                <div
                  onClick={() => {
                    history.push(
                      `?id=${row.id}&name=edit&util=${row.utility_type}`
                    )
                  }}
                >
                  <StyledSVG width={24} height={24} src={editSVG} />
                </div>
              </Tooltip>
            )}
            {(isAdmin || isCoordinator) && (
              <Tooltip title='Excluir'>
                <div
                  onClick={() =>
                    setConfirmModal({
                      id: row.id,
                      title: row.title,
                      util_type: utilsTypes[row.utility_type],
                      open: true
                    })
                  }
                >
                  <StyledSVG width={24} height={24} src={deleteSVG} />
                </div>
              </Tooltip>
            )}
          </div>
        )
      }
    }
  ]

  const getColumns = () => {
    const columnKeys = isVersarAdmin
      ? [
          'title',
          'school',
          'createdDate',
          'scheduledDate',
          'finalDate',
          'type',
          'receiver',
          'grade',
          'classroom',
          'actions'
        ]
      : [
          'title',
          'createdDate',
          'scheduledDate',
          'finalDate',
          'type',
          'receiver',
          'grade',
          'classroom',
          'actions'
        ]

    return columns.filter(column => columnKeys.includes(column.key))
  }

  return (
    <div className={classes.root}>
      <Form onSubmit={handleSubmit}>
        {/* Header */}
        <div>
          {/* content */}
          <div>
            <div className={classes.searchContainer}>
              <div className={classes.searchDiv}>
                <strong style={{ marginBottom: 10 }}>Busca</strong>
                <Field
                  style={{ width: 300 }}
                  component={MaterialInput}
                  ref={selectNameRef}
                  name='title'
                  onKeyDown={handleChangeTitle}
                  placeholder={`Título do ${utilsTypes[
                    utilType
                  ].toLowerCase()}`}
                  type='text'
                />
              </div>
              {(isAdmin || isCoordinator) && (
                <Button
                  style={{
                    lineHeight: '16px',
                    whiteSpace: 'nowrap',
                    textTransform: 'none'
                  }}
                  className={classes.createBtn}
                  color='primary'
                  type='button'
                  onClick={() => history.push(`?name=new&util=${utilType}`)}
                >
                  {`Criar`}
                </Button>
              )}
            </div>
          </div>
        </div>
        {/* Advanced filter */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <StyledSVG src={filterSVG} height={12} width={12} />
            <p
              onClick={() => setOpenMoreFilter(prev => !prev)}
              style={{
                fontWeight: 700,
                fontSize: 12,
                color: '#386093',
                cursor: 'default'
              }}
            >
              Filtros avançados
            </p>
            {!openMoreFilter ? (
              <KeyboardArrowDownIcon
                onClick={() => setOpenMoreFilter(prev => !prev)}
                style={{ height: 12, width: 12 }}
              />
            ) : (
              <KeyboardArrowUpIcon
                onClick={() => setOpenMoreFilter(prev => !prev)}
                style={{ height: 12, width: 12 }}
              />
            )}
          </div>
          <div
            className={classes.advancedFilter}
            style={{
              height: openMoreFilter ? '250px' : '0px'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 24
              }}
            >
              <div
                className={`${!openMoreFilter ? classes.hidden : ''} ${
                  classes.searchGroup
                }`}
                style={{ opacity: openMoreFilter ? '1' : '0' }}
              >
                {isVersarAdmin && (
                  <div className={classes.select}>
                    <strong htmlFor='school_id'>Escola</strong>
                    <Field
                      component={AsyncSelect}
                      selectRef={selectSchoolRef}
                      defaultOptions={false}
                      id='school_id'
                      name='school_id'
                      placeholder='Digite ou selecione a escola'
                      handleAsyncChange={handleChange}
                      searchParam='q[name_cont]'
                      request={{
                        path: 'school'
                      }}
                      touch={touch}
                    />
                  </div>
                )}
                <div className={classes.select}>
                  <strong htmlFor='grade_id'>Série</strong>
                  <Field
                    component={AsyncSelect}
                    defaultOptions={false}
                    selectRef={selectGradeRef}
                    cacheUniqs={[schoolValue]}
                    id='grade_id'
                    name='grade_id'
                    placeholder='Digite ou selecione'
                    handleAsyncChange={handleChange}
                    searchParam='q[name_cont]'
                    request={{
                      path: 'grade',
                      params: {
                        'q[school_id_null]': true
                      }
                    }}
                    touch={touch}
                  />
                </div>
                <div
                  className={classes.select}
                  // style={{
                  //   display: 'flex',
                  //   flexDirection: 'column',
                  //   alignItems: 'flex-start'
                  // }}
                >
                  <strong htmlFor='classroom_id'>Turma</strong>
                  <Field
                    component={AsyncSelect}
                    defaultOptions={false}
                    id='classroom_id'
                    name='classroom_id'
                    selectRef={selectClassroomRef}
                    cacheUniqs={[gradeValue, schoolValue]}
                    placeholder='Digite ou selecione'
                    handleAsyncChange={handleChange}
                    searchParam='q[name_cont]'
                    request={{
                      path: 'classroom',
                      params: {
                        'q[school_id_in]': schoolId || schoolValue,
                        'q[grade_id_in]': gradeValue,
                        'q[year_lteq]': new Date().getFullYear() + 1,
                        'q[year_gteq]': new Date().getFullYear()
                      }
                    }}
                    touch={touch}
                  />
                </div>
                <div />
              </div>
              <div
                style={{
                  opacity: openMoreFilter ? '1' : '0',
                  transition: 'opacity 0.3s ease-in-out'
                }}
                className={`${classes.buttons} ${
                  !openMoreFilter ? classes.hidden : ''
                }`}
              >
                <Button
                  className={classes.clearBtn}
                  onClick={() => {
                    setOpenMoreFilter(false)
                    handleClearFields()
                  }}
                >
                  Limpar
                </Button>
                <Button
                  onClick={() => {
                    handleSearch()
                  }}
                  className={classes.applyBtn}
                  type='button'
                >
                  Aplicar
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* Horizontal bar */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: 1,
            border: '#ADB8CC solid 1px'
          }}
        />
        <div />
      </Form>
      <h1 className={classes.listTitle}>{`Lista de ${utilsTypes[
        utilType
      ].toLowerCase()}s`}</h1>
      <CustomTable
        from={'utils'}
        columns={getColumns()}
        data={utils}
        columnsPointer={[0]}
        border={'none'}
        bodyBorder={'none'}
        headCellRadius
        headColor={'rgba(173, 184, 204, 0.3)'}
        isFetching={isFetching}
        fetchItems={getUtils}
        params={searchParams}
        pagination={pagination}
        firstPageAfterSearch={firstPageAfterSearch}
        noDataAlert={<NoDataAlert utilType={utilType} />}
      />
      <ConfirmModal
        title={`Deseja remover ${
          confirmModal.title
        } da lista de ${confirmModal?.util_type?.toLowerCase()} ?`}
        open={confirmModal.open}
        setOpen={value => {
          if (value) {
            setConfirmModal({ ...confirmModal, open: value })
          } else {
            setConfirmModal({ id: null, title: '', util_type: '', open: value })
          }
        }}
        onConfirm={() => {
          deleteUtil(confirmModal.id)
        }}
      />
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('searchUtilsForm')

  return {
    utils: state.utils.items,
    isFetching: state.utils.isFetching,
    pagination: state.utils.pagination,
    userOccupations: state.auth.currentOccupation,
    schoolId:
      state.school.currentSchool && state.school.currentSchool.school_id,
    startDateValue: selector(state, 'start_date'),
    finalDateValue: selector(state, 'final_date'),
    createdDateValue: selector(state, 'created_at'),
    schoolValue:
      selector(state, 'school_id') ||
      (state.school.currentSchool && state.school.currentSchool.school_id),
    classroomValue: selector(state, 'classroom_id'),
    gradeValue: selector(state, 'grade_id'),
    destinationTypeValue: selector(state, 'destination_type'),
    searchUtilTypeValue: selector(state, 'search_util_type'),
    fieldValues: getFormValues('searchUtilsForm')(state)
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('searchUtilsForm', field, data)),
  getUtils: data => dispatch(getUtils(data)),
  deleteUtil: data => dispatch(deleteUtil(data)),
  cleanUtilSuccessful: data => dispatch(cleanUtilSuccessful(data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    ),
  getClassroomsBySchoolIdRSPA: data =>
    dispatch(getClassroomsBySchoolIdRSPA.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'searchUtilsForm'
  })(SearchUtilsForm)
)
