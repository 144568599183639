import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

const RouteWithTemplate = ({
  component: Component,
  template: Template,
  ...rest
}) => {
  const { componentAttribute } = rest
  return (
    <Route
      {...rest}
      render={props => (
        <Template {...props}>
          <Component componentAttribute={componentAttribute} {...props} />
        </Template>
      )}
    />
  )
}

RouteWithTemplate.propTypes = {
  component: PropTypes.func.isRequired,
  template: PropTypes.func.isRequired
}

export default RouteWithTemplate
