import { createAction } from '@reduxjs/toolkit'
import { createPromiseAction } from 'redux-saga-promise-actions'
import * as types from './types'

export const getMaterials = createAction(types.GET_MATERIALS)
export const getMaterialsSuccessful = createAction(
  types.GET_MATERIALS_SUCCESSFUL
)
export const getMaterialsFailure = createAction(types.GET_MATERIALS_FAILURE)

export const getMaterialById = createAction(types.GET_MATERIAL_BY_ID)
export const getMaterialByIdSuccessful = createAction(
  types.GET_MATERIAL_BY_ID_SUCCESSFUL
)
export const getMaterialByIdFailure = createAction(
  types.GET_MATERIAL_BY_ID_FAILURE
)

export const createMaterials = createAction(types.CREATE_MATERIALS)
export const createMaterialsSuccessful = createAction(
  types.CREATE_MATERIALS_SUCCESSFUL
)
export const createMaterialsFailure = createAction(
  types.CREATE_MATERIALS_FAILURE
)

export const deleteMaterial = createAction(types.DELETE_MATERIAL)
export const deleteMaterialSuccessful = createAction(
  types.DELETE_MATERIAL_SUCCESSFUL
)
export const deleteMaterialFailure = createAction(types.DELETE_MATERIAL_FAILURE)

export const editMaterial = createAction(types.EDIT_MATERIAL)
export const editMaterialSuccessful = createAction(
  types.EDIT_MATERIAL_SUCCESSFUL
)
export const editMaterialFailure = createAction(types.EDIT_MATERIAL_FAILURE)

export const setCurrentMaterial = createAction(types.SET_CURRRENT_MATERIAL)
export const cleanMaterialSuccessful = createAction(
  types.CLEAN_CURRRENT_MATERIAL
)

export const exportMaterialReportRSPA = createPromiseAction(
  'rspa: EXPORT_MATERIAL_REPORT'
)()
