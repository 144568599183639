import { all, call, takeLatest, put } from 'redux-saga/effects'

import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'
import { showAlertMessage } from '../alert/actions'
import { editModule } from '../modules/actions'
import * as actions from './actions'
import * as services from './services'

export function * fetchThemes ({ payload }) {
  try {
    const themesData = yield call(services.fetchThemes, payload)
    yield put(actions.getThemesSuccessful(themesData))
  } catch (error) {
    yield put(actions.getThemesFailure(error))
  }
}

export function * fetchThemeById ({ action, payload }) {
  const { ids } = payload
  try {
    const response = yield call(services.fetchThemeById, ids)
    yield put(actions.getThemeByIdSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getThemeByIdFailure(error))
  }
}

export function * fetchThemeContents ({ payload }) {
  try {
    const response = yield call(services.fetchThemeContents, payload)
    yield put(actions.getThemeContentsSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getThemeContentsFailure(error))
  }
}

export function * createThemeRequest ({ payload }) {
  try {
    const response = yield call(services.createTheme, payload)
    yield put(actions.createThemeSuccessful(response))
  } catch (error) {
    yield put(actions.createThemeFailure(error))
    yield put(
      showAlertMessage({ message: 'Falha ao criar tema.', severity: 'error' })
    )
  }
}

export function * editThemeRequest ({ payload }) {
  try {
    const response = yield call(services.editTheme, payload)
    yield put(actions.editThemeSuccessful(response))
  } catch (error) {
    yield put(actions.editThemeFailure(error))
    yield put(
      showAlertMessage({ message: 'Falha ao salvar tema.', severity: 'error' })
    )
  }
}

export function * deleteThemeRequest ({ payload }) {
  const { id, afterEffect } = payload
  try {
    const themeById = yield call(services.fetchThemeById, id)
    const module =
      themeById?.included &&
      themeById.included.filter(include => include.type === 'theme_modules')
    let moduleTheme
    if (module && module.length > 0) {
      moduleTheme =
        module && module[0].attributes && module[0].attributes.themes_order
      moduleTheme && moduleTheme.splice(moduleTheme.indexOf(id), 1)
    }
    yield call(services.deleteTheme, id)
    afterEffect && afterEffect()
    yield module &&
      module.length > 0 &&
      put(editModule({ id: module[0].id, data: { themes_order: moduleTheme } }))
    yield put(actions.deleteThemeSuccessful(id))
  } catch (error) {
    console.error(error)
    yield put(actions.deleteThemeFailure(error))
  }
}

export function * fetchThemeSegments ({ payload }) {
  const { ids, afterEffect } = payload
  try {
    const response = yield all(
      ids.map(id => call(services.fetchThemeSegments, id))
    )
    afterEffect(response)
    yield put(actions.getThemeSegmentsSuccessful())
  } catch (error) {
    yield put(actions.getThemesSegmentsFailure(error))
  }
}

export function * fetchTheme ({ payload }) {
  const { id } = payload
  try {
    const response = yield call(services.fetchTheme, id)
    yield put(actions.getThemeByIdSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getThemeByIdFailure(error))
  }
}

export function * cloneThemeRequst ({ payload }) {
  try {
    const response = yield call(services.cloneTheme, payload)
    yield put(actions.cloneThemeSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.cloneThemeFailure(error))
  }
}
export function * fetchThemesRSPARequest (action) {
  try {
    const { payload: { themeId } } = action
    const response = yield call(services.fetchTheme, themeId)
    resolvePromiseAction(action, response)
  } catch (error) {
    console.error(error)
    rejectPromiseAction(action, error)
  }
}

export function * fetchSegmentByModuleRSPARequest (action) {
  try {
    const { payload: { moduleId } } = action
    const response = yield call(services.fetchSegmentByModule, moduleId)
    resolvePromiseAction(action, response)
  } catch (error) {
    console.error(error)
    rejectPromiseAction(action, error)
  }
}

export function * createThemeRSPARequest (action) {
  try {
    const { payload } = action
    const newValues = {
      title: payload.title,
      description: payload.description,
      theme_module_id: payload.module.id,
      skill_ids: payload.skill_ids.map(item => +item.value || +item),
      school_id: payload.school.id,
      cover_id: payload.cover_id
    }
    const response = yield call(services.createTheme, newValues)
    resolvePromiseAction(action, response)
  } catch (error) {
    console.error(error)
    rejectPromiseAction(action, error)
  }
}

export function * newGetThemeByIdRequest ({ payload }) {
  try {
    const response = yield call(services.newFetchThemeById, payload.id)
    yield put(actions.newGetThemeByIdSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.newGetThemeByIdFailure(error))
  }
}

export function * newFetchModules ({ payload }) {
  try {
    const modulesData = yield call(services.newFetchThemes, payload)
    yield put(actions.newGetThemesSuccessful(modulesData))
  } catch (error) {
    yield put(actions.newGetThemesFailure(error))
  }
}

export function * newFetchThemeContents ({ payload }) {
  try {
    const response = yield call(services.newFetchThemeContents, payload)
    yield put(actions.newGetThemeContentsSuccessful(response.data))
  } catch (error) {
    console.error(error)
    yield put(actions.newGetThemeContentsFailure(error))
  }
}

// Watchers
export function * watchThemes () {
  yield takeLatest(actions.getThemes, fetchThemes)
  yield takeLatest(actions.getThemeById, fetchThemeById)
  yield takeLatest(actions.getThemeContents, fetchThemeContents)
  yield takeLatest(actions.createTheme, createThemeRequest)
  yield takeLatest(actions.editTheme, editThemeRequest)
  yield takeLatest(actions.deleteTheme, deleteThemeRequest)
  yield takeLatest(actions.getThemeSegments, fetchThemeSegments)
  yield takeLatest(actions.getTheme, fetchTheme)
  yield takeLatest(actions.cloneTheme, cloneThemeRequst)
  yield takeLatest(actions.getThemesRSPA.request, fetchThemesRSPARequest)
  yield takeLatest(
    actions.getSegmentByModuleRSPA.request,
    fetchSegmentByModuleRSPARequest
  )
  yield takeLatest(actions.createThemeRSPA.request, createThemeRSPARequest)
  yield takeLatest(actions.newGetThemeById, newGetThemeByIdRequest)
  yield takeLatest(actions.newGetThemes, newFetchModules)
  yield takeLatest(actions.newGetThemeContents, newFetchThemeContents)
}

export default function * themesSaga () {
  yield all([watchThemes()])
}
