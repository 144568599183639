import React from 'react'
import { connect } from 'react-redux'

import SendMessageForm from '../components/form/SendMessageForm'
import {
  createCommunication,
  cleanSuccess
} from '../store/communications/actions'
import { roles } from '../utils/constants'

const mapStateToProps = ({ auth, school, communications }) => {
  return {
    currentSchool: school.currentSchool,
    selectedSchoolItem: school.selectedItem,
    userOccupations: auth.currentOccupation,
    isSuccess: communications.isSuccess
  }
}

const mapDispatchToProps = {
  createCommunication,
  cleanSuccess
}

const SendMessagePage = ({
  currentSchool,
  selectedSchoolItem,
  userOccupations,
  createCommunication,
  cleanSuccess,
  isSuccess
}) => {
  const isAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)

  return (
    <div style={{ padding: '1rem 0' }}>
      <SendMessageForm
        createCommunication={createCommunication}
        selectedSchool={selectedSchoolItem}
        school={currentSchool}
        isAdmin={isAdmin}
        isSuccess={isSuccess}
        cleanSuccess={cleanSuccess}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SendMessagePage)
