export const occupationsObject = {
  administrator: 'Administrador',
  school_administrator: 'Administrador da escola',
  content_creator: 'Criador de conteúdo',
  versar_content_reader: 'Leitor de conteúdo',
  director: 'Diretor',
  coordinator: 'Coordenador',
  psychologist: 'Psicólogo(a)',
  teacher: 'Professor(a)',
  student: 'Estudante',
  parent: 'Responsável',
  school_moderator: 'Moderador da escola',
  classroom_moderator: 'Moderador de turma',
  administrators: 'Administrador',
  school_administrators: 'Administrador da escola',
  content_creators: 'Criador de conteúdo',
  versar_content_readers: 'Leitor de conteúdo',
  directors: 'Diretor',
  coordinators: 'Coordenador',
  psychologists: 'Psicólogo(a)',
  teachers: 'Professor(a)',
  students: 'Estudante',
  parents: 'Responsável',
  school_moderators: 'Moderador da escola',
  classroom_moderators: 'Moderador de turma'
}
