import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useQuery } from '../utils/helpers'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import StyledSVG from '../components/shared/StyledSVG'
import ButtonCard from '../components/card/ButtonCard'
import {
  getCommunications,
  updateCommunication
} from '../store/communications/actions'
import MessagesView from '../components/view/MessagesView'
import jsonApi from '../utils/jsonApi'
import { roles } from '../utils/constants'
import onGoingSVG from '../assets/icons/message-ongoing.svg'
import finishedSVG from '../assets/icons/message-finished.svg'
import newsSVG from '../assets/icons/message-new.svg'
import allMsgSVG from '../assets/icons/message-all.svg'

const mapStateToProps = ({ auth, communications, school }) => {
  return {
    communications: communications,
    user: auth.user?.data,
    selectedSchoolItem: school.selectedItem || school.currentSchool.school,
    userOccupations: auth.currentOccupation,
    isFetching: communications.isFetching,
    schoolId: school.currentSchool && school.currentSchool.school_id
  }
}

const mapDispatchToProps = dispatch => ({
  getCommunications: data => dispatch(getCommunications(data)),
  updateCommunication: data => dispatch(updateCommunication(data))
})

const defaultColor = '#00ACDB'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem 0'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
    margin: 16,
    flexWrap: 'wrap',
    [theme.breakpoints.down('426')]: {
      '& button': {
        width: '100px',
        height: '100px',
        '& svg': {
          width: '30px !important',
          height: '30px !important',
          minWidth: '30px !important',
          minHeight: '30px !important'
        },
        '& p': {
          fontSize: '10px'
        }
      }
    },
    [theme.breakpoints.down('330')]: {
      '& button': {
        width: '80px',
        height: '80px'
      }
    }
  },
  paper: {
    borderRadius: 8,
    padding: 32
  }
}))

const MessagesPage = ({
  communications,
  user,
  isFetching,
  userOccupations,
  match,
  selectedSchoolItem,
  getCommunications,
  updateCommunication,
  schoolId
}) => {
  const classes = useStyles()
  const history = useHistory()
  const query = useQuery()
  const [quantity, setQuantity] = useState({})
  const [currentStatusFilter, setCurrentStatusFilter] = useState('')

  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)
  const isTeacher = userOccupations?.includes(roles.TEACHER)

  //  isFetching is in the dependency array of useeffect because the counter
  //  must be update whenever communications are activated/deactivated
  useEffect(
    () => {
      const statusOptions = ['ongoing', 'pending']
      statusOptions.map((status, index) => {
        getCommunicationByStatus(index).then(res => {
          setQuantity(prevState => {
            return { ...prevState, [status]: res.meta.total_count }
          })
        })
      })
      jsonApi
        .findAll('communications', {
          params: {
            'q[school_id_in]': schoolId || selectedSchoolItem?.id,
            'q[active_eq]': false
          }
        })
        .then(res => {
          setQuantity(prevState => {
            return { ...prevState, finished: res.meta.total_count }
          })
        })
    },
    [isFetching]
  )

  const getCommunicationByStatus = status => {
    return jsonApi.findAll('communications', {
      params: {
        'q[school_id_in]': schoolId || selectedSchoolItem?.id,
        'q[active_eq]': true,
        'q[status_eq]': status
      }
    })
  }
  const topButtons = [
    {
      id: 1,
      title: 'Novas',
      icon: newsSVG,
      type: 'new-message',
      count: quantity['pending'],
      filterBy: 1
    },
    {
      id: 2,
      title: 'Em andamento',
      icon: onGoingSVG,
      type: 'ongoing-message',
      count: quantity['ongoing'],
      filterBy: 0
    },
    {
      id: 3,
      title: 'Finalizadas',
      icon: finishedSVG,
      type: 'finished-message',
      count: quantity['finished'],
      filterBy: 'active'
    },
    {
      id: 4,
      title: 'Todas',
      icon: allMsgSVG,
      type: 'all-message',
      count: quantity['finished'] + quantity['ongoing'] + quantity['pending'],
      filterBy: 'none'
    }
  ]

  const handleClickButton = (type, filterBy) => {
    history.push(`?name=list&messages=${type}`, { filterBy })
  }

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        {topButtons.map(btn => {
          return (
            <ButtonCard
              key={btn.id}
              styleType={
                String(btn.filterBy) === String(currentStatusFilter) ||
                (btn.filterBy === 'none' && currentStatusFilter === null)
                  ? 'default'
                  : 'disabled'
              }
              onClick={() => handleClickButton(btn.type, btn.filterBy)}
            >
              <StyledSVG
                color={defaultColor}
                src={btn.icon}
                width={35}
                height={35}
              />
              <div>
                <p style={{ margin: 0 }}>{btn.title}</p>
                <p style={{ margin: 0 }}>{`(${btn.count || 0})`}</p>
              </div>
            </ButtonCard>
          )
        })}
      </div>
      <MessagesView
        updateCommunication={updateCommunication}
        messageType={query.get('messages')}
        setCurrentStatusFilter={setCurrentStatusFilter}
        userOccupations={userOccupations}
        getCommunications={getCommunications}
        schoolId={schoolId}
        communications={communications}
        userId={user?.id}
        quantity={quantity}
        isFetching={isFetching}
        selectedSchool={selectedSchoolItem}
        isTeacher={isTeacher}
        isAdmin={isAdmin}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesPage)
