import { createReducer } from '@reduxjs/toolkit'
import * as types from './types'

const initialState = {
  items: [],
  isFetching: false,
  isError: false,
  errorMessage: '',
  tagOptions: [],
  pagination: {
    total: 1,
    page: 1,
    pageCount: 1
  }
}

const reportsReducer = createReducer(initialState, {
  [types.GET_TAGS]: (state, action) => ({
    ...state,
    items: [],
    isFetching: true
  }),
  [types.GET_TAGS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: action.payload.data,
    pagination: {
      total: action.payload.meta.total_count,
      pageCount: action.payload.meta.page_count
    },
    isFetching: false
  }),
  [types.GET_TAGS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_TAG_OPTIONS]: (state, action) => ({
    ...state,
    tagOptions: [],
    isFetching: true
  }),
  [types.GET_TAG_OPTIONS_SUCCESSFUL]: (state, action) => ({
    ...state,
    tagOptions: action.payload.data,
    isFetching: false
  }),
  [types.GET_TAG_OPTIONS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    tagOptions: [],
    isError: true,
    errorMessage: action.payload
  })
})

export default reportsReducer
