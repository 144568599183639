import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import DescriptionIcon from '@material-ui/icons/Description'
import { occupationsObject } from '../../utils/dicts'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 1220
  },
  paper: {
    padding: theme.spacing(2),
    margin: 0,
    borderRadius: '8px',
    lineHeight: '20.11px !important',
    fontSize: 16,
    minWidth: 300,
    paddingLeft: '1.5rem',
    maxWidth: '67vw',
    paddingTop: '3rem',
    '& h2': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '80vw'
    }
  },
  cardContainer: {
    alignItems: 'center',
    background: '#F6F7F8',
    borderRadius: 8,
    minWidth: 280,
    minHeight: 116,
    margin: 10,
    padding: '1rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& p': {
      color: '#555555',
      marginBottom: 0
    },
    '& h2': {
      margin: 0
    }
  },
  linkContainer: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '16px',
    verticalAlign: 'middle',
    justifyContent: 'center',
    display: 'inline-block',
    padding: '0 5px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}0a`
    },
    '& a, svg': {
      textDecoration: 'none',
      color: theme.palette.primary.main
    },
    '& svg': {
      paddingRight: '5px',
      verticalAlign: 'middle'
    }
  },
  backContainer: {
    margin: '1rem 10px 2rem',
    textAlign: 'end'
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const ContentView = ({ currentContent, contentIncluded }) => {
  const history = useHistory()
  const classes = useStyles()
  const content =
    (currentContent?.data && currentContent.data[0]?.attributes) || {}
  const theme =
    contentIncluded &&
    contentIncluded.filter(include => include.type === 'themes')
  const segment =
    contentIncluded &&
    contentIncluded.filter(include => include.type === 'segments')
  const occupation =
    contentIncluded &&
    contentIncluded.filter(include => include.type === 'occupations')
  const category =
    contentIncluded &&
    contentIncluded.filter(include => include.type === 'categories')

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <div className={classes.cardContainer}>
              <h2>Título do conteúdo</h2>
              <p>{content.title}</p>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className={classes.cardContainer}>
              <h2>Documentos</h2>
              <span>
                {content &&
                (!content.document_urls ||
                  content.document_urls?.length === 0) ? (
                    <p>Sem documentos</p>
                ) : (
                  content.document_urls.map(doc => (
                    <Tooltip
                      key={doc.id}
                      title='Download'
                      aria-label='Download'
                    >
                      <p className={classes.linkContainer}>
                        <DescriptionIcon />
                        <a
                          download
                          target='_blank'
                          href={doc.url}
                          rel='noreferrer'
                        >
                          {doc.filename}
                        </a>
                      </p>
                    </Tooltip>
                  ))
                )}
              </span>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className={classes.cardContainer}>
              <h2>Tema</h2>
              <p>{theme && theme[0]?.attributes?.title}</p>
            </div>
          </Grid>
          {/* <Grid item md={6}>
            <div className={classes.cardContainer}>
              <h2>Áudio</h2>
              {content && content.audio_url ? (
                <audio
                  style={{ marginTop: 10 }}
                  controls
                  src={content && content.audio_url}
                >
                  O seu navegador não suporta o elemento <code>audio</code>.
                </audio>
              ) : (
                <p>Sem áudio</p>
              )}
            </div>
          </Grid> */}
          <Grid item md={6}>
            <div className={classes.cardContainer}>
              <h2>Segmento</h2>
              <p>{segment && segment[0]?.attributes?.name}</p>
            </div>
          </Grid>
          {/* <Grid item md={6}>
            <div className={classes.cardContainer}>
              <h2>Vídeo</h2>
              {content && content.video_url ? (
                <Tooltip title='Acessar' aria-label='Acessar'>
                  <p className={classes.linkContainer}>
                    <YouTubeIcon />
                    <a
                      href={content.video_url}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      {content.video_url}
                    </a>
                  </p>
                </Tooltip>
              ) : (
                <p>Sem vídeo</p>
              )}
            </div>
          </Grid> */}
          <Grid item md={4}>
            <div className={classes.cardContainer}>
              <h2>Foco</h2>
              <p>
                {occupation &&
                  occupationsObject[occupation[0]?.attributes?.name]}
              </p>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className={classes.cardContainer}>
              <h2>Categoria</h2>
              <p>{category && category[0]?.attributes?.name}</p>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className={classes.cardContainer}>
              <h2>Etapa</h2>
              <p>{content.stage}</p>
            </div>
          </Grid>
          <Grid item md={12}>
            <div className={classes.cardContainer}>
              <h2>Resumo</h2>
              <p>{content.abstract}</p>
            </div>
          </Grid>
          <Grid item md={12}>
            <div className={classes.cardContainer}>
              <h2>Texto</h2>
              <p dangerouslySetInnerHTML={{ __html: content.text_content }} />
            </div>
          </Grid>
          <Grid item md={12}>
            <div className={classes.backContainer}>
              <Button
                className={classes.backBtn}
                variant='outlined'
                type='button'
                onClick={() => history.goBack()}
              >
                Voltar
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default ContentView
