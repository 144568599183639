import jsonApi from '../../utils/jsonApi'
import http from '../../utils/http'

const coversModel = 'covers'

export const fetchCovers = ({ page, per }) => {
  return jsonApi.findAll(coversModel, {
    params: {
      'page[number]': page,
      'page[size]': per || 18
    }
  })
}

export const createCovers = data =>
  http
    .post(coversModel, data, {
      'Content-Type': 'multipart/form-data'
    })
    .then(res => res.data)
