import React from 'react'
import PropTypes from 'prop-types'
import MaterialChip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core/styles'

const Chip = props => {
  const StyledChip = withStyles(theme => ({
    root: {
      color: props.checked
        ? theme.palette.info.main
        : theme.palette.primary.main,
      backgroundColor: `${
        props.checked ? theme.palette.info.main : theme.palette.primary.main
      }14 !important`,
      border: `0.7px solid ${
        props.checked
          ? theme.palette.info.main
          : theme.palette.primary.main + '14'
      }`,
      borderRadius: 16,
      fontSize: 18,
      fontWeight: 400,
      height: 32,
      padding: 4,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        filter: 'brightness(95%)'
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: 'red', // `${theme.palette.info.main}14`,
        borderColor: theme.palette.info.main,
        color: theme.palette.info.main
      },
      '&:focus': {
        backgroundColor: `${theme.palette.info.main}14 !important`,
        borderColor: theme.palette.info.main,
        color: theme.palette.info.main
      }
    },
    outlined: {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: `transparent !important`
    },
    icon: {
      color:
        props.variant === 'outlined' ? theme.palette.primary.main : '#ADB8CC'
    },
    deleteIcon: {
      color:
        props.variant === 'outlined' ? theme.palette.primary.main : '#ADB8CC'
    }
  }))(MaterialChip)

  return <StyledChip {...props} />
}

Chip.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['regular, outlined'])
}

export default Chip
