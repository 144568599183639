import { createReducer } from '@reduxjs/toolkit'
import { GET_SKILLS, GET_SKILLS_SUCCESSFUL, GET_SKILLS_FAILURE } from './types'

const initialState = {
  items: {},
  isFetching: false,
  isError: false,
  errorMessage: ''
}

const skillsReducer = createReducer(initialState, {
  [GET_SKILLS]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [GET_SKILLS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: action.payload,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [GET_SKILLS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: {},
    isError: true,
    errorMessage: action.payload
  })
})

export default skillsReducer
