import { all, call, takeLatest, put } from 'redux-saga/effects'
import { jsonToFormdata } from '../../utils/mappers'
import { showAlertMessage } from '../alert/actions'
import moment from 'moment'
import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'

import * as actions from './actions'
import * as services from './services'

export function * createUtilRequest ({ payload }) {
  try {
    const fd = new FormData()
    const isIndividual = payload.isIndividual
    const hasDestinationSchool =
      payload.school_id && !payload.classroom_id && !payload.grade_id
    fd.append('data[type]', 'utilities')

    if (payload.utility_type === 'bulletins') {
      payload.classroom_id &&
        fd.append('data[attributes][classroom_id]', payload.classroom_id)
      payload.grade_id &&
        fd.append('data[attributes][grade_id]', payload.grade_id)
      if (!isIndividual) {
        payload.files &&
          payload.files.forEach((file, index) => {
            fd.append(`data[attributes][files][][file]`, file.file)
            fd.append(`data[attributes][files][][child_id]`, file.id)
          })
      } else {
        payload.files &&
          (payload.files.forEach(file => {
            fd.append(
              'data[attributes][files_attributes][][utility_file]',
              file.file
            )
            fd.append(
              'data[attributes][destinations_attributes][][child_id]',
              file.id
            )
          }),
          fd.append(
            'data[attributes]scheduled_at',
            moment(new Date()).format('YYYY/MM/DD HH:mm')
          ),
          fd.append('data[attributes]title', payload.title))
        delete payload.title
      }
    } else {
      payload.files &&
        payload.files.forEach(file => {
          fd.append('data[attributes][files_attributes][][utility_file]', file)
        })
      payload.classroom_id &&
        payload.classroom_id.forEach(classroom => {
          fd.append(
            'data[attributes][destinations_attributes][][classroom_id]',
            classroom
          )
          payload.destinationType &&
            classroom &&
            fd.append(
              'data[attributes][destinations_attributes][][destination_type]',
              payload.destinationType
            )
        })
    }

    hasDestinationSchool &&
      fd.append(
        'data[attributes][destinations_attributes][][school_id]',
        payload.school_id
      )
    payload.destinations &&
      payload.destinations.forEach(destination => {
        fd.append(
          'data[attributes][destinations_attributes][][user_id]',
          destination.value
        )
      })
    delete payload.files
    delete payload.destinations
    delete payload.destinationType
    delete payload.grade_id
    delete payload.classroom_id
    delete payload.isIndividual
    yield jsonToFormdata(fd, payload)
    let response
    if (payload.utility_type !== 'bulletins' || isIndividual) {
      response = yield call(services.createUtil, fd)
    } else {
      response = yield call(services.createBulletin, fd)
    }

    yield put(
      actions.createUtilsSuccessful({
        id: response.data.id,
        ...response.data.attributes
      })
    )
    const utilType = {
      menus: 'Cardápio',
      messages: 'Recado',
      links: 'Link',
      documents: 'Documento',
      docs_pdf: 'Documento PDf',
      bulletins: 'Boletim'
    }
    yield put(
      showAlertMessage({
        message: `${utilType[payload.utility_type]} enviado com sucesso!`,
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.createUtilsFailure(error))
  }
}

export function * fetchWrapperUtils ({ payload }) {
  try {
    const response = yield call(services.fetchUtils, payload)
    yield put(
      actions.getWrapperUtilsSuccessful({
        data: response.data,
        meta: response.meta
      })
    )
  } catch (error) {
    yield put(actions.getWrapperUtilsFailure(error))
  }
}

export function * fetchUtils ({ payload }) {
  try {
    const response = yield call(services.fetchUtils, payload)
    yield put(
      actions.getUtilsSuccessful({ data: response.data, meta: response.meta })
    )
  } catch (error) {
    yield put(actions.getUtilsFailure(error))
  }
}

export function * deleteUtilRequest ({ payload }) {
  try {
    yield call(services.deleteUtil, payload)
    yield put(actions.deleteUtilSuccessful(payload))
    yield put(
      showAlertMessage({
        message: 'Deletado com sucesso.',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.deleteUtilFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao deletar.',
        severity: 'error'
      })
    )
  }
}

export function * fetchUtilByIdRequest ({ payload }) {
  try {
    const response = yield call(services.fetchUtilById, payload)
    let destinationsData
    const utilDestination =
      response?.destinations?.length && response.destinations[0]
    const utilDestinationType =
      utilDestination && utilDestination.destination_type
    if (response.destinations?.length > 0 && utilDestination.user_id) {
      destinationsData = {
        items: response.destinations.map(destination => ({
          id: destination.user.id,
          name: destination.user.name
        })),
        type: utilDestinationType,
        pagination: {
          total: 1,
          pageCount: 1,
          page: 1
        }
      }
      delete response.destinations
      delete response.classroom
    } else if (response.classroom_id) {
      let items
      switch (utilDestinationType) {
        case 'teachers':
          items = response.classroom?.teachers
          break
        case 'parents':
          items = response.classroom?.children
            .map(child => child.parents)
            .flat()
          break
        case 'students':
          items = response.classroom?.children
          break
        default:
          items = response.classroom[0]?.teachers
            .concat(
              response.classroom[0]?.children,
              response.classroom[0]?.children.map(child => child?.parents)
            )
            .flat()
          break
      }
      destinationsData = {
        items: items,
        type: utilDestinationType,
        pagination: {
          total: 1,
          pageCount: 1,
          page: 1
        }
      }
    } else if (
      !response.classroom_id &&
      response.school_id &&
      !response.grade_id
    ) {
      let itemsPayload
      if (utilDestinationType === 'teachers') {
        const destinationSchool = yield call(services.fetchTeachersSchool, {
          params: { 'q[classroom_school_id_eq]': response.school_id },
          page: 1
        })
        itemsPayload = {
          items: destinationSchool.data.map(destination => destination.teacher),
          meta: destinationSchool.meta
        }
      } else if (utilDestinationType === 'students') {
        const destinationSchool = yield call(services.fetchChildrenSchool, {
          params: { 'q[classrooms_school_id_eq]': response.school_id },
          page: 1
        })
        itemsPayload = {
          items: destinationSchool.data,
          meta: destinationSchool.meta
        }
      } else {
        const destinationSchool = yield call(services.fetchDestinationSchool, {
          params: { 'q[school_id_eq]': utilDestination.school_id },
          page: 1
        })
        const destinationItems = yield destinationSchool.included
          .map(item => {
            if (item.type === 'users') {
              return { id: item.id, name: item.attributes.name }
            } else if (item.type === 'children' && item.attributes.user_id) {
              return {
                id: String(item.attributes.user_id),
                name: item.attributes.name
              }
            }
          })
          .filter(Boolean)
        itemsPayload = {
          items: destinationItems,
          meta: {
            total_count: destinationItems.length,
            page_count: destinationSchool.meta.page_count
          }
        }
      }

      destinationsData = {
        items: itemsPayload.items,
        type: utilDestinationType,
        pagination: {
          total: itemsPayload.meta.total_count,
          pageCount: itemsPayload.meta.page_count,
          page: 1
        }
      }
    }
    const successfulPayload = {
      data: response,
      destinations: destinationsData
    }
    yield put(actions.getUtilByIdSuccessful(successfulPayload))
  } catch (error) {
    yield put(actions.getUtilByIdFailure(error))
  }
}

export function * editUtilWrapperRequest ({ payload }) {
  try {
    const fd = new FormData()
    fd.append('data[type]', 'utilities')
    fd.append('data[id]', payload.id)
    delete payload.data?.files
    delete payload.data?.destinationType
    delete payload.data?.previousFiles
    delete payload.data?.school_id
    // no momento estou enviando somente as datas e título
    yield jsonToFormdata(fd, payload.data)

    const response = yield call(services.editWrapper, {
      id: payload.id,
      data: fd
    })
    yield put(actions.editWrapperSuccessful(response))
    yield put(
      showAlertMessage({
        message: `Informações dos boletins atualizadas com sucesso!`,
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.editWrapperFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha na atualização.',
        severity: 'error'
      })
    )
  }
}

export function * editUtilRequest ({ payload }) {
  try {
    const fd = new FormData()
    fd.append('data[type]', 'utilities')
    fd.append('data[id]', payload.id)
    if (payload.data.files && payload.data.files.length > 0) {
      yield all(
        payload.data.previousFiles.map(file => {
          return call(services.deleteUtilityFile, {
            id: file.id
          })
        })
      )
    }
    payload.data.files &&
      payload.data.files.forEach(file => {
        fd.append('data[attributes][files_attributes][][utility_file]', file)
      })
    yield payload.data.destinations &&
      payload.data.destinations.forEach(destination => {
        fd.append(
          'data[attributes][destinations_attributes][][classroom_id]',
          payload.data.classroom_id
        )
        fd.append(
          'data[attributes][destinations_attributes][][user_id]',
          destination.value
        )
      })
    delete payload.data.files
    delete payload.data.destinations
    delete payload.data.destinationType
    delete payload.data.previousFiles
    yield jsonToFormdata(fd, payload.data)
    const response = yield call(services.editUtil, {
      id: payload.id,
      data: fd
    })
    yield put(
      actions.editUtilSuccessful({
        id: response.data.id,
        ...response.data.attributes
      })
    )
    const utilType = {
      menus: 'Cardápio',
      messages: 'Recado',
      links: 'Link',
      documents: 'Documento',
      docs_pdf: 'Documento PDf',
      bulletins: 'Boletim'
    }
    yield put(
      showAlertMessage({
        message: `${
          utilType[payload.data.utility_type]
        } atualizado com sucesso!`,
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.editUtilFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha na atualização',
        severity: 'error'
      })
    )
  }
}

export function * fetchUtilForAllDestinationsRSPARequest (action) {
  try {
    const { payload: { endpoint, params } } = action
    const response = yield call(services.fetchAllDestinationsUtils, {
      params,
      endpoint
    })
    resolvePromiseAction(action, response)
  } catch (err) {
    rejectPromiseAction(action, err)
  }
}

export function * fetchUtilDestinationsRSPARequest (action) {
  try {
    const { payload: { endpoint, params } } = action
    const response = yield call(services.fetchDestinationsUtils, {
      params,
      endpoint
    })
    resolvePromiseAction(action, response)
  } catch (err) {
    rejectPromiseAction(action, err)
  }
}

export function * watchFetchUtils () {
  yield takeLatest(actions.createUtils, createUtilRequest)
  yield takeLatest(actions.getUtils, fetchUtils)
  yield takeLatest(actions.deleteUtil, deleteUtilRequest)
  yield takeLatest(actions.getUtilById, fetchUtilByIdRequest)
  yield takeLatest(actions.editUtil, editUtilRequest)
  yield takeLatest(
    actions.getUtilForAllDestinationsRSPA.request,
    fetchUtilForAllDestinationsRSPARequest
  )
  yield takeLatest(
    actions.getUtilDestinationsRSPA.request,
    fetchUtilDestinationsRSPARequest
  )
  yield takeLatest(actions.getWrapperUtils, fetchWrapperUtils)
  yield takeLatest(actions.editWrapper, editUtilWrapperRequest)
}

export default function * utilsSagas () {
  yield all([watchFetchUtils()])
}
