import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import { getContentById } from '../store/contents/actions'
import ContentView from '../components/view/ContentView'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: 0,
    maxWidth: '1110px',
    maxHeight: '100%'
  }
}))

const mapDispatchToProps = {
  getContentById
}

const mapStateToProps = ({ contents }) => {
  return {
    currentContent: contents.currentItem?.item || {},
    contentIncluded: contents.currentItem?.included
  }
}

const ContentPage = ({
  match,
  getContentById,
  currentContent,
  contentIncluded
}) => {
  const classes = useStyles()
  const contentId = match.params?.id

  useEffect(() => {
    getContentById({ ids: contentId })
  }, [])

  if (currentContent.isFetching) return <CircularProgress color='secondary' />

  return (
    <div className={classes.root}>
      <ContentView
        currentContent={currentContent}
        contentIncluded={contentIncluded}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPage)
