import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import { SIGN_IN as signIn } from '../store/auth/actions'
import { makeStyles } from '@material-ui/core/styles'

import LoginComponent from '../components/form/LoginForm'
import bgImage from '../assets/bg.png'

const useStyles = makeStyles(theme => ({
  container: {
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    boxSizing: 'border-box',
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    height: '100%',
    background: '#E8E8E8'
  }
}))

export const LoginPage = ({ signIn }) => {
  const location = useLocation()
  const history = useHistory()
  React.useEffect(() => {
    const queryString = 'token'
    const queryProjectId = 'project_id'
    const queryparams = new URLSearchParams(location.search)
    if (queryparams.has(queryString)) {
      signIn({
        ...(queryparams.has(queryProjectId)
          ? { project_id: queryparams.get(queryProjectId) }
          : {}),
        token: queryparams.get(queryString),
        history
      })
    }
  }, [])
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <LoginComponent onSubmit={signIn} />
    </div>
  )
}

LoginPage.propTypes = {
  signIn: PropTypes.func.isRequired
}

LoginPage.defaultProps = {
  loginValues: undefined
}

const mapStateToProps = () => ({})

const mapDispatchToPros = dispatch =>
  bindActionCreators(
    {
      signIn
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToPros)(LoginPage)
