import { createAction } from '@reduxjs/toolkit'
import { createPromiseAction } from 'redux-saga-promise-actions'
import * as types from './types'

// Communications
export const getCommunications = createAction(types.GET_COMMUNICATIONS)
export const getCommunicationsSuccessful = createAction(
  types.GET_COMMUNICATIONS_SUCCESSFUL
)
export const getCommunicationsFailure = createAction(
  types.GET_COMMUNICATIONS_FAILURE
)

export const getCommunicationsById = createAction(types.GET_COMMUNICATION_BY_ID)
export const getCommunicationsByIdSuccessful = createAction(
  types.GET_COMMUNICATION_BY_ID_SUCCESSFUL
)
export const getCommunicationsByIdFailure = createAction(
  types.GET_COMMUNICATION_BY_ID_FAILURE
)

export const createCommunication = createAction(types.CREATE_COMMUNICATION)
export const createCommunicationSuccessful = createAction(
  types.CREATE_COMMUNICATION_SUCCESSFUL
)
export const createCommunicationFailure = createAction(
  types.CREATE_COMMUNICATION_FAILURE
)

export const updateCommunication = createAction(types.UPDATE_COMMUNICATION)
export const updateCommunicationSuccessful = createAction(
  types.UPDATE_COMMUNICATION_SUCCESSFUL
)
export const updateCommunicationFailure = createAction(
  types.UPDATE_COMMUNICATION_FAILURE
)

// Messages
export const getMessages = createAction(types.GET_MESSAGES)
export const getMessagesSuccessful = createAction(types.GET_MESSAGES_SUCCESSFUL)
export const getMessagesFailure = createAction(types.GET_MESSAGES_FAILURE)

export const getMessageById = createAction(types.GET_MESSAGE_BY_ID)
export const getMessageByIdSuccessful = createAction(
  types.GET_MESSAGE_BY_ID_SUCCESSFUL
)
export const getMessageByIdFailure = createAction(
  types.GET_MESSAGE_BY_ID_FAILURE
)

export const createMessages = createAction(types.CREATE_MESSAGES)
export const createMessagesSuccessful = createAction(
  types.CREATE_MESSAGES_SUCCESSFUL
)
export const createMessagesFailure = createAction(types.CREATE_MESSAGES_FAILURE)

export const updateMessage = createAction(types.UPDATE_MESSAGE)
export const updateMessageSuccessful = createAction(
  types.UPDATE_MESSAGE_SUCCESSFUL
)
export const updateMessageFailure = createAction(types.UPDATE_MESSAGE_FAILURE)

export const respondMessages = createAction(types.RESPOND_MESSAGES)
export const respondMessagesSuccessful = createAction(
  types.RESPOND_MESSAGES_SUCCESSFUL
)
export const respondMessagesFailure = createAction(
  types.RESPOND_MESSAGES_FAILURE
)

export const cleanSuccess = createAction(types.CLEAN_SUCCESS)

export const getPendingCommunicationsRSPA = createPromiseAction(
  'rspa: GET_PENDING_COMMUNICATIONS'
)()
export const getDestinationsByCommunicationRSPA = createPromiseAction(
  'rspa: GET_MESSAGES_DESTINATIONS_BY_COMMUNICATION'
)()
