// import { select } from 'redux-saga/effects'
import { all, call, takeLatest, put } from 'redux-saga/effects'
// import { jsonToFormdata } from '../../utils/mappers'
import { showAlertMessage } from '../alert/actions'
import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'

import * as actions from './actions'
import * as services from './services'

export function * deleteMaterialRequest ({ payload }) {
  try {
    yield call(services.deleteMaterial, payload)
    yield put(actions.deleteMaterialSuccessful(payload))
    yield put(
      showAlertMessage({
        message: 'Material deletado com sucesso.',
        severity: 'success'
      })
    )
  } catch (err) {
    yield put(actions.deleteMaterialFailure(err))
    yield put(
      showAlertMessage({
        message: 'Falha ao deletar material.',
        severity: 'error'
      })
    )
  }
}

export function * fetchMaterials ({ payload }) {
  try {
    const response = yield call(services.fetchMaterials, payload)
    yield put(
      actions.getMaterialsSuccessful({
        data: response.data,
        meta: { ...response.meta }
      })
    )
  } catch (err) {
    yield put(actions.getMaterialsFailure(err))
    // yield put(showAlertMessage('error', 'Erro ao buscar os materiais'))
  }
}

export function * getMaterialByIdRequest ({ payload }) {
  try {
    const response = yield call(services.fetchMaterialById, payload)
    yield put(actions.getMaterialByIdSuccessful({ data: response }))
  } catch (err) {
    console.error(err)
    yield put(actions.getMaterialByIdFailure(err))
  }
}

export function * editMaterialRequest ({ payload }) {
  try {
    const {
      // eslint-disable-next-line camelcase
      subject_id,
      // eslint-disable-next-line camelcase
      child_id,
      // eslint-disable-next-line camelcase
      classroom_id,
      description,
      files,
      // eslint-disable-next-line camelcase
      scheduled_at,
      // eslint-disable-next-line camelcase
      teacher_id,
      title,
      links,
      id,
      destinationsToDelete,
      filesToDelete,
      hasAlreadyFiles
    } = payload
    const fd = new FormData()
    fd.append('data[type]', 'materials')
    fd.append('data[id]', id)
    title && fd.append('data[attributes][title]', title)
    description && fd.append('data[attributes][description]', description)
    // eslint-disable-next-line camelcase
    scheduled_at && fd.append('data[attributes][scheduled_at]', scheduled_at)
    // eslint-disable-next-line camelcase
    teacher_id &&
      // eslint-disable-next-line camelcase
      fd.append('data[attributes][user_id]', teacher_id.value || teacher_id)
    // eslint-disable-next-line camelcase
    subject_id &&
      // eslint-disable-next-line camelcase
      fd.append('data[attributes][subject_id]', subject_id.value || subject_id)
    // eslint-disable-next-line camelcase
    classroom_id &&
      fd.append(
        'data[attributes][classroom_id]',
        // eslint-disable-next-line camelcase
        classroom_id.value || classroom_id
      )
    if (child_id?.length > 0) {
      child_id.forEach(child => {
        fd.append(
          'data[attributes][destinations_attributes][][user_id]',
          child.value
        )
      })
    } else {
      fd.append(
        'data[attributes][destinations_attributes][][classroom_id]',
        // eslint-disable-next-line camelcase
        classroom_id.value || classroom_id
      )
    }
    files?.length > 0 &&
      files.forEach(file => {
        fd.append('data[attributes][files_attributes][][file]', file)
      })
    if (hasAlreadyFiles && (files?.length > 0 || links?.length > 0)) {
      fd.append('data[attributes][material_type]', 'files')
    }
    const response = yield call(services.editMaterial, fd)
    if (response.data && links?.length > 0) {
      yield all(
        links.map(link => {
          const data = {
            data: {
              type: 'material_files',
              attributes: {
                material_id: response.data.id,
                link_url: link
              }
            }
          }
          return call(services.createMaterialFile, data)
        })
      )
    }
    if (destinationsToDelete?.length > 0) {
      yield all(
        destinationsToDelete.map(destination => {
          return call(services.deleteMaterialDestination, destination.id)
        })
      )
    }
    if (filesToDelete?.length > 0) {
      yield all(
        filesToDelete.map(file => {
          return call(services.deleteMaterialFile, file)
        })
      )
    }

    yield put(
      actions.editMaterialSuccessful({
        id: response.data.id,
        ...response.data.attributes
      })
    )
  } catch (err) {
    console.error(err)
    yield put(actions.editMaterialFailure(err))
  }
}

export function * createMaterialsRequest ({ payload }) {
  try {
    const {
      // eslint-disable-next-line camelcase
      school_id,
      // eslint-disable-next-line camelcase
      subject_id,
      // eslint-disable-next-line camelcase
      child_id,
      // eslint-disable-next-line camelcase
      classroom_id,
      description,
      files,
      // eslint-disable-next-line camelcase
      scheduled_at,
      // eslint-disable-next-line camelcase
      teacher_id,
      title,
      links
    } = payload
    const fd = new FormData()
    fd.append('data[type]', 'materials')
    title && fd.append('data[attributes][title]', title)
    description && fd.append('data[attributes][description]', description)
    // eslint-disable-next-line camelcase
    scheduled_at && fd.append('data[attributes][scheduled_at]', scheduled_at)
    // eslint-disable-next-line camelcase
    teacher_id && fd.append('data[attributes][user_id]', teacher_id)
    // eslint-disable-next-line camelcase
    subject_id && fd.append('data[attributes][subject_id]', subject_id)
    // eslint-disable-next-line camelcase
    school_id && fd.append('data[attributes][school_id]', school_id)
    // eslint-disable-next-line camelcase
    classroom_id && fd.append('data[attributes][classroom_id]', classroom_id)
    if (child_id?.length > 0) {
      child_id.forEach(child => {
        fd.append(
          'data[attributes][destinations_attributes][][child_id]',
          child.value
        )
      })
    } else {
      fd.append(
        'data[attributes][destinations_attributes][][classroom_id]',
        classroom_id
      )
    }
    files?.length > 0 &&
      files.forEach(file => {
        fd.append('data[attributes][files_attributes][][file]', file)
      })
    if (files?.length > 1 || (files?.length > 0 && links?.length > 0)) {
      fd.append('data[attributes][material_type]', 'files')
    } else if (files?.length === 1 && links?.length < 1) {
      if (files[0].type.includes('audio')) {
        fd.append('data[attributes][material_type]', 'audios')
      } else if (files[0].type.includes('video')) {
        fd.append('data[attributes][material_type]', 'videos')
      } else {
        fd.append('data[attributes][material_type]', 'documents')
      }
    } else if (links?.length > 0) {
      fd.append('data[attributes][material_type]', 'links')
    } else {
      fd.append('data[attributes][material_type]', 'documents')
    }
    const response = yield call(services.createMaterial, fd)
    if (response.data && links?.length > 0) {
      yield all(
        links.map(link => {
          const data = {
            data: {
              type: 'material_files',
              attributes: {
                material_id: response.data.id,
                link_url: link
              }
            }
          }
          return call(services.createMaterialFile, data)
        })
      )
    }
    yield put(
      actions.createMaterialsSuccessful({
        id: response.data.id,
        ...response.data.attributes
      })
    )
    yield put(
      showAlertMessage({
        message: `Material criado com sucesso!`,
        severity: 'success'
      })
    )
  } catch (err) {
    // console.error(err)
    yield put(actions.createMaterialsFailure(err))
  }
}

export function * exportMaterialReportRSPARequest (action) {
  try {
    const { payload: { id } } = action
    const response = yield call(services.exportMaterialReport, id)
    yield put(
      showAlertMessage({
        message: 'Dados exportados com sucesso!',
        severity: 'success'
      })
    )
    resolvePromiseAction(action, response)
  } catch (error) {
    console.error(error)
    yield put(
      showAlertMessage({
        message: 'Falha ao exportar os dados.',
        severity: 'error'
      })
    )
    rejectPromiseAction(action, error)
  }
}

export function * watchFetchEvents () {
  yield takeLatest(actions.getMaterials, fetchMaterials)
  yield takeLatest(actions.createMaterials, createMaterialsRequest)
  yield takeLatest(actions.getMaterialById, getMaterialByIdRequest)
  yield takeLatest(actions.deleteMaterial, deleteMaterialRequest)
  yield takeLatest(actions.editMaterial, editMaterialRequest)
  yield takeLatest(
    actions.exportMaterialReportRSPA.request,
    exportMaterialReportRSPARequest
  )
}

export default function * eventsSagas () {
  yield all([watchFetchEvents()])
}
