import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Form from '../components/form/CreateModuleForm'
import Label from '../components/label/Label'

import {
  createModule,
  editModule,
  getModuleById
} from '../store/modules/actions'
import {
  getThemes,
  editTheme,
  getThemeById,
  clearCurrentTheme
} from '../store/themes/actions'
import { CircularProgress } from '@material-ui/core'

const mapDispatchToProps = {
  createModule,
  getThemes,
  editTheme,
  editModule,
  getThemeById,
  getModuleById,
  clearCurrentTheme
}

const mapStateToProps = ({ modules, themes, school }) => {
  return {
    modules: modules,
    themes: themes,
    currentModule: modules?.currentItem,
    currentTheme: themes?.currentItem,
    school: school.currentSchool,
    schoolIsFetching: school.isFetching
  }
}

const CreateModulePage = ({
  createModule,
  getThemes,
  editModule,
  editTheme,
  themes,
  currentModule,
  currentTheme,
  modules,
  school,
  schoolIsFetching,
  getModuleById,
  getThemeById,
  clearCurrentTheme,
  match
}) => {
  const [isEditing, setIsEditing] = React.useState(false)

  useEffect(() => {
    if (match.params.id) {
      setIsEditing(true)
      getModuleById({ ids: match.params.id }) // editing
    }
  }, [])

  if (!isEditing) {
    return (
      <div>
        <div
          style={{
            padding: '0 1rem'
          }}
        >
          <Label>Criar Módulo</Label>
        </div>
        <Form
          themes={themes}
          createModule={createModule}
          editModule={editModule}
          editTheme={editTheme}
          currentModule={currentModule}
          currentTheme={currentTheme}
          getThemes={getThemes}
          modules={modules}
          school={school?.school}
          getModuleById={getModuleById}
          getThemeById={getThemeById}
          clearCurrentTheme={clearCurrentTheme}
        />
      </div>
    )
  } else {
    return (
      <div>
        <div
          style={{
            padding: '0 1rem'
          }}
        >
          <Label>Editar Módulo</Label>
        </div>
        {currentModule.isFetching ? (
          <CircularProgress color='secondary' />
        ) : (
          <Form
            themes={themes}
            createModule={createModule}
            editModule={editModule}
            editTheme={editTheme}
            currentModule={currentModule}
            currentTheme={currentTheme}
            getThemes={getThemes}
            modules={modules}
            school={school?.school}
            getModuleById={getModuleById}
            getThemeById={getThemeById}
            clearCurrentTheme={clearCurrentTheme}
            initialValues={
              currentModule &&
              currentModule.item &&
              currentModule.item.attributes
            }
            isEditing
          />
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateModulePage)
