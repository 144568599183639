import React from 'react'
import SVG from 'react-inlinesvg'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    width: props => props.width || 24,
    height: props => props.height || 24,
    minWidth: props => props.minWidth || props.width || 24,
    minHeight: props => props.minHeight || props.height || 24,
    '& path': {
      stroke: props => props.color
    }
  }
})

const StyledSVG = ({
  onClick,
  color,
  width,
  style,
  height,
  src,
  alt,
  className
}) => {
  const classes = useStyles({ color, width, height })
  return (
    <SVG
      style={style}
      className={`${classes.root} ${className}`}
      src={src}
      alt={alt}
    >
      StyledSVG
    </SVG>
  )
}

export default StyledSVG
