import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

const useStyles = makeStyles(theme => ({
  photoSection: {
    position: 'relative',
    border: '1px dashed #ADB8CC',
    borderRadius: '7px',
    width: 'fit-content',
    padding: '25px'
  },
  rmvBtn: {
    position: 'absolute',
    zIndex: 1,
    right: '-1px',
    top: '-1px',
    color: 'red',
    margin: '5px'
  },
  img: {
    maxWidth: '210px',
    maxHeight: '210px',
    position: 'relative'
  }
}))

const ListImg = ({ handleDeleteImg, item }) => {
  const classes = useStyles()
  if (typeof item === 'object' && item !== null) {
    // in post form
    return (
      <div className={classes.photoSection}>
        {handleDeleteImg && (
          <HighlightOffIcon
            color='secondary'
            className={classes.rmvBtn}
            onClick={() => handleDeleteImg()}
            style={{
              cursor: 'pointer'
            }}
          />
        )}
        <img
          className={classes.img}
          src={URL.createObjectURL(item)}
          alt={item.name}
        />
      </div>
    )
  } else {
    if (item === 'delete') {
      return <></>
    }
    // in put form
    return (
      <div className={classes.photoSection}>
        {handleDeleteImg && (
          <HighlightOffIcon
            color='secondary'
            className={classes.rmvBtn}
            onClick={() => handleDeleteImg(true)}
            style={{
              cursor: 'pointer'
            }}
          />
        )}
        <img className={classes.img} src={item} alt={'avatarImg'} />
      </div>
    )
  }
}

export default ListImg
