import React from 'react'

import { useQuery } from '../../utils/helpers'
import AnnouncementsForm from '../form/AnnouncementsForm'
import { Paper, makeStyles } from '@material-ui/core'
import AnnouncementView from './AnnouncementView'
import SearchAnnouncementsForm from '../form/SearchAnnouncementsForm'

const useStyles = makeStyles(theme => ({
  header: {
    marginLeft: '1.3vw',
    '& h1': {
      color: '#4D5E80',
      marginBottom: 0,
      fontSize: 32,
      [theme.breakpoints.down('426')]: {
        fontSize: 22
      }
    },
    '& p': {
      margin: '0px 0px 25px 0px',
      fontSize: 16,
      [theme.breakpoints.down('426')]: {
        fontSize: 14
      }
    }
  }
}))

const AnnouncementsView = ({ title, eventType, isAdmin }) => {
  const query = useQuery()
  const classes = useStyles()
  return (
    <>
      {(query.get('name') === null || query.get('name') === 'list') && (
        <Paper style={{ borderRadius: 8, padding: 32 }} elevation={0}>
          <div>
            <SearchAnnouncementsForm
              eventType={query.get('event') || eventType}
            />
          </div>
        </Paper>
      )}
      {query.get('name') === 'new' && (
        <Paper style={{ borderRadius: 8, padding: 32 }} elevation={0}>
          <div>
            <AnnouncementsForm eventType={query.get('event') || eventType} />
          </div>
        </Paper>
      )}
      {query.get('name') === 'edit' && (
        <Paper style={{ borderRadius: 8, padding: 32 }} elevation={0}>
          <div>
            <AnnouncementsForm
              eventType={query.get('event') || eventType}
              isEdit
              eventId={query.get('id')}
            />
          </div>
        </Paper>
      )}
      {query.get('name') === 'view' && (
        <div>
          <div className={classes.header}>
            <h1>Detalhes do comunicado</h1>
            <p> Visualize todos os detalhes do comunicado enviado</p>
          </div>
          <AnnouncementView
            eventType={query.get('event') || eventType}
            eventId={query.get('id')}
          />
        </div>
      )}
      {query.get('name') === 'review' &&
        isAdmin && (
          <Paper style={{ borderRadius: 8, padding: 32 }} elevation={0}>
            <div>
              <AnnouncementsForm
                eventType={query.get('event') || eventType}
                isEdit
                eventId={query.get('id')}
                isReview
              />
            </div>
          </Paper>
        )}
    </>
  )
}

export default AnnouncementsView
