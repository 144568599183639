import { createAction } from '@reduxjs/toolkit'
import { createPromiseAction } from 'redux-saga-promise-actions'
import * as types from './types'

export const getCoordinators = createAction(types.GET_COORDINATOR)
export const getCoordinatorsSuccessful = createAction(
  types.GET_COORDINATOR_SUCCESSFUL
)
export const getCoordinatorsFailure = createAction(
  types.GET_COORDINATOR_FAILURE
)

export const editCoordinator = createAction(types.EDIT_COORDINATOR)
export const editCoordinatorSuccessful = createAction(
  types.EDIT_COORDINATOR_SUCCESSFUL
)
export const editCoordinatorFailure = createAction(
  types.EDIT_COORDINATOR_FAILURE
)

export const getCoordinatorById = createAction(types.GET_COORDINATOR_BY_ID)
export const getCoordinatorByIdSuccessful = createAction(
  types.GET_COORDINATOR_BY_ID_SUCCESSFUL
)
export const getCoordinatorByIdFailure = createAction(
  types.GET_COORDINATOR_BY_ID_FAILURE
)
export const createCoordinator = createAction(types.CREATE_COORDINATOR)
export const createCoordinatorSuccessful = createAction(
  types.CREATE_COORDINATOR_SUCCESSFUL
)
export const createCoordinatorFailure = createAction(
  types.CREATE_COORDINATOR_FAILURE
)

export const getDeletedCoordinators = createAction(
  types.GET_DELETED_COORDINATOR
)
export const getDeletedCoordinatorsSuccessful = createAction(
  types.GET_DELETED_COORDINATOR_SUCCESSFUL
)
export const getDeletedCoordinatorsFailure = createAction(
  types.GET_DELETED_COORDINATOR_FAILURE
)

export const deleteCoordinatorRequestRSPA = createPromiseAction(
  'rspa: DELETE_COORDINATOR'
)()

export const restoreCoordinatorsRSPA = createPromiseAction(
  'rspa: RESTORE_COORDINATORS_RSPA'
)()
