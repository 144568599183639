export const GET_COORDINATOR = 'coordinators/GET_COORDINATOR'
export const GET_COORDINATOR_SUCCESSFUL =
  'coordinators/GET_COORDINATOR_SUCCESSFUL'
export const GET_COORDINATOR_FAILURE = 'coordinators/GET_COORDINATOR_FAILURE'

export const EDIT_COORDINATOR = 'coordinators/EDIT_COORDINATOR'
export const EDIT_COORDINATOR_SUCCESSFUL =
  'coordinators/EDIT_COORDINATOR_SUCCESSFUL'
export const EDIT_COORDINATOR_FAILURE = 'coordinators/EDIT_COORDINATOR_FAILURE'

export const GET_COORDINATOR_BY_ID = 'coordinators/GET_COORDINATOR_BY_ID'
export const GET_COORDINATOR_BY_ID_SUCCESSFUL =
  'coordinators/GET_COORDINATOR_BY_ID_SUCCESSFUL'
export const GET_COORDINATOR_BY_ID_FAILURE =
  'coordinators/GET_COORDINATOR_BY_ID_FAILURE'

export const CREATE_COORDINATOR = 'coordinators/CREATE_COORDINATOR'
export const CREATE_COORDINATOR_SUCCESSFUL =
  'coordinators/CREATE_COORDINATOR_SUCCESSFUL'
export const CREATE_COORDINATOR_FAILURE =
  'coordinators/CREATE_COORDINATOR_FAILURE'

export const GET_DELETED_COORDINATOR = 'coordinators/GET_DELETED_COORDINATOR'
export const GET_DELETED_COORDINATOR_SUCCESSFUL =
  'coordinators/GET_DELETED_COORDINATOR_SUCCESSFUL'
export const GET_DELETED_COORDINATOR_FAILURE =
  'coordinators/GET_DELETED_COORDINATOR_FAILURE'
