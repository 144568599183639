import { all, call, takeLatest, put } from 'redux-saga/effects'

import * as actions from './actions'
import * as services from './services'

export function * fetchOccupations ({ payload }) {
  try {
    const response = yield call(services.fetchOccupationsBySegmentId, payload)
    yield put(actions.getOccupationsSuccessful(response.data))
  } catch (error) {
    yield put(actions.getOccupationsFailure(error))
  }
}

export function * watchFetchOccupations () {
  yield takeLatest(actions.getOccupations, fetchOccupations)
}

export default function * occupationsSagas () {
  yield all([watchFetchOccupations()])
}
