import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import fileSVG from '../../assets/icons/material-file.svg'
import Button from '../button/Button'
import StyledSVG from '../shared/StyledSVG'
import linkSVG from '../../assets/icons/material-link.svg'
import MaterialViewTemplate from '../shared/MaterialViewTemplate'
import { cleanMaterialSuccessful } from '../../store/materials/actions'
import { useHistory } from 'react-router-dom'
import { downloadZip } from '../../utils/helpers'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(theme => ({
  root: {
    width: '98%',
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    margin: 'auto',
    height: '95%',
    padding: '32px',
    marginBottom: '80px'
  },
  horizontalBar: {
    marginTop: 24,
    marginBottom: 27,
    width: '96%',
    height: 1,
    border: '1px solid #ADB8CC'
  },
  file: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
    width: '25%',
    height: 70,
    border: '1px solid #D7DCE480',
    borderRadius: 16,
    minWidth: '250px',
    overflowX: 'hidden',
    '& .aroundDiv': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 56,
      backgroundColor: '#FDE6E8',
      height: 56,
      width: 56,
      minWidth: 56,
      minHeight: 56
    },
    '& p': {
      fontWeight: 600,
      fontSize: 14,
      color: '#555555',
      margin: 0
    }
  },
  files: {
    display: 'flex',
    gap: 8,
    flexWrap: 'wrap',
    marginBottom: 30
  },
  bottomBtns: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    '& button': {
      // width: 167
    }
  },
  goBackBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  reportBtn: {
    width: 146,
    border: '1px solid #F4A87C',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#F4A87C',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #F4A87C'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  downloadAll: {
    backgroundColor: '#F4A87C',
    '& span': {
      textTransform: 'initial'
    },
    width: 200,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#F4A87C'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const MaterialView = ({
  files,
  materialId,
  currentMaterial,
  cleanMaterialSuccessful,
  userOccupations
}) => {
  const history = useHistory()
  const materialFiles = currentMaterial?.files?.filter(item => !item.link_url)

  React.useEffect(() => {
    return () => {
      cleanMaterialSuccessful()
    }
  }, [])
  const isFolder = currentMaterial?.files?.length > 1
  const classes = useStyles()
  const handleDownload = () => {
    const materialsFiltered = materialFiles?.filter(item => !!item.content_type)
    downloadZip(materialsFiltered, currentMaterial?.title)
  }
  return (
    <div className={classes.root}>
      <MaterialViewTemplate
        userOccupations={userOccupations}
        cleanMaterialSuccessful={cleanMaterialSuccessful}
        showEditButton
        currentMaterial={currentMaterial}
      />
      <div className={classes.horizontalBar} />
      <div className={classes.files}>
        {currentMaterial?.files?.length > 0 &&
          currentMaterial?.files.map(file => {
            const isLink = !!file.link_url
            const filename = isLink ? file.link_url : file.filename
            const redirectLink = isLink ? file.link_url : file.file_url
            const redirectLinkWithHttp =
              redirectLink.trim().slice(0, 4) === 'http'
                ? redirectLink
                : `https://${redirectLink}`
            return (
              <div
                onClick={() =>
                  redirectLinkWithHttp &&
                  window.open(redirectLinkWithHttp, '_blank')
                }
                key={file}
                className={classes.file}
              >
                <div className='aroundDiv'>
                  <StyledSVG src={isLink ? linkSVG : fileSVG} height={30} />
                </div>
                <Tooltip title={filename} aria-label='capa'>
                  <p>
                    {filename.length > 40
                      ? `${filename.slice(0, 40)}...`
                      : filename}
                  </p>
                </Tooltip>
              </div>
            )
          })}
      </div>
      <div className={classes.bottomBtns}>
        <Button
          type='button'
          variant='outlined'
          className={classes.goBackBtn}
          onClick={() => history.push('/materials')}
        >
          Voltar
        </Button>
        <Button
          type='button'
          variant='outlined'
          className={classes.reportBtn}
          onClick={() => {
            history.push(`/materials/${materialId}/report`)
          }}
        >
          Relatório
        </Button>
        {materialFiles?.length > 0 && (
          <Button
            type='button'
            className={classes.downloadAll}
            onClick={() => {
              handleDownload()
            }}
          >
            {isFolder ? 'Baixar todos' : 'Baixar'}
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    userOccupations: state.auth.currentOccupation
  }
}

const mapDispatchToProps = dispatch => ({
  cleanMaterialSuccessful: data => dispatch(cleanMaterialSuccessful(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MaterialView)
