import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const TextArea = ({
  input: { value, onBlur, onChange, ...restInput },
  placeholder,
  type,
  meta: { touched, error },
  characterCounter,
  defaultValue,
  touch,
  onChangeInput,
  componentStyle,
  ...rest
}) => {
  const [hover, setHover] = React.useState(false)
  const generalTheme = useTheme()

  const useStyles = makeStyles(theme => ({
    root: {
      marginBottom: 16
    },
    container: {
      display: 'flex',
      flexFlow: 'column'
    },
    textarea: {
      fontFamily: 'Source Sans Pro',
      marginBottom: '2px !important',
      border: props =>
        componentStyle?.hasBorder === false ? 'none' : props.borderColor,
      borderRadius: '8px !important',
      backgroundColor: componentStyle?.bgColor || 'unset',
      padding: '8px 12px',
      color: componentStyle?.textColor || theme.palette.text.primary
    },
    error: {
      color: theme.palette.danger.main,
      fontSize: '0.8rem'
    },
    count: {
      color: '#999',
      marginRight: '0.5rem',
      fontFamily: 'inherit'
    }
  }))
  const classes = useStyles({
    borderColor:
      touched && error
        ? `1px solid ${generalTheme.palette.danger.main} !important`
        : hover
          ? `1px solid ${generalTheme.palette.primary.light} !important`
          : '1px solid #ADB8CC !important'
  })
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <textarea
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          type={type}
          style={{ resize: 'none' }}
          placeholder={placeholder}
          className={classes.textarea}
          defaultValue={defaultValue || value}
          onChange={event => {
            onChangeInput ? onChangeInput(event) : onChange(event)
          }}
          onBlur={() => {
            onBlur(undefined)
          }}
          {...restInput}
          {...rest}
        />
      </div>
      {touched && error && <span className={classes.error}>{error}</span>}
      {characterCounter && (
        <span className={classes.count}>
          {' '}
          Caracteres: {defaultValue?.length || value.length}
        </span>
      )}
    </div>
  )
}

export default TextArea
