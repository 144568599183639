import React from 'react'

import SearchUsersHistoryForm from '../components/form/SearchUsersHistoryForm'

const HistoryAccessPage = ({ match }) => {
  return (
    <>
      <SearchUsersHistoryForm />
    </>
  )
}

export default HistoryAccessPage
