import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
// import CircularProgress from '@material-ui/core/CircularProgress'
import StyledSVG from './StyledSVG'
import docSVG from '../../assets/icons/doc.svg'
import audioSVG from '../../assets/icons/audio.svg'
import linkSVG from '../../assets/icons/link.svg'

import downloadSVG from '../../assets/icons/download-icon.svg'
import Modal from '../modal/Modal'

import { downloadImage } from '../../utils/helpers'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  imageGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: '8px',
    [theme.breakpoints.down('1500')]: {
      gridTemplateColumns: 'unset'
    }
  },
  mainImage: {
    '& img': {
      borderRadius: '8px'
    },
    marginRight: '8px',
    borderRadius: '8px',
    backgroundColor: '#9DD2DA',
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gridColumn: 'span 3',
    gridRow: 'span 3',
    [theme.breakpoints.down('1500')]: {
      marginBottom: 30
    }
  },
  secondaryImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      borderRadius: '8px'
    },
    gridColumn: 'span 1',
    gridRow: 'span 1',
    width: 'fit-content',
    height: 'fit-content'
  },
  link: {
    backgroundColor: 'rgba(218, 218, 218, 0.2)',
    borderRadius: 8,
    display: 'flex',
    gap: 8,
    wordBreak: 'break-all',
    padding: '5px 10px',
    width: 'fit-content',
    '& a': {
      color: theme.palette.text.secondary,
      textDecoration: 'none'
    }
  },
  restFiles: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  filesResponsive: {
    objectFit: 'cover',
    maxWidth: '500px',
    maxHeight: '500px',
    [theme.breakpoints.down('750')]: {
      maxWidth: '350px',
      maxHeight: '350px'
    },
    [theme.breakpoints.down('500')]: {
      maxWidth: '300px',
      maxHeight: '300px'
    },
    [theme.breakpoints.down('450')]: {
      maxWidth: '250px',
      maxHeight: '250px'
    },
    [theme.breakpoints.down('380')]: {
      maxWidth: '200px',
      maxHeight: '200px'
    }
  }
}))

const ShowEventFiles = ({ mainImage, images, otherFiles }) => {
  const [showContent, setShowContent] = React.useState({
    open: false,
    content: null,
    isVideo: false,
    fileToDownload: null
  })
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('426'))

  const classes = useStyles()

  return (
    <div>
      <div className={classes.imageGrid}>
        {mainImage && (
          <div
            className={classes.mainImage}
            style={{ gridColumn: 'span 2', gridRow: 'span 2' }}
          >
            <div
              style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <img
                onClick={() =>
                  setShowContent({
                    open: true,
                    content: mainImage,
                    isVideo: false,
                    fileToDownload: mainImage
                  })
                }
                src={mainImage}
                height={175}
                width={175}
                alt='Imagem Principal'
              />
              <p style={{ color: 'white', margin: 0 }}>Imagem de capa</p>
            </div>
          </div>
        )}

        {images?.length > 0 &&
          images?.map((image, index) => {
            return (
              <div key={index} className={classes.secondaryImage}>
                {image.content_type?.includes('image') ? (
                  <img
                    onClick={() =>
                      setShowContent({
                        open: true,
                        content: image.file_url,
                        isVideo: false,
                        fileToDownload: image
                      })
                    }
                    height={100}
                    width={100}
                    src={image.file_url}
                    alt={`Imagem Secundária ${index + 1}`}
                  />
                ) : (
                  <video
                    onClick={() =>
                      setShowContent({
                        open: true,
                        content: image.file_url,
                        isVideo: true
                      })
                    }
                    src={image.file_url}
                    style={{ borderRadius: 8 }}
                    height={100}
                    width={100}
                  />
                )}
              </div>
            )
          })}
      </div>
      <div className={classes.restFiles}>
        {otherFiles?.length > 0 &&
          otherFiles?.map((file, index) => {
            const isAudio = file?.content_type?.includes('audio')
            const isLink = file.content_type?.includes('link')
            // format file name
            let fileName = file?.filename
            const extencion = fileName?.split('.')[
              fileName?.split('.').length - 1
            ]
            fileName?.length > 20 &&
              matches &&
              (fileName = `${fileName.slice(0, 12)}...${extencion}`)

            const possibleTypes = {
              audio: (
                <div key={file.filename}>
                  <p className={classes.link}>
                    <img src={audioSVG} alt='video' width='21' height='21' />
                    <a target='_blank' href={file.file_url} rel='noreferrer'>
                      {fileName}
                    </a>
                  </p>
                </div>
              ),
              link: (
                <div style={{ height: 'fit-content' }} key={file.note}>
                  <p className={classes.link}>
                    <img src={linkSVG} alt='link' width='21' height='21' />
                    <a target='_blank' href={file.note} rel='noreferrer'>
                      {file.note}
                    </a>
                  </p>
                </div>
              ),
              default: (
                <div style={{ height: 'fit-content' }} key={file.filename}>
                  <p className={classes.link}>
                    <img src={docSVG} alt='document' width='21' height='21' />
                    <a target='_blank' href={file.file_url} rel='noreferrer'>
                      {fileName}
                    </a>
                  </p>
                </div>
              )
            }

            return (
              <div key={file.name}>
                <div>
                  {(isAudio && possibleTypes['audio']) ||
                    (isLink && possibleTypes['link']) ||
                    possibleTypes['default']}
                </div>
              </div>
            )
          })}
      </div>
      <Modal
        open={showContent.open}
        handleClose={() =>
          setShowContent(prev => ({
            open: false,
            content: null,
            isVideo: false,
            fileToDownload: null
          }))
        }
      >
        <div className={classes.textModal}>
          {showContent.isVideo === true ? (
            <video
              src={showContent.content}
              controls
              className={classes.filesResponsive}
              style={{
                borderRadius: 8
              }}
            />
          ) : (
            <img
              className={classes.filesResponsive}
              src={showContent.content}
              alt='Imagem Principal'
            />
          )}
          <div
            style={{
              display: showContent.isVideo === true ? 'none' : 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              cursor: 'pointer'
            }}
            onClick={() => {
              !showContent.fileToDownload?.filename
                ? downloadImage(showContent.fileToDownload, 'Foto de capa')
                : downloadImage(showContent.fileToDownload)
            }}
          >
            <StyledSVG src={downloadSVG} height={20} width={20} />
            <p style={{ textDecoration: 'underline', color: '#555555' }}>
              Baixar
            </p>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ShowEventFiles
