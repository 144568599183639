import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import SearchTeacherForm from '../components/form/SearchTeacherForm'
import { getTeachers } from '../store/teachers/actions'
import { roles } from '../utils/constants'

const mapStateToProps = ({ auth, school, teachers }) => {
  return {
    userOccupations: auth.currentOccupation,
    currentSchool: school.currentSchool,
    teachers: teachers
  }
}

const mapDispatchToProps = {
  getTeachers
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem 0'
  }
}))

const SettingsTeachersPage = ({
  currentSchool,
  getTeachers,
  teachers,
  userOccupations
}) => {
  const classes = useStyles()
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)

  useEffect(
    () => {
      currentSchool &&
        getTeachers({ params: { 'q[school_id_eq]': currentSchool.school_id } })
    },
    [currentSchool]
  )

  return (
    <div className={classes.root}>
      <SearchTeacherForm
        getTeachers={getTeachers}
        teachers={teachers}
        school={currentSchool}
        isAdmin={isAdmin}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(
  SettingsTeachersPage
)
