import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
  paper: {
    margin: 0,
    borderRadius: '16px',
    padding: '40px',
    lineHeight: '20.11px !important',
    fontSize: 16,
    display: 'flex',
    maxWidth: '100vw',
    minWidth: '300px',
    backgroundColor: '#386093',
    color: '#FFF',
    minHeight: '18vh'
  }
}))

const HeaderCard = ({ children, parentClass }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <Paper className={`${classes.paper} ${parentClass}`} elevation={0}>
        {children}
      </Paper>
    </Grid>
  )
}

export default HeaderCard
