import http from '../../utils/http'

const fetchOccupationsUrl = '/occupations'

export const fetchOccupationsBySegmentId = id =>
  http
    .get(fetchOccupationsUrl, {
      params: {
        'q[occupation_categories_segment_id_eq]': id
      }
    })
    .then(res => res.data)
