import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  reduxForm,
  Field,
  change,
  Form,
  formValueSelector,
  getFormValues
} from 'redux-form'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import HeadFormTab from '../tab/HeadFormTab'
import { backToThePage } from '../../store/pagination/actions'
import Label from '../label/Label'
import AsyncSelect from '../shared/AsyncSelect'
import MaterialInput from '../shared/MaterialInput'
import { Box } from './styles/CreateModuleForm.style'
import Button from '../button/Button'
import { required } from '../../utils/formUtils'
import YearPickerInput from '../shared/YearPickerInput'
import moment from 'moment'
import ShiftSelect from '../select/ShiftSelect'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    margin: 0,
    borderRadius: '8px',
    borderTopLeftRadius: 'unser',
    lineHeight: '20.11px !important',
    fontSize: 16,
    minWidth: 300,
    paddingLeft: '1.5rem',
    [theme.breakpoints.down('md')]: {
      minWidth: 255
    }
  },
  form: {
    minHeight: '500px'
  },
  btnStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    textAlign: 'right',
    '& button': {
      minWidth: 150
    }
  },
  bull: {
    color: theme.palette.primary.dark,
    fontSize: '2rem',
    verticalAlign: 'text-bottom',
    marginLeft: '0.8rem'
  },
  createBtn: {
    backgroundColor: '#386093',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const CreateClassroomForm = ({
  change,
  handleSubmit,
  initialValues,
  onSubmit,
  pristine,
  school,
  submitSucceeded,
  touch,
  valid,
  currentClassroom,
  schoolValue,
  segmentValue,
  isAdmin,
  backToThePage,
  currentPagination,
  fieldValues,
  ...props
}) => {
  const classes = useStyles()
  const history = useHistory()
  const selectGradeRef = React.useRef(null)
  const [lastSegmentId, setLastSegmentId] = React.useState()

  React.useEffect(
    () => {
      setLastSegmentId(fieldValues?.segment_id?.value)
    },
    [fieldValues?.segment_id?.value]
  )

  const handleChange = (event, input) => {
    if (input.name === 'segment_id') {
      if (+lastSegmentId !== +event?.value && fieldValues?.grade_id?.value) {
        change('grade_id', null)
      }
      setLastSegmentId(+event?.value)
    }
    const newValues = Array.isArray(event)
      ? event?.map(v => v.value)
      : event ? event.value : ''
    change(input.name, newValues)
  }

  const goBack = () => {
    backToThePage({ ...currentPagination, canGoBack: true })
    history.push('/settings/classrooms')
  }
  React.useEffect(
    () => {
      if (currentClassroom?.id) {
        if (fieldValues?.grade_id && !fieldValues?.segment_id) {
          selectGradeRef.current?.select.clearValue()
        }
      }
    },
    [fieldValues?.segment_id]
  )

  submitSucceeded && goBack()
  return (
    <div className={`${classes.root}`}>
      <HeadFormTab currentPage={'classroom'} />
      <Box>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid className={classes.form} container spacing={2}>
            <Grid item xs={12}>
              <Label>Turma</Label>
            </Grid>
            {isAdmin && (
              <Grid item xs={12}>
                <label htmlFor='school_id'>
                  Escola
                  <span className={classes.bull}>&bull;</span>
                </label>
                <Field
                  component={AsyncSelect}
                  defaultValue={{
                    label: currentClassroom?.school?.name,
                    value: initialValues.school_id
                  }}
                  id='school_id'
                  name='school_id'
                  placeholder='Digite ou selecione uma escola'
                  handleAsyncChange={handleChange}
                  searchParam='q[name_cont]'
                  cacheUniqs={0}
                  onInputChange={(value, action) => {
                    if (action.action === 'menu-close') {
                      change('grade_id', null)
                      selectGradeRef.current?.select.clearValue()
                    }
                  }}
                  request={{
                    path: 'school'
                  }}
                  touch={touch}
                  validate={[required]}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={2}>
              <label htmlFor='year'>
                Ano
                <span className={classes.bull}>&bull;</span>
              </label>
              <Field
                autoFocus
                component={YearPickerInput}
                name='year'
                placeholder='Ano da turma'
                views={['year']}
                validate={[required]}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label htmlFor='name'>
                Nome
                <span className={classes.bull}>&bull;</span>
              </label>
              <Field
                component={MaterialInput}
                name='name'
                placeholder='Nome da turma'
                type='text'
                autoComplete='off'
                validate={[required]}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <label htmlFor='segment_id'>
                Segmento
                <span className={classes.bull}>&bull;</span>
              </label>
              <Field
                component={AsyncSelect}
                defaultValue={initialValues.segment_id}
                id='segment_id'
                name='segment_id'
                placeholder='Selecione um segmento'
                handleAsyncChange={handleChange}
                isSearchable
                cacheUniqs={schoolValue || 0}
                request={{
                  path: 'segment'
                }}
                touch={touch}
                validate={[required]}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <label htmlFor='grade_id'>
                Série
                <span className={classes.bull}>&bull;</span>
              </label>
              <Field
                component={AsyncSelect}
                defaultValue={fieldValues?.grade_id}
                selectRef={selectGradeRef}
                id='grade_id'
                name='grade_id'
                placeholder='Selecione uma série'
                handleAsyncChange={handleChange}
                isSearchable={false}
                cacheUniqs={[segmentValue]}
                disabled={!segmentValue}
                request={{
                  path: 'grade',
                  params: {
                    // segmentValue by the initialValues has a label and value, and by 'change' it has only a value
                    'q[segment_id_in]': segmentValue?.value
                      ? segmentValue.value
                      : segmentValue
                  }
                }}
                touch={touch}
                validate={[required]}
              />
            </Grid>
            <Grid style={{ minHeight: 300 }} item xs={12} sm={6}>
              <label htmlFor='teacher_ids'>Professor(a)</label>
              <Field
                component={AsyncSelect}
                defaultValue={initialValues.teacher_ids}
                isMulti
                cacheUniqs={schoolValue || 0}
                id='teacher_ids'
                name='teacher_ids'
                placeholder='Digite ou selecione um(a) professor(a)'
                handleAsyncChange={handleChange}
                searchParam='q[teacher_name_cont]'
                request={{
                  path: 'school_teachers',
                  params: {
                    'q[school_id_eq]':
                      (school && school.school_id) || schoolValue,
                    include: 'teacher'
                  }
                }}
                touch={touch}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor='shift'>Turno</label>
              <Field component={ShiftSelect} name='shift' />
            </Grid>
            <Grid
              style={{ marginTop: '40px' }}
              item
              xs={12}
              className={classes.btnStyle}
            >
              <Button
                variant='outlined'
                className={classes.backBtn}
                onClick={() => goBack()}
              >
                Voltar
              </Button>
              <Button
                className={classes.createBtn}
                type='submit'
                disabled={!valid || pristine}
              >
                {currentClassroom?.id ? 'Editar' : 'Cadastrar'}
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Box>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('createClassroomForm')
  const initValues = {
    grade_id: props.currentClassroom?.grade
      ? {
          label: props.currentClassroom.grade.name,
          value: Number(props.currentClassroom.grade.id)
        }
      : null,
    segment_id: props.currentClassroom?.segment
      ? {
          label: props.currentClassroom.segment.name,
          value: Number(props.currentClassroom.segment.id)
        }
      : undefined,
    shift: props.currentClassroom?.shift,
    name: props.currentClassroom?.name,
    year: props.currentClassroom?.year
      ? moment(`02-02-${props.currentClassroom.year}`)
      : props.currentClassroom?.id && !props.currentClassroom?.year
        ? null
        : new Date(),
    school_id:
      (props.school && props.school.school_id) ||
      (props.currentClassroom &&
        props.currentClassroom.school &&
        props.currentClassroom.school_id),
    teacher_ids:
      props.currentClassroom?.teachers &&
      props.currentClassroom?.teachers?.length > 0
        ? props.currentClassroom?.teachers.map(teacher => ({
            label: teacher.name,
            value: teacher.id
          }))
        : null
  }
  return {
    initialValues: initValues,
    schoolValue: selector(state, 'school_id'),
    segmentValue: selector(state, 'segment_id'),
    currentPagination: state.pagination,
    fieldValues: getFormValues('createClassroomForm')(state)
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('createClassroomForm', field, data)),
  backToThePage: data => dispatch(backToThePage(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'createClassroomForm',
    validateOnBlur: true,
    touchOnChange: true,
    touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {
      if (props.currentClassroom.id) {
        // edit
        const teachersValues = values.teacher_ids?.map(
          teacher => (teacher?.value ? teacher.value : teacher)
        )
        const findTeacher = id =>
          props.currentClassroom.teachers.find(
            teacher => String(teacher.id) === String(id)
          )
        const teachersIds = teachersValues?.filter(id => {
          const teacher = findTeacher(id)
          return !teacher && id
        })

        const teachersToRemove = props.currentClassroom.teachers
          .filter(teacher => {
            const find = teachersValues.includes(teacher.id)
            return !find && teacher.id
          })
          .map(teacher => {
            const classroomTeacher = teacher.classroom_teachers.find(
              classTeacher => {
                return (
                  String(classTeacher.classroom_id) ===
                  String(props.currentClassroom.id)
                )
              }
            )
            return classroomTeacher?.id
          })

        const newValues = {
          ...values,
          name: values.name.trim(),
          shift: values?.shift,
          year: moment(values.year).get('year'),
          school_id: values.school_id.value || values.school_id,
          grade_id: values.grade_id.value
            ? values.grade_id.value
            : values.grade_id,
          segment_id: values.segment_id.value
            ? values.segment_id.value
            : values.segment_id,
          teacher_ids: teachersIds
        }
        await props.editClassroom({
          id: props.currentClassroom.id,
          ...newValues
        })
        teachersToRemove &&
          teachersToRemove.length > 0 &&
          (await props.removeClassroomTeachers({ ids: teachersToRemove }))
      } else {
        // create
        const newValues = {
          ...values,
          name: values.name.trim(),
          year: moment(values.year).get('year')
        }
        props.createClassroom(newValues)
      }
    }
  })(CreateClassroomForm)
)
