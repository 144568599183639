import React from 'react'
import { useDispatch } from 'react-redux'
import AnnouncementConfirmationsView from '../components/view/AnnouncementConfirmationsView'
import {
  getEventConfirmationRSPA,
  getEventById,
  getEventNotConfirmedRSPA
} from '../store/events/actions'

const AnnouncementConfirmationsPage = ({ match }) => {
  const dispatch = useDispatch()
  const { eventId } = match.params
  const [eventConfirmations, setEventConfirmations] = React.useState()
  const [eventNotConfirmed, setEventNotConfirmed] = React.useState()
  const [eventRefused, setEventRefused] = React.useState()

  React.useEffect(
    () => {
      if (eventId) {
        const data = {
          type: 'events',
          attributes: {
            event_id: eventId
          }
        }
        dispatch(getEventById({ id: eventId }))
        dispatch(getEventNotConfirmedRSPA.request({ data }))
          .then(res => {
            res.data.forEach(item => {
              if (item.refused_at) {
                setEventRefused(prev => [...(prev || []), item])
              } else {
                setEventNotConfirmed(prev => [...(prev || []), item])
              }
            })
          })
          .catch(err => {
            console.error(err)
          })
        dispatch(
          getEventConfirmationRSPA.request({
            data
          })
        )
          .then(res => {
            setEventConfirmations(res.data)
          })
          .catch(err => {
            console.error(err)
          })
      }
    },
    [eventId]
  )

  return (
    <AnnouncementConfirmationsView
      eventId={eventId}
      eventConfirmations={eventConfirmations}
      eventNotConfirmed={eventNotConfirmed}
      eventRefused={eventRefused}
    />
  )
}

export default AnnouncementConfirmationsPage
