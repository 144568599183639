import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

const useStyles = makeStyles(theme => ({
  rootDiv: {
    marginBottom: 14,
    '& .MuiGrid-item > .MuiToggleButtonGroup-root': {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.up('md')]: {
        flexFlow: 'row wrap'
      }
    }
  },
  root: {
    textTransform: 'capitalize',
    width: '125px',
    border: 'none',
    background: '#F6F7F8',
    borderRadius: '8px !important',
    fontWeight: 'bold',
    padding: '2rem 6.5rem',
    color: '#555555',
    whiteSpace: 'pre',
    '& p': {
      margin: 0
    }
  },
  selected: {
    '&[aria-pressed="true"]': {
      backgroundColor: theme.palette.secondary.main,
      color: '#FFF',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      }
    },
    '&:hover': {
      color: '#FFF',
      backgroundColor: theme.palette.secondary.main
    }
  },
  error: {
    color: theme.palette.danger.main,
    fontSize: '0.8rem',
    marginLeft: '0.5rem'
  }
}))

export default function ToggleButtonSizes ({
  radios,
  name,
  handleRadioChange,
  defaultValue,
  required,
  input,
  meta,
  children,
  ...custom
}) {
  const { value } = input
  const classes = useStyles()
  const [touch, setTouch] = React.useState(false)
  const formControlLabel = radios.map(radio => (
    <ToggleButton
      className={[classes.root, classes.selected]}
      key={radio.value}
      value={radio.value}
    >
      {radio.label}
    </ToggleButton>
  ))

  const handleClick = () => {
    setTouch(true)
  }

  return (
    <Grid container spacing={2} direction='column' className={classes.rootDiv}>
      <Grid item>
        <ToggleButtonGroup
          size='medium'
          name={name}
          value={value}
          exclusive
          onChange={handleRadioChange}
          onClick={handleClick}
        >
          {formControlLabel}
        </ToggleButtonGroup>
      </Grid>
      {required &&
        !value &&
        touch && <span className={classes.error}>Campo Obrigatório *</span>}
    </Grid>
  )
}
