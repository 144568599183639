import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'

export default function SimpleSelect ({
  id,
  input: { value: inputValue, onChange, ...restInput },
  label,
  meta: { touched, invalid, error, active },
  placeholder,
  multiple,
  options = [],
  type,
  dependentFields,
  change,
  height,
  ...custom
}) {
  const [hover, setHover] = React.useState(false)
  const generalTheme = useTheme()

  const useStyles = makeStyles(theme => ({
    root: {
      marginBottom: 14,
      display: 'grid',
      color: '#4d5e73',
      '& .MuiInputBase-root': {
        borderRadius: '8px',
        border: props => props.borderColor,
        height: props => (props.height ? props.height : '2.5rem'),
        '& .MuiSelect-select.MuiSelect-select': {
          paddingLeft: 24
        }
      },
      '& .MuiSelect-icon': {
        color: theme.palette.primary.dark
      },
      '& .Mui-disabled': {
        background: '#F2F2F2',
        padding: 0
      }
    },
    formControl: {
      display: 'flex',
      flexFlow: 'column',
      minWidth: 350,
      minHeight: 56,
      backgroundColor: '#FFF',
      '& .MuiInputBase-formControl': {
        minHeight: 50
      },
      '& .MuiSelect-select input': {
        borderRadius: '8px !important',
        border: 'none'
      },
      '& .MuiSelect-nativeInput': {
        // opacity: 1,
        border: 'none',
        bottom: '-12px'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '8px',
        border: '1px solid rgba(0,0,0,0.15)'
      },
      '& .MuiSelect-outlined.MuiSelect-outlined': {
        paddingTop: '10px',
        paddingBottom: '10px'
      }
    },
    label: {
      opacity: 1,
      color: 'rgba(0, 0, 0, 0.26)'
    },
    input: {
      marginBottom: '2px !important',
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.25)',
        fontSize: '1rem'
      }
    },
    error: {
      color: theme.palette.danger.main,
      fontSize: '0.8rem'
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingLeft: '1rem'
    },
    chip: {
      margin: 2
    }
  }))

  const classes = useStyles({
    borderColor:
      touched && error
        ? `1px solid ${generalTheme.palette.danger.main} !important`
        : hover
          ? `1px solid ${generalTheme.palette.primary.light}`
          : '1px solid #ADB8CC',
    height: height
  })
  const handleChange = event => {
    if (dependentFields) {
      dependentFields.forEach(field => {
        change(field, null)
      })
    }
    onChange(event)
  }
  const menuItems = options.map(item => (
    <MenuItem key={item.value} value={item.value}>
      {item.label}
    </MenuItem>
  ))
  return (
    <div className={classes.root}>
      <Select
        disableUnderline
        className={classes.formControl}
        id={id}
        multiple={multiple}
        placeholder={placeholder}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onChange={handleChange}
        variant='outlined'
        value={inputValue}
        type={type}
        renderValue={selected => {
          return Array.isArray(selected) ? (
            <div className={classes.chips}>
              {selected.map(value => {
                return (
                  <Chip
                    key={value}
                    label={
                      options[options.findIndex(x => x.value === String(value))]
                        ?.label
                    }
                    className={classes.chip}
                  />
                )
              })}
            </div>
          ) : (
            <div>
              {
                options[options.findIndex(x => x.value === String(selected))]
                  ?.label
              }
            </div>
          )
        }}
        {...restInput}
        {...custom}
      >
        {menuItems}
      </Select>
      {touched &&
        !active &&
        error && <span className={classes.error}>{error}</span>}
    </div>
  )
}

SimpleSelect.propTypes = {
  borderColor: PropTypes.string,
  dependentFields: PropTypes.array,
  id: PropTypes.string,
  options: PropTypes.array.isRequired,
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  meta: PropTypes.object,
  change: PropTypes.func,
  type: PropTypes.string,
  height: PropTypes.string
}
