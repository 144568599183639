import React, { useEffect } from 'react'

import SearchReportsForm from '../form/SearchReportsForm'
import { NoReportsDiv } from '../../pages/ReportsPage'

const ReportsHistoryView = ({
  history,
  getReports,
  reports,
  schoolId,
  title,
  handleClickButton,
  defaultParams,
  handleGenerateReport
}) => {
  useEffect(() => {
    getReports({ params: defaultParams })
  }, [])

  return (
    <div>
      <SearchReportsForm
        handleGenerateReport={handleGenerateReport}
        defaultParams={defaultParams}
        reports={reports}
        handleClickButton={handleClickButton}
        schoolId={schoolId}
        getReports={getReports}
      />
      {reports.items.length === 0 && !reports.isFetching && <NoReportsDiv />}
    </div>
  )
}

export default ReportsHistoryView
