import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'

import ReportStudentByClassroomForm from '../components/form/ReportStudentByClassroomForm'
import {
  getReportStudentById,
  createReportStudent,
  getReportById,
  getReportOptionsById,
  editReportStudent,
  cleanReportStudentAnswers
} from '../store/reports/actions'
// import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    width: '98%',
    padding: '1rem 0'
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 24,
    marginBottom: 16
  },
  paper: {
    borderRadius: 8,
    padding: 32
  }
}))

const ReportStudentByClassroomPage = ({
  match: { params },
  reports,
  getReportOptionsById,
  getReportStudentById,
  getReportById,
  createReportStudent,
  editReportStudent,
  cleanReportStudentAnswers
}) => {
  const classes = useStyles()
  // const location = useLocation()
  // const lengthPathname = location.pathname.split('/').length - 1
  // const editPath = location.pathname.split('/')[lengthPathname]

  useEffect(() => {
    getReportOptionsById({ id: params.reportId })
    getReportById({ id: params.reportId, getWithAllStudents: true })
    return () => {
      cleanReportStudentAnswers()
    }
  }, [])

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <h1 className={classes.label}>{reports.currentItem.classroom?.name}</h1>
        {/* eslint-disable-next-line */}
        <ReportStudentByClassroomForm
          reportId={params.reportId}
          reports={reports}
        />
      </Paper>
    </div>
  )
}

const mapStateToProps = ({ auth, reports, school }) => {
  return {
    userOccupations: auth.user?.data?.attributes?.occupations,
    schoolId: school.items && school.items.school_id,
    reports: reports
  }
}

const mapDispatchToProps = {
  getReportStudentById,
  getReportOptionsById,
  getReportById,
  createReportStudent,
  editReportStudent,
  cleanReportStudentAnswers
}

export default connect(mapStateToProps, mapDispatchToProps)(
  ReportStudentByClassroomPage
)
