import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'

import Button from '../button/Button'
import FormProgress from '../shared/FormProgress'
import themePuzzleSVG from '../../assets/icons/theme-puzzle.svg'
import StyledSVG from '../shared/StyledSVG'
// import SkillsComponent from '../shared/SkillsComponent'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      columnGap: 12
    },
    '& path': {
      stroke: '#4D5E80'
    },
    '& h1': {
      fontWeight: 700,
      color: '#4D5E80',
      fontSize: 32,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16,
      maxWidth: '25%'
    }
  },
  steps: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
    '& p': {
      margin: 0,
      fontWeight: 700,
      fontSize: 16
    },
    '& span': {
      fontWeight: 500,
      fontSize: 14
    }
  },
  bottomBtns: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    '& button': {
      width: 150
    }
  },
  cancelBtn: {
    width: 146,
    border: '2px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '2px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  sendBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  horizontalBar: {
    border: '1px solid rgba(173, 184, 204, 0.3)'
  }
}))

const SecondStepThemeForm = props => {
  const {
    currentStep,
    steps,
    valid,
    // change,
    history,
    currentTheme
  } = props

  const classes = useStyles()

  return (
    <div>
      <div className={classes.header}>
        <div className='title'>
          <StyledSVG src={themePuzzleSVG} height={32} width={32} />
          <h1>Alimentar foco e criar conteúdo</h1>
        </div>
        <span>Chegamos na etapa de adicionar conteúdo ao seu tema.</span>
      </div>

      <div className={classes.steps}>
        <p>Etapas</p>
        <span>Fique atento as etapas a seguir:</span>
        <FormProgress
          currentStep={currentStep}
          showTooltip
          tooltipIndex={2}
          tooltipMessage={
            'Preencha todos os focos desse segmento para avançar.'
          }
          // setCurrentStep={setCurrentStep}
          steps={steps}
        />
      </div>

      <div className={classes.horizontalBar} />

      <div className={classes.projectInfos}>
        <div className={classes.themeCover}>
          <img src={currentTheme?.cover?.image_url} />
        </div>
        <div className={classes.projectDetails}>
          <div>
            <span>Módulo</span>
            <p>{currentTheme?.theme_module?.title}</p>
          </div>
          <div>
            <span>Tema</span>
            <p>{currentTheme?.title}</p>
          </div>
          <div>
            <span>Habilidades</span>
            {/* <SkillsComponent skills={}/> */}
          </div>
        </div>
      </div>

      <div className={classes.bottomBtns}>
        <Button
          type='button'
          variant={'outlined'}
          className={classes.cancelBtn}
          onClick={() => history.push('/modules')}
        >
          Voltar
        </Button>
        <Button
          type='submit'
          // onClick={() => change('currentStep', currentStep)}
          className={classes.sendBtn}
          disabled={!valid}
        >
          Seguir
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    // initialValues: props.initialValues
  }
}

const mapDispatchToProps = dispatch => ({
  // alert: (message, type) =>
  //   dispatch(
  //     showAlertMessage({
  //       message: message,
  //       severity: type
  //     })
  //   )
})

export default connect(mapStateToProps, mapDispatchToProps)(SecondStepThemeForm)
