import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import { Form, reduxForm, Field, formValueSelector, change } from 'redux-form'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { roles, reportsShift } from '../../utils/constants'
import AsyncSelect from '../shared/AsyncSelect'
import MaterialInput from '../shared/MaterialInput'
import CustomTable from '../table/CustomTable'
import Button from '../button/Button'
import filterSVG from '../../assets/icons/filter-icon.svg'
import viewSVG from '../../assets/icons/pattern-view-icon.svg'
import editSVG from '../../assets/icons/pattern-edit-icon.svg'
import insertStudentSVG from '../../assets/icons/add-student.svg'
import StyledSVG from '../shared/StyledSVG'
import { Tooltip } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '40px 30px',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  btnStyle: {
    marginTop: 18,
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'center',
    textAlign: 'right',
    gap: 10,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& button': {
        width: 240
      }
    }
  },
  actionsSection: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& path': {
      stroke: '#868E96'
    },
    '& circle': {
      stroke: '#868E96'
    }
  },
  pageLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 20,
    '& h1': {
      fontSize: 32,
      color: '#386093',
      fontWeight: 700,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  searchSection: {
    marginTop: 40
  },
  searchDiv: {
    '& .MuiInputBase-root': {
      width: 300,
      [theme.breakpoints.down('426')]: {
        maxWidth: '250px'
      },
      [theme.breakpoints.down('330')]: {
        maxWidth: 220
      }
    }
  },
  filtersSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  filterLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  advancedFilter: {
    overflow: 'hidden',
    transition: 'height 0.5s ease-in-out',
    [theme.breakpoints.down('1000')]: {
      overflow: 'scroll'
    }
  },
  filtersDisplay: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24
  },
  searchGroup: {
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    gap: 24,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      gap: 12
    }
  },
  searchField: {
    '& .select__menu-list': {
      maxHeight: '150px'
    },
    '& div': {
      '& .select__control': {
        minWidth: 200
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        minWidth: 'unset',
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    },
    [theme.breakpoints.down('1000')]: {
      width: '100% !important',
      '& div': {
        // redux form 'Field' componente
        '& div': {
          '& .select__control, .select__value-container, .select__indicators, .MuiInputBase-root': {
            minHeight: '35px',
            height: '35px'
          },
          maxWidth: '100%'
        }
      }
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: 8,
    '& button': {
      marginRight: 0,
      width: 149
    },
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'flex-start',
      marginTop: 15,
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    [theme.breakpoints.down('350')]: {
      '& button': {
        width: '170px !important'
      }
    }
  },
  clearBtn: {
    backgroundColor: '#EF7C8F',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  horizontalBar: {
    marginBottom: 27,
    width: '96%',
    height: 1,
    border: '1px solid #ADB8CC'
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  createBtn: {
    backgroundColor: '#386093',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const SearchClassroomForm = ({
  change,
  classrooms,
  getClassrooms,
  gradeValue,
  userOccupations,
  school,
  isVersarAdmin,
  isAdmin,
  touch,
  childrenValue,
  parentValues,
  name,
  reset,
  schoolValue
}) => {
  const classes = useStyles()

  const history = useHistory()
  const [openMoreFilter, setOpenMoreFilter] = React.useState(false)
  const paramsRequest = {
    'q[grade_id_in]': gradeValue,
    'q[name_cont]': name,
    ...(schoolValue
      ? { 'q[school_id_in]': schoolValue }
      : { 'q[school_id_in]': school && school.school_id }),
    'q[children_id_eq]': childrenValue,
    include: 'children,grade,school,segment',
    'q[s]': 'name asc'
  }
  const selectNameRef = React.useRef(null)
  const selectSchoolRef = React.useRef(null)
  const selectGradeRef = React.useRef(null)
  const selectChildRef = React.useRef(null)

  const handleChangeTitle = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      getClassrooms({ params: paramsRequest })
    }
  }
  const handleChange = (event, input) => {
    const newValue = event ? event.value : null
    change(input.name, newValue)
  }

  const handleClearFields = () => {
    reset()
    selectGradeRef.current?.select.clearValue()
    selectNameRef.current?.select.clearValue()
    selectChildRef.current?.select.clearValue()
    selectSchoolRef.current?.select.clearValue()
    getClassrooms({
      params: {
        'q[school_id_in]': school && school.school_id,
        include: 'children,grade,school,segment',
        'q[s]': 'name asc'
      }
    })
  }

  const handleSearch = () => {
    getClassrooms({
      params: {
        ...paramsRequest
      }
    })
  }

  const columns = [
    {
      key: 'classroom',
      name: 'Turma',
      render: row => {
        return (
          <span
            onClick={() =>
              history.push(`${history.location.pathname}/${row.id}`)
            }
          >
            {row.name}
          </span>
        )
      }
    },
    {
      key: 'school',
      name: 'Escola',
      render: row => {
        return row.school?.name
      }
    },
    {
      key: 'year',
      name: 'Ano',
      render: row => {
        return row?.year
      }
    },
    {
      key: 'segment',
      name: 'Segmento',
      render: row => {
        return row.segment?.name || 'Não definido'
      }
    },
    {
      key: 'grade',
      name: 'Série',
      render: row => {
        return row.grade?.name
      }
    },
    {
      key: 'totalStudent',
      name: 'Quant. Alunos',
      render: row => {
        return row.children.length
      }
    },
    {
      key: 'shift',
      name: 'Turnos',
      render: row => {
        return reportsShift[row.shift] || 'Não definido'
      }
    },
    {
      key: 'actions',
      name: 'Ações',
      width: '10%',
      align: 'right',
      render: function render (row) {
        // mudar o redirecionamento quando for admin
        return (
          <div className={classes.actionsSection}>
            <Tooltip title='Visualizar'>
              <div
                onClick={() =>
                  history.push(`${history.location.pathname}/${row.id}`)
                }
              >
                <StyledSVG src={viewSVG} width={20} height={20} />
              </div>
            </Tooltip>
            <Tooltip title='Editar'>
              <div
                onClick={() =>
                  history.push(`${history.location.pathname}/${row.id}/edit`)
                }
              >
                <StyledSVG src={editSVG} width={20} height={20} />
              </div>
            </Tooltip>
            <Tooltip title='Inserir Aluno'>
              <div
                onClick={() =>
                  history.push(
                    `${history.location.pathname}/${row.id}/students/new`,
                    {
                      allowGoBack: true
                    }
                  )
                }
              >
                <StyledSVG src={insertStudentSVG} width={20} height={20} />
              </div>
            </Tooltip>
          </div>
        )
      }
    }
  ]

  const getColumns = () => {
    const columnKeys = isVersarAdmin
      ? [
          'classroom',
          'year',
          'school',
          'grade',
          'segment',
          'totalStudent',
          'actions',
          'shift'
        ]
      : [
          'classroom',
          'year',
          'grade',
          'segment',
          'totalStudent',
          'actions',
          'shift'
        ]

    return columns.filter(column => columnKeys.includes(column.key))
  }

  const schoolParams = !isVersarAdmin && {
    'q[school_id_in]': school && school.school_id,
    'q[m]': 'or',
    'q[school_id_null]': true
  }

  return (
    <div className={classes.root}>
      <Form>
        <div className={classes.pageLabel}>
          <h1>Turmas</h1>
          <span>Explore a lista de turmas disponíveis na plataforma</span>
        </div>
        {/* Header */}
        <div className={classes.searchSection}>
          <div className={classes.searchDiv}>
            <strong>Busca</strong>
            <Field
              style={{ marginTop: 10 }}
              component={MaterialInput}
              selectRef={selectNameRef}
              name='name'
              endBtnFunction={() => {
                getClassrooms({ params: paramsRequest })
              }}
              onKeyDown={handleChangeTitle}
              placeholder={'Nome da turma'}
              type='text'
            />
          </div>
          <div className={classes.filtersSection}>
            <div className={classes.filterLabel}>
              <StyledSVG src={filterSVG} width={12} height={12} />
              <p
                onClick={() => setOpenMoreFilter(prev => !prev)}
                style={{
                  cursor: 'default',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#386093'
                }}
              >
                Filtros avançados
              </p>
              {!openMoreFilter ? (
                <KeyboardArrowDownIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              )}
            </div>
            <div
              className={classes.advancedFilter}
              style={{
                height: openMoreFilter ? '250px' : '0px'
              }}
            >
              <div className={classes.filtersDisplay}>
                <div
                  className={classes.searchGroup}
                  style={{ opacity: openMoreFilter ? '1' : '0' }}
                >
                  {userOccupations?.includes(roles.VERSAR_ADMIN) && (
                    <div className={classes.searchField}>
                      <strong htmlFor='school_id'>Escola</strong>
                      <Field
                        component={AsyncSelect}
                        selectRef={selectSchoolRef}
                        defaultOptions={false}
                        id='school_id'
                        name='school_id'
                        placeholder='Digite ou selecione a escola'
                        handleAsyncChange={handleChange}
                        searchParam='q[name_cont]'
                        request={{
                          path: 'school'
                        }}
                        touch={touch}
                      />
                    </div>
                  )}
                  <div className={classes.searchField}>
                    <label htmlFor={`grade_id`}>Série</label>
                    <Field
                      component={AsyncSelect}
                      selectRef={selectGradeRef}
                      id='grade_id'
                      name='grade_id'
                      placeholder='Selecione uma série'
                      handleAsyncChange={handleChange}
                      isSearchable={false}
                      request={{
                        path: 'grade',
                        params: schoolParams
                      }}
                      touch={touch}
                    />
                  </div>
                  <div className={classes.searchField}>
                    <label htmlFor={'children_id'}>Aluno</label>
                    <Field
                      component={AsyncSelect}
                      selectRef={selectChildRef}
                      id='children_id'
                      name='children_id'
                      placeholder='Digite ou selecione um aluno'
                      handleAsyncChange={handleChange}
                      cacheUniqs={gradeValue || 0}
                      searchParam='q[name_cont]'
                      touch={touch}
                      request={{
                        path: 'children',
                        params: {
                          'q[classrooms_school_id_eq]': school.school_id,
                          'q[classrooms_year_lteq]':
                            new Date().getFullYear() + 1,
                          'q[classrooms_year_gteq]': new Date().getFullYear()
                        }
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    opacity: openMoreFilter ? '1' : '0',
                    transition: 'opacity 0.3s ease-in-out'
                  }}
                  className={classes.buttons}
                >
                  <Button
                    onClick={() => handleClearFields()}
                    className={classes.clearBtn}
                  >
                    Limpar
                  </Button>
                  <Button
                    className={classes.applyBtn}
                    type='button'
                    onClick={() => handleSearch()}
                  >
                    Aplicar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.horizontalBar} />
        </div>
        <div>
          <h2>Lista de Turmas</h2>
          <CustomTable
            columns={getColumns()}
            columnsPointer={[0]}
            border={'none'}
            bodyBorder={'none'}
            headCellRadius
            headColor={'rgba(173, 184, 204, 0.3)'}
            data={classrooms.items}
            params={paramsRequest}
            fetchItems={getClassrooms}
            isFetching={classrooms.isFetching}
            pagination={classrooms.pagination}
          />
        </div>
        <div>
          <Grid item xs={12} className={classes.btnStyle}>
            <Button
              className={classes.backBtn}
              variant='outlined'
              type='button'
              onClick={() => history.push('/settings')}
            >
              Voltar
            </Button>
            {isAdmin && (
              <Button
                className={classes.createBtn}
                type='button'
                onClick={() => history.push(`${history.location.pathname}/new`)}
              >
                Cadastrar nova turma
              </Button>
            )}
          </Grid>
        </div>
      </Form>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('searchClassroomForm')
  return {
    gradeValue: selector(state, 'grade_id'),
    name: selector(state, 'name'),
    schoolValue: selector(state, 'school_id'),
    childrenValue: selector(state, 'children_id'),
    parentValues: selector(state, 'parent_id'),
    userOccupations: state.auth.currentOccupation
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('searchClassroomForm', field, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'searchClassroomForm'
  })(SearchClassroomForm)
)
