export const GET_EVENTS = 'events/GET_EVENTS'
export const GET_EVENTS_SUCCESSFUL = 'events/GET_EVENTS_SUCCESSFUL'
export const GET_EVENTS_FAILURE = 'events/GET_EVENTS_FAILURE'

export const GET_EVENT_BY_ID = 'events/GET_EVENT_BY_ID'
export const GET_EVENT_BY_ID_SUCCESSFUL = 'events/GET_EVENT_BY_ID_SUCCESSFUL'
export const GET_EVENT_BY_ID_FAILURE = 'events/GET_EVENT_BY_ID_FAILURE'

export const CREATE_EVENTS = 'events/CREATE_EVENTS'
export const CREATE_EVENTS_SUCCESSFUL = 'events/CREATE_EVENTS_SUCCESSFUL'
export const CREATE_EVENTS_FAILURE = 'events/CREATE_EVENTS_FAILURE'

export const GET_EVENT_DESTINATION_BY_ID = 'events/GET_EVENT_DESTINATION_BY_ID'
export const GET_EVENT_DESTINATION_BY_ID_SUCCESSFUL =
  'events/GET_EVENT_DESTINATION_BY_ID_SUCCESSFUL'
export const GET_EVENT_DESTINATION_BY_ID_FAILURE =
  'events/GET_EVENT_DESTINATION_BY_ID_FAILURE'

export const GET_EVENT_COMMENTS = 'events/GET_EVENT_COMMENTS'
export const GET_EVENT_COMMENTS_SUCCESSFUL =
  'events/GET_EVENT_COMMENTS_SUCCESSFUL'
export const GET_EVENT_COMMENTS_FAILURE = 'events/GET_EVENT_COMMENTS_FAILURE'

export const GET_EVENT_LIKES = 'events/GET_EVENT_LIKES'
export const GET_EVENT_LIKES_SUCCESSFUL = 'events/GET_EVENT_LIKES_SUCCESSFUL'
export const GET_EVENT_LIKES_FAILURE = 'events/GET_EVENT_LIKES_FAILURE'

export const CREATE_EVENT_COMMENTS = 'events/CREATE_EVENT_COMMENTS'
export const CREATE_EVENT_COMMENTS_SUCCESSFUL =
  'events/CREATE_EVENT_COMMENTS_SUCCESSFUL'
export const CREATE_EVENT_COMMENTS_FAILURE =
  'events/CREATE_EVENT_COMMENTS_FAILURE'

export const CREATE_EVENT_LIKES = 'events/CREATE_EVENT_LIKES'
export const CREATE_EVENT_LIKES_SUCCESSFUL =
  'events/CREATE_EVENT_LIKES_SUCCESSFUL'
export const CREATE_EVENT_LIKES_FAILURE = 'events/CREATE_EVENT_LIKES_FAILURE'

export const GET_EVENT_FILES = 'events/GET_EVENT_FILES'
export const GET_EVENT_FILES_SUCCESSFUL = 'events/GET_EVENT_FILES_SUCCESSFUL'
export const GET_EVENT_FILES_FAILURE = 'events/GET_EVENT_FILES_FAILURE'

export const DELETE_EVENT = 'events/DELETE_EVENT'
export const DELETE_EVENT_SUCCESSFUL = 'events/DELETE_EVENT_SUCCESSFUL'
export const DELETE_EVENT_FAILURE = 'events/DELETE_EVENT_FAILURE'

export const EDIT_EVENT = 'events/EDIT_EVENT'
export const EDIT_EVENT_SUCCESSFUL = 'events/EDIT_EVENT_SUCCESSFUL'
export const EDIT_EVENT_FAILURE = 'events/EDIT_EVENT_FAILURE'

export const SET_CURRRENT_EVENT = 'events/SET_CURRRENT_EVENT'
export const CLEAN_CURRRENT_EVENT = 'events/CLEAN_CURRRENT_EVENT'
export const CLEAN_CURRRENT_DESTINATIONS_EVENT =
  'events/CLEAN_CURRRENT_DESTINATIONS_EVENT'
