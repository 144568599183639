import React from 'react'
import ApexCharts from 'react-apexcharts'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #DADADA',
    borderRadius: 16,
    padding: 32
  }
}))

function PlataformViewChart ({ props }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <ApexCharts
        options={{
          chart: {
            type: 'donut'
          },
          colors: ['#9DD2DA', '#EF7C8F', '#386093'],
          legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            labels: {
              useSeriesColors: false
            }
          },
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                size: '20%',
                labels: {
                  show: false
                },
                customScale: 0.8
              }
            }
          },
          responsive: [
            {
              breakpoint: 750,
              options: {
                chart: {
                  width: 250
                },
                legend: {
                  position: 'bottom'
                }
              }
            },
            {
              breakpoint: 700,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            },
            {
              breakpoint: 380,
              options: {
                chart: {
                  width: 150
                },
                legend: {
                  position: 'bottom'
                }
              }
            }
          ],
          labels: props.map(item => item.key)
        }}
        series={props.map(item => {
          const { value } = item
          const range =
            parseFloat(
              (value['views'] / value['recipients'] * 100).toFixed(2)
            ) || 0
          return range
        })}
        type='donut'
        width={300}
        height={300}
      />
    </div>
  )
}

export default PlataformViewChart
