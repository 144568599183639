import { createReducer } from '@reduxjs/toolkit'
import {
  GET_OCCUPATIONS,
  GET_OCCUPATIONS_SUCCESSFUL,
  GET_OCCUPATIONS_FAILURE
} from './types'

const initialState = {
  items: [],
  isFetching: false,
  isError: false,
  errorMessage: ''
}

const getOccupationsReducer = createReducer(initialState, {
  [GET_OCCUPATIONS]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [GET_OCCUPATIONS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: action.payload,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [GET_OCCUPATIONS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  })
})

export default getOccupationsReducer
