import jsonApi from '../../utils/jsonApi'

export const fetchCycles = params => {
  return jsonApi
    .findAll('cycle', {
      params: params
    })
    .then(res => {
      const options =
        res.data &&
        res.data.map(data => {
          return { label: data.name, value: data.id }
        })
      return options
    })
}
