import { createReducer } from '@reduxjs/toolkit'
import * as types from './types'

const initialState = {
  items: [],
  isFetching: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  currentItem: {},
  currentStudent: {},
  currentTemplate: {},
  reportStudents: {
    items: [],
    isFetching: false,
    pagination: {
      total: 1,
      page: 1,
      pageCount: 1
    }
  },
  reportOptions: [],
  pagination: {
    total: 1,
    page: 1,
    pageCount: 1
  },
  reportsFromTemplate: {
    items: [],
    include: null,
    isFetching: false,
    pagination: {
      total: 1,
      page: 1,
      pageCount: 1
    }
  },
  reportOfTheDay: null
}

const reportsReducer = createReducer(initialState, {
  [types.GET_REPORTS]: (state, action) => ({
    ...state,
    items: [],
    currentItem: {},
    isFetching: true,
    isSuccess: false,
    reportOfTheDay: null
  }),
  [types.GET_REPORTS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: action.payload.data,
    pagination: {
      total: action.payload.meta.total_count,
      pageCount: action.payload.meta.page_count
    },
    currentTemplate: null,
    isFetching: false
  }),
  [types.GET_REPORTS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.GENERATE_REPORTS]: (state, action) => ({
    ...state,
    reportOfTheDay: null,
    isFetching: true,
    isError: false,
    isSuccess: false
  }),
  [types.GENERATE_REPORTS_SUCCESSFUL]: (state, action) => ({
    ...state,
    reportOfTheDay: action.payload.data || action.payload,
    isFetching: false,
    isError: false,
    isSuccess: true
  }),
  [types.GENERATE_REPORTS_FAILURE]: (state, action) => ({
    ...state,
    reportOfTheDay: null,
    isFetching: false,
    isError: true,
    isSuccess: false,
    errorMessage: action.payload
  }),
  [types.GET_GENERATED_REPORTS_BY_ID]: (state, action) => ({
    ...state,
    reportsFromTemplate: {
      items: [],
      include: null,
      isFetching: false
    },
    reportOfTheDay: null,
    isFetching: true,
    isError: false,
    isSuccess: false
  }),
  [types.GET_GENERATED_REPORTS_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    reportsFromTemplate: {
      items: action.payload.reportsFromTemplates.data,
      include: action.payload.reportsFromTemplates.included,
      isFetching: false,
      pagination: {
        total: action.payload.reportsFromTemplates.meta.total_count,
        pageCount: action.payload.reportsFromTemplates.meta.page_count
      }
    },
    reportOfTheDay: action.payload.reportDay,
    isFetching: false,
    isError: false,
    isSuccess: true
  }),
  [types.GET_GENERATED_REPORTS_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    isSuccess: false,
    errorMessage: action.payload,
    reportsFromTemplate: {
      items: [],
      include: null,
      isFetching: false
    }
  }),
  [types.GET_REPORT_TEMPLATE_BY_ID]: (state, action) => ({
    ...state,
    items: [],
    reportOptions: [],
    currentTemplate: {},
    isFetching: true
  }),
  [types.GET_REPORT_TEMPLATE_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentTemplate: action.payload,
    isFetching: false
  }),
  [types.GET_REPORT_TEMPLATE_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.EDIT_REPORT_TEMPLATE]: (state, action) => ({
    ...state,
    items: [],
    isFetching: true,
    isSuccess: false
  }),
  [types.EDIT_REPORT_TEMPLATE_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    currentTemplate: {
      ...state.currentTemplate
    },
    isSuccess: true
  }),
  [types.EDIT_REPORT_TEMPLATE_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_REPORT_BY_ID]: (state, action) => ({
    ...state,
    items: [],
    reportOptions: [],
    currentItem: {},
    isFetching: true
  }),
  [types.GET_REPORT_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: action.payload.report,
    isFetching: false,
    reportStudents: {
      items: action.payload.studentsReport.data,
      isFetching: false,
      pagination: {
        total: action.payload.studentsReport.meta.total_count,
        page: 1,
        pageCount: action.payload.studentsReport.meta.page_count
      }
    }
  }),
  [types.GET_REPORT_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.CREATE_REPORTS]: (state, action) => ({
    ...state,
    items: [],
    currentItem: {},
    isFetching: true,
    isSuccess: false
  }),
  [types.CREATE_REPORTS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: [...state.items, action.payload],
    isFetching: false,
    isSuccess: true
  }),
  [types.CREATE_REPORTS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    isSuccess: false,
    errorMessage: action.payload
  }),
  [types.GET_REPORT_STUDENTS]: (state, action) => ({
    ...state,
    items: [],
    reportOptions: [],
    reportStudents: {
      ...initialState.reportStudentsModel,
      isFetching: true
    },
    isFetching: true
  }),
  [types.GET_REPORT_STUDENTS_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    reportStudents: {
      items: action.payload.data,
      isFetching: false,
      pagination: {
        total: action.payload.meta.total_count,
        page: 1,
        pageCount: action.payload.meta.page_count
      }
    }
  }),
  [types.GET_REPORT_STUDENTS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload,
    reportStudents: {
      ...initialState.reportStudentsModel,
      isFetching: false
    }
  }),
  [types.GET_REPORT_STUDENT_BY_ID]: (state, action) => ({
    ...state,
    items: [],
    currentStudent: {},
    reportOptions: [],
    isFetching: true
  }),
  [types.GET_REPORT_STUDENT_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentStudent: action.payload,
    isFetching: false
  }),
  [types.GET_REPORT_STUDENT_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_REPORT_OPTIONS_BY_ID]: (state, action) => ({
    ...state,
    items: [],
    currentStudent: {},
    reportOptions: [],
    isFetching: true
  }),
  [types.GET_REPORT_OPTIONS_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    reportOptions: action.payload,
    isFetching: false
  }),
  [types.GET_REPORT_OPTIONS_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.CREATE_REPORT_STUDENT]: (state, action) => ({
    ...state,
    items: [],
    isFetching: true
  }),
  [types.CREATE_REPORT_STUDENT_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false
  }),
  [types.CREATE_REPORT_STUDENT_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.EDIT_REPORT_STUDENT]: (state, action) => ({
    ...state,
    items: [],
    isFetching: true,
    isSuccess: false
  }),
  [types.EDIT_REPORT_STUDENT_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    currentItem: {
      ...state.currentItem,
      report_students:
        state.currentItem.report_students &&
        state.currentItem.report_students.map(
          report =>
            report.id === action.payload.id
              ? { ...report, status: 'done' }
              : { ...report }
        )
    },
    isSuccess: true
  }),
  [types.EDIT_REPORT_STUDENT_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.CLEAN_REPORT_STUDENT_ANSWERS]: (state, action) => ({
    ...state,
    currentStudent: initialState.currentStudent
  })
})

export default reportsReducer
