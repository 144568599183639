import { createReducer } from '@reduxjs/toolkit'
import * as types from './types'

const initialState = {
  items: [],
  wrapperItems: {},
  isFetching: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  currentItem: {},
  destinations: {
    items: [],
    type: '',
    pagination: {
      total: 1,
      pageCount: 1
    }
  },
  pagination: {
    total: 1,
    page: 1,
    pageCount: 1
  }
}

const utilsReducer = createReducer(initialState, {
  [types.CREATE_UTILS]: (state, action) => ({
    ...state,
    items: [],
    currentItem: {},
    isFetching: true,
    isSuccess: false,
    isFailure: false
  }),
  [types.CREATE_UTILS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: [...state.items, action.payload],
    isFetching: false,
    isSuccess: true,
    isError: false
  }),
  [types.CREATE_UTILS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    isSuccess: false,
    errorMessage: action.payload
  }),
  [types.GET_UTILS]: (state, action) => ({
    ...state,
    items: [],
    currentItem: {},
    isFetching: true,
    isSuccess: false
  }),
  [types.GET_UTILS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: action.payload.data,
    pagination: {
      total: action.payload.meta.total_count,
      pageCount: action.payload.meta.page_count
    },
    destinations: action.payload.included,
    isFetching: false
  }),
  [types.GET_UTILS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    errorMessage: action.payload
  }),
  [types.GET_WRAPPER_UTILS]: (state, action) => ({
    ...state,
    wrapperItems: {},
    isFetching: true
  }),
  [types.GET_WRAPPER_UTILS_SUCCESSFUL]: (state, action) => ({
    ...state,
    wrapperItems: action.payload,
    isFetching: false
  }),
  [types.GET_WRAPPER_UTILS_FAILURE]: (state, action) => ({
    ...state,
    wrapperItems: {},
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.EDIT_WRAPPER]: (state, action) => ({
    ...state,
    isFetching: true,
    isSuccess: false
  }),
  [types.EDIT_WRAPPER_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isSuccess: true
  }),
  [types.EDIT_WRAPPER_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    isSuccess: false,
    errorMessage: action.payload
  }),
  [types.EDIT_UTIL]: (state, action) => ({
    ...state,
    isFetching: true,
    isSuccess: false
  }),
  [types.EDIT_UTIL_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: state.items.filter(
      item => String(item.id) !== String(action.payload)
    ),
    isFetching: false,
    isSuccess: true
  }),
  [types.EDIT_UTIL_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    isSuccess: false,
    errorMessage: action.payload
  }),
  [types.DELETE_UTIL]: (state, action) => ({
    ...state,
    isFetching: true
  }),
  [types.DELETE_UTIL_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: state.items.filter(
      item => String(item.id) !== String(action.payload)
    ),
    isFetching: false
  }),
  [types.DELETE_UTIL_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_UTIL_BY_ID]: (state, action) => ({
    ...state,
    items: [],
    currentItem: {},
    destinations: [],
    isFetching: true
  }),
  [types.GET_UTIL_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    destinations: action.payload.destinations,
    currentItem: action.payload.data
  }),
  [types.GET_UTIL_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.CLEAN_CURRRENT_UTIL]: (state, action) => ({
    ...initialState
  })
})

export default utilsReducer
