import React from 'react'
import ApexCharts from 'react-apexcharts'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    '& .apexcharts-xaxis-label': {
      '& tspan': {
        fill: '#9B9B9B'
      }
    }
  }
}))

function Chart ({ props }) {
  const classes = useStyles()
  const getColor = key => {
    if (key === 'Pais') {
      return '#9DD2DA'
    } else if (key === 'Professor') {
      return '#8B598E'
    } else if (key === 'Alunos') {
      return '#386093'
    } else {
      return '#7AC2CD'
    }
  }
  const options = {
    chart: {
      type: 'bar'
    },
    plotOptions: {},
    xaxis: {
      labels: {
        show: true,
        style: {
          cssClass: 'apexcharts-xaxis-label'
        }
      }
    },
    responsive: [
      {
        breakpoint: 850,
        options: {
          chart: {
            width: 350
          }
        }
      },
      {
        breakpoint: 700,
        options: {
          chart: {
            width: 250
          }
        }
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          }
        }
      },
      {
        breakpoint: 321,
        options: {
          chart: {
            width: 170
          }
        }
      }
    ],
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5,
      tooltip: {
        enable: true
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        style: {
          colors: ['#9B9B9B']
        },
        formatter: function (val) {
          return val + '%'
        }
      }
    },
    tooltip: {
      enabled: true,
      intersect: false,
      marker: {
        show: false
      }
    },
    dataLabels: {
      enabled: true,
      position: 'center',
      style: {
        // colors: ['#9B9B9B']
      },
      formatter: function (val, { dataPointIndex }) {
        let value
        if (dataPointIndex === 0) {
          value = props.find(item => item.key === 'Pais').value
        } else if (dataPointIndex === 1) {
          value = props.find(item => item.key === 'Professor').value
        } else if (dataPointIndex === 2) {
          value = props.find(item => item.key === 'Alunos').value
        }
        return value ? `${value['views']} / ${value['recipients']}` : ''
      }
    }
  }

  const series = [
    {
      name: 'Porcentagem',
      data: props?.map(item => {
        const { key, value } = item
        const range =
          parseFloat((value['views'] / value['recipients'] * 100).toFixed(2)) ||
          0
        return {
          x: key,
          y: range > 100 ? 100 : range,
          colors: ['#9B9B9B'],
          fillColor: getColor(key)
        }
      })
    }
  ]
  return (
    <div className={classes.root}>
      <h2>Visualizações</h2>
      <ApexCharts
        options={options}
        series={series}
        type='bar'
        width={450}
        height={300}
      />
    </div>
  )
}

export default Chart
