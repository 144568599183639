import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  reduxForm,
  Form,
  Field,
  formValueSelector,
  change,
  submit,
  destroy,
  getFormNames,
  isDirty
} from 'redux-form'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { CircularProgress, Grid } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import { Box, ButtonStyled } from './styles/CreateModuleForm.style'
import Btn from '../button/Button'
import AsyncSelect from '../shared/AsyncSelect'
import ImagesGrid from '../shared/ImagesGrid'
import Stepper from '../shared/Stepper'
import ListTable from '../shared/ListTable'
import Input from '../shared/Input'
import MaterialInput from '../shared/MaterialInput'
import TextArea from '../shared/TextArea'
import { validDescription } from '../../utils/formUtils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginLeft: 0,
    maxWidth: '1110px',
    maxHeight: '100%'
  },
  headerList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    '& span > h2': {
      fontSize: '2rem'
    },
    '& span > p': {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '8px',
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      padding: '1em 2em',
      textAlign: 'center'
    }
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px',
    marginLeft: theme.spacing(2)
  },
  createBtn: {
    backgroundColor: '#386093',
    color: '#FFFF',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  instructions: {
    background: 'white',
    marginRight: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  stepButton: {
    zIndex: 999
  },
  mobileStepper: {
    background: 'none'
  },
  active: {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  selects: {
    backgroundColor: '#FFF',
    width: '100%',
    '& .MuiInputBase-formControl': {
      maxHeight: 50
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '2px',
      border: 'none'
    }
  },
  contentTable: {
    backgroundColor: '#FFF',
    padding: '1rem 2rem',
    marginBottom: '10px',
    '& p:first-of-type': {
      fontWeight: 'bold'
    },
    '& span': {
      marginRight: '2rem'
    }
  },
  themeTags: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 0.20fr)',
    justifyContent: 'space-between',
    '& div': {
      background: 'lightgray',
      color: '#555555',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '8px',
      padding: '0 1rem',
      '& :last-child': {
        cursor: 'pointer'
      }
    }
  },
  bull: {
    color: theme.palette.primary.dark
  }
}))

const getSteps = {
  1: 'Novo Módulo',
  2: 'Seleção de Temas',
  3: 'Salvar'
}

const validate = values => {
  const errors = {}
  if (!values.title) {
    errors.title = 'Campo Obrigatório *'
  }
  if (!values.grade_id) {
    errors.grade_id = 'Campo Obrigatório *'
  }
  if (!values.cover_id) {
    errors.cover_id = 'Campo Obrigatório *'
  }
  if (!values.description) {
    errors.description = 'Campo Obrigatório *'
  }
  return errors
}

export const CreateModuleForm = ({
  handleSubmit,
  onSubmit,
  submit,
  submitting,
  title,
  touch,
  change,
  initialValues,
  gradeId,
  color,
  description,
  themes,
  submitSucceeded,
  createModule,
  destroy,
  currentModule,
  currentTheme,
  getThemes,
  dirtyForms,
  valid,
  editTheme,
  modules,
  school,
  getModuleById,
  getThemeById,
  clearCurrentTheme,
  themeCoverId,
  value
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(1)
  const [selectedCover, setSelectedCover] = useState(initialValues.cover_id)
  const moduleValues = currentModule.item
  const initialThemes = initialValues.themes_order
    ? initialValues.themes_order.map(theme => String(theme))
    : []
  const [selectedThemes, setSelectedThemes] = useState(initialThemes)
  const gradeValue =
    currentModule?.included &&
    currentModule.included.find(include => include.type === 'grades')
  const [selectGrade, setSelectGrade] = useState(gradeValue)
  useEffect(
    async () => {
      if (activeStep === 2) {
        await getThemes({
          sort: 'id desc',
          hasModule: false,
          hasSchoolId: !!school?.id,
          schoolId: school?.id
        })
      }
      moduleValues?.attributes &&
        moduleValues.attributes.themes_order.length > 0 &&
        (await getThemeById({
          ids: selectedThemes.join(',')
        }))
    },
    [activeStep]
  )

  useEffect(
    async () => {
      if (history.location.state?.from === 'createTheme') {
        setActiveStep(2)
        moduleValues?.attributes &&
          moduleValues.attributes.themes_order.length > 0 &&
          (await getThemeById({
            ids: selectedThemes.join(',')
          }))
      }
    },
    [history]
  )
  useEffect(
    async () => {
      moduleValues?.attributes &&
        moduleValues.attributes.themes_order.length > 0 &&
        getThemeById({ ids: selectedThemes.join(',') })
    },
    [moduleValues, activeStep]
  )

  useEffect(
    () => {
      school && change('school_id', school.id)
    },
    [school]
  )

  const handleClickCover = id => {
    setSelectedCover(id)
    change('cover_id', id)
  }

  const handleCheckThemes = themesIds => {
    setSelectedThemes(themesIds === null ? [] : themesIds)
    change('themes_order', themesIds === null ? [] : themesIds)
  }
  // const handleChange = id => {
  //   const currentIndex = selectedThemes.indexOf(id)
  //   const newChecked = [...selectedThemes]

  //   if (currentIndex === -1) {
  //     newChecked.push(id)
  //   } else {
  //     newChecked.splice(currentIndex, 1)
  //   }
  //   handleCheckThemes(newChecked.length === 0 ? null : newChecked)
  // }

  const handleChangeSelect = (event, input) => {
    const newValue = event ? event.value : null
    change(input.name, newValue)
    setSelectGrade(event)
  }

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 1:
        return (
          <Box className={`${classes.root}`}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <label htmlFor='title' aria-required>
                  <span>Título do Módulo</span>
                  <span className={classes.bull}>&bull;</span>
                </label>
                <Field
                  component={MaterialInput}
                  required
                  name='title'
                  placeholder='Título do Módulo'
                  type='text'
                  allowClear
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label htmlFor='grade_id'>
                  <span>Série</span>
                  <span className={classes.bull}>&bull;</span>
                </label>
                <Field
                  component={AsyncSelect}
                  id='grade_id'
                  name='grade_id'
                  placeholder='Selecione uma série'
                  handleAsyncChange={handleChangeSelect}
                  isSearchable={false}
                  defaultValue={
                    selectGrade
                      ? {
                          label: selectGrade.attributes?.name,
                          value: selectGrade.id
                        }
                      : {}
                  }
                  request={{
                    path: 'grade',
                    params: {
                      'q[school_id_in]': school && school.school_id,
                      'q[m]': 'or',
                      'q[school_id_null]': true
                    }
                  }}
                  touch={touch}
                />
              </Grid>
              <Grid item sm={2}>
                <label htmlFor='color'>
                  <span>Cor</span>
                  <span className={classes.bull}>&bull;</span>
                </label>
                <Field
                  component={Input}
                  name='color'
                  placeholder='Cor'
                  type='color'
                  isForModule
                />
              </Grid>
            </Grid>
            <Grid item sm={10}>
              <label htmlFor='description'>
                <span>Descrição do Módulo</span>
                <span className={classes.bull}>&bull;</span>
              </label>
              <Field
                component={TextArea}
                name='description'
                placeholder='Descrição do Módulo'
                rows='7'
                type='text'
                characterCounter='308'
                validate={[validDescription]}
              />
            </Grid>
            <Grid item sm={10}>
              <label htmlFor='cover_id'>Selecione a imagem de capa</label>
              <ImagesGrid
                handleClick={handleClickCover}
                selected={selectedCover}
              />
              <Field component={Input} name='cover_id' type='number' hidden />
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', gap: 12 }}
            >
              {backButton(() => history.goBack())}
              {submitButton()}
            </div>
          </Box>
        )
      case 2:
        return (
          <Box>
            {/* <div className={classes.themeTags}>
              {selectedThemes?.length && themes.currentItem?.item?.length
                ? selectedThemes.map(item => {
                    const themeTitle = themes.currentItem.item.find(
                      theme => theme.id === item
                    )?.attributes?.title
                    return (
                      <div key={item}>
                        <span>{themeTitle}</span>
                        <HighlightOffIcon onClick={() => handleChange(item)} />
                      </div>
                    )
                  })
                : null}
            </div> */}
            <div className={classes.headerList}>
              <span>
                <h2>Lista de Temas</h2>
                <p>
                  {moduleValues && moduleValues.themes_order?.length > 0
                    ? moduleValues.themes_order?.length + selectedThemes.length
                    : selectedThemes.length}{' '}
                  Temas Selecionados
                </p>
              </span>
              <ButtonStyled>
                <Btn
                  className={classes.createBtn}
                  startIcon={<AddIcon />}
                  onclick={() => {
                    clearCurrentTheme()
                    destroy('createThemeForm')
                    history.push('/themes/create', {
                      from: 'createModule',
                      title: title
                    })
                  }}
                >
                  Criar tema
                </Btn>
              </ButtonStyled>
            </div>
            <ListTable
              data={themes.themes}
              name='themes_order'
              handleCheck={handleCheckThemes}
              fetchItems={getThemes}
              param={{ hasModule: false }}
              selected={selectedThemes}
              defaultValue={currentTheme && currentTheme.item}
            />
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', gap: 12 }}
            >
              {backButton(handleBack)}
              {submitButton()}
            </div>
          </Box>
        )
      case 3:
        return (
          <Box>
            <h2>Resumo</h2>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <h3>Título do Módulo</h3>
                <p>{title || moduleValues?.attributes?.title}</p>
                <h3>Série</h3>
                <p>{gradeValue?.attributes?.name || selectGrade?.label}</p>
                <h3>Descrição</h3>
                <p>{description || moduleValues?.attributes?.description}</p>
                <h3>Cor</h3>
                <input
                  type='color'
                  value={moduleValues?.attributes?.color || color}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <h3>{selectedThemes?.length} Temas Selecionados</h3>
                {currentTheme.item &&
                  currentTheme.item.length > 0 &&
                  currentTheme.item
                    .filter(
                      theme => selectedThemes.indexOf(String(theme.id)) !== -1
                    )
                    .map(theme => (
                      <p key={theme.id}>{theme?.attributes?.title}</p>
                    ))}
                {(history.location.state?.from === 'createTheme' ||
                  !currentTheme.item) &&
                  themes.themes?.data &&
                  themes.themes.data
                    .filter(
                      theme => selectedThemes.indexOf(String(theme.id)) !== -1
                    )
                    .map(theme => (
                      <p key={theme.id}>{theme.attributes?.title}</p>
                    ))}
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', gap: 12 }}
            >
              {backButton(handleBack)}
              {finishedButton('Salvar Módulo')}
            </div>
          </Box>
        )
      default:
        return ''
    }
  }

  const submitButton = () => (
    <Button
      variant='contained'
      className={classes.createBtn}
      disabled={!valid || modules.isFetching}
      onClick={async () => {
        await submit()
        handleNext()
      }}
    >
      Salvar
    </Button>
  )

  const finishedButton = (name, action) => (
    <Button
      name='btnNext'
      type='button'
      className={classes.createBtn}
      onClick={async () => {
        await dirtyForms.map(form => destroy(form))
        history.goBack()
      }}
    >
      {name}
    </Button>
  )

  const backButton = action => (
    <Button
      className={classes.backBtn}
      name='btnback'
      type='button'
      variant='outlined'
      onClick={action}
    >
      Voltar
    </Button>
  )

  const handleNext = () => {
    (!modules.isFetching || !modules.isError) &&
      title &&
      gradeId &&
      description &&
      color &&
      themeCoverId &&
      setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} steps={getSteps} totalSteps={3} />
      <div>
        <div className={classes.instructions}>
          {modules.isFetching ? (
            <CircularProgress color='secondary' />
          ) : (
            <Form
              initialValues={initialValues}
              onSubmit={handleSubmit(onSubmit)}
            >
              {getStepContent(activeStep)}
            </Form>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('createModuleForm')
  const dirtyForms = getFormNames()(state).filter(form => isDirty(form)(state))
  const { initialValues } = props
  const themeCoverId = selector(state, 'cover_id')
  const initValues = {
    title: initialValues?.title,
    grade_id: initialValues && String(initialValues.grade_id),
    description: initialValues?.description,
    color: initialValues?.color || '#000000',
    cover_id: (initialValues && String(initialValues.cover_id)) || themeCoverId,
    themes_order: initialValues && initialValues.themes_order
  }
  return {
    dirtyForms,
    initialValues: initValues,
    title: selector(state, 'title'),
    gradeId: selector(state, 'grade_id'),
    description: selector(state, 'description'),
    themeCoverId: themeCoverId,
    color: selector(state, 'color'),
    themes_order: selector(state, 'themes_order')
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('createModuleForm', field, data)),
  submit: () => dispatch(submit('createModuleForm')),
  destroy: formname => dispatch(destroy(formname))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'createModuleForm',
    validate,
    validateOnBlur: true,
    touchOnChange: true,
    touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {
      const currentModule = props.currentModule?.item
      try {
        if (currentModule) {
          if (!props.pristine) {
            await props.editModule({
              id: currentModule.id,
              data: { ...values }
            })
            props.initialValues &&
              props.initialValues.themes_order?.length > 0 &&
              props.initialValues.themes_order.map(
                theme =>
                  values.themes_order?.findIndex(
                    x => String(x) === String(theme)
                  ) === -1 &&
                  props.editTheme({
                    id: theme,
                    data: { theme_module_id: null }
                  })
              )
            ;(await values.themes_order) &&
              values.themes_order.map(
                theme =>
                  theme &&
                  props.editTheme({
                    id: theme,
                    data: { theme_module_id: currentModule.id }
                  })
              )
          }
        } else {
          Promise.all([
            values.title && props.createModule(values),
            values.themes_order &&
              values.themes_order.map(theme =>
                props.editTheme({
                  id: theme,
                  data: { theme_module_id: currentModule.id }
                })
              )
          ])
        }
      } catch (err) {
        console.error(err)
      }
    }
  })(CreateModuleForm)
)
