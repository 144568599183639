import { createAction } from '@reduxjs/toolkit'
import { createPromiseAction } from 'redux-saga-promise-actions'

export const getUserById = createAction('users/GET_USER_BY_ID')
export const getUserByIdSuccessful = createAction(
  'users/GET_USER_BY_ID_SUCCESSFUL'
)
export const getUserByIdFailure = createAction('users/GET_USER_BY_ID_FAILURE')

export const getUsersHistory = createAction('users/GET_USERS_HISTORY')
export const getUsersHistorySuccessful = createAction(
  'users/GET_USERS_HISTORY_SUCCESSFUL'
)
export const getUsersHistoryFailure = createAction(
  'users/GET_USERS_HISTORY_FAILURE'
)

export const exportUsersHistoryRSPA = createPromiseAction(
  'rspa: EXPORT_USERS_HISTORY'
)()

export const getUsers = createPromiseAction('rspa: GET_USERS')()
export const clearCurrentUser = createAction('users/CLEAR_CURRENT_USER')

export const forceTemporaryPasswordRequest = createAction(
  'users/FORCE_TEMPORARY_PASSWORD_REQUEST'
)
export const forceTemporaryPasswordSuccess = createAction(
  'users/FORCE_TEMPORARY_PASSWORD_SUCCESS'
)
export const forceTemporaryPasswordFailure = createAction(
  'users/FORCE_TEMPORARY_PASSWORD_FAILURE'
)
