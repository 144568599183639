import { createReducer } from '@reduxjs/toolkit'
import * as types from './types'

const initialState = {
  items: [],
  isFetching: false,
  isError: false,
  errorMessage: '',
  currentItem: {},
  isSuccess: false,
  isUpdateSuccess: false,
  pagination: {
    total: 1,
    pageCount: 1
  }
}

const communicationsReducer = createReducer(initialState, {
  [types.GET_COMMUNICATIONS]: (state, action) => ({
    ...state,
    items: [],
    isFetching: true,
    isError: false,
    isSuccess: false,
    isUpdateSuccess: false,
    errorMessage: ''
  }),
  [types.GET_COMMUNICATIONS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: action.payload.data,
    pagination: {
      total: action.payload.meta.total_count,
      pageCount: action.payload.meta.page_count
    },
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_COMMUNICATIONS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.CREATE_COMMUNICATION]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    isSuccess: false,
    isUpdateSuccess: false,
    errorMessage: ''
  }),
  [types.CREATE_COMMUNICATION_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: action.payload.data,
    isFetching: false,
    isSuccess: true,
    isError: false,
    errorMessage: ''
  }),
  [types.CREATE_COMMUNICATION_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_COMMUNICATION_BY_ID]: (state, action) => ({
    ...state,
    currentItem: {},
    isFetching: true,
    isError: false,
    isSuccess: false,
    isUpdateSuccess: false,
    errorMessage: ''
  }),
  [types.GET_COMMUNICATION_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: {
      ...action.payload.data[0].communication,
      messages: action.payload.data
    },
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_COMMUNICATION_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.UPDATE_COMMUNICATION]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    isSuccess: false,
    isUpdateSuccess: false,
    errorMessage: ''
  }),
  [types.UPDATE_COMMUNICATION_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: state.items.map(
      item =>
        item.id === String(action.payload.data.id)
          ? { ...item, ...action.payload.data.attributes }
          : item
    ),
    currentItem: { ...state.currentItem, ...action.payload.data.attributes },
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.UPDATE_COMMUNICATION_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.UPDATE_MESSAGE]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    isSuccess: false,
    isUpdateSuccess: false,
    errorMessage: ''
  }),
  [types.UPDATE_MESSAGE_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      messages: state.currentItem.messages.map(
        message =>
          message.id !== action.payload.data.id
            ? message
            : Object.assign({}, message, action.payload.data.attributes)
      )
    },
    isUpdateSuccess: true,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.UPDATE_MESSAGE_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.RESPOND_MESSAGES]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    isSuccess: false,
    isUpdateSuccess: false,
    errorMessage: ''
  }),
  [types.RESPOND_MESSAGES_SUCCESSFUL]: (state, action) => ({
    ...state,
    currentItem: {
      ...state.currentItem,
      messages: state.currentItem.messages.map(
        message =>
          message.id !== action.payload.data.id
            ? message
            : Object.assign({}, message, action.payload.data.attributes)
      )
    },
    isFetching: false,
    isError: false,
    isSuccess: true,
    isUpdateSuccess: true,
    errorMessage: ''
  }),
  [types.RESPOND_MESSAGES_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.CLEAN_SUCCESS]: (state, action) => ({
    isSuccess: false
  })
})

export default communicationsReducer
