import { createAction } from '@reduxjs/toolkit'
import * as types from './types'

export const getCovers = createAction(types.GET_COVERS)
export const getCoversSuccessful = createAction(types.GET_COVERS_SUCCESSFUL)
export const getCoversFailure = createAction(types.GET_COVERS_FAILURE)

export const uploadCovers = createAction(types.UPLOAD_COVERS)
export const uploadCoversSuccessful = createAction(
  types.UPLOAD_COVERS_SUCCESSFUL
)
export const uploadCoversFailure = createAction(types.UPLOAD_COVERS_FAILURE)
