import { all, call, takeLatest, put } from 'redux-saga/effects'
import { showAlertMessage } from '../alert/actions'
import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'
import { frequencyTags } from '../../utils/constants'

import * as actions from './actions'
import * as services from './services'
import moment from 'moment'

export function * fetchReportRequest ({ payload }) {
  try {
    const response = yield call(services.fetchReports, payload)
    yield put(actions.getReportsSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getReportsFailure(error))
  }
}

export function * fetchReportTemplateByIdRequest ({ payload }) {
  try {
    const response = yield call(services.fetchReportTemplateById, payload)
    yield put(actions.getReportTemplateByIdSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getReportByIdFailure(error))
  }
}
function * getAllReportStudents (params) {
  try {
    let currentPage = 1
    const responseAnswers = yield call(services.fetchReportStudents, {
      page: currentPage,
      ...params
    })
    let fullResponseAnswers = { ...responseAnswers }
    while (
      fullResponseAnswers.data.length < fullResponseAnswers.meta.total_count
    ) {
      currentPage += 1
      let responseForNextPage = yield call(services.fetchReportStudents, {
        page: currentPage,
        ...params
      })
      fullResponseAnswers.data = [
        ...fullResponseAnswers.data,
        ...responseForNextPage.data
      ]
    }
    return fullResponseAnswers
  } catch (error) {
    console.error(error)
  }
}
export function * fetchReportByIdRequest ({ payload }) {
  try {
    const response = yield call(services.fetchReportById, { id: payload.id })
    const params = { 'q[report_id_eq]': payload.id }
    let responseStudents
    if (payload.getWithAllStudents === true) {
      responseStudents = yield call(getAllReportStudents, {
        params
      })
    } else {
      responseStudents = yield call(services.fetchReportStudents, {
        params
      })
    }
    yield all(
      responseStudents.data.map(function * (reportStudent) {
        const answers = yield call(getReportStudentAnswerById, {
          id: reportStudent.id
        })
        return Object.assign(reportStudent, { answers: answers.data })
      })
    )
    yield put(
      actions.getReportByIdSuccessful({
        report: response,
        studentsReport: responseStudents
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.getReportByIdFailure(error))
  }
}

export function * createReportRequest ({ payload }) {
  try {
    const response = yield call(services.createReport, payload)
    yield put(
      showAlertMessage({
        message: 'Diário criado sucesso.',
        severity: 'success'
      })
    )
    yield put(actions.createReportsSuccessful(response))
  } catch (error) {
    yield put(actions.createReportsFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao criar um diário.',
        severity: 'error'
      })
    )
  }
}

export function * fetchReportStudentsRequest ({ payload }) {
  try {
    const response = yield call(services.fetchReportStudents, payload)
    yield put(actions.getReportStudentsSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getReportStudentsFailure(error))
  }
}

function * getReportStudentAnswerById ({ id }) {
  try {
    let currentPage = 1
    const responseAnswers = yield call(services.fetchReportStudentAnswersById, {
      id: id,
      page: currentPage
    })
    let fullResponseAnswers = { ...responseAnswers }
    while (
      fullResponseAnswers.data.length < fullResponseAnswers.meta.total_count
    ) {
      currentPage += 1
      let responseForNextPage = yield call(
        services.fetchReportStudentAnswersById,
        {
          page: currentPage,
          id
        }
      )
      fullResponseAnswers.data = [
        ...fullResponseAnswers.data,
        ...responseForNextPage.data
      ]
    }
    return fullResponseAnswers
  } catch (error) {
    console.error(error)
  }
}

export function * fetchReportStudentByIdRequest ({ payload }) {
  try {
    const response = yield call(services.fetchReportStudentById, payload)
    const responseAnswers = yield call(getReportStudentAnswerById, payload)
    yield put(
      actions.getReportStudentByIdSuccessful({
        ...response,
        answers: responseAnswers.data
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.getReportStudentByIdFailure(error))
  }
}

export function * fetchReportOptionsByIdRequest ({ payload }) {
  try {
    let currentPage = 1
    const response = yield call(services.fetchReportOptionsById, {
      page: currentPage,
      ...payload
    })
    let fullResponse = { ...response }
    while (fullResponse.data.length < fullResponse.meta.total_count) {
      currentPage += 1
      let responseForNextPage = yield call(services.fetchReportOptionsById, {
        page: currentPage,
        ...payload
      })
      fullResponse.data = [...fullResponse.data, ...responseForNextPage.data]
    }
    yield put(actions.getReportOptionsByIdSuccessful(fullResponse.data))
  } catch (error) {
    console.error(error)
    yield put(actions.getReportOptionsByIdFailure(error))
  }
}

export function * createReportStudentRequest ({ payload }) {
  try {
    yield all(
      Object.entries(payload.answers).map(([key, value]) => {
        return call(services.createReportStudentAnswers, {
          topic: key,
          answer: value.answer,
          report_student_id: payload.reportStudentId,
          tag_id: value.tag_id
        })
      })
    )
    yield put(actions.createReportStudentSuccessful({}))
    yield put(
      showAlertMessage({
        message: 'Diário do estudante enviado.',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.createReportStudentFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao enviar diário.',
        severity: 'error'
      })
    )
  }
}

export function * editReportStudentByClassroomRequest ({ payload }) {
  try {
    const {
      values,
      formAnswers,
      studentFiles,
      studentIds,
      filesToDelete,
      ...props
    } = payload
    // eslint-disable-next-line camelcase

    const studentAnswersDueDate = Object.entries(values).filter(
      ([key, value]) => key === 'fieldsDates'
    )

    const frequencyType = props?.reports?.reportOptions?.[0]?.tag?.name
    // exclusão de arquivos
    if (filesToDelete?.length > 0) {
      yield all(
        filesToDelete.map(item => {
          return call(services.deleteReportFile, item)
        })
      )
    }

    // adição de arquivos
    const reportFiles = yield all(
      studentFiles?.map(function * (item) {
        return yield all(
          item.files.map(function * (fileUnity) {
            const fd = new FormData()
            fd.append('data[type]', 'report_files')
            fd.append('data[attributes][file]', fileUnity)
            fd.append('data[attributes][tag_id]', item.tagId)

            const reportFile = yield call(services.createReportFile, fd)
            Object.assign(item, { reportFileId: reportFile?.data?.id })

            return reportFile
          })
        )
      })
    )
    const flattenedReportFiles = reportFiles?.flat()

    // associação de arquivos aos estudantes
    yield all(
      flattenedReportFiles?.map(function * (item) {
        const newData = {
          type: 'report_files',
          id: item.data.id,
          attributes: {
            report_student_files_attributes: studentIds.map(student => {
              return { report_student_id: student }
            })
          }
        }

        return yield call(services.editReportFile, {
          id: item.data.id,
          data: newData
        })
      })
    )

    // resposta dos relatórios
    yield all(
      formAnswers.map((repStudent, idx) => {
        const reportStudentId = repStudent[0].split('_')[1]
        const reportStudentAnswers = repStudent[1]
        const reportStudentDueDates = studentAnswersDueDate.find(
          dueDatesObject => dueDatesObject[0] === `fieldsDates`
        )?.[1]
        const currentReportStudent = props.reports?.reportStudents?.items?.find(
          rpStudent => +rpStudent.id === +reportStudentId
        )

        delete reportStudentAnswers?.files
        delete reportStudentAnswers?.reportFiles
        const newValues = {
          id: reportStudentId,
          data: {
            user_id: props.userId,
            child_id: currentReportStudent.child_id,
            report_id: currentReportStudent.report_id,
            status: 'done',
            frequency:
              frequencyType === frequencyTags['frequencyForAllName']
                ? reportStudentAnswers?.[frequencyTags['frequencyForAll']]
                    ?.answer === 'Presente'
                : reportStudentAnswers?.[
                    `${frequencyTags['frequencyForSubject']}-${[
                      values?.subjectValue?.label
                    ]}`
                  ]?.answer === 'Presente',
            report_student_files_attributes: studentFiles
              .filter(item => +item.reportStudentId === +reportStudentId)
              .map(item => {
                if (item?.reportFileId) {
                  return { report_file_id: item?.reportFileId }
                }
              }),
            report_student_answers_attributes: Object.entries(
              reportStudentAnswers
            ).map(([key, value]) => {
              const dueDate = reportStudentDueDates?.[`${key}-due_date`]

              const valuesToRequest = {
                // não envia o id para criar varias respostas
                // id: reportAnsewrsId,
                current_answer: true,
                topic: value.topic,
                answer: value.answer,
                report_student_id: reportStudentId,
                multiple_answers: value.multiple_answers,
                tag_id: value.tag_id,
                due_date: dueDate && moment(dueDate).format('YYYY-MM-DD'),
                subject_id: value.subject_id,
                user_id: value.user_id
              }
              return { ...valuesToRequest }
            })
          }
        }
        // if (
        //   props.reports.currentItem.status === 'waiting_moderation' &&
        //   (props.userOccupations?.includes(roles.SCHOOL_ADMIN) ||
        //     props.userOccupations?.includes(roles.VERSAR_ADMIN))
        // ) {
        //   // frequência nao importa na moderação e por nao ter uma disciplina selecionada, o campo frequency pode quebrar o envio
        //   delete newValues.data.frequency
        // }
        return call(services.editReportStudent, newValues)
      })
    )

    yield put(actions.editReportStudentSuccessful(payload))
    yield put(
      showAlertMessage({
        message: 'Diário enviado.',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.editReportStudentFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao enviar diário.',
        severity: 'error'
      })
    )
  }
}

export function * editReportStudentRequest ({ payload }) {
  try {
    yield call(services.editReportStudent, payload)
    yield put(actions.editReportStudentSuccessful(payload))
    yield put(
      showAlertMessage({
        message: 'Diário enviado.',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.editReportStudentFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao enviar diário.',
        severity: 'error'
      })
    )
  }
}

export function * editReportTemplateRequest ({ payload }) {
  try {
    yield call(services.editReport, payload)
    yield put(actions.editReportTemplateSuccessful(payload))
    yield put(
      showAlertMessage({
        message: 'Diário editado com sucesso.',
        severity: 'success'
      })
    )
  } catch (error) {
    yield put(actions.editReportTemplateFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao enviar diário.',
        severity: 'error'
      })
    )
  }
}

export function * fetchReportsRequest ({ payload }) {
  try {
    const response = yield call(services.generateReportsRequest, payload)
    yield put(actions.generateReportsSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.generateReportsFailure(error))
  }
}

export function * fetchGeneratedReportsByIdRequest ({ payload }) {
  try {
    const response = yield call(services.getGeneratedReport, payload)
    const today = moment(new Date().toISOString()).format('DD/MM/YYYY')
    const reportDay = response.data.filter(
      res => moment(res.attributes.created_at).format('DD/MM/YYYY') === today
    )
    if (reportDay[0]) {
      yield put(
        actions.getGeneratedReportsByIdSuccessful({
          reportsFromTemplates: response,
          reportDay: reportDay[0]
        })
      )
    } else {
      yield put(
        actions.getGeneratedReportsByIdSuccessful({
          reportsFromTemplates: response
        })
      )
    }
  } catch (error) {
    console.error(error)
    yield put(actions.getGeneratedReportsByIdFailure(error))
  }
}

export function * fetchLastTeacherByReportRSPARequest (action) {
  try {
    const { payload: { reportId } } = action
    const response = yield call(services.fetchLastTeacherByReport, reportId)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * exportReportRSPARequest (action) {
  try {
    const { payload: { data } } = action
    const response = yield call(services.exportReport, data)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * watchFetchReports () {
  yield takeLatest(actions.getReports, fetchReportRequest)
  yield takeLatest(actions.generateReports, fetchReportsRequest)
  yield takeLatest(
    actions.getGeneratedReportsById,
    fetchGeneratedReportsByIdRequest
  )
  yield takeLatest(actions.getReportById, fetchReportByIdRequest)
  yield takeLatest(actions.createReports, createReportRequest)
  yield takeLatest(actions.getReportStudentById, fetchReportStudentByIdRequest)
  yield takeLatest(actions.createReportStudent, createReportStudentRequest)
  yield takeLatest(
    actions.editReportStudentByClassroom,
    editReportStudentByClassroomRequest
  )
  yield takeLatest(actions.editReportStudent, editReportStudentRequest)
  yield takeLatest(actions.getReportOptionsById, fetchReportOptionsByIdRequest)
  yield takeLatest(actions.getReportStudents, fetchReportStudentsRequest)
  yield takeLatest(
    actions.getReportTemplateById,
    fetchReportTemplateByIdRequest
  )
  yield takeLatest(actions.editReportTemplate, editReportTemplateRequest)
  yield takeLatest(
    actions.getLastTeacherByReportRSPA.request,
    fetchLastTeacherByReportRSPARequest
  )
  yield takeLatest(actions.exportReportRSPA.request, exportReportRSPARequest)
}

export default function * reportsSagas () {
  yield all([watchFetchReports()])
}
