import { all, call, takeLatest, put } from 'redux-saga/effects'

import * as actions from './actions'
import * as services from './services'

export function * fetchCycles ({ payload }) {
  try {
    const response = yield call(services.fetchCycles, payload)
    yield put(actions.getCyclesSuccessful(response))
  } catch (error) {
    yield put(actions.getCyclesFailure(error))
  }
}

export function * watchFetchCycles () {
  yield takeLatest(actions.getCycles, fetchCycles)
}

export default function * coversSagas () {
  yield all([watchFetchCycles()])
}
