import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Loading from '../shared/Loading'

const { REACT_APP_PROJECT_LOGO } = process.env

const useStyles = makeStyles(theme => ({
  container: {
    boxSizing: 'border-box',
    background: '#E8E8DB',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    width: '100%'
  },
  childContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'white',
    padding: '60px 80px',
    height: '100%'
  },
  logo: {
    width: '300px',
    marginBottom: '30px'
  }
}))
const VersarPage = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <section className={classes.childContainer}>
        <img
          src={REACT_APP_PROJECT_LOGO}
          className={classes.logo}
          alt='Logo do versar'
        />
        <Loading />
      </section>
    </div>
  )
}

export default VersarPage
