import { all, call, takeLatest, put } from 'redux-saga/effects'
import { jsonToFormdata } from '../../utils/mappers'
import { showAlertMessage } from '../alert/actions'
import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'

import * as actions from './actions'
import * as services from './services'

export function * fetchCommunicationsRequest ({ payload }) {
  try {
    const response = yield call(services.fetchCommunications, payload)
    yield put(actions.getCommunicationsSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getCommunicationsFailure(error))
  }
}

export function * createCommunicationRequest ({ payload }) {
  try {
    const fd = new FormData()
    fd.append('data[type]', 'communications')
    yield jsonToFormdata(fd, payload)
    const response = yield call(services.createCommunication, fd)
    yield put(actions.createCommunicationSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.createCommunicationFailure(error))
  }
}

export function * editMessageCommunicationRequest ({ payload }) {
  try {
    const fd = new FormData()
    fd.append('data[type]', 'messages')
    fd.append('data[id]', payload.id)
    yield jsonToFormdata(fd, payload.data)
    if (payload.filesToDelete && payload.filesToDelete.length > 0) {
      yield all(
        payload.filesToDelete.map(filesId => {
          return call(services.deleteMessageFile, filesId)
        })
      )
    }

    const response = yield call(services.editCommunicationMessage, {
      id: payload.id,
      data: fd
    })
    yield put(actions.updateMessageSuccessful(response))
    yield put(
      showAlertMessage({
        message:
          payload?.data?.status === 'refused'
            ? 'A mensagem foi recusada.'
            : 'A mensagem foi aprovada com sucesso.',
        severity: 'success'
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.updateMessageFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha, tente novamente mais tarde.',
        severity: 'error'
      })
    )
  }
}

export function * updateCommunicationRequest ({ payload }) {
  try {
    const response = yield call(services.updateCommunication, {
      id: payload.id,
      type: 'communications',
      attributes: { active: payload.active, status: payload.status }
    })
    yield put(actions.updateCommunicationSuccessful(response))
    yield put(
      showAlertMessage({
        message: 'O status da conversa foi alterado.',
        severity: 'success'
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.updateCommunicationFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao alterar o status, tente novamente mais tarde.',
        severity: 'error'
      })
    )
  }
}

export function * respondMessageRequest ({ payload }) {
  try {
    const fd = new FormData()
    fd.append('data[type]', 'messages')
    yield jsonToFormdata(fd, payload)
    payload.files &&
      payload.files.forEach(file => {
        fd.append('data[attributes][files_attributes][][file]', file)
      })
    const response = yield call(services.createMessage, fd)
    yield put(actions.respondMessagesSuccessful(response))
    yield put(
      showAlertMessage({
        message: 'Mensagem enviada com sucesso!',
        severity: 'success'
      })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.respondMessagesFailure(error))
    yield put(
      showAlertMessage({
        message: 'Falha ao responder mensagem, tente novamente mais tarde.',
        severity: 'error'
      })
    )
  }
}

export function * fetchCommunicationByIdRequest ({ payload }) {
  try {
    const response = yield call(services.fetchMessageByCommunicationId, payload)
    yield put(actions.getCommunicationsByIdSuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getCommunicationsByIdFailure(error))
  }
}

export function * fetchPendingCommunicationsRSPARequest (action) {
  try {
    const { payload: { userId, schoolId } } = action
    const response = yield call(services.fetchPendingCommunications, {
      userId,
      schoolId
    })
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * fetchDestinationsByCommunicationRSPARequest (action) {
  try {
    const { payload: { communicationId } } = action
    const response = yield call(
      services.fetchDestinationsByCommunication,
      communicationId
    )
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * watchFetchCommunications () {
  yield takeLatest(actions.getCommunications, fetchCommunicationsRequest)
  yield takeLatest(actions.createCommunication, createCommunicationRequest)
  yield takeLatest(actions.getCommunicationsById, fetchCommunicationByIdRequest)
  yield takeLatest(actions.updateCommunication, updateCommunicationRequest)
  yield takeLatest(actions.updateMessage, editMessageCommunicationRequest)
  yield takeLatest(actions.respondMessages, respondMessageRequest)
  yield takeLatest(
    actions.getPendingCommunicationsRSPA.request,
    fetchPendingCommunicationsRSPARequest
  )
  yield takeLatest(
    actions.getDestinationsByCommunicationRSPA.request,
    fetchDestinationsByCommunicationRSPARequest
  )
}

export default function * communicationsSaga () {
  yield all([watchFetchCommunications()])
}
