export const GET_CLASSROOMS = 'classrooms/GET_CLASSROOMS'
export const GET_CLASSROOMS_SUCCESSFUL = 'classrooms/GET_CLASSROOMS_SUCCESSFUL'
export const GET_CLASSROOMS_FAILURE = 'classrooms/GET_CLASSROOMS_FAILURE'

export const GET_CLASSROOM_BY_ID = 'classrooms/GET_CLASSROOM_BY_ID'
export const GET_CLASSROOM_BY_ID_SUCCESSFUL =
  'classrooms/GET_CLASSROOM_BY_ID_SUCCESSFUL'
export const GET_CLASSROOM_BY_ID_FAILURE =
  'classrooms/GET_CLASSROOM_BY_ID_FAILURE'

export const GET_CLASSROOM_RECORDS = 'classrooms/GET_CLASSROOM_RECORDS'
export const GET_CLASSROOM_RECORDS_SUCCESSFUL =
  'classrooms/GET_CLASSROOM_RECORDS_SUCCESSFUL'
export const GET_CLASSROOM_RECORDS_FAILURE =
  'classrooms/GET_CLASSROOM_RECORDS_FAILURE'

export const CREATE_CLASSROOM = 'classrooms/CREATE_CLASSROOM'
export const CREATE_CLASSROOM_SUCCESSFUL =
  'classrooms/CREATE_CLASSROOMS_SUCCESSFUL'
export const CREATE_CLASSROOM_FAILURE = 'classrooms/CREATE_CLASSROOM_FAILURE'

export const EDIT_CLASSROOM = 'classrooms/EDIT_CLASSROOM'
export const EDIT_CLASSROOM_SUCCESSFUL = 'classrooms/EDIT_CLASSROOMS_SUCCESSFUL'
export const EDIT_CLASSROOM_FAILURE = 'classrooms/EDIT_CLASSROOM_FAILURE'

export const GET_CLASSROOM_CHILDREN = 'classrooms/GET_CLASSROOM_CHILDREN'
export const GET_CLASSROOM_CHILDREN_SUCCESSFUL =
  'classrooms/GET_CLASSROOM_CHILDREN_SUCCESSFUL'
export const GET_CLASSROOM_CHILDREN_FAILURE =
  'classrooms/GET_CLASSROOM_CHILDREN_FAILURE'

export const CREATE_CLASSROOM_CHILDREN = 'classrooms/CREATE_CLASSROOM_CHILDREN'
export const CREATE_CLASSROOM_CHILDREN_SUCCESSFUL =
  'classrooms/CREATE_CLASSROOM_CHILDREN_SUCCESSFUL'
export const CREATE_CLASSROOM_CHILDREN_FAILURE =
  'classrooms/CREATE_CLASSROOM_CHILDREN_FAILURE'

export const EDIT_CLASSROOM_CHILDREN = 'classrooms/EDIT_CLASSROOM_CHILDREN'
export const EDIT_CLASSROOM_CHILDREN_SUCCESSFUL =
  'classrooms/EDIT_CLASSROOM_CHILDREN_SUCCESSFUL'
export const EDIT_CLASSROOM_CHILDREN_FAILURE =
  'classrooms/EDIT_CLASSROOM_CHILDREN_FAILURE'

export const GET_CLASSROOMS_TEACHERS = 'classrooms/GET_CLASSROOMS_TEACHERS'
export const GET_CLASSROOMS_TEACHERS_SUCCESSFUL =
  'classrooms/GET_CLASSROOMS_TEACHERS_SUCCESSFUL'
export const GET_CLASSROOMS_TEACHERS_FAILURE =
  'classrooms/GET_CLASSROOMS_TEACHERS_FAILURE'

export const GET_CLASSROOM_TEACHER_BY_ID =
  'classrooms/GET_CLASSROOM_TEACHER_BY_ID'
export const GET_CLASSROOM_TEACHER_BY_ID_SUCCESSFUL =
  'classrooms/GET_CLASSROOM_TEACHER_BY_ID_SUCCESSFUL'
export const GET_CLASSROOM_TEACHER_BY_ID_FAILURE =
  'classrooms/GET_CLASSROOM_TEACHER_BY_ID_FAILURE'

export const CREATE_CLASSROOM_TEACHER = 'classrooms/CREATE_CLASSROOM_TEACHER'
export const CREATE_CLASSROOM_TEACHER_SUCCESSFUL =
  'classrooms/CREATE_CLASSROOM_TEACHER_SUCCESSFUL'
export const CREATE_CLASSROOM_TEACHER_FAILURE =
  'classrooms/CREATE_CLASSROOM_TEACHER_FAILURE'

export const EDIT_CLASSROOM_TEACHER = 'classrooms/EDIT_CLASSROOM_TEACHER'
export const EDIT_CLASSROOM_TEACHER_SUCCESSFUL =
  'classrooms/EDIT_CLASSROOM_TEACHER_SUCCESSFUL'
export const EDIT_CLASSROOM_TEACHER_FAILURE =
  'classrooms/EDIT_CLASSROOM_TEACHER_FAILURE'

export const CREATE_CHILDREN_WITH_PARENTS =
  'classrooms/CREATE_CHILDREN_WITH_PARENTS'
export const CREATE_CHILDREN_WITH_PARENTS_SUCCESSFUL =
  'classrooms/CREATE_CHILDREN_WITH_PARENTS_SUCCESSFUL'
export const CREATE_CHILDREN_WITH_PARENTS_FAILURE =
  'classrooms/CREATE_CHILDREN_WITH_PARENTS_FAILURE'

export const REMOVE_CLASSROOM_CHILD = 'classrooms/REMOVE_CLASSROOM_CHILD'
export const REMOVE_CLASSROOM_CHILD_SUCCESSFUL =
  'classrooms/REMOVE_CLASSROOM_CHILD_SUCCESSFUL'
export const REMOVE_CLASSROOM_CHILD_FAILURE =
  'classrooms/REMOVE_CLASSROOM_CHILD_FAILURE'

export const CLEAR_CURRENT_CLASSROOM = 'classrooms/CLEAR_CURRENT_CLASSROOM'
