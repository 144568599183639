import { createReducer } from '@reduxjs/toolkit'
import * as types from './types'

const initialState = {
  items: [],
  isFetching: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  currentItem: {},
  pagination: {
    total: 1,
    page: 1,
    pageCount: 1
  }
}

const materialsReducer = createReducer(initialState, {
  [types.GET_MATERIALS]: (state, action) => ({
    ...state,
    items: [],
    currentItem: {},
    isFetching: true,
    isSuccess: false
  }),
  [types.GET_MATERIALS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: action.payload.data,
    pagination: {
      total: action.payload.meta.total_count,
      pageCount: action.payload.meta.page_count
    },
    isFetching: false
  }),
  [types.GET_MATERIALS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_MATERIAL_BY_ID]: (state, action) => ({
    ...state,
    items: [],
    currentItem: {},
    destinations: [],
    isFetching: true
  }),
  [types.GET_MATERIAL_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    currentItem: action.payload.data
  }),
  [types.GET_MATERIAL_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.CREATE_MATERIALS]: (state, action) => ({
    ...state,
    items: [],
    currentItem: {},
    isFetching: true,
    isSuccess: false
  }),
  [types.CREATE_MATERIALS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: [...state.items, action.payload],
    isFetching: false,
    isSuccess: true
  }),
  [types.CREATE_MATERIALS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    isSuccess: false,
    errorMessage: action.payload
  }),
  [types.DELETE_MATERIAL]: (state, action) => ({
    ...state,
    isFetching: true
  }),
  [types.DELETE_MATERIAL_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: state.items.filter(
      item => String(item.id) !== String(action.payload)
    ),
    isFetching: false
  }),
  [types.DELETE_MATERIAL_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.EDIT_MATERIAL]: (state, action) => ({
    ...state,
    isFetching: true,
    isSuccess: false
  }),
  [types.EDIT_MATERIAL_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: state.items.filter(
      item => String(item.id) !== String(action.payload)
    ),
    isFetching: false,
    isSuccess: true
  }),
  [types.EDIT_MATERIAL_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    isSuccess: false,
    errorMessage: action.payload
  }),
  [types.SET_CURRRENT_MATERIAL]: (state, action) => ({
    ...state,
    currentItem: action.payload,
    isFetching: false
  }),
  [types.CLEAN_CURRRENT_MATERIAL]: (state, action) => ({
    ...initialState
  })
})

export default materialsReducer
