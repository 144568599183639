import React from 'react'
import ApexCharts from 'react-apexcharts'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // width: '300px',
    '& h4, h2': {
      color: '#386093'
    },
    padding: 16,
    borderRadius: 16,
    backgroundColor: '#F1F3F4'
  }
}))

function TotallyViewChart ({ props }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <h2>Total de entregues</h2>
      <ApexCharts
        options={{
          chart: {
            type: 'donut'
          },
          colors: ['#386093'],
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          labels: ['Entregues'],
          plotOptions: {
            pie: {
              donut: {
                size: '70%',
                labels: {
                  show: true,
                  name: {
                    show: false
                  },
                  value: {
                    show: true,
                    color: '#386093',
                    formatter: function (val) {
                      return val + '%'
                    }
                  }
                },
                customScale: 0.8
              }
            }
          },
          responsive: [
            {
              breakpoint: 700,
              options: {
                chart: {
                  width: 250
                }
              }
            },
            {
              breakpoint: 630,
              options: {
                chart: {
                  width: 200
                }
              }
            },
            {
              breakpoint: 600,
              options: {
                chart: {
                  width: 300
                }
              }
            },
            {
              breakpoint: 450,
              options: {
                chart: {
                  width: 200
                }
              }
            },
            {
              breakpoint: 321,
              options: {
                chart: {
                  width: 170
                }
              }
            }
          ]
        }}
        series={[100]}
        type='donut'
        width={300}
        height={200}
      />
      {props && <h4>{`${props} / ${props}`}</h4>}
    </div>
  )
}

export default TotallyViewChart
