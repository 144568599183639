import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

import CreateClassroomForm from '../components/form/CreateClassroomForm'
import {
  createClassroom,
  getClassroomById,
  editClassroom,
  clearCurrentClassroom
} from '../store/classrooms/actions'
import { removeClassroomTeachers } from '../store/teachers/actions'
import { roles } from '../utils/constants'

const mapStateToProps = ({ auth, school, classrooms }) => {
  return {
    userOccupations: auth.currentOccupation,
    currentSchool: school.currentSchool,
    classrooms: classrooms,
    currentClassroom: classrooms.currentItem,
    isFetching: classrooms.isFetching
  }
}

const mapDispatchToProps = {
  getClassroomById,
  createClassroom,
  editClassroom,
  clearCurrentClassroom,
  removeClassroomTeachers
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1rem 0',
    '& > div': {
      minHeight: 'calc(100vh - 160px)'
    }
    // maxWidth: 1220
  }
}))

const CreateClassroomPage = ({
  currentSchool,
  currentClassroom,
  clearCurrentClassroom,
  createClassroom,
  getClassroomById,
  editClassroom,
  isFetching,
  removeClassroomTeachers,
  userOccupations,
  match
}) => {
  const classes = useStyles()
  const { classId } = match.params
  const isAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)

  useEffect(() => {
    const loadData = async () => {
      await getClassroomById(classId)
    }
    if (classId) {
      loadData()
    } else {
      clearCurrentClassroom()
    }

    return () => {
      clearCurrentClassroom()
    }
  }, [])

  if (isFetching) return <CircularProgress color='secondary' />

  return (
    <div className={classes.root}>
      <CreateClassroomForm
        school={currentSchool}
        createClassroom={createClassroom}
        currentClassroom={currentClassroom}
        editClassroom={editClassroom}
        removeClassroomTeachers={removeClassroomTeachers}
        isAdmin={isAdmin}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateClassroomPage)
