import { createReducer } from '@reduxjs/toolkit'
import * as types from './types'

const initialState = {
  items: {
    schoolTeacher: [],
    schoolAdmin: [],
    schoolCoordinator: []
  },
  school_config: {
    id: 0,
    report_moderation: null,
    event_moderation: null,
    message_moderation: null,
    utility_moderation: null
  },
  list: [],
  selectedItem: {},
  isFetching: false,
  isError: false,
  errorMessage: '',
  currentSchool: {}
}

const schoolReducer = createReducer(initialState, {
  CLEAR_SCHOOL_CONFIG: (state, action) => ({
    ...state,
    school_config: {
      ...initialState.school_config
    }
  }),
  [types.CHANGE_SCHOOL_CONFIG]: (state, action) => ({
    ...state,
    school_config: {
      ...state.school_config
    }
  }),
  [types.CHANGE_SCHOOL_CONFIG_SUCCESSFUL]: (state, action) => ({
    ...state,
    school_config: {
      ...state.school_config,
      id: action.payload.data.id,
      report_moderation: action.payload.data.attributes.moderate_reports,
      event_moderation: action.payload.data.attributes.moderate_events,
      message_moderation: action.payload.data.attributes.moderate_messages,
      utility_moderation: action.payload.data.attributes.moderate_utilities
    }
  }),
  [types.CHANGE_SCHOOL_CONFIG_FAILURE]: (state, action) => ({
    ...state,
    school_config: {
      ...state.school_config,
      errorMessage: action.payload
    }
  }),
  [types.GET_SCHOOL_CONFIG]: (state, action) => ({
    ...state,
    isFetching: true,
    school_config: {
      ...state.school_config
    }
  }),
  [types.GET_SCHOOL_CONFIG_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    school_config: {
      id: action?.payload?.id,
      report_moderation: action?.payload?.moderate_reports,
      event_moderation: action?.payload?.moderate_events,
      message_moderation: action?.payload?.moderate_messages,
      utility_moderation: action?.payload?.moderate_utilities
    }
  }),
  [types.GET_SCHOOL_CONFIG_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    errorMessage: action.payload
  }),
  [types.GET_SCHOOL_CONTENT_CREATORS_BY_ID]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_SCHOOL_CONTENT_CREATORS_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: action.payload && action.payload[0],
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_SCHOOL_CONTENT_CREATORS_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: {},
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_SCHOOL_ADMINISTRATOR_BY_ID]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_SCHOOL_ADMINISTRATOR_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: {
      schoolTeacher: [...state.items.schoolTeacher],
      schoolAdmin:
        action.payload.response && action.payload.isLogin
          ? [...state.items.schoolAdmin, ...action.payload.response]
          : [...action.payload.response],
      schoolCoordinator: [...state.items.schoolCoordinator]
    },
    isFetching: false,
    isError: false,
    errorMessage: '',
    currentSchool:
      action.payload.isLogin !== false
        ? action.payload.response && action.payload.response[0]
        : state.currentSchool
  }),
  [types.GET_SCHOOL_ADMINISTRATOR_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: {},
    isError: true,
    errorMessage: action.payload
  }),
  [types.SWITCH_SCHOOL]: (state, action) => ({
    ...state,
    currentSchool: action.payload
  }),
  [types.GET_SCHOOL_TEACHER_BY_ID]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_SCHOOL_TEACHER_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: {
      schoolAdmin: [...state.items.schoolAdmin],
      schoolTeacher:
        action.payload.response && action.payload.isLogin
          ? [...state.items.schoolTeacher, ...action.payload.response]
          : [...action.payload.response],
      schoolCoordinator: [...state.items.schoolCoordinator]
    },
    isFetching: false,
    isError: false,
    errorMessage: '',
    currentSchool:
      action.payload.isLogin !== false
        ? action.payload.response && action.payload.response[0]
        : state.currentSchool
  }),
  [types.GET_SCHOOL_TEACHER_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: {},
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_SCHOOL_COORDINATOR_BY_ID]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_SCHOOL_COORDINATOR_BY_ID_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: {
      schoolAdmin: [...state.items.schoolAdmin],
      schoolTeacher: [...state.items.schoolTeacher],
      schoolCoordinator:
        action.payload.response && action.payload.isLogin
          ? [...state?.items?.schoolCoordinator, ...action.payload.response]
          : [...action.payload.response]
    },
    isFetching: false,
    isError: false,
    errorMessage: '',
    currentSchool:
      action.payload.isLogin !== false
        ? action.payload.response && action.payload.response[0]
        : state.currentSchool
  }),
  [types.GET_SCHOOL_COORDINATOR_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: {},
    isError: true,
    errorMessage: action.payload
  }),
  [types.UPDATE_LAYOUT_SCHOOL]: (state, action) => ({
    ...state,
    isFetching: true,
    isError: false,
    errorMessage: ''
  }),
  [types.UPDATE_LAYOUT_SCHOOL_SUCCESSFUL]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: false,
    errorMessage: '',
    currentSchool: {
      ...state.currentSchool,
      school: { ...action.payload.attributes, id: action.payload.id }
    }
  }),
  [types.UPDATE_LAYOUT_SCHOOL_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    isError: true,
    errorMessage: action.payload
  }),
  [types.GET_SCHOOLS]: (state, action) => ({
    ...state,
    isFetching: true,
    list: [],
    selectedItem: {},
    isError: false,
    errorMessage: ''
  }),
  [types.GET_SCHOOLS_SUCCESSFUL]: (state, action) => ({
    ...state,
    items: {},
    list: action.payload,
    isFetching: false,
    isError: false,
    errorMessage: ''
  }),
  [types.GET_SCHOOLS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    items: {},
    list: [],
    isError: true,
    errorMessage: action.payload
  }),
  [types.SELECTED_CURRENT_SCHOOL]: (state, action) => ({
    ...state,
    selectedItem: {}
  }),
  [types.SELECTED_CURRENT_SCHOOL_SUCCESSFUL]: (state, action) => ({
    ...state,
    selectedItem: action.payload
  }),
  [types.SELECTED_CURRENT_SCHOOL]: (state, action) => ({
    ...state,
    selectedItem: {}
  }),
  [types.CLEAR_CURRENT_SCHOOL]: (state, action) => {
    return {
      ...state,
      currentSchool: { ...initialState.currentSchool },
      items: {
        schoolTeacher: [],
        schoolAdmin: [],
        schoolCoordinator: []
      }
    }
  }
})

export default schoolReducer
