import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MaterialModal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 16,
    boxShadow: theme.shadows[5],
    padding: props => (props.disablePadding ? 'unset' : '60px 90px'),
    textAlign: 'center',
    maxHeight: props => (props.unlimitedHeight ? 'unset' : '95%'),
    overflow: props => (props.unlimitedHeight ? 'unset' : 'auto'),
    maxWidth: '85%',
    width: props => (props.width ? props.width : 'auto'),
    [theme.breakpoints.down('600')]: {
      maxWidth: '90%',
      padding: props => (props.disablePadding ? 'unset' : '30px 45px')
    },
    [theme.breakpoints.down('380')]: {
      padding: props => (props.disablePadding ? 'unset' : '15px 24px')
    },
    '& button': {
      fontWeight: 700,
      textTransform: 'capitalize'
    },
    '& .MuiTableContainer-root': {
      height: 450
    },
    '&::-webkit-scrollbar': {
      width: '0px'
    }
  }
}))

export default function Modal ({
  children,
  open,
  handleClose,
  unlimitedHeight,
  disablePadding,
  width
}) {
  const classes = useStyles({ unlimitedHeight, disablePadding, width })

  return (
    <div>
      <MaterialModal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={() => handleClose && handleClose(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>{children}</div>
        </Fade>
      </MaterialModal>
    </div>
  )
}
