import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Card from '@material-ui/core/Card'
import { Button } from '@material-ui/core'
import HeaderCard from '../card/HeaderCard'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
    // maxWidth: 1220
  },
  container: {
    paddingLeft: 0,
    marginTop: '2rem',
    '& h2,h3': {
      color: theme.palette.primary.main
    }
    // maxWidth: '100vw'
  },
  paper: {
    padding: theme.spacing(2, 2, 0, 2),
    margin: 0,
    borderRadius: '8px',
    lineHeight: '20.11px !important',
    fontSize: 16
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    maxWidth: '67vw',
    minWidth: '300px',
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    height: '23vh',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '80vw'
    },
    '& div:first-of-type': {
      margin: '3.2rem auto auto 1rem',
      [theme.breakpoints.down('lg')]: {
        marginTop: '0'
      }
    },
    '& div:last-of-type': {
      margin: '1rem 1rem auto auto',
      [theme.breakpoints.down('lg')]: {
        marginTop: '0'
      }
    }
  },
  paperLeftSide: {
    minWidth: 300,
    paddingLeft: '1.5rem',
    maxWidth: '67vw',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '80vw'
    }
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '1rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  cardContent: {
    background: '#F6F7F8',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    padding: '0.8rem',
    width: '270px',
    margin: '1rem',
    textAlign: 'center',
    '& p': {
      color: theme.palette.text.primary
    },
    '& span': {
      color: theme.palette.primary.dark,
      fontWeight: 'bold'
    }
  },
  contentHeader: {
    color: theme.palette.primary.main,
    display: 'flex',
    // gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    marginBottom: '2rem',
    '& img': {
      border: `1px dashed ${theme.palette.primary.brightest}`,
      borderRadius: 8
    },
    '& div > p': {
      border: `1px dashed ${theme.palette.primary.brightest}`,
      borderRadius: 8,
      padding: '1.5rem',
      minHeight: 185,
      height: 'fit-content',
      width: 380
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      justifyItems: 'start',
      '& img': {
        width: 270
      },
      '& div > p': {
        width: 270
      }
    }
  },
  themePaper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  applyBtn: {
    color: '#FFFF',
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const ThemeView = ({ theme, themeContents }) => {
  const classes = useStyles()
  const history = useHistory()
  const [contentTeacher, setContentTeacher] = useState([])
  const [contentStudent, setContentStudent] = useState([])
  const [contentParent, setContentParent] = useState([])

  const selectedTheme = history.location?.state?.theme
  const gradeId = history.location?.state?.gradeId
  const previewState = history.location?.state

  useEffect(
    () => {
      themeContents &&
        !(Object.keys(themeContents).length === 0) &&
        themeContents.map(content => {
          switch (content.occupation_id) {
            case 8:
              setContentTeacher(prevState => prevState.concat(content))
              break
            case 9:
              setContentStudent(prevState => prevState.concat(content))
              break
            case 10:
              setContentParent(prevState => prevState.concat(content))
              break
            default:
              break
          }
        })
    },
    [themeContents]
  )

  if (!theme) return <p>Não foi possível carregar o tema.</p>

  const cardContent = list => {
    return (
      <div className={classes.cardContainer}>
        {list.length > 0 &&
          list.map(content => (
            <Card
              key={content.id}
              className={classes.cardContent}
              elevation={0}
              onClick={() => history.push(`/contents/${content.id}`)}
            >
              <h4>{content.title}</h4>
              <p>{content.category?.name}</p>
              <span>Etapa {content.stage}</span>
            </Card>
          ))}
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <HeaderCard>
          <div style={{ margin: 'auto 1rem' }}>
            <h1 style={{ fontSize: '1.5rem' }}>
              {theme.theme_module?.grade?.name}
            </h1>
            <h4 style={{ fontWeight: 400 }}>
              {theme.theme_module?.grade?.segment?.name}
            </h4>
          </div>
        </HeaderCard>
        <Grid item xs={12} container className={classes.container}>
          <Grid item xs={12}>
            <Paper
              className={`${classes.paper} ${classes.themePaper}`}
              elevation={0}
              style={{ marginBottom: '1rem' }}
            >
              <div style={{ marginBottom: '1rem' }}>
                <p>Módulo: {theme.theme_module?.title}</p>
                <h2>{theme.title}</h2>
                {theme.skills &&
                  theme.skills.map((skill, index) => (
                    <span key={skill.id}>
                      {skill.name}
                      {index < theme.skills.length - 1 ? ' | ' : ''}
                    </span>
                  ))}
              </div>
              <div className={classes.contentHeader}>
                {theme.description && (
                  <div>
                    <p>{theme.description}</p>
                  </div>
                )}
                <div>
                  {theme.cover && (
                    <img
                      src={theme.cover.image_url}
                      alt='Capa do tema'
                      width='380'
                      height='185'
                    />
                  )}
                </div>
              </div>
            </Paper>
            {/* <Paper
                className={classes.paper}
                elevation={0}
                key={schedule.id}
                style={{ marginBottom: '1rem' }}
              >
                <div className={classes.cyleCard}>
                  <h2>
                    {schedule.favorited && (
                      <FavoriteIcon className={classes.favoritedIcon} />
                    )}{' '}
                    {schedule.cycle?.name || 'Ciclo'}
                  </h2>
                  <div>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        history.push(`/themes/${schedule.theme?.id}`)
                      }
                    >
                      <h3>
                        {moment(schedule.start_at).format('DD/MM/YYYY')} a{' '}
                        {moment(schedule.end_at).format('DD/MM/YYYY')}
                      </h3>
                      <p>
                        Módulo:{' '}
                        {schedule.theme && schedule.theme.theme_module?.title}
                      </p>
                      <h2
                        onClick={() =>
                          history.push(`/themes/${schedule.theme?.id}`)
                        }
                      >
                        {schedule.theme?.title}
                      </h2>
                      {schedule.theme?.skills &&
                        schedule.theme.skills.map((skill, index) => (
                          <span key={skill.id}>
                            {skill.name}
                            {index < schedule.theme?.skills.length - 1
                              ? ' | '
                              : ''}
                          </span>
                        ))}
                    </div>
                    <div>
                      {schedule?.theme?.cover && (
                        <img
                          src={schedule.theme.cover.image_url}
                          alt='Capa do tema'
                          width='370'
                          height='185'
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Paper> */}

            {contentTeacher.length > 0 && (
              <>
                <h3>Conteúdo Professor</h3>
                <Paper
                  style={{
                    boxShadow: '3px 11px 14px 6px rgba(107, 103, 103, 0.1)'
                  }}
                >
                  {cardContent(contentTeacher)}
                </Paper>
              </>
            )}
            {contentParent.length > 0 && (
              <>
                <h3>Conteúdo Responsável</h3>
                <Paper
                  style={{
                    boxShadow: '3px 11px 14px 6px rgba(107, 103, 103, 0.1)'
                  }}
                >
                  {cardContent(contentParent)}
                </Paper>
              </>
            )}
            {contentStudent.length > 0 && (
              <>
                <h3>Conteúdo Aluno</h3>
                <Paper
                  style={{
                    boxShadow: '3px 11px 14px 6px rgba(107, 103, 103, 0.1)'
                  }}
                >
                  {cardContent(contentStudent)}
                </Paper>
              </>
            )}
            <div style={{ textAlign: 'end', marginBottom: '2rem' }}>
              <Button
                variant='outlined'
                type='button'
                className={classes.backBtn}
                onClick={() => history.goBack()}
              >
                Voltar
              </Button>
              {selectedTheme && (
                <Button
                  type='button'
                  style={{ marginLeft: '1rem' }}
                  className={classes.applyBtn}
                  onClick={() =>
                    history.push(
                      `${
                        previewState.cycle
                          ? `/theme-schedule/grades/${gradeId}/${
                              previewState.cycle.id
                            }`
                          : `/planning/grades/${gradeId}`
                      }`,
                      {
                        ...previewState
                      }
                    )
                  }
                >
                  Aplicar
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default ThemeView
