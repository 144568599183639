import { createReducer } from '@reduxjs/toolkit'

import * as types from './types'

const initialState = {
  current: 1,
  canGoBack: false,
  from: ''
}

const paginationReducer = createReducer(initialState, {
  [types.SET_PAGINATION]: (state, action) => {
    return {
      ...state,
      current: action.payload?.current,
      from: action.payload?.from,
      canGoBack: action.payload?.canGoBack
    }
  }
})

export default paginationReducer
