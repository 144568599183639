import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '1rem',
    flexGrow: 1,
    padding: '1rem',
    [theme.breakpoints.up('426')]: {
      width: 'calc(calc(100% - 8rem)/2)'
    }
  },
  title: {
    display: 'flex',
    flexFlow: 'row',
    [theme.breakpoints.down('426')]: {
      '& p': {
        fontSize: '18px'
      }
    }
  },
  stepper: {
    background: 'inherit',
    padding: 'initial',
    '& .MuiStep-horizontal': {
      padding: '0.5rem 0'
    },
    '& .MuiStepConnector-horizontal > .MuiStepConnector-line': {
      borderColor: '#4D5E80'
    }
  },
  stepsNumber: {
    color: '#4D5E80',
    textTransform: 'uppercase',
    fontSize: '1.3rem',
    fontWeight: 'bold',
    marginRight: '1.5rem'
  },
  stepsName: {
    color: '#4D5E80',
    fontSize: '1.3rem'
  },
  stepDot: {
    alignItems: 'center',
    border: `1px solid #4D5E80`,
    borderRadius: '50%',
    display: 'flex',
    height: '1.5rem',
    justifyContent: 'center',
    width: '1.5rem',
    '& div': {
      background: '#4D5E80',
      borderRadius: '50%',
      height: '1rem',
      width: '1rem'
    }
  }
}))

export default function ProgressMobileStepper ({
  activeStep,
  steps,
  totalSteps,
  styles
}) {
  const classes = useStyles()

  return (
    <div className={classes.root} style={{ ...styles }}>
      <div className={classes.title}>
        <p className={classes.stepsNumber}>
          Etapa {activeStep} de {totalSteps}
        </p>
        <p className={classes.stepsName}>{steps[activeStep]}</p>
      </div>
      <Stepper
        className={classes.stepper}
        steps={4}
        position='static'
        activeStep={activeStep}
      >
        {Object.keys(steps).map(step => {
          const isActive = step * 1 <= activeStep
          return (
            <Step key={step} active={step * 1 === activeStep}>
              <div className={classes.stepDot}>{isActive && <div />}</div>
            </Step>
          )
        })}
      </Stepper>
    </div>
  )
}
