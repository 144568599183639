// Import
import JsonApi from 'fractal-devour-client'
import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'
import models from '../jsonApi/models'

const { REACT_APP_SERVER_ENV } = process.env

const currentApi = process.env.REACT_APP_PROJECT_API_HOST

const backendHosts = {
  production: 'https://api.plataformaversar.com.br',
  staging: 'https://stag-api.plataformaversar.com.br',
  development: 'https://stag-api.plataformaversar.com.br',
  homolog: 'https://homolog-api.plataformaversar.com.br',
  local: 'http://localhost:3000'
}

// const apiVersion = 'v1'

export const currentHost = currentApi || backendHosts[REACT_APP_SERVER_ENV]

const cache = setupCache({
  maxAge: 1 * 60 * 1000,
  exclude: {
    query: false
  }
})

// Multipart
export const multipartInstance = axios.create({
  baseURL: currentHost,
  adapter: cache.adapter,
  defaults: {
    timeout: 6000
  }
})

// Axios instance
export const instance = axios.create({
  baseURL: currentHost
})

// Bootstrap
const jsonApi = new JsonApi({
  axiosInstance: instance
})

models.forEach(model =>
  jsonApi.define(model.name, model.attributes, model.options)
)

export default jsonApi
