import React, { useState } from 'react'
import TableCell from '@material-ui/core/TableCell'

const ExtraContentCellTable = ({ row, extraContent, classes }) => {
  const [seeMore, setSeeMore] = useState(false)
  const qtdSpan = 3

  return (
    <>
      {extraContent.map(extra => (
        <TableCell key={extra.attr}>
          {row[extra.attr] instanceof Array && row[extra.attr].length > 0 ? (
            <>
              {row[extra.attr].map((item, index) => {
                if (index < qtdSpan || seeMore) {
                  return (
                    <span
                      key={item['name'] || item['title']}
                      style={{ display: 'block' }}
                    >
                      {item['name'] || item['title']}
                    </span>
                  )
                }
              })}
              {row[extra.attr].length > 3 && (
                <button
                  className={classes.seeMoreBtn}
                  onClick={() => setSeeMore(!seeMore)}
                >
                  {seeMore ? 'Ver menos' : 'Ver mais'}
                </button>
              )}
            </>
          ) : (
            <span>{row[extra.attr]?.name || row[extra.attr]?.title}</span>
          )}
        </TableCell>
      ))}
    </>
  )
}

export default ExtraContentCellTable
