export const GET_REPORTS = 'reports/GET_REPORTS'
export const GET_REPORTS_SUCCESSFUL = 'reports/GET_REPORTS_SUCCESSFUL'
export const GET_REPORTS_FAILURE = 'reports/GET_REPORTS_FAILURE'

export const GENERATE_REPORTS = 'reports/GENERATE_REPORTS'
export const GENERATE_REPORTS_SUCCESSFUL = 'reports/GENERATE_REPORTS_SUCCESSFUL'
export const GENERATE_REPORTS_FAILURE = 'reports/GENERATE_REPORTS_FAILURE'

export const GET_GENERATED_REPORTS_BY_ID = 'reports/GET_GENERATED_REPORTS_BY_ID'
export const GET_GENERATED_REPORTS_BY_ID_SUCCESSFUL =
  'reports/GET_GENERATED_REPORTS_BY_ID_SUCCESSFUL'
export const GET_GENERATED_REPORTS_BY_ID_FAILURE =
  'reports/GET_GENERATED_REPORTS_BY_ID_FAILURE'

export const GET_REPORT_BY_ID = 'reports/GET_REPORT_BY_ID'
export const GET_REPORT_BY_ID_SUCCESSFUL = 'reports/GET_REPORT_BY_ID_SUCCESSFUL'
export const GET_REPORT_BY_ID_FAILURE = 'reports/GET_REPORT_BY_ID_FAILURE'

export const GET_REPORT_TEMPLATE_BY_ID = 'reports/GET_REPORT_TEMPLATE_BY_ID'
export const GET_REPORT_TEMPLATE_BY_ID_SUCCESSFUL =
  'reports/GET_REPORT_TEMPLATE_BY_ID_SUCCESSFUL'
export const GET_REPORT_TEMPLATE_BY_ID_FAILURE =
  'reports/GET_REPORT_TEMPLATE_BY_ID_FAILURE'

export const CREATE_REPORTS = 'reports/CREATE_REPORTS'
export const CREATE_REPORTS_SUCCESSFUL = 'reports/CREATE_REPORTS_SUCCESSFUL'
export const CREATE_REPORTS_FAILURE = 'reports/CREATE_REPORTS_FAILURE'

export const EDIT_REPORT_TEMPLATE = 'reports/EDIT_REPORT_TEMPLATE'
export const EDIT_REPORT_TEMPLATE_SUCCESSFUL =
  'reports/EDIT_REPORT_TEMPLATE_SUCCESSFUL'
export const EDIT_REPORT_TEMPLATE_FAILURE =
  'reports/EDIT_REPORT_TEMPLATE_FAILURE'

export const GET_REPORT_STUDENT_BY_ID = 'reports/GET_REPORT_STUDENT_BY_ID'
export const GET_REPORT_STUDENT_BY_ID_SUCCESSFUL =
  'reports/GET_REPORT_STUDENT_BY_ID_SUCCESSFUL'
export const GET_REPORT_STUDENT_BY_ID_FAILURE =
  'reports/GET_REPORT_STUDENT_BY_ID_FAILURE'

export const GET_REPORT_OPTIONS_BY_ID = 'reports/GET_REPORT_OPTIONS_BY_ID'
export const GET_REPORT_OPTIONS_BY_ID_SUCCESSFUL =
  'reports/GET_REPORT_OPTIONS_BY_ID_SUCCESSFUL'
export const GET_REPORT_OPTIONS_BY_ID_FAILURE =
  'reports/GET_REPORT_STUDENT_BY_ID_FAILURE'

export const CREATE_REPORT_STUDENT = 'reports/CREATE_REPORT_STUDENT'
export const CREATE_REPORT_STUDENT_SUCCESSFUL =
  'reports/CREATE_REPORT_STUDENT_SUCCESSFUL'
export const CREATE_REPORT_STUDENT_FAILURE =
  'reports/CREATE_REPORT_STUDENT_FAILURE'

export const EDIT_REPORT_STUDENT = 'reports/EDIT_REPORT_STUDENT'
export const EDIT_REPORT_STUDENT_SUCCESSFUL =
  'reports/EDIT_REPORT_STUDENT_SUCCESSFUL'
export const EDIT_REPORT_STUDENT_FAILURE = 'reports/EDIT_REPORT_STUDENT_FAILURE'

export const CLEAN_REPORT_STUDENT_ANSWERS =
  'repors/CLEAN_REPORT_STUDENT_ANSWERS'

export const GET_REPORT_STUDENTS = 'reports/GET_REPORT_STUDENTS'
export const GET_REPORT_STUDENTS_SUCCESSFUL =
  'reports/GET_REPORT_STUDENTS_SUCCESSFUL'
export const GET_REPORT_STUDENTS_FAILURE = 'reports/GET_REPORT_STUDENTS_FAILURE'
