import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Field,
  Form,
  formValueSelector,
  reduxForm,
  getFormValues
} from 'redux-form'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '../button/Button'
import moment from 'moment'
import Backdrop from '@material-ui/core/Backdrop'
import MaterialInput from '../shared/MaterialInput'
import SimpleEditorInput from '../shared/SimpleEditorInput'
import DropzoneInput from '../shared/DropzoneInput'
import DatePickerInput, { TimePickerInput } from '../shared/DatePickerInput'
import AsyncSelect from '../shared/AsyncSelect'
import destinationsSVG from '../../assets/icons/sended-icon.svg'
import docSVG from '../../assets/icons/doc.svg'
import videoSVG from '../../assets/icons/video.svg'
import audioSVG from '../../assets/icons/audio.svg'
import linkSVG from '../../assets/icons/material-link.svg'
import crossAddSVG from '../../assets/icons/cross-add-icon.svg'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import imgSVG from '../../assets/icons/img.svg'
import StyledSVG from '../shared/StyledSVG'
import { createMaterials, editMaterial } from '../../store/materials/actions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { required } from '../../utils/formUtils'
import { roles } from '../../utils/constants'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  root: {
    width: '98%',
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    margin: 'auto',
    height: '95%',
    padding: '32px',
    marginBottom: '100px'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
    '& h1': {
      fontWeight: 700,
      color: '#4D5E80',
      fontSize: 32,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  materialTitle: {
    width: '80% !important',
    minWidth: '255px',
    '& label': {
      fontSize: 16,
      color: '#555555',
      fontWeight: 600
    },
    '& .MuiInputBase-root': {
      marginTop: 4
    }
  },
  materialLink: {
    maxWidth: '400px'
  },
  descriptionRootStyle: {
    padding: 0,
    marginBottom: 0,
    border: '1.5px solid #ADB8CC',
    '& .mce-edit-area': {
      minHeight: '120px'
    },
    '& .mce-container-body': {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    '& .mce-btn-group .mce-btn': {
      margin: 0,
      backgroundColor: 'transparent'
    },
    '& .mce-toolbar-grp': {
      width: 'fit-content',
      backgroundColor: '#F6F7F8',
      boxShadow: '3px 11px 14px 6px #6B67671A',
      margin: '0px 30px 30px 40px',
      height: 'fit-content',
      borderRadius: 8
    },
    '& .mce-btn': {
      '& button': {
        height: 50
      }
    },
    '& .mce-panel': {
      backgroundColor: 'unset'
    }
  },
  materialDescription: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
    '& label': {
      fontSize: 16,
      fontWeight: 600
    }
  },
  materialInput: {
    marginTop: 25
  },
  dropzoneRootStyle: {
    maxWidth: 'unset !important'
  },
  scheduledDate: {
    marginTop: 30,
    '& label': {
      fontSize: 16,
      fontWeight: 600,
      color: '#555555'
    },
    '& .inputs': {
      marginTop: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 8,
      '& .MuiGrid-container': {
        width: 'unset'
      },
      [theme.breakpoints.down('700')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    },
    '& .date': {
      width: '250px !important'
    },
    '& .hour': {
      width: '120px !important'
    }
  },
  destinationSection: {
    marginTop: 20,
    '& .header': {
      display: 'flex',
      gap: 8,
      alignItems: 'center',
      marginBottom: 15,
      '& span': {
        fontSize: 16,
        fontWeight: 600
      }
    }
  },
  destinationFields: {
    display: 'flex',
    gap: 8,
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  searchField: {
    width: 'fit-content',
    '& label': {
      fontSize: 16,
      fontWeight: 600,
      color: '#555555',
      '& span': {
        fontWeight: 400
      }
    }
  },
  asyncSelectClass: {
    width: '350px !important',
    marginTop: 4
  },
  bottomBtns: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    '& button': {
      width: 150
    }
  },
  cancelBtn: {
    width: 146,
    border: '2px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '2px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  sendBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  file: {
    display: 'flex',
    '& p': {
      backgroundColor: 'rgba(218, 218, 218, 0.2)',
      borderRadius: 8,
      margin: 0,
      display: 'flex',
      gap: 14,
      padding: '5px 10px',
      width: 'fit-content',
      alignItems: 'center',
      '& a': {
        color: theme.palette.text.secondary,
        textDecoration: 'none'
      }
    }
  },
  deleteImgButton: {
    width: 15,
    margin: '2px -8px',
    color: 'gray !important',
    cursor: 'pointer'
  },
  files: {
    marginTop: 20,
    display: 'flex',
    gap: 12,
    flexWrap: 'wrap'
  },
  teacher: {
    width: '20%',
    minWidth: '240px'
  },
  firstLineField: {
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    [theme.breakpoints.down('830')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  linkIcon: {
    marginRight: 8
  },
  addLinkBtn: {
    cursor: 'pointer',
    borderRadius: 50,
    width: 45,
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0.75px dashed #9B9B9B'
  },
  addLinkSection: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& span': {
      fontSize: 18,
      color: '#9B9B9B',
      [theme.breakpoints.down('970')]: {
        display: 'none'
      }
    }
  },
  linkSection: {
    marginTop: 18,
    display: 'flex',
    alignItems: 'center',
    gap: 18
  },
  listFiles: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: 16,
    marginTop: 16,
    height: 'fit-content',
    maxHeight: 250,
    '& img:not(.icon)': {
      borderRadius: 8,
      maxHeight: 250,
      maxWidth: 250,
      objectFit: 'contain',
      width: 'fit-content'
    },
    '& div': {
      alignItems: 'center',
      borderRadius: 8,
      color: '#9B9B9B',
      display: 'flex',
      padding: '6px 8px',
      maxHeight: 250,
      '& img': {
        marginRight: 4
      }
    }
  },
  removeButton: {
    position: 'absolute',
    zIndex: 1,
    right: '1px',
    top: '-8px',
    cursor: 'pointer'
  }
}))

const ShowLink = ({ classes, link, handleDeleteLinks, isInstance }) => {
  return (
    <div className={classes.file}>
      <p>
        <img src={linkSVG} alt='image' width='21' height='21' />
        <span>{link.link_url || link}</span>
        <HighlightOffIcon
          className={classes.deleteImgButton}
          onClick={() => {
            isInstance ? handleDeleteLinks(link.id, true) : handleDeleteLinks()
          }}
        />
      </p>
    </div>
  )
}

const ShowFile = ({ matches, classes, file, handleDeleteFiles, typeBlob }) => {
  const attributesMapped = {
    type: typeBlob ? 'type' : 'content_type',
    name: typeBlob ? 'name' : 'filename',
    preview: typeBlob ? 'preview' : 'file_url'
  }
  const isImage = file?.[attributesMapped.type]?.includes('image')
  const isVideo = file?.[attributesMapped.type]?.includes('video')
  const isAudio = file?.[attributesMapped.type]?.includes('audio')
  const handleDeleteParam = typeBlob ? file.name : file.id

  let fileName = file[attributesMapped.name]
  const extencion = fileName.split('.')[fileName.split('.').length - 1]
  fileName.length > 20 &&
    !matches &&
    (fileName = `${fileName.slice(0, 17)}...${extencion}`)
  let fileDoc
  if ((!isAudio || !isVideo || !isImage) && typeBlob) {
    fileDoc = URL.createObjectURL(file)
  }

  if (isImage) {
    return (
      <div className={classes.file} key={file.name}>
        <p>
          <img src={imgSVG} alt='image' width='21' height='21' />
          <a
            target='_blank'
            href={file[attributesMapped.preview]}
            rel='noreferrer'
          >
            {fileName}
          </a>
          <HighlightOffIcon
            className={classes.deleteImgButton}
            onClick={() => handleDeleteFiles(handleDeleteParam)}
          />
        </p>
      </div>
    )
  } else if (isVideo) {
    return (
      <div className={classes.file} key={file.name}>
        <p>
          <img src={videoSVG} alt='video' width='21' height='21' />
          <a
            target='_blank'
            href={file[attributesMapped.preview]}
            rel='noreferrer'
          >
            {fileName}
          </a>
          <HighlightOffIcon
            className={classes.deleteImgButton}
            onClick={() => handleDeleteFiles(handleDeleteParam)}
          />
        </p>
      </div>
    )
  } else if (isAudio) {
    return (
      <div className={classes.file} key={file.name}>
        <p>
          <img src={audioSVG} alt='video' width='21' height='21' />
          <a
            target='_blank'
            href={file[attributesMapped.preview]}
            rel='noreferrer'
          >
            {fileName}
          </a>
          <HighlightOffIcon
            className={classes.deleteImgButton}
            onClick={() => handleDeleteFiles(handleDeleteParam)}
          />
        </p>
      </div>
    )
  } else {
    return (
      <div className={classes.file} key={file.name}>
        <p>
          <img src={docSVG} alt='document' width='21' height='21' />
          <a target='_blank' href={fileDoc || file.file_url} rel='noreferrer'>
            {fileName}
          </a>
          <HighlightOffIcon
            className={classes.deleteImgButton}
            onClick={() => handleDeleteFiles(handleDeleteParam)}
          />
        </p>
      </div>
    )
  }
}
const CreateMaterialForm = ({
  handleSubmit,
  change,
  touch,
  schoolId,
  schoolValue,
  gradeValue,
  fieldValues,
  classroomValue,
  files,
  linkValue,
  createMaterials,
  currentMaterial,
  initialValues,
  materialFiles,
  materialLinks,
  isEdit,
  valid,
  isSuccess,
  teacherValue,
  userOccupations,
  userId
}) => {
  const classes = useStyles()
  const selectGradeRef = React.useRef(null)
  const selectClassroomRef = React.useRef(null)
  const subjectRef = React.useRef(null)
  const selectTeacherRef = React.useRef(null)
  const selectSchoolRef = React.useRef(null)
  const selectStudentRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)
  const [fileToDeleteDropzone, setFileToDeleteDropzone] = React.useState(null)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('426'))
  const history = useHistory()
  const [links, setLinks] = React.useState([])
  const [blobFiles, setBlobFiles] = React.useState([])
  const [materialFilesToDelete, setMaterialFilesToDelete] = React.useState([])
  const [materialFilesInstance, setMaterialFilesInstace] = React.useState([
    ...materialFiles
  ])
  const [materialLinksInstance, setMaterialLinksInstance] = React.useState([
    ...materialLinks
  ])
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const isAdmin =
    userOccupations?.includes(roles.VERSAR_ADMIN) ||
    userOccupations?.includes(roles.SCHOOL_ADMIN)

  const isCoordinator = userOccupations?.includes(roles.COORDINATOR)

  const handleToggle = () => {
    valid && setOpen(!open)
  }
  const alreadyFilled = value => {
    return value?.length > 0
  }

  isSuccess && history.push('/materials')

  const handleChange = (event, input) => {
    touch(input.name)
    let newValue
    if (Array.isArray(event)) {
      newValue = event?.map(v => v)
    } else {
      newValue = event ? event.value : ''
    }
    change(input.name, newValue)
  }

  const handleDeleteMaterialFileInstance = id => {
    setMaterialFilesToDelete(prev => [...prev, id])
  }

  React.useEffect(
    () => {
      change('links', links)
    },
    [links]
  )

  React.useEffect(
    () => {
      change('filesToDelete', materialFilesToDelete)
    },
    [materialFilesToDelete]
  )

  React.useEffect(
    () => {
      if (files?.length > 0) {
        setBlobFiles(files)
      }
    },
    [files]
  )

  const handleAddLink = () => {
    if (linkValue) {
      setLinks(prev => {
        const newState = [...prev, linkValue]
        return newState
      })
      change('linkInput', '')
    }
  }

  const handleDeleteFiles = blobName => {
    setBlobFiles(prev => {
      const index = prev.findIndex(item => item && item.name === blobName)
      if (index !== -1) {
        const newState = [...prev.slice(0, index), ...prev.slice(index + 1)]
        setFileToDeleteDropzone(blobName)
        change('files', newState)
        return newState
      }
      return prev
    })
  }
  const handleDeleteMaterialFiles = (id, isForLink) => {
    if (isForLink) {
      setMaterialLinksInstance(prev => {
        const newState = prev.filter(file => +file.id !== +id)
        return newState
      })
    } else {
      setMaterialFilesInstace(prev => {
        const newState = prev.filter(file => +file.id !== +id)
        return newState
      })
    }
    handleDeleteMaterialFileInstance(id)
  }

  if (isEdit && isEmpty(currentMaterial)) {
    return <CircularProgress size={30} />
  }
  return (
    <div className={classes.root}>
      <Form onSubmit={handleSubmit}>
        <div className={classes.header}>
          <h1>Envio de arquivo</h1>
          <span>Insira as informações necessárias para cada item.</span>
        </div>
        <div className={classes.firstLineField}>
          {(isAdmin || isCoordinator) && (
            <div className={`${classes.searchField} ${classes.teacher}`}>
              <label>Professor</label>
              <Field
                component={AsyncSelect}
                defaultValue={initialValues?.teacher_id}
                ref={selectTeacherRef}
                cacheUniqs={schoolValue || 0}
                id='teacher_id'
                name='teacher_id'
                placeholder='Digite ou selecione um(a) professor(a)'
                handleAsyncChange={handleChange}
                searchParam='q[teacher_name_cont]'
                request={{
                  path: 'school_teachers',
                  params: {
                    'q[school_id_eq]': schoolId || schoolValue,
                    include: 'teacher'
                  }
                }}
                validate={
                  !alreadyFilled(initialValues?.teacher_id) && [required]
                }
                touch={touch}
              />
            </div>
          )}
          <div className={classes.materialTitle}>
            <label htmlFor='title'>Título:</label>
            <Field
              component={MaterialInput}
              name='title'
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                }
              }}
              type='text'
              placeholder='Digite o título...'
              autoComplete='off'
              validate={!alreadyFilled(initialValues.title) && [required]}
            />
          </div>
        </div>
        <div className={classes.destinationSection}>
          <div className='header'>
            <StyledSVG src={destinationsSVG} height={16} width={16} />
            <span>Destinatários</span>
          </div>
          <div className={classes.destinationFields}>
            {isVersarAdmin && (
              <div className={classes.searchField}>
                <label>Escola</label>
                <Field
                  component={AsyncSelect}
                  selectRef={selectSchoolRef}
                  rootStyle={classes.asyncSelectClass}
                  defaultOptions={false}
                  id='school_id'
                  name='school_id'
                  placeholder='Digite ou selecione a escola'
                  handleAsyncChange={handleChange}
                  searchParam='q[name_cont]'
                  defaultValue={initialValues?.schoolSelect}
                  disabled={isEdit}
                  validate={
                    userOccupations?.includes(roles.VERSAR_ADMIN) &&
                    !alreadyFilled(initialValues?.schoolSelect?.value) && [
                      required
                    ]
                  }
                  request={{
                    path: 'school'
                  }}
                  touch={touch}
                />
              </div>
            )}
            <div className={classes.searchField}>
              <label>Série</label>
              <Field
                component={AsyncSelect}
                selectRef={selectGradeRef}
                rootStyle={classes.asyncSelectClass}
                cacheUniqs={[teacherValue]}
                disabled={isAdmin && !teacherValue}
                defaultValue={initialValues?.grade_id}
                id='grade_id'
                name='grade_id'
                placeholder='Digite ou selecione'
                handleAsyncChange={handleChange}
                searchParam='q[name_cont]'
                request={{
                  path: 'grade',
                  params: {
                    'q[classrooms_teachers_id_eq]': !isAdmin
                      ? userId
                      : teacherValue?.value || teacherValue,
                    'q[school_id_null]': true
                  }
                }}
                touch={touch}
                validate={!isEdit && [required]}
              />
            </div>
            <div className={classes.searchField}>
              <label>Turma</label>
              <Field
                component={AsyncSelect}
                selectRef={selectClassroomRef}
                rootStyle={classes.asyncSelectClass}
                defaultValue={initialValues?.classroom_id}
                disabled={!gradeValue || (isAdmin && !teacherValue)}
                cacheUniqs={[gradeValue, teacherValue]}
                id='classroom_id'
                name='classroom_id'
                placeholder='Digite ou selecione a(s) turma(s)'
                handleAsyncChange={handleChange}
                searchParam='q[name_cont]'
                request={{
                  path: 'classroom',
                  params: {
                    'q[teachers_id_eq]': !isAdmin
                      ? userId
                      : teacherValue?.value || teacherValue,
                    'q[school_id_in]': schoolId || schoolValue,
                    'q[grade_id_in]': gradeValue?.value || gradeValue,
                    'q[year_lteq]': new Date().getFullYear() + 1,
                    'q[year_gteq]': new Date().getFullYear()
                  }
                }}
                touch={touch}
                validate={!isEdit && [required]}
              />
            </div>
            <div className={classes.searchField}>
              <label>Disciplina</label>
              <Field
                component={AsyncSelect}
                selectRef={subjectRef}
                rootStyle={classes.asyncSelectClass}
                defaultValue={initialValues?.subject_id}
                cacheUniqs={[teacherValue]}
                id='subject_id'
                name='subject_id'
                placeholder='Digite ou selecione a(s) turma(s)'
                handleAsyncChange={handleChange}
                searchParam='q[name_cont]'
                validate={
                  !alreadyFilled(initialValues?.subject_id) && [required]
                }
                request={{
                  path: 'subjects',
                  params: {
                    'q[teacher_subjects_school_teacher_teacher_id_eq]': !isAdmin
                      ? userId
                      : teacherValue?.value || teacherValue,
                    'q[school_id_in]': schoolId || schoolValue
                  }
                }}
                touch={touch}
              />
            </div>
            <div className={classes.searchField}>
              <label>
                Aluno <span>(opcional)</span>
              </label>
              <Field
                component={AsyncSelect}
                selectRef={selectStudentRef}
                rootStyle={classes.asyncSelectClass}
                cacheUniqs={[gradeValue, classroomValue]}
                disabled={!classroomValue}
                isMulti
                id='child_id'
                name='child_id'
                defaultValue={initialValues?.child_id}
                placeholder='Digite ou selecione'
                handleAsyncChange={handleChange}
                searchParam='q[name_cont]'
                request={{
                  path: 'child',
                  params: {
                    'q[user_id_not_null]': true,
                    'q[classrooms_id_in]':
                      classroomValue?.value || classroomValue
                  }
                }}
                touch={touch}
              />
            </div>
          </div>
        </div>
        <div className={classes.materialDescription}>
          <label htmlFor='description'>Descrição:</label>
          <Field
            name='description'
            component={SimpleEditorInput}
            defaultValue={
              fieldValues?.description || initialValues?.description
            }
            addRootStyle={classes.descriptionRootStyle}
            placeholder='Informe o motivo da rejeição...'
            validate={!alreadyFilled(initialValues.description) && [required]}
          />
        </div>
        <div className={classes.materialInput}>
          <Field
            component={DropzoneInput}
            maxFiles={100}
            maxSize={524288000}
            name='files'
            type='file'
            dropzoneRootStyle={classes.dropzoneRootStyle}
            fileToDelete={fileToDeleteDropzone}
            withoutPreview
          />
        </div>
        <div className={classes.files}>
          {blobFiles?.length > 0 &&
            blobFiles.map(file => {
              return (
                <ShowFile
                  typeBlob
                  matches={matches}
                  classes={classes}
                  key={file?.filename}
                  file={file}
                  handleDeleteFiles={handleDeleteFiles}
                />
              )
            })}
          {isEdit &&
            materialFilesInstance.length > 0 &&
            materialFilesInstance.map((file, index) => {
              return (
                <ShowFile
                  matches={matches}
                  classes={classes}
                  key={file?.filename}
                  file={file}
                  handleDeleteFiles={handleDeleteMaterialFiles}
                />
              )
            })}
        </div>
        <div className={classes.linkSection}>
          <div
            className={`${classes.materialLink} ${classes.materialTitle} ${
              classes.searchField
            }`}
          >
            <label htmlFor='linkInput'>
              Link <span>(opcional)</span>
            </label>
            <Field
              component={MaterialInput}
              name='linkInput'
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  handleAddLink()
                }
              }}
              startAdornment={
                <StyledSVG
                  className={classes.linkIcon}
                  src={linkSVG}
                  width={15}
                  height={15}
                />
              }
              type='text'
              placeholder='Insira o link aqui'
              autoComplete='off'
            />
          </div>
          <div className={classes.addLinkSection}>
            <div
              onClick={() => {
                handleAddLink()
              }}
              className={classes.addLinkBtn}
            >
              <StyledSVG src={crossAddSVG} width={18} height={18} />
            </div>
            <span>Adicionar link</span>
          </div>
        </div>
        <div className={classes.listFiles}>
          {links.length > 0 &&
            links.map((link, index) => {
              return (
                <ShowLink
                  key={`${link}-${index}`}
                  classes={classes}
                  link={link}
                  handleDeleteLinks={() => {
                    setLinks(prev => {
                      const index = prev.findIndex(
                        item => item && item === link
                      )
                      if (index !== -1) {
                        const newState = [
                          ...prev.slice(0, index),
                          ...prev.slice(index + 1)
                        ]
                        return newState
                      }
                      return prev
                    })
                  }}
                />
              )
            })}
          {isEdit &&
            materialLinksInstance.length > 0 &&
            materialLinksInstance.map((link, index) => {
              return (
                <ShowLink
                  isInstance
                  key={`${link}-${index}`}
                  classes={classes}
                  link={link}
                  handleDeleteLinks={handleDeleteMaterialFiles}
                />
              )
            })}
        </div>
        <div className={classes.scheduledDate}>
          <label htmlFor='scheduled_at'>Data e hora de publicação</label>
          <div className='inputs'>
            <Field
              component={DatePickerInput}
              id='scheduled_at'
              minDate={new Date()}
              defaultValue={
                initialValues?.scheduled_at &&
                moment(initialValues?.scheduled_at)
              }
              name='scheduled_at'
              placeholder='dd /mm /aaaa'
              disableToolbar
              className='date'
              validate={
                !alreadyFilled(initialValues.scheduled_at) && [required]
              }
            />
            <Field
              component={TimePickerInput}
              id='scheduled_at'
              name='scheduled_at'
              placeholder='00:00'
              className='hour'
              validate={
                !alreadyFilled(initialValues.scheduled_at) && [required]
              }
            />
          </div>
        </div>
        <div className={classes.bottomBtns}>
          <Button
            type='button'
            variant={'outlined'}
            className={classes.cancelBtn}
            onClick={() => history.push('/materials')}
          >
            Voltar
          </Button>
          <Button
            type='submit'
            className={classes.sendBtn}
            disabled={!valid}
            onClick={handleToggle}
          >
            {isEdit ? 'Editar' : 'Criar'}
          </Button>
        </div>
      </Form>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color='secondary' />
      </Backdrop>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('createMaterialsForm')
  const current = props.currentMaterial
  const initValues = props.isEdit
    ? {
        title: current?.title,
        description: current?.description,
        teacher_id: { label: current?.user?.name, value: current?.user_id },
        scheduled_at: current?.scheduled_at,
        grade_id: { label: current?.grade?.name, value: current?.grade_id },
        classroom_id: {
          label: current?.classroom?.name,
          value: current?.classroom_id
        },
        subject_id: {
          label: current?.subject?.name,
          value: current?.subject_id
        },
        child_id:
          current?.destinations &&
          current?.destinations?.[0]?.child_id &&
          current?.destinations?.map(destination => {
            return {
              label: destination.child.name,
              value: destination.child_id
            }
          }),
        schoolSelect: current.school_id && {
          label: current.school.name,
          value: current.school.id
        }
      }
    : {}
  return {
    userId: state.auth.user.data.id,
    materialFiles: current?.files?.filter(item => !item.link_url) || [],
    materialLinks: current?.files?.filter(item => item.link_url) || [],
    initialValues: initValues,
    schoolValue:
      selector(state, 'school_id') ||
      (state.school.currentSchool && state.school.currentSchool.school_id),
    fieldValues: getFormValues('createMaterialsForm')(state),
    schoolId:
      state.school.currentSchool && state.school.currentSchool.school_id,
    materials: state.materials.items,
    userOccupations: state.auth.currentOccupation,
    isFetching: state.materials.isFetching,
    pagination: state.materials.pagination,
    files: selector(state, 'files'),
    linkValue: selector(state, 'linkInput'),
    classroomValue: selector(state, 'classroom_id'),
    gradeValue: selector(state, 'grade_id'),
    teacherValue: selector(state, 'teacher_id'),
    isSuccess: state.materials.isSuccess
  }
}

const mapDispatchToProps = dispatch => ({
  createMaterials: data => dispatch(createMaterials(data)),
  editMaterial: data => dispatch(editMaterial(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'createMaterialsForm',
    enableReinitialize: true,
    onSubmit: async (values, dispatch, props) => {
      const teacherId = values.teacher_id || props.userId
      values?.teacher_id && delete values.teacher_id
      if (props.isEdit) {
        let destinationsToDelete = []
        if (values.child_id) {
          destinationsToDelete = props.currentMaterial.destinations.filter(
            destination => {
              return !values.child_id.find(
                child => +child.value === +destination.user_id
              )
            }
          )
        }
        props.editMaterial({
          ...values,
          teacher_id: teacherId,
          id: props.currentMaterial.id,
          destinationsToDelete,
          hasAlreadyFiles:
            props.materialFiles.length > 0 || props.materialLinks.length > 0
        })
      } else {
        props.createMaterials({
          ...values,
          teacher_id: teacherId,
          school_id: props.schoolId || props.schoolValue
        })
      }
    }
  })(CreateMaterialForm)
)
