import { createAction } from '@reduxjs/toolkit'
import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESSFUL,
  GET_CATEGORIES_FAILURE
} from './types'

export const getCategories = createAction(GET_CATEGORIES)
export const getCategoriesSuccessful = createAction(GET_CATEGORIES_SUCCESSFUL)
export const getCategoriesFailure = createAction(GET_CATEGORIES_FAILURE)
