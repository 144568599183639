export const GET_SCHOOL_CONTENT_CREATORS_BY_ID =
  'school/GET_SCHOOL_CONTENT_CREATORS_BY_ID'
export const GET_SCHOOL_CONTENT_CREATORS_BY_ID_SUCCESSFUL =
  'school/GET_SCHOOL_CONTENT_CREATORS_BY_ID_SUCCESSFUL'
export const GET_SCHOOL_CONTENT_CREATORS_BY_ID_FAILURE =
  'school/GET_SCHOOL_CONTENT_CREATORS_BY_ID_FAILURE'

export const GET_SCHOOL_ADMINISTRATOR_BY_ID =
  'school/GET_SCHOOL_ADMINISTRATOR_BY_ID'
export const GET_SCHOOL_ADMINISTRATOR_BY_ID_SUCCESSFUL =
  'school/GET_SCHOOL_ADMINISTRATOR_BY_ID_SUCCESSFUL'
export const GET_SCHOOL_ADMINISTRATOR_BY_ID_FAILURE =
  'school/GET_SCHOOL_ADMINISTRATOR_BY_ID_FAILURE'

export const SWITCH_SCHOOL = 'school/SWITCH_SCHOOL'
export const SWITCH_SCHOOL_SUCCESSFUL = 'school/SWITCH_SCHOOL_SUCCESSFUL'
export const SWITCH_SCHOOL_FAILURE = 'school/SWITCH_SCHOOL_FAILURE'

export const GET_SCHOOL_TEACHER_BY_ID = 'school/GET_SCHOOL_TEACHER_BY_ID'
export const GET_SCHOOL_TEACHER_BY_ID_SUCCESSFUL =
  'school/GET_SCHOOL_TEACHER_BY_ID_SUCCESSFUL'
export const GET_SCHOOL_TEACHER_BY_ID_FAILURE =
  'school/GET_SCHOOL_TEACHER_BY_ID_FAILURE'

export const GET_SCHOOL_COORDINATOR_BY_ID =
  'school/GET_SCHOOL_COORDINATOR_BY_ID'
export const GET_SCHOOL_COORDINATOR_BY_ID_SUCCESSFUL =
  'school/GET_SCHOOL_COORDINATOR_BY_ID_SUCCESSFUL'
export const GET_SCHOOL_COORDINATOR_BY_ID_FAILURE =
  'school/GET_SCHOOL_COORDINATOR_BY_ID_FAILURE'

export const UPDATE_LAYOUT_SCHOOL = 'school/UPDATE_LAYOUT_SCHOOL'
export const UPDATE_LAYOUT_SCHOOL_SUCCESSFUL =
  'school/UPDATE_LAYOUT_SCHOOL_SUCCESSFUL'
export const UPDATE_LAYOUT_SCHOOL_FAILURE =
  'school/UPDATE_LAYOUT_SCHOOL_FAILURE'

export const GET_SCHOOLS = 'school/GET_SCHOOLS'
export const GET_SCHOOLS_SUCCESSFUL = 'school/GET_SCHOOLS_SUCCESSFUL'
export const GET_SCHOOLS_FAILURE = 'school/GET_SCHOOLS_FAILURE'

export const SELECTED_CURRENT_SCHOOL = 'school/SELECTED_CURRENT_SCHOOL'
export const SELECTED_CURRENT_SCHOOL_SUCCESSFUL =
  'school/SELECTED_CURRENT_SCHOOL_SUCCESSFUL'
export const CLEAR_CURRENT_SCHOOL = 'school/CLEAR_CURRENT_SCHOOL'

export const GET_SCHOOL_CONFIG = 'school/GET_SCHOOL_CONFIG'
export const GET_SCHOOL_CONFIG_SUCCESSFUL =
  'school/GET_SCHOOL_CONFIG_SUCCESSFUL'
export const GET_SCHOOL_CONFIG_FAILURE = 'school/GET_SCHOOL_CONFIG_FAILURE'

export const CHANGE_SCHOOL_CONFIG = 'school/CHANGE_SCHOOL_CONFIG'
export const CHANGE_SCHOOL_CONFIG_SUCCESSFUL =
  'school/CHANGE_SCHOOL_CONFIG_SUCCESSFUL'
export const CHANGE_SCHOOL_CONFIG_FAILURE =
  'school/CHANGE_SCHOOL_CONFIG_FAILURE'
