import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import CircularProgress from '@material-ui/core/CircularProgress'

import GradeTemplateForm from '../components/form/GradeTemplateForm'
import { getGradesById } from '../store/grades/actions'

import jsonApi from '../utils/jsonApi'

const mapStateToProps = ({ grades }) => {
  return {
    currentGrade: grades.current,
    isFetching: grades.isFetching
  }
}

const mapDispatchToProps = {
  getGradesById
}

const GradeTemplatePage = ({
  match,
  getGradesById,
  currentGrade,
  isFetching
}) => {
  const { params: { gradeId } } = match
  const [initValues, setInitValues] = useState({})
  const [cycles, setCycles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (gradeId) {
      getCycles()
      getGradesById(gradeId)
    }
  }, [])

  useEffect(
    () => {
      const loadData = async () => {
        if (
          currentGrade.schedule_templates?.length > 0 &&
          currentGrade.schedule_templates[0].template
        ) {
          setIsLoading(true)
          const newThemes = {}
          const newRows = {}
          const newOrder = []
          const themesIds = await currentGrade.schedule_templates[0].template.map(
            (row, index) => {
              // create new row
              const cycle = cycles.find(c => c.value === String(row.cycle_id))
              const newRow = {
                [row.cycle_id]: {
                  name: cycle?.label,
                  cycle_id: row.cycle_id,
                  theme_ids: row.theme_ids
                }
              }
              // add row to rows
              Object.assign(newRows, newRow)
              // add row order
              newOrder.push(row.cycle_id)
              return row.theme_ids.join(',')
            }
          )
          getThemeByIds({ ids: themesIds.join(',') })
            .then(async res => {
              setIsLoading(true)
              // create new theme
              await res.data.forEach(data => {
                const newTheme = {
                  [data.id]: {
                    id: data.id,
                    theme: data.title,
                    module: data.theme_module?.title
                  }
                }
                // add theme to themes
                Object.assign(newThemes, newTheme)
              })
              setIsLoading(false)
            })
            .catch(err => console.error(err))
          const newInitialValues = {
            rows: newRows,
            rowOrder: newOrder,
            themes: newThemes
          }
          setInitValues(newInitialValues)
          setIsLoading(false)
        } else {
          setInitValues({})
        }
      }
      loadData()

      return () => {
        setInitValues({})
      }
    },
    [currentGrade]
  )

  const getThemeByIds = async ({ ids }) => {
    return jsonApi.findAll('theme', {
      params: {
        'q[id_in]': ids,
        include: 'theme_module'
      }
    })
  }

  const getCycles = async () => {
    return jsonApi
      .findAll('cycle', {
        params: {
          'page[size]': 20
        }
      })
      .then(res => {
        const options =
          res.data &&
          res.data.map(data => {
            return { label: data.name, value: data.id }
          })
        setCycles(options)
      })
  }

  if (isFetching || isLoading || cycles.length <= 0) {
    return <CircularProgress color='secondary' />
  }
  return (
    <GradeTemplateForm
      cyclesOptions={cycles}
      grade={currentGrade}
      initValues={initValues}
      history={history}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GradeTemplatePage)
