import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  skillComponent: {
    backgroundColor: 'rgba(138, 88, 141, 0.1)',
    color: '#8A588D',
    borderRadius: '4px',
    padding: '4px 7px'
  },
  skillsSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 8
  }
}))

const SkillsComponent = ({ skills }) => {
  const classes = useStyles()
  return (
    <div className={classes.skillsSection}>
      {skills?.map((skill, index) => {
        return (
          <div key={index} className={classes.skillComponent}>
            <span>{skill.name}</span>
          </div>
        )
      })}
    </div>
  )
}

export default SkillsComponent
