import React from 'react'

import { useQuery } from '../../utils/helpers'

import Label from '../label/Label'
import SearchUtilsForm from '../form/SearchUtilsForm'
import UtilsForm from '../form/UtilsForm'
import { utilsTypes } from '../../utils/constants'
import SearchBulletinsForm from '../form/SearchBulletinsForm'
import SearchStudentsBulletinForm from '../form/SearchStudentsBulletinForm'

const UtilsView = ({ title, utilType }) => {
  const query = useQuery()
  return (
    <>
      {(query.get('name') === null || query.get('name') === 'list') && (
        <div>
          {utilType === 'wrapper' ? (
            <SearchBulletinsForm />
          ) : utilType === 'bulletins' ? (
            <SearchStudentsBulletinForm />
          ) : (
            <SearchUtilsForm utilType={utilType} />
          )}
        </div>
      )}
      {query.get('name') === 'new' && (
        <div>
          <Label>{`Novo item`}</Label>
          <UtilsForm
            utilType={query.get('util') || utilType}
            utilId={query.get('id')}
          />
        </div>
      )}
      {query.get('name') === 'edit' && (
        <div>
          <Label>{`Editar ${utilsTypes[utilType]}`}</Label>
          <UtilsForm
            utilType={query.get('util') || utilType}
            isEdit
            utilId={query.get('id')}
          />
        </div>
      )}
    </>
  )
}

export default UtilsView
