import 'date-fns'
import React from 'react'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers'
import 'moment/locale/pt-br'
import StyledSVG from './StyledSVG'
import CalendarSVG from '../../assets/icons/calendar.svg'

const useStyles = makeStyles(theme => ({
  input: {
    '& div.MuiOutlinedInput-root': {
      border: 'none',
      borderRadius: '8px',
      height: '48px',
      paddingLeft: 0,
      [theme.breakpoints.down('426')]: {
        height: '35px'
      },
      '& input': {
        minWidth: 'auto',
        '&:-webkit-autofill,input:-webkit-autofill:focus': {
          transition: 'background-color 600000s 0s, color 600000s 0s'
        }
      },
      '& .MuiInputAdornment-positionEnd': {
        width: 24,
        '& .MuiIconButton-root': {
          padding: '10px 7px'
        }
      }
    },
    '& div.MuiFormControl-marginNormal': {
      margin: 0,
      width: '100%',
      [theme.breakpoints.down('426')]: {
        width: 255
      }
    },
    '& fieldset': {
      border: '1px solid #ADB8CC'
    },
    '&.MuiPickersCalendarHeader-dayLabel': {
      color: theme.palette.secondary.main
    },
    '& input:-internal-autofill-selected': {
      background: 'none'
    }
  },
  inputTime: {
    '& .MuiFormControl-marginNormal': {
      width: 100
    }
  },
  error: {
    color: theme.palette.danger.main,
    fontSize: '0.8rem'
  }
}))

const DatePickerInput = ({
  meta: { submitting, error, touched },
  input: { onBlur, value, ...inputProps },
  defaultValue,
  ...others
}) => {
  const classes = useStyles()

  const onChange = date => {
    Date.parse(date)
      ? inputProps.onChange(
          moment(date)
            .format('YYYY-MM-DD')
            .toISOString()
        )
      : inputProps.onChange(null)
  }

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={'pt-br'}
    >
      <>
        <Grid className={classes.input} container>
          <KeyboardDatePicker
            minDateMessage='Data final deve ser posterior à data inicial.'
            autoOk
            inputVariant='outlined'
            invalidDateMessage='Data inválida.'
            InputAdornmentProps={{ position: 'start' }}
            variant='inline'
            format='DD/MM/yyyy'
            margin='normal'
            value={
              value ? moment(value) : defaultValue ? moment(defaultValue) : null
            }
            disabled={submitting}
            onBlur={() =>
              onBlur(
                moment(value).isValid() ? new Date(value).toISOString() : null
              )
            }
            onChange={onChange}
            keyboardIcon={
              <StyledSVG
                width={16}
                height={16}
                color='#ADB8CC'
                src={CalendarSVG}
              />
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            {...inputProps}
            {...others}
          />
        </Grid>
        {touched && error && <span className={classes.error}>{error}</span>}
      </>
    </MuiPickersUtilsProvider>
  )
}

export const TimePickerInput = ({
  meta: { submitting, error, touched },
  input: { onBlur, value, ...inputProps },
  ...others
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('426'))
  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={'pt-br'}
    >
      <Grid
        className={`${classes.input} ${classes.inputTime}`}
        container
        justifyContent={matches ? 'space-around' : 'unset'}
      >
        <KeyboardTimePicker
          autoOk
          invalidDateMessage='Horário inválido.'
          inputVariant='outlined'
          variant='inline'
          placeholder='00:00'
          format='HH:mm'
          ampm={false}
          mask='__:__'
          margin='normal'
          value={value ? moment(value) : null}
          keyboardIcon={<ArrowDropDownIcon color='primary' />}
          {...inputProps}
          {...others}
        />
      </Grid>
      {touched && error && <span className={classes.error}>{error}</span>}
    </MuiPickersUtilsProvider>
  )
}

export default DatePickerInput
