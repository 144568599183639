import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import {
  Field,
  Form,
  formValueSelector,
  reduxForm,
  change,
  getFormValues
} from 'redux-form'

import Avatar from '@material-ui/core/Avatar'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Tooltip, Typography } from '@material-ui/core'
import { roles, eventsTypes, statusEvent } from '../../utils/constants'

import doneSVG from '../../assets/icons/status-confirmation.svg'
import pendingSVG from '../../assets/icons/status-pending.svg'
import openSVG from '../../assets/icons/status-open.svg'
import moderatingSVG from '../../assets/icons/moderating-icon.svg'
import ReactSelect from '../select/ReactSelect'
import DatePickerInput from '../shared/DatePickerInput'
import Button from '../button/Button'
import AsyncSelect from '../shared/AsyncSelect'
import CustomTable from '../table/CustomTable'
import heartSVG from '../../assets/icons/heart.svg'
import commentSVG from '../../assets/icons/comment.svg'
import LightTooltip from '../shared/LightTooltip'
import MaterialInput from '../shared/MaterialInput'
import StyledSVG from '../shared/StyledSVG'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import filterSVG from '../../assets/icons/filter-icon.svg'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ConfirmModal from '../modal/ConfirmModal'
import viewSVG from '../../assets/icons/pattern-view-icon.svg'
import editSVG from '../../assets/icons/pattern-edit-icon.svg'
import deleteSVG from '../../assets/icons/pattern-delete-icon.svg'

import { getEvents, deleteEvent } from '../../store/events/actions'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  action: {
    color: theme.palette.text.secondary,
    '&:hover': {
      background: '#F6F7F8'
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: 8,
    '& button': {
      marginRight: 0,
      width: 149
    },
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'flex-start',
      marginTop: 15,
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    [theme.breakpoints.down('350')]: {
      '& button': {
        width: '170px !important'
      }
    }
  },
  clearBtn: {
    backgroundColor: '#EF7C8F',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  searchField: {
    '& .select__menu-list': {
      maxHeight: '150px'
    },
    '& div': {
      '& .select__control': {
        minWidth: 200
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        minWidth: 'unset',
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    },
    [theme.breakpoints.down('1000')]: {
      width: '100% !important',
      '& div': {
        // redux form 'Field' componente
        '& div': {
          '& .select__control, .select__value-container, .select__indicators, .MuiInputBase-root': {
            minHeight: '35px',
            height: '35px'
          },
          maxWidth: '100%'
        }
      }
    }
  },
  avatar: {
    '& .MuiAvatar-root': {
      width: '32px !important',
      height: '32px !important'
    }
  },
  typography: {
    [theme.breakpoints.down('426')]: {
      '& p': {
        fontSize: '12px'
      }
    }
  },
  statusField: {
    '& div': {
      [theme.breakpoints.down('426')]: {
        minWidth: 'unset'
      },
      '& .select__control, .MuiInputBase-root': {
        [theme.breakpoints.down('426')]: {
          width: '255px !important'
        },
        [theme.breakpoints.down('370')]: {
          width: '65vw !important'
        }
      }
    }
  },
  createBtn: {
    backgroundColor: '#00ACDB',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px',
    [theme.breakpoints.up('890')]: {
      marginTop: 16
    },
    whiteSpace: 'nowrap'
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 45,
    [theme.breakpoints.down('890')]: {
      flexDirection: 'column-reverse',
      gap: 16
    }
  },
  searchDiv: {
    '& .MuiInputBase-root': {
      width: 300,
      [theme.breakpoints.down('426')]: {
        maxWidth: '250px'
      },
      [theme.breakpoints.down('330')]: {
        maxWidth: 220
      }
    }
  },
  searchGroup: {
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    gap: 24,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      gap: 12
    }
  },
  advancedFilter: {
    overflow: 'hidden',
    transition: 'height 0.5s ease-in-out',
    [theme.breakpoints.down('1000')]: {
      overflow: 'scroll'
    }
  },
  listTitle: {
    marginTop: 25,
    color: '#386093',
    [theme.breakpoints.down('426')]: {
      fontSize: 18
    }
  },
  statusRoot: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    [theme.breakpoints.down('426')]: {
      justifyContent: 'center'
    }
  },
  actionsSection: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& path': {
      stroke: '#868E96'
    },
    '& circle': {
      stroke: '#868E96'
    }
  },
  moderatingSvg: {
    '& path': {
      fill: '#ffffff'
    }
  }
}))

const SearchAnnouncementsForm = ({
  events,
  eventType,
  isFetching,
  getEvents,
  pagination,
  schoolId,
  handleSubmit,
  startDateValue,
  userOccupations,
  endDateValue,
  schoolValue,
  classroomValue,
  gradeValue,
  eventUserValue,
  statusValue,
  fieldValues,
  change,
  reset,
  deleteEvent,
  touch,
  userId,
  scheduledDateValue,
  createdDateValue
}) => {
  const classes = useStyles()
  const history = useHistory()
  const selectGradeRef = useRef(null)
  const selectClassroomRef = useRef(null)
  const selectEventUserRef = useRef(null)
  const selectCreatedByRef = useRef(null)
  const selectEventRef = useRef(null)
  const selectStatusRef = useRef(null)
  const selectSchoolRef = useRef(null)
  const selectNameRef = React.useRef(null)
  const [openMoreFilter, setOpenMoreFilter] = React.useState(false)
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const isCoordinator = userOccupations?.includes(roles.COORDINATOR)
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('426'))
  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    title: null,
    event_type: null,
    id: null
  })

  const defaultParams = {
    'page[size]': 5,
    'q[school_id_eq]': schoolId,
    ...(eventType !== 'review' &&
      eventType !== 'selfs' && { 'q[event_type_eq]': eventType }),
    ...(eventType !== 'selfs' && eventType !== 'review'
      ? { 'q[status_in]': [0, 1] }
      : eventType === 'review' ? { 'q[status_eq': 2 } : {}),
    'q[user_id_eq]': eventType === 'selfs' ? userId : null,
    'q[user_id_not_eq]': eventType !== 'selfs' && !isAdmin ? userId : null
  }
  let additionalParams = { isAdmin: isAdmin, isCoordinator: isCoordinator }
  const eventDateParams = () => {
    const createdDateValueAux = createdDateValue && new Date(createdDateValue)
    const sheduledDateValueAux =
      scheduledDateValue && new Date(scheduledDateValue)
    const datesParams = {
      'q[created_at_gteq]': createdDateValue
        ? moment(createdDateValue).format('YYYY-MM-DD')
        : null,
      'q[created_at_lteq]': createdDateValue
        ? moment(
            new Date(
              createdDateValueAux.setDate(createdDateValueAux.getDate() + 1)
            )
          ).format('YYYY-MM-DD')
        : null,
      'q[scheduled_at_gteq]': scheduledDateValue
        ? moment(scheduledDateValue).format('YYYY-MM-DD')
        : null,
      'q[scheduled_at_lteq]': scheduledDateValue
        ? moment(
            new Date(
              sheduledDateValueAux.setDate(sheduledDateValueAux.getDate() + 1)
            )
          ).format('YYYY-MM-DD')
        : null
    }
    return datesParams
  }

  const { 'q[user_id_not_eq]': _, ...newParams } = defaultParams

  const [searchParams, setSearchParams] = React.useState({
    ...newParams,
    ...eventDateParams(),
    'q[school_id_eq]': schoolValue,
    'q[user_id_eq]': eventType !== 'selfs' ? eventUserValue : userId,
    'q[event_type_eq]':
      eventType !== 'selfs' && eventType !== 'review' ? eventType : null
  })

  useEffect(
    () => {
      const updatedParams = { ...searchParams, ...eventDateParams() }
      updatedParams['q[destinations_classroom_id_eq]'] = classroomValue
      updatedParams['q[destinations_classroom_grade_id_eq]'] = gradeValue
      updatedParams['q[school_id_eq]'] = schoolValue
      setSearchParams(updatedParams)
    },
    [
      classroomValue,
      gradeValue,
      schoolValue,
      scheduledDateValue,
      createdDateValue
    ]
  )

  useEffect(
    () => {
      if (selectNameRef.current?.value?.length > 0) {
        setSearchParams(
          Object.assign(searchParams, {
            'q[title_cont]': selectNameRef.current?.value
          })
        )
      } else {
        setSearchParams(prev => {
          const { 'q[title_cont]': _, ...rest } = prev
          return rest
        })
      }
    },
    [selectNameRef.current?.value]
  )

  useEffect(
    () => {
      if (eventType === 'selfs') {
        Object.assign(additionalParams, { isSelf: true })
      }
      getEvents({ params: defaultParams, ...additionalParams })
    },
    [eventType]
  )

  const handleChange = (event, input) => {
    touch()
    let newValue
    if (Array.isArray(event)) {
      newValue = event?.map(v => v)
    } else {
      newValue = event ? event.value : ''
    }
    change(input.name, newValue)
  }

  const handleClearFields = () => {
    reset()
    selectGradeRef.current?.select.clearValue()
    selectClassroomRef.current?.select.clearValue()
    selectEventUserRef.current?.select.clearValue()
    selectCreatedByRef.current?.select.clearValue()
    selectEventRef.current?.select.clearValue()
    selectStatusRef.current?.select.clearValue()
    selectSchoolRef.current?.select.clearValue()
    getEvents({ params: defaultParams, ...additionalParams })
  }

  const firstPageAfterSearch = React.useRef(false)

  const handleChangeTitle = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      getEvents({
        params: { ...defaultParams, 'q[title_cont]': event.target.value },
        ...additionalParams
      })
    }
  }
  const handleSearch = () => {
    getEvents({ params: searchParams, ...additionalParams })
    firstPageAfterSearch.current = true
  }

  const columns = [
    {
      key: 'title',
      name: 'Título',
      width: '30%',
      render: function render (row) {
        let title
        matches ? (title = row.title) : (title = `${row.title.slice(0, 10)}...`)
        return (
          <Tooltip title={row.title}>
            <div className={classes.typography}>
              <Typography
                variant='subtitle2'
                component='p'
                onClick={() => {
                  eventType === 'review' && isAdmin
                    ? history.push(
                        `?id=${row.id}&name=review&event=${row.event_type}`
                      )
                    : history.push(
                        `?id=${row.id}&name=view&event=${row.event_type}`
                      )
                }}
              >
                {title}
              </Typography>
            </div>
          </Tooltip>
        )
      }
    },
    {
      key: 'school',
      name: 'Escola',
      render: function render (row) {
        return <p>{row.school?.name}</p>
      }
    },
    {
      key: 'createdBy',
      name: 'Criado por',
      render: function render (row) {
        return (
          <LightTooltip title={row.user?.name}>
            <div className={!matches ? classes.avatar : ''}>
              <Avatar>{row.user?.name[0]}</Avatar>
            </div>
          </LightTooltip>
        )
      }
    },
    {
      key: 'createDate',
      name: 'Data de Criação',
      render: row => {
        return moment(row.created_at).format('DD/MM/YYYY HH:mm')
      }
    },
    {
      key: 'scheduledDate',
      name: 'Data de Publicação',
      render: row => {
        return moment(row.scheduled_at).format('DD/MM/YYYY HH:mm')
      }
    },
    {
      key: 'comments',
      name: 'Comentários',
      render: function render (row) {
        return (
          <p>
            <img src={commentSVG} alt='icone like' />
            {String(row.event_comments?.length || '0').padStart(2, '0')}
          </p>
        )
      }
    },
    {
      key: 'status',
      name: 'Status',
      render: function render (row) {
        const statusProperties = (status, isForColor) => {
          switch (status) {
            case 'done': {
              if (isForColor) return '#00ACDB'
              return doneSVG
            }
            case 'pending': {
              if (isForColor) return '#F69E9E'
              return moderatingSVG
            }
            case 'open': {
              if (isForColor) return '#C4C4C4'
              return openSVG
            }
            case 'refused': {
              if (isForColor) return '#EF7C8F'
              return pendingSVG
            }
            default:
              return ''
          }
        }
        return (
          <div className={classes.statusRoot}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledSVG
                height={20}
                width={20}
                src={statusProperties(row.status)}
              />
            </div>
            <div>
              <span
                style={{ color: statusProperties(row.status, true) }}
                className={classes.status}
              >
                {statusEvent[row.status]}
              </span>
            </div>
          </div>
        )
      }
    },
    {
      key: 'likes',
      name: 'Curtidas',
      render: function render (row) {
        return (
          <p>
            <img src={heartSVG} alt='icone like' />
            {String(row.event_likes?.length || '0').padStart(2, '0')}
          </p>
        )
      }
    },
    {
      key: 'actions',
      name: 'Ações',
      // align: 'right',
      render: function render (row) {
        return (
          <div className={classes.actionsSection}>
            <Tooltip title='Visualizar'>
              <div
                onClick={() =>
                  history.push(
                    `?id=${row.id}&name=view&event=${row.event_type}`
                  )
                }
              >
                <StyledSVG width={24} height={24} src={viewSVG} />
              </div>
            </Tooltip>
            {(isAdmin || row?.user?.id === userId) && (
              <Tooltip title='Editar'>
                <div
                  onClick={() => {
                    history.push(
                      `?id=${row.id}&name=edit&event=${row.event_type}`
                    )
                  }}
                >
                  <StyledSVG width={24} height={24} src={editSVG} />
                </div>
              </Tooltip>
            )}
            {isAdmin && (
              <Tooltip title='Excluir'>
                <div
                  onClick={() => {
                    setConfirmModal({
                      id: row.id,
                      title: row.title,
                      event_type: eventsTypes[row.event_type],
                      open: true
                    })
                  }}
                >
                  <StyledSVG width={24} height={24} src={deleteSVG} />
                </div>
              </Tooltip>
            )}
            {isAdmin &&
              row.status === 'pending' && (
                <Tooltip title='Moderar'>
                  <div
                    className={classes.moderatingSvg}
                    onClick={() =>
                      history.push(
                        `?id=${row.id}&name=review&event=${row.event_type}`
                      )
                    }
                  >
                    <StyledSVG width={20} height={20} src={moderatingSVG} />
                  </div>
                </Tooltip>
              )}
          </div>
        )
      }
    }
  ]

  const getColumns = () => {
    const columnKeys = isVersarAdmin
      ? [
          'title',
          'school',
          'createdBy',
          'createDate',
          'scheduledDate',
          'comments',
          'status',
          'likes',
          'actions'
        ]
      : [
          'title',
          'createdBy',
          'createDate',
          'scheduledDate',
          'comments',
          'status',
          'likes',
          'actions'
        ]

    return columns.filter(column => columnKeys.includes(column.key))
  }

  // let appID = process.env.REACT_APP_PROJECT_ID

  return (
    <div className={classes.root}>
      <Form onSubmit={handleSubmit}>
        {/* Header */}
        <div>
          {/* content */}
          <div className={classes.searchContainer}>
            <div className={classes.searchDiv}>
              <strong style={{ marginBottom: 10 }}>Busca</strong>
              <Field
                component={MaterialInput}
                ref={selectNameRef}
                name='title'
                endBtnFunction={() => {
                  getEvents({
                    params: {
                      ...defaultParams,
                      'q[title_cont]': fieldValues?.title
                    },
                    ...additionalParams
                  })
                }}
                onKeyDown={handleChangeTitle}
                placeholder={`Título d${
                  eventType === 'images'
                    ? `a ${
                        eventsTypes[eventType]
                          ? eventsTypes[eventType]
                          : 'comunicado'
                      }`
                    : `o ${
                        eventsTypes[eventType]
                          ? eventsTypes[eventType]
                          : 'comunicado'
                      }`
                }`}
                type='text'
              />
            </div>
            {eventType !== 'review' &&
              eventType !== 'selfs' && (
                <Button
                  className={classes.createBtn}
                  id='create_announcement'
                  type='button'
                  onClick={() => history.push(`?name=new&event=${eventType}`)}
                >
                  {`Criar`}
                </Button>
              )}
          </div>
          {/* advanced filters */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <StyledSVG src={filterSVG} height={12} width={12} />
              <p
                onClick={() => setOpenMoreFilter(prev => !prev)}
                style={{
                  cursor: 'default',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#386093'
                }}
              >
                Filtros avançados
              </p>
              {!openMoreFilter ? (
                <KeyboardArrowDownIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              )}
            </div>
            <div
              className={classes.advancedFilter}
              style={{
                height: openMoreFilter ? '250px' : '0px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: 24
                }}
              >
                <div
                  className={`${classes.searchGroup}`}
                  style={{ opacity: openMoreFilter ? '1' : '0' }}
                >
                  <div
                    className={`dates ${classes.searchField}`}
                    style={{ marginBottom: '15px' }}
                  >
                    <strong style={{ marginBottom: 10 }} htmlFor='created_at'>
                      Criação
                    </strong>
                    <Field
                      component={DatePickerInput}
                      id='created_at'
                      name='created_at'
                      placeholder='dd /mm /aaaa'
                      disableToolbar
                    />
                  </div>
                  <div
                    className={`dates ${classes.searchField}`}
                    style={{ marginRight: '16px' }}
                  >
                    <strong style={{ marginBottom: 10 }} htmlFor='Publicação'>
                      Publicação
                    </strong>
                    <Field
                      component={DatePickerInput}
                      id='scheduled_at'
                      name='scheduled_at'
                      placeholder='dd /mm /aaaa'
                      disableToolbar
                    />
                  </div>
                  {userOccupations?.includes(roles.VERSAR_ADMIN) && (
                    <div className={classes.searchField}>
                      <strong htmlFor='school_id'>Escola</strong>
                      <Field
                        component={AsyncSelect}
                        selectRef={selectSchoolRef}
                        defaultOptions={false}
                        id='school_id'
                        name='school_id'
                        placeholder='Digite ou selecione a escola'
                        handleAsyncChange={handleChange}
                        searchParam='q[name_cont]'
                        request={{
                          path: 'school'
                        }}
                        touch={touch}
                      />
                    </div>
                  )}
                  <div className={classes.searchField}>
                    <strong style={{ marginBottom: 10 }} htmlFor='grade_id'>
                      Série
                    </strong>
                    <Field
                      component={AsyncSelect}
                      defaultOptions={false}
                      selectRef={selectGradeRef}
                      cacheUniqs={[schoolValue]}
                      id='grade_id'
                      name='grade_id'
                      placeholder='Digite ou selecione'
                      handleAsyncChange={handleChange}
                      searchParam='q[name_cont]'
                      request={{
                        path: 'grade',
                        params: {
                          'q[school_id_null]': true
                        }
                      }}
                      touch={touch}
                    />
                  </div>
                  <div className={classes.searchField}>
                    <strong style={{ marginBottom: 10 }} htmlFor='classroom_id'>
                      Turma
                    </strong>
                    <Field
                      component={AsyncSelect}
                      defaultOptions={false}
                      id='classroom_id'
                      name='classroom_id'
                      selectRef={selectClassroomRef}
                      cacheUniqs={[gradeValue, schoolValue]}
                      placeholder='Digite ou selecione'
                      handleAsyncChange={handleChange}
                      searchParam='q[name_cont]'
                      request={{
                        path: 'classroom',
                        params: {
                          'q[school_id_in]': schoolId || schoolValue,
                          'q[grade_id_in]': gradeValue,
                          'q[year_lteq]': new Date().getFullYear() + 1,
                          'q[year_gteq]': new Date().getFullYear()
                        }
                      }}
                      touch={touch}
                    />
                  </div>
                  <div
                    style={eventType !== 'selfs' ? { display: 'none' } : {}}
                    className={`${classes.statusField} ${classes.searchField}`}
                  >
                    <strong style={{ marginBottom: 10 }} htmlFor='status'>
                      Status
                    </strong>
                    <Field
                      component={ReactSelect}
                      selectRef={selectStatusRef}
                      id='status'
                      name='status'
                      isClearable
                      placeholder='Selecione o status'
                      handleChange={handleChange}
                      defaultValue={statusValue || fieldValues?.status}
                      touch={touch}
                      options={[
                        { label: 'Em aberto', value: '0' },
                        {
                          label: 'Finalizado',
                          value: '1'
                        },
                        {
                          label: 'Aguardando moderação',
                          value: '2'
                        },
                        { label: 'Recusado', value: '3' }
                      ]}
                    />
                  </div>
                  <div />
                </div>
                <div
                  style={{
                    opacity: openMoreFilter ? '1' : '0',
                    transition: 'opacity 0.3s ease-in-out'
                  }}
                  className={`${classes.buttons}`}
                >
                  <Button
                    className={classes.clearBtn}
                    onClick={() => {
                      handleClearFields()
                      setOpenMoreFilter(false)
                    }}
                  >
                    Limpar
                  </Button>
                  <Button
                    onClick={handleSearch}
                    type='button'
                    className={classes.applyBtn}
                  >
                    Aplicar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* horizontal bar */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              height: 1,
              border: '#ADB8CC solid 1px'
            }}
          />
        </div>
      </Form>
      <h1 className={classes.listTitle}>{`Lista de ${
        eventsTypes[eventType] ? eventsTypes[eventType] : 'comunicado'
      }s`}</h1>
      <CustomTable
        from={'announcements'}
        columns={getColumns()}
        data={events}
        columnsPointer={[0]}
        border={'none'}
        bodyBorder={'none'}
        headCellRadius
        headColor={'rgba(173, 184, 204, 0.3)'}
        isFetching={isFetching}
        fetchItems={getEvents}
        isAdmin={isAdmin}
        params={searchParams}
        pagination={pagination}
        firstPageAfterSearch={firstPageAfterSearch}
      />
      <ConfirmModal
        title={`Deseja remover ${
          confirmModal.title
        } da lista de ${confirmModal?.event_type?.toLowerCase()} ?`}
        open={confirmModal.open}
        setOpen={value => {
          if (value) {
            setConfirmModal({ ...confirmModal, open: value })
          } else {
            setConfirmModal({
              id: null,
              title: '',
              event_type: '',
              open: value
            })
          }
        }}
        onConfirm={() => {
          deleteEvent(confirmModal.id)
        }}
      />
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('searchAnnouncementsForm')

  return {
    events: state.events.items,
    isFetching: state.events.isFetching,
    pagination: state.events.pagination,
    userOccupations: state.auth.currentOccupation,
    userId: state.auth.user.data && state.auth.user.data.id,
    schoolId:
      state.school.currentSchool && state.school.currentSchool.school_id,
    startDateValue: selector(state, 'start_at'),
    endDateValue: selector(state, 'end_at'),
    schoolValue:
      selector(state, 'school_id') ||
      (state.school.currentSchool && state.school.currentSchool.school_id),
    classroomValue: selector(state, 'classroom_id'),
    scheduledDateValue: selector(state, 'scheduled_at'),
    createdDateValue: selector(state, 'created_at'),
    gradeValue: selector(state, 'grade_id'),
    statusValue: selector(state, 'status'),
    eventUserValue: selector(state, 'eventUser'),
    fieldValues: getFormValues('searchAnnouncementsForm')(state)
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) =>
    dispatch(change('searchAnnouncementsForm', field, data)),
  getEvents: data => dispatch(getEvents(data)),
  deleteEvent: data => dispatch(deleteEvent(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'searchAnnouncementsForm'
  })(SearchAnnouncementsForm)
)
