import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Field, Form, reduxForm } from 'redux-form'
import Chart from '../shared/VerticalBarChart'
import MaterialInput from '../shared/MaterialInput'
import PlataformViewChart from '../shared/PlataformViewChart'
import TotallyViewChart from '../shared/TotallyViewChart'
import Label from '../label/Label'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button from '../button/Button'
import { showAlertMessage } from '../../store/alert/actions'
import { getEventById } from '../../store/events/actions'
import PaginatedAsyncTable from '../table/PaginatedAsyncTable'
import moment from 'moment'
import { occupationsObject } from '../../utils/dicts'
import StyledSVG from '../shared/StyledSVG'
import webSVG from '../../assets/icons/web-icon.svg'
import androidSVG from '../../assets/icons/android-icon.svg'
import iosSVG from '../../assets/icons/ios-icon.svg'
import emailSVG from '../../assets/icons/mail-icon.svg'
import withoutViewSVG from '../../assets/icons/event-without-views.svg'
import { LinearProgress } from '@material-ui/core'

import ShowOvercontentTable from '../table/ShowOvercontentTable'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '20px',
    padding: '30px 50px',
    backgroundColor: '#fff',
    borderRadius: 16
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    '& h2.label': {
      margin: 0
    },
    '& h2.title': {
      marginTop: '16px',
      marginBottom: '16px',
      fontSize: '20px'
    },
    '& p': {
      [theme.breakpoints.up('800')]: {
        maxWidth: '40vw'
      },
      [theme.breakpoints.up('1000')]: {
        maxWidth: '25vw'
      }
    }
  },
  bottomButtons: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'center'
    }
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  graphs: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('1550')]: {
      flexWrap: 'wrap',
      gap: 16
    },
    [theme.breakpoints.down('1220')]: {
      justifyContent: 'center'
    }
  },
  searchDiv: {
    '& .MuiInputBase-root': {
      width: 300,
      [theme.breakpoints.down('426')]: {
        maxWidth: '250px'
      },
      [theme.breakpoints.down('330')]: {
        maxWidth: 220
      }
    }
  },
  withoutViewSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& p': {
      color: '#4D5E80',
      fontWeight: 700,
      maxWidth: '50%',
      fontSize: 18,
      textAlign: 'center'
    },
    [theme.breakpoints.down('1700')]: {
      '& p': {
        maxWidth: '65%'
      }
    },
    [theme.breakpoints.down('1400')]: {
      '& p': {
        maxWidth: '80%'
      }
    },
    [theme.breakpoints.down('800')]: {
      '& p': {
        maxWidth: '90%'
      },
      '& svg': {
        width: 230,
        minWidth: 230
      }
    },
    [theme.breakpoints.down('426')]: {
      '& p': {
        fontSize: 14
      },
      '& svg': {
        width: 200,
        minWidth: 200
      }
    }
  }
}))

const AnnouncementDetailsView = ({
  eventId,
  currentEvent,
  userOccupations,
  getEventById,
  eventDetail,
  isFetching,
  handleSubmit,
  onSubmit
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [data, setData] = React.useState()
  const dataGraph = [
    {
      key: 'Pais',
      value: {
        recipients: eventDetail?.parent_view_info?.parents_recipients,
        views: eventDetail?.parent_view_info?.parents_views
      }
    },
    {
      key: 'Professor',
      value: {
        recipients: eventDetail?.teacher_view_info?.teachers_recipients,
        views: eventDetail?.teacher_view_info?.teachers_views
      }
    },
    {
      key: 'Alunos',
      value: {
        recipients: eventDetail?.student_view_info?.students_recipients,
        views: eventDetail?.student_view_info?.students_views
      }
    },
    {
      key: 'Aplicativo',
      value: {
        recipients: eventDetail?.app_view_info?.total_recipients,
        views: eventDetail?.app_view_info?.seen_by_app
      }
    },
    {
      key: 'Email',
      value: {
        recipients: eventDetail?.email_view_info?.total_recipients,
        views: eventDetail?.email_view_info?.seen_by_email
      }
    },
    {
      key: 'Web',
      value: {
        recipients: eventDetail?.web_view_info?.total_recipients,
        views: eventDetail?.web_view_info?.seen_by_web
      }
    }
  ]
  const hasDataToGraph = dataGraph.some(item => {
    if (
      item.key === 'Pais' ||
      item.key === 'Professor' ||
      item.key === 'Alunos'
    ) {
      return item.value.views > 0
    }
  })
  React.useEffect(() => {
    eventId && getEventById({ id: eventId, withoutDestinations: true })
  }, [])

  React.useEffect(
    () => {
      currentEvent.event_views &&
        setData(
          currentEvent.event_views.map(item => {
            const occupationsByEmail =
              item.viewing_platform &&
              item.viewing_platform.toLowerCase() === 'email'
                ? item?.viewer_role?.split(',')?.map((item, index, array) => {
                    if (index === array.length - 1) {
                      return `${occupationsObject[item.trim()]}`
                    } else {
                      return `${occupationsObject[item.trim()]}, `
                    }
                  })
                : undefined
            return {
              name: item.user.name,
              email: item.user.email,
              occupation:
                occupationsByEmail ||
                occupationsObject[item?.viewer_role] ||
                '-',
              classrooms: item?.user?.classrooms || '-',
              date: moment(item.created_at).format('DD/MM/YYYY - HH:mm'),
              by: item?.viewing_platform
            }
          })
        )
    },
    [currentEvent.event_views]
  )

  const getViewingPlatformIcon = platform => {
    switch (platform.toLowerCase()) {
      case 'web':
        return webSVG
      case 'android':
        return androidSVG
      case 'ios':
        return iosSVG
      case 'email':
        return emailSVG
      default:
        return null
    }
  }

  const columns = [
    {
      key: 'users',
      name: 'Usuários',
      render: function render (row) {
        return row.name
      }
    },
    {
      key: 'occupation',
      name: 'Perfil',
      render: function render (row) {
        return row.occupation
      }
    },
    {
      key: 'classroom',
      name: 'Turma',
      render: function render (row) {
        const classroom = row?.classrooms?.map(item => item.name)
        if (classroom.length > 0) {
          return <ShowOvercontentTable item={classroom} />
        } else {
          return '-'
        }
      }
    },
    {
      key: 'date',
      name: 'Data e hora',
      render: function render (row) {
        return row.date
      }
    },
    {
      key: 'by',
      name: 'Via',
      render: function render (row) {
        const by = row?.by?.charAt(0)?.toUpperCase() + row?.by?.slice(1)
        if (row?.by) {
          return (
            <div style={{ display: 'flex' }}>
              {row.by && (
                <StyledSVG
                  color={'#7B7B7B'}
                  height={20}
                  width={20}
                  src={getViewingPlatformIcon(row.by)}
                />
              )}
              <span style={{ marginLeft: '7px' }}>{by}</span>
            </div>
          )
        } else {
          return (
            <div style={{ display: 'flex' }}>
              <StyledSVG
                color={'#7B7B7B'}
                height={20}
                width={20}
                src={getViewingPlatformIcon('Ios')}
              />
              <span style={{ marginLeft: '7px' }}>{'Ios'}</span>
            </div>
          )
        }
      }
    }
  ]

  const handleChangeTitle = event => {
    const inputValue = event.target.value.toLowerCase()
    setData(prev => {
      const prevStateCopy = [...prev]
      const ordenedData = prevStateCopy.sort((a, b) => {
        const containsInputA = a?.[event.target.name]
          .toLowerCase()
          .includes(inputValue)
        const containsInputB = b?.[event.target.name]
          .toLowerCase()
          .includes(inputValue)

        if (containsInputA && !containsInputB) {
          return -1
        } else if (!containsInputA && containsInputB) {
          return 1
        } else {
          return 0
        }
      })
      return ordenedData
    })
  }

  if (isFetching) return <LinearProgress color='secondary' />
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Label className={'label'} id={'idlabel'}>
          Visualizado por
        </Label>
        <p style={{ color: '#555555' }}>
          Fornecemos dados cruciais para aprimorar suas estratégias de alcance:
          entregue, visualização, comentários e etc.
        </p>
        <Label className={'title'}>{currentEvent.title?.toUpperCase()}</Label>
      </div>
      {hasDataToGraph ? (
        <div className={classes.graphs}>
          <TotallyViewChart props={eventDetail?.recipients_count} />
          <Chart
            props={dataGraph.filter(item =>
              ['Pais', 'Professor', 'Alunos'].includes(item.key)
            )}
          />
          <PlataformViewChart
            props={dataGraph.filter(item =>
              ['Aplicativo', 'Email', 'Web'].includes(item.key)
            )}
          />
        </div>
      ) : (
        <div className={classes.withoutViewSection}>
          <StyledSVG height={330} width={315} src={withoutViewSVG} />
          <p>
            Estamos em pausa no momento. Seu comunicado foi enviado, mas ainda
            não foi visualizado. Vamos dar mais um tempinho e logo você terá os
            dados que deseja.
          </p>
        </div>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ marginBottom: 50 }}>
          <h2 id='modal-title'>Sobre os usuários</h2>
          <div className={classes.searchDiv}>
            <strong style={{ marginBottom: 10 }}>Busca</strong>
            <Field
              component={MaterialInput}
              name='name'
              onKeyDown={evet => handleChangeTitle(evet)}
              placeholder={'Digite o nome do usuário'}
              type='text'
            />
          </div>
        </div>
      </Form>
      {data &&
        data?.length > 0 && (
          <PaginatedAsyncTable
            columns={columns}
            headBorder={'none'}
            borderBottom={'none'}
            headCellRadius
            headColor={'rgba(173, 184, 204, 0.3)'}
            pageSize={10}
            data={data}
          />
        )}
      <div className={classes.bottomButtons}>
        <Button
          variant='outlined'
          className={classes.backBtn}
          type='button'
          onClick={() => {
            currentEvent.id &&
              history.push(
                `/announcements?id=${eventId}&name=view&event=${
                  currentEvent?.event_type
                }`
              )
          }}
        >
          Voltar
        </Button>
      </div>
    </div>
  )
}
const mapDispatchToProps = dispatch => ({
  getEventById: id => dispatch(getEventById(id)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    )
})

const mapStateToProps = (state, props) => {
  return {
    currentEvent: state.events.currentItem,
    isFetching: state.events.isFetching,
    userOccupations: state.auth.currentOccupation
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(
//   AnnouncementDetailsView
// )

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'searchEventViewers',
    enableReinitialize: true,
    touchOnChange: true,
    touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {}
  })(AnnouncementDetailsView)
)
