import React from 'react'
import { connect } from 'react-redux'
import {
  reduxForm,
  Field,
  change,
  FieldArray,
  Form,
  getFormValues,
  formValueSelector
} from 'redux-form'
import { useHistory } from 'react-router-dom'
import {
  normalizePhone,
  normalizeCPF,
  normalizeCEP,
  capitalize,
  onlyNums,
  zipcodeUrl,
  // required,
  validFullname,
  isValidCPF
} from '../../utils/formUtils'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import ToggleButton from '@material-ui/lab/ToggleButton'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import StyledSVG from '../shared/StyledSVG'
import imgSVG from '../../assets/icons/img.svg'
import ListImg from '../shared/ListImg'
import { handleAddAvatar } from '../../utils/helpers'
import heic2any from 'heic2any'
import {
  getChildrenByTaxpayerRSPA,
  getClassroomByChildrenIdRSPA,
  createChildrenWithParents
} from '../../store/classrooms/actions'

import Label from '../label/Label'
import MaterialInput from '../shared/MaterialInput'
import { Box } from './styles/CreateModuleForm.style'
import Button from '../button/Button'

import CheckboxInput from '../input/CheckboxInput'
import DatePickerInput from '../shared/DatePickerInput'
import { showAlertMessage } from '../../store/alert/actions'
import { backToThePage } from '../../store/pagination/actions'
import ParentForm from './ParentForm'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    margin: 0,
    borderRadius: '8px',
    lineHeight: '20.11px !important',
    fontSize: 16,
    minWidth: 300,
    paddingLeft: '1.5rem'
  },
  addAvatarBtn: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    '& button': {
      backgroundColor: '#F6F7F8',
      border: 'none',
      borderRadius: '50% !important',
      marginRight: 16,
      padding: 7
    },
    '& button.Mui-selected': {
      backgroundColor: '#F6F7F8'
    },
    '& > div.MuiToggleButtonGroup-root': {
      marginBottom: '0px !important'
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  photoSection: {
    position: 'relative',
    border: '1px dashed #ADB8CC',
    borderRadius: '7px',
    width: 'fit-content',
    padding: '25px'
  },
  rmvBtn: {
    position: 'absolute',
    zIndex: 1,
    right: '-1px',
    top: '-1px',
    color: 'red',
    margin: '5px'
  },
  img: {
    maxWidth: '210px',
    maxHeight: '210px',
    position: 'relative'
  },
  btnStyle: {
    '& button': {
      height: 40,
      minWidth: 150
    }
  },
  btnDashed: {
    '& button': {
      borderStyle: 'dashed',
      fontWeight: 400,
      minWidth: 350,
      '&:hover': {
        borderStyle: 'dashed'
      }
    }
  },
  switch: {
    marginLeft: '0 !important',
    '& span:nth-of-type(2)': {
      fontSize: '16px !important',
      marginLeft: '0 !important'
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  parentContainer: {
    border: `1px dashed ${theme.palette.secondary}`
  },
  bull: {
    color: theme.palette.primary.dark,
    fontSize: '2rem',
    verticalAlign: 'text-bottom',
    marginLeft: '0.8rem'
  },
  removeParentIcon: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  checkChildIsParent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& p': {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '24px',
      color: '#00ACDB'
    },
    '& svg': {
      fill: '#00ACDB'
    },
    [theme.breakpoints.down('426')]: {
      '& span': {
        padding: '9px 9px 9px 0px'
      }
    },
    [theme.breakpoints.down('360')]: {
      '& p': {
        fontSize: 14,
        lineHeight: '18px'
      }
    }
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  saveBtn: {
    backgroundColor: '#386093',
    width: 165,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  searchBtn: {
    marginLeft: 12,
    width: 146,
    border: '1px solid #00ACDB',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#00ACDB',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  userTypeLabel: {
    color: '#4D5E80'
  }
}))

const requiredField = 'Campo Obrigatório *'
const validate = values => {
  const errors = {}
  if (!values.name) {
    errors.name = requiredField
  }
  if (!values.taxpayer_number && !values.isEdit) {
    errors.taxpayer_number = requiredField
  }
  if (values.name && validFullname(values.name)) {
    errors.name = 'Não são permitidos números ou caracteres especiais'
  }
  if (values.childIsParent && !values.email) {
    errors.email = requiredField
  }
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Endereço de email inválido'
  }
  if (!values.parents || !values.parents.length) {
    errors.parents = 'Adicionar responsável'
  } else {
    const parentsArrayErros = []
    values.parents.forEach((parent, index) => {
      const parentErrors = {}
      if (!parent || !parent.name) {
        parentErrors.name = requiredField
        parentsArrayErros[index] = parentErrors
      }
      if (parent.name && validFullname(parent.name)) {
        parentErrors.name = 'Não são permitidos números ou caracteres especiais'
        parentsArrayErros[index] = parentErrors
      }
      if (!parent || !parent.email) {
        parentErrors.email = requiredField
        parentsArrayErros[index] = parentErrors
      }
      if (!parent.taxpayer_number && !values.isEdit) {
        parentErrors.taxpayer_number = requiredField
        parentsArrayErros[index] = parentErrors
      }
      if (
        parent &&
        parent.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(parent.email)
      ) {
        parentErrors.email = 'Endereço de email inválido'
        parentsArrayErros[index] = parentErrors
      }
      if (
        parent &&
        parent.phone &&
        (onlyNums(parent.phone).length < 10 ||
          onlyNums(parent.phone).length > 11)
      ) {
        parentErrors.phone = 'Tamanho do número de telefone inválido'
        parentsArrayErros[index] = parentErrors
      }
    })
    if (parentsArrayErros.length) {
      errors.parents = parentsArrayErros
    }
  }
  return errors
}

const RenderParentsFields = ({
  fields,
  isEdit,
  initialValues,
  meta: { touched, error, submitFailed }
}) => {
  const classes = useStyles()

  // para iniciar com o form aberto quando não tiver valor
  fields && fields.length === 0 && fields.push({})
  return (
    <>
      {fields.map((parent, index) => (
        <div key={`parent[${index}]`}>
          {fields.length > 1 && (
            <Grid item xs={12} className={classes.removeParentIcon}>
              <Tooltip title='Remover responsável'>
                <HighlightOffIcon onClick={() => fields.remove(index)} />
              </Tooltip>
            </Grid>
          )}
          <ParentForm
            isEdit={isEdit}
            parent={parent}
            fields={fields}
            index={index}
            className={classes.parentContainer}
            initialValues={initialValues}
          />
        </div>
      ))}
      <Grid item xs={12} sm={6} className={`${classes.btnDashed}`}>
        <Button
          color='secondary'
          type='button'
          variant='outlined'
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => fields.push({})}
        >
          Add Responsável
        </Button>
        {submitFailed && error && <span>{error}</span>}
      </Grid>
    </>
  )
}

const CreateStudentForm = ({
  handleSubmit,
  onSubmit,
  pristine,
  change,
  submitSucceeded,
  valid,
  classroomId,
  currentGrade,
  currentClassroomChild,
  fieldValues,
  taxpayerValue,
  initialValues,
  alert,
  backToThePage,
  currentPagination,
  getChildrenByTaxpayerRSPA,
  getClassroomByChildrenIdRSPA,
  zipCodeValue
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const imageRef = React.useRef(null)
  const [imagesElements, setImagesElements] = React.useState({ img: null })
  const [uploadingInProgress, setUploadingInProgress] = React.useState(false)
  const [childIsParent, setChildIsParent] = React.useState(false)
  const [
    childrenTaxpayerNumberIsValid,
    setChildrenTaxpayerNumberIsValid
  ] = React.useState(false)
  const [
    parentTaxpayerNumberIsValid,
    setParentTaxpayerNumberIsValid
  ] = React.useState(false)

  // create account only to Fundamental II
  const createAccount =
    currentGrade &&
    (String(currentGrade.segment_id) === '3' ||
      String(currentGrade.segment_id) === '4')
  const canCreateWithoutParent =
    currentGrade && String(currentGrade.segment_id) === '4'
  const handleToggle = () => {
    setOpen(!open)
  }
  const goBack = () => {
    if (history.location.state?.allowGoBack) {
      backToThePage({ ...currentPagination, canGoBack: true })
      history.push('/settings/classrooms')
    } else {
      history.goBack()
    }
  }
  React.useEffect(() => {
    if (
      currentClassroomChild.id &&
      currentClassroomChild?.child_parents?.length < 1
    ) {
      setChildIsParent(true)
      change('childIsParent', true)
    }
  }, [])

  currentClassroomChild?.id ? change('isEdit', true) : undefined
  submitSucceeded && history.push(`/settings/classrooms/${classroomId}`)

  const searchZipcopde = () => {
    let newZipcode = zipCodeValue?.replace(/[^0-9]/g, '')
    if (newZipcode?.length !== 8) {
      alert('O cep deve possuir 8 números', 'info')
      return
    }
    fetch(zipcodeUrl(newZipcode))
      .then(res => res.json())
      .then(data => {
        if (data.erro) {
          // setOpen(!open)
          alert(`O cep ${zipCodeValue} não foi encontrado`, 'warning')
        } else {
          change('address.street', data.logradouro)
          change('address.neighbourhood', data.bairro)
        }
      })
      .catch(error => console.error(error))
  }

  const handleCheck = event => {
    change('childIsParent', event.target.checked)
    setChildIsParent(event.target.checked)
  }
  const validateTaxpayerNumber = (cpf, occupation) => {
    cpf = cpf && onlyNums(cpf)
    if (fieldValues?.isEdit) {
      if (cpf && cpf.length < 11 && cpf.length > 0) {
        occupation === 'parent'
          ? setParentTaxpayerNumberIsValid(false)
          : setChildrenTaxpayerNumberIsValid(false)
      } else {
        occupation === 'parent'
          ? setParentTaxpayerNumberIsValid(true)
          : setChildrenTaxpayerNumberIsValid(true)
      }
    } else if (cpf && cpf.length === 11) {
      if (!isValidCPF(cpf, true)) {
        alert(
          `CPF do ${
            occupation === 'parent' ? 'reponsável' : 'aluno'
          } inválido.`,
          'warning'
        )
        occupation === 'parent'
          ? setParentTaxpayerNumberIsValid(false)
          : setChildrenTaxpayerNumberIsValid(false)
      } else {
        occupation === 'parent'
          ? setParentTaxpayerNumberIsValid(true)
          : setChildrenTaxpayerNumberIsValid(true)
      }
    } else {
      occupation === 'parent'
        ? setParentTaxpayerNumberIsValid(false)
        : setChildrenTaxpayerNumberIsValid(false)
    }
  }
  React.useEffect(
    () => {
      validateTaxpayerNumber(fieldValues?.taxpayer_number, 'children')
      fieldValues?.parents &&
        fieldValues?.parents.map(parent =>
          validateTaxpayerNumber(parent?.taxpayer_number, 'parent')
        )
    },
    [fieldValues?.taxpayer_number, fieldValues?.parents]
  )

  React.useEffect(
    () => {
      if (!fieldValues?.taxpayer_number) {
        change('childrenId', null)
      }
    },
    [fieldValues]
  )

  const handleDeleteChildAvatar = (deleteFromDataBase = false) => {
    setImagesElements(prevState => {
      const newState = {
        img: deleteFromDataBase === true ? 'delete' : null
      }
      change('image', newState.img)
      return newState
    })
  }

  const handleOnChangeAvatar = event => {
    handleAddAvatar(
      event,
      setUploadingInProgress,
      alert,
      param =>
        setImagesElements(prevState => {
          const newState = {
            img: param
          }
          change('image', newState.img)
          return newState
        }),
      heic2any
    )
  }

  const searchChildrenByTaxpayerNumber = async () => {
    try {
      await getChildrenByTaxpayerRSPA({ taxpayerValue })
        .then(res => {
          if (res.data.data && res.data.data.length === 1) {
            const response = res.data
            const changeChildIfHasEmail = (
              email = undefined,
              id = undefined
            ) => {
              change('email', email)
              change('userId_child', id)
            }
            const changeParents = parents => {
              let parentsObjects = []
              parents.forEach(item => {
                const childParent = response.included.find(include => {
                  return (
                    include.type === 'child_parents' &&
                    +include.attributes.user_id === +item.id
                  )
                })
                parentsObjects.push({
                  id: item.id,
                  name: item.attributes.name,
                  email: item.attributes.email,
                  phone: item.attributes.phone,
                  address: item.attributes.address,
                  taxpayer_number: item.attributes.taxpayer_number,
                  child_parent: {
                    relationship_type: childParent.attributes.relationship_type,
                    description: childParent.attributes.description
                  }
                })
              })
              change('parents', parentsObjects)
            }
            const changeChildrenData = child => {
              change('name', child.attributes.name)
              change('birthdate', child?.attributes?.birthdate)
              change('taxpayer_number', child?.attributes?.taxpayer_number)
              change('avatar_url', child?.attributes?.avatar_url)
            }
            const parentsUsers = response.included.filter(item => {
              return (
                item.type === 'users' &&
                item.attributes.occupations.includes('parent')
              )
            })
            const studentUser = response.included.filter(item => {
              return (
                item.type === 'users' &&
                item.attributes.occupations.includes('student')
              )
            })
            const childrenData = response.data[0]
            childrenData && changeChildrenData(childrenData)
            studentUser && studentUser.length > 0
              ? changeChildIfHasEmail(
                  studentUser[0]?.attributes?.email,
                  studentUser[0]?.id
                )
              : changeChildIfHasEmail()
            parentsUsers &&
              parentsUsers.length > 0 &&
              changeParents(parentsUsers)
            return childrenData
          } else {
            alert(`Aluno não encontrado.`, 'warning')
          }
        })
        .then(res => {
          if (res) {
            getClassroomByChildrenIdRSPA({ childId: res.id }).then(res => {
              if (res.data.data.length > 0) {
                const include = res.data.included.find(
                  incl => incl.type === 'children'
                )
                change('childrenId', include.id)
              }
            })
          }
        })
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <div className={`${classes.root}`}>
      {/* <HeadFormTab currentPage={'students'} /> */}
      <Box>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Label className={classes.userTypeLabel}>Aluno(a)</Label>
            </Grid>
            <Grid item xs={12} sm={7}>
              <label htmlFor='name'>
                Nome do(a) Aluno(a)
                <span className={classes.bull}>&bull;</span>
              </label>
              <Field
                component={MaterialInput}
                name='name'
                placeholder='Nome do(a) aluno(a)'
                type='text'
                autoComplete='off'
                normalize={capitalize}
                autoFocus
              />
            </Grid>
            <Grid item>
              <label htmlFor='bithdate'>Data de Nascimento</label>
              <Field
                component={DatePickerInput}
                name='birthdate'
                placeholder='Data de nascimento do(a) aluno(a)'
                disableFuture
              />
            </Grid>
            {createAccount && (
              <Grid item xs={12} sm={5}>
                <label htmlFor='email'>
                  Email
                  {childIsParent && (
                    <span
                      style={
                        currentClassroomChild?.id ? { display: 'none' } : {}
                      }
                      className={classes.bull}
                    >
                      &bull;
                    </span>
                  )}
                </label>
                <Field
                  component={MaterialInput}
                  name='email'
                  placeholder='Email do(a) aluno(a)'
                  disabled={fieldValues?.isEdit && initialValues?.email}
                  type='email'
                  autoComplete='off'
                />
              </Grid>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Grid item>
                <label htmlFor={'taxpayer_number'}>
                  CPF
                  <span
                    style={currentClassroomChild?.id ? { display: 'none' } : {}}
                    className={classes.bull}
                  >
                    &bull;
                  </span>
                </label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Field
                    allowClear
                    component={MaterialInput}
                    name={'taxpayer_number'}
                    placeholder='CPF'
                    type='text'
                    autoComplete='off'
                    normalize={normalizeCPF}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        searchChildrenByTaxpayerNumber()
                      }
                    }}
                  />
                  <Button
                    type='button'
                    variant='outlined'
                    className={classes.searchBtn}
                    onclick={() => {
                      searchChildrenByTaxpayerNumber()
                    }}
                  >
                    Procurar
                  </Button>
                </div>
              </Grid>
            </div>
            {canCreateWithoutParent && (
              <Grid item xs={12} sm={5}>
                <div className={classes.checkChildIsParent}>
                  <CheckboxInput
                    name='school'
                    checked={childIsParent}
                    style={{ width: 'unset !important' }}
                    handleCheck={handleCheck}
                    // disabled={isEdit}
                  />
                  <p>
                    Este aluno não requer o cadastro de pais ou responsáveis,
                    pois é seu próprio responsável legal.
                  </p>
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className={classes.addAvatarBtn}>
                <p>Adicionar foto:</p>
                <input
                  accept='.png, .jpg, .jpeg, .heic'
                  hidden
                  name='files'
                  onChange={handleOnChangeAvatar}
                  ref={imageRef}
                  type='file'
                />
                {!uploadingInProgress ? (
                  <Tooltip title='Adicionar foto' aria-label='foto'>
                    <ToggleButton
                      style={{ background: 'none' }}
                      onClick={() =>
                        imageRef.current && imageRef.current.click()
                      }
                      value='image'
                      aria-label='image'
                      color='secondary'
                    >
                      <StyledSVG width={21} height={21} src={imgSVG} />
                    </ToggleButton>
                  </Tooltip>
                ) : (
                  <CircularProgress color='secondary' size={21} />
                )}
              </div>
              {imagesElements.img ? (
                // show image in createChildren
                <ListImg
                  handleDeleteImg={handleDeleteChildAvatar}
                  item={imagesElements.img}
                />
              ) : initialValues.avatar_url ? (
                // show image in editUser
                <ListImg
                  item={initialValues.avatar_url}
                  handleDeleteImg={handleDeleteChildAvatar}
                />
              ) : fieldValues?.avatar_url ? (
                // show image in getChildrenByTaxpayer
                <ListImg
                  item={fieldValues?.avatar_url}
                  handleDeleteImg={handleDeleteChildAvatar}
                />
              ) : (
                undefined
              )}
            </Grid>
            {childIsParent && (
              <>
                <Grid item xs={12}>
                  <label htmlFor={'phone'}>Telefone</label>
                  <Field
                    allowClear
                    style={{ maxWidth: 264 }}
                    component={MaterialInput}
                    name={'phone'}
                    placeholder='Digite o telefone'
                    type='text'
                    autoComplete='off'
                    normalize={normalizePhone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <h4
                    style={{
                      margin: '28px 0px'
                    }}
                  >
                    Endereço
                  </h4>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <label htmlFor={'address.zipcode'}>CEP</label>
                  <Field
                    allowClear
                    component={MaterialInput}
                    name={'address.zipcode'}
                    placeholder='CEP'
                    type='text'
                    autoComplete='off'
                    normalize={normalizeCEP}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        searchZipcopde(zipCodeValue)
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={6} style={{ alignSelf: 'center' }}>
                  <Button
                    type='button'
                    onClick={searchZipcopde}
                    variant='outlined'
                    className={classes.searchBtn}
                    style={{ marginTop: 20 }}
                  >
                    Procurar
                  </Button>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <label htmlFor={'address.street'}>Rua</label>
                  <Field
                    allowClear
                    component={MaterialInput}
                    name={'address.street'}
                    placeholder='Nome da rua'
                    type='text'
                    autoComplete='off'
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <label htmlFor={'address.number'}>Número</label>
                  <Field
                    allowClear
                    component={MaterialInput}
                    name={'address.number'}
                    placeholder='Número'
                    type='text'
                    autoComplete='off'
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label htmlFor={'address.complement'}>Complemento</label>
                  <Field
                    allowClear
                    component={MaterialInput}
                    name={'address.complement'}
                    placeholder='Complemento'
                    type='text'
                    autoComplete='off'
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <label htmlFor={'address.neighbourhood'}>Bairro</label>
                  <Field
                    allowClear
                    component={MaterialInput}
                    name={'address.neighbourhood'}
                    placeholder='Bairro'
                    type='text'
                    autoComplete='off'
                  />
                </Grid>
              </>
            )}
            {!childIsParent && (
              <>
                <Grid item xs={12} style={{ marginTop: '1.5rem' }}>
                  <Label className={classes.userTypeLabel}>Responsáveis</Label>
                </Grid>
                <FieldArray
                  name='parents'
                  isEdit={!!currentClassroomChild?.id}
                  component={RenderParentsFields}
                  initialValues={initialValues}
                />
              </>
            )}
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 10,
                textAlign: 'right'
              }}
            >
              <Button
                variant='outlined'
                className={classes.backBtn}
                type='button'
                onClick={() => goBack()}
              >
                Voltar
              </Button>
              <Button
                className={classes.saveBtn}
                type='submit'
                disabled={
                  pristine ||
                  !valid ||
                  (!childrenTaxpayerNumberIsValid ||
                    (!parentTaxpayerNumberIsValid && !childIsParent))
                }
                onClick={() => handleToggle()}
              >
                {currentClassroomChild?.id ? 'Salvar' : 'Cadastrar'}
              </Button>
            </Grid>
          </Grid>
        </Form>
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </Box>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('createStudentForm')
  const initValues = {
    classroom_id: props.classroomId,
    address: props.currentClassroomChild?.user?.address,
    phone: props.currentClassroomChild?.user?.phone,
    name:
      props.currentClassroomChild?.user?.name ||
      props.currentClassroomChild?.name,
    birthdate: props.currentClassroomChild?.birthdate,
    user_id: props.currentClassroomChild && props.currentClassroomChild.user_id,
    taxpayer_number: props.currentClassroomChild?.user?.email
      ? props.currentClassroomChild?.user?.taxpayer_number
      : props.currentClassroomChild?.taxpayer_number,
    avatar_url: props.currentClassroomChild?.avatar_url,
    email: props.currentClassroomChild?.user?.email,
    parents:
      props.currentClassroomChild?.child_parents &&
      props.currentClassroomChild.child_parents.map(parent => {
        return {
          id: parent.parent.id,
          name: parent.parent.name,
          email: parent.parent.email,
          phone: parent.parent.phone,
          address: parent.parent.address,
          avatar: parent.parent.avatar_url,
          taxpayer_number: parent.parent.taxpayer_number,
          child_parent: {
            id: parent.id,
            relationship_type: parent.relationship_type,
            description: parent.description
          }
        }
      })
  }

  return {
    fieldValues: getFormValues('createStudentForm')(state),
    zipCodeValue: selector(state, 'address.zipcode'),
    initialValues: initValues,
    taxpayerValue: selector(state, 'taxpayer_number'),
    currentPagination: state.pagination
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('createStudentForm', field, data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    ),
  backToThePage: data => dispatch(backToThePage(data)),
  getChildrenByTaxpayerRSPA: data =>
    dispatch(getChildrenByTaxpayerRSPA.request(data)),
  getClassroomByChildrenIdRSPA: data =>
    dispatch(getClassroomByChildrenIdRSPA.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'createStudentForm',
    validate,
    onSubmit: async (values, dispatch, props) => {
      const childParentsIds = values.parents
        ?.map(item => item?.child_parent?.id)
        .filter(item => item !== undefined)
      const parentDeleted = props?.currentClassroomChild?.child_parents
        ?.map(item => {
          return childParentsIds && !childParentsIds.includes(item.id)
            ? item
            : undefined
        })
        .filter(item => item !== undefined)
      let childrenData = {
        address: values.address,
        phone: values.phone,
        id: props.currentClassroomChild?.id,
        name: values.name,
        email: values.email,
        birthdate: values.birthdate,
        avatar: values.image,
        taxpayer_number: values.taxpayer_number,
        classroom_id: values.classroom_id,
        childIsParent: values.childIsParent
      }
      if (props.currentClassroomChild?.id || values.childrenId) {
        const propsToEdit = {
          userId: props.currentClassroomChild?.user?.id || values.userId_child,
          childrenData,
          parentsData: values.childIsParent !== true ? values.parents : [],
          parentDeleted
        }
        if (values.childrenId) {
          Object.assign(propsToEdit, {
            childrenId: values.childrenId
          })
        }
        props.editClassroomChildren({
          ...propsToEdit
        })
      } else {
        dispatch(
          createChildrenWithParents({
            childrenData: { ...childrenData, classroom_id: props.classroomId },
            parentsData: values.childIsParent !== true ? values.parents : []
          })
        )
      }
    }
  })(CreateStudentForm)
)
