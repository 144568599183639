import React from 'react'
import { numberFromText } from '../../utils/helpers'
import { colors } from '../../utils/constants'
import { Avatar } from '@material-ui/core'

const UserAvatar = ({
  userName,
  avatarUrl,
  style,
  avatarStyle,
  onClick,
  className,
  withoutUserName
}) => {
  const userColorName =
    userName && numberFromText(userName.slice(0, 2)) % colors.length
  return (
    <span
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        ...style
      }}
      className={className}
    >
      <Avatar
        style={{
          backgroundColor:
            // eslint-disable-next-line standard/computed-property-even-spacing
            colors[userColorName],
          ...avatarStyle
        }}
        src={avatarUrl || ''}
      >
        {userName?.[0]}
      </Avatar>
      {!withoutUserName && <span>{userName}</span>}
    </span>
  )
}

export default UserAvatar
