import { createAction } from '@reduxjs/toolkit'
import { createPromiseAction } from 'redux-saga-promise-actions'
import * as types from './types'

export const getReports = createAction(types.GET_REPORTS)
export const getReportsSuccessful = createAction(types.GET_REPORTS_SUCCESSFUL)
export const getReportsFailure = createAction(types.GET_REPORTS_FAILURE)

export const getGeneratedReportsById = createAction(
  types.GET_GENERATED_REPORTS_BY_ID
)
export const getGeneratedReportsByIdSuccessful = createAction(
  types.GET_GENERATED_REPORTS_BY_ID_SUCCESSFUL
)
export const getGeneratedReportsByIdFailure = createAction(
  types.GET_GENERATED_REPORTS_BY_ID_FAILURE
)

export const generateReports = createAction(types.GENERATE_REPORTS)
export const generateReportsSuccessful = createAction(
  types.GENERATE_REPORTS_SUCCESSFUL
)
export const generateReportsFailure = createAction(
  types.GENERATE_REPORTS_FAILURE
)

export const getReportById = createAction(types.GET_REPORT_BY_ID)
export const getReportByIdSuccessful = createAction(
  types.GET_REPORT_BY_ID_SUCCESSFUL
)
export const getReportByIdFailure = createAction(types.GET_REPORT_BY_ID_FAILURE)

export const createReports = createAction(types.CREATE_REPORTS)
export const createReportsSuccessful = createAction(
  types.CREATE_REPORTS_SUCCESSFUL
)
export const createReportsFailure = createAction(types.CREATE_REPORTS_FAILURE)

export const getReportTemplateById = createAction(
  types.GET_REPORT_TEMPLATE_BY_ID
)
export const getReportTemplateByIdSuccessful = createAction(
  types.GET_REPORT_TEMPLATE_BY_ID_SUCCESSFUL
)
export const getReportTemplateByIdFailure = createAction(
  types.GET_REPORT_TEMPLATE_BY_ID_FAILURE
)

export const editReportTemplate = createAction(types.EDIT_REPORT_TEMPLATE)
export const editReportTemplateSuccessful = createAction(
  types.EDIT_REPORT_TEMPLATE_SUCCESSFUL
)
export const editReportTemplateFailure = createAction(
  types.EDIT_REPORT_TEMPLATE_FAILURE
)

export const getReportStudentById = createAction(types.GET_REPORT_STUDENT_BY_ID)
export const getReportStudentByIdSuccessful = createAction(
  types.GET_REPORT_STUDENT_BY_ID_SUCCESSFUL
)
export const getReportStudentByIdFailure = createAction(
  types.GET_REPORT_STUDENT_BY_ID_FAILURE
)

export const getReportOptionsById = createAction(types.GET_REPORT_OPTIONS_BY_ID)
export const getReportOptionsByIdSuccessful = createAction(
  types.GET_REPORT_OPTIONS_BY_ID_SUCCESSFUL
)
export const getReportOptionsByIdFailure = createAction(
  types.GET_REPORT_OPTIONS_BY_ID_FAILURE
)

export const createReportStudent = createAction(types.CREATE_REPORT_STUDENT)
export const createReportStudentSuccessful = createAction(
  types.CREATE_REPORT_STUDENT_SUCCESSFUL
)
export const createReportStudentFailure = createAction(
  types.CREATE_REPORT_STUDENT_FAILURE
)

export const editReportStudentByClassroom = createAction(
  'EDIT_REPORT_STUDENT_BY_CLASSROOM'
)

export const editReportStudent = createAction(types.EDIT_REPORT_STUDENT)
export const editReportStudentSuccessful = createAction(
  types.EDIT_REPORT_STUDENT_SUCCESSFUL
)
export const editReportStudentFailure = createAction(
  types.EDIT_REPORT_STUDENT_FAILURE
)

export const cleanReportStudentAnswers = createAction(
  types.CLEAN_REPORT_STUDENT_ANSWERS
)

export const getReportStudents = createAction(types.GET_REPORT_STUDENTS)
export const getReportStudentsSuccessful = createAction(
  types.GET_REPORT_STUDENTS_SUCCESSFUL
)
export const getReportStudentsFailure = createAction(
  types.GET_REPORT_STUDENTS_FAILURE
)

export const getLastTeacherByReportRSPA = createPromiseAction(
  'rspa: GET_LAST_TEACHER_BY_REPORT'
)()

export const exportReportRSPA = createPromiseAction('rspa: EXPORT_REPORT')()
