import { createAction } from '@reduxjs/toolkit'
import { createPromiseAction } from 'redux-saga-promise-actions'
import * as types from './types'

export const getClassrooms = createAction(types.GET_CLASSROOMS)
export const getClassroomsSuccessful = createAction(
  types.GET_CLASSROOMS_SUCCESSFUL
)
export const getClassroomsFailure = createAction(types.GET_CLASSROOMS_FAILURE)

export const getClassroomById = createAction(types.GET_CLASSROOM_BY_ID)
export const getClassroomByIdSuccessful = createAction(
  types.GET_CLASSROOM_BY_ID_SUCCESSFUL
)
export const getClassroomByIdFailure = createAction(
  types.GET_CLASSROOM_BY_ID_FAILURE
)

export const getClassroomRecords = createAction(types.GET_CLASSROOM_RECORDS)
export const getClassroomRecordsSuccessful = createAction(
  types.GET_CLASSROOM_RECORDS_SUCCESSFUL
)
export const getClassroomRecordsFailure = createAction(
  types.GET_CLASSROOM_RECORDS_FAILURE
)

export const createClassroom = createAction(types.CREATE_CLASSROOM)
export const createClassroomSuccessful = createAction(
  types.CREATE_CLASSROOM_SUCCESSFUL
)
export const createClassroomFailure = createAction(
  types.CREATE_CLASSROOM_FAILURE
)

export const editClassroom = createAction(types.EDIT_CLASSROOM)
export const editClassroomSuccessful = createAction(
  types.EDIT_CLASSROOM_SUCCESSFUL
)
export const editClassroomFailure = createAction(types.EDIT_CLASSROOM_FAILURE)

export const getClassroomChildren = createAction(types.GET_CLASSROOM_CHILDREN)
export const getClassroomChildrenSuccessful = createAction(
  types.GET_CLASSROOM_CHILDREN_SUCCESSFUL
)
export const getClassroomChildrenFailure = createAction(
  types.GET_CLASSROOM_CHILDREN_FAILURE
)

export const editClassroomChildren = createAction(types.EDIT_CLASSROOM_CHILDREN)
export const editClassroomChildrenSuccessful = createAction(
  types.EDIT_CLASSROOM_CHILDREN_SUCCESSFUL
)
export const editClassroomChildrenFailure = createAction(
  types.EDIT_CLASSROOM_CHILDREN_FAILURE
)

export const getClassroomsTeachers = createAction(types.GET_CLASSROOMS_TEACHERS)
export const getClassroomsTeachersSuccessful = createAction(
  types.GET_CLASSROOMS_TEACHERS_SUCCESSFUL
)
export const getClassroomsTeachersFailure = createAction(
  types.GET_CLASSROOMS_TEACHERS_FAILURE
)

export const getClassroomTeacherById = createAction(
  types.GET_CLASSROOM_TEACHER_BY_ID
)
export const getClassroomTeacherByIdSuccessful = createAction(
  types.GET_CLASSROOM_TEACHER_BY_ID_SUCCESSFUL
)
export const getClassroomTeacherByIdFailure = createAction(
  types.GET_CLASSROOM_TEACHER_BY_ID_FAILURE
)

export const createClassroomTeacher = createAction(
  types.CREATE_CLASSROOM_TEACHER
)
export const createClassroomTeacherSuccessful = createAction(
  types.CREATE_CLASSROOM_TEACHER_SUCCESSFUL
)
export const createClassroomTeacherFailure = createAction(
  types.CREATE_CLASSROOM_TEACHER_FAILURE
)

export const editClassroomTeacher = createAction(types.EDIT_CLASSROOM_TEACHER)
export const editClassroomTeacherSuccessful = createAction(
  types.EDIT_CLASSROOM_TEACHER_SUCCESSFUL
)
export const editClassroomTeacherFailure = createAction(
  types.EDIT_CLASSROOM_TEACHER_FAILURE
)

export const removeClassroomChild = createAction(types.REMOVE_CLASSROOM_CHILD)
export const removeClassroomChildSuccessful = createAction(
  types.REMOVE_CLASSROOM_CHILD_SUCCESSFUL
)
export const removeClassroomChildFailure = createAction(
  types.REMOVE_CLASSROOM_CHILD_FAILURE
)

export const createChildrenWithParents = createAction(
  types.CREATE_CHILDREN_WITH_PARENTS
)
export const createChildrenWithParentsSuccessful = createAction(
  types.CREATE_CHILDREN_WITH_PARENTS_SUCCESSFUL
)
export const createChildrenWithParentsFailure = createAction(
  types.CREATE_CHILDREN_WITH_PARENTS_FAILURE
)

export const clearCurrentClassroom = createAction(types.CLEAR_CURRENT_CLASSROOM)

// for students migration
export const createClassroomChildrenRSPA = createPromiseAction(
  'rspa: CREATE_CLASSROOM_CHILDREN'
)()

export const exportClassroomRSPA = createPromiseAction(
  'rspa: EXPORT_CLASSSROOM'
)()

export const getChildrenByTaxpayerRSPA = createPromiseAction(
  'rspa: GET_CHILDREN_BY_TAXPAYER'
)()
export const getClassroomByChildrenIdRSPA = createPromiseAction(
  'rspa: GET_CLASSROOM_BY_CHILDREN_ID'
)()
export const recoverPasswordRSPA = createPromiseAction(
  'rspa: RECOVER_PASSWORD'
)()
export const getParentByEmailRSPA = createPromiseAction(
  'rspa: GET_PARENT_BY_EMAIL'
)()
export const getClassroomTeacherByUserIdRSPA = createPromiseAction(
  'rspa: GET_CLASSROOM_TEACHER_BY_USER_ID'
)()
export const getClassroomsBySchoolIdRSPA = createPromiseAction(
  'rspa: GET_CLASSROOMS_BY_SCHOOL_ID'
)()
export const getClassroomsByGradeIdRSPA = createPromiseAction(
  'rspa: GET_CLASSROOMS_BY_GRADE_ID'
)()
export const getDestinationsByClassroomRSPA = createPromiseAction(
  'rspa: GET_DESTINATIONS_BY_CLASSROOM'
)()
export const getChildrensByClassroomRSPA = createPromiseAction(
  'rspa: GET_CHILDREN_BY_CLASSROOM'
)()
export const getClassroomChildrenByChildRSPA = createPromiseAction(
  'rspa: GET_CLASSROOM_CHILDREN_BY_CHILD'
)()
export const removeChildrenTaxpayerNmbRSPA = createPromiseAction(
  'rspa: EDIT_CHILDREN'
)()
export const removeUserTaxpayerNmbRSPA = createPromiseAction(
  'rspa: EDIT_USER'
)()

export const deleteUserChildrenRSPA = createPromiseAction(
  'rspa: DELETE_USER_CHILDREN'
)()
