import jsonApi from '../../utils/jsonApi'
import http from '../../utils/http'

const teachersModel = 'school_teachers'
const classroomTeacherModel = 'classroom_teacher'
const teacherSubjectModel = 'teacher_subject'

export const fetchTeacherss = ({ page, per, sort, params }) =>
  jsonApi
    .findAll(teachersModel, {
      params: {
        'q[s]': 'created_at desc',
        'page[number]': page || 1,
        'page[size]': per || 5,
        sort: sort,
        include:
          'school,teacher.classroom_teachers.classroom,teacher_subjects.subject',
        ...params
      }
    })
    .then(res => res)

export const fetchTeacherById = teacherId =>
  jsonApi
    .find(teachersModel, teacherId, {
      params: {
        include:
          'teacher,school,teacher.classroom_teachers.classroom,teacher.timesheets,teacher_subjects.subject'
      }
    })
    .then(res => res)

export const createTeacher = data => {
  return jsonApi.create(classroomTeacherModel, data).then(res => res.data)
}

export const deleteClassroomTeacher = id => {
  return jsonApi.destroy(classroomTeacherModel, id).then(res => res.data)
}

export const deleteSubjectTeacher = id => {
  return jsonApi.destroy(teacherSubjectModel, id).then(res => res.data)
}

export const fetchDeletedTeachers = payload => {
  const { page, ...rest } = payload
  return jsonApi
    .customRequest(
      'GET',
      'school_teachers/only_deleted',
      teachersModel,
      undefined,
      {
        params: {
          'page[number]': page || 1,
          include: 'teacher',
          ...rest?.params
        }
      }
    )
    .then(res => {
      return res
    })
}

export const restoreTeacher = id => {
  return http.put(`/school_teachers/restore/${id}`).then(res => res)
}
