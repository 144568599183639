import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const LightTooltipStyled = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: 8,
    color: '#666',
    fontSize: 10
  }
}))(Tooltip)

const LightTooltip = ({ children, title }) => {
  return (
    <div>
      <LightTooltipStyled title={title}>{children}</LightTooltipStyled>
    </div>
  )
}

export default LightTooltip
