import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Field, formValueSelector, reduxForm, getFormValues } from 'redux-form'

import {
  getUtils,
  createUtils,
  deleteUtil,
  cleanUtilSuccessful,
  getUtilById
} from '../../store/utils/actions'
import { connect } from 'react-redux'
import StyledSVG from '../shared/StyledSVG'
import { showAlertMessage } from '../../store/alert/actions'
import pdfBulletinSVG from '../../assets/icons/pdf-bulletins-icon.svg'
import UserAvatar from '../shared/UserAvatar'
import { getClassroomById } from '../../store/classrooms/actions'
import Label from '../label/Label'
import ConfirmModal from '../modal/ConfirmModal'
import Modal from '../modal/Modal'
import bulletinsSVG from '../../assets/icons/bulletins.svg'
import warningSVG from '../../assets/icons/bulletin-warning.svg'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Paper from '@material-ui/core/Paper'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import MaterialInput from '../shared/MaterialInput'
import viewSVG from '../../assets/icons/pattern-view-icon.svg'
import editSVG from '../../assets/icons/pattern-edit-icon.svg'
import deleteSVG from '../../assets/icons/pattern-delete-icon.svg'
import { Tooltip, MenuItem, makeStyles, withStyles } from '@material-ui/core'
import { roles } from '../../utils/constants'
import CustomTable from '../table/CustomTable'
import RowActionTable from '../table/RowActionTable'
import Button from '../button/Button'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  action: {
    color: theme.palette.text.secondary,
    '&:hover': {
      background: '#F6F7F8'
    },
    '& svg': {
      color: '#375F92'
    }
  },
  archiveInTable: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& .failure': {
      color: '#EF7C8F'
    }
  },
  addButon: {
    position: 'absolute',
    bottom: '-35px',
    right: '0',
    width: '100px',
    backgroundColor: '#386093',
    borderRadius: 8
  },
  backBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    '& button': {
      width: 146,
      border: '1px solid #EF7C8F',
      height: 44,
      padding: '14px 48px',
      gap: 10,
      color: '#EF7C8F',
      borderRadius: 8,
      '&:hover': {
        border: '1px solid #EF7C8F'
      },
      fontSize: '16px',
      fontWeight: 600,
      textTransform: 'capitalize',
      lineHeight: '19.5px'
    },
    [theme.breakpoints.down('890')]: {
      marginTop: 36,
      justifyContent: 'center'
    }
  },
  createBtn: {
    backgroundColor: '#00ACDB',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#00ACDB'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px',
    [theme.breakpoints.up('890')]: {
      marginTop: 16
    },
    whiteSpace: 'nowrap'
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 45,
    [theme.breakpoints.down('890')]: {
      flexDirection: 'column-reverse',
      gap: 16
    }
  },
  searchDiv: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiInputBase-root': {
      width: 300,
      [theme.breakpoints.down('426')]: {
        maxWidth: '250px'
      },
      [theme.breakpoints.down('330')]: {
        maxWidth: 220
      }
    }
  },
  actionsSection: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    width: '55%',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& path': {
      stroke: '#868E96'
    }
  }
}))

const SearchStudentsBulletinForm = ({
  utils,
  getUtils,
  getUtilById,
  userOccupations,
  isFetching,
  pagination,
  getClassroomById,
  currentClassroom,
  currentWrapper,
  deleteUtil,
  createUtils,
  schoolValue,
  schoolId,
  alert,
  cleanUtilSuccessful
}) => {
  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#FFFF',
      fontWeight: 700,
      borderBottom: '1px solid #9B9B9B'
    },
    body: {
      fontSize: 14,
      borderBottom: '1px solid #DB8CC'
    }
  }))(TableCell)
  const location = useLocation()
  const [
    modalForIndividualBulletin,
    setModalForIndividualBulletin
  ] = React.useState(false)
  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    id: null
  })
  const [
    sendedIndividualBulletin,
    setSendedIndividualBulletin
  ] = React.useState(false)
  const bulletinInputRef = React.useRef(null)
  const bulletinViewRef = React.useRef(null)
  const [remainingBulletins, setRemainingBulletins] = React.useState([])
  const classes = useStyles()
  const history = useHistory()
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)

  const defaultParams = {
    'q[wrapper_id_eq]': location?.state?.wrappedId
  }
  const [utilsFiltered, setUtilsFiltered] = React.useState([])
  const [hasWrappedId, setHasWrappedId] = React.useState(false)

  React.useEffect(
    () => {
      if (location?.state?.wrappedId) {
        setHasWrappedId(location?.state?.wrappedId)
      }
    },
    [location?.state?.wrappedId]
  )

  React.useEffect(
    () => {
      setUtilsFiltered(utils)
    },
    [utils]
  )

  const handleChangeTitle = event => {
    const inputValue = event.target.value.trim().toUpperCase()
    if (event.keyCode === 13 && event.shiftKey === false) {
      setUtilsFiltered(prev => {
        const arrayToSup = [...prev]
        const filtered = arrayToSup.sort((a, b) => {
          const nameA = a.destinations?.[0].child.name.toUpperCase()
          const nameB = b.destinations?.[0].child.name.toUpperCase()
          if (nameA.includes(inputValue) && !nameB.includes(inputValue)) {
            return -1
          }
          if (!nameA.includes(inputValue) && nameB.includes(inputValue)) {
            return 1
          }
          return 0
        })
        return filtered
      })
    }
  }
  React.useEffect(
    () => {
      if (sendedIndividualBulletin && !isFetching) {
        getUtils({
          params: { 'q[wrapper_id_eq]': location?.state?.wrappedId }
        })
        setSendedIndividualBulletin(false)
      }
    },
    [sendedIndividualBulletin, isFetching]
  )

  const handleCrudFiles = (event, childName) => {
    setRemainingBulletins(prev => {
      let countNotEquals = 0
      const newState = prev.map(item => {
        if (
          event.target.files[0].name
            .replace(/_/g, ' ')
            .replace(
              `.${
                event.target.files[0].name.split('.')[
                  event.target.files[0].name.split('.').length - 1
                ]
              }`,
              ''
            )
            .toLowerCase() === childName.toLowerCase() &&
          childName.toLowerCase() === item.name.toLowerCase()
        ) {
          const bulletinObjectPreview = event.target.files[0]
          Object.assign(bulletinObjectPreview, {
            preview: URL.createObjectURL(event.target.files[0])
          })
          if (event.target.files[0].size > 20971520) {
            alert('O arquivo deve ter no máximo 20MB.', 'warning')
            return item
          }
          const bulletinChanged = { ...item, file: bulletinObjectPreview }
          return bulletinChanged
        } else {
          countNotEquals += 1
          return item
        }
      })
      if (countNotEquals === prev.length) {
        alert('O nome do arquivo não corresponde ao nome do aluno', 'error')
      }
      return newState
    })
  }

  const columns = [
    {
      key: 'archive_name',
      name: 'Nome do arquivo',
      width: '45%',
      render: function render (row) {
        return (
          <div
            onClick={() => {
              row?.file_url && window.open(row?.file_url, '_blank')
            }}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: 10
            }}
          >
            <StyledSVG src={pdfBulletinSVG} height={35} width={35} />
            <span>{row?.files?.[0]?.filename}</span>
          </div>
        )
      }
    },
    {
      key: 'student_name',
      name: 'Nome do aluno',
      width: '40%',
      render: function render (row) {
        return (
          <UserAvatar
            userName={row?.destinations?.[0]?.child?.name}
            avatarUrl={row?.destinations?.[0]?.child?.avatar_url}
          />
        )
      }
    },
    {
      key: 'actions',
      name: 'Ações',
      width: '15%',
      align: 'left',
      render: function render (row) {
        return (
          <div className={classes.actionsSection}>
            <Tooltip title='Visualizar'>
              <div
                className={classes.action}
                onClick={() => {
                  row?.file_url && window.open(row?.file_url, '_blank')
                }}
              >
                <StyledSVG width={24} height={24} src={viewSVG} />
              </div>
            </Tooltip>
            {isAdmin && (
              <Tooltip title='Editar'>
                <div
                  className={classes.action}
                  onClick={() => {
                    history.push({
                      pathname: '/utils',
                      search: `?id=${row.id}&name=edit&util=${
                        row?.utility_type
                      }`,
                      state: {
                        isIndividual: true
                      }
                    })
                  }}
                >
                  <StyledSVG width={24} height={24} src={editSVG} />
                </div>
              </Tooltip>
            )}
            {isAdmin && (
              <Tooltip title='Excluir'>
                <div
                  className={classes.action}
                  onClick={() =>
                    setConfirmModal({
                      id: row.id,
                      open: true
                    })
                  }
                >
                  <StyledSVG width={24} height={24} src={deleteSVG} />
                </div>
              </Tooltip>
            )}
          </div>
        )
      }
    }
  ]

  React.useEffect(() => {
    getUtils({
      params: { ...defaultParams }
    })
    getClassroomById(location?.state?.classroomId || utils?.[0]?.classroom_id)
    if (location?.state?.wrappedId) {
      getUtilById({ id: location?.state?.wrappedId })
    }
    return cleanUtilSuccessful()
  }, [])

  React.useEffect(
    () => {
      setRemainingBulletins(prev => {
        const childrensHasAlreadyBulletin = utils?.map(
          item => item?.destinations?.[0]?.child_id
        )
        return currentClassroom?.children
          ?.filter(
            item => childrensHasAlreadyBulletin?.includes(+item.id) === false
          )
          .map(item => {
            return { id: item.id, name: item.name, file: null }
          })
      })
    },
    [utils, currentClassroom]
  )
  return (
    <div>
      <div
        className={classes.searchContainer}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 5,
          marginBottom: 45
        }}
      >
        <div className={classes.searchDiv}>
          <strong style={{ marginBottom: 10 }}>Busca</strong>
          <Field
            style={{ width: 300 }}
            component={MaterialInput}
            name='title'
            onKeyDown={handleChangeTitle}
            placeholder='Nome do aluno'
            type='text'
          />
        </div>
        {remainingBulletins?.length > 0 && (
          <Button
            type='button'
            className={classes.createBtn}
            onClick={() => setModalForIndividualBulletin(prev => !prev)}
          >
            Adicionar boletim
          </Button>
        )}
      </div>
      <div>
        <CustomTable
          from={'utils'}
          columns={columns}
          data={hasWrappedId ? utilsFiltered : []}
          columnsPointer={[0]}
          border={'none'}
          bodyBorder={'none'}
          headCellRadius
          headColor={'rgba(173, 184, 204, 0.3)'}
          isFetching={isFetching}
          fetchItems={hasWrappedId && getUtils}
          params={defaultParams}
          pagination={hasWrappedId && pagination}
        />
      </div>
      <div className={classes.backBtn}>
        <Button
          variant='outlined'
          type='button'
          onClick={() => {
            history.push(`/utils?name=list&util=wrapper`)
            cleanUtilSuccessful()
          }}
        >
          Voltar
        </Button>
      </div>
      <Modal
        open={modalForIndividualBulletin}
        handleClose={() => setModalForIndividualBulletin(prev => !prev)}
        unlimitedHeight
      >
        <div style={{ position: 'relative' }}>
          <div
            style={{
              marginBottom: 32,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <Label style={{ marginBottom: 0 }}>Boletim</Label>
            <p>
              Adicione individualmente um boletim para os alunos que ainda não o
              possuem:
            </p>
          </div>

          {remainingBulletins?.length > 0 && (
            <>
              <div className={classes.tableRoot}>
                <TableContainer component={Paper} elevation={0}>
                  <Table
                    className={classes.table}
                    aria-label='customized table'
                  >
                    <TableHead>
                      <TableRow className={classes.tableHeadRow}>
                        <StyledTableCell>Nome do arquivo</StyledTableCell>
                        <StyledTableCell>Destino</StyledTableCell>
                        <StyledTableCell>Ações</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {remainingBulletins?.map(item => {
                        return (
                          <TableRow
                            key={item.id}
                            className={classes.tableHeadRow}
                          >
                            <StyledTableCell>
                              {item?.file ? (
                                <div className={classes.archiveInTable}>
                                  <StyledSVG
                                    width={30}
                                    height={30}
                                    src={bulletinsSVG}
                                  />
                                  <p>
                                    {item.file.name || item.file.filename || ''}
                                  </p>
                                </div>
                              ) : (
                                <div className={classes.archiveInTable}>
                                  <StyledSVG
                                    width={30}
                                    height={30}
                                    src={warningSVG}
                                  />
                                  <p className='failure'>
                                    Arquivo errado ou inexistente!
                                  </p>
                                </div>
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              <UserAvatar userName={item.name} />
                            </StyledTableCell>
                            <StyledTableCell>
                              <RowActionTable>
                                <MenuItem
                                  className={classes.action}
                                  onClick={() =>
                                    bulletinInputRef.current &&
                                    bulletinInputRef.current.click()
                                  }
                                >
                                  <input
                                    accept='.pdf, .jpg, .jpeg, .png'
                                    hidden
                                    multiple={false}
                                    name='individuaBulletinFile'
                                    onChange={event =>
                                      handleCrudFiles(event, item.name)
                                    }
                                    ref={bulletinInputRef}
                                    type='file'
                                  />
                                  {item.file ? 'Editar' : 'Adicionar'}
                                </MenuItem>
                                {item.file && (
                                  <MenuItem
                                    className={classes.action}
                                    onClick={() =>
                                      bulletinViewRef.current &&
                                      bulletinViewRef.current.click()
                                    }
                                  >
                                    <a
                                      ref={bulletinViewRef}
                                      target='_blank'
                                      href={item?.file?.preview}
                                      rel='noreferrer'
                                    />
                                    {'Visualizar'}
                                  </MenuItem>
                                )}
                                {item.file && (
                                  <MenuItem
                                    className={classes.action}
                                    onClick={() =>
                                      setRemainingBulletins(prev => {
                                        let removeItem
                                        const remainingItems = prev.filter(
                                          element => {
                                            if (element.id === item.id) {
                                              removeItem = element
                                              removeItem.file = null
                                            }
                                            return element.id !== item.id
                                          }
                                        )
                                        return [removeItem, ...remainingItems]
                                      })
                                    }
                                  >
                                    {'Remover'}
                                  </MenuItem>
                                )}
                              </RowActionTable>
                            </StyledTableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          )}
          <Button
            className={classes.addButon}
            color='primary'
            type='button'
            disabled={!remainingBulletins?.some(item => item.file)}
            onClick={() => {
              createUtils({
                title: currentWrapper?.title,
                classroom_id: currentWrapper?.classroom_id,
                grade_id: currentWrapper?.grade_id,
                final_date: currentWrapper?.final_date,
                start_date:
                  currentWrapper?.start_date ||
                  moment(new Date()).format('YYYY/MM/DD HH:mm'),
                school_id: schoolValue || schoolId,
                isIndividual: !(
                  remainingBulletins.filter(item => item.file)?.length > 1
                ),
                wrapper_id: location?.state?.wrappedId,
                utility_type: 'bulletins',
                files: [...remainingBulletins.filter(item => item.file)]
              })
              setSendedIndividualBulletin(true)
              setModalForIndividualBulletin(prev => !prev)
            }}
          >
            Adicionar
          </Button>
        </div>
      </Modal>
      <ConfirmModal
        title={'Deseja remover o boletim?'}
        open={confirmModal.open}
        setOpen={value => {
          if (value) {
            setConfirmModal({ ...confirmModal, open: value })
          } else {
            setConfirmModal({ id: null, open: value })
          }
        }}
        onConfirm={() => {
          deleteUtil(confirmModal.id)
          setUtilsFiltered(prev =>
            prev.filter(item => +item.id !== +confirmModal.id)
          )
        }}
      />
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('searchBulletinsForm')
  return {
    utils: state.utils.items,
    currentWrapper: state.utils.currentItem,
    currentClassroom: state.classrooms.currentItem,
    isFetching: state.utils.isFetching,
    pagination: state.utils.pagination,
    userOccupations: state.auth.currentOccupation,
    schoolId:
      state.school.currentSchool && state.school.currentSchool.school_id,
    schoolValue:
      selector(state, 'school_id') ||
      (state.school.currentSchool && state.school.currentSchool.school_id),
    fieldValues: getFormValues('searchBulletinsForm')(state)
  }
}

const mapDispatchToProps = dispatch => ({
  createUtils: data => dispatch(createUtils(data)),
  getUtils: data => dispatch(getUtils(data)),
  deleteUtil: data => dispatch(deleteUtil(data)),
  cleanUtilSuccessful: data => dispatch(cleanUtilSuccessful(data)),
  getUtilById: data => dispatch(getUtilById(data)),
  getClassroomById: data => dispatch(getClassroomById(data)),
  alert: (message, type) =>
    dispatch(
      showAlertMessage({
        message: message,
        severity: type
      })
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'searchStudentsBulletinForm'
  })(SearchStudentsBulletinForm)
)
