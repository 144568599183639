import React from 'react'
import { connect } from 'react-redux'
import PlanningGradesAdminView from '../components/view/PlanningGradesAdminView'

import PlanningGradesView from '../components/view/PlanningGradesView'

const mapStateToProps = ({ auth, school }) => {
  return {
    selectedSchoolItem: school.selectedItem,
    userOccuppations: auth.user.data?.attributes?.occupations,
    currentSchool: school.currentSchool
  }
}

const PlanningGradesPage = ({ currentSchool, match, selectedSchoolItem }) => {
  const planningAdminPage = match.path.includes('/planning/grades')

  return planningAdminPage ? (
    <PlanningGradesAdminView school={currentSchool} />
  ) : (
    <PlanningGradesView
      selectedSchool={selectedSchoolItem}
      school={currentSchool}
    />
  )
}

export default connect(mapStateToProps)(PlanningGradesPage)
