import { createAction } from '@reduxjs/toolkit'
import {
  GET_OCCUPATIONS,
  GET_OCCUPATIONS_SUCCESSFUL,
  GET_OCCUPATIONS_FAILURE
} from './types'

export const getOccupations = createAction(GET_OCCUPATIONS)
export const getOccupationsSuccessful = createAction(GET_OCCUPATIONS_SUCCESSFUL)
export const getOccupationsFailure = createAction(GET_OCCUPATIONS_FAILURE)
